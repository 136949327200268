import React, { Component, useEffect } from "react";
import * as API from "./api.js";
import "./style.css";
import SingleMap from './ReusableComponents/map'
import MarkerIcon from './assets/marker.png'
import { Link, StaticRouter, Redirect } from "react-router-dom";
import Autocomplete from "react-google-autocomplete";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import myMapsInactive from "./assets/sideBarIcons/myMaps.png";
import upIcon from "./icons/upIcon.png";
import downIcon from "./icons/downIcon.png";
import service from "./sidebaricon/Services.png";
import { addProjectDetails } from "./redux";
import { updateProfileDetails } from './redux'
import { connect } from "react-redux";
import L, { layerGroup, marker } from "leaflet";
import dateFormat from 'dateformat'
import EditIcon from './icons/editIcon.svg';
import DroneIcon from './icons/droneIcon.svg';
import ParaIcon from './icons/paraicon.svg';
import flightType from './icons/flightType.png'
import Spraying from './icons/Spraying.svg'
import Loader from './icons/loader.svg';
import Survey from './icons/survey.svg'
import WayPoints from './icons/wayPointImg.svg'
import roadMapView from './assets/Images/roadMapViewzz.png'
import sateliteView from './assets/Images/sateliteView.png'
import ButtonWithIcon from "./ReusableComponents/ButtonWithIcon.js";
import { GCS_FEATURES, getActiveTeam, getFeaturesPermissions } from "./Teams/index.js";
import AppConstants, { MAP_URLS } from "./AppConstants.js";
import { addUserAnalytic, checkURLExist, updateOutTime } from "./ReusableComponents/reusableFunctions.js";
import Select from "react-dropdown-select";
import Button from "./ReusableComponents/Button/Button.js";
import { StepperModalNew } from "./ReusableComponents/StepperModalNew.js";

const mapStyles = {
  width: "auto",
  height: "100%",
};
var cntr = { lat: 19.993982, lng: 73.790416 };

class RMSelectLocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      selectedDroneId: undefined,
      videoJsOptions: null,
      drones: [],
      locations: "nashik",
      streamKeys: this.props.streamKeys,
      topics: this.props.topics,
      dronesLoading: true,
      center: this.props.location.state.center ? this.props.location.state.center : { lat: 19.993982, lng: 73.790416 },
      zoom: 18,
      redirect: false,
      type: "",
      sidebarMode: this.props.location.state
        ? this.props.location.state.sidebarMode
        : "shrunk",
      marker: null,
      maps: [],
      linked_map: null,
      idx: 0,
      RGBShow: true,
      planDetailsPopup: false,
      planName: "",
      client_name: "asd",
      flightType: 1,
      altitude: "",
      speed: "",
      steps: [{
        title: 'Details',
        icon: EditIcon,
      }, {
        title: 'Flight type',
        icon: DroneIcon

      }, {
        title: 'parameters',
        icon: ParaIcon
      }],
      currentStep: 0,
      pathname: "/aerogcsenterprise/remote_mission/flightPlan",
      errorMessage: null,
      emptyPlan: false,
      processTileIndex: "NDVI",
      processTileMin: -0.283,
      processTileMax: 0.181,
      processTileType: "RGN",
      baseLayer: '',
      activeBaseLayer: 'satelite',
      selectedLayer: 'Orthomosaic',
      layerSelectionPopup: false,
      selectedLayerType: 'orthomosaic',
      outId: ""
    };
  }

  setBaseLayerToState = (baseLayer) => {
    this.setState({ baseLayer })
  }

  updateHistogram = async () => {
    return new Promise((resolve, reject) => {
      let taskId = this.state.linked_map
      let { processTileIndex, processTileType, processTileMin, processTileMax, user } = this.state
      let sas = encodeURIComponent(this.state.permissions?.st)
      let blobContainer = this.state.permissions?.container
      let algorithm = processTileIndex
      API.getHistogram({ taskId, algorithm, processTileType: processTileType ? processTileType : "RGB", processTileMin, processTileMax, sas, blobContainer }).then((data) => {
        this.setState((state) => ({
          ...state,
          processTileMin: data.statistics[1].percentiles[0].toFixed(4),
          processTileMax: data.statistics[1].percentiles[1].toFixed(4),
          processTileType: processTileType ? this.state.processTileType : data.algorithms[0].filters[0]
        }), () => {
          this.overlayTilesLayerOf("plantHealth")
        })
      })
    })
  }

  changeSelectedLayer = (layer) => {
    console.log("Layer : ", layer);
    if (layer == 'NDVI') {
      console.log("Here is NDVI");
      this.setState((state) => ({
        selectedLayer: layer,
        layerSelectionPopup: false,
        processTileIndex: "NDVI",
        processTileType: "RGN",
        selectedLayerType: 'plantHealth'
      }), () => {
        this.updateHistogram()
      })
    } else if (layer == 'VARI') {
      console.log("Here is vari");
      this.setState((state) => ({
        selectedLayer: layer,
        layerSelectionPopup: false,
        processTileIndex: "VARI",
        processTileType: "RGB",
        selectedLayerType: 'plantHealth'
      }), () => {
        this.updateHistogram()
      })
    } else if (layer == 'Orthomosaic') {
      this.setState({
        selectedLayer: layer,
        layerSelectionPopup: false,
        selectedLayerType: 'orthomosaic'
      })
      this.overlayTilesLayerOf("orthomosaic")
    } else {
      this.setState({
        selectedLayer: layer,
        layerSelectionPopup: false,
        selectedLayerType: 'base'
      })
      this.removeLayer('orthoLayer')
    }
  }


  closeStepper = () => {
    this.setState((state) => ({
      ...state,
      currentStep: 0,
      planDetailsPopup: false,
      planName: this.props.location.state.planName ? this.props.location.state.planName : "",
      flightType: 1,
      altitude: null,
      speed: null
    }))
  }

  onchangeFlightType = (type) => {
    let pathname = type == 1 ? '/aerogcsenterprise/remote_mission/flightPlan' : type == 2 ? '/aerogcsenterprise/remote_mission/surveyFlightPlan' : '/aerogcsenterprise/remote_mission/sprayingFlightPlot'
    // '/aerogcsenterprise/remote_mission/sprayingFlightPlan'
    this.setState({ flightType: type, pathname, currentStep: this.state.currentStep + 1 })
  }

  handleMapClick = (e) => {
    console.log(e.latlng)
    let marker = this.state.marker
    if (marker) {
      this.state.marker.setLatLng({ lat: Number(e.latlng?.lat), lng: Number(e.latlng?.lng) })
    } else {
      let center = { lat: Number(e.latlng?.lat), lng: Number(e.latlng?.lng) }
      var markerOptions = {
        title: "MyLocation",
        clickable: false,
        draggable: false,
        icon: new L.Icon({
          iconUrl: MarkerIcon,
          iconSize: [20, "auto"],
          iconAnchor: [10, 25],
        })
      }
      marker = new L.Marker(center, markerOptions).addTo(this.state.map)
      this.state.map.setView(center);
      this.setState({ center, marker })
    }
  }

  // To change base layer of map
  switchBaseLayer = () => {
    const { map1 } = this.state
    this.setState({ activeBaseLayer: this.state.activeBaseLayer == "satelite" ? "terrain" : "satelite" }, () => {
      this.state.baseLayer.setUrl(this.state.activeBaseLayer === "satelite" ? MAP_URLS.SATELITE : MAP_URLS.ROAD_MAP)
    })
  }

  checkPlanEmpty = () => {
    if (this.state.plan && this.state.plan.MissionStatistics) {
      let missionType = this.state.plan.MissionStatistics[0].MissionStatistics.Mission_Type
      let resMission = this.state.plan.Mission;
      let resGeofece = missionType == 1 || missionType == 2 ? this.state.plan.DGCAfence : this.state.plan.Geofence;
      // console.log(missionType);
      // console.log(resGeofece);
      let lat = resMission[0].waypoints["MSG x"] ? resMission[0].waypoints["MSG x"] : this.state.center.lat; // get lat 
      let lng = resMission[0].waypoints["Msg y"] ? resMission[0].waypoints["Msg y"] : this.state.center.lng; // get lng
      console.log(lat, ":", lng)
      if (!lat || !lng)
        this.setState({ emptyPlan: true })
    }
    else
      this.setState({ emptyPlan: true })
  }

  setDrones = async () => {
    let gmarkers = [];
    let drones = this.state.drones;
    // var bounds = new L.LatLngBounds()
    for (let i = 0; i < drones.length; i++) {
      if (drones[i].online) {
        let temp = i + 1;
        let lat = drones[i].teleData.lat || 0
        let lng = drones[i].teleData.lon || 0
        let latlng = { lat: lat, lng: lng };
        //bounds.extend(new L.LatLng(lat, lng));
        //this.state.map.fitBounds(bounds);
        gmarkers.push(latlng);
        var markerOptions = {
          title: "MyLocation",
          icon: new L.Icon({
            iconUrl: "https://download.aeromegh.in/file/5d9629eb-9fea-4e4c-ad60-1d9d9a2e0524.png",
            iconSize: [40, "auto"],
            iconAnchor: [10, 25],
          })
        }
        let marker = new L.Marker(latlng, markerOptions).addTo(this.state.map)
      }
    }
  }

  initMap = () => {
    let { map, center } = this.state
    var markerOptions = {
      title: "MyLocation",
      clickable: true,
      draggable: false,
      icon: new L.Icon({ iconUrl: MarkerIcon, iconSize: [20, 28], iconAnchor: [10, 28] })
    }
    let marker = new L.Marker(center, markerOptions).addTo(map).addEventListener("dragend", (e) => {
      let center = e.target.getLatLng()
      this.setState({ center })
      this.state.map.setView(center, 17);
    })
    this.state.map.setView(center, 17);
    this.setState({ marker })
    this.setDrones()
  };

  onClickPrevious = () => {
    this.setState((state) => ({
      currentStep: state.currentStep - 1,
    }))
  }
  onClickNext = () => {
    if (this.state.currentStep == 0 && !this.state.editPlan)
      this.getPlan()
    else {
      this.setState((state) => ({
        currentStep: state.currentStep + 1,
      }))
    }
  }

  getPlan = () => {
    API.checkPlan(this.state.project ? this.state.project.project_name : this.state.projectName, this.state.planName, this.props.location.state.project.id).then(async (res) => {
      if (res.message == "Plan doesn't already exist") {
        let dt = new Date();
        let date = dt.getDate().toString().padStart(2, "0");
        let month = (dt.getMonth() + 1).toString().padStart(2, "0");
        let year = dt.getFullYear();
        let hour = dt.getHours();
        let minute = dt.getMinutes();
        let seconds = dt.getSeconds();
        let dateTime = `${year}-${month}-${date} ${hour}:${minute}:${seconds}`;
        let project = {
          projectDetails: {
            clientName: this.state.client_name,
            "dateTime": new Date().toISOString(),
            flightCompletedCount: 0,
            projectName: this.state.projectName,
            projectId: this.props.location.state.project.id,
            softwareVerison: "1.1",
          }
        }
        await this.props.addProjectDetails(
          {
            projectName: this.state.projectName,
            projectId: this.props.location.state.project.id,
            clientName: this.state.client_name,
            planName: this.state.planName,
            dateAndTime: dateFormat(new Date(), 'mmm dd, yyyy hh:mm:ss ')
          })
        this.setState((state) => ({
          currentStep: state.currentStep + 1,
        }))
      }
      else {
        this.setState({ errorMessage: "Plan name already exist" });
      }
    })
  }

  onChangeHandler = (e) => {
    let t = e.target;
    let format = /[!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?]+/;
    if (t.value.includes(' ')) {
      this.setState({ errorMessage: 'Spaces are not allowed.' })
      console.log("Spaces are not allowed.", t.name)

    } else if (format.test(e.target.value)) {
      this.setState({ errorMessage: "Special characters are not allowed." })
      console.log("Special characters are not allowed.", t.name)

    } else {
      if (t.name == 'altitude' || t.name == 'speed') {
        console.log('t.name if', t.name)
        this.setState((state) => ({
          ...state,
          AltitudeMessage: undefined,
          SpeedMessage: undefined,
        }))
        let value = parseFloat(e.target.value);
        if (value < 1) {
          this.setState((state) => ({
            ...state,
            AltitudeMessage: t.name == 'altitude' ? 'Altitude is less than 1' : undefined,
            SpeedMessage: t.name == 'speed' ? 'Speed is less than 1' : undefined,
          }))
        } else if (value > 121) {
          this.setState((state) => ({
            ...state,
            AltitudeMessage: t.name == 'altitude' ? 'Altitude is greater than 121' : undefined,
          }))
        } else if (value > 20 && t.name == 'speed') {
          this.setState((state) => ({
            ...state,
            SpeedMessage: t.name == 'speed' ? 'Speed is greater than 20' : undefined,
          }))
        } else {
          this.setState((state) => ({
            ...state,
            [t.name]: t.value
          }))
        }
      } else {
        console.log('t.name', t.name)
        this.setState((state) => ({
          ...state,
          errorMessage: null,
          [t.name]: t.value
        }))
      }
    }
  }

  onchangePlace = (e) => {
    console.log("Place changed : ", e.target.value);
    let val = e.target.value.trim()
    let seperator = val.indexOf(",") != -1 ? ',' : val.indexOf(" ") != -1 ? " " : " "
    let latlng = val.trim().split(seperator)
    if (latlng.length == 2) {
      console.log({ lat: latlng[0], lng: latlng[1] });
      this.setState({ center: { lat: latlng[0], lng: latlng[1] } })
    }
  }

  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      console.log('Enter key pressed !')
      this.state.map.setView(this.state.center, 17);
      this.state.marker.setLatLng(this.state.center);
    }
  }

  nextMap = () => {
    let id = this.state.idx + 1
    this.setState((state) => ({
      ...state,
      idx: id,
      linked_map: this.state.maps[id].task_id,
      mapLoading: true
    }));
    document.getElementById('container').scrollLeft += 150;
    this.changeOrtho()
  }

  prevMap = () => {
    let id = this.state.idx - 1
    this.setState((state) => ({
      ...state,
      idx: id,
      linked_map: this.state.maps[id].task_id,
      mapLoading: true
    }));
    document.getElementById('container').scrollLeft -= 150;
    this.changeOrtho()
  }

  changeOrtho = () => {
    if (this.state.RGBShow) {
      this.removeLayer("orthoLayer")
      this.getTilesBounds(this.state.linked_map).then(() => {
        this.overlayTilesLayerOf(this.state.selectedLayerType)
      })
    }
  }

  getTilesBounds = async (taskId) => {
    return new Promise((resolve, reject) => {
      let sas = encodeURIComponent(this.state.permissions?.st)
      let blobContainer = this.state.permissions?.container
      API.getTilesBounds({ taskId, tiffType: "orthomosaic", sas, blobContainer }).then((data) => {
        this.setState({
          tilesBounds: data.tilesBounds,
          bounds: data.bounds,
          //defaultOrtho: true,
          band1: "1",
          band2: "2",
          band3: "3",
        }, () => {
          resolve(this.state.bounds)
        })
      })
    })
  }

  removeLayer = async (layer) => {
    let removeLayer = this.state[layer]
    this.setState({ [layer]: undefined }, () => {
      if (removeLayer) {
        console.log(removeLayer)
        removeLayer.remove()
      }
    })
  }

  overlayTilesLayerOf = async (type) => {
    console.log("Type : ", type);
    let { map } = this.state
    this.removeLayer("orthoLayer").then(async () => {
      if (this.state.linked_map) {
        let taskid = this.state.linked_map
        let { processTileIndex, processTileType, processTileMin, processTileMax, tilesBounds, tileMinZoom, tileMaxZoom } = this.state
        let blobContainer = this.state.permissions?.container;
        let sas = encodeURIComponent(this.state.permissions?.st);
        await this.getTilesBounds(taskid)
        let bounds = [[this.state.bounds[1], this.state.bounds[0]], [this.state.bounds[3], this.state.bounds[2]]]
        console.log(bounds);
        let bandsSelection = `${this.state.RGBShow ? `&bands=${this.state.band1},${this.state.band2},${this.state.band3}` : "&viewDefault=true"}`
        if (type == "plantHealth") {
          let orthoLayer = L.tileLayer(`${AppConstants.tilesServer}/tiles/${taskid}/plantHealth/{z}/{x}/{y}.png?sas=${sas}&blobContainer=${blobContainer}&algorithm=${processTileIndex}&min=${processTileMin}&max=${processTileMax}&filterType=${processTileType}`, {
            tileSize: 256,
            maxZoom: 28,
            bounds: bounds
          }).addTo(map);
          this.setState({ orthoLayer })
        } else {
          console.log("in else part...");
          let orthoLayer = L.tileLayer(`${AppConstants.tilesServer}/tiles/${taskid}/${type}/{z}/{x}/{y}.png?sas=${sas}&blobContainer=${blobContainer}${bandsSelection}`, {
            tileSize: 256,
            maxZoom: 28,
            bounds: bounds
          }).addTo(map);
          console.log(orthoLayer);
          orthoLayer.on("load", () => {
            console.log("Loaded !!!");
            this.setState({ mapLoading: false })
          });
          this.setState({ orthoLayer })
        }
      }
    })
  }

  changeMarkerPosition = (mark) => {
    this.state.map.setView(this.state.center);
    this.state.marker.setLatLng(mark);
  }


  parentSidebarSwitch = () => {
    this.setState((state) => ({
      ...state,
      sidebarMode: state.sidebarMode == "shrunk" ? "expanded" : "shrunk",
    }));
  };

  componentDidMount() {
    API.GetAllDronesStatus().then(
      async (data) => {
        console.log(data);
        let result = [];
        let count = 0;
        data.drones.forEach(async (element, i) => {
          if (element.teleData == undefined) {
          }
          else {
            let latlng = { lat: element.teleData.lat, lng: element.teleData.lon }
            element.latlng = latlng
            result.push(element)
          }
          if (i == data.drones.length - 1) {
            if (result.length > 0) {
              let lat = result[0].teleData.lat;
              let lng = result[0].teleData.lon;
              let latlng = {}
              latlng.lat = parseFloat(lat);
              latlng.lng = parseFloat(lng);

              this.setState((state) => ({
                ...state,
                drones: result,
                dronesLoading: false
              }));
              this.setDrones()
            }
            else {
              this.setState((state) => ({
                ...state,
                drones: [],
                dronesLoading: false
              }));
            }
          }
        })
      })
  }

  drawPlan = async () => {
    let dt = new Date();
    let date = dt.getDate().toString().padStart(2, "0");
    let month = (dt.getMonth() + 1).toString().padStart(2, "0");
    let year = dt.getFullYear();
    let hour = dt.getHours();
    let minute = dt.getMinutes();
    let seconds = dt.getSeconds();
    let dateTime = `${year}-${month}-${date} ${hour}:${minute}:${seconds}`;
    await this.props.addProjectDetails(
      {
        projectName: this.props.location.state.projectName,
        clientName: "",
        planName: this.state.planName,
        dateAndTime: dateFormat(new Date(), 'mmm dd, yyyy hh:mm:ss ')
      })
  }

  getProjects = () => {
    API.getAllProjects(this.state.projectName, 1000, 0).then(async (data) => {
      console.log('data from projects APi', data);
      let projectsData = data && data.length > 0 ? data.filter((data) => data.project_name == this.state.projectName) : {}
      console.log('projectsData', projectsData[0]);
      this.setState((state) => ({
        ...state,
        project: projectsData[0],
        planDetailsPopup: true
      }), () => {
        this.setState({ loading: false })
      })
    }, (e) => {
      console.log(e);
    })
  }

  componentWillUnmount() { updateOutTime(this.state.outId) }

  componentWillMount = async () => {
    addUserAnalytic(this.props.location.pathname).then(id => { this.setState({ outId: id }) })
    if (this.props.location.state) {
      let { user, type, drawPlan, editPlan, project, plan, linked_map, planName, projectName } = this.props.location.state; //draw plan is used if plan is created but plan in not exist
      if (drawPlan == true && editPlan == true) {
        this.drawPlan()
      }
      this.setState(
        (state) => ({
          ...state,
          user,
          type,
          drawPlan,
          project,
          linked_map,
          planName: planName ? planName : '',
          plan,
          editPlan,
          projectName,
        }),
        () => {
          getFeaturesPermissions([GCS_FEATURES.PLANS]).then(async permissions => {
            this.checkPlanEmpty()
            this.setState({ loading: false, permissions, activeTeam: await getActiveTeam() }, () => {
              API.GetAllDronesStatus().then(
                async (data) => {
                  let result = [];
                  let count = 0;
                  data.drones.forEach(async (element, i) => {
                    if (element.teleData == undefined) {
                    }
                    else {
                      let latlng = { lat: element.teleData.lat, lng: element.teleData.lon }
                      element.latlng = latlng
                      result.push(element)
                    }
                    if (i == data.drones.length - 1) {
                      if (result.length > 0) {
                        let lat = result[0].teleData.lat;
                        let lng = result[0].teleData.lon;
                        let latlng = {}
                        latlng.lat = parseFloat(lat);
                        latlng.lng = parseFloat(lng);

                        this.setState((state) => ({
                          ...state,
                          drones: result,
                          dronesLoading: false
                        }));
                        this.setDrones()
                      }
                      else {
                        this.setState((state) => ({
                          ...state,
                          drones: [],
                          dronesLoading: false
                        }));
                      }
                    }
                  })
                })
            })
          }).catch(e => window.location.replace('/'))
        }
      );
    } else {
      API.getUserType().then(
        ([blah, type, user]) => {
          this.setState(
            (state) => ({
              ...state,
              user,
              type,
            }),
            () => { }
          );
        },
        (e) => {
          window.location.replace("/login");
        }
      );
    }
  }
  changeDrone = (e) => {
    this.setState({ selectedDroneId: e?.drone_name });
    let latlng = e?.latlng
    this.setState((state) => ({
      ...state,
      center: latlng && latlng.lat && latlng.lng ? latlng : { lat: 19.993982, lng: 73.790416 }
    }), () => {
      if (latlng && latlng.lat && latlng.lng) {
        this.state.map.setView(this.state.center, 17);
        this.state.marker.setLatLng(this.state.center);
      }
      else {
        this.state.map.setView({ lat: 19.993982, lng: 73.790416 }, 17);
        this.state.marker.setLatLng({ lat: 19.993982, lng: 73.790416 });
      }
    });
  };
  render() {
    return (
      <div className="wrapper">
        <div className="right-content-new" style={{ overflowY: "hidden" }}
        >
          <div className="top-bar-new" >
            <div className="top-bar-text-new"   >
              SELECT LOCATION
            </div>
          </div>
          <div>
            {this.state.loading ? <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
              <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
            </div> :
              <div id="rmp-map" className="main-content main-content-dcc"
                style={{
                  paddingTop: "1px",
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  display: "flex",
                  height: "calc(100vh - 70px)",
                  width: '100vw'
                }}>
                <SingleMap
                  setBaseLayerToState={this.setBaseLayerToState}
                  initCenter={[this.state.center.lat, this.state.center.lng]}
                  initZoom={22}
                  // bounds={this.getCurrentMapExtent()}
                  handleMapClick={this.handleMapClick}
                  handleMoveStart={this.props.handleMoveStart}
                  maxZoom={23}
                  setMap={(map) => {
                    this.setState({ map }, this.initMap)
                  }}
                />
              </div>}
            {!this.state.loading && this.state.maps.length > 0 ?
              <div
                style={{
                  height: '98%',
                  width: "150px",
                  top: '40px',
                  left: '5px',
                  boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
                  position: 'absolute',
                  backgroundColor: 'rgb(0 0 0 / 0.5)',
                  display: 'flex',
                  flexDirection: "column",
                  padding: "7px 0px",
                  alignItems: "center"
                }}>
                <div style={{}}>
                  <img src={upIcon}
                    style={{
                      width: "22px",
                      height: "22px",
                      opacity: this.state.idx == 0 || this.state.mapLoading ? '0.5' : 1,
                      cursor: this.state.idx == 0 || this.state.mapLoading ? 'default' : 'pointer'
                    }}
                    onClick={() => {
                      if (this.state.idx > 0 && !this.state.mapLoading)
                        this.prevMap()
                    }} />
                </div>
                {/* <div className='content-text' style={{ color: "white", fontWeight:500 }}> Maps </div> */}
                <div id='container' style={{
                  display: 'flex',
                  flexDirection: "column",
                  width: '100%',
                  padding: '10px 2px',
                  overflow: 'auto',
                  gap: '15px',
                  height: '96%',
                  alignItems: "center",
                }}>
                  {this.state.maps.map((image, idx) => {
                    return <div>
                      {this.state.idx == idx ? <img
                        src={image && image.task_id ? image.mapURL : `${myMapsInactive}`}

                        style={{
                          cursor: this.state.mapLoading ? 'default' : "pointer",
                          width: "105px",
                          height: "75px",
                          maxWidth: '140px',
                          transition: 'transform .2s',
                          transform: 'scale(1.2)',
                          margin: '0px 20px',
                          boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
                          zIndex: '111',
                          border: 'solid 3px #2989CF',
                        }}
                        onClick={() => {
                          if (!this.state.mapLoading) {
                            this.setState((state) => ({
                              ...state,
                              mapLoading: true,
                              idx: idx,
                              linked_map: image.task_id,
                            }), () => this.changeOrtho());
                          }
                        }}
                      /> :
                        <img
                          src={image && image.task_id ? image.mapURL : `${myMapsInactive}`}
                          style={{
                            width: "105px",
                            height: "75px",
                            cursor: this.state.mapLoading ? 'default' : "pointer",
                            maxWidth: '130px',
                            border: "1px solid white"
                          }}
                          onClick={() => {
                            this.setState((state) => ({
                              ...state,
                              mapLoading: true,
                              idx: idx,
                              linked_map: image.task_id,
                            }), () => this.changeOrtho());
                            // if (!this.state.mapLoading) {
                            //     this.setState((state) => ({
                            //         ...state,
                            //         mapLoading: true,
                            //         idx: idx,
                            //         linked_map: image.task_id,
                            //     }), () => this.changeOrtho());
                            // }
                          }}
                        />
                      }
                    </div>
                  })
                  }
                </div>
                <div style={{ marginBottom: "25px" }}>
                  <img src={downIcon}
                    style={{
                      width: "22px",
                      height: "22px",
                      opacity: this.state.idx == this.state.maps.length - 1 ? '0.5' : 1,
                      cursor: this.state.idx == this.state.maps.length - 1 ? 'default' : 'pointer'
                      // opacity: this.state.idx == this.state.maps.length - 1 || this.state.mapLoading ? '0.5' : 1,
                      // cursor: this.state.idx == this.state.maps.length - 1 || this.state.mapLoading ? 'default' : 'pointer'
                    }}
                    onClick={() => {
                      if (this.state.idx != this.state.maps.length - 1) this.nextMap()
                    }} />
                </div>
              </div> :
              <></>
            }
            <div style={{ top: "90px", zIndex: 1, position: "absolute", right: "50px" }}>
              <div
                className="page2"
                style={{
                  width: "420px",
                  borderRadius: '10px',
                  padding: "30px",
                }}
              >
                <div
                  className="font-color-primary"
                  style={{
                    fontFamily: "Lato",
                    fontWeight: "500",
                    fontSize: "22px",
                  }}
                >{"Select Location"}</div>
                <div className="Search_wrapper" style={{ width: '100%', margin: '30px 0px' }}>
                  <Autocomplete
                    style={{ backgroundColor: "white", fontFamily: "Lato" }}
                    onChange={(e) => { this.onchangePlace(e) }}
                    onKeyDown={(e) => this.handleKeyDown(e)}
                    onPlaceSelected={(place) => {
                      try {
                        let lat = place.geometry.location.lat();
                        let lng = place.geometry.location.lng();
                        let cntr = {};
                        cntr.lat = parseFloat(lat);
                        cntr.lng = parseFloat(lng);
                        this.setState((state) => ({
                          ...state,
                          center: cntr,
                        }), () => {
                          this.changeMarkerPosition(cntr)
                        });

                      } catch {

                      }
                    }}
                    types={[]}
                  />
                </div>
                <div title={this.state.selectedDroneId?.length > 32 && this.state.selectedDroneId}>
                  <Select
                    placeholder="Select Drone"
                    searchable={true}
                    noDataRenderer={() => {
                      return <span style={{ textAlign: 'center', padding: '5px 0px' }}>Drones not available</span>
                    }}
                    options={
                      this.state.drones.map((log, i) => ({
                        label: log.drone_name,
                        value: log
                      }))
                    }
                    className="custom_dropdown"
                    onChange={(event) =>
                      this.changeDrone(event[0]?.value)
                    }
                  />

                </div>
                <div style={{ marginTop: '30px', display: 'flex', justifyContent: 'flex-end' }}>
                  <ButtonWithIcon
                    text="Next"
                    color={"white"}
                    width={"100px"}
                    height={"40px"}
                    fontWeight={500}
                    textFontSize={"16px"}
                    borderRadius={"10px"}
                    backgroundColor={"#3988F9"}
                    border={"1px solid #3988F9"}
                    isBtnActive
                    onClick={() => {
                      this.getProjects()
                      this.setState({ planDetailsPopup: true })
                    }}
                  />
                </div>
              </div>
            </div>
            {/* Layer selection popup */}
            {this.state.linked_map ? <div className='slide' style={{ textAlign: 'center', border: 'solid 1px rgb(102, 102, 102, 0.3)', position: 'absolute', width: "105px", padding: "4px 12px", bottom: '24px', right: '118px', backgroundColor: 'white', borderRadius: '5px', cursor: 'pointer', zIndex: '1', fontSize: "12px", fontWeight: 500 }}
              onClick={() => {
                this.setState({ layerSelectionPopup: !this.state.layerSelectionPopup })
              }}>
              {this.state.selectedLayer}
            </div> : <></>}
            {this.state.layerSelectionPopup && this.state.linked_map ?
              <div style={{ position: 'absolute', width: "105px", fontSize: "12px", bottom: '56px', right: '118px', backgroundColor: 'white', borderRadius: '5px', zIndex: '1', padding: "1px", fontWeight: 500 }} classname="cardscon content-text">
                <div onClick={() => this.changeSelectedLayer("Orthomosaic")} style={{ cursor: 'pointer', margin: "3px 4px 3px 12px" }}>
                  Orthomosaic
                </div>
                <hr style={{ margin: "3px" }}></hr>
                <div onClick={() => this.changeSelectedLayer("NDVI")} style={{ cursor: 'pointer', margin: "3px 4px 3px 12px" }}>
                  NDVI
                </div>
                <hr style={{ margin: "3px" }}></hr>
                <div onClick={() => this.changeSelectedLayer("VARI")} style={{ cursor: 'pointer', margin: "3px 4px 3px 12px" }}>
                  VARI
                </div>
                <hr style={{ margin: "3px" }}></hr>
                <div onClick={() => this.changeSelectedLayer("Base")} style={{ cursor: 'pointer', margin: "3px 4px 3px 12px" }}>
                  BASE
                </div>
              </div> : <></>}

            {/* changeBaseMapView  */}
            <div className='slide' style={{ border: 'solid 1px rgb(102, 102, 102, 0.3)', position: 'absolute', bottom: '24px', right: '18px', backgroundColor: 'rgba(255, 255, 255, 0.9)', borderRadius: '5px', cursor: 'pointer', zIndex: '1' }}
              onClick={() => {
                this.switchBaseLayer()
              }}>
              <img style={{ width: '90px', height: '27px', borderRadius: '4px' }} src={this.state.activeBaseLayer === "satelite" ? roadMapView : sateliteView} />
            </div>
            {/* Stepper for plan details */}
            <StepperModalNew
              steps={this.state.steps}
              closeStepper={() => {
                this.closeStepper()
              }}
              styles={{ labelMargin: 5 }}
              stepperModal={this.state.planDetailsPopup}
              heading={"Add Plan Details"}
              currentStep={this.state.currentStep}
              height={"500px"}
              width={"680px"}

            >
              <div className="content-text" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                {this.state.currentStep == 0 ? (
                  <div style={{ margin: "5% 15% 2% 15%", display: "flex", flexDirection: "column", textAlign: "left", justifyContent: "left" }}>
                    <div>
                      <div style={{ fontSize: "15px", fontFamily: 400, color: "#555C67" }}>Project Name <br /></div>
                      <input type="text" value={this.state.projectName} className="add-user-form-text" placeholder="Name"
                        disabled style={{ width: "95%" }} />
                      <div style={{ fontSize: "15px", fontFamily: 400, color: "#555C67", marginTop: "18px" }} > Plan Name <br /></div>
                      <input disabled={this.state.editPlan} type="text" name="planName" maxLength={50} value={this.state.planName} className="add-user-form-text" placeholder="Name"
                        onChange={this.onChangeHandler} style={{ width: "95%" }} />
                      {this.state.errorMessage ? <div style={{ lineHeight: '12px', margin: '10px 0px 3px 0px', fontSize: '11px', color: 'red' }}>{this.state.errorMessage}</div> : ''}
                    </div>
                  </div>
                )
                  : this.state.currentStep == 1 ? (
                    <div style={{ margin: "10% 13%", display: "flex", flexDirection: "column", textAlign: "left", justifyContent: "left" }}>
                      <div>
                        {/* <div style={{ fontSize: "14px", marginBottom:"34px", width:"445px" }}> Flight type <br /></div> */}
                        <div className="flex-center" style={{ justifyContent: "space-evenly" }}>
                          <div className="flex-center" style={{ flexDirection: "column" }}>
                            <div onClick={() => this.onchangeFlightType(1)} className="cardscon" style={{ padding: "0px", width: "115px", cursor: "pointer", marginBottom: "16px", border: this.state.flightType == 1 ? '1px solid #2989cf' : 'none' }}>
                              <img src={WayPoints} height={"110px"} style={{ scale: 1.17 }} />
                            </div>
                            <div className="content-text" style={this.state.flightType == 1 ? { color: '#555C67', fontSize: "14px" } : {}}>Waypoints</div>
                          </div>
                          <div className="flex-center" style={{ flexDirection: "column" }}>
                            <div onClick={() => this.onchangeFlightType(2)} className="cardscon" style={{ padding: "0px", width: "115px", cursor: "pointer", marginBottom: "16px", border: this.state.flightType == 2 ? '1px solid #2989cf' : 'none' }}>
                              <img src={Survey} height={"110px"} style={{ scale: 1.17 }} />
                            </div>
                            <div className="content-text" style={this.state.flightType == 2 ? { color: '#555C67', fontSize: "14px" } : {}}>Survey</div>

                          </div>
                          <div className="flex-center" style={{ flexDirection: "column" }}>
                            <div onClick={() => this.onchangeFlightType(3)} className="cardscon" style={{ padding: "0px", width: "115px", cursor: "pointer", marginBottom: "16px", border: this.state.flightType == 3 ? '1px solid #2989cf' : 'none' }}>
                              <img src={Spraying} height={"110px"} style={{ scale: 1.17 }} />
                            </div>
                            <div className="content-text" style={this.state.flightType == 3 ? { color: '#555C67', fontSize: "14px" } : {}}>Spraying</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div style={{ margin: "5% 20%", display: "flex", flexDirection: "column", textAlign: "left", justifyContent: "left" }}>
                      <div>
                        <div style={{ fontSize: "15px", fontFamily: 400, color: "#555C67" }}>Altitude <br /></div>
                        <div className="add-user-form-text-wrapper">
                          <input type="number" className="add-user-form-text" style={{ height: "20px", width: "95%", paddingLeft: "7px" }} name="altitude" value={this.state.altitude} onChange={this.onChangeHandler} onKeyDown={(event) => { return event.keyCode == 69 ? false : true }} tabIndex="0" />
                          <div className="units" style={{ marginLeft: "85%", marginTop: '-1.5rem' }}>m</div>
                          {this.state.AltitudeMessage ? <div style={{ lineHeight: '12px', margin: '10px 0px 3px 0px', fontSize: '11px', color: 'red' }}>{this.state.AltitudeMessage}</div> : ''}
                          <div className="span" style={{ marginLeft: "72%" }}>[Min-1 Max-121]</div>
                        </div>

                        <div style={{ fontSize: "15px", fontFamily: 400, color: "#555C67", marginTop: "10px" }}>Speed<br /></div>
                        <div className="add-user-form-text-wrapper">
                          <input type="number" name="speed" className="add-user-form-text" style={{ height: "20px", width: "95%", paddingLeft: "7px" }} value={this.state.speed} onChange={this.onChangeHandler} />
                          <div className="units" style={{ marginLeft: "85%", marginTop: '-1.5rem' }}>m/s</div>
                          {this.state.SpeedMessage ? <div style={{ lineHeight: '12px', margin: '10px 0px 3px 0px', fontSize: '11px', color: 'red' }}>{this.state.SpeedMessage}</div> : ''}
                          <div className="span" style={{ marginLeft: "72%" }}>[Min-1 Max-20]</div>
                        </div>
                      </div>
                    </div>
                  )
                }
                <div style={{ width: "100%", padding: "0% 5%", display: "flex", justifyContent: "space-between", bottom: "6%", position: "absolute" }}>

                  {this.state.currentStep != 0 ?
                    <Button text="Previous" className={"new-btn-md"} onClick={this.onClickPrevious} />
                    : ''
                  }

                  {(this.state.currentStep == 0 && this.state.planName && !this.state.errorMessage) || (this.state.currentStep == 1 && flightType) ?
                    (this.state.currentStep == 0 && this.state.planName && !this.stateerrorMessage) ?
                      <div style={{ marginLeft: "auto" }}>
                        <Button className={"new-btn-md"} isBtnActive={true} text={"Next"} onClick={this.onClickNext} />
                      </div>
                      :
                      <Button className={"new-btn-md"} isBtnActive={true} text={"Next"} onClick={this.onClickNext} />
                    :
                    this.state.currentStep == 2 && this.state.altitude && this.state.speed ?
                      <Link style={{ outline: "none" }}
                        id="next"
                        to={{
                          pathname: this.state.pathname,
                          state: {
                            altitude: this.state.altitude,
                            speed: this.state.speed,
                            flightType: this.state.flightType,
                            user: this.state.user,
                            type: this.state.type,
                            sidebarMode: this.state.sidebarMode,
                            center: { lat: Number(this.state.center.lat), lng: Number(this.state.center.lng) },
                            drawPlan: this.props.location.state.drawPlan,
                            editPlan: !this.state.emptyPlan,
                            project: this.props.location?.state?.project || this.state.project,
                            plan: this.state.plan,
                            projectName: this.state.projectName,
                            planName: this.state.planName,
                            linked_map: this.state.linked_map,
                            clientName: this.state.client_name,
                          }
                        }}
                      >
                        <button className="new-button new-btn-active new-btn-md" onClick={this.onClickNext}>Next</button>
                      </Link>
                      :
                      <div style={{ marginLeft: "auto" }}>
                        <Button text="Next" className={"new-btn-md"} isDisabled />
                      </div>
                  }
                </div>
              </div>
            </StepperModalNew>
          </div>
        </div>
      </div >
    );
  }
}


const mapStateToProps = state => {
  return {
    projectName: state.project.projectName,
    clientName: state.project.clientName,
    planName: state.project.planName,
    dateAndTime: state.project.dateAndTime
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateProfileDetails: (payload) => dispatch(updateProfileDetails(payload)),
    addProjectDetails: (payload) => dispatch(addProjectDetails(payload))
  };
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyBuxfUtRZSEUT2QrbZA8zUsG58lZUYYmwI",
  libraries: ["drawing", "places"],
})(connect(mapStateToProps, mapDispatchToProps)(RMSelectLocation));