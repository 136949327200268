import React, { Component } from "react";
import * as API from "./api.js";
import "./style.css";
import { addUserAnalytic, updateOutTime, checkSubscriptionAndStorage } from './ReusableComponents/reusableFunctions.js';
import { BreadCrumbs } from './ReusableComponents/BreadCrumbs';
import Menu from './icons/MenuWhite.png';
import { Link, Redirect, withRouter } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { SortComponent } from "./ReusableComponents/SortComponent.js";
import Nopaln from './assets/Images/Noplan.png';
import { connect } from "react-redux";
import { updateProfileDetails } from './redux'
import { ActivityPopup } from './ReusableComponents/ActivityPopup.js';
import { ActiveRole, GCS_FEATURES, getActiveTeam, getFeaturesPermissions, setActiveRoleForProject } from "./Teams/index.js";
import AppConstants from "./AppConstants.js";
import SubscriptionExpireModal from "./subscription/Reusable/SubscriptionExpireModal.js";
import TableView from "./ReusableComponents/TableView/TableView"
import OverviewBox from "./AeroGCSEnterprise/Projects/OverviewBox.js";
import planSvg from "./icons/planSvg.svg";
import "./AeroGCSEnterprise/Projects/Project.css";
import addIcon from "./icons/addIcon.svg";
import "./AeroGCSEnterprise/Plans/Plans.css";
import plotSvg from "./icons/plotSvg.svg"
import SearchHeader from "./ReusableComponents/SearchHeader/SearchHeader.js";
import LoaderComponent from "./ReusableComponents/LoaderComponent.js";
import Button from "./ReusableComponents/Button/Button.js";
import TableViewPagination from "./ReusableComponents/TableView/TableViewPagination.js";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <div style={{ height: "20px", width: "20px", borderRadius: "10px", background: "rgba(0, 0, 0, 0.6)" }} alt="I am alt" >
      <img src={Menu} style={{ height: "12px", marginBottom: "5px" }} alt="I am alt" />
    </div>
  </a>
));
class Plans extends Component {
  constructor(props) {
    super(props);
    this.viewOnMapRef = React.createRef();
    this.state = {
      user: {},
      type: "",
      flightTye: "1",
      services: [],
      project: {},
      showTaskDeletePopup: false,
      plans: [],
      searchUserKeyword: "",
      manageAccessModal: false,
      manageAccessList: [],
      notInAccessList: [],
      givePermission: {},
      givePermissionToUser: "",
      activePlan: "",
      projectsLoading: true,
      editPermission: "",
      createPermission: "",
      deletePermission: "",
      readPermission: "",
      sidebarMode: this.props.location.state
        ? this.props.location.state.sidebarMode
        : "shrunk",
      mapView: false,
      listview: true,
      plansonMap: [],
      planstat: {},
      selectMapPlan: {},
      itemName: "",
      showProcessPopup: false,
      processPopupIcon: "",
      processAction: "",
      processMessage: "",
      sortType: 'Last Modified',
      outId: "",
      gridView: false,
      plots: [],
      plotview: false,
      deltePlot: '',
      geofenceData: [],
      planStatModal: false,
      showSubscriptionExpirePopup: false,

      searchHeaderObj: {
        searchString: '',
        fromDate: undefined,
        toDate: undefined,
        tableViewType: 'gride'
      },
      plotsObj: {
        allPlots: [],
        totalPlotsCount: 0,
        pageNumber: 1,
        pageSize: 15,
        orderBy: 'created_datetime',
        orderType: 'DESC'
      },
      plansObj: {
        allPlans: [],
        totalPlansCount: 0,
        pageNumber: 1,
        pageSize: 15,
        orderBy: 'date_and_time',
        orderType: 'DESC'
      }

    };
  }

  getPlansOnMap = () => {
    API.getPlansOnMap(this.state.project.project_name, this.props.location.state.project.id).then((projectplans) => {
      this.setState((state) => ({
        ...state,
        plansonMap: projectplans.plans
      }))
    }, (e) => {
      console.log(e);
      alert(e)
    })
  }

  closeActivityPopup = () => {
    this.setState((state) => ({
      ...state,
      showProcessPopup: false,
      processMessage: null
    }))
  }

  deletePlot = () => {
    this.setState(
      (state) => ({
        ...state,
        itemName: "Plot",
        processPopupIcon: "WAIT",
        processAction: "DELETE",
        processMessage: null,
        showProcessPopup: true,
      })
    )
    API.deleteSprayingPlot(this.state.deltePlot.id).then((data) => {
      if (data.message == 'OK') {
        this.setState((state) => ({
          ...state,
          itemName: "Plot",
          processPopupIcon: "COMPLETE",
          processAction: "DELETE",
          processMessage: null,
          showProcessPopup: true,
        }), () => {
          if (this.state.permissions[GCS_FEATURES.PLANS].DELETE) {
            this.getAllSprayingPlots()
          } else {
            this.setState(
              (state) => ({
                ...state,
                redirectToProject: true
              }))
          }
        })
      } else {
        this.setState((state) => ({
          ...state,
          itemName: "Plot",
          processPopupIcon: "COMPLETE",
          processAction: "DELETE",
          processMessage: data.message,
          showProcessPopup: true,
        }), () => {
          if (this.state.permissions[GCS_FEATURES.PLANS].DELETE) {
            this.getAllSprayingPlots()
          } else {
            this.setState(
              (state) => ({
                ...state,
                redirectToProject: true
              }))
          }
        })
      }

    },
      (err) => {
        console.log(err);
        this.setState(
          (state) => ({
            ...state,
            itemName: "Plot",
            processPopupIcon: "ERROR",
            processAction: "DELETE",
            processMessage: "Cannot Delete Plot",
            showProcessPopup: true,
          }), () => {
            if (this.state.permissions[GCS_FEATURES.PLANS].DELETE) {
              this.getAllSprayingPlots()
            } else {
              this.setState(
                (state) => ({
                  ...state,
                  redirectToProject: true
                }))
            }
          })
      }
    );
  }

  deletePlan = () => {
    if (this.state.permissions[GCS_FEATURES.PLANS].DELETE) {
      this.setState({
        projectsLoading: true,
        itemName: "Plan",
        processPopupIcon: "WAIT",
        processAction: "DELETE",
        processMessage: null,
        showProcessPopup: true,
      }, () => {
        API.deleteAerocapturePlan(this.state.plan.id, AppConstants.SERVICES.AEROGCS).then(
          (data) => {
            this.setState(
              (state) => ({
                ...state,
                projectsLoading: true,
                itemName: "Plan",
                processPopupIcon: "COMPLETE",
                processAction: "DELETE",
                processMessage: null,
                showProcessPopup: true,
              }), () => {
                this.getPlans()
                this.getPlansOnMap()
              })
          },
          (err) => {
            console.log(err);
            this.setState(
              (state) => ({
                ...state,
                projectsLoading: true,
                itemName: "Plan",
                processPopupIcon: "ERROR",
                processAction: "DELETE",
                processMessage: "Cannot Delete Plan",
                showProcessPopup: true,
              }), () => {
                if (this.state.permissions[GCS_FEATURES.PLANS].DELETE) {
                  this.getPlans()
                } else {
                  this.setState(
                    (state) => ({
                      ...state,
                      redirectToProject: true
                    }))
                }
              })
          }
        );
      });
    }
    else alert("Dont have delete access.");
  }

  redirectToCreatePlan = () => {
    let { user, type } = this.state
    if (this.state.redirectToProject) {
      return <Redirect to={{
        pathname: "/aerogcsenterprise/projects",
        state: {
          user: this.state.user,
          type: this.state.type,
          sidebarMode: this.state.sidebarMode,
        }
      }} />
    }
  }

  openPlanstat = (idx) => {
    this.setState((state) => ({
      ...state,
      loadingData: true,
      selectMapPlan: this.state.plansonMap[idx],
      planStatModal: true,

    }), () => {
      this.setState((state) => ({
        ...state,
        loadingData: false
      }))
      this.state.plans.map((plan) => {
        if (plan.id == this.state.plansonMap[idx].plan_id) {
          this.setState((state) => ({
            ...state,
            planFromMap: plan
          }), () => {
            if (this.viewOnMapRef.current) {
              this.viewOnMapRef.current.click()
            }
          })
        } else {
          console.log("plans not match with selected plan from map")
        }
      })
    })
  }

  parentSidebarSwitch = () => {
    this.setState((state) => ({
      ...state,
      sidebarMode: state.sidebarMode === "shrunk" ? "expanded" : "shrunk",
    }));
  };

  sortFenceLatLng = (Geofence) => {
    return new Promise((resolve, reject) => {
      let poly = []
      for (let i = 0; i < Geofence.geofence.length - 1; i++) {
        let polyPoint = { lat: Geofence.geofence[i].lat, lng: Geofence.geofence[i].lon }
        poly.push(polyPoint)
      }
      resolve(poly)
    })
  }

  sortFenceLatLng1 = (Geofence) => {
    return new Promise((resolve, reject) => {
      let poly = []
      for (let i = 0; i < Geofence.length - 1; i++) {
        let polyPoint = { lat: Geofence[i].geofence['geofence X'], lng: Geofence[i].geofence['geofence Y'] }
        poly.push(polyPoint)
      }
      resolve(poly)
    })
  }

  getFenceData = (plans) => {
    return new Promise(async (resolve, reject) => {
      for (let i = 0; i < plans.length; i++) {
        if (plans[i].geofenceData != null && plans[i].geofenceData != 'File not found in DB') {
          if (plans[i].geofenceData.geofence) {
            let planFence = await this.sortFenceLatLng(plans[i].geofenceData)
            this.state.geofenceData.push(planFence)
          } else {
            let planFence = await this.sortFenceLatLng1(plans[i].geofenceData)
            this.state.geofenceData.push(planFence)
          }
        }
      }
      resolve(true)
    })
  }

  getPlans = async () => {
    let key = "";
    if (this.state.permissions[GCS_FEATURES.PLANS].READ) {
      this.setState({
        loading: true,
        projectsLoading: true
      }, () => {
        let { pageSize, pageNumber, orderBy, orderType } = this.state.plansObj;
        let { searchString, fromDate, toDate, tableViewType } = this.state.searchHeaderObj;

        let offset = (pageNumber - 1) * 15;

        API.getPlans(this.props.location.state.project.project_name, searchString || ' ', pageSize, offset, undefined, this.props.location.state.project.id, fromDate, toDate, orderBy, orderType)
          .then(async (data) => {
            if (data && data.length == 0) {
              this.setState((state) => ({
                ...state,
                loading: false,
                geofenceData: [],
                projectsLoading: false,
                plansObj: {
                  ...this.state.plansObj,
                  allPlans: [],
                  totalPlansCount: 0
                }
              }))
            } else {
              this.setState({ geofenceData: [] })
              await this.getFenceData(data.plans)
              this.setState((state) => ({
                ...state,
                loading: false,
                projectsLoading: false,
                plansObj: {
                  ...this.state.plansObj,
                  allPlans: data.plans,
                  totalPlansCount: data.count
                }
              }))
            }
          }, (e) => {
            console.log(e);
          })
      })
    }
    else {
      this.setState({
        plans: [],
        loading: false,
        geofenceData: [],
        projectsLoading: false
      })
    }
  };

  getAllSprayingPlots = () => {

    let { pageSize, pageNumber, orderBy, orderType } = this.state.plotsObj;
    let { searchString, fromDate, toDate, tableViewType } = this.state.searchHeaderObj;

    API.getAllSprayingPlots(this.props.location.state.project.project_name, searchString || ' ', pageSize, pageNumber, fromDate, toDate, orderBy, orderType).then((plotData) => {
      if (plotData.length > 0) {
        this.setState((state) => ({
          ...state,
          // plots: plotData,
          plotsObj: { ...this.state.plotsObj, totalPlotsCount: plotData.length, allPlots: plotData },
          loading: false,
          projectsLoading: false
        }))
      } else {
        this.setState((state) => ({
          ...state,
          plotsObj: { ...this.state.plotsObj, totalPlotsCount: 0, allPlots: [] },
          loading: false,
          projectsLoading: false
        }))
      }

    })
  }

  componentWillUnmount() {
    updateOutTime(this.state.outId)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.state.notificationId != this.props.location.state.notificationId) {
      getFeaturesPermissions([GCS_FEATURES.PLANS])
        .then(permissions => {
          let { user, type, project } = this.props.location.state;
          this.setState(state => ({
            ...state,
            permissions,
            user,
            type,
            project
          }), () => {
            this.state.plotview ? this.getAllSprayingPlots() : this.getPlans();
          })
        })
    }
  }

  componentWillMount() {
    addUserAnalytic(this.props.location.pathname).then(id => this.setState({ outId: id }))
    let { user, type, project } = this.props.location.state;
    this.setState(
      (state) => ({
        ...state,
        user,
        type,
        project
      }),
      async () => {
        try {
          const role = await setActiveRoleForProject(project.id || project.project_id)
          if (!role) throw ({ message: "unauthorized access." })
          const permissions = await getFeaturesPermissions([GCS_FEATURES.PLANS])
          this.setState({ permissions }, () => {

            !this.state.plotview ? this.getPlans() : this.getAllSprayingPlots()

            this.getPlansOnMap();
            checkSubscriptionAndStorage(AppConstants.SERVICES.AEROGCS).then(data => {
              if (data.error || data.storageExceed || data.isExpired || !data.isValidStoragePlan) this.setState({ showSubscriptionExpirePopup: { ...data, user: this.props?.location?.state?.user } });
              else this.setState({ showSubscriptionExpirePopup: false, infoPopup: true });
            })
          })
        }
        catch (e) {
          console.log(e)
          window.location.replace("/");
        }
      }
    );
  }
  componentDidMount() {
    document.title = this.props.location.state.project.project_name + " - Plans - AeroGCS"
    if (this.props.location.state.plotview) {
      this.setState({
        plotview: this.props.location.state.plotview
      })
    }
    getActiveTeam().then(activeTeam => {
      this.setState({
        activeTeam: activeTeam
      }, () => {
        if (!this.props.location.state) {
          API.getUserType().then(
            ([registered, type, user]) => {
              this.setState((state) => ({
                ...state,
                loading: false,
                registered,
                type,
                user,
              }));
            },
            (e) => {
              this.setState({
                user: {},
                loading: false,
                type: "unknown",
              });
            }
          );
        }
      })
    })
  }

  changeColor = (e) => {
    e.preventDefault();
    const refs = ["home", "customer", "service"];
    refs.map((ref) => this.refs[ref].classList.remove("text-color"));
    e.target.classList.add("text-color");
    const { name } = Object.values(e.target)[1];
    this.setState((state) => ({
      ...state,
      selected: name,
    }));
  };
  render() {
    let { user, type, project, flightTye, plans, sidebarMode } = this.state;
    let projectName = project.project_name;
    const CustomSortToggle = React.forwardRef(({ children, onClick }, ref) => (
      <a
        href=""
        ref={ref}
        onClick={e => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {children}
        <div className='sort-type-style'>{this.state.sortType}</div>
      </a>
    ));

    if (this.props.location.state) {
      return user.username ? (
        <div className="wrapper">
          <div style={{ display: "none" }}>
            <Link
              ref={this.viewOnMapRef}
              style={{ color: "#707683", }}
              to={{
                pathname: "/aerogcsenterprise/remote_mission/assignDroneToPlan",
                state: {
                  user,
                  type,
                  sidebarMode: this.state.sidebarMode,
                  plan: this.state.planFromMap,
                  project
                },
              }}
            >
              <button className="add-user-form-submit-button btn-ok" >
                View
              </button>
            </Link>
          </div>
          {this.redirectToCreatePlan()}
          <div
            className="right-content-new"
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
            onClick={() => {
              this.setState((state) => ({
                ...state,
                sidebarMode: "shrunk",
              }));
            }}
          >
            <div className="top-bar-new">
              <div className="top-bar-text-new">
                <BreadCrumbs data={[
                  {
                    displayName: "Projects",
                    pathname: "/aerogcsenterprise/projects",
                    state: {
                      type,
                      user,
                    }
                  },
                  {
                    displayName: project ? project.project_name : "Plans"
                  }
                ]} />
                <ActiveRole
                  hideBrackets={true}
                />
              </div>
            </div>
            <ActivityPopup
              item={this.state.itemName}
              open={this.state.showProcessPopup}
              icon={this.state.processPopupIcon}
              action={this.state.processAction}
              msg={this.state.processMessage}
              close={() => this.closeActivityPopup()}
              onClickOk={() => this.state.plotview ? this.deletePlot() : this.deletePlan()}
            />
            {
              this.state.showSubscriptionExpirePopup ?
                <SubscriptionExpireModal
                  data={this.state.showSubscriptionExpirePopup}
                /> : <></>
            }

            <div className="main-content-new" style={{ display: "flex", gap: "20px" }}>
              <div className="gcs-plans-screen" style={{ width: "100%", display: "flex", flexDirection: "column", height: "100%" }}>
                <div style={{ display: "flex", justifyContent: 'space-between', marginBottom: "10px" }}>
                  <div style={{ display: 'flex', alignItems: "center" }}>
                    <div style={{ marginRight: "5px" }} className="services-content-title">{this.state.plotview ? "Plots" : "Plans"}
                      <span className='services-content-title'>({this.state.plotview ? this.state.plotsObj.totalPlotsCount : (this.state.plansObj.totalPlansCount || "0")})</span>
                    </div>
                    <Button
                      className={'new-btn-rounded'}
                      isDisabled={!this.state.projectsLoading ? !this.state.permissions[GCS_FEATURES.PLANS].CREATE : true}
                      icon={addIcon}
                      borderRadius={"50%"}
                      backgroundColor={"#3988F9"}
                      display={"flex"}
                      alignItems={"center"}
                      isBtnActive={true}
                      iconPadding={"2px"}
                      iconWidth={"18px"}
                      linkData={{
                        pathname: "/aerogcsenterprise/remote_mission/selectLocation",
                        state: {
                          user,
                          sidebarMode: this.state.sidebarMode,
                          projectName: project.project_name,
                          project: this.state.project
                        }
                      }}
                    />
                  </div>
                  <div style={{ display: "flex", alignItems: "center", gap: "30px" }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div className="switch_btn_container" >
                        <div className={this.state.plotview == false ? 'switch_btn_active' : 'switch_btn_inactive'}
                          style={{ borderRadius: this.state.plotview === false ? "10px 0px 0px 10px" : "10px 0px 0px 10px" }}
                          onClick={() => {
                            this.setState({ plotview: false }, () => { this.getPlans() })
                          }}
                        >
                          Plans
                        </div>
                        <div className={this.state.plotview ? 'switch_btn_active' : 'switch_btn_inactive'}
                          style={{ borderRadius: this.state.plotview ? "0px 10px 10px 0px" : "0px 10px 10px 0px" }}
                          onClick={() => {
                            this.setState({ plotview: true }, () => { this.getAllSprayingPlots() })
                          }}
                        >
                          Plots
                        </div>
                      </div>
                    </div>
                    {/* <div onClick={() => {
                          this.setState({
                            mapView: !this.state.mapView
                          })
                        }}>
                          <img src={MapIcon} alt="i am map icon" style={{ width: "30px" }} />
                        </div> */}
                  </div>
                </div>

                <SearchHeader
                  isDisabled={this.state.projectsLoading}
                  placeholder={`Search ${this.state.plotview ? "plots" : "plans"}...`}
                  onFilterDataChange={(filter) => {
                    this.setState({
                      searchHeaderObj: {
                        ...this.state.searchHeaderObj,
                        searchString: filter.searchString,
                        fromDate: filter.fromDateTime === undefined || filter.toDateTime === undefined ? undefined : new Date(filter.fromDateTime).toISOString(),
                        toDate: filter.fromDateTime === undefined || filter.toDateTime === undefined ? undefined : new Date(filter.toDateTime).toISOString(),
                        tableViewType: filter.selectView
                      },
                      plansObj: { ...this.state.plansObj, pageNumber: 1 },
                      plotsObj: { ...this.state.plotsObj, pageNumber: 1 },
                      gridView: filter.selectView === "grid"
                    }, () => {
                      if (this.state.plotview) this.getAllSprayingPlots()
                      else this.getPlans();
                    })
                  }}
                  isShowViewButtons
                  defaultView={this.state.gridView ? "grid" : "list"}
                  isShowDateTimePicker={true}
                />

                {this.state.plans.length > 0 && this.state.gridView ?
                  <>
                    <div style={{ height: "30px" }}>
                      <SortComponent
                        label={'Name'}
                        sortAscending={() => this.setState({
                          plansObj: {
                            ...this.state.plansObj,
                            orderType: 'ASC',
                            orderBy: 'plan_name'
                          }
                        }, () => { this.getPlans() })}
                        sortDecending={() => this.setState({
                          plansObj: {
                            ...this.state.plansObj,
                            orderType: 'DESC',
                            orderBy: 'plan_name'
                          }
                        }, () => { this.getPlans() })}
                        sortASC={true}
                      />
                    </div>
                  </> :
                  <></>
                }
                <Modal
                  style={{ marginTop: "150px" }}
                  show={this.state.showTaskDeletePopup}
                  onHide={() => {
                    this.setState((state) => ({
                      ...state,
                      showTaskDeletePopup: false,
                    }));
                  }}
                >
                  <Modal.Body>
                    <p>Are you sure you want to delete?</p>
                  </Modal.Body>
                  <Modal.Footer>
                    <div>
                      <button
                        className="addcustomer-button-empty-dashboard popup-btn-left btn-ok"
                        onClick={() => {
                          this.setState((state) => ({
                            ...state,
                            showTaskDeletePopup: false,
                          }));
                        }}
                      >
                        No
                      </button>
                    </div>
                    <div>
                      <button
                        className="add-user-form-submit-button popup-btn-right btn-ok"
                        onClick={this.deletePlan}
                      >
                        Yes
                      </button>
                    </div>
                  </Modal.Footer>
                </Modal>
                {this.state.plotview ?
                  <>{this.state.plotsObj.totalPlotsCount > 0 ?
                    (this.state.gridView ?
                      this.state.projectsLoading ? <LoaderComponent /> :
                        <div className="box_container">
                          {this.state.plotsObj.allPlots?.filter(plot => this.state.filter?.searchString ? plot.plot_name.toLowerCase().includes(this.state.filter.searchString.toLowerCase()) : true).map((plot, key) => {
                            return (
                              <OverviewBox
                                linkData={{
                                  pathname: "/aerogcsenterprise/remote_mission/sprayingFlightPlot",
                                  state: { user, type, sidebarMode: this.state.sidebarMode, project, plot, plotEdit: true },
                                }}
                                boxImage={plotSvg}
                                boxTitle={plot.plot_name}
                                boxSubTitle={""}
                                boxTime={plot.created_datetime}
                                activities={{
                                  disableDelete: !this.state.permissions[GCS_FEATURES.PLANS].DELETE,
                                  onClickDelete: () => {
                                    this.state.permissions[GCS_FEATURES.PLANS].DELETE &&
                                      this.setState({
                                        deltePlot: plot,
                                        itemName: "Plot",
                                        processPopupIcon: "WARNING",
                                        processAction: "DELETE",
                                        processMessage: null,
                                        showProcessPopup: true,
                                      })
                                  }
                                }}
                              />
                            );

                          })}
                        </div>
                      : <div className="plots-dashboard-table-height">
                        <TableView
                          isLoading={this.state.projectsLoading}
                          // hidePagination
                          onClickRow={(plot) => [
                            this.props.history.push("/aerogcsenterprise/remote_mission/sprayingFlightPlot", { user, type, sidebarMode: this.state.sidebarMode, project, plot, plotEdit: true })
                          ]}
                          activities={{
                            disableDelete: !this.state.permissions[GCS_FEATURES.PLANS].DELETE,
                            onClickDelete: (plot) => {
                              this.state.permissions[GCS_FEATURES.PLANS].DELETE &&
                                this.setState({
                                  deltePlot: plot,
                                  itemName: "Plot",
                                  processPopupIcon: "WARNING",
                                  processAction: "DELETE",
                                  processMessage: null,
                                  showProcessPopup: true,
                                })
                            }
                          }}
                          columns={[
                            { Header: 'Plot Name', accessor: 'plot_name', width: '30%', isSortable: true }, //isSortable: true
                            { Header: 'Date Created', accessor: 'created_datetime', width: '60%', isDate: true,   isSortable: true } //isSortable: true
                          ]}
                          data={this.state.plotsObj.allPlots.filter(plot => this.state.filter?.searchString ? plot.plot_name.toLowerCase().includes(this.state.filter.searchString.toLowerCase()) : true)}
                          user={user}
                          count={this.state.plotsObj.totalPlotsCount}
                          onStateChange={(changedState) => {
                            this.setState({
                              plotsObj: {
                                ...this.state.plotsObj,
                                pageNumber: changedState.pageNumber,
                                orderBy: changedState.orderBy,
                                orderType: changedState.orderType
                              },
                            }, () => this.getAllSprayingPlots())
                          }}
                        />
                      </div>
                    ) :
                    <div className="rmp-dashboard-empty-data-message"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        height: "80vh"
                      }}
                    >
                      <img style={{ height: '180px', width: '180px' }} src={Nopaln} />
                      <p style={{ margin: '10px' }}>No Plots available</p>
                    </div>
                  }</>
                  : <>
                    {this.state.plansObj.allPlans && this.state.plansObj.allPlans.length === 0 ?
                      this.state.projectsLoading ? <LoaderComponent /> :
                        <div className="rmp-dashboard-empty-data-message" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
                          <img style={{ height: '180px' }} src={Nopaln} />
                          <p style={{ marginTop: '10px' }}>No plan available</p>
                        </div> :
                      this.state.gridView ?
                        this.state.projectsLoading ? <LoaderComponent /> :
                          <div className="box_container">
                            {this.state.plansObj.allPlans.map((plan) => {
                              return <OverviewBox
                                linkData={{
                                  pathname: "/aerogcsenterprise/remote_mission/assignDroneToPlan",
                                  state: {
                                    user: user,
                                    project: this.state.project,
                                    plan: plan
                                  },
                                }}
                                boxImage={planSvg}
                                boxTitle={plan.plan_name}
                                boxSubTitle={plan.MissionType == 0 ? 'Waypoints' : plan.MissionType == 1 ? 'Survey' : plan.MissionType == 2 ? 'Spraying'
                                  : plan.MissionType == 3 ? 'Vertical' : plan.MissionType == 4 ? '-' : plan.MissionType == 5 ? 'Corridor'
                                    : plan.MissionType == 6 ? 'Circular' : '-'}
                                boxTime={plan.date_and_time}
                                activities={{
                                  onClickDelete: () => {
                                    if (plan.project_name != 'ManualProject' && plan.plan_name != 'SprayingPlan') {
                                      this.state.permissions[GCS_FEATURES.PLANS].DELETE &&
                                        this.setState({
                                          projectName,
                                          plan,
                                          itemName: "Plan",
                                          processPopupIcon: "WARNING",
                                          processAction: "DELETE",
                                          processMessage: null,
                                          showProcessPopup: true,
                                        });
                                    } else {
                                      this.setState({
                                        itemName: "Plan",
                                        processPopupIcon: "ERROR",
                                        processAction: "DELETE",
                                        processMessage: 'You can not delete this plan.',
                                        showProcessPopup: true,
                                      })
                                    }

                                  },
                                  canEdit: this.state.permissions[GCS_FEATURES.PLANS].EDIT,
                                  onClickEdit: (() => {
                                    this.props.history.push(plan.MissionType === "notGCSPlan" ? '/aerogcsenterprise/remote_mission/selectLocation' : plan.MissionType == 1 ? `/aerogcsenterprise/remote_mission/surveyFlightPlan` : plan.MissionType == 2 ? `/aerogcsenterprise/remote_mission/sprayingFlightPlan` : `/aerogcsenterprise/remote_mission/flightPlan`, {
                                      user,
                                      type,
                                      drawPlan: true,
                                      flightType:
                                        this.state.flightTye,
                                      planName: plan.plan_name,
                                      projectName,
                                      plan,
                                      project,
                                      editPlan: true
                                    },
                                    )
                                  }),
                                  disableDelete: !this.state.permissions[GCS_FEATURES.PLANS].DELETE
                                }}
                              />
                            })}
                            <div className="grid-view-pagination-width">
                              {!this.state.projectsLoading && <TableViewPagination
                                currentPage={this.state.plansObj.pageNumber}
                                totalCount={this.state.plansObj.totalPlansCount}
                                pageSize={this.state.plansObj.pageSize}
                                padding="10px 5px"

                                onPageChange={(page) => {
                                  this.setState({
                                    plansObj: {
                                      ...this.state.plansObj,
                                      pageNumber: page
                                    }
                                  }, () => { if (page == this.state.plansObj.pageNumber) this.getPlans() })
                                }}
                              />}
                            </div>
                          </div>
                        : <div className="plans-dashboard-table-height">
                          <TableView
                            isLoading={this.state.projectsLoading}
                            onClickRow={(row) => [
                              this.props.history.push("/aerogcsenterprise/remote_mission/assignDroneToPlan", {
                                user: this.state.user,
                                project: this.state.project,
                                plan: row
                              })
                            ]}
                            activities={{
                              onClickDelete: (plan) => {
                                if (plan.project_name != 'ManualProject' && plan.plan_name != 'SprayingPlan') {
                                  this.state.permissions[GCS_FEATURES.PLANS].DELETE &&
                                    this.setState({
                                      projectName,
                                      plan,
                                      itemName: "Plan",
                                      processPopupIcon: "WARNING",
                                      processAction: "DELETE",
                                      processMessage: null,
                                      showProcessPopup: true,
                                    });
                                } else {
                                  this.setState({
                                    itemName: "Plan",
                                    processPopupIcon: "ERROR",
                                    processAction: "DELETE",
                                    processMessage: 'You can not delete this plan.',
                                    showProcessPopup: true,
                                  })
                                }
                              },
                              canEdit: this.state.permissions[GCS_FEATURES.PLANS].EDIT,
                              onClickEdit: (plan => {
                                this.props.history.push(plan.MissionType === "-" ? '/aerogcsenterprise/remote_mission/selectLocation' : plan.MissionType == "Survey" ? `/aerogcsenterprise/remote_mission/surveyFlightPlan` : plan.MissionType == "Spraying" ? `/aerogcsenterprise/remote_mission/sprayingFlightPlan` : `/aerogcsenterprise/remote_mission/flightPlan`, {
                                  user,
                                  type,
                                  drawPlan: true,
                                  flightType:
                                    this.state.flightTye,
                                  planName: plan.plan_name,
                                  projectName,
                                  plan,
                                  project,
                                  editPlan: true
                                },
                                )
                              }),
                              disableDelete: !this.state.permissions[GCS_FEATURES.PLANS].DELETE
                            }}
                            columns={[
                              { Header: 'Plan Name', accessor: 'plan_name', width: '25%', isSortable: true },
                              { Header: 'Plan Type', accessor: 'MissionType', width: '20%', },
                              { Header: 'Date Created', accessor: 'date_and_time', width: '25%', isSortable: true, isDate: true,   },
                              { Header: 'Images', accessor: 'images', width: '20%', }
                            ]}
                            data={this.state.plansObj.allPlans.map(plan => {
                              return ({
                                ...plan,
                                MissionType: plan.MissionType == 0 ? 'Waypoints' : plan.MissionType == 1 ? 'Survey' : plan.MissionType == 2 ? 'Spraying'
                                  : plan.MissionType == 3 ? 'Vertical' : plan.MissionType == 4 ? '-' : plan.MissionType == 5 ? 'Corridor'
                                    : plan.MissionType == 6 ? 'Circular' : '-'
                              })
                            })}
                            user={user}
                            count={this.state.plansObj.totalPlansCount}
                            onStateChange={(changedState) => {
                              this.setState({
                                plansObj: {
                                  ...this.state.plansObj,
                                  pageNumber: changedState.pageNumber,
                                  orderBy: changedState.orderBy || 'date_and_time',
                                  orderType: changedState.orderType || 'DESC'
                                },
                              }, () => this.getPlans())
                            }}
                          />
                        </div>
                    }
                  </>}
              </div>
            </div>

          </div>
        </div>
      ) : (
        <div />
      );
    } else {
      return <Redirect to="/aerogcsenterprise/projects" />;
    }
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateProfileDetails: (payload) => dispatch(updateProfileDetails(payload))
  };
};

const mapStateToProps = state => {
  return {
    activeTeamId: state.profile.activeTeamId,
    activeTeam: state.profile.activeTeam,
    SubscriptionData: state.subscription.subscriptionData,
  };
};

const connectedPlans = connect(mapStateToProps, mapDispatchToProps)(Plans)

export default withRouter(connectedPlans);

