import React, { Component } from "react";
import * as API from "./api.js";
import ServicesTileTray from "./servicesTileTray";
import ServicesCard from "./ServicesCard";
import TeamsInActive from './sidebaricon/TeamsInactive.png'
import Help from "./sidebaricon/Help.png";
import Settings from "./sidebaricon/Settings.png";
import Planner from "./icons/calenderInactive.png"
import Serviceactive from "./sidebaricon/ServicesActive.png";
import "./style.css";
import Popup from "reactjs-popup";
import Loader from "./icons/loader.svg";
import downloadGCSPopup from "./downloadGCSPopup.svg";
import downloadGCSPopupCloseButton from "./downloadGCSPopupCloseButton.svg";
import downloadGCSButton from "./downloadGCSButton.svg";

// import Settings from './settings.png';

import { connect } from "react-redux";
import { updateProfileDetails, activesubscription } from "./redux";

import SideBar from "./SideBar";
import { Modal } from "react-bootstrap";
import { addUserAnalytic, updateOutTime } from './ReusableComponents/reusableFunctions'
import WelcomePopup from "./WelcomePopup.js";
import ServeyPopup from "./SurveyPopup.js";
import { ActivityPopup } from './ReusableComponents/ActivityPopup.js';
import compDashboardInactive from './icons/compDashboardInactive.png'
import { isTeamOwner } from "./Teams";
import { sideBarPaths } from "./sideBarPaths.js";

class Services extends Component {
  state = {
    user: {},
    type: "",
    leftBorder: false,
    services: [],
    loading: true,
    sidebarMode: this.props.location.state
      ? this.props.location.state.sidebarMode
      : "shrunk",
    showWelcomePopup: false,
    showServeyPopup: false,
    isAdmin: false,
    itemName: "",
    showProcessPopup: false,
    processPopupIcon: "",
    processAction: "",
    processMessage: "",
    surveyStatus: false,
    outId: "",
    isOwner: undefined
  };

  parentSidebarSwitch = () => {
    this.setState((state) => ({
      ...state,
      sidebarMode: state.sidebarMode == "shrunk" ? "expanded" : "shrunk",
    }));
  };

  getSubbedServices = async () => {
    API.getUserSubbedServices().then(
      (services) => {
        let storeServices = services[0].filter(
          (service) =>
            service.title === "Admin Tools" ||
            service.title === "AeroGCS Enterprise" ||
            service.title === "Analytic Tool"
        );
        let names = services[0].map((service) => { return service.title })
        var admin = false
        if (names.findIndex(data => data == "Admin Tools") != -1)
          admin = true
        this.checkIsOwner()
        this.setState((state) => ({
          ...state,
          services: storeServices,
          loading: false,
          isAdmin: admin
        }));
      },
      (e) => {
        this.setState({
          loading: false,
        });
      }
    );
  };

  componentDidMount() {
  }

  closeActivityPopup = () => {
    this.setState((state) => ({
      ...state,
      showProcessPopup: false,
      processMessage: null
    }))
  }

  checkIsOwner = async () => {
    this.setState({
      isOwner: await isTeamOwner()
    })
  }

  componentWillUnmount() { updateOutTime(this.state.outId) }

  componentWillMount() {
    addUserAnalytic("/").then(id => this.setState({ outId: id }))
    document.title = "Services - AeroGCS";
    localStorage.removeItem('dateUnit')
    this.checkSurveyStatus()
    this.checkIsOwner()
    if (this.props.location.state) {
      let { user, type } = this.props.location.state;
      this.props.activesubscription({
        activesubscription: undefined,
      });
      this.setState(
        (state) => ({
          ...state,
          user,
          type,
        }),
        () => {
          this.props.updateProfileDetails({
            user: user,
            type: type,
          });
          this.getSubbedServices();
          console.log("ASKDJHASK", user);
        }
      );
    } else {
      API.getUserType().then(
        ([blah, type, user]) => {
          this.setState(
            (state) => ({
              ...state,
              user,
              type,
            }),
            () => {
              this.props.updateProfileDetails({
                user: user,
                type: type,
              });
              this.getSubbedServices();
            }
          );
        },
        (e) => {
          console.log(e)
          window.location.replace("/login");
        }
      );
    }
  }

  closeDownloadGCSPopup = () => {
    document.getElementById("downloadGCSPopupTriggerButton").click();
  };

  downloadGCSFunc = () => {
    window.open(
      "https://download.aeromegh.in/api/files/latest/AeroGCS/stable",
      "_blank"
    );
  };

  submitSurvey = (data) => {
    this.setState((state) => ({
      ...state,
      showServeyPopup: false,
    }));
    let { industry, role, teamMembers, phoneNo, country } = data
    API.addUserSurvey(industry, role, teamMembers, phoneNo, country).then((data) => {
    }).catch((err) => {
      this.setState((state) => ({
        ...state,
        itemName: "",
        processPopupIcon: "ERROR",
        processAction: "ADD",
        processMessage: "Something went wrong while adding your details",
        showProcessPopup: true,
      }))
    })
  }

  checkSurveyStatus = () => {
    API.checkSurveyStatus().then((data) => {
      this.setState((state) => ({
        ...state,
        showWelcomePopup: !data.survey
      }))
    })
  }

  closeWelcomePopup = () => {
    this.setState((state) => ({
      ...state,
      showWelcomePopup: false,
      showServeyPopup: true
    }));
  };

  changeColor = (e) => {
    e.preventDefault();
    const refs = ["home", "customer", "service"];
    refs.map((ref) => this.refs[ref].classList.remove("text-color"));
    e.target.classList.add("text-color");

    const { name } = Object.values(e.target)[1];

    this.setState((state) => ({
      ...state,
      selected: name,
    }));
  };

  render() {
    console.log("sidebarpaths", sideBarPaths["/"])
    let { user, type, services, sidebarMode } = this.state;


    return user.username ? (
      <div className="wrapper">
        <div
          className="right-content-new" >
          <Popup
            trigger={
              <button
                id="downloadGCSPopupTriggerButton"
                style={{
                  display: "none",
                }}
              ></button>
            }
            modal
          >
            <div>
              <img
                src={downloadGCSPopup}
                style={{
                  height: "auto",
                  width: "100%",
                }}
              />
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  top: "-70px",
                  position: "relative",
                  height: "0px",
                }}
              >
                <img
                  src={downloadGCSButton}
                  style={{
                    margin: "auto",
                    width: "min-content",
                    cursor: "pointer",
                  }}
                  onClick={this.downloadGCSFunc}
                />
              </div>
              <div
                style={{
                  height: "0px",
                  width: "0px",
                }}
              >
                <img
                  src={downloadGCSPopupCloseButton}
                  style={{
                    width: "20px",
                    height: "20px",
                    background: "white",
                    borderRadius: "50%",
                    position: "absolute",
                    top: "-10px",
                    right: "-10px",
                    cursor: "pointer",
                  }}
                  onClick={this.closeDownloadGCSPopup}
                />
              </div>
            </div>
          </Popup>

          {/* ------- Welcome Popup ------- */}
          <WelcomePopup
            show={this.state.showWelcomePopup}
            user={user}
            close={() => {
              this.closeWelcomePopup();
            }}
          />

          <ServeyPopup
            show={this.state.showServeyPopup}
            user={user}
            submit={(data) => {
              this.submitSurvey(data);
            }}
          />
          {/* -------------------------------- */}

          {/* -------------- Activity Popup ----------------------- */}
          <ActivityPopup
            item={this.state.itemName}
            open={this.state.showProcessPopup}
            icon={this.state.processPopupIcon}
            action={this.state.processAction}
            msg={this.state.processMessage}
            close={() => this.closeActivityPopup()}
          />
          {/* --------------------------------------------------------------- */}
          <div className="top-bar-new"><div className="top-bar-text-new">SERVICES</div></div>
          {/* <TopBar user={user} sidebarMode={sidebarMode} type={type} pageTitle = {'SERVICES'}></TopBar> */}
          {this.state.loading ? (
            <div
              style={{
                height: "85vh",
                width: "91vw",
                display: "flex",
                textAlign: "center",
              }}
            >
              <img
                src={Loader}
                style={{ height: "30vh", width: "100vw", margin: "auto" }}
              />
            </div>
          ) : (
            <div className="main-content-new" style={{ background: "white" }}>
              {this.props.SubscriptionData ? (
                <></>
              ) : (
                <Modal
                  style={{ marginTop: "150px" }}
                  show={true}
                  onHide={() => {
                    window.location.replace("/login");
                  }}
                >
                  <Modal.Body>
                    <p>
                      Your don't have any active subscrpition plan, please
                      contact us at support@aeromegh.com and we will help you
                      with necessary actions.{" "}
                    </p>
                  </Modal.Body>
                  <Modal.Footer>
                    <div>
                      <button
                        className="add-user-form-submit-button popup-btn-right btn-ok"
                        onClick={() => {
                          window.location.replace("/login");
                        }}
                      >
                        Ok
                      </button>
                    </div>
                  </Modal.Footer>
                </Modal>
              )}
              {this.state.isAdmin}
              {this.state.isAdmin ?
                <ServicesTileTray
                  mainServices="true"
                  tiles={services}
                  user={user}
                  type={type}
                  sidebarMode={sidebarMode}
                /> : (
                  <ServicesCard
                    mainServices="true"
                    tiles={services}
                    user={user}
                    type={type}
                    sidebarMode={sidebarMode} />
                )}
            </div>
          )}
        </div>
      </div>
    ) : (
      <div />
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateProfileDetails: (payload) => dispatch(updateProfileDetails(payload)),
    activesubscription: (payload) => dispatch(activesubscription(payload)),
  };
};

const mapStateToProps = (state) => {
  return {
    activeTeamId: state.profile.activeTeamId,
    activeTeam: state.profile.activeTeam,
    firstTimeLoginGCSE: state.profile.firstTimeLoginGCSE,
    isLoggedInUserOwner: state.profile.isLoggedInUserOwner,
    SubscriptionData: state.subscription.subscriptionData,
    activesubscription: state.activesubscription.activesubscription,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Services);
