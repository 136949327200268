import React, { Component } from "react";
import * as API from "../api.js";
import { Link, StaticRouter, Redirect } from "react-router-dom";
import aeromeghLogo from './assets/aeromeghLogo.png'
import emailIcon from './assets/email.svg'
import lockIcon from './assets/lock.svg'
import eyeIcon from './assets/eye.svg'
import eyeOffIcon from './assets/eye-off.svg'
import { AnimatedModal } from '../ReusableComponents/AnimatedModal'
import Cookies from 'universal-cookie';
import googleIcon from './assets/google.png'
import microsoftIcon from './assets/microsoft.png'
import Loader from '../icons/loader.svg'
import { ActivityPopup } from '../ReusableComponents/ActivityPopup.js';
import CookiePolicyPopup from "./CookiePolicyPopup.js";
import LoaderComponent from "../ReusableComponents/LoaderComponent.js";
let otpInterval = null

const cookie = new Cookies()
const query = new URLSearchParams(window.location.search);
const trueEncode = Buffer.from('true').toString('base64');
const falseEncode = Buffer.from('false').toString('base64');
const trustEncode = Buffer.from('trustflag').toString('base64');
let tempCookie = cookie.get(trustEncode) != "" || cookie.get(trustEncode) !== null ? cookie.get(trustEncode) : { trustEncode: "", un: "" }

class userLogin extends Component {
    state = {
        username: "",
        password: "",
        showPassword: false,
        otpModal: false,
        timeOutFlag: false,
        otp: '',
        errorOtp: '',
        otpTimer: '',
        showOtp: false,
        loading: false,
        loader: false,
        itemName: "",
        showProcessPopup: false,
        processPopupIcon: "",
        processAction: "",
        processMessage: "",
        sendOtpLoader: false,
        verifyOtpLoader: false,
        trustThisDeviceFlag: tempCookie && tempCookie.trustEncode === trueEncode ? true : false,
        resendOtp: false,
    }

    componentWillMount() {
        if (query.get("token")) {
            if (cookie.get(trustEncode) == "" || cookie.get(trustEncode) == null) {
                cookie.set(trustEncode, JSON.stringify({ trustEncode: "", un: "" }), {
                    path: "/",
                    maxAge: 31536000
                });
            }

            this.setState((state) => ({
                ...state,
                loading: true,
            }))
            cookie.set("session_IAMAero", query.get("token"), {
                path: "/"
            });
            cookie.set('restrictLogout', "", { path: '/' })

            window.location.replace("/")
            window.location.reload()
        }
        document.title = "AeroGCS - Login";
    }

    componentDidMount() {
        setTimeout(() => {
            if (query.get("error")) {
                let msg = query.get("error") == 0 ? "Google authentication failed, please try again." : "Microsoft authentication failed, please try again."
                this.setState((state) => ({
                    ...state,
                    itemName: "Login",
                    processPopupIcon: "ERROR",
                    processAction: "",
                    processMessage: msg,
                    showProcessPopup: true,
                }))
            }
        }, 1000)

    }

    sendOtp = (sendOTP) => {
        this.setState({ showSendOTPbtn: !sendOTP })
        API.sendUserAuthOtp(this.state.username, this.state.password, this.state.sendOTPOnMobile, sendOTP).then(async (data) => {
            data.json().then(res => {
                if (data.status == 200) {
                    if (sendOTP) this.timer()
                    this.setState((state) => ({
                        ...state,
                        isMobileVerified: res?.isMobileVerified,
                        sentOTPOnMobile: res?.sentOTPOnMobile,
                        otpSent: sendOTP,
                        lastFourDigitPhone: res?.lastFourDigitPhone,
                        otpModal: true,
                        sendOtpLoader: false,
                        loader: false,
                        timeOutFlag: false,
                        resendOtp: false,
                    }))
                } else if (data.status == 400) {
                    this.setState({ error: res.message, loader: false, sendOtpLoader: false })
                } else if (data.status === 202) {
                    this.formSubmit()
                } else {
                    this.setState({ error: 'Something went wrong while sending OTP', loader: false, sendOtpLoader: false })
                }
            })
        }).catch(err => {
            console.log(err)
        })
    }

    loginUser = (e) => {
        if (e) e.preventDefault();
        if (!(this.state.loader && !this.state.error)) {
            let temp = cookie.get(trustEncode)
            this.setState({ loader: true, otpSent: false })
            if (temp && temp.trustEncode === trueEncode && temp.un === Buffer.from(this.state.username).toString('base64')) {
                this.formSubmit()
            } else {
                if (this.state.username == '') {
                    this.setState((state) => ({
                        ...state,
                        loader: false,
                        error: 'Please enter username'
                    }))
                } else if (this.state.password == '') {
                    this.setState((state) => ({
                        ...state,
                        loader: false,
                        error: 'Please enter password'
                    }))
                } else {
                    this.setState({
                        sendOtpLoader: true,
                    }, () => this.sendOtp(false))
                }
            }
        }
    }

    verifyOtp = () => {
        if (this.state.otp == '') {
            this.setState((state) => ({
                ...state,
                errorOtp: 'Please enter OTP'
            }))
        } else if (this.state.username == '') {
            this.setState((state) => ({
                ...state,
                errorOtp: 'Please enter username'
            }))
        } else if (this.state.password == '') {
            this.setState((state) => ({
                ...state,
                errorOtp: 'Please enter password'
            }))
        } else {
            this.setState((state) => ({
                ...state,
                verifyOtpLoader: true,
            }), () => {
                API.verifyOtp(this.state.username, this.state.password, this.state.otp).then((verifydata) => {
                    console.log('otpData', verifydata);
                    if (verifydata.status == 200) {
                        this.formSubmit()
                        this.setState((state) => ({
                            ...state,
                            verifyOtpLoader: false,
                        }))
                    } else if (verifydata.status == 401) {
                        this.setState((state) => ({
                            ...state,
                            verifyOtpLoader: false,
                            errorOtp: 'OTP expired'
                        }))
                    } else if (verifydata.status == 400) {
                        this.setState((state) => ({
                            ...state,
                            verifyOtpLoader: false,
                            errorOtp: 'Invalid OTP'
                        }))
                    } else {
                        this.setState((state) => ({
                            ...state,
                            verifyOtpLoader: false,
                            errorOtp: 'Something went wrong while sending OTP'
                        }))
                    }
                }, (e) => {
                    console.log(e);
                })
            })
        }
    }

    timer = () => {
        API.getOtpTimer(this.state.username).then(async (data) => {
            if (otpInterval) clearInterval(otpInterval);
            if (data.status == 200) {
                let timerData = await data.json();
                let minutes = Number(timerData.remainTime.split(':')[0])
                let seconds = Number(timerData.remainTime.split(':')[1])
                console.log('time', minutes, seconds);
                otpInterval = setInterval(() => {
                    let min = `0${minutes}`
                    let sec = seconds < 10 ? `0${seconds}` : seconds
                    this.setState((state) => ({
                        ...state,
                        otpTimer: min < 1 ? `Resend OTP in ${min} : ${sec} sec` : `Resend OTP in ${min} : ${sec} min`
                    }))
                    // document.getElementById("demo").innerHTML = "Resend OTP in  " + minutes + ":" + seconds;
                    seconds--;
                    if (seconds < 0) {
                        minutes--;
                        seconds = 59;
                        if (minutes < 0) {
                            clearInterval(otpInterval);
                            this.setState((state) => ({
                                ...state,
                                otpTimer: ``,
                                timeOutFlag: true
                            }))
                        }
                    }
                }, 1000);
            } else {
                window.location.replace('/login');
            }
        })
    }

    formSubmit = () => {
        if (this.state.username == '') {
            this.setState({ errorOtp: 'Please enter username' })
        } else if (this.state.password == '') {
            this.setState({ errorOtp: 'Please enter password' })
        } else {
            API.loginUser(this.state.username, this.state.password, "IAMAero").then((e) => {
                cookie.set("session_IAMAero", e.sess_id, {
                    path: "/"
                });
                cookie.set('restrictLogout', "", { path: '/' })
                let cookieObject = { trustEncode: this.state.trustThisDeviceFlag ? trueEncode : falseEncode, un: Buffer.from(this.state.username).toString('base64') }
                cookie.set(trustEncode, JSON.stringify(cookieObject), {
                    path: "/",
                    maxAge: 31536000
                });
                window.location.replace("/")
            }).catch((e) => {
                e.then(error => {
                    this.setState({ error: error.message || "Something went wrong...", loader: false })
                })
            })
        }
    }

    googleLogin = () => {
        window.location.replace(`${process.env.REACT_APP_SERVER_PROTOCOL}://${process.env.REACT_APP_SERVER_HOST}${process.env.REACT_APP_SERVER_PORT ? process.env.REACT_APP_SERVER_PORT : ''}/auth/google`)
    }

    microsoftLogin = () => {
        window.location.replace(`${process.env.REACT_APP_SERVER_PROTOCOL}://${process.env.REACT_APP_SERVER_HOST}${process.env.REACT_APP_SERVER_PORT ? process.env.REACT_APP_SERVER_PORT : ''}/auth/microsoft`)
    }

    closeActivityPopup = () => {
        this.setState((state) => ({
            ...state,
            showProcessPopup: false,
            processMessage: null,
        }))
    }

    render() {
        return (
            <div>
                <ActivityPopup
                    item={this.state.itemName}
                    open={this.state.showProcessPopup}
                    icon={this.state.processPopupIcon}
                    action={this.state.processAction}
                    msg={this.state.processMessage}
                    close={() => this.closeActivityPopup()}
                    onClickOk={() => this.closeActivityPopup()}
                >
                </ActivityPopup>

                {this.state.loading ? <LoaderComponent />
                    : <div className="authPage">
                        <CookiePolicyPopup />
                        <div className="EnterpriseLogoName">
                            <img src={aeromeghLogo} className="EnterpriseLogoNameImg" />
                            <div>AeroGCS<span className="EnterpriseLogoNameSpan"> Enterprise</span></div>
                        </div>
                        <AnimatedModal
                            isOpen={this.state.otpModal}
                            height="350px"
                            width="400px"
                        >
                            <div style={{ width: '100%', textAlign: 'center', fontSize: '16px', fontWeight: '500', marginTop: '25px' }}>Verify Account</div>
                            <hr style={{ width: '96%', color: 'grey', marginTop: '10px' }} />
                            {this.state.otpSent ? <div style={{ width: '100%', textAlign: 'center', lineHeight: '20px', marginTop: '10px', fontSize: '14px', fontWeight: '400' }}>
                                OTP has been sent to registered {this.state.sendOTPOnMobile ? "mobile number." : "email."}
                                {/* <br /><span style={{ color: 'red' }}>Please do not refresh page</span> */}
                            </div> :
                                <div style={{ textAlign: "left", margin: "5px 35px", fontSize: "14px" }}>You have enabled two factor authentication (2FA) for your account. Verify that it's you by using</div>}
                            <div class="login-error" id="otpErrorMsg" style={{ height: '30px', fontSize: '14px', color: 'red', marginTop: '5px' }}>{this.state.errorOtp}</div>
                            {
                                this.state.otpSent ? <>
                                    <div style={{ textAlign: 'center' }}>
                                        <img style={{ position: 'absolute', marginTop: '21px', marginLeft: '25px' }} src={lockIcon} />
                                        <input
                                            autoFocus
                                            onKeyDown={(e) => { if (e.key === 'Enter') this.verifyOtp(); }}
                                            class="text-input login-text-input" id="otp" style={{ width: '18rem' }} name="otp"
                                            type={this.state.showOtp ? "text" : "password"} onChange={(e) => {
                                                this.setState({
                                                    errorOtp: '',
                                                    otp: e.target.value
                                                })
                                            }}
                                            placeholder="OTP" maxlength="6" required />
                                        <img className="login-password-wrapper-img" id="view-password" style={{ cursor: 'pointer', top: '155px', left: '310px' }}
                                            src={this.state.showOtp ? eyeOffIcon : eyeIcon} onClick={() => {
                                                this.setState({ showOtp: !this.state.showOtp })
                                            }} />
                                    </div>
                                </> : <div
                                    style={{
                                        textAlign: "left",
                                        lineHeight: "35px",
                                        margin: "-23px 35px",
                                        fontSize: "14px"
                                    }}
                                >
                                    <input type="radio" checked={!this.state.sendOTPOnMobile} onChange={() => this.setState({ sendOTPOnMobile: false })} /> email {this.state.username}
                                    <br />
                                    {this.state.lastFourDigitPhone && <>
                                        <input type="radio" checked={this.state.sendOTPOnMobile} onChange={() => this.setState({ sendOTPOnMobile: true })} /> mobile number +91 XXXXXX{this.state.lastFourDigitPhone}
                                    </>}
                                </div>
                            }
                            <div style={{ height: '25px', fontSize: '14px', marginTop: '10px' }}>
                                {this.state.otpTimer}
                            </div>
                            <div style={{ textAlign: 'center', margin: '20px 35px' }}>
                                {this.state.otpSent ? <>
                                    {this.state.timeOutFlag === false && this.state.resendOtp === false ?
                                        <button type="button" className="loginUserButton" name="otpButton" id="otpButton" onClick={() => { this.verifyOtp() }}>Verify</button> :
                                        <button type="button" className="loginUserButton" name="resendButton" id="resendButton"
                                            style={{ opacity: this.state.resendOtp ? "0.5" : "", cursor: this.state.resendOtp ? "not-allowed" : "pointer" }}
                                            onClick={() => {
                                                if (!this.state.resendOtp) {
                                                    this.setState({ resendOtp: true })
                                                    this.sendOtp(true)
                                                }
                                            }}>{this.state.resendOtp ? 'Resending OTP' : 'Resend OTP'}</button>}
                                </> : <button type="button" className="loginUserButton" name="otpButton" id="otpButton"
                                    style={{ opacity: !this.state.showSendOTPbtn ? "0.5" : "", cursor: !this.state.showSendOTPbtn ? "not-allowed" : "pointer" }}
                                    onClick={() => { if (this.state.showSendOTPbtn) { this.sendOtp(true) } }}>{!this.state.showSendOTPbtn ? 'Sending OTP' : 'Send OTP'}</button>}
                            </div>
                        </AnimatedModal>

                        <form className="cardLogin login-card" onSubmit={this.loginUser}>
                            <div className="loginCardHeader">Welcome Back !</div>
                            <div className="login-error1">{this.state.error || ''}</div>
                            <div className="add-user-form-row1">
                                <img className="inputLeftIcon" src={emailIcon} />
                                <input autoFocus
                                    onChange={(e) => {
                                        this.setState({
                                            error: undefined,
                                            username: e.target.value.toLowerCase()
                                        })
                                    }}
                                    className="loginSignupInput" id="username" name="username" type="email"
                                    placeholder="E-mail" required />
                            </div>

                            <div className="add-user-form-row1">
                                <img className="inputLeftIcon" src={lockIcon} />
                                <input className="loginSignupInput" id="password"
                                    onChange={(e) => {
                                        this.setState({
                                            error: undefined,
                                            password: e.target.value
                                        })
                                    }}
                                    name="password" type={this.state.showPassword ? "text" : "password"}
                                    placeholder="Password" required />
                                <img className="inputRightIcon" id="view-password"
                                    src={this.state.showPassword ? eyeOffIcon : eyeIcon} onClick={() => {
                                        this.setState({ showPassword: !this.state.showPassword })
                                    }} />
                            </div>

                            <div className="login-forgot-password-wrapper">
                                <Link className="login-forgot-password" to={{
                                    pathname: "/forgotPassword",
                                }} ><u>Forgot Your Password?</u></Link>
                            </div>

                            <div className="signupAgreement">
                                <input type='checkbox' checked={this.state.trustThisDeviceFlag}
                                    onClick={() => {
                                        this.setState((state) => ({
                                            ...state,
                                            trustThisDeviceFlag: !this.state.trustThisDeviceFlag
                                        }))
                                    }}
                                /> Trust this device
                            </div>

                            <button type="submit"
                                className={this.state.loader && !this.state.error ? "loginUserButtonDisable" : "loginUserButton"}
                                name="loginButton" id="loginButton">
                                {this.state.loader && !this.state.error ? "VERIFYING..." : "LOGIN"}
                            </button>
                            <div className="signup">
                                Don't have an account?
                                <Link className="login-signup-button" style={{ marginLeft: '4px' }}
                                    to={{ pathname: "/signup", }}
                                ><u>Sign up</u></Link>
                            </div>
                            <div className="signupOrData">OR</div>
                            <div className="OauthContainer">
                                <div className="sigin-oauth" onClick={() => { this.googleLogin() }} >
                                    <img src={googleIcon} className="sigin-oauthImg" />
                                    Google
                                </div>

                                <div className="sigin-oauth" onClick={() => { this.microsoftLogin() }} >
                                    <img src={microsoftIcon} className="sigin-oauthImg" />
                                    Microsoft
                                </div>
                            </div>
                        </form>
                    </div >
                }
            </div>
        );
    }
}

export default userLogin
