import React, { Component } from 'react';
import * as API from '../../api.js';
import '../../style.css';
import "./dashboardAeroplane.css"
import LoaderComponent from "../../ReusableComponents/LoaderComponent.js"
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min.js';
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import StatCard from "../../ReusableComponents/StatCard.js"
// import { Bar } from "react-chartjs-2";
import SubscriptionExpireModal from "../../subscription/Reusable/SubscriptionExpireModal.js"
import AreaIcon from "../../assets/Acres.svg";
import DistanceIcon from "../../assets/Drones.svg";
import DroneIcon from "../../assets/Flights.svg";
import PilotIcon from "../../assets/Pilots.svg";
import NoWeeklyFlights from "../../icons/NoWeeklyFlights.png";
import { Popup, checkSubscriptionAndStorage, dragElement, getCurrentWeekDates } from '../../ReusableComponents/reusableFunctions.js';
import { Line } from "react-chartjs-2";
import { Link, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import { updateProfileDetails, activesubscription } from '../../redux/index.js'
import AeroStreamServiceActive from '../../assets/LiveStream.svg'
import SignUpInfoPopup from "../../ReusableComponents/SignUpInfoPopup.js";
import Loader from '../../icons/loader.svg';
import { addUserAnalytic, updateOutTime } from '../../ReusableComponents/reusableFunctions.js';
import {
  GoogleApiWrapper
} from "google-maps-react";
import DronePerformance from '../Components/DronePerformance.js';
import AppConstants from '../../AppConstants.js';
import { getActiveTeam } from '../../Teams/index.js';
import TableView from '../../ReusableComponents/TableView/TableView.js';
import NoContent from '../../ReusableComponents/NoContent.js';
import Cookies from 'universal-cookie';
import MapComponent from '../../MapComponent.js';
import SingleMap from '../../ReusableComponents/map/index.js';
import MakeDragable from '../../ReusableComponents/MakeDragable.js';
import EnterprisePip from '../Components/EnterprisePip.js';
import flightReplay from "../../replayVideo.svg";
import InteractiveGraph from '../Components/InteractiveGraph.js';

class DashboardAeroplane extends Component {

  state = {
    activeTeam: {},
    key: " ",
    fromDate: '1000000000',
    toDate: Date.now(),
    user: {},
    latestPlans: [],
    type: "",
    liveFlightsIdx: 0,
    flightCount: 0,
    statData: [],
    projects: [],
    isFlightLogEmpty: false,
    liveFlights: [],
    flightLogs: [],
    isLatestPlansEmpty: true,
    monthlyFlightCount: 0,
    weeklyFlightCount: [0, 0, 0, 0, 0, 0, 0],
    isUserTeamOwner: false,
    sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk"),
    statistics: {},
    droneData: {},
    options: {},
    droneHours: [],
    droneNames: [],
    startDate: "",
    endDate: "",
    loading: true,
    totalAreaCovered: 0,
    totalDistanceCovered: 0,
    pilots: [],
    center: { lat: 19.993982, lng: 73.790416 },
    selectedFlight: {},
    liveFlightsCount: 0,
    weeklyFlightLoading: true,
    droneLoading: true,
    flightLoading: true,
    planLoading: true,
    mapLoading: true,
    outId: "",
    leaseData: {},
    greenData: {},
    leaseInfoPopup: false,
    greenInfoPopup: false,
    showSubscriptionExpirePopup: false,
    infoPopup: false,
    statCardLoading: true,
    statCardLoadingStreams: true,
    weeklyDates: getCurrentWeekDates(),
    pipWindowOpen: true,
  }

  parentSidebarSwitch = () => {
    this.setState((state) => ({
      ...state,
      sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
    }))
  }

  closeSignUpPopup = () => {
    this.setState((state) => ({
      ...state,
      infoPopup: false,
    }));
    this.props.updateProfileDetails({
      firstTimeLoginGCSE: this.state.user.userid
    })
  };

  componentWillUnmount() { updateOutTime(this.state.outId) }

  componentWillMount() {
    document.title = "AeroGCS Enterprise";
    const cookies = new Cookies()
    cookies.remove('page_data', { path: '/' })
    ChartJS.register(BarElement, CategoryScale,
      LinearScale,
      PointElement,
      LineElement,
      Title,
      Tooltip,
      Legend,
      Filler
    );
    addUserAnalytic(this.props.location.pathname).then(id => this.setState({ outId: id }))
    getActiveTeam().then(activeTeam => {
      this.setState({
        activeTeam
      }, () => {
        // if (this.props.location.state) {
        //   let { user, type } = this.props.location.state;
        //   this.setState((state) => ({
        //     ...state,
        //     user,
        //     type
        //   }), () => {
        //     this.props.updateProfileDetails({
        //       user: user,
        //       type: type,
        //     });
        //     checkSubscriptionAndStorage(AppConstants.SERVICES.AEROGCS).then(data => {
        //       if (data.error || data.storageExceed || data.subscriptionExpire || !data.isValidStoragePlan || data.isExpired) this.setState({ showSubscriptionExpirePopup: { ...data, user: this.state.user } });
        //       else this.setState({ showSubscriptionExpirePopup: false, infoPopup: this.props.SubscriptionData && !(this.props.firstTimeLoginGCSE === this.state.user.userid) });
        //     })
        //     if (this.state.activeTeam.isOwner) {

        //       this.loadFlights();
        //       this.loadLatestPlans()
        //       this.loadFlightCount()
        //       this.getFlightsCompletedHours()
        //       this.getStatistics()
        //     } else {
        //       this.setState({
        //         mapLoading: false,
        //         planLoading: false,
        //         droneLoading: false,
        //         flightLoading: false,
        //         weeklyFlightLoading: false,
        //         loading: false
        //       })

        //       this.props.history.push("/aerogcsenterprise/projects", {
        //         user,
        //         type,
        //         sidebarMode: this.state.sidebarMode,
        //       })
        //     }
        //     this.getAeroStreamVideos();
        //   })
        // } else {
        API.getUserType().then(([blah, type, user]) => {
          this.setState((state) => ({
            ...state,
            user,
            type
          }), () => {
            checkSubscriptionAndStorage(AppConstants.SERVICES.AEROGCS).then(data => {
              console.log(data);
              // this.setState({ showSubscriptionExpirePopup: { ...data, user: this.state.user.user } })
              if (data.error || data.storageExceed || data.isExpired || data.subscriptionExpire || !data.isValidStoragePlan) this.setState({ showSubscriptionExpirePopup: { ...data, user: this.state.user.user } });
              else this.setState({ showSubscriptionExpirePopup: false, infoPopup: this.props.SubscriptionData && !(this.props.firstTimeLoginGCSE === this.state.user.userid) });
            })
            this.props.updateProfileDetails({
              user: user,
              type: type,
            });
            if (this.state.activeTeam.isOwner) {

              this.loadFlights();
              this.loadLatestPlans()
              this.loadFlightCount()
              this.getFlightsCompletedHours()
              this.getStatistics()
            } else {
              this.setState({
                mapLoading: false,
                planLoading: false,
                droneLoading: false,
                flightLoading: false,
                weeklyFlightLoading: false,
                loading: false
              })

              this.props.history.push("/aerogcsenterprise/projects", {
                user,
                type,
                sidebarMode: this.state.sidebarMode,
              })
            }
          })
        }, (e) => {
          window.location.replace("/login");
        })
        // }
      })
    })
  }

  getStatistics = () => {
    // this.state.activeTeam.isOwner ?
    API.getAeroGCSDashboardStatistics("", "").then((result) => {
      this.setState((state) => ({
        ...state,
        // leaseData: result.leaseData,
        // greenData: result.greenData,
        statistics: result,
        totalAreaCovered: result.totalAreaCovered,
        totalDistanceCovered: result.totalDistanceCovered,
        totalDrones: result.totalDrones,
        pilots: result.pilots ? result.pilots : [],
        endDate: this.state.toDate,
        startDate: this.state.fromDate,
        liveFlightsCount: result.totalLiveFlights,
        statCardLoading: false,
      }))
    }).catch(error => {
      Popup.alert("ERROR", error?.message || error,)
      this.setState({
        loading: false,
        statCardLoading: false,
      })
    })
    this.setState({
      loading: false,
    })
  }

  loadFlights = async () => {
    let key = this.state.key;
    let fromDate = this.state.fromDate;
    let toDate = this.state.toDate;
    let firstpage = 0;
    let userperpage = 4;
    API.getAllFlightsLogs(key == "" ? " " : key, fromDate, toDate, firstpage, userperpage, null).then(
      (data) => {
        this.setState({ flightLogsLoading: false })
        let flightLogs = [];
        if (data.logs.length > 0) {
          this.setState((state) => ({
            ...state,
            isFlightLogEmpty: false,
          }));
        } else if (data.logs.length == 0) {
          this.setState((state) => ({
            ...state,
            isFlightLogEmpty: true,
            flightLoading: false
          }));
        }
        data.logs.forEach((element, i) => {
          if (element.end_time) {
            flightLogs.push(element);
          }
          if (i == data.logs.length - 1) {
            this.setState((state) => ({
              ...state,
              flightLogs: flightLogs,
              flightLoading: false,
              flightLogsLoading: false
            }));
          }
        });
      },
      (e) => {
        this.setState((state) => ({
          ...state,
          flightLoading: false,
          flightLogsLoading: false
        }))
        Popup.alert("ERROR", e?.message || e)
      }
    )
  }

  loadLatestPlans = () => {
    API.getLatestPlans(' ', 4, 1, 'date_and_time', 'DESC', this.state.activeTeam.teamId).then(
      (data) => {
        this.setState((state) => ({
          ...state,
          planLoading: false
        }))
        let counter = 0
        let plansData = data.data && data.data.length > 0 ? data.data : data.data
        plansData.forEach((element, i) => {
          counter++
          if (i == data.data.length - 1) {
            this.setState((state) => ({
              ...state,
              latestPlans: plansData,
              isLatestPlansEmpty: !this.state.isLatestPlansEmpty,
            }));
          }
        });
      },
      (err) => {
        this.setState((state) => ({
          ...state,
          planLoading: false
        }))
        Popup.alert("ERROR", err?.message || err)
      }
    );
  };

  loadFlightCount = () => {
    this.setState({ weeklyFlightLoading: true }, () => {
      API.getWeeklyFlightsCount().then(
        (data) => {
          const data2 = async () => {
            let timeArr = getCurrentWeekDates();
            let valuesArr = data.data.weekly_count;
            this.setState({ weeklyFlightLoading: false, data: valuesArr.map((val, i) => { return { "time": (new Date(timeArr[i]).getTime() / 1000).toString(), "value": val.toString() } }) })
          }

          data2()
          this.setState(async (state) => ({
            ...state,
            monthlyFlightCount: data.data.month_count,
            // monthlyFlightCount:22,
            weeklyFlightLoading: false
          }));
        },
        (err) => {
          this.setState((state) => ({
            ...state,
            weeklyFlightLoading: false
          }))
          Popup.alert("ERROR", err?.message || err)
        }
      );
    })
  };

  getFlightsCompletedHours = () => {
    API.getAllFlightsCompletedHours(' ', 'total_flight_time', 'DESC', 1, 5).then((data) => {
      let drones = data.drones.drones
      let droneNames = []
      let hours = []
      if (drones.length < 5 && drones.length != 0) {
        for (let i = drones.length; i < 5; i++)
          drones.push({ "drone_id": "" })
      }
      for (let i = 0; i < drones.length; i++) {
        let element = drones[i]
        droneNames.push(element.drone_name ? element.drone_name : element.drone_id)
        let minutes = element.total_flight_time == undefined ? 0
          : ((element.total_flight_time.days == undefined ? 0 : element.total_flight_time.days * 24 * 60)
            + (element.total_flight_time.hours == undefined ? 0 : element.total_flight_time.hours * 60)
            + (element.total_flight_time.minutes == undefined ? 0 : element.total_flight_time.minutes)
            + (element.total_flight_time.seconds == undefined ? 0 : element.total_flight_time.seconds / 60)
            + (element.total_flight_time.milliseconds == undefined ? 0 : element.total_flight_time.milliseconds / 60000))
        hours.push(minutes == undefined ? 0.00 : `${(minutes / 60).toFixed(2)}`)
      }
      let options = {
        elements: {
          bar: {
            borderWidth: 2,
          },
        },
        responsive: true,
        plugins: {
          legend: {
            position: 'right',
          },
        },
      };
      const dronePerformance = (canvas) => {
        return {
          labels: droneNames,
          datasets: [
            {
              data: hours,
              fill: true,
              backgroundColor: '#EBA303',
              borderSkipped: false,
              borderRadius: 50,
            },
          ],
        }
      }

      this.setState((state) => ({
        ...state,
        droneData: dronePerformance(),
        options: options,
        droneHours: hours,
        droneNames: droneNames,
        dronesCompletedHours: data.drones.drones,
        droneLoading: false
      }))
    }, (err) => {
      Popup.alert("ERROR", err?.message || err)
      this.setState({ droneLoading: false })
    })
  }

  render() {

    let settings = {
      dots: false,
      autoplay: true,
      infinite: true,
      speed: 300,
      arrow: false,
    }
    const loaderComponent = <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginLeft: "4%", height: "100%", width: "100%" }}>
      <img src={Loader} style={{ height: "80px" }} />
    </div>
    const { user, type, sidebarMode, statistics, startDate, endDate, totalAreaCovered, totalDistanceCovered, pilots } = this.state;


    return (

      <div className="wrapper">
        <div className="right-content-new" >

          {
            this.state.showSubscriptionExpirePopup ?
              <SubscriptionExpireModal
                data={this.state.showSubscriptionExpirePopup}
              /> : <></>
          }


          {/* ------- Info Popup ------- */}
          {this.props.SubscriptionData && !(this.props.firstTimeLoginGCSE === user.userid) ? (
            <SignUpInfoPopup
              saveUserLogin={this.saveUserLogin}
              show={this.state.infoPopup}
              close={() => {
                this.closeSignUpPopup();
              }}
            />
          ) : (
            <></>
          )}

          <div className="top-bar-new" style={{ display: "flex", zIndex: '2' }}>
            <div className="top-bar-text-new">AeroGCS Enterprise</div>
          </div>
          <div className="main-content-new" style={{ filter: this.state.activeTeam?.isOwner ? '' : 'grayscale(100%)', background: 'none' }}>
            {this.state.flightLogs && this.state.flightLogs?.[0] && this.state.flightLogs?.[0]?.id
              && this.state.pipWindowOpen && <EnterprisePip
                flightId={this.state.flightLogs?.[0]?.id}
                Title={'Flight Replay'}
                height='200px'
                width='300px'
                dragWindow={false}
                user={this.state.user}
                onClickAction={(e) => { this.props.history.push("/aerogcsenterprise/drone_control_center/fly_view", { flight: this.state.flightLogs?.[0]?.id, user: this.state.user, replyData: e }) }}
                onCloseAction={() => { this.setState({ pipWindowOpen: !this.state.pipWindowOpen }) }}
              />}

            <div className='stat-cards-container' >
              <div style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center', height: '100%', width: '100%' }}>
                <StatCard
                  isHover={true}
                  className={'stat-flights'}
                  leftBorderColor={'#4285F4'}
                  // icon={DroneIcon}
                  iconContainer={<div className='icon-container' style={{ background: 'rgba(66, 133, 244, 0.1)', }}>
                    <img style={{ width: "100%", height: '100%' }} src={DroneIcon} className='flights-animation' />
                  </div>}
                  statCount={statistics ? statistics?.flightCount : 0}
                  statName="Flights"
                  isDisabled={!this.state.activeTeam?.isOwner}
                  width={'15vw'}
                  minWidth={'200px'}
                  maxWidth={'300px'}
                  loading={this.state.statCardLoading}
                  action={this.state.statCardLoading ? false : {
                    actionStatName: "View",
                    actionStatLink: {
                      pathname: "/aerogcsenterprise/flights",
                      state: {
                        user,
                        type,
                        sidebarMode: this.state.sidebarMode,
                        startDate,
                        endDate,
                      },
                    },
                  }}
                />

                <StatCard
                  isHover={true}
                  className={'stat-acres'}
                  leftBorderColor={'#718D4A'}
                  // icon={AreaIcon}
                  iconContainer={<div className='icon-container' style={{ background: 'rgba(113, 141, 74, 0.1)', }}>
                    <img style={{ width: "100%", height: '100%' }} className='acres-animation' src={AreaIcon} />
                  </div>}
                  statCount={statistics && statistics.totalAreaCovered ? (Number(statistics.totalAreaCovered) / 4046.85642).toFixed(4) : 0}
                  statName="Acres"
                  isDisabled={!this.state.activeTeam?.isOwner}
                  width={'15vw'}
                  minWidth={'200px'}
                  maxWidth={'300px'}
                  loading={this.state.statCardLoading}
                  action={this.state.statCardLoading ? false : {
                    actionStatName: "View",
                    actionStatLink: {
                      pathname: "/aerogcsenterprise/flights/acres",
                      state: {
                        user,
                        type,
                        sidebarMode: this.state.sidebarMode,
                        startDate,
                        endDate,
                        totalAreaCovered,
                      },
                    },
                  }}
                />

                <StatCard
                  isHover={true}
                  className={'stat-kilometers'}
                  leftBorderColor={'#EBA303'}
                  // icon={DistanceIcon}
                  iconContainer={<div className='icon-container' style={{ background: 'rgba(242, 119, 60, 0.1)', padding: '0px' }}>
                    <img style={{ width: "100%", height: '100%' }} className='drone-animation' src={DistanceIcon} />
                  </div>}
                  statCount={statistics && statistics.totalDrones ? Number(statistics.totalDrones) : 0}
                  statName="Drones"
                  isDisabled={!this.state.activeTeam?.isOwner}
                  width={'15vw'}
                  minWidth={'200px'}
                  maxWidth={'300px'}
                  loading={this.state.statCardLoading}

                  action={this.state.statCardLoading ? false : {
                    actionStatName: "View",
                    actionStatLink: {
                      pathname: "/aerogcsenterprise/drones",
                      state: {
                        user,
                        type,
                        sidebarMode: this.state.sidebarMode,
                        startDate,
                        endDate,
                        totalDistanceCovered,
                      },
                    },
                  }}
                />

                <StatCard
                  isHover={true}
                  className={'stat-pilots'}
                  leftBorderColor={'#6868B2'}
                  // icon={PilotIcon}
                  iconContainer={<div className='icon-container' style={{ background: 'rgba(104, 104, 178, 0.1)', }}>
                    <img style={{ width: "100%", height: '100%' }} className='pilots-animation' src={PilotIcon} />
                  </div>}
                  statCount={statistics?.pilots?.length}
                  statName="Pilots"
                  isDisabled={!this.state.activeTeam?.isOwner}
                  width={'15vw'}
                  minWidth={'200px'}
                  maxWidth={'300px'}
                  loading={this.state.statCardLoading}
                  action={this.state.statCardLoading ? false : {
                    actionStatName: "View",
                    actionStatLink: {
                      pathname: "/aerogcsenterprise/flights/pilots",
                      state: {
                        user,
                        type,
                        sidebarMode: this.state.sidebarMode,
                        startDate,
                        endDate,
                        pilots,
                      },
                    },
                  }}
                />

                <StatCard
                  isHover={true}
                  className={'stat-livestream'}
                  leftBorderColor={'#CF3329'}
                  // icon={AeroStreamServiceActive}
                  iconContainer={<div className='icon-container' style={{ background: 'rgba(207, 51, 41, 0.1)', }}>
                    <img style={{ width: "100%", height: '100%' }} className={this.state.liveFlightsCount > 0 && this.state.activeTeam?.isOwner ? 'livestream-animation' : ""} src={AeroStreamServiceActive} />
                  </div>}
                  statCount={this.state.liveFlightsCount}
                  statName="Live Drones"
                  width={'15vw'}
                  minWidth={'200px'}
                  maxWidth={'300px'}
                  loading={this.state.statCardLoading}
                />

              </div>
            </div>

            <div className='flex-center' style={{ margin: "1.5% 0%", justifyContent: "space-between", alignItems: 'start', gap: '', height: 'auto' }}>
              {/* drone performance */}
              <DronePerformance isDisabled={!this.state.activeTeam.isOwner} loading={this.state.droneLoading} drones={this.state.droneHours.map((hr, i) => ({ hoursUsed: hr, name: this.state.droneNames[i] }))} redirectTo={{
                pathname: "/aerogcsenterprise/remote_mission/flights_completed_hours",
                state: {
                  user,
                  type,
                  sidebarMode: this.state.sidebarMode,
                },
              }} />

              {/* weekly flights */}
              <div className='table-box-large' style={{ overflow: "hidden", padding: 0, position: "relative" }}>
                <div style={{
                  width: "fit-content",
                  position: "absolute",
                  right: "20px",
                  zIndex: "2",
                  top: "10px",
                  cursor: "pointer"
                }}
                  onClick={(e) => {
                    this.props.history.push("/aerogcsenterprise/flights", {
                      user,
                      type,
                      sidebarMode: this.state.sidebarMode,
                      startDate,
                      endDate,
                    })
                  }}
                ><span className="view-all">View All</span></div>
                {this.state.weeklyFlightLoading ? loaderComponent : <InteractiveGraph
                  data={this.state?.data || []}
                  lineType={0}
                  title={'Weekly Flights'}
                  titleStyle={{
                    color: "#3c3c3c",
                    width: "fit-content",
                    fontSize: "14px",
                    fontWeight: "500"
                  }}
                  style={{ height: "100%", margin: '0px' }}
                />}
              </div>

            </div>


            <div className='flex-center' style={{ margin: "1.5% 0%", justifyContent: "space-between", alignItems: 'start', gap: '', height: 'auto' }}>
              {/* flights */}
              <div className='table-box-large' style={{ padding: '0px' }}>
                <div className='table-box-large-header'>
                  <div className="table-header">Recent Flights</div>
                  {this.state.flightLogs?.length > 0 && this.state.activeTeam.isOwner && <Link
                    to={{
                      pathname: "/aerogcsenterprise/flights",
                      state: {
                        user,
                        type,
                        sidebarMode: this.state.sidebarMode,
                      },
                    }}
                  >
                    <span className="view-all">View All</span>
                  </Link>}
                </div>
                {this.state.flightLogs.length > 0 ?
                  <TableView
                    showSrNo
                    isLoading={this.state.flightLogsLoading}
                    onStateChange={() => { }}
                    onClickRow={flight => {
                      this.props.history.push("/aerogcsenterprise/remote_mission/flight_details",
                        {
                          ...flight,
                          user,
                          flightId: flight.id,
                          planName: flight.plan_name,
                          projectName: flight.project_name,
                          date: flight.end_time,
                          startTime: flight.start_time,
                          end_time: flight.end_time,
                          streamKey: flight.streamkey,
                          areaCovered: (Number(flight.area_covered) / 4046.85642).toFixed(4),
                          areaCoveredMethod: flight.area_calculation_method,
                          distanceCovered: flight.distance_covered,
                          droneId: flight.drone_id,
                          firmwareVersion: flight.firmware_version_number,
                          greenBuildNumber: flight.green_build_number,
                          droneName: flight.drone_name,
                          droneUIN: flight.uin,
                          software_edition: flight.software_edition
                        }
                      )
                    }}
                    onClickLink={(flight) => {
                      this.props.history.push("/aerogcsenterprise/drone_control_center/fly_view", { flight: flight.id, user: this.state.user, replyData: 0 })
                    }}
                    data={this.state.flightLogs?.map((flight) => {
                      return ({
                        ...flight,
                        link_data: { img: flightReplay }
                      })
                    }) || []}
                    columns={[
                      { Header: "Project", accessor: "project_name", width: '22%', },
                      { Header: "Plan", accessor: "plan_name", width: '22%', },
                      { Header: "Start Time", accessor: "start_time", width: '22%', isDate: true, },
                      { Header: "End Time", accessor: "end_time", width: '22%', isDate: true, },
                      { Header: "Replay", accessor: "link_data", width: '22%', isLink: true, isCenter: true },
                    ]}
                  />
                  : <NoContent />}
              </div>

              {/* recent plans */}
              <div className='table-box-large' style={{ padding: '0px' }}>
                <div className='table-box-large-header' >
                  <div className="table-header" >Recent Plans</div>
                  {this.state.latestPlans?.length > 0 && this.state.activeTeam.isOwner && <Link
                    to={{
                      pathname: "/aerogcsenterprise/allFlightPlans",
                      state: {
                        user,
                        type,
                        sidebarMode: this.state.sidebarMode,
                        activeTeamId: this.state.activeTeam.teamId
                      },
                    }}
                  >
                    <span className="view-all">View All</span>
                  </Link>}
                </div>

                {this.state.latestPlans.length > 0 ? <TableView
                  isLoading={this.state.planLoading}
                  onStateChange={() => { }}
                  onClickRow={log => {
                    this.props.history.push("/aerogcsenterprise/remote_mission/assignDroneToPlan",
                      {
                        user,
                        type,
                        sidebarMode: this.state.sidebarMode,
                        plan: { "plan_name": log.plan_name, "id": log.id },
                        project: { "project_name": log.project_name, "id": log.project_id }
                      },)
                  }}
                  showSrNo
                  data={this.state.activeTeam.isOwner ? this.state.latestPlans : []}
                  columns={[
                    { Header: "Plan Name", accessor: "plan_name", width: '35px', },
                    { Header: "Project Name", accessor: "project_name", width: '35px' },
                    { Header: "Date", accessor: "date_and_time", width: '25px', isDate: true, }
                  ]}
                /> : <NoContent />}
              </div>
            </div>
          </div>
        </div >
      </div >
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateProfileDetails: (payload) => dispatch(updateProfileDetails(payload)),
    activesubscription: (payload) => dispatch(activesubscription(payload))
  };
};

const mapStateToProps = state => {
  return {
    SubscriptionData: state.subscription.subscriptionData,
    activesubscription: state.activesubscription.activesubscription,
    isLoggedInUserOwner: state.profile.isLoggedInUserOwner,
    firstTimeLoginGCSE: state.profile.firstTimeLoginGCSE,
  };
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyBuxfUtRZSEUT2QrbZA8zUsG58lZUYYmwI",
  libraries: ["drawing", "places"],
  LoadingContainer: () => <LoaderComponent height={'90vh'} />,
})(connect(mapStateToProps, mapDispatchToProps)(withRouter(DashboardAeroplane)));
