import React, { useState, useEffect, useRef } from 'react';
import dropDownIcon from "../icons/dropDownAerrow.svg";
import Tippy from '@tippyjs/react';
import Loader from '../icons/loader.svg'

const CreateSelect = ({
    options,
    value,
    onChange,
    onClick,
    allowCreate,
    stopLoading,
    containerHeight
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [inputValue, setInputValue] = useState(value?.label || "");
    const [optionList, setOptionList] = useState(options || []);
    const [filteredOptions, setFilteredOptions] = useState(options || []);
    const [selectedOption, setSelectedOption] = useState(value || null);
    const [showCreateOption, setShowCreateOption] = useState(false)
    const [loading, setLoading] = useState(false)

    const selectedRef = useRef(null)
    const dropdownRef = useRef(null)

    const handleInputChange = (e) => {
        const value = e.target.value;

        setIsOpen(true)
        setInputValue(value.slice(0, 50));
        setShowCreateOption(allowCreate
            && value.length > 1
            && !optionList.some(opt => opt.label.toLowerCase() == value.toLowerCase())
        )
        const matchingOptions = optionList.filter(opt =>
            opt.label.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredOptions(matchingOptions);
    };

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setInputValue(option.label);
        setIsOpen(false);
        if (onChange) onChange(option);
    };

    const handleCreateNewOption = () => {
        const newOption = { label: inputValue, value: inputValue };
        setOptionList(prevOptions => [...prevOptions, newOption]);
        setLoading(true)
        setSelectedOption(newOption);
        setInputValue(newOption.label);
        if (onChange) onChange({ ...newOption, __isNew__: true });
        setIsOpen(false);
    };

    const handleInputBlur = () => {
        if (!selectedOption || selectedOption.label !== inputValue) {
            setInputValue(value?.label || "");
        }
    };

    useEffect(() => {
        setInputValue(value?.label || null);
        setSelectedOption(value || null);
    }, [value, selectedOption]);

    useEffect(() => {
        setOptionList(options)
        if (isOpen) {
            setShowCreateOption(false)
            setFilteredOptions(optionList);
        }
        else {
            handleInputBlur()
        }
    }, [isOpen, optionList, options]);

    useEffect(() => {
        setLoading(false)
    }, [stopLoading])

    return (<Tippy
        visible={isOpen}
        animation={false}
        interactive={true}
        maxWidth={'100%'}
        // placement={'bottom'}
        popperOptions={{
            modifiers: [
                {
                    name: "flip",
                    options: {
                        fallbackPlacements: [], // Prevent unnecessary flipping
                    },
                },
                // {
                //     name: "preventOverflow",
                //     options: {
                //         boundary: "viewport", // Prevent dropdown from overflowing viewport
                //         altAxis: true, // Adjusts placement dynamically if necessary
                //     },
                // },
            ],
        }}

        onClickOutside={() => setIsOpen(false)}
        onShown={(instance) => {
            document.querySelector('[data-tippy-root]').addEventListener('click', event => {
                instance.hide();
                setIsOpen(false);
            });
        }}
        onShow={instance => {
            if (dropdownRef.current) {
                const { width, bottom } = dropdownRef.current.getBoundingClientRect();
                instance.popper.style.maxWidth = `${width}px`;
                instance.popper.style.minWidth = `${width}px`;

                const windowHeight = window.innerHeight;
                console.log('windowHeight - bottom', windowHeight - bottom);
                if ((windowHeight - bottom) < 200) {
                    instance.props.placement = 'top'
                } else {
                    instance.props.placement = 'bottom'
                }
            }

            const el = document.querySelector(".scroll-container");
            if (el)
                el.addEventListener('scroll', event => {
                    instance.hide();
                    setIsOpen(false);
                });
        }}
        className='tippyMenuDropDown'
        arrow={false}
        content={
            <div title="" size="sm" className='createSelectOptionStyle' >
                {filteredOptions.length > 0 && (filteredOptions.map((opt, index) => (
                    <div eventKey={opt.value} ref={selectedOption?.value === opt.value ? selectedRef : null}
                        className={`createSelectSingleOptionStyle ${selectedOption?.value === opt.value ? 'selected' : ''}`}
                        onClick={() => handleOptionClick(opt)}
                    >{opt.label}</div>
                )))}
                {showCreateOption ? <div className='createSelectSingleOptionStyle createNewOption' onClick={handleCreateNewOption}>Create new option: "{inputValue}"</div>
                    : filteredOptions.length == 0 && <div className='createSelectSingleOptionStyle createNewOption'>No Options</div>}
            </div >
        }
    >
        <div className='createSelectDropdown' style={{ height: containerHeight || '' }} ref={dropdownRef} onClick={(e) => { e.preventDefault(); e.stopPropagation(); setIsOpen(!isOpen); if (onClick) { onClick() } }} >
            {loading ? <div style={{ width: '100%', height: '100%', overflow: 'hidden', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <img src={Loader} style={{ width: "100%", height: '100%' }} />
            </div> : <>
                <input className='createSelectInputFieldStyle' type='text' placeholder='Select Option' value={inputValue} onChange={(e) => { handleInputChange(e) }} />
                <img src={dropDownIcon} alt="dropdown icon" />
            </>}
        </div >
    </Tippy >)
};

export default CreateSelect;