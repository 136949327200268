import React, { Component } from 'react';
import SideBar from '../SideBar';
import DatePicker from 'react-datepicker';
import ButtonWithIcon from '../ReusableComponents/ButtonWithIcon';
import DropDownWithButton from '../ReusableComponents/DropDownWithButton';
import * as API from '../api';
import { SetRestrictSesssionExpire } from "../helperComponents/OnIdleHandlerWrapper";
import { getActiveTeamId } from '../Teams';
import { AnimatedModal } from '../ReusableComponents/AnimatedModal';
import { CircularProgressbar } from 'react-circular-progressbar';
import { Modal } from 'react-bootstrap';
import { ActivityPopup } from '../ReusableComponents/ActivityPopup';
import teams from "../sidebaricon/TeamsInactive.png";
import planner from "../assets/sideBarIcons/PlanInactive.png";
import setting from "../sidebaricon/Settings.png";
import help from "../sidebaricon/Help.png";
import incidentActive from '../icons/incidentActive.png'
import clock from "../icons/clock.png"
import calendar from "../icons/calendar.png"
import { setHours, setMinutes } from 'date-fns';
import { convertInMbGB } from '../ReusableComponents/reusableFunctions';

let prevLoadedData = 0;
let chunkSize = 10 * 1024 * 1024;

class NewIncident extends Component {
  state = {
    name: "",
    incidentTimeDate: new Date(),
    department: "",
    contactNumber: "",
    location: "",
    details: "",
    droneId: "",
    pilotId: "",
    targetFilesArr: "",
    uploadedFileId: undefined,
    fileName: '',
    currentFileIndex: null,
    lastUploadedFileIndex: null,
    currentChunkIndex: null,
    show: false,
    showCancelPopup: false,
    item: undefined,
    itemName: undefined,
    processPopupIcon: undefined,
    processMessage: undefined,
    showProcessPopup: false,
    showContactWarning: false,
    uploadedData: 0,
    uploadFileSize: 0,
  }

  componentWillMount() {
    document.title = "Incident Report";

    API.gcsDrones().then((droneData) => {
      console.log("drone data ", droneData);
      this.setState({
        droneIdList: droneData.drones.length > 0 ? droneData.drones.map(drone => ({ 'name': drone.drone_id, onClick: () => { this.setState({ droneId: drone.drone_id }) } })) : [
          {
            name: "No drones found",
            onClick: () => { }
          }
        ]
      })
      // console.log("drone data : ", droneData.drones.map(drone => ({ 'id': drone.id })))
    })

    this.setState({
      user: this.props.location.state.user
    });
  }

  cancelUpload = () => {
    prevLoadedData = 0;
    this.setState({
      isCancel: true,
      showCancelPopup: false,
      show: false,
      targetFilesArr: [],
      fileName: '',
      toShowProgressOf: 0,
      lastUploadedFileIndex: null,
      uploadedCount: 0,
      currentChunkIndex: null,
      currentFileIndex: null,
      uploadCheckPopup: false
    })
  }

  onClickListenerFile = (event) => {
    event.target.value = ''
    this.setState({
      uploadedData: 0,
      uploadFileSize: 0,
    })
  }


  uploadChunk = (readerEvent, fileName, fileSize, currentChunkIndex, currentFileIndex) => {
    const data = new FormData()
    data.append('file', readerEvent)
    data.append('name', fileName);
    data.append('size', fileSize);
    data.append('currentChunkIndex', currentChunkIndex);
    data.append('totalChunks', Math.ceil(fileSize / chunkSize));
    data.append('incidentId', this.state.uploadedFileId);

    API.uploadIncidentFile(data).then(response => {
      response.json().then((res) => {
        console.log('response', response);
        const chunks = fileSize / chunkSize;
        if (response.status === 200) {
          if (currentChunkIndex === Math.ceil(chunks - 1)) {
            prevLoadedData = 0
            this.setState({
              uploadedData: fileSize,
              uploadFileSize: fileSize,
              lastUploadedFileIndex: currentFileIndex,
              currentChunkIndex: null,
              toShowProgressOf: ((currentFileIndex + 1) * 100) / this.state.targetFilesArr.length,
              uploadedCount: (currentFileIndex + 1)
            }, () => {
              if (this.state.uploadedCount === this.state.targetFilesArr.length) {
                SetRestrictSesssionExpire(false);
              }
            })
          } else {
            let fileUploadedPercentage = currentChunkIndex / chunks * 100
            this.setState({
              uploadedData: this.state.uploadedData + chunkSize,
              uploadFileSize: fileSize,
              toShowProgressOf: this.state.toShowProgressOf < 100 ? this.state.toShowProgressOf + 100 / ((this.state.targetFilesArr.length * 100) / (fileUploadedPercentage - prevLoadedData)) : 100//this.state.toShowProgressOf + (100 / ((this.state.file.length * 100) / fileUploadedPercentage))
            }, () => {
              this.setState({ currentChunkIndex: currentChunkIndex + 1 })
              prevLoadedData = fileUploadedPercentage;
              console.log("Upload % : ", this.state.toShowProgressOf);
              if (this.state.toShowProgressOf < 0 || this.state.toShowProgressOf === 100) {
                console.log("this is minus value");
                prevLoadedData = 0
                this.setState({ toShowProgressOf: 0, targetFilesArr: '', fileName: '' });
              }
            })
          }
        } else if (response.status == 500) {
          this.readAndUploadCurrentChunk();
        } else {
          this.setUploadFailedError(res.message || "something went wrong while uploading image.")
        }
      })
    })
      .catch(error => {
        console.log("catch error : ", error);
        this.setState({
          targetFilesArr: [],
          item: "Report file",
          processPopupIcon: "ERROR",
          itemName: "Report file",
          processMessage: "Error while uploading incident file !",
          showProcessPopup: true,
        })
        this.cancelUpload();
      });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentChunkIndex !== this.state.currentChunkIndex && this.state.currentChunkIndex !== null && !this.state.isCancel) {
      this.readAndUploadCurrentChunk();
    }

    if (prevState.currentFileIndex !== this.state.currentFileIndex) {
      if (this.state.currentFileIndex !== null) {
        this.setState({ currentChunkIndex: 0 })
      }
    }

    if (prevState.lastUploadedFileIndex !== this.state.lastUploadedFileIndex) {
      if (this.state.lastUploadedFileIndex === null) {
        return;
      }
      const isLastFile = this.state.lastUploadedFileIndex === this.state.targetFilesArr.length - 1;
      const nextFileIndex = isLastFile ? null : this.state.currentFileIndex + 1;
      this.setState({ currentFileIndex: nextFileIndex })
    }
  }

  readAndUploadCurrentChunk = () => {
    const file = this.state.targetFilesArr[this.state.currentFileIndex];
    if (!file) {
      return;
    } else {
      chunkSize = chunkSize > file.size ? file.size : chunkSize
      const from = this.state.currentChunkIndex * chunkSize;
      const to = from + chunkSize;
      const blob = file.slice(from, to);
      this.uploadChunk(blob, file.name, file.size, this.state.currentChunkIndex, this.state.currentFileIndex);
    }
  }

  addIncidentReport = async () => {
    API.saveIncidentReport({
      name: this.state.name,
      incidentTimeDate: this.state.incidentTimeDate,
      department: this.state.department,
      contactNumber: this.state.contactNumber,
      location: this.state.location,
      details: this.state.details,
      droneId: this.state.droneId,
      pilotId: this.state.pilotId,
      teamId: (await getActiveTeamId())
    }).then(data => {
      console.log("saved data : ", data)
      this.setState({
        uploadedFileId: data.incidentLog.id,
        name: "",
        incidentTimeDate: new Date(),
        department: "",
        contactNumber: "",
        location: "",
        details: "",
        droneId: "",
        pilotId: "",
      }, () => {

        if (!this.state.targetFilesArr) {
          this.setState({
            item: "COMPLETE",
            itemName: "COMPLETE",
            processPopupIcon: 'COMPLETE',
            processMessage: 'Report saved successfully !',
            showProcessPopup: true,
          })
        }

        this.uploadFiles();
      })
    })
      .catch(e => {
        console.log(e)
        this.setState({
          item: "Report",
          processPopupIcon: "ERROR",
          itemName: "Report",
          processMessage: "Error while saving report !",
          showProcessPopup: true,
        })
      })
  }

  close() {
    if (this.state.uploadedCount !== this.state.targetFilesArr.length) {
      this.setState((prevState) => ({
        ...prevState,
        showCancelPopup: true
      }));
    } else {

      if (this.state.uploadedCount === this.state.targetFilesArr.length) {
        SetRestrictSesssionExpire(false);
        this.setState({
          item: "COMPLETE",
          itemName: "COMPLETE",
          processPopupIcon: 'COMPLETE',
          processMessage: 'Report saved successfully !',
          showProcessPopup: true,
        });
      }

      this.setState((prevState) => ({
        ...prevState,
        show: this.state.toShowProgressOf >= 100 ? false : true,
        targetFilesArr: [],
        fileName: '',
        toShowProgressOf: 0,
        lastUploadedFileIndex: null,
        uploadedCount: 0,
        currentChunkIndex: null,
        currentFileIndex: null,
      }));
    }

  }

  uploadFiles = async () => {
    if (this.state.targetFilesArr) {
      if (this.state.currentFileIndex === null) {
        SetRestrictSesssionExpire(true)
        this.setState({
          currentFileIndex: this.state.lastUploadedFileIndex === null ? 0 : this.state.lastUploadedFileIndex + 1,
          toShowProgressOf: 0,
          uploadFileSize: this.state.targetFilesArr[0]?.size,
          show: true,
          uploadCheckPopup: false,
          activityPopup: undefined,
          isCancel: false,
          uploadedCount: 0
        });
      }
    }
  }

  onSelectFiles = (event) => {
    console.log("selected file :", event.target.files[0].name);
    this.setState({
      fileName: event.target.files[0].name,
      targetFilesArr: event.target.files
    })
    chunkSize = Math.round(navigator.connection.downlink * 1024 * 1024)
  }


  importFiles = () => {
    const fileImporter = document.getElementById("fileImporter")
    fileImporter.click()
  }

  closeActivityPopup = () => {
    this.setState({
      showProcessPopup: false,
    });
  }


  render() {
    let { user, type, name, incidentTimeDate, department, contactNumber, location, details, droneId, pilotId, droneIdList } = this.state
    let sidebarConfigurations = {
      options: [
        // {
        //   icon: service,
        //   text: "Services",
        //   path: "/"
        // },
        {
          icon: teams,
          text: "Teams",
          path: "/teams",
        },
        {
          icon: planner,
          text: "Planner",
          path: "/planner",
        },
        {
          icon: incidentActive,
          text: "Incident Report",
          path: "/aerogcsenterprise/incidentReport",
          selected: true,
        },
        {
          icon: setting,
          text: "Settings",
          path: "/profile-settings",
        },
        {
          icon: help,
          text: "Help",
          path: "/feedback",
        },
        // {
        //     icon: TeamsActive,
        //     text: "Teams",
        //     path: "/teams",
        //     selected: true,
        // },
      ],
      user,
      type,
      mode: this.state.sidebarMode,
    }

    return (

      <>
        <input
          id='fileImporter'
          style={{ display: 'none' }}
          type="file"
          onClick={(event) => this.onClickListenerFile(event)}
          onChange={(e) => this.onSelectFiles(e)}
        />

        <ActivityPopup
          item={this.state.item}
          open={this.state.showProcessPopup}
          icon={this.state.processPopupIcon}
          action={this.state.processAction}
          msg={this.state.processMessage}
          close={() => this.closeActivityPopup()}
          onClickOk={() => this.setState({ showProcessPopup: false })}
        />

        <AnimatedModal
          isOpen={this.state.show}
          onRequestClose={() => this.hide()}
          height="300px"
          width="450px"
        >
          <div>
            <div className="services-content-title" style={{ textAlign: 'center', marginTop: '15px' }}>{this.state.toShowProgressOf == 100 ? "Upload Completed !" : this.props.fileType == "PntCld" ? "Uploading File..." : "Uploading Image..."}</div>
            <div style={{}}>
              <div style={{ textAlign: 'center', marginTop: "4%", marginBottom: "2%" }}>
                <div style={{ width: 175, height: 175, margin: 'auto', fontSize: "10px" }}>
                  <CircularProgressbar
                    value={this.state.toShowProgressOf}
                    text={`${convertInMbGB(this.state.uploadedData)}/${convertInMbGB(this.state.uploadFileSize)}`}
                    styles={{
                      text: {
                        fill: '#2989cf',
                        fontSize: '9px',
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div style={{ paddingLeft: "250px" }}>
            <ButtonWithIcon
              text={this.state.uploadedCount === this.state.targetFilesArr.length ? "Close" : "Cancel"}
              width="94px" isBtnActive
              onClick={() => this.close()}
            />
          </div>

        </AnimatedModal>

        <Modal
          style={{ marginTop: '150px' }} showCancelPopup
          show={this.state.showCancelPopup}
          onHide={() => {
            this.setState((state) => ({
              ...state,
              showCancelPopup: false,
            }));
          }}
        >
          <Modal.Body >
            <p>Your file upload is still in progress, Are you sure you want to cancel it?</p>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <button
                className="addcustomer-button-empty-dashboard popup-btn-left btn-ok"
                onClick={() => {
                  this.setState((state) => ({
                    ...state,
                    showCancelPopup: false,
                    show: true
                  }));
                }}
              >
                No
              </button>
            </div>
            <div>
              <button
                className="add-user-form-submit-button popup-btn-right btn-ok"
                onClick={() => {
                  this.cancelUpload()
                }}
              >
                Yes
              </button>
            </div>
          </Modal.Footer>
        </Modal>


        {user.username ?
          <div className="wrapper">
            <SideBar
              config={sidebarConfigurations}
              showMainDashboardIcons={this.state.showBackIcon}
              parentSidebarSwitch={this.parentSidebarSwitch}
            />
            <div className="right-content"
              onClick={
                () => {
                  this.setState((state) => ({
                    ...state, sidebarMode: 'shrunk'
                  }))
                }
              }
            >
              <div className="top-bar"><div className="top-bar-text">{"Report"}</div></div>
              <div className='main-content' style={{ display: 'flex', justifyContent: 'center' }} >
                <div style={{ display: "flex", flexDirection: "column", width: "859px", }}>
                  <div style={{ borderTopLeftRadius: "5px", borderTopRightRadius: "5px", backgroundColor: "white", height: "40px", outline: "1px solid #c6c6c6", padding: "10px", fontSize: "16px", color: "black", borderBottom: "1px solid #dbdbdb", textAlign: "left" }}>{"New Incident Report"}
                    {/* <div style={{ position: "absolute", right: "17px", top: "17px", width: "20px", height: "20px", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}
                  // onClick={closeTaskAssigner}
                  ><img style={{ width: "100%", height: "100%" }} src={Close} /></div> */}
                  </div>
                  <div style={{ padding: "0px 44px", display: "flex", flexDirection: "column", paddingBottom: "20px", outline: "1px solid #c6c6c6", backgroundColor: "rgb(243 243 243)", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px" }}>
                    <div style={{ padding: "10px", display: "flex", flexDirection: "column", height: "540px" }}>

                      {/* Details Section */}
                      <div style={{ width: "100%" }}>
                        <div style={{ fontSize: "16px", textAlign: "left" }}>Details</div>
                      </div>

                      <div style={{ display: "flex", justifyContent: "space-between" }}>

                        <div style={{ margin: "3px 10px 0px 0px", }}>
                          <div style={{ fontSize: "13px", textAlign: "left" }}>Name <span style={{ color: "red" }}>*</span></div>
                          <input
                            type="text"
                            maxLength={35}
                            className="add-user-form-text "
                            onChange={(e) => {
                              let regex = /^[a-zA-Z]*$/;
                              if (regex.test(e.target.value)) {
                                this.setState({
                                  name: e.target.value
                                })
                              }
                            }}
                            name="name"
                            value={name}
                            style={{ width: '345px' }}
                          // disabled
                          />
                        </div>

                        <div style={{ margin: "3px 10px 0px 45px", }}>
                          <div style={{ fontSize: "13px", textAlign: "left" }}>Date  <span style={{ color: "red" }}>*</span></div>
                          <div style={{ display: "flex", justifyContent: "center", backgroundColor: "white", margin: "2px 0 0 0", height: "30px", outline: "1px solid #b4b4bb", borderRadius: "5px", textAlign: "left", padding: "0 5px" }}>
                            <div style={{ display: "flex", alignItems: 'center', }}>
                              <img src={calendar} style={{ height: "17px", margin: "0 15px 0 7px" }} />
                              <DatePicker
                                selected={incidentTimeDate}
                                // minDate={new Date()}
                                dateFormat="dd/MM/yyyy"
                                maxDate={new Date().setHours(0, 0, 0, 0)}
                                onChange={(date) =>
                                  this.setState({
                                    incidentTimeDate: new Date(date)
                                  })
                                }
                                customInput={<input style={{ backgroundColor: "transparent", border: "none", width: "100%", outline: "none", fontSize: "13px" }} />} />
                            </div>

                          </div>
                        </div>
                        <div style={{ margin: "3px 10px 0px 45px", }}>
                          <div style={{ fontSize: "13px", textAlign: "left" }}>Time  <span style={{ color: "red" }}>*</span></div>
                          <div style={{ display: "flex", justifyContent: "center", backgroundColor: "white", margin: "2px 0 0 0", height: "30px", outline: "1px solid #b4b4bb", borderRadius: "5px", textAlign: "left", padding: "0 5px" }}>
                            <div style={{ display: "flex", alignItems: 'center' }} >
                              <img src={clock} style={{ height: "17px", margin: "0 15px 0 7px" }} />
                              <DatePicker
                                selected={incidentTimeDate} dateFormat="h:mm aa"

                                minTime={new Date(2000, 5, 3)}
                                maxTime={new Date(incidentTimeDate).getDate() == new Date().getDate() && new Date(incidentTimeDate).getMonth() == new Date().getMonth() && new Date(incidentTimeDate).getFullYear() == new Date().getFullYear() ? new Date() : setHours(setMinutes(new Date(), 59), 23)}
                                // new Date(selectedDate).getDate() <= new Date().getDate() && new Date(selectedDate).getMonth() <= new Date().getMonth() && new Date(selectedDate).getFullYear() <= new Date().getFullYear() ? setHours(setMinutes(new Date(), 59), 23) : ""
                                onChange={(time) => {
                                  const maxTime = (new Date(incidentTimeDate).getDate() == new Date().getDate() && new Date(incidentTimeDate).getMonth() == new Date().getMonth() && new Date(incidentTimeDate).getFullYear() == new Date().getFullYear() ? new Date() : setHours(setMinutes(new Date(), 59), 23))
                                  this.setState({ incidentTimeDate: (time > maxTime) ? maxTime : time })
                                }}
                                showTimeSelect
                                showTimeSelectOnly
                                customInput={<input style={{ backgroundColor: "transparent", border: "none", width: "100%", outline: "none", fontSize: "13px" }} />}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ margin: "8px 10px 0px 0px", }}>
                          <div style={{ fontSize: "13px", textAlign: "left" }}>Department  <span style={{ color: "red" }}>*</span></div>
                          <input
                            type="text"
                            maxLength={35}
                            className="add-user-form-text "
                            onChange={(e) => {
                              this.setState({
                                department: e.target.value
                              })
                            }}
                            name="Department"
                            value={department}
                            style={{ width: '345px' }}
                          // disabled
                          />
                        </div>

                        <div style={{ margin: "8px 10px 0px 45px", }}>
                          <div style={{ fontSize: "13px", textAlign: "left" }}>Contact No  <span style={{ color: "red" }}>*</span> <span style={{ fontSize: "10px", color: "red" }}>{(this.state.showContactWarning && !(this.state.contactNumber.length == 10)) ? (this.state.contactNumber.length == 0) ? `` : `Phone number should be 10 digit !` : ``}</span></div>
                          <input
                            type="number"
                            maxLength={10}
                            className="add-user-form-text "
                            onChange={(e) => {

                              const regex = /^\d{10}$/; // Regex to allow up to 10 digits   

                              this.setState({
                                showContactWarning: regex.test(e.target.value) ? false : true,
                                contactNumber: e.target.value.length > 10 ? this.state.contactNumber : e.target.value
                              })

                            }}

                            name="contactNumber"
                            value={contactNumber}
                            style={{ width: '345px' }}
                          // disabled
                          />
                        </div>
                      </div>
                      {/* Details Section End */}

                      {/* Description Section */}
                      <div style={{ margin: "20px 0 0 0" }}>
                        <div style={{ fontSize: "16px", textAlign: "left" }}>Description </div>
                      </div>

                      <div style={{ margin: "3px 10px 0px 0px", }}>
                        <div style={{ fontSize: "13px", textAlign: "left" }}>Location</div>
                        <div style={{ display: "flex ", justifyContent: "spacebetween" }}>
                          <input
                            type="text"
                            maxLength={40}
                            className="add-user-form-text "
                            onChange={(e) => {
                              this.setState({
                                location: e.target.value
                              })
                            }}
                            name="location"
                            value={location}
                            style={{ width: '345px' }}
                          // disabled
                          />
                        </div>
                      </div>

                      <div style={{ margin: "10px 0px 0px 0px" }}>
                        <div style={{ fontSize: "13px", textAlign: "left" }}>Incident Details  <span style={{ color: "red" }}>*</span></div>
                        <textarea style={{ fontSize: "13px", backgroundColor: "white", outline: "1px solid #c6c6c6", borderRadius: "5px", minHeight: "75px", maxHeight: "75px", textAlign: "left", padding: "8px", width: "744px", border: "none" }}
                          onChange={(e) => {
                            this.setState({
                              details: e.target.value
                            })
                          }}
                          name="details"
                          value={details}
                        ></textarea>
                      </div>

                      <div style={{ margin: "10px 10px 0px 0px", }}>
                        <div style={{ fontSize: "13px", textAlign: "left" }}>Upload File (Images, Video, Logs)</div>
                        <div style={{ display: "flex ", justifyContent: "spacebetween" }}>
                          <div onClick={() => this.importFiles()}>
                            <input
                              type="text"
                              value={this.state.fileName}
                              maxLength={25}
                              className="add-user-form-text "
                              onChange={(e) => {
                                this.setState({
                                  incidentFile: e.target.files[0]
                                })
                              }}
                              name="file"
                              style={{ width: '345px' }}
                            // disabled
                            />
                          </div>
                          <div style={{ margin: "4px 0 0 5px" }}>
                            <ButtonWithIcon text="Browse"
                              onClick={() => this.importFiles()}
                            />
                          </div>
                        </div>
                      </div>

                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ margin: "10px 0px 0px 0px", }}>
                          <div style={{ fontSize: "13px", textAlign: "left" }}>Drone Id</div>
                          <div style={{ backgroundColor: "white", outline: "1px solid #c6c6c6", borderRadius: "4px" }}>
                            <div style={{ display: "flex", height: "34px" }}>
                              <input
                                type="text"
                                maxLength={40}
                                className="add-user-form-text "
                                onChange={(e) => {
                                  this.setState({
                                    droneId: e.target.value
                                  })
                                }}
                                name="location"
                                value={droneId}
                                style={{ border: "1px solid white", width: '315px' }}
                              // disabled
                              />
                              <div>
                                {/* <img src={dropdownLogo} style={{
                                  height: "11px",
                                  margin: "11px 0px 0 20px"
                                }} /> */}
                                <DropDownWithButton
                                  // width={'345px'}
                                  listLeft="-316px"
                                  listWidth="345px"
                                  listHeight="70px"
                                  margin="7px 0 0 0"
                                  list={
                                    droneIdList
                                  }
                                />
                              </div>
                            </div>
                            {/* <DropDownWithButton
                              textAlign={"left"}
                              width={"100%"}
                              height={"32px"}
                              // list={getPrioritiesList()}
                              selectedItem={droneId}
                            /> */}
                          </div>
                        </div>

                        <div style={{ margin: "10px 8px 0px 0px" }}>
                          <div style={{ fontSize: "13px", textAlign: "left" }}>Pilot name</div>
                          <div style={{ backgroundColor: "white", outline: "1px solid #c6c6c6", borderRadius: "4px" }}>
                            <div style={{ display: "flex", height: "34px" }}>
                              <input
                                type="text"
                                maxLength={35}
                                className="add-user-form-text "
                                onChange={(e) => {
                                  this.setState({
                                    pilotId: e.target.value
                                  })
                                }}
                                name="location"
                                value={pilotId}
                                style={{ border: "1px solid white", margin: "0 6px 0 0", width: '335px' }}
                              // disabled
                              />
                              <div>
                                {/* <img src={dropdownLogo} style={{
                                  height: "11px",
                                  margin: "11px 0px 0 20px"
                                }} /> */}
                                {/* <DropDownWithButton
                                  // width={'345px'}
                                  listLeft="-310px"
                                  listWidth="345px"
                                  margin="10px"
                                  list={
                                    [
                                      {
                                        name: "option1", onClick: () => {
                                          this.setState({
                                            pilotId: "option1"
                                          })
                                        }
                                      },
                                      {
                                        name: "option2", onClick: () => {
                                          this.setState({
                                            pilotId: "option2"
                                          })
                                        }
                                      },
                                      {
                                        name: "option3", onClick: () => {
                                          this.setState({
                                            pilotId: "option3"
                                          })
                                        }
                                      },
                                    ]
                                  }
                                /> */}
                              </div>
                            </div>
                            {/* <DropDownWithButton
                              textAlign={"left"}
                              width={"100%"}
                              height={"32px"}
                              // list={getPrioritiesList()}
                              selectedItem={droneId}
                            /> */}
                          </div>
                        </div>
                      </div>
                      {/* Description Section End */}

                      <div style={{ display: "flex", justifyContent: "right", width: "100%", margin: "25px 0 0 0", gap: "20px" }}>
                        <div style={{ fontSize: "16px", textAlign: "right" }}>
                          <ButtonWithIcon
                            text="Cancel"
                            isBtnActive
                            linkData={{
                              pathname: "/aerogcsenterprise/incidentReport",
                              state: { user: this.props.location.state.user }
                            }}
                          />
                        </div>
                        <div style={{ fontSize: "16px", textAlign: "right" }}>
                          <ButtonWithIcon
                            onClick={() => { this.addIncidentReport() }}
                            text="submit"
                            isBtnActive
                            isDisabled={!((this.state.name) && (this.state.incidentTimeDate) && (this.state.department) && (this.state.contactNumber.length == 10) && (this.state.details))}
                          />
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div > : <div />
        }
      </>
    );
  }
}
export default (NewIncident);

