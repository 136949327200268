import React, { Component } from "react";
import * as API from "../api.js";
import "../style.css";
import { addUserAnalytic, updateOutTime } from '../ReusableComponents/reusableFunctions.js';
import deleteIcon from "../trash.svg";
import dateFormat from 'dateformat'
import editIcon from "../edit.svg";
import { Link, Redirect } from "react-router-dom";
import Loader from '../icons/loader.svg';
import { AnimatedModal } from '../ReusableComponents/AnimatedModal'
import { connect } from "react-redux";
import { updateProfileDetails } from '../redux'
import { ActivityPopup } from '../ReusableComponents/ActivityPopup.js';
import Dropdown from 'react-bootstrap/Dropdown';
import MenuDark from '../icons/MenuDark.png';
import noLeaseData from '../icons/noLeaseData.png'
import noInvoices from '../icons/noInvoices.png'
import LoaderComponent from "../ReusableComponents/LoaderComponent.js";
import { DeleteComponentTable } from "../ReusableComponents/Menu.js";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href=""
        ref={ref}
        onClick={e => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
        <img src={MenuDark} style={{ height: "14px" }} alt="I am alt" />
    </a>
));


class userCustomerInfo extends Component {
    state = {
        user: {},
        type: "",
        loading: true,
        sidebarMode: this.props.location.state
            ? this.props.location.state.sidebarMode
            : "shrunk",
        itemName: "",
        showProcessPopup: false,
        processPopupIcon: "",
        processAction: "",
        processMessage: "",
        userCustomer: {},
        drones: [],
        addLeaseDronePopUp: false,
        fromDate: '',
        toDate: '',
        selectedDrone: '',
        lease: [],
        edit: false,
        editLease: '',
        minDate: new Date(),
        selectedDroneName: '',
        invoices: [],
        invoiceLoading: true,
        leaseLoading: true,
        leaseOnPopUp: false,
        leaseDetailsSelect: {},
    };

    getAllInvoices = () => {
        console.log('inget invoices');
        API.getLeaseInvoices(this.state.userCustomer.id, null).then((leaseResult) => {
            console.log("invoice data", leaseResult);
            this.setState(state => ({
                ...state,
                invoices: leaseResult.invoiceData,
                loading: false,
            }), () => {
                this.setState({ invoiceLoading: false })
            })
        }, (e) => {
            console.log(e)
        })
    }


    componentWillUnmount() { updateOutTime(this.state.outId) }

    parentSidebarSwitch = () => {
        this.setState((state) => ({
            ...state,
            sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
        }))
    }
    closeActivityPopup = () => {
        this.setState((state) => ({
            ...state,
            showProcessPopup: false,
            processMessage: null,
        }))
    }

    getAllUserCustomer = () => {
        API.getAllUserCustomer('lease', 0).then((data) => {
            console.log(data);
            if (data.usersCustomers && data.usersCustomers.length > 0) {
                data.usersCustomers.map((cust) => {
                    if (cust.id == this.props.location.state.userCustomer.id) {
                        this.setState((state) => ({
                            ...state,
                            userCustomer: cust
                        }), () => {
                            this.getDronesData()
                            this.getAllUserDronesLease()
                        })
                    }
                })
            } else {
                this.setState((state) => ({
                    ...state,
                    userCustomer: {}
                }))
            }
        }, (e) => {
            console.log(e)
        })
    }

    getDronesData = () => {
        API.gcsDrones().then((result) => {
            this.setState(state => ({
                ...state,
                drones: result?.drones?.filter(drone => drone.is_registered == true),
                loading: false
            }))
        }, (e) => {
            console.log(e)
        })
    }

    onChangeListenerDrone = (e) => {
        e.preventDefault();
        let key = e.target.value
        console.log(key);
        if (e.target.value == -1) {
            this.setState((state) => ({
                ...state,
                selectedDrone: {},
                selectedDroneName: -1,
            }))
        } else {
            this.setState((state) => ({
                ...state,
                selectedDrone: this.state.drones[key],
                selectedDroneName: key,
            }))
        }
    }

    addUserCustomerDroneLease = () => {
        this.setState((state) => ({
            ...state,
            addLeaseDronePopUp: false,
            itemName: "Lease",
            processPopupIcon: "WAIT",
            processAction: "ADD",
            processMessage: null,
            showProcessPopup: true,
        }), () => {
            API.addUserCustomerDroneLease(this.state.userCustomer.id, this.state.selectedDrone.drone_id, this.state.fromDate, this.state.toDate, this.state.selectedDrone.drone_rent)
                .then((leaseRes) => {
                    console.log('leaseRes', leaseRes);
                    if (leaseRes.message == "OK") {
                        this.setState((state) => ({
                            ...state,
                            addLeaseDronePopUp: false,
                            itemName: "Lease",
                            processPopupIcon: "COMPLETE",
                            processAction: "ADD",
                            processMessage: 'Lease added sucessfully',
                            showProcessPopup: true,
                            fromDate: '',
                            toDate: '',
                            selectedDroneName: '',
                            selectedDrone: '',
                        }), () => {
                            this.getAllUserDronesLease()
                        })
                    } else {
                        this.setState((state) => ({
                            ...state,
                            addLeaseDronePopUp: false,
                            itemName: "Lease",
                            processPopupIcon: "ERROR",
                            processAction: "ADD",
                            processMessage: leaseRes.message,
                            showProcessPopup: true,
                        }))
                    }
                }, (e) => {
                    this.setState((state) => ({
                        ...state,
                        addLeaseDronePopUp: false,
                        itemName: "Lease",
                        processPopupIcon: "ERROR",
                        processAction: "ADD",
                        processMessage: 'Something went wrong while adding lease.',
                        showProcessPopup: true,
                    }))
                })
        })
    }

    getAllUserDronesLease = () => {
        API.getAllUserDronesLease(this.state.userCustomer.id, null).then((leaseData) => {
            console.log('leaseData', leaseData);
            this.setState(state => ({
                ...state,
                lease: leaseData.usersCustomersDroneLease,
                leaseLoading: false
            }))
        }, (e) => {
            console.log(e)
        })
    }

    deleteUserCustomerDroneLease = () => {
        this.setState((state) => ({
            ...state,
            addCustomerPopUp: false,
            itemName: "Lease",
            processPopupIcon: "WAIT",
            processAction: "ADD",
            processMessage: null,
            showProcessPopup: true,
        }), () => {
            API.deleteUserCustomerDroneLease(this.state.deleteLeaseId).then((deleteRes) => {
                console.log('deleteRes', deleteRes);
                if (deleteRes.message == "OK") {
                    this.setState((state) => ({
                        ...state,
                        addCustomerPopUp: false,
                        itemName: "Lease",
                        processPopupIcon: "COMPLETE",
                        processAction: "DELETE",
                        processMessage: 'Lease deleted sucessfully',
                        showProcessPopup: true,
                        deleteUserCust: ''
                    }), () => {
                        this.getAllUserDronesLease()
                    })
                } else {
                    this.setState((state) => ({
                        ...state,
                        addCustomerPopUp: false,
                        itemName: "Lease",
                        processPopupIcon: "ERROR",
                        processAction: "DELETE",
                        processMessage: deleteRes.message,
                        showProcessPopup: true,
                    }))
                }
            }, (e) => {
                this.setState((state) => ({
                    ...state,
                    addCustomerPopUp: false,
                    itemName: "Customer",
                    processPopupIcon: "ERROR",
                    processAction: "DELETE",
                    processMessage: 'Something went wrong while deleting lease.',
                    showProcessPopup: true,
                }))
            })
        })
    }

    updateUserCustomerDroneLease = () => {
        this.setState((state) => ({
            ...state,
            addLeaseDronePopUp: false,
            itemName: "Lease",
            processPopupIcon: "WAIT",
            processAction: "UPDATE",
            processMessage: null,
            showProcessPopup: true,
        }), () => {
            API.updateUserCustomerDroneLease(this.state.fromDate, this.state.toDate, this.state.editLease.id, this.state.editLease.drone_id, this.state.selectedDrone.drone_rent)
                .then((leaseRes) => {
                    console.log('leaseRes', leaseRes);
                    if (leaseRes.message == "OK") {
                        this.setState((state) => ({
                            ...state,
                            addLeaseDronePopUp: false,
                            itemName: "Lease",
                            processPopupIcon: "COMPLETE",
                            processAction: "UPDATE",
                            processMessage: 'Lease updated sucessfully',
                            showProcessPopup: true,
                            fromDate: '',
                            toDate: '',
                            edit: false,
                            editLease: '',
                            selectedDroneName: '',
                            selectedDrone: '',
                        }), () => {
                            this.getAllUserDronesLease()
                        })
                    } else {
                        this.setState((state) => ({
                            ...state,
                            addLeaseDronePopUp: false,
                            itemName: "Lease",
                            processPopupIcon: "ERROR",
                            processAction: "UPDATE",
                            processMessage: leaseRes.message,
                            showProcessPopup: true,
                        }))
                    }
                }, (e) => {
                    this.setState((state) => ({
                        ...state,
                        addLeaseDronePopUp: false,
                        itemName: "Lease",
                        processPopupIcon: "ERROR",
                        processAction: "UPDATE",
                        processMessage: 'Something went wrong while updating lease.',
                        showProcessPopup: true,
                    }))
                })
        })
    }

    componentWillMount() {
        addUserAnalytic(this.props.location.pathname).then(id => this.setState({ outId: id }))
        document.title = "User-Customer";
        if (this.props.location.state) {
            let { user, type, userCustomer } = this.props.location.state;
            let todaysDate = new Date()
            let currentDate = dateFormat(todaysDate, "yyyy-mm-dd")
            this.setState((state) => ({
                ...state,
                user,
                type,
                userCustomer,
                // fromDate: currentDate,
                // toDate: currentDate,
            }), () => {
                this.getAllUserCustomer()
                this.getAllInvoices()
            })
        } else {
            API.getUserType().then(([blah, type, user]) => {
                this.setState((state) => ({
                    ...state,
                    user,
                    type
                }))
            }, (e) => { window.location.replace("/login") })
        }
    }




    render() {
        let { user, type, } = this.state;


        // if (this.props.location.state) {
        return <div className="wrapper">
            <div
                className="right-content-new">
                <div className="top-bar-new">
                    <div className="top-bar-text-new">User Customer</div>
                </div>
                <ActivityPopup
                    item={this.state.itemName}
                    open={this.state.showProcessPopup}
                    icon={this.state.processPopupIcon}
                    action={this.state.processAction}
                    msg={this.state.processMessage}
                    close={() => this.closeActivityPopup()}
                    onClickOk={() => this.deleteUserCustomerDroneLease()}
                />

                <AnimatedModal
                    isOpen={this.state.addLeaseDronePopUp}
                    height="350px"
                    width="500px"
                >
                    <div style={{ width: "100%", height: "100%", padding: '10px 20px' }}>
                        <div className="services-content-title"
                            style={{
                                margin: "10px 0px",
                                textAlign: "center",
                                color: "#3C3C3C",
                            }}
                        >
                            Add Drone On Lease
                        </div>
                        <hr style={{ margin: '5px 0px 0px 0px', padding: '0px', width: '100%' }} />
                        <div style={{ margin: '15px 20px 0px 20px', display: 'flex', justifyContent: 'space-between', height: '75px', }}>
                            <div style={{ textAlign: "left", width: '100%', }}>
                                <div className="content-text">Select Drone</div>
                                <select
                                    onChange={(event) => this.onChangeListenerDrone(event)}
                                    className="add-user-form-text2"
                                    value={this.state.selectedDroneName}
                                    style={{ width: "100%", cursor: 'pointer' }}
                                    disabled={this.state.edit}
                                >
                                    <option key={-1} value={-1}>Select Drone For Lease</option>
                                    {this.state.drones.map((drone, i) => {
                                        return <option key={i} value={i}>{drone.drone_id}</option>
                                    })}
                                </select>
                            </div>
                        </div>

                        <div style={{ margin: '5px 20px 0px 20px', display: 'flex', justifyContent: 'space-between', height: '75px', }}>
                            <div style={{ textAlign: "left", width: '45%', }}>
                                <div className="content-text" >Start Date</div>
                                <div>
                                    <input
                                        id="dateRequired"
                                        name="dateRequired"
                                        className="add-user-form-text"
                                        type="date"
                                        max={this.state.toDate ? dateFormat(this.state.toDate, 'yyyy-mm-dd') : '9000-01-01'}
                                        min={dateFormat(this.state.minDate, 'yyyy-mm-dd')}
                                        defaultValue={this.state.fromDate}
                                        onChange={(e) => {
                                            let value = e.target.value;
                                            this.setState((state) => ({
                                                ...state,
                                                fromDate: value,
                                            }))
                                        }}
                                        value={this.state.fromDate}
                                        style={{
                                            height: "auto",
                                            borderRadius: '10px',
                                            paddingLeft: '5px',
                                            width: "auto",
                                        }}
                                    />
                                </div>
                            </div>

                            <div style={{ textAlign: "left", width: '45%', }}>
                                <div className="content-text" >End Date</div>
                                <div>
                                    <input
                                        id="dateRequired"
                                        name="dateRequired"
                                        defaultValue={this.state.toDate}
                                        className="add-user-form-text"
                                        type="date"
                                        max='9000-01-01'
                                        min={dateFormat(this.state.fromDate ? this.state.fromDate : this.state.minDate, 'yyyy-mm-dd')}
                                        onChange={(e) => {
                                            let value = e.target.value;
                                            this.setState((state) => ({
                                                ...state,
                                                toDate: value,
                                            }))
                                        }}
                                        value={this.state.toDate}
                                        style={{
                                            height: "auto",
                                            borderRadius: '10px',
                                            paddingLeft: '5px',
                                            width: "auto",
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', margin: '30px 20px', }}>
                            <button
                                className="addcustomer-button-empty-dashboard popup-btn-left"
                                onClick={() => {
                                    this.setState((state) => ({
                                        ...state,
                                        addLeaseDronePopUp: false,
                                        selectedDroneName: '',
                                        selectedDrone: '',
                                        fromDate: '',
                                        toDate: '',
                                        edit: false,
                                        editLease: '',
                                    }));
                                }}
                            >
                                cancel
                            </button>
                            {this.state.fromDate && this.state.toDate && this.state.selectedDrone ? <button
                                className="add-user-form-submit-button popup-btn-right btn-ok"
                                onClick={() => {
                                    this.state.edit ? this.updateUserCustomerDroneLease() : this.addUserCustomerDroneLease()
                                }}
                            >
                                Save
                            </button> : <button style={{ opacity: '0.5', cursor: 'auto' }}
                                className="add-user-form-submit-button popup-btn-right btn-ok">
                                Save
                            </button>}
                        </div>
                    </div>
                </AnimatedModal>
                {/* <div className="top-bar">
                    
                </div> */}
                {/* --------------------------------------------------------------- */}
                {this.state.loading ? <LoaderComponent /> :
                    <div className="main-content-new">
                        <AnimatedModal
                            isOpen={this.state.leaseOnPopUp}
                            height="400px"
                            width="600px"
                        >
                            <div style={{ width: "100%", height: "100%", padding: '10px 40px' }}>
                                <div
                                    style={{
                                        marginTop: "10px",
                                        textAlign: "left",
                                        fontSize: "15px",
                                        fontWeight: "500",
                                        color: "#3C3C3C",
                                    }}
                                >
                                    Lease Details
                                </div>
                                <hr style={{ margin: '5px 0px 0px 0px', padding: '0px', width: '100%' }} />

                                <div style={{ margin: '15px 0px', display: 'flex', width: '100%' }}>
                                    <div style={{ width: '60%' }}>
                                        <div style={{ textAlign: "left", fontSize: '14px', color: '#666666' }}>Drone Id</div>
                                        <div style={{ textAlign: "left", fontSize: '16px', }}>{this.state.leaseDetailsSelect.drone_id}</div>
                                    </div>
                                    <div style={{ width: '40%' }}>
                                        <div style={{ textAlign: "left", fontSize: '14px', color: '#666666' }}>Mob. No.</div>
                                        <div style={{ textAlign: "left", fontSize: '16px', }}>{this.state.leaseDetailsSelect.contact_number}</div>
                                    </div>
                                </div>

                                <div style={{ margin: '15px 0px', display: 'flex', width: '100%' }}>
                                    <div style={{ width: '60%' }}>
                                        <div style={{ textAlign: "left", fontSize: '14px', color: '#666666' }}>Name</div>
                                        <div style={{ textAlign: "left", fontSize: '16px', }}>{this.state.leaseDetailsSelect.customer_name}</div>
                                    </div>
                                    <div style={{ width: '40%' }}>
                                        <div style={{ textAlign: "left", fontSize: '14px', color: '#666666' }}>Status</div>
                                        <div style={{ textAlign: "left", fontSize: '16px', }}>{this.state.leaseDetailsSelect.status}</div>
                                    </div>
                                </div>

                                <div style={{ margin: '15px 0px', display: 'flex', width: '100%' }}>
                                    <div style={{ width: '60%' }}>
                                        <div style={{ textAlign: "left", fontSize: '14px', color: '#666666' }}>GST No.</div>
                                        <div style={{ textAlign: "left", fontSize: '16px', }}>{this.state.leaseDetailsSelect.gst_no ? this.state.leaseDetailsSelect.gst_no : <span>&nbsp;&nbsp;&nbsp;-</span>}</div>
                                    </div>
                                    <div style={{ width: '40%' }}>
                                        <div style={{ textAlign: "left", fontSize: '14px', color: '#666666' }}>Date</div>
                                        <div style={{ textAlign: "left", fontSize: '16px', }}>{dateFormat(this.state.leaseDetailsSelect.startdate, 'dd/mm/yyyy')}  To  {dateFormat(this.state.leaseDetailsSelect.enddate, 'dd/mm/yyyy')}</div>
                                    </div>
                                </div>

                                <div style={{ margin: '15px 0px', display: 'flex', width: '100%' }}>
                                    <div style={{ width: '100%' }}>
                                        <div style={{ textAlign: "left", fontSize: '14px', color: '#666666' }}>Address</div>
                                        <div style={{ textAlign: "left", fontSize: '16px', }}>{this.state.leaseDetailsSelect.address}</div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', bottom: '25px', width: '85%', position: 'absolute', justifyContent: 'end', }}>
                                    <div className="addcustomer-button-empty-dashboard"
                                        style={{ cursor: "pointer", padding: "8px 38px", background: "white", }}
                                        onClick={() => {
                                            this.setState((state) => ({
                                                ...state,
                                                leaseOnPopUp: false,
                                                leaseDetailsSelect: {},
                                                invoiceType: false,
                                                withGst: false,
                                                totalValue: 0,
                                                gst: 18,
                                                discount: 0,
                                            }));
                                        }}
                                    >
                                        Close
                                    </div>
                                </div>
                            </div>
                        </AnimatedModal>
                        <div
                            className="page"
                            style={{
                                padding: "0px",
                                height: "auto",
                                minHeight: "0",
                                width: "100%",
                                marginTop: '10px'
                            }}
                        >
                            <div
                                className="rpas-model-info-top-section"
                                style={{ padding: "10px 50px" }}
                            >
                                <div style={{ width: '100%' }} className="rpas-model-info-primary-details">
                                    <div style={{ marginTop: '8px' }} className="rpas-model-info-primary-details-title-row">
                                        <div className="services-content-title">
                                            {this.state.userCustomer.customer_name}
                                        </div>
                                        <div
                                            className="drones-table-actions"
                                            style={{ marginRight: "0px" }}
                                        >
                                            {this.state.user.subs.length == 0 ? (
                                                <div
                                                    className="add-user-form-submit-button"
                                                    style={{
                                                        cursor: "default",
                                                        backgroundColor: "#666666",
                                                    }}
                                                >
                                                    Lease Drone
                                                </div>
                                            ) : (
                                                <div
                                                    className="add-user-form-submit-button"
                                                    onClick={() => {
                                                        this.setState({
                                                            addLeaseDronePopUp: true
                                                        })
                                                    }}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    Lease Drone
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="rpas-model-info-bottom-section"
                                style={{ paddingBottom: "10px" }}
                            >
                                <div style={{ display: 'flex', paddingTop: '10px' }}>
                                    <div className="rpas-model-info-primary-details-row">
                                        <div className="rpas-model-info-primary-details-title">
                                            Name:
                                        </div>
                                        <div className="rpas-model-info-primary-details-value">
                                            {this.state.userCustomer.customer_name}
                                        </div>
                                    </div>
                                    <div className="rpas-model-info-primary-details-row">
                                        <div className="rpas-model-info-primary-details-title">
                                            Contact Number:
                                        </div>
                                        <div className="rpas-model-info-primary-details-value">
                                            {this.state.userCustomer.contact_number}
                                        </div>
                                    </div>
                                </div>

                                <div style={{ display: 'flex' }}>
                                    <div className="rpas-model-info-primary-details-row">
                                        <div className="rpas-model-info-primary-details-title">
                                            Address:
                                        </div>
                                        <div className="rpas-model-info-primary-details-value">
                                            {this.state.userCustomer.address ? this.state.userCustomer.address : '-'}
                                        </div>
                                    </div>
                                    <div className="rpas-model-info-primary-details-row">
                                        <div className="rpas-model-info-primary-details-title">
                                            Email:
                                        </div>
                                        <div className="rpas-model-info-primary-details-value">
                                            {this.state.userCustomer.email ? this.state.userCustomer.email : '-'}
                                        </div>
                                    </div>
                                </div>

                                <div style={{ display: 'flex' }}>
                                    <div className="rpas-model-info-primary-details-row">
                                        <div className="rpas-model-info-primary-details-title">
                                            Government Id Number:
                                        </div>
                                        <div className="rpas-model-info-primary-details-value">
                                            {this.state.userCustomer.government_id ? this.state.userCustomer.government_id : '-'}
                                        </div>
                                    </div>
                                    <div className="rpas-model-info-primary-details-row">
                                        <div className="rpas-model-info-primary-details-title">
                                            Pilot Id Number:
                                        </div>
                                        <div className="rpas-model-info-primary-details-value">
                                            {this.state.userCustomer.pilot_license_id ? this.state.userCustomer.pilot_license_id : '-'}
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <div className="rpas-model-info-primary-details-row">
                                        <div className="rpas-model-info-primary-details-title">
                                            GST Number:
                                        </div>
                                        <div className="rpas-model-info-primary-details-value">
                                            {this.state.userCustomer.gst_no ? this.state.userCustomer.gst_no : '-'}
                                        </div>
                                    </div>
                                    {/* <div className="rpas-model-info-primary-details-row">
                                        <div className="rpas-model-info-primary-details-title">
                                            Pilot Id Number:
                                        </div>
                                        <div className="rpas-model-info-primary-details-value">
                                            {this.state.userCustomer.pilot_license_id ? this.state.userCustomer.pilot_license_id : '-'}
                                        </div>
                                    </div> */}
                                </div>
                            </div>

                        </div>

                        <div style={{ display: 'flex', height: 'calc(100vh - 490px)', width: '100%', overflow: 'hidden', padding: '20px 0px 0px 0px', }}>
                            {this.state.leaseLoading ?
                                <div style={{ display: 'flex', border: '1px solid #C2C2C2', backgroundColor: "#FAFAFA", alignItems: 'center', borderRadius: "5px", width: '50%', marginTop: '10px', marginRight: '1%' }}>
                                    <img src={Loader} style={{ height: "50%", width: "100%", marginLeft: '5%' }} />
                                </div> :
                                <div style={{ width: '50%', marginRight: '1%' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div className="table-header">Lease</div>
                                    </div>
                                    <div style={{ border: '1px solid #C2C2C2', background: '#FAFAFA', borderRadius: '5px', marginTop: '10px', height: 'calc(100% - 35px)', overflow: 'auto' }}>
                                        {this.state.lease.length ? <table style={{ marginTop: '0px', }} className="customers-table">
                                            <col style={{ width: '70%' }} />
                                            <col style={{ width: '25%' }} />
                                            <col style={{ width: '5%' }} />
                                            <tr className="customers-table-header-row">
                                                <th>Drone Id</th>
                                                {/* <th style={{ textAlign: 'center' }}>Phone Number</th> */}
                                                <th>Status</th>
                                                <th></th>
                                            </tr>
                                            {this.state.lease.map((lease, key) => (
                                                <tr className="customers-table-data-row">
                                                    <td className="leasedData" onClick={() => {
                                                        this.setState((state) => ({
                                                            ...state,
                                                            leaseDetailsSelect: lease,
                                                        }), () => {
                                                            this.setState({ leaseOnPopUp: true })
                                                        })
                                                    }}>{lease.drone_id}</td>
                                                    <td>{lease.status}</td>
                                                    <td style={{ textAlign: 'right', }}>
                                                        <DeleteComponentTable
                                                            style={{ marginRight: '10px' }}
                                                            canEdit={lease.droneStatus}
                                                            onClickEdit={() => {
                                                                this.state.drones.map((drone, i) => {
                                                                    if (lease.drone_id == drone.drone_id) {
                                                                        this.setState((state) => ({
                                                                            ...state,
                                                                            edit: true,
                                                                            editLease: lease,
                                                                            selectedDroneName: i,
                                                                            selectedDrone: drone,
                                                                            fromDate: dateFormat(lease.startdate, 'yyyy-mm-dd'),
                                                                            toDate: dateFormat(lease.enddate, 'yyyy-mm-dd'),
                                                                        }), () => {
                                                                            this.setState((state) => ({
                                                                                ...state,
                                                                                addLeaseDronePopUp: true
                                                                            }))
                                                                        })
                                                                    }
                                                                })
                                                            }}
                                                            onClickDelete={() => {
                                                                this.setState((state) => ({
                                                                    ...state,
                                                                    deleteLeaseId: lease.id
                                                                }), () => {
                                                                    this.setState((state) => ({
                                                                        ...state,
                                                                        itemName: "Lease",
                                                                        processPopupIcon: "WARNING",
                                                                        processAction: "DELETE",
                                                                        processMessage: <div>All lease data including lease, invoice will be deleted.
                                                                            <br />Are you sure you want to delete this Lease ?</div>,
                                                                        showProcessPopup: true,
                                                                    }))
                                                                })
                                                            }}
                                                        // disableDelete={activities.disableDelete}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </table> :
                                            <div className="empty-data-message" style={{ display: 'flex', flexDirection: 'column', height: '100%', backgroundColor: "#FAFAFA", alignItems: 'center', justifyContent: 'center' }}>
                                                <img src={noLeaseData} style={{ height: "60px", width: "60px", }} />
                                                <div style={{ marginTop: '20px' }}>No Lease</div>
                                            </div>
                                        }
                                    </div>
                                </div>}

                            {this.state.invoiceLoading ?
                                <div style={{ display: 'flex', border: '1px solid #C2C2C2', backgroundColor: "#FAFAFA", alignItems: 'center', borderRadius: "5px", width: '50%', marginTop: '10px', marginLeft: '1%' }}>
                                    <img src={Loader} style={{ height: "50%", width: "100%", marginLeft: '5%' }} />
                                </div> :
                                <div style={{ width: '50%', marginLeft: '1%' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div className="table-header">Invoices</div>
                                    </div>
                                    <div style={{ border: '1px solid #C2C2C2', background: '#FAFAFA', borderRadius: '5px', marginTop: '10px', height: 'calc(100% - 35px)', overflow: 'auto' }}>
                                        {this.state.invoices.length ? <table className="customers-table" style={{ marginTop: '0px', borderRadius: '5px', }}>
                                            <tr className="customers-table-header-row" style={{ zIndex: '0' }}>
                                                <th>Invoice Id</th>
                                                <th style={{ textAlign: 'center' }}>Date</th>
                                                <th>Status</th>
                                            </tr>
                                            {this.state.invoices.map((invoice, key) => (
                                                <tr className="customers-table-data-row">
                                                    <td>
                                                        <Link style={{ color: '#3c3c3c' }} to={{
                                                            pathname: '/aerogcsenterprise/lease_drone_invoice',
                                                            state: {
                                                                user: this.state.user,
                                                                type: this.state.type,
                                                                lease: invoice.leaseData,
                                                                withGst: invoice.gstflag,
                                                                totalValue: invoice.total_amount,
                                                                gst: invoice.gst,
                                                                discount: invoice.discount,
                                                                dailycharges: invoice.dailycharges,
                                                                paid_status: invoice.paid_status
                                                            }
                                                        }}>
                                                            {invoice.invoiceid}
                                                        </Link>
                                                    </td>
                                                    <td style={{ textAlign: 'center' }}>{dateFormat(invoice.generate_date, "dd/mm/yyyy")}</td>
                                                    <td>{invoice.paid_status == true ? 'Paid' : 'Unpaid'}</td>
                                                </tr>
                                            ))}
                                        </table> :
                                            <div className="rmp-dashboard-empty-data-message" style={{ display: 'flex', flexDirection: 'column', height: '100%', backgroundColor: "#FAFAFA", alignItems: 'center', justifyContent: 'center' }}>
                                                <img src={noInvoices} style={{ height: "60px", width: "60px", }} />
                                                <div style={{ marginTop: '20px' }}>No Invoices</div>
                                            </div>
                                        }
                                    </div>
                                </div>}
                        </div>
                    </div>}
            </div>
        </div>
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateProfileDetails: (payload) => dispatch(updateProfileDetails(payload))
    };
};

const mapStateToProps = state => {
    return {
        activeTeamId: state.profile.activeTeamId,
        activeTeam: state.profile.activeTeam,
        isLoggedInUserOwner: state.profile.isLoggedInUserOwner,
        SubscriptionData: state.subscription.subscriptionData,
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(userCustomerInfo)