//spraying new logic
import React, { Component, useEffect } from "react";
import { addProjectDetails } from "./redux";
import * as API from "./api.js";
import "./style.css";
import { connect } from "react-redux";
import * as turf from '@turf/turf'
import { Link, StaticRouter, Redirect } from "react-router-dom";
import Geocode from "react-geocode";
import Loader from './icons/loader.svg';
import SingleMap from './ReusableComponents/map'
import { ActivityPopup } from './ReusableComponents/ActivityPopup.js';
import "leaflet/dist/leaflet.css";
import L, { layerGroup, marker, polygon } from "leaflet";
import "leaflet-editable"
import { AnimatedModal } from './ReusableComponents/AnimatedModal'
import missionImg from './icons/missionImg.png'
import plotImg from './icons/plotImg.png'
import plotSave from './icons/plotSave.png'
import clearPlot from './icons/clearPlot.png'
import addPlot from './icons/addBlue.png'
import circleObs from './icons/circleObs.png'
import polygonObs from './icons/polygonObs.png'
import editPlot from './icons/plotEdit.png'
import * as htmlToImage from 'html-to-image'
import { v4 as uuid } from 'uuid';
import {
    GoogleApiWrapper,
    LoadingContainer,
} from "google-maps-react";
import {
    drawingEndEvent,
    onUpdateLayer,
} from './ReusableComponents/map/leafletMeasure.js';
import { addPlan } from "./redux";
import getArrows from './ReusableComponents/ArrowOnPolyline'
import roadMapView from './assets/Images/roadMapViewzz.png'
import sateliteView from './assets/Images/sateliteView.png'
import { ActiveRole, GCS_FEATURES, getFeaturesPermissions } from "./Teams";
import AppConstants, { MAP_URLS } from "./AppConstants";
import { BreadCrumbs } from "./ReusableComponents/BreadCrumbs.js";
import { addUserAnalytic, updateOutTime } from "./ReusableComponents/reusableFunctions.js";
Geocode.setApiKey("AIzaSyBuxfUtRZSEUT2QrbZA8zUsG58lZUYYmwI");
Geocode.setLanguage("en");

const EARTH_RADIUS = 6371.0 / 0.001;
var d2r = Math.PI / 180; // degrees to radians
var earthsradius = 3963; // 3963 is the radius of the earth in miles
class RMPSprayingFlightPlot extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            user: {},
            map: {},
            center: { lat: 20.008482, lng: 73.756834 },
            altitude: 0,
            speed: 0,
            drawPlan: '',
            speedd: "",
            type: "",
            flightType: "",
            sidebarMode: this.props.location.state
                ? this.props.location.state.sidebarMode
                : "shrunk",
            redirect: false,
            Distance: '',
            polygon: undefined,
            itemName: "",
            showProcessPopup: false,
            processPopupIcon: "",
            processAction: "",
            processMessage: "",
            planName: '',
            projectName: '',
            editPlan: false,
            layer: {},
            bounds: [],
            mapLoading: true,
            RGBShow: true,
            band1: "1",
            band2: "2",
            band3: "3",
            processTileIndex: "NDVI",
            processTileMin: -0.283,
            processTileMax: 0.181,
            processTileType: "RGN",
            baseLayer: '',
            activeBaseLayer: 'satelite',
            selectedLayer: 'Orthomosaic',
            layerSelectionPopup: false,
            selectedLayerType: 'orthomosaic',
            planLine: null,
            selectedPolygon: undefined,
            obstacleDraw: false,
            obsPolygons: [],
            obsCircle: [],
            planDraw: true,
            planDrawFlag: true,
            selectPlanTypePopup: false,
            plotSaveFlag: false,
            plotName: '',
            error: '',
            plot: '',
            plotEdit: false,
            planSaveFlag: false,
            obstacleOptionPopup: false,
            selectedObsType: '',
            importKMLFilePop: false,
            KMLerror: '',
            selectedKMLFile: '',
            kmlFileCordinates: [],
            plotSaved: false,
            outId: ""
        };
    }

    componentWillUnmount() { updateOutTime(this.state.outId) }

    componentWillMount() {
        document.title = "Flight Type:Spraying Plot";
        addUserAnalytic(this.props.location.pathname).then(id => { this.setState({ outId: id }) })
        getFeaturesPermissions([GCS_FEATURES.PLANS]).then(permissions => {
            this.setState({ permissions }, () => {
                if (this.props.location.state.plotEdit) {
                    let { user, type, sidebarMode, project, plot, plotEdit } = this.props.location.state;
                    console.log(this.props.location.state)
                    this.props.addProjectDetails({
                        projectName: project.project_name,
                        clientName: "",
                        planName: "",
                        dateAndTime: "",
                    });
                    this.setState((state) => ({
                        ...state,
                        type,
                        user,
                        project,
                        clientName: "asd",
                        sidebarMode,
                        plot,
                        projectName: project.project_name,
                        plotName: plot.plot_name,
                        plotEdit,
                        plotSaved: true,
                    }), () => {
                        this.setState({ loading: false })
                    })

                } else if (this.props.location.state.editPlan) {
                    let { user, type, flightType, planName, projectName, editPlan, sidebarMode, project, plan, linked_map, clientName } = this.props.location.state;
                    this.props.addProjectDetails({
                        projectName: projectName,
                        clientName: "",
                        planName: planName,
                        dateAndTime: "",
                    });
                    this.setState((state) => ({
                        ...state,
                        flightType,
                        type,
                        user,
                        planName,
                        projectName,
                        project,
                        plan,
                        linked_map: linked_map ? linked_map : plan && plan.linked_map ? plan.linked_map : null,
                        clientName: clientName ? clientName : "asd",
                        editPlan,
                        sidebarMode
                    }), () => {
                        this.setState({ loading: false })
                    })
                } else if (this.props.location.state) {
                    let { Distance, altitude, center, drawPlan, flightType, speed, speedd, type, user, project, plan, linked_map, clientName, planName, projectName } = this.props.location.state;
                    this.setState((state) => ({
                        ...state,
                        Distance,
                        altitude,
                        center,
                        drawPlan,
                        flightType,
                        speed,
                        speedd,
                        type,
                        user,
                        project,
                        planName,
                        projectName,
                        plan,
                        linked_map: linked_map ? linked_map : plan && plan.linked_map ? plan.linked_map : null,
                        clientName: clientName ? clientName : "asd"
                    }), () => {
                        this.setState({ loading: false })
                    })
                } else {
                    // window.location.replace("/login");
                }
            })
        }).catch(e => { console.log(e) }
            // window.location.replace("/")
        )
    }

    ObstacleInPlan = (obstacles) => {
        if (obstacles.polygons.length > 0) {
            obstacles.polygons.map((ploypoints) => {
                let uniqueId = uuid()
                console.log('ploypoints', ploypoints.vertices);
                let obs = new L.Polygon(ploypoints.vertices.map((latLng => [latLng.lat, latLng.lon])), {
                    color: '#2989CF',
                    fillOpacity: 0,
                    weight: 3,
                    zIndex: 1,
                }).addTo(this.state.map).on('click', () => {
                    if (this.state.planDraw == false && this.state.permissions?.[GCS_FEATURES.PLANS].EDIT) {
                        this.disableEdit().then(() => {
                            obs.enableEdit()
                            this.setState({ selectedPolygon: obs, obstacleDraw: true, selectedObsType: 'polygon' })
                        })
                    }
                })
                obs.name = uniqueId
                this.state.obsPolygons.push({
                    id: uniqueId,
                    coordinates: obs.getLatLngs()[0].map((latlng) => { return { lat: latlng.lat, lng: latlng.lng } }),
                    leafletLayer: obs
                })
                this.setState({ selectedPolygon: obs }, () => {
                    this.disableEdit()
                })
            })
        }
        if (obstacles.circles.length > 0) {
            obstacles.circles.map((cir) => {
                let uniqueId = uuid()
                console.log('circle points', cir);
                let obsCir = new L.circle({ lat: cir.centroid.lat, lng: cir.centroid.lon }, cir.radius, {
                    color: '#2989CF',
                    fillOpacity: 0,
                    weight: 3,
                    zIndex: 1,
                }).addTo(this.state.map).on('click', () => {
                    if (this.state.planDraw == false) {
                        this.disableEdit().then(() => {
                            obsCir.enableEdit()
                            this.setState({ selectedPolygon: obsCir, obstacleDraw: true, selectedObsType: 'circle' })
                        })
                    }
                })
                obsCir.name = uniqueId
                this.state.obsCircle.push({
                    id: uniqueId,
                    coordinates: { lat: obsCir.getLatLng().lat, lng: obsCir.getLatLng().lng },
                    radius: obsCir.getRadius(),
                    leafletLayer: obsCir
                })
                this.setState({ selectedPolygon: obsCir }, () => {
                    this.disableEdit()
                })
            })


        }
    }

    isObInsidePlot = (coordinates) => {
        return new Promise((resolve, reject) => {
            const latLngs = this.state.polygon.leafletLayer.getLatLngs()[0]
            const plotPolygon = turf.polygon([[...(latLngs.map(latLng => [latLng.lat, latLng.lng])), [latLngs[0].lat, latLngs[0].lng]]]);
            console.log(plotPolygon)
            const points = turf.points(coordinates.map(latLng => [latLng.lat, latLng.lng]));
            if ((turf.pointsWithinPolygon(points, plotPolygon)).features.length === points.features.length) {
                resolve(true)
            } else {
                this.setState((state) => ({
                    ...state,
                    itemName: "Plot",
                    processPopupIcon: "ERROR",
                    processAction: "ADD",
                    processMessage: 'you can not draw obstacle outside plot.',
                    showProcessPopup: true,
                }))
                resolve(false)
            }
        })
    }

    getPlot = () => {
        API.getSprayingPlotCordinates(this.state.plot.id).then((data) => {
            console.log('plotData', data);
            if (data.plotData) {
                let polygonDraw = new L.Polygon(data.plotData.boundary.map((latLng => [latLng.lat, latLng.lon])), {
                    color: 'red',
                    fillOpacity: 0,
                    weight: 3,
                    zIndex: 1,
                }).addTo(this.state.map).on('click', () => {
                    if (this.state.obstacleDraw == false && this.state.planDrawFlag == true && this.state.permissions?.[GCS_FEATURES.PLANS].EDIT) {
                        this.disableEdit().then(() => {
                            polygonDraw.enableEdit();
                            this.setState({ selectedPolygon: polygonDraw, planDraw: true })
                        })
                    }
                })
                let bounds = polygonDraw.getBounds()
                this.state.map.fitBounds(bounds)
                this.setState((state) => ({
                    ...state,
                    polygon: {
                        coordinates: polygonDraw.getLatLngs()[0].map((latlng) => { return { lat: latlng.lat, lng: latlng.lng } }),
                        leafletLayer: polygonDraw
                    },
                    //  polygonDraw,
                    selectedPolygon: polygonDraw,
                    planDraw: true,
                }), () => {
                    this.disableEdit().then(() => {
                        this.ObstacleInPlan(data.plotData.obstacles)
                    })
                })
            } else {
                console.log('plotData', data);
            }
        })
    }
    setBaseLayerToState = (baseLayer) => {
        this.setState({ baseLayer })
    }

    updateHistogram = async () => {
        return new Promise((resolve, reject) => {
            let taskId = this.state.linked_map
            let { processTileIndex, processTileType, processTileMin, processTileMax, user } = this.state
            let sas = encodeURIComponent(this.state.permissions?.st)
            let blobContainer = this.state.permissions?.container
            let algorithm = processTileIndex
            API.getHistogram({ taskId, algorithm, processTileType: processTileType ? processTileType : "RGB", processTileMin, processTileMax, sas, blobContainer }).then((data) => {
                this.setState((state) => ({
                    ...state,
                    processTileMin: data.statistics[1].percentiles[0].toFixed(4),
                    processTileMax: data.statistics[1].percentiles[1].toFixed(4),
                    processTileType: processTileType ? this.state.processTileType : data.algorithms[0].filters[0]
                }), () => {
                    this.overlayTilesLayerOf("plantHealth")
                })
            })
        })
    }

    removeLayer = async (layer) => {
        let removeLayer = this.state[layer]
        this.setState({ [layer]: undefined }, () => {
            if (removeLayer) {
                console.log(removeLayer)
                removeLayer.remove()
            }
        })
    }

    updatePlanStyle = (type) => {
        //-------- Change polyline, arrows, polygon and marker color according to layer --------
        if (this.state.planLine)
            this.state.planLine.setStyle({ color: type == 'orthomosaic' || type == 'base' ? 'orange' : '#2989cf' })
        if (this.state.toggle && this.state.fencePolygon)
            this.state.fencePolygon.setStyle({ color: type == 'orthomosaic' || type == 'base' ? 'green' : 'white' })
        if (this.state.arrowArray && this.state.arrowMarkerLayer) {
            this.state.arrowMarkerLayer.remove();
            let arrows = getArrows(this.state.arrowArray, this.state.selectedLayer == 'Orthomosaic' || this.state.selectedLayer == 'Base' ? 'orange' : '#2989cf', 1, this.state.map)
            let arrowMarkerLayer = L.featureGroup(arrows).addTo(this.state.map);
            this.setState((state) => ({
                ...state,
                arrowMarkerLayer: arrowMarkerLayer,
            }))
        }
    }

    getTilesBounds = async (taskId) => {
        return new Promise((resolve, reject) => {
            let sas = encodeURIComponent(this.state.permissions?.st)
            let blobContainer = this.state.permissions?.container
            API.getTilesBounds({ taskId, tiffType: "orthomosaic", sas, blobContainer }).then((data) => {
                this.setState({
                    tilesBounds: data.tilesBounds,
                    bounds: data.bounds,
                    //defaultOrtho: true,
                    band1: "1",
                    band2: "2",
                    band3: "3",
                }, () => {
                    resolve(this.state.bounds)
                })
            })
        })
    }

    overlayTilesLayerOf = async (type) => {
        let { map } = this.state
        this.removeLayer("orthoLayer").then(async () => {
            if (this.state.linked_map) {
                this.updatePlanStyle(type)
                let taskid = this.state.linked_map
                let { processTileIndex, processTileType, processTileMin, processTileMax, tilesBounds, tileMinZoom, tileMaxZoom } = this.state
                let blobContainer = this.state.permissions?.container;
                let sas = encodeURIComponent(this.state.permissions?.st);
                await this.getTilesBounds(taskid)
                let bounds = [[this.state.bounds[1], this.state.bounds[0]], [this.state.bounds[3], this.state.bounds[2]]]
                console.log(bounds);
                let bandsSelection = `${this.state.RGBShow ? `&bands=${this.state.band1},${this.state.band2},${this.state.band3}` : "&viewDefault=true"}`
                if (type == "plantHealth") {
                    let orthoLayer = L.tileLayer(`${AppConstants.tilesServer}/tiles/${taskid}/plantHealth/{z}/{x}/{y}.png?sas=${sas}&blobContainer=${blobContainer}&algorithm=${processTileIndex}&min=${processTileMin}&max=${processTileMax}&filterType=${processTileType}`, {
                        tileSize: 256,
                        maxZoom: 28,
                        bounds: bounds
                    }).addTo(map);
                    this.setState({ orthoLayer })
                } else {
                    console.log("in else part...");
                    let orthoLayer = L.tileLayer(`${AppConstants.tilesServer}/tiles/${taskid}/${type}/{z}/{x}/{y}.png?sas=${sas}&blobContainer=${blobContainer}${bandsSelection}`, {
                        tileSize: 256,
                        maxZoom: 28,
                        bounds: bounds
                    }).addTo(map);
                    console.log(orthoLayer);
                    orthoLayer.on("load", () => { this.setState({ mapLoading: false }) });
                    this.setState({ orthoLayer })
                }
            }
        })
    }

    changeSelectedLayer = (layer) => {
        console.log("Layer : ", layer);
        if (layer == 'NDVI') {
            console.log("Here is NDVI");
            this.setState((state) => ({
                selectedLayer: layer,
                layerSelectionPopup: false,
                processTileIndex: "NDVI",
                processTileType: "RGN",
                selectedLayerType: 'plantHealth'
            }), () => {
                this.updateHistogram()
            })
        } else if (layer == 'VARI') {
            console.log("Here is vari");
            this.setState((state) => ({
                selectedLayer: layer,
                layerSelectionPopup: false,
                processTileIndex: "VARI",
                processTileType: "RGB",
                selectedLayerType: 'plantHealth'
            }), () => {
                this.updateHistogram()
            })
        } else if (layer == 'Orthomosaic') {
            this.setState({
                selectedLayer: layer,
                layerSelectionPopup: false,
                selectedLayerType: 'orthomosaic'
            })
            this.overlayTilesLayerOf("orthomosaic")
        } else {
            this.setState({
                selectedLayer: layer,
                layerSelectionPopup: false,
                selectedLayerType: 'base'
            })
            this.updatePlanStyle('base')
            this.removeLayer('orthoLayer')
        }
    }

    initMap = () => {
        let { map, center } = this.state
        map.setView(center, 20);
        this.state.plotEdit ? this.getPlot() : this.drawPolygon()
        // this.selectPlanType()
        onUpdateLayer(map, this.polygonUpdated)
        this.overlayTilesLayerOf("orthomosaic")
        drawingEndEvent(map, this.getPolygon)
    };


    // convert circle in polygon
    getLatLngsOfCircle = (latLng, radius, points) => {
        return new Promise((resolve, reject) => {
            var rlat = ((radius / earthsradius / (180 / Math.PI)) * 2.0418);
            var rlng = rlat / Math.cos(latLng.lat * d2r);

            var extp = new Array();
            for (var i = 0; i < points; i++) {
                var theta = Math.PI * (i / (points / 2));
                let ex = latLng.lng + (rlng * Math.cos(theta));
                let ey = latLng.lat + (rlat * Math.sin(theta));
                extp.push({ lat: ey, lng: ex });
            }
            resolve(extp);
        })
    }

    checkPolygonObstaclesInsidePlot = (updatedPolygon) => {
        return new Promise(async (resolve, reject) => {
            let obsData = 0
            for (let i = 0; i < this.state.obsPolygons.length; i++) {
                let obs = await this.isObInsidePlot(this.state.obsPolygons[i].coordinates)
                obsData = obs == true ? obsData : obsData + 1
            }
            resolve(obsData > 0 ? false : true)
        })
    }

    checkCircleObstaclesInsidePlot = (updatedPolygon) => {
        return new Promise(async (resolve, reject) => {
            let obsData = 0
            for (let i = 0; i < this.state.obsCircle.length; i++) {
                let circlePoygon = await this.getLatLngsOfCircle(this.state.obsCircle[i].coordinates, this.state.obsCircle[i].radius, 36)
                let obs = await this.isObInsidePlot(circlePoygon)
                obsData = obs == true ? obsData : obsData + 1
            }
            resolve(obsData > 0 ? false : true)
        })
    }

    polygonUpdated = async (updatedPolygon) => {
        console.log('updatedPolygon called', updatedPolygon);
        if (this.state.planDraw) {
            let polygonInside = await this.checkPolygonObstaclesInsidePlot(updatedPolygon)
            let circleInside = await this.checkCircleObstaclesInsidePlot(updatedPolygon)
            console.log(polygonInside, circleInside);
            if (polygonInside && circleInside) {
                console.log('inside data update');
                this.setState({
                    polygon: {
                        coordinates: updatedPolygon.getLatLngs()[0].map((latlng) => { return { lat: latlng.lat, lng: latlng.lng } }),
                        leafletLayer: updatedPolygon
                    },

                })
            } else {
                updatedPolygon.disableEdit()
                updatedPolygon.setLatLngs(this.state.polygon.coordinates)
            }
        } else if (this.state.obstacleDraw) {
            if (this.state.selectedObsType == 'circle') {
                let circlePoygon = await this.getLatLngsOfCircle({ lat: updatedPolygon.getLatLng().lat, lng: updatedPolygon.getLatLng().lng }, updatedPolygon.getRadius(), 36)
                console.log('circlePoygon', circlePoygon);
                if (await this.isObInsidePlot(circlePoygon)) {
                    if (updatedPolygon.getRadius() > 0) {
                        this.setState({
                            obsCircle: this.state.obsCircle.map((cir) => {
                                if (cir.id === updatedPolygon.name) {
                                    return {
                                        ...cir,
                                        coordinates: { lat: updatedPolygon.getLatLng().lat, lng: updatedPolygon.getLatLng().lng },
                                        radius: updatedPolygon.getRadius(),
                                        leafletLayer: updatedPolygon
                                    }
                                } else {
                                    return cir
                                }
                            })
                        })
                    }
                } else {
                    updatedPolygon.disableEdit()
                    updatedPolygon.setLatLng((this.state.obsCircle.find(obs => obs.id === updatedPolygon.name)).coordinates)
                    updatedPolygon.setRadius((this.state.obsCircle.find(obs => obs.id === updatedPolygon.name)).radius)
                }
            } else {
                if (await this.isObInsidePlot(updatedPolygon.getLatLngs()[0])) {
                    this.setState({
                        obsPolygons: this.state.obsPolygons.map((obs) => {
                            if (obs.id === updatedPolygon.name) {
                                return {
                                    ...obs,
                                    coordinates: updatedPolygon.getLatLngs()[0].map((latlng) => { return { lat: latlng.lat, lng: latlng.lng } }),
                                    leafletLayer: updatedPolygon
                                }
                            } else {
                                return obs
                            }
                        })
                    })
                } else {
                    updatedPolygon.disableEdit()
                    updatedPolygon.setLatLngs((this.state.obsPolygons.find(obs => obs.id === updatedPolygon.name)).coordinates)
                }

            }
        }
    }

    getPolygon = async (polygonObs) => {
        console.log('get polygon called');
        if (this.state.planDraw) {
            if (polygonObs.layer.getLatLngs()[0].length > 2) {
                console.log('plan draw', polygonObs);
                polygonObs.layer.setStyle({
                    color: 'red',
                    fillOpacity: 0,
                    weight: 3,
                })
                polygonObs.layer.on('click', () => {
                    if (this.state.obstacleDraw == false && this.state.planDrawFlag == true && this.state.permissions?.[GCS_FEATURES.PLANS].EDIT) {
                        this.disableEdit().then(() => {
                            polygonObs.layer.enableEdit()
                            this.setState({ selectedPolygon: polygonObs.layer, planDraw: true })
                        })
                    }
                })
                this.setState({
                    polygon: {
                        coordinates: polygonObs.layer.getLatLngs()[0].map((latlng) => { return { lat: latlng.lat, lng: latlng.lng } }),
                        leafletLayer: polygonObs.layer
                    },
                    selectedPolygon: polygonObs.layer,
                }, () => { this.disableEdit() })
            }
        } else {
            if (this.state.selectedObsType == 'circle') {
                let circlePoygon = await this.getLatLngsOfCircle({ lat: polygonObs.layer.getLatLng().lat, lng: polygonObs.layer.getLatLng().lng }, polygonObs.layer.getRadius(), 36)
                console.log('circlePoygon', circlePoygon);
                if (polygonObs.layer.getRadius() > 0 && await this.isObInsidePlot(circlePoygon)) {
                    let uniqueId = uuid()
                    polygonObs.layer.setStyle({
                        color: '#2989CF',
                        fillOpacity: 0,
                        weight: 3,
                    })
                    polygonObs.layer.name = uniqueId
                    polygonObs.layer.on('click', () => {
                        if (this.state.planDraw == false && this.state.permissions?.[GCS_FEATURES.PLANS].EDIT) {
                            this.disableEdit().then(() => {
                                this.state.polygon.leafletLayer.disableEdit()
                                polygonObs.layer.enableEdit()
                                this.setState({ selectedPolygon: polygonObs.layer, obstacleDraw: true, selectedObsType: 'circle' })
                            })
                        }
                    })
                    this.state.obsCircle.push({
                        id: uniqueId,
                        coordinates: { lat: polygonObs.layer.getLatLng().lat, lng: polygonObs.layer.getLatLng().lng },
                        radius: polygonObs.layer.getRadius(),
                        leafletLayer: polygonObs.layer
                    })
                    this.setState({ selectedPolygon: polygonObs.layer, obstacleDraw: false }, () => { this.disableEdit() })
                } else {
                    polygonObs.layer.remove()
                    this.savePoly()
                }
            } else if (this.state.selectedObsType == 'polygon' && polygonObs?.layer?.getLatLngs()?.[0]?.length > 2) {
                if (await this.isObInsidePlot(polygonObs.layer.getLatLngs()[0])) {
                    let uniqueId = uuid()
                    polygonObs.layer.setStyle({
                        color: '#2989CF',
                        fillOpacity: 0,
                        weight: 3,
                    })
                    polygonObs.layer.name = uniqueId
                    polygonObs.layer.on('click', () => {
                        if (this.state.planDraw == false && this.state.permissions?.[GCS_FEATURES.PLANS].EDIT) {
                            this.disableEdit().then(() => {
                                this.state.polygon.leafletLayer.disableEdit()
                                polygonObs.layer.enableEdit()
                                this.setState({ selectedPolygon: polygonObs.layer, obstacleDraw: true, selectedObsType: 'polygon' })
                            })
                        }
                    })
                    this.state.obsPolygons.push({
                        id: uniqueId,
                        coordinates: polygonObs.layer.getLatLngs()[0].map((latlng) => { return { lat: latlng.lat, lng: latlng.lng } }),
                        leafletLayer: polygonObs.layer
                    })
                    this.setState({ selectedPolygon: polygonObs.layer, obstacleDraw: false }, () => { this.disableEdit() })
                } else {
                    polygonObs.layer.remove()
                    this.savePoly()
                }
            }
        }
    }

    disableEdit = async () => {
        console.log('disable edit called');
        if (this.state.selectedPolygon) {
            this.state.selectedPolygon.disableEdit()
            this.setState({
                selectedPolygon: undefined,
            })
        }
    }

    poltClick = () => {
        if (this.state.obstacleDraw) {
            this.setState((state) => ({
                ...state,
                itemName: "Plot",
                processPopupIcon: "ERROR",
                processAction: "ADD",
                processMessage: 'Obstacle not saved.',
                showProcessPopup: true,
            }))
        } else {
            this.disableEdit().then(() => {
                this.setState((state) => ({
                    ...state,
                    planDrawFlag: true,
                    planDraw: true,
                    obstacleDraw: false,
                }), () => {
                    console.log('plot click called');
                    this.drawPolygon()
                })
            })
        }
    }

    obstacleClick = () => {
        if (this.state.polygon?.leafletLayer && this.state.obstacleDraw == false && this.state.planDrawFlag) {
            this.setState((state) => ({
                ...state,
                obstacleOptionPopup: true,
            }))
        }
    }

    obstacleSelectClick = () => {
        if (this.state.obstacleDraw == false) {
            if (this.state.polygon?.leafletLayer) {
                this.disableEdit().then(() => {
                    this.setState((state) => ({
                        ...state,
                        planDrawFlag: false,
                        planDraw: false,
                        obstacleDraw: true,
                    }), () => {
                        this.drawObstacleInPlan()
                    })
                })
            } else {
                this.setState((state) => ({
                    ...state,
                    itemName: "Plot",
                    processPopupIcon: "ERROR",
                    processAction: "ADD",
                    processMessage: 'Please draw plan frist.',
                    showProcessPopup: true,
                }))
            }
        }
    }

    savePoly = () => {
        this.setState((state) => ({
            ...state,
            obstacleDraw: false,
            selectedObsType: ''
        }), () => {
            this.disableEdit()
            this.state.map.editTools.stopDrawing()
        })
    }

    drawObstacleInPlan = () => {
        console.log('obstacle Draw called');
        if (!(this.state.selectedPolygon)) {
            this.state.polygon.leafletLayer.disableEdit()
            if (this.state.selectedObsType == 'circle') {
                this.state.map.editTools.startCircle(undefined, {
                    radius: 0,
                })
            } else {
                this.state.map.editTools.startPolygon()
            }
        }
    }

    drawPolygon = () => {
        console.log('draw polygon called');
        this.disableEdit().then(() => {
            if (this.state.polygon?.leafletLayer && this.state.permissions?.[GCS_FEATURES.PLANS].EDIT) {
                this.state.polygon.leafletLayer.enableEdit()
                this.setState({ selectedPolygon: this.state.polygon.leafletLayer, planDraw: true })
            } else {
                if (!(this.state.selectedPolygon)) {
                    this.state.map.editTools.startPolygon()
                }
            }
        })
    }

    getPlotBoundry = () => {
        return new Promise((resolve, reject) => {
            let polyPoints = this.state.polygon.coordinates
            let plotBoundry = []
            for (let i = 0; i < polyPoints.length; i++) {
                plotBoundry.push({
                    "alt": 0,
                    "lat": polyPoints[i].lat,
                    "lon": polyPoints[i].lng
                })
            }
            resolve(plotBoundry)
        })

    }

    getObstacleVertices = (poly) => {
        return new Promise((resolve, reject) => {
            let polyPoint = poly.getLatLngs()[0]
            let vertices = []
            for (let i = 0; i < polyPoint.length; i++) {
                vertices.push({
                    "alt": 0,
                    "lat": polyPoint[i].lat,
                    "lon": polyPoint[i].lng
                })
            }
            resolve(vertices)
        })

    }

    getObstaclePolygons = () => {
        return new Promise(async (resolve, reject) => {
            let polygons = []
            for (let i = 0; i < this.state.obsPolygons.length; i++) {
                let area = L.GeometryUtil.geodesicArea(this.state.obsPolygons[i].leafletLayer.getLatLngs()[0])
                let vertices = this.state.obsPolygons[i].coordinates.map((cords => ({ alt: 0, lat: cords.lat, lon: cords.lng })))
                polygons.push({
                    "area": area,
                    "vertices": vertices
                })
            }
            resolve(polygons)
        })
    }

    getObstacleCircles = () => {
        return new Promise(async (resolve, reject) => {
            let circles = []
            for (let i = 0; i < this.state.obsCircle.length; i++) {
                circles.push({
                    "centroid": { lat: this.state.obsCircle[i].coordinates.lat, lon: this.state.obsCircle[i].coordinates.lng },
                    "radius": this.state.obsCircle[i].radius,
                    "area": (3.14159 * this.state.obsCircle[i].radius * this.state.obsCircle[i].radius)
                })
            }
            resolve(circles)
        })

    }

    resizedataURL = (datas, wantedWidth, wantedHeight) => {
        return new Promise(async function (resolve, reject) {
            // We create an image to receive the Data URI
            var img = document.createElement("img");

            // When the event "onload" is triggered we can resize the image.
            img.onload = function () {
                // We create a canvas and get its context.
                var canvas = document.createElement("canvas");
                var ctx = canvas.getContext("2d");

                // We set the dimensions at the wanted size.
                canvas.width = wantedWidth;
                canvas.height = wantedHeight;

                // We resize the image with the canvas method drawImage();
                ctx.drawImage(this, 0, 0, wantedWidth, wantedHeight);

                var dataURI = canvas.toDataURL();

                // This is the return of the Promise
                resolve(dataURI);
            };

            // We put the Data URI in the image's src attribute
            img.src = datas;
        });
    } // Use it like : var newDataURI = await resizedataURL('yourDataURIHere', 50, 50);

    getAsset = async () => {
        // this.setState((prevState) => ({
        //     ...prevState,
        //     creatingPlan: this.state.editPlan ? false : true,
        //     itemName: "Plan",
        //     processPopupIcon: "WAIT",
        //     processAction: this.state.editPlan ? "UPDATE" : "ADD",
        //     processMessage: null,
        //     showProcessPopup: true,
        // }));
        // take screenshot of plan using html-to-image library
        var newDataUri;
        let a = await htmlToImage
            .toPng(document.getElementById("rmp-map"))
            .then(async (dataUrl) => {
                let newDataUri1;
                newDataUri = await this.resizedataURL(dataUrl, 1080, 720); // reduce the image size
                return newDataUri1;
                // download(dataUrl, 'map.png');
            });
        return newDataUri;
    }

    // save plot and obstacle in db and json
    getPlotData = () => {
        this.setState((state) => ({
            ...state,
            itemName: "Plot",
            processPopupIcon: "WAIT",
            processAction: this.state.plotEdit ? "UPDATE" : "ADD",
            processMessage: null,
            showProcessPopup: true,
        }), () => {
            this.disableEdit().then(async () => {
                let area = L.GeometryUtil.geodesicArea(this.state.polygon.coordinates)
                let plotBoundry = await this.getPlotBoundry()
                let assets = await this.getAsset();
                console.log('assets', assets);
                let obstaclePolygons = []
                let obstacleCircles = []
                if (this.state.obsPolygons.length > 0) {
                    obstaclePolygons = await this.getObstaclePolygons()
                }
                if (this.state.obsCircle.length > 0) {
                    obstacleCircles = await this.getObstacleCircles()
                    console.log('obstacleCircles', obstacleCircles);
                }
                let myAsset = {
                    "plotMap.png": assets,
                };
                let plotData = {
                    "dateTime": new Date().toISOString(),
                    "modifiedDateTime": '',
                    "area": area,
                    "boundary": plotBoundry,
                    "obstacles": {
                        "polygons": obstaclePolygons,
                        "circles": obstacleCircles
                    },
                }
                this.setState({ plotSaveFlag: false })
                if (this.state.plotEdit) {
                    API.updateSprayingPlot(this.state.plotName, this.state.plot.id, this.state.projectName, plotData, myAsset).then((res) => {
                        if (res.message == "OK") {
                            console.log('res ok', res);
                            this.setState((state) => ({
                                ...state,
                                itemName: "Plot",
                                processPopupIcon: "COMPLETE",
                                processAction: "UPDATE",
                                processMessage: null,
                                showProcessPopup: true,
                            }));
                        } else {
                            console.log('res reject', res);
                            this.setState((state) => ({
                                ...state,
                                itemName: "Plot",
                                processPopupIcon: "ERROR",
                                processAction: "UPDATE",
                                processMessage: res.message,
                                showProcessPopup: true,
                            }));
                        }
                    }, (e) => {
                        this.setState((state) => ({
                            ...state,
                            itemName: "Plot",
                            processPopupIcon: "ERROR",
                            processAction: "UPDATE",
                            processMessage: e,
                            showProcessPopup: true,
                        }));
                    })

                } else {
                    API.addSprayingPlot(this.state.plotName, this.state.projectName, plotData, myAsset).then((res) => {
                        if (res.message == "OK") {
                            console.log('res ok', res);
                            this.setState((state) => ({
                                ...state,
                                itemName: "Plot",
                                processPopupIcon: "COMPLETE",
                                processAction: "ADD",
                                processMessage: null,
                                showProcessPopup: true,
                                plotSaved: true
                            }));
                        } else {
                            console.log('res reject', res);
                            this.setState((state) => ({
                                ...state,
                                itemName: "Plot",
                                processPopupIcon: "ERROR",
                                processAction: "ADD",
                                processMessage: res.message,
                                showProcessPopup: true,
                            }));
                        }
                    }, (e) => {
                        this.setState((state) => ({
                            ...state,
                            itemName: "Plot",
                            processPopupIcon: "ERROR",
                            processAction: "ADD",
                            processMessage: e,
                            showProcessPopup: true,
                        }));
                    })
                }
            })
        });
    }

    // plot save on change handle 
    handleChange = (e) => {
        let format = /[!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?]+/;
        if (format.test(e.target.value)) {
            this.setState({ error: "Spacial characters are not allowed." })
        } else if (e.target.value.includes(' ')) {
            this.setState({ error: "Spaces are not allowed." })
        } else {
            this.setState({ error: null, plotName: e.target.value });
        }
    }

    // plan save on change handle
    handleChange1 = (e) => {
        let format = /[!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?]+/;
        if (format.test(e.target.value)) {
            this.setState({ error: "Spacial characters are not allowed." })
        } else if (e.target.value.includes(' ')) {
            this.setState({ error: "Spaces are not allowed." })
        } else {
            this.setState({ error: null, planName: e.target.value });
        }
    }
    // clear only obstacle
    clearObstacle = async () => {
        await this.disableEdit()
        this.state.obsPolygons.map((obs) => {
            obs.leafletLayer.remove()
        })

        this.state.obsCircle.map((obs) => {
            obs.leafletLayer.remove()
        })
        this.setState((state) => ({
            ...state,
            obsPolygons: [],
            obsCircle: [],
            obstacleDraw: false,
            selectedPolygon: undefined
        }))
    }
    // clear obstacle and plot
    clearData = async () => {
        await this.disableEdit()
        this.state.obsPolygons.map((obs) => {
            obs.leafletLayer.remove()
        })

        this.state.obsCircle.map((obs) => {
            obs.leafletLayer.remove()
        })
        this.state.polygon.leafletLayer.remove()
        this.setState((state) => ({
            ...state,
            obsPolygons: [],
            obsCircle: [],
            planDrawFlag: true,
            planDraw: true,
            obstacleDraw: false,
            selectedPolygon: undefined
        }), () => {
            if (!(this.state.selectedPolygon)) {
                this.state.map.editTools.startPolygon()
            }
            this.setState({ polygon: undefined })
        })
    }

    // To change base layer of map
    switchBaseLayer = () => {
        const { map1 } = this.state
        this.setState({ activeBaseLayer: this.state.activeBaseLayer == "satelite" ? "terrain" : "satelite" }, () => {
            this.state.baseLayer.setUrl(this.state.activeBaseLayer === "satelite" ? MAP_URLS.SATELITE : MAP_URLS.ROAD_MAP)
        })
    }

    // create plan from plot plan popup trigger
    createMissionPlan = () => {
        this.disableEdit().then(() => {
            if (this.state.polygon?.leafletLayer) {
                this.setState({ planSaveFlag: true })
            }
        })
    }

    // save plot popup trigger
    savePlotClick = () => {
        this.disableEdit().then(() => {
            if (this.state.polygon?.leafletLayer) {
                this.setState({ plotSaveFlag: true })
            }
        })
    }

    // validate kml file
    isValidKML = (e) => {
        return new Promise((resolve, reject) => {
            let plan = []
            const reader = new FileReader();
            let text;
            reader.onload = async (e) => {
                text = (e.target.result)
                const dom = document.createRange().createContextualFragment(text);
                let Polygon = dom.querySelector("Polygon")
                if (Polygon === null) {
                    let LineString = dom.querySelector("LineString")
                    if (LineString === null) {
                        reject("Invalid KML.")
                    } else {
                        let innerText = LineString.innerHTML
                        let tempText = await innerText.split('<coordinates>').pop().split('</coordinates>')[0];
                        let finalText = tempText.replace(/\n/g, '').replace(/\t/g, '')
                        let coordinates = finalText.split(" ")
                        coordinates.forEach((coord) => {
                            if (coord != "") {
                                let latLng = coord.split(",")
                                if (latLng[0] && latLng[1]) {
                                    plan.push([Number(latLng[0]), Number(latLng[1]), Number(latLng[2])])
                                }
                                else (
                                    reject("Unsupported KML File.")
                                )
                            }
                        })
                        resolve(plan);
                    }
                } else {
                    let innerText = Polygon.innerHTML
                    let tempText = await innerText.split('<coordinates>').pop().split('</coordinates>')[0];
                    let finalText = tempText.replace(/\n/g, '').replace(/\t/g, '')
                    let coordinates = finalText.split(" ")
                    coordinates.forEach((coord) => {
                        if (coord != "") {
                            let latLng = coord.split(",")
                            if (latLng[0] && latLng[1]) {
                                plan.push([Number(latLng[0]), Number(latLng[1]), Number(latLng[2])])
                            }
                            else (
                                reject("Invalid Plan.")
                            )
                        }
                    })
                    resolve(plan);
                }
            };
            reader.readAsText(e.target.files[0])
        });
    }

    // onchange kml file
    importKML = async (e) => {
        if (e.target.files[0]) {
            let fileName = e.target.files[0].name
            this.isValidKML(e).then((plan) => {
                console.log('plan', plan);
                this.setState((state) => ({
                    ...state,
                    KMLerror: '',
                    kmlFileCordinates: plan,
                    selectedKMLFile: fileName
                }))
            }).catch(err => {
                this.setState({
                    KMLerror: err,
                    selectedKMLFile: '',
                    kmlFileCordinates: []
                })
            })
        }
    }
    // 

    importKMLPlot = () => {
        if (this.state.kmlFileCordinates.length > 2) {
            this.disableEdit()
            this.state.map.editTools.stopDrawing()
            if (this.state.polygon?.leafletLayer) { this.state.polygon.leafletLayer.remove() }
            this.setState({ polygon: undefined })
            let polygonDraw = new L.Polygon(this.state.kmlFileCordinates.map((latLng => [latLng[1], latLng[0]])), {
                color: 'red',
                fillOpacity: 0,
                weight: 3,
                zIndex: 1,
            }).addTo(this.state.map).on('click', () => {
                if (this.state.obstacleDraw == false && this.state.planDrawFlag == true && this.state.permissions?.[GCS_FEATURES.PLANS].EDIT) {
                    this.disableEdit().then(() => {
                        polygonDraw.enableEdit();
                        this.setState({ selectedPolygon: polygonDraw, planDraw: true })
                    })
                }
            })
            let bounds = polygonDraw.getBounds()
            this.state.map.fitBounds(bounds)
            this.setState((state) => ({
                ...state,
                polygon: {
                    coordinates: polygonDraw.getLatLngs()[0].map((latlng) => { return { lat: latlng.lat, lng: latlng.lng } }),
                    leafletLayer: polygonDraw
                },
                selectedPolygon: polygonDraw,
                planDraw: true,
                importKMLFilePop: false,
                KMLerror: '',
                selectedKMLFile: '',
                kmlFileCordinates: []
            }))
        } else {
            this.setState({
                KMLerror: 'Polygon KML Must Contain Three or More Points',
                selectedKMLFile: '',
                kmlFileCordinates: []
            })
        }
    }

    render() {
        let { user, type, project, plans, plots, plotview } = this.state
        return (
            <>
                <div className="wrapper">
                    <div className="right-content-new" style={{ overflowY: "hidden" }}
                        onClick={
                            () => {
                                this.setState((state) => ({
                                    ...state, sidebarMode: 'shrunk'
                                }))
                            }
                        }
                    >

                        <ActivityPopup
                            item={this.state.itemName}
                            open={this.state.showProcessPopup}
                            icon={this.state.processPopupIcon}
                            action={this.state.processAction}
                            msg={this.state.processMessage}
                            close={() => {
                                this.setState((state) => ({
                                    ...state,
                                    showProcessPopup: false,
                                    processMessage: null
                                }))
                            }}
                            onClickOk={() => { }}
                        />
                        {/*  mission select popup */}
                        <AnimatedModal
                            isOpen={this.state.selectPlanTypePopup}
                            height="300px"
                            width="500px"
                        >
                            <div style={{ textAlign: 'left', margin: '15px 20px', fontSize: '18px', fontWeight: '400' }}>Start With</div>
                            <hr style={{ width: '100%', margin: '5px 0px' }}></hr>
                            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '100%', height: '180px', padding: '5px 30px' }}>
                                <div className='missionType' onClick={() => { this.setState({ MissionDrawType: 'fieldPlot', selectPlanTypePopup: false }) }} >
                                    <img src={plotImg} style={{ width: '50px', height: '50px' }} />
                                    <div style={{ marginTop: '20px', fontSize: '18px' }}>Field Plot</div>
                                </div>
                                <div className='missionType' onClick={() => { }}>
                                    <img src={missionImg} style={{ width: '50px', height: '50px' }} />
                                    <div style={{ marginTop: '20px', fontSize: '18px' }}>Mission Plan</div>
                                </div>
                            </div>
                        </AnimatedModal>

                        {/* obstale draw type select popup */}
                        <AnimatedModal
                            isOpen={this.state.obstacleOptionPopup}
                            height="300px"
                            width="500px"
                        >
                            <div style={{ textAlign: 'left', margin: '15px 20px', fontSize: '18px', fontWeight: '400' }}>Select obstacle Type</div>
                            <hr style={{ width: '100%', margin: '5px 0px' }}></hr>
                            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '100%', height: '180px', padding: '5px 30px' }}>
                                <div className='missionType' onClick={() => {
                                    this.setState((state) => ({
                                        ...state,
                                        selectedObsType: 'polygon',
                                        obstacleOptionPopup: false,
                                    }), () => {
                                        this.obstacleSelectClick()
                                    })
                                }} >
                                    <img src={polygonObs} style={{ width: '50px', height: '50px' }} />
                                    <div style={{ marginTop: '20px', fontSize: '18px' }}>Polygon</div>
                                </div>
                                <div className='missionType' onClick={() => {
                                    this.setState((state) => ({
                                        ...state,
                                        selectedObsType: 'circle',
                                        obstacleOptionPopup: false,
                                    }), () => {
                                        this.obstacleSelectClick()
                                    })
                                }} >
                                    <img src={circleObs} style={{ width: '50px', height: '50px' }} />
                                    <div style={{ marginTop: '20px', fontSize: '18px' }}>Circle</div>
                                </div>
                            </div>
                            <div
                                className="imageViewButton1"
                                style={{ cursor: 'pointer', fontSize: '14px', marginLeft: 'auto', marginRight: '50px', borderRadius: '15px', width: '100px', height: '25px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                onClick={() => {
                                    this.setState({ obstacleOptionPopup: false, })
                                }}
                            >Cancel</div>
                        </AnimatedModal>

                        {/* save plot popup */}
                        <AnimatedModal
                            isOpen={this.state.plotSaveFlag}
                            height="350px"
                            width="550px"
                        >
                            <div style={{ width: '100%', padding: '50px 100px 10px 100px', textAlign: 'left' }}>
                                <div className="services-content-title" style={{ fontSize: '22px' }} >{"Save Plot"}</div>
                                <div style={{ marginTop: "25px", }}>
                                    <div className="font-color-secondary" style={{ fontSize: "12px", }}>Project Name</div>
                                    <input type="text" className="add-user-form-text" value={this.state.projectName} placeholder="eg. My_Project" style={{ width: '350px', opacity: '0.8' }}
                                        disabled />
                                    {/* <img src={editPlot} style={{ width: '16px', position: 'absolute', top: '132px', left: '425px', opacity: '0.8' }} /> */}
                                </div>
                                <div style={{ marginTop: "15px", }}>
                                    <div className="font-color-secondary" style={{ fontSize: "12px", }}>Plot Name</div>
                                    {this.state.plotEdit ? <input type="text" maxLength={50} className="add-user-form-text" value={this.state.plotName} placeholder="eg. My_Plot" style={{ width: '350px', opacity: '0.8' }}
                                        onChange={this.handleChange} disabled /> : <input type="text" maxLength={30} className="add-user-form-text" value={this.state.plotName} placeholder="eg. My_Plot" style={{ width: '350px' }}
                                            onChange={this.handleChange} />}
                                    <img src={editPlot} style={{ width: '16px', position: 'absolute', top: '210px', left: '425px' }} />
                                    <div style={{ color: "red", fontSize: "12px", height: "5px", padding: "5px 0 0 3px" }}>{this.state.error}</div>
                                </div>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '35px', padding: '5px 30px' }}>
                                <div
                                    className="imageViewButton1"
                                    style={{ cursor: 'pointer', padding: '5px 14px 5px 14px', width: '80px' }}
                                    onClick={() => {
                                        this.setState({ plotSaveFlag: false, error: '', plotName: this.state.plotEdit ? this.state.plotName : '' })
                                    }}
                                >
                                    Cancel
                                </div>
                                {this.state.plotName ? <div
                                    className="imageViewButton1"
                                    style={{ cursor: 'pointer', padding: '5px 14px 5px 14px', width: '80px' }}
                                    onClick={() => {
                                        this.getPlotData()
                                    }}
                                >
                                    Save
                                </div> :
                                    <div
                                        className="imageViewButton1"
                                        style={{ cursor: 'pointer', padding: '5px 14px 5px 14px', opacity: '0.5', width: '80px' }}
                                    >
                                        Save
                                    </div>}
                            </div>
                        </AnimatedModal>

                        {/* plan save popup */}
                        <AnimatedModal
                            isOpen={this.state.planSaveFlag}
                            height="350px"
                            width="550px"
                        >
                            <div style={{ width: '100%', padding: '50px 100px 10px 100px', textAlign: 'left' }}>
                                <div className="services-content-title" style={{ fontSize: '22px' }} >{"Save Plan"}</div>
                                <div style={{ marginTop: "25px", }}>
                                    <div className="font-color-secondary" style={{ fontSize: "12px", }}>Project Name</div>
                                    <input type="text" className="add-user-form-text" value={this.state.projectName} placeholder="eg. My_Project" style={{ width: '350px', opacity: '0.8' }}
                                        disabled />
                                    {/* <img src={editPlot} style={{ width: '16px', position: 'absolute', top: '132px', left: '425px', opacity: '0.8' }} /> */}
                                </div>
                                <div style={{ marginTop: "15px", }}>
                                    <div className="font-color-secondary" style={{ fontSize: "12px", }}>Plan Name</div>
                                    <input type="text" maxLength={30} className="add-user-form-text" value={this.state.planName} placeholder="eg. My_Plan" style={{ width: '350px' }}
                                        onChange={this.handleChange1}
                                    />
                                    <img src={editPlot} style={{ width: '16px', position: 'absolute', top: '208px', left: '425px' }} />
                                    <div style={{ color: "red", fontSize: "12px", height: "5px", padding: "5px 0 0 3px" }}>{this.state.error}</div>
                                </div>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '35px', padding: '5px 30px' }}>
                                <div
                                    className="imageViewButton1"
                                    style={{ cursor: 'pointer', padding: '5px 14px 5px 14px', width: '80px' }}
                                    onClick={() => {
                                        this.setState({ planSaveFlag: false, error: '', })
                                    }}
                                >
                                    Cancel
                                </div>
                                {this.state.planName ? <Link style={{ outline: "none" }}
                                    to={{
                                        pathname: '/aerogcsenterprise/remote_mission/sprayingFlightPlan',
                                        state: {
                                            Distance: this.state.Distance,
                                            altitude: this.state.altitude,
                                            speed: this.state.speed,
                                            flightType: this.state.flightType,
                                            user: this.state.user,
                                            type: this.state.type,
                                            center: { lat: Number(this.state.center.lat), lng: Number(this.state.center.lng) },
                                            drawPlan: this.props.location.state.drawPlan,
                                            editPlan: this.state.editPlan,
                                            project: this.state.project || this.props.location.state.project,
                                            plan: this.state.plan,
                                            projectName: this.state.projectName,
                                            planName: this.state.planName,
                                            linked_map: this.state.linked_map,
                                            clientName: this.state.client_name,
                                            polygon: this.state.polygon?.leafletLayer ? this.state.polygon.coordinates : this.state.polygon,
                                            obstaclePolygons: this.state.obsPolygons.map(obs => ({ id: obs.id, coordinates: obs.leafletLayer.getLatLngs()[0] })),
                                            obstacleCircles: this.state.obsCircle.map((cir => ({ id: cir.id, coordinates: cir.coordinates, radius: cir.radius })))
                                        }
                                    }}
                                >
                                    <div
                                        className="imageViewButton1"
                                        style={{ cursor: 'pointer', padding: '5px 14px 5px 14px', width: '80px' }}
                                    >
                                        Ok
                                    </div>
                                </Link> :
                                    <div
                                        className="imageViewButton1"
                                        style={{ cursor: 'pointer', padding: '5px 14px 5px 14px', opacity: '0.5', width: '80px' }}
                                    >
                                        Ok
                                    </div>}
                            </div>
                        </AnimatedModal>

                        {/* // kml import popup */}
                        <AnimatedModal
                            isOpen={this.state.importKMLFilePop}
                            height="250px"
                            width="450px"
                        >
                            <div style={{ width: '100%', padding: '10px 25px', textAlign: 'left' }}>
                                <div className="services-content-title" style={{ fontSize: '22px' }} >{"Import KML File"}</div>
                                <hr style={{ margin: '15px 0px' }} />
                                <div className="add-user-form-row">
                                    <div className="add-user-form-row-left">
                                        <div className="add-user-form-label">Import KML</div>
                                        <div className="custom-file" style={{ marginTop: '5px' }}>
                                            <input
                                                name="myFile"
                                                accept=".kml"
                                                style={{ width: "400px" }}
                                                onChange={this.importKML}
                                                type="file"
                                                className="custom-file-input"
                                                id="inputGroupFile02" />
                                            <label style={{ borderRadius: '10px' }} className="custom-file-label" for="inputGroupFile02">{this.state.selectedKMLFile ? this.state.selectedKMLFile : "Select KML"}</label>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ color: 'red', fontSize: '12px', lineHeight: '22px', marginBottom: '4px', height: '20px', marginTop: '5px' }}>{this.state.KMLerror ? this.state.KMLerror : ''}</div>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '10px', padding: '5px 25px' }}>
                                <div
                                    className="imageViewButton1"
                                    style={{ cursor: 'pointer', padding: '5px 14px 5px 14px', width: '80px' }}
                                    onClick={() => {
                                        this.setState({ importKMLFilePop: false, KMLerror: '', selectedKMLFile: '', kmlFileCordinates: [] })
                                    }}
                                >
                                    Cancel
                                </div>
                                {this.state.selectedKMLFile ? <div
                                    className="imageViewButton1"
                                    style={{ cursor: 'pointer', padding: '5px 14px 5px 14px', width: '80px' }}
                                    onClick={() => {
                                        this.importKMLPlot()
                                    }}
                                >
                                    Import
                                </div> :
                                    <div
                                        className="imageViewButton1"
                                        style={{ cursor: 'pointer', padding: '5px 14px 5px 14px', opacity: '0.5', width: '80px' }}
                                    >
                                        Import
                                    </div>}
                            </div>
                        </AnimatedModal>

                        <div className="top-bar-new" >
                            {/* <div className="top-bar-text" >
                                FLIGHT PLAN
                            </div> */}
                            <div className="top-bar-text-new">
                                <BreadCrumbs data={[
                                    {
                                        displayName: "Projects",
                                        pathname: "/aerogcsenterprise/projects",
                                        state: {
                                            type,
                                            user,
                                        }
                                    },
                                    {
                                        displayName: this.props.projectName,
                                        pathname: "/aerogcsenterprise/plans",
                                        state: {
                                            type,
                                            user,
                                            project,
                                            plans,
                                            plots,
                                            plotview: true
                                        }
                                    },
                                    {
                                        displayName: this.props.location.state.plot?.plot_name || 'Create plot',
                                    }
                                ]} />
                                <ActiveRole
                                    hideBrackets={true}
                                />
                            </div>
                        </div>
                        {this.state.loading ? <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
                            <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
                        </div> :
                            <div id="rmp-map" className="main-content-new" style={{ padding: "0", height: "calc(100vh - 70px)", }}>
                                <SingleMap
                                    setBaseLayerToState={this.setBaseLayerToState}
                                    initCenter={[this.state.center.lat, this.state.center.lng]}
                                    initZoom={22}
                                    handleMapClick={this.handleMapClick}
                                    maxZoom={23}
                                    setMap={(map) => {
                                        this.setState({ map }, this.initMap)
                                    }}
                                />
                            </div>}
                        {/* Layer selection popup */}
                        {this.state.linked_map ?
                            <div className='slide' style={{ textAlign: 'center', zIndex: 1, fontWeight: 500, border: 'solid 1px rgb(102, 102, 102, 0.3)', position: 'absolute', width: "105px", padding: "4px 12px", bottom: '20px', right: '325px', backgroundColor: 'white', borderRadius: '5px', cursor: 'pointer', fontSize: "12px" }}
                                onClick={() => {
                                    this.setState({ layerSelectionPopup: !this.state.layerSelectionPopup })
                                }}>
                                {this.state.selectedLayer}
                            </div>
                            : <></>}
                        {this.state.layerSelectionPopup && this.state.linked_map ?
                            <div style={{ zIndex: 1, position: 'absolute', width: "105px", fontWeight: 500, fontSize: "12px", bottom: '52px', right: '325px', backgroundColor: 'white', borderRadius: '5px', padding: "1px" }} classname="cardscon content-text">
                                <div onClick={() => this.changeSelectedLayer("Orthomosaic")} style={{ cursor: 'pointer', margin: "3px 4px 3px 12px" }}>
                                    Orthomosaic
                                </div>
                                <hr style={{ margin: "3px" }}></hr>
                                <div onClick={() => this.changeSelectedLayer("NDVI")} style={{ cursor: 'pointer', margin: "3px 4px 3px 12px" }}>
                                    NDVI
                                </div>
                                <hr style={{ margin: "3px" }}></hr>
                                <div onClick={() => this.changeSelectedLayer("VARI")} style={{ cursor: 'pointer', margin: "3px 4px 3px 12px" }}>
                                    VARI
                                </div>
                                <hr style={{ margin: "3px" }}></hr>
                                <div onClick={() => this.changeSelectedLayer("Base")} style={{ cursor: 'pointer', margin: "3px 4px 3px 12px" }}>
                                    BASE
                                </div>
                            </div>
                            :
                            <></>}

                        {/* changeBaseMapView  */}
                        <div className='slide' style={{ border: 'solid 1px rgb(102, 102, 102, 0.3)', position: 'absolute', bottom: '20px', right: '215px', backgroundColor: 'rgba(255, 255, 255, 0.9)', borderRadius: '5px', cursor: 'pointer', zIndex: '1' }}
                            onClick={() => {
                                this.switchBaseLayer()
                            }}>
                            <img style={{ width: '90px', height: '27px', borderRadius: '4px' }} src={this.state.activeBaseLayer === "satelite" ? roadMapView : sateliteView} />
                        </div>

                        {/* plot abd obstacle draw control */}
                        {this.state.selectPlanTypePopup ? '' : <>
                            {this.state.planDrawFlag ? <>
                                <div
                                    className="imageViewButton1"
                                    style={{ opacity: this.state.polygon?.leafletLayer && this.state.plotSaved && this.state.permissions?.[GCS_FEATURES.PLANS].CREATE ? '1' : '0.7', cursor: 'pointer', fontSize: '14px', borderRadius: '15px', width: '135px', height: '30px', background: 'white', position: 'absolute', top: '80px', right: '20px', textAlign: 'left', display: 'flex', alignItems: 'center' }}
                                    onClick={() => {
                                        if (this.state.polygon?.leafletLayer && this.state.plotSaved && this.state.permissions?.[GCS_FEATURES.PLANS].CREATE) {
                                            this.createMissionPlan()
                                        } else {
                                            this.setState({
                                                itemName: "Pl",
                                                processPopupIcon: "ERROR",
                                                processAction: "ADD",
                                                processMessage: "Please save plot first.",
                                                showProcessPopup: true,
                                            })
                                        }
                                    }}
                                >
                                    <img src={addPlot} style={{ width: '16px', marginLeft: '12px', marginRight: '6px' }} /> Create Plan
                                </div>

                                <div
                                    className="imageViewButton1"
                                    style={{ opacity: this.state.polygon?.leafletLayer && this.state.permissions?.[GCS_FEATURES.PLANS].EDIT ? '1' : '0.7', cursor: 'pointer', fontSize: '14px', borderRadius: '15px', width: '135px', height: '30px', background: 'white', position: 'absolute', top: '120px', right: '20px', textAlign: 'left', display: 'flex', alignItems: 'center' }}
                                    onClick={() => {
                                        if (this.state.polygon?.leafletLayer && this.state.permissions?.[GCS_FEATURES.PLANS].EDIT) {
                                            this.clearData()
                                        }
                                    }}
                                >
                                    <img src={clearPlot} style={{ width: '18px', marginLeft: '12px', marginRight: '6px' }} /> Clear
                                </div>

                                <div
                                    className="imageViewButton1"
                                    style={{ opacity: this.state.polygon?.leafletLayer && this.state.permissions?.[GCS_FEATURES.PLANS].EDIT ? '1' : '0.7', cursor: 'pointer', fontSize: '14px', borderRadius: '15px', width: '135px', height: '30px', background: 'white', position: 'absolute', top: '160px', right: '20px', textAlign: 'left', display: 'flex', alignItems: 'center' }}
                                    onClick={() => {
                                        if (this.state.permissions?.[GCS_FEATURES.PLANS].EDIT) {
                                            if (this.state.polygon?.leafletLayer) {
                                                this.savePlotClick()
                                            } else {
                                                this.setState({
                                                    itemName: "Pl",
                                                    processPopupIcon: "ERROR",
                                                    processAction: "ADD",
                                                    processMessage: "Create plot first before saving.",
                                                    showProcessPopup: true,
                                                })
                                            }
                                        }
                                    }}
                                >
                                    <img src={plotSave} style={{ width: '20px', marginLeft: '12px', marginRight: '6px' }} /> Save
                                </div>
                                <div
                                    className="imageViewButton1"
                                    style={{ opacity: !(this.state.polygon?.leafletLayer) && this.state.permissions?.[GCS_FEATURES.PLANS].EDIT ? '1' : '0.7', cursor: 'pointer', fontSize: '14px', borderRadius: '15px', width: '135px', height: '30px', background: 'white', position: 'absolute', top: '200px', right: '20px', textAlign: 'left', display: 'flex', alignItems: 'center' }}
                                    onClick={() => {
                                        if (!(this.state.polygon?.leafletLayer) && this.state.permissions?.[GCS_FEATURES.PLANS].EDIT) this.setState({ importKMLFilePop: true })
                                    }}
                                >
                                    <img src={plotSave} style={{ width: '20px', marginLeft: '12px', marginRight: '6px' }} /> Import KML
                                </div>
                            </> : <>
                                <div
                                    className="imageViewButton1"
                                    style={{ cursor: 'pointer', fontSize: '14px', borderRadius: '15px', width: '110px', height: '30px', background: 'white', position: 'absolute', top: '80px', right: '20px', textAlign: 'left', display: 'flex', alignItems: 'center' }}
                                    onClick={() => {
                                        if (this.state.polygon?.leafletLayer && this.state.obstacleDraw == false) {
                                            this.setState((state) => ({
                                                ...state,
                                                obstacleOptionPopup: true,
                                            }))
                                        }
                                    }}
                                >
                                    <img src={addPlot} style={{ width: '16px', marginLeft: '15px', marginRight: '10px' }} /> Add
                                </div>


                                <div
                                    className="imageViewButton1"
                                    style={{ cursor: 'pointer', fontSize: '14px', borderRadius: '15px', width: '110px', height: '30px', background: 'white', position: 'absolute', top: '120px', right: '20px', textAlign: 'left', display: 'flex', alignItems: 'center' }}
                                    onClick={() => {
                                        this.clearObstacle()
                                    }}
                                >
                                    <img src={clearPlot} style={{ width: '18px', marginLeft: '15px', marginRight: '10px' }} /> Clear
                                </div>

                                <div
                                    className="imageViewButton1"
                                    style={{ cursor: 'pointer', fontSize: '14px', borderRadius: '15px', width: '110px', height: '30px', background: 'white', position: 'absolute', top: '160px', right: '20px', textAlign: 'left', display: 'flex', alignItems: 'center' }}
                                    onClick={() => {
                                        this.savePoly()
                                    }}
                                >
                                    <img src={plotSave} style={{ width: '20px', marginLeft: '15px', marginRight: '10px' }} /> Save
                                </div>
                            </>}
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', bottom: '20px', right: '20px', background: 'white', borderRadius: '30px' }}>
                                <div className="imageViewButton" style={{ display: 'flex', height: '30px', borderRadius: '30px', outline: 'none' }} >
                                    <div className={this.state.planDrawFlag == false ? 'imageViewButtonListActive' : 'imageViewButtonList'} style={{ width: "90px", height: '30px', borderRadius: '30px 0px 0px 30px', }}
                                        onClick={() => {
                                            if (this.state.permissions?.[GCS_FEATURES.PLANS].EDIT) this.obstacleClick()
                                        }}>
                                        <div>Obstacle</div>
                                    </div>
                                    <div className={this.state.planDrawFlag ? 'imageViewButtonListActive' : 'imageViewButtonList'} style={{ width: "90px", height: '30px', borderRadius: '0px 30px 30px 0px' }}
                                        onClick={() => {
                                            this.poltClick()
                                        }}>
                                        <div>Plot</div>
                                    </div>
                                </div>
                            </div>

                        </>}
                    </div>
                </div>
            </>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        projectName: state.project.projectName,
        clientName: state.project.clientName,
        planName: state.project.planName,
        dateAndTime: state.project.dateAndTime,
        activeTeamId: state.profile.activeTeamId,
        activeTeam: state.profile.activeTeam,
        isLoggedInUserOwner: state.profile.isLoggedInUserOwner
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        addPlan: (payload) => dispatch(addPlan(payload)),
        addProjectDetails: (payload) => dispatch(addProjectDetails(payload))
    };
};

export default GoogleApiWrapper({
    apiKey: "AIzaSyBuxfUtRZSEUT2QrbZA8zUsG58lZUYYmwI",
    libraries: ["drawing", "places"],
    LoadingContainer: LoadingContainer,
})(connect(mapStateToProps, mapDispatchToProps)(RMPSprayingFlightPlot));