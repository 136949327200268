import React, { Component } from "react";
import * as API from "./api";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { ActivityPopup } from "./ReusableComponents/ActivityPopup";
import { AnimatedModal } from "./ReusableComponents/AnimatedModal";
import ButtonWithIcon from "./ReusableComponents/ButtonWithIcon";
import { convertInMbGB } from "./ReusableComponents/reusableFunctions";
import { SetRestrictSesssionExpire } from "./helperComponents/OnIdleHandlerWrapper";
import orthoUploaderIcon from "./icons/orthoUploaderIcon.svg"

let prevLoadedData = 0
let chunkSize = 10 * 1024 * 1024;

export default class AdminOrthoImporter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      targetFilesArr: [],
      uploaded_data: [],
      imageLimitCount: "0",
      progress: 0,
      _progressInfos: [],
      toShowProgressOf: 0,
      show: false,
      uploadedCount: 0,
      totalImages: 0,
      isCancel: false,
      showCancelPopup: false,
      cancelledAt: 0,
      action: 0,
      currentFileIndex: null,
      lastUploadedFileIndex: null,
      currentChunkIndex: null,
      item: undefined,
      activityPopup: false,
      metadata: null,
      uploadedData: 0,
      uploadFileSize: 0,
    };
  }


  cancelUpload = () => {
    prevLoadedData = 0;
    this.setState({
      isCancel: true,
      showCancelPopup: false,
      show: false,
      targetFilesArr: [],
      toShowProgressOf: 0,
      lastUploadedFileIndex: null,
      uploadedCount: 0,
      currentChunkIndex: null,
      currentFileIndex: null,
      uploadCheckPopup: false,
      activityPopup: false,
    })
  }

  uploadChunk = (readerEvent, fileName, fileSize, currentChunkIndex, currentFileIndex) => {
    const data = new FormData()
    data.append('file', readerEvent)
    data.append('name', fileName);
    data.append('size', fileSize);
    data.append('currentChunkIndex', currentChunkIndex);
    data.append('totalChunks', Math.ceil(fileSize / chunkSize));

    if (fileName.toLowerCase().includes(".tif") || fileName.toLowerCase().includes(".tiff")) {
      API.importOrthomosaic(data).then(response => {
        response.json().then((res) => {
          const chunks = fileSize / chunkSize;
          if (response.status == 200) {
            if (currentChunkIndex === Math.ceil(chunks - 1)) {
              prevLoadedData = 0
              this.setState({
                uploadedData: fileSize,
                uploadFileSize: fileSize,
                lastUploadedFileIndex: currentFileIndex,
                currentChunkIndex: null,
                toShowProgressOf: ((currentFileIndex + 1) * 100) / this.state.targetFilesArr.length,
                uploadedCount: (currentFileIndex + 1)
              }, () => {
                if (this.state.uploadedCount === this.state.targetFilesArr.length) {
                  SetRestrictSesssionExpire(false);
                }
              })
            } else {
              let fileUploadedPercentage = currentChunkIndex / chunks * 100
              this.setState({
                uploadedData: this.state.uploadedData + chunkSize,
                uploadFileSize: fileSize,
                toShowProgressOf: this.state.toShowProgressOf < 100 ? this.state.toShowProgressOf + 100 / ((this.state.targetFilesArr.length * 100) / (fileUploadedPercentage - prevLoadedData)) : 100//this.state.toShowProgressOf + (100 / ((this.state.targetFilesArr.length * 100) / fileUploadedPercentage))
              }, () => {
                this.setState({ currentChunkIndex: currentChunkIndex + 1 })
                prevLoadedData = fileUploadedPercentage;
                if (this.state.toShowProgressOf < 0 || this.state.toShowProgressOf === 100) {
                  prevLoadedData = 0
                  this.setState({ toShowProgressOf: 0 });
                }
              })
            }
          } else if (response.status == 500) {
            this.readAndUploadCurrentChunk();
          } else if (response.status == 402) {
            this.props.showUploadErrWarning()
          } else if (response.status == 400) {
            this.setUploadFailedError(res.message)
          }
        })
      }).catch(error => {
        SetRestrictSesssionExpire(false)
        console.log("catch error : ", error);
        this.setState({
          activityPopup: {
            icon: "ERROR",
            message: `${error}`,
          }
        });
        this.cancelUpload();
      });
    } else {
      this.setState({
        activityPopup: {
          icon: "ERROR",
          message: "Incorrect File, Please choose valid extention file !",
        }
      });
      this.cancelUpload();
      SetRestrictSesssionExpire(false)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentChunkIndex !== this.state.currentChunkIndex && this.state.currentChunkIndex !== null && !this.state.isCancel) {
      this.readAndUploadCurrentChunk();
    }

    if (prevState.currentFileIndex !== this.state.currentFileIndex) {
      if (this.state.currentFileIndex !== null) {
        this.setState({ currentChunkIndex: 0 })
      }
    }

    if (prevState.lastUploadedFileIndex !== this.state.lastUploadedFileIndex) {
      if (this.state.lastUploadedFileIndex === null) return;
      const isLastFile = this.state.lastUploadedFileIndex === this.state.targetFilesArr.length - 1;
      const nextFileIndex = isLastFile ? null : this.state.currentFileIndex + 1;
      this.setState({ currentFileIndex: nextFileIndex })
    }
  }

  readAndUploadCurrentChunk = () => {
    const file = this.state.targetFilesArr[this.state.currentFileIndex];
    if (!file) return;
    else {
      chunkSize = chunkSize > file.size ? file.size : chunkSize
      const from = this.state.currentChunkIndex * chunkSize;
      const to = from + chunkSize;
      const blob = file.slice(from, to);
      this.uploadChunk(blob, file.name, file.size, this.state.currentChunkIndex, this.state.currentFileIndex);
    }
  }

  // Function to execute when overwrite option is selected
  setUploadFailedError = (msg) => {
    this.setState({
      activityPopup: {
        item: "Upload Failed !",
        action: "ERROR",
        icon: "ERROR",
        message: msg,
        isCancel: true,
        show: false
      }
    }, () => {
      this.cancelUpload();
    });
  }

  uploadFiles = async () => {
    if (this.state.targetFilesArr.length > 0) {
      if (this.state.currentFileIndex === null) {
        this.setState({
          currentFileIndex: this.state.lastUploadedFileIndex === null ? 0 : this.state.lastUploadedFileIndex + 1,
          toShowProgressOf: 0,
          uploadFileSize: this.state.targetFilesArr[0]?.size,
          show: true,
          uploadCheckPopup: false,
          activityPopup: undefined,
          isCancel: false,
          uploadedCount: 0
        });
      }
    }
  }

  getFilteredFiles = (files) => {
    return (
      files.filter((file) => (file.name.toLowerCase()).includes(".tiff") || (file.name.toLowerCase()).includes(".tif"))
    )
  }

  onChangeListenerFile = async (event) => {
    let targetFilesArr = this.getFilteredFiles(Array.from(event.target.files))
    if (targetFilesArr.length > 0) {
      this.setState((state) => ({
        ...state,
        targetFilesArr,
        uploadedData: 0,
      }), () => {
        chunkSize = Math.round(navigator.connection.downlink * 1024 * 1024)
        this.uploadFiles()
      });
    }
    else {
      this.setState({
        activityPopup: {
          icon: "ERROR",
          message: "Incorrect File, Please choose valid extention file !",
        }
      });
    }
  }

  onClickListenerFile = (event) => {
    event.target.value = ''
    this.setState({
      uploadedData: 0,
      uploadFileSize: 0,
    })
  }

  close() {
    if (this.state.uploadedCount !== this.state.targetFilesArr.length) {
      this.setState({
        activityPopup: {
          icon: "WARNING",
          message: "Your file upload is still in progress, Are you sure you want to cancel it?",
        }
      });
    } else {
      this.setState((prevState) => ({
        ...prevState,
        show: this.state.toShowProgressOf >= 100 ? false : true,
        targetFilesArr: [],
        toShowProgressOf: 100,
        lastUploadedFileIndex: null,
        uploadedCount: 0,
        currentChunkIndex: null,
        currentFileIndex: null,
      }));
    }

  }

  hide() {
    this.setState((prevState) => ({
      ...prevState,
      show: this.state.toShowProgressOf >= 100 ? false : true,
    }));
  }

  componentWillUnmount() {
    SetRestrictSesssionExpire(false)
  }

  // componentWillMount() {
  //   SetRestrictSesssionExpire(true)
  // }

  render() {

    return (
      <>
        <ActivityPopup
          item={this.state.activityPopup?.item}
          open={this.state.activityPopup}
          icon={this.state.activityPopup?.icon}
          action={this.state.activityPopup?.action}
          msg={this.state.activityPopup?.message}
          close={() => this.setState({
            activityPopup: false
          })}
          onClickOk={() => {
            if (this.state.uploadedCount !== this.state.targetFilesArr.length) {
              this.cancelUpload()
            } else {
              this.setState({
                activityPopup: false
              });
            }
          }}
        />
        <React.Fragment>
          <div
            className="add-user-form-text-wrapper"
            style={{ marginTop: "0px" }}
          >
            <input
              name="company_logo"
              id="hiddenFileInputUpload1"
              type="file"
              className="add-user-form-file"
              required
              onClick={(event) => this.onClickListenerFile(event)}
              onChange={(e) => {
                this.onChangeListenerFile(e);
              }}
              accept={".tif , .tiff,"}
              multiple={false}
              style={{ display: "none" }}
            />
          </div>

          <AnimatedModal
            isOpen={this.state.show}
            onRequestClose={() => this.hide()}
            height="300px"
            width="450px"
          >
            <div>
              <div className="services-content-title" style={{ textAlign: 'center', marginTop: '15px' }}>{this.state.toShowProgressOf == 100 ? "Upload Completed !" : this.props.fileType == "PntCld" ? "Uploading File..." : "Uploading Image..."}</div>
              <div style={{}}>
                <div style={{ textAlign: 'center', marginTop: "4%", marginBottom: "2%" }}>
                  <div style={{ width: 175, height: 175, margin: 'auto', fontSize: "10px" }}>
                    <CircularProgressbar
                      value={this.state.toShowProgressOf}
                      text={`${convertInMbGB(this.state.uploadedData)}/${convertInMbGB(this.state.uploadFileSize)}`}
                      styles={{
                        text: {
                          fill: '#2989cf',
                          fontSize: '9px',
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'right', marginTop: '4%', marginRight: '6%' }}>
              <ButtonWithIcon
                text={this.state.uploadedCount === this.state.targetFilesArr.length ? "Close" : "Cancel"}
                width="94px" isBtnActive
                onClick={() => this.close()}
              />
            </div>

          </AnimatedModal>
        </React.Fragment>
        <div className="sidecontent-new" id="sidecontent" onClick={() => { document.getElementById("hiddenFileInputUpload1").click(); }}>
          <div className="sidebar-icon-new">
            <img className="sidebar-icon-img-new" src={orthoUploaderIcon} alt="serviceIcon" />
          </div>
          <div name="service" className={"sidebaropt-new"} style={{ color: '#FFFFFF99'  }}  >
           Upload GeoTiff
          </div>
        </div>
      </>
    );
  }
}
