import React, { Component } from "react";
import * as API from "../api.js";
import "../style.css";
import { addUserAnalytic, updateOutTime, checkSubscriptionAndStorage } from '../ReusableComponents/reusableFunctions.js';
import deleteIcon from "../trash.svg";
import dateFormat from 'dateformat'
import { AnimatedModal } from '../ReusableComponents/AnimatedModal'
import { connect } from "react-redux";
import { updateProfileDetails } from '../redux'
import editIcon from "../edit.svg";
import { ActivityPopup } from '../ReusableComponents/ActivityPopup.js';
import Dropdown from 'react-bootstrap/Dropdown';
import btnAdd from '../icons/btnAdd.svg';
import MenuDark from '../icons/MenuDark.png';
import { Link } from "react-router-dom";
import noLeaseVector from '../icons/noLeaseVector.png'
import SubscriptionExpireModal from "../subscription/Reusable/SubscriptionExpireModal.js";
import AppConstants from "../AppConstants.js";
import LoaderComponent from "../ReusableComponents/LoaderComponent.js";
import { DeleteComponentTable } from "../ReusableComponents/Menu.js";

class userAllLease extends Component {
    state = {
        user: {},
        type: "",
        loading: true,
        sidebarMode: this.props.location.state
            ? this.props.location.state.sidebarMode
            : "shrunk",
        addCustomerPopUp: false,
        customerAddress: '',
        contactNumber: '',
        customerEmail: '',
        customerGovIdNo: '',
        customerName: '',
        customerPilotLicenseId: '',
        itemName: "",
        showProcessPopup: false,
        processPopupIcon: "",
        processAction: "",
        processMessage: "",
        usersCustomers: [],
        drones: [],
        deleteUserCust: '',
        customerId: '',
        lease: [],
        minDate: new Date(),
        leaseOnPopUp: false,
        leaseDetailsSelect: {},
        invoiceType: false,
        withGst: false,
        totalValue: 0,
        gst: 18,
        discount: 0,
        dailycharges: 0,
        selectedLeaseDays: 0,
        selectedLeaseAmount: 0,
        selectedLeaseGstAmount: 0,
        leaseGstAmount: 0,
        addLeaseDronePopUp: false,
        editLease: {},
        edit: false,
        fromDate: '',
        toDate: '',
        selectedCustomer: {},
        selectedCustomerName: '',
        selectedDrone: {},
        selectedDroneName: '',
        showSubscriptionExpirePopup: false,
        droneLoading: true,
        custLoading: true,
    };


    droneForEdit = (lease) => {
        return new Promise((resolve, reject) => {
            for (let i = 0; i < this.state.drones.length; i++) {
                if (lease.drone_id == this.state.drones[i].drone_id) {
                    resolve({ selectedDrone: this.state.drones[i], selectedDroneName: i })
                }
            }
        })
    }

    customerForEdit = (lease) => {
        return new Promise((resolve, reject) => {
            for (let i = 0; i < this.state.usersCustomers.length; i++) {
                if (lease.customer_id == this.state.usersCustomers[i].id) {
                    resolve({ selectedCustomer: this.state.usersCustomers[i], selectedCustomerName: i })
                }
            }
        })
    }

    updateLeaseData = async (lease) => {
        let droneForEdit = await this.droneForEdit(lease)
        console.log('droneForEdit', droneForEdit);
        let customerForEdit = await this.customerForEdit(lease)
        console.log('customerForEdit', customerForEdit);
        this.setState((state) => ({
            ...state,
            edit: true,
            editLease: lease,
            fromDate: dateFormat(lease.startdate, 'yyyy-mm-dd'),
            toDate: dateFormat(lease.enddate, 'yyyy-mm-dd'),
            selectedCustomer: customerForEdit.selectedCustomer,
            selectedCustomerName: `${customerForEdit.selectedCustomerName}`,
            selectedDrone: droneForEdit.selectedDrone,
            selectedDroneName: `${droneForEdit.selectedDroneName}`,
        }), () => {
            this.setState((state) => ({
                ...state,
                addLeaseDronePopUp: true
            }))
        })
    }

    getDronesData = () => {
        API.gcsDrones().then((result) => {
            this.setState(state => ({
                ...state,
                drones: result?.drones?.filter(drone => drone.is_registered == true),
                unregisteredAssignedDrones: result.unregisteredAssignedDrones,
            }), () => {
                this.setState({ droneLoading: false })
            })
        }, (e) => {
            console.log(e)
        })
    }

    getAllUserCustomer = () => {
        API.getAllUserCustomer('lease', 0).then((data) => {
            console.log('data', data);
            if (data.usersCustomers && data.usersCustomers.length > 0) {
                this.setState((state) => ({
                    ...state,
                    usersCustomers: data.usersCustomers

                }), () => {
                    this.setState({ custLoading: false })
                })
            } else {
                this.setState((state) => ({
                    ...state,
                    usersCustomers: []
                }), () => {
                    this.setState({ custLoading: false })
                })
            }
        }, (e) => {
            console.log(e)
        })
    }

    addUserCustomerDroneLease = () => {
        this.setState((state) => ({
            ...state,
            addLeaseDronePopUp: false,
            itemName: "Lease",
            processPopupIcon: "WAIT",
            processAction: "ADD",
            processMessage: null,
            showProcessPopup: true,
        }), () => {
            API.addUserCustomerDroneLease(this.state.selectedCustomer.id, this.state.selectedDrone.drone_id, this.state.fromDate, this.state.toDate, this.state.selectedDrone.drone_rent)
                .then((leaseRes) => {
                    console.log('leaseRes', leaseRes);
                    if (leaseRes.message == "OK") {
                        this.setState((state) => ({
                            ...state,
                            addLeaseDronePopUp: false,
                            itemName: "Lease",
                            processPopupIcon: "COMPLETE",
                            processAction: "ADD",
                            processMessage: 'Lease added sucessfully',
                            showProcessPopup: true,
                            fromDate: '',
                            toDate: '',
                            selectedDroneName: '',
                            selectedDrone: '',
                            selectedCustomer: {},
                            selectedCustomerName: '',
                        }), () => {
                            this.getAllUserDronesLease()
                        })
                    } else {
                        this.setState((state) => ({
                            ...state,
                            addLeaseDronePopUp: false,
                            itemName: "Lease",
                            processPopupIcon: "ERROR",
                            processAction: "ADD",
                            processMessage: leaseRes.message,
                            showProcessPopup: true,
                        }))
                    }
                }, (e) => {
                    this.setState((state) => ({
                        ...state,
                        addLeaseDronePopUp: false,
                        itemName: "Lease",
                        processPopupIcon: "ERROR",
                        processAction: "ADD",
                        processMessage: 'Something went wrong while adding lease.',
                        showProcessPopup: true,
                    }))
                })
        })
    }

    onChangeListenerDrone = (e) => {
        e.preventDefault();
        let key = e.target.value
        console.log(key);
        if (e.target.value == -1) {
            this.setState((state) => ({
                ...state,
                selectedDrone: {},
                selectedDroneName: "",
            }))
        } else {
            this.setState((state) => ({
                ...state,
                selectedDrone: this.state.drones[key],
                selectedDroneName: key,
            }))
        }
    }

    onChangeListenerCust = (e) => {
        e.preventDefault();
        let key = e.target.value
        console.log(key);
        if (e.target.value == -1) {
            this.setState((state) => ({
                ...state,
                selectedCustomer: {},
                selectedCustomerName: "",
            }))
        } else {
            this.setState((state) => ({
                ...state,
                selectedCustomer: this.state.usersCustomers[key],
                selectedCustomerName: key
            }))
        }
    }

    componentWillUnmount() { updateOutTime(this.state.outId) }

    parentSidebarSwitch = () => {
        this.setState((state) => ({
            ...state,
            sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
        }))
    }

    getAllUserDronesLease = () => {
        API.getAllUserDronesLease(null, null).then((leaseData) => {
            console.log('leaseData', leaseData);
            this.setState(state => ({
                ...state,
                lease: leaseData.usersCustomersDroneLease,
            }), () => { this.setState({ loading: false }) })
        }, (e) => { console.log(e) })
    }

    componentWillMount() {
        addUserAnalytic(this.props.location.pathname).then(id => this.setState({ outId: id }))
        document.title = "User-Drone_lease";

        checkSubscriptionAndStorage(AppConstants.SERVICES.AEROGCS).then(data => {
            if (data.error || data.storageExceed || data.isExpired || !data.isValidStoragePlan) this.setState({ showSubscriptionExpirePopup: { ...data, user: this.props?.location?.state?.user } });
            else this.setState({ showSubscriptionExpirePopup: false, infoPopup: true });
        })

        if (this.props.location.state) {
            let { user, type } = this.props.location.state;
            this.setState((state) => ({
                ...state,
                user,
                type,
            }), () => {
                this.getAllUserCustomer()
                this.getDronesData()
                this.getAllUserDronesLease()
            })
        } else {
            API.getUserType().then(([blah, type, user]) => {
                this.setState((state) => ({
                    ...state,
                    user,
                    type
                }), () => {
                    this.getAllUserDronesLease()
                })
            }, (e) => {
                window.location.replace("/login")
            })
        }
    }


    deleteUserCustomerDroneLease = () => {
        this.setState((state) => ({
            ...state,
            addCustomerPopUp: false,
            itemName: "Lease",
            processPopupIcon: "WAIT",
            processAction: "DELETE",
            processMessage: null,
            showProcessPopup: true,
        }), () => {
            API.deleteUserCustomerDroneLease(this.state.deleteLeaseId).then((deleteRes) => {
                console.log('deleteRes', deleteRes);
                if (deleteRes.message == "OK") {
                    this.setState((state) => ({
                        ...state,
                        addCustomerPopUp: false,
                        itemName: "Lease",
                        processPopupIcon: "COMPLETE",
                        processAction: "DELETE",
                        processMessage: 'Lease deleted sucessfully',
                        showProcessPopup: true,
                        deleteUserCust: ''
                    }), () => {
                        this.getAllUserDronesLease()
                    })
                } else {
                    this.setState((state) => ({
                        ...state,
                        addCustomerPopUp: false,
                        itemName: "Lease",
                        processPopupIcon: "ERROR",
                        processAction: "DELETE",
                        processMessage: deleteRes.message,
                        showProcessPopup: true,
                    }))
                }
            }, (e) => {
                this.setState((state) => ({
                    ...state,
                    addCustomerPopUp: false,
                    itemName: "Customer",
                    processPopupIcon: "ERROR",
                    processAction: "DELETE",
                    processMessage: 'Something went wrong while deleting lease.',
                    showProcessPopup: true,
                }))
            })
        })
    }

    updateUserCustomerDroneLease = () => {
        this.setState((state) => ({
            ...state,
            addLeaseDronePopUp: false,
            itemName: "Lease",
            processPopupIcon: "WAIT",
            processAction: "UPDATE",
            processMessage: null,
            showProcessPopup: true,
        }), () => {
            API.updateUserCustomerDroneLease(this.state.fromDate, this.state.toDate, this.state.editLease.id, this.state.selectedDrone.drone_id, this.state.selectedDrone.drone_rent)
                .then((leaseRes) => {
                    console.log('leaseRes', leaseRes);
                    if (leaseRes.message == "OK") {
                        this.setState((state) => ({
                            ...state,
                            addLeaseDronePopUp: false,
                            itemName: "Lease",
                            processPopupIcon: "COMPLETE",
                            processAction: "UPDATE",
                            processMessage: 'Lease updated sucessfully',
                            showProcessPopup: true,
                            selectedCustomer: '',
                            selectedCustomerName: '',
                            fromDate: '',
                            toDate: '',
                            edit: false,
                            editLease: '',
                        }), () => {
                            this.getAllUserDronesLease()
                        })
                    } else {
                        this.setState((state) => ({
                            ...state,
                            addLeaseDronePopUp: false,
                            itemName: "Lease",
                            processPopupIcon: "ERROR",
                            processAction: "UPDATE",
                            processMessage: leaseRes.message,
                            showProcessPopup: true,
                        }))
                    }
                }, (e) => {
                    this.setState((state) => ({
                        ...state,
                        addLeaseDronePopUp: false,
                        itemName: "Lease",
                        processPopupIcon: "ERROR",
                        processAction: "UPDATE",
                        processMessage: 'Something went wrong while updating lease.',
                        showProcessPopup: true,
                    }))
                })
        })
    }


    closeActivityPopup = () => {
        this.setState((state) => ({
            ...state,
            showProcessPopup: false,
            processMessage: null,
        }))
    }

    totalIvoiceAmout = () => {
        if (this.state.withGst) {
            let amountWithDiscount = (Number(this.state.discount) / 100) * Number(this.state.selectedLeaseAmount)
            let total = (Number(this.state.leaseGstAmount) + Number(this.state.selectedLeaseAmount)) - Number(amountWithDiscount)
            this.setState({ totalValue: total, })
        } else {
            let amountWithDiscount = (Number(this.state.discount) / 100) * Number(this.state.selectedLeaseAmount)
            let total = Number(this.state.selectedLeaseAmount) - Number(amountWithDiscount)
            this.setState({ totalValue: total })
        }
    }



    render() {
        let { user, type, } = this.state;
        return <div className="wrapper">
            <div
                className="right-content-new" >
                <div className="top-bar-new">
                    <div className="top-bar-text-new">All Lease</div>
                </div>
                <ActivityPopup
                    item={this.state.itemName}
                    open={this.state.showProcessPopup}
                    icon={this.state.processPopupIcon}
                    action={this.state.processAction}
                    msg={this.state.processMessage}
                    close={() => this.closeActivityPopup()}
                    onClickOk={() => this.deleteUserCustomerDroneLease()}
                />

                {
                    this.state.showSubscriptionExpirePopup ?
                        <SubscriptionExpireModal
                            data={this.state.showSubscriptionExpirePopup}
                        /> : <></>
                }

                <AnimatedModal
                    isOpen={this.state.leaseOnPopUp}
                    height="400px"
                    width="600px"
                >
                    <div style={{ width: "100%", height: "100%", padding: '5px 40px' }}>
                        <div className="services-content-title"
                            style={{
                                margin: "10px 0px",
                                textAlign: "left",
                                fontWeight: "500",
                                color: "#3C3C3C",
                            }}
                        >
                            Lease Details
                        </div>
                        <hr style={{ margin: '5px 0px 0px 0px', padding: '0px', width: '100%' }} />


                        {this.state.invoiceType ? <div style={{ margin: '10px 0px', textAlign: 'left', }}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '15px' }}>
                                <div>Daily Charges</div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span style={{ marginRight: '-27px', zIndex: '1' }}>Rs.</span>
                                    <input type='text' maxLength={5} className='add-user-form-text2' style={{ width: '120px', paddingLeft: '35px' }}
                                        value={this.state.dailycharges} onChange={(e) => {
                                            if (!isNaN(e.target.value)) {
                                                let selectedLeaseAmount = Number(e.target.value) * Number(this.state.selectedLeaseDays)
                                                this.setState({
                                                    selectedLeaseAmount: selectedLeaseAmount,
                                                    dailycharges: Number(e.target.value),
                                                    leaseGstAmount: Math.round((Number((this.state.gst / 100) * Number(selectedLeaseAmount))))
                                                }, () => {
                                                    this.totalIvoiceAmout()
                                                })
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '15px' }}>
                                <div>Charges for {this.state.selectedLeaseDays} days</div> <div>Rs. {this.state.selectedLeaseAmount}</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '15px' }}>
                                <div style={{ display: 'flex', alignItems: 'center', }}>
                                    <input type='checkbox' className='add-user-form-checkbox' style={{ marginRight: '15px', cursor: 'pointer' }}
                                        value={this.state.withGst} onChange={() => {
                                            this.setState({ withGst: !this.state.withGst }, () => { this.totalIvoiceAmout() })
                                        }}
                                    />Include GST @ 18%
                                </div>
                                <div>Rs. {this.state.leaseGstAmount}</div>
                            </div>
                            <     div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '15px' }}>
                                <div>Discount</div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <input type='text' className='add-user-form-text2' style={{ width: '80px', paddingRight: '25px' }} maxLength={2}
                                        value={this.state.discount} onChange={(e) => {
                                            if (!isNaN(e.target.value)) {
                                                this.setState({ discount: Number(e.target.value) }, () => { this.totalIvoiceAmout() })
                                            }
                                        }}
                                    /> <span style={{ marginLeft: '-20px' }}>%</span>
                                </div>
                            </div>
                            <hr style={{ margin: '20px 0px 0px 0px', padding: '0px', width: '100%' }} />
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px', fontWeight: '500' }}>
                                <div>Total Amount</div> <div style={{ color: '#2989CF' }}>Rs. {this.state.totalValue}</div>
                            </div>

                        </div> : <>
                            <div style={{ margin: '18px 0px', display: 'flex', width: '100%' }}>
                                <div style={{ width: '60%' }}>
                                    <div style={{ textAlign: "left", fontSize: '12px', color: '#666666' }}>Drone Id</div>
                                    <div style={{ textAlign: "left", fontSize: '14px', }}>{this.state.leaseDetailsSelect.drone_id}</div>
                                </div>
                                <div style={{ width: '40%' }}>
                                    <div style={{ textAlign: "left", fontSize: '12px', color: '#666666' }}>Mob. No.</div>
                                    <div style={{ textAlign: "left", fontSize: '14px', }}>{this.state.leaseDetailsSelect.contact_number ? this.state.leaseDetailsSelect.contact_number : '-'}</div>
                                </div>
                            </div>

                            <div style={{ margin: '18px 0px', display: 'flex', width: '100%' }}>
                                <div style={{ width: '60%' }}>
                                    <div style={{ textAlign: "left", fontSize: '12px', color: '#666666' }}>Name</div>
                                    <div style={{ textAlign: "left", fontSize: '14px', }}>{this.state.leaseDetailsSelect.customer_name ? this.state.leaseDetailsSelect.customer_name : 'Unknown'}</div>
                                </div>
                                <div style={{ width: '40%' }}>
                                    <div style={{ textAlign: "left", fontSize: '12px', color: '#666666' }}>Status</div>
                                    <div style={{ textAlign: "left", fontSize: '14px', }}>{this.state.leaseDetailsSelect.status}</div>
                                </div>
                            </div>

                            <div style={{ margin: '18px 0px', display: 'flex', width: '100%' }}>
                                <div style={{ width: '60%' }}>
                                    <div style={{ textAlign: "left", fontSize: '12px', color: '#666666' }}>GST No.</div>
                                    <div style={{ textAlign: "left", fontSize: '14px', }}>{this.state.leaseDetailsSelect.gst_no ? this.state.leaseDetailsSelect.gst_no : '-'}</div>
                                </div>
                                <div style={{ width: '40%' }}>
                                    <div style={{ textAlign: "left", fontSize: '12px', color: '#666666' }}>Date</div>
                                    <div style={{ textAlign: "left", fontSize: '14px', }}>{dateFormat(this.state.leaseDetailsSelect.startdate, 'dd/mm/yyyy')}  To  {dateFormat(this.state.leaseDetailsSelect.enddate, 'dd/mm/yyyy')}</div>
                                </div>
                            </div>

                            <div style={{ margin: '18px 0px', display: 'flex', width: '100%' }}>
                                <div style={{ width: '100%' }}>
                                    <div style={{ textAlign: "left", fontSize: '12px', color: '#666666' }}>Address</div>
                                    <div style={{ textAlign: "left", fontSize: '14px', }}>{this.state.leaseDetailsSelect.address ? this.state.leaseDetailsSelect.address : '-'}</div>
                                </div>
                            </div>
                        </>}
                        <div style={{ display: 'flex', alignItems: 'center', bottom: '25px', width: '85%', position: 'absolute', justifyContent: 'end', }}>
                            <div className="addcustomer-button-empty-dashboard"
                                style={{ cursor: "pointer", padding: "8px 38px", background: "white", marginRight: '20px' }}
                                onClick={() => {
                                    this.setState((state) => ({
                                        ...state,
                                        leaseOnPopUp: false,
                                        leaseDetailsSelect: {},
                                        invoiceType: false,
                                        withGst: false,
                                        totalValue: 0,
                                        gst: 18,
                                        discount: 0,
                                    }));
                                }}
                            >
                                Close
                            </div>

                            {this.state.invoiceType ?
                                <Link to={{
                                    pathname: '/aerogcsenterprise/lease_drone_invoice',
                                    state: {
                                        user: this.state.user,
                                        type: this.state.type,
                                        lease: this.state.leaseDetailsSelect,
                                        withGst: this.state.withGst,
                                        totalValue: this.state.totalValue,
                                        gst: this.state.gst,
                                        discount: this.state.discount,
                                        dailycharges: this.state.dailycharges,
                                        paid_status: false,
                                    }
                                }}
                                >
                                    <div className="addcustomer-button-empty-dashboard"
                                        style={{ cursor: "pointer", padding: "8px 25px", background: "#2989CF", color: 'white' }}>
                                        Generate
                                    </div>
                                </Link>
                                : <>{this.state.leaseDetailsSelect.invoiceGen ? <Link to={{
                                    pathname: '/aerogcsenterprise/lease_drone_invoice',
                                    state: {
                                        user: this.state.user,
                                        type: this.state.type,
                                        lease: this.state.leaseDetailsSelect,
                                        withGst: this.state.withGst,
                                        totalValue: this.state.totalValue,
                                        gst: this.state.gst,
                                        discount: this.state.discount,
                                        dailycharges: this.state.leaseDetailsSelect.daily_charges,
                                        paid_status: false,

                                        // user: this.state.user,
                                        // type: this.state.type,
                                        // lease: lease,
                                        // withGst: this.state.withGst,
                                        // totalValue: this.state.totalValue,
                                        // gst: this.state.gst,
                                        // discount: this.state.discount,
                                        // dailycharges: this.state.dailycharges,
                                        // paid_status: false,
                                    }
                                }}
                                    style={{ color: '#3c3c3c' }}
                                >
                                    <div className="addcustomer-button-empty-dashboard"
                                        style={{ cursor: "pointer", padding: "8px 32px", background: "#2989CF", color: 'white' }} >
                                        Invoice
                                    </div>
                                </Link>
                                    :
                                    <div className="addcustomer-button-empty-dashboard"
                                        style={{ cursor: "pointer", padding: "8px 32px", background: "#2989CF", color: 'white' }}
                                        onClick={() => {
                                            this.totalIvoiceAmout()
                                            this.setState((state) => ({
                                                ...state,
                                                invoiceType: true
                                            }));
                                        }}>
                                        Invoice
                                    </div>
                                }</>
                            }
                        </div>
                    </div>
                </AnimatedModal>

                <AnimatedModal
                    isOpen={this.state.addLeaseDronePopUp}
                    height="420px"
                    width="500px"
                >
                    <div style={{ width: "100%", height: "100%", padding: '5px 20px' }}>
                        <div className="services-content-title"
                            style={{
                                margin: "10px 0px",
                                textAlign: "center",
                                fontWeight: "500",
                                color: "#3C3C3C",
                            }}
                        >
                            {this.state.edit ? 'Update Drone On Lease' : 'Add Drone On Lease'}
                        </div>
                        <hr style={{ margin: '5px 0px 0px 0px', padding: '0px', width: '100%' }} />
                        <div style={{ margin: '15px 20px 0px 20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: '75px', }}>
                            <div style={{ textAlign: "left", width: '100%', }}>
                                <div className='content-text'>Select Customer</div>
                                <select
                                    onChange={(event) => this.onChangeListenerCust(event)}
                                    className="add-user-form-text2"
                                    value={this.state.selectedCustomerName}
                                    style={{ width: "100%", cursor: 'pointer' }}
                                    disabled={this.state.edit}
                                >
                                    <option key={-1} value={-1}>Select Customer For Lease</option>
                                    {this.state.usersCustomers.map((cust, i) => {
                                        return <option key={i} value={i}>{cust.customer_name}</option>
                                    })}
                                </select>
                            </div>
                        </div>

                        <div style={{ margin: '15px 20px 0px 20px', display: 'flex', justifyContent: 'space-between', height: '75px', }}>
                            <div style={{ textAlign: "left", width: '100%', }}>
                                <div className='content-text'>Select Drone</div>
                                <select
                                    onChange={(event) => this.onChangeListenerDrone(event)}
                                    className="add-user-form-text2"
                                    value={this.state.selectedDroneName}
                                    style={{ width: "100%", cursor: 'pointer' }}
                                    disabled={this.state.edit}
                                >
                                    <option key={-1} value={-1}>Select Drone For Lease</option>
                                    {this.state.drones.map((drone, i) => {
                                        return <option key={i} value={i}>{drone.drone_id}</option>
                                    })}
                                </select>
                            </div>
                        </div>

                        <div style={{ margin: '15px 20px 0px 20px', display: 'flex', justifyContent: 'space-between', height: '75px', }}>
                            <div style={{ textAlign: "left", width: '45%', }}>
                                <div className='content-text' >Start Date</div>
                                <div>
                                    <input
                                        id="dateRequired"
                                        name="dateRequired"
                                        className="add-user-form-text"
                                        type="date"
                                        max={this.state.toDate ? dateFormat(this.state.toDate, 'yyyy-mm-dd') : '9000-01-01'}
                                        min={dateFormat(this.state.minDate, 'yyyy-mm-dd')}
                                        defaultValue={this.state.fromDate}
                                        onChange={(e) => {
                                            let value = e.target.value;
                                            this.setState((state) => ({
                                                ...state,
                                                fromDate: value,
                                            }))
                                        }}
                                        value={this.state.fromDate}
                                        style={{
                                            height: "auto",
                                            borderRadius: '10px',
                                            paddingLeft: '5px',
                                            width: "auto",
                                        }}
                                    />
                                </div>
                            </div>

                            <div style={{ textAlign: "left", width: '45%', }}>
                                <div className='content-text' >End Date</div>
                                <div>
                                    <input
                                        id="dateRequired"
                                        name="dateRequired"
                                        defaultValue={this.state.toDate}
                                        className="add-user-form-text"
                                        type="date"
                                        max={this.state.toDate ? dateFormat(this.state.toDate, 'yyyy-mm-dd') : '9000-01-01'}
                                        min={dateFormat(this.state.fromDate ? this.state.fromDate : this.state.minDate, 'yyyy-mm-dd')}
                                        onChange={(e) => {
                                            let value = e.target.value;
                                            this.setState((state) => ({
                                                ...state,
                                                toDate: value,
                                            }))
                                        }}
                                        value={this.state.toDate}
                                        style={{
                                            height: "auto",
                                            borderRadius: '10px',
                                            paddingLeft: '5px',
                                            width: "auto",
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', margin: '30px 20px', }}>
                            <button
                                className="addcustomer-button-empty-dashboard popup-btn-left"
                                onClick={() => {
                                    this.setState((state) => ({
                                        ...state,
                                        addLeaseDronePopUp: false,
                                        selectedCustomer: {},
                                        selectedCustomerName: "",
                                        selectedDrone: {},
                                        selectedDroneName: "",
                                        fromDate: '',
                                        toDate: '',
                                        edit: false,
                                        editLease: '',
                                    }));
                                }}
                            >
                                cancel
                            </button>
                            {this.state.fromDate && this.state.toDate && this.state.selectedCustomerName && this.state.selectedDroneName ? <button
                                className="add-user-form-submit-button popup-btn-right btn-ok"
                                onClick={() => {
                                    this.state.edit ? this.updateUserCustomerDroneLease() : this.addUserCustomerDroneLease()
                                }}
                            >
                                Save
                            </button> : <button style={{ opacity: '0.5', cursor: 'auto' }}
                                className="add-user-form-submit-button popup-btn-right btn-ok">
                                Save
                            </button>}
                        </div>
                    </div>
                </AnimatedModal>

                {/* <AnimatedModal
                    isOpen={this.state.addLeaseDronePopUp}
                    height="420px"
                    width="500px"
                >
                    <div style={{ width: "100%", height: "100%", padding: '10px 20px' }}>
                        <div
                            style={{
                                marginTop: "10px",
                                textAlign: "center",
                                fontSize: "16px",
                                fontWeight: "500",
                                color: "#3C3C3C",
                            }}
                        >
                            Add Drone On Lease
                        </div>
                        <hr style={{ margin: '5px 0px 0px 0px', padding: '0px', width: '100%' }} />
                        <div style={{ margin: '15px 20px 0px 20px', display: 'flex', justifyContent: 'space-between', height: '75px', }}>
                            <div style={{ textAlign: "left", width: '100%', }}>
                                <div className='content-text'>Select Customer</div>
                                <select
                                    className="add-user-form-text2"
                                    value={this.state.editLease.customer_name}
                                    style={{ width: "100%", cursor: 'pointer' }}
                                    disabled={this.state.edit}
                                >
                                    <option>{this.state.editLease.customer_name}</option>
                                </select>
                            </div>
                        </div>

                        <div style={{ margin: '15px 20px 0px 20px', display: 'flex', justifyContent: 'space-between', height: '75px', }}>
                            <div style={{ textAlign: "left", width: '100%', }}>
                                <div className='content-text'>Select Drone</div>
                                <select
                                    className="add-user-form-text2"
                                    value={this.state.editLease.drone_id}
                                    style={{ width: "100%", cursor: 'pointer' }}
                                    disabled={this.state.edit}
                                >
                                    <option>{this.state.editLease.drone_id}</option>
                                </select>
                            </div>
                        </div>

                        <div style={{ margin: '5px 20px 0px 20px', display: 'flex', justifyContent: 'space-between', height: '75px', }}>
                            <div style={{ textAlign: "left", width: '45%', }}>
                                <div className='content-text' >Start Date</div>
                                <div>
                                    <input
                                        id="dateRequired"
                                        name="dateRequired"
                                        className="add-user-form-text"
                                        type="date"
                                         max='9000-01-01'
                                        min={dateFormat(this.state.minDate, 'yyyy-mm-dd')}
                                        defaultValue={this.state.fromDate}
                                        onChange={(e) => {
                                            let value = e.target.value;
                                            this.setState((state) => ({
                                                ...state,
                                                fromDate: value,
                                            }))
                                        }}
                                        value={this.state.fromDate}
                                        style={{
                                            height: "auto",
                                            borderRadius: '10px',
                                            paddingLeft: '5px',
                                            width: "auto",
                                        }}
                                    />
                                </div>
                            </div>

                            <div style={{ textAlign: "left", width: '45%', }}>
                                <div className='content-text' >End Date</div>
                                <div>
                                    <input
                                        id="dateRequired"
                                        name="dateRequired"
                                        defaultValue={this.state.toDate}
                                        className="add-user-form-text"
                                        type="date"
                                         max='9000-01-01'
                                        min={dateFormat(this.state.fromDate ? this.state.fromDate : this.state.minDate, 'yyyy-mm-dd')}
                                        onChange={(e) => {
                                            let value = e.target.value;
                                            this.setState((state) => ({
                                                ...state,
                                                toDate: value,
                                            }))
                                        }}
                                        value={this.state.toDate}
                                        style={{
                                            height: "auto",
                                            borderRadius: '10px',
                                            paddingLeft: '5px',
                                            width: "auto",
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', margin: '30px 20px', }}>
                            <button
                                className="addcustomer-button-empty-dashboard popup-btn-left"
                                onClick={() => {
                                    this.setState((state) => ({
                                        ...state,
                                        addLeaseDronePopUp: false,
                                        selectedDrone: '',
                                        fromDate: '',
                                        toDate: '',
                                        edit: false
                                    }));
                                }}
                            >
                                cancel
                            </button>
                            {this.state.fromDate && this.state.toDate && this.state.editLease ? <button
                                className="add-user-form-submit-button popup-btn-right btn-ok"
                                onClick={() => {
                                    this.state.edit ? this.updateUserCustomerDroneLease() : this.updateUserCustomerDroneLease()
                                }}
                            >
                                Save
                            </button> : <button style={{ opacity: '0.5', cursor: 'auto' }}
                                className="add-user-form-submit-button popup-btn-right btn-ok">
                                Save
                            </button>}
                        </div>
                    </div>
                </AnimatedModal> */}
                {/* --------------------------------------------------------------- */}
                {this.state.loading || this.state.droneLoading || this.state.custLoading ? <LoaderComponent /> :
                    <div className="main-content-new">
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', margin: '10px 0px' }}>
                            <img src={btnAdd} style={{ marginLeft: '20px', width: '25px', height: '25px', cursor: 'pointer' }} onClick={() => {
                                this.setState({ addLeaseDronePopUp: true })
                            }} />
                        </div>
                        <div style={{ height: 'calc(100% - 45px)' }}>
                            {this.state.lease.length ? <table className="customers-table">
                                <col style={{ width: '28%' }} />
                                <col style={{ width: '16%' }} />
                                <col style={{ width: '16%' }} />
                                <col style={{ width: '16%' }} />
                                <col style={{ width: '16%' }} />
                                {/* <col style={{ width: '13%' }} /> */}
                                <col style={{ width: '5%' }} />

                                <tr className="customers-table-header-row">
                                    <th>Customer Name</th>
                                    <th style={{ textAlign: 'center' }}>Phone Number</th>
                                    <th style={{ textAlign: 'center' }}>Start Date</th>
                                    <th style={{ textAlign: 'center' }}>End Date</th>
                                    <th>Status</th>
                                    {/* <th style={{ textAlign: 'center' }}>Rent Amount</th> */}
                                    <th></th>
                                </tr>
                                {this.state.lease.map((lease, key) => (
                                    <tr className="customers-table-data-row">
                                        <td>
                                            <div className="leasedData" style={{ cursor: 'pointer', width: 'fit-content', }}
                                                onClick={() => {
                                                    this.setState((state) => ({
                                                        ...state,
                                                        leaseDetailsSelect: lease,
                                                        dailycharges: lease.daily_charges,
                                                        selectedLeaseDays: lease.totalDays,
                                                        selectedLeaseAmount: lease.rent_amount,
                                                        leaseGstAmount: Math.round((Number((this.state.gst / 100) * Number(lease.rent_amount))))
                                                    }), () => {
                                                        this.setState({ leaseOnPopUp: true })
                                                    })
                                                }}
                                            >{lease.customer_name ? lease.customer_name.length > 20 ?
                                                `${lease.customer_name.substring(0, 20)}..` :
                                                `${lease.customer_name}` : 'Unknown'}</div>
                                        </td>
                                        <td style={{ textAlign: 'center' }}>{lease.contact_number ? lease.contact_number : '-'}</td>
                                        <td style={{ textAlign: 'center' }}>{dateFormat(lease.startdate, "dd-mmm-yyyy")}</td>
                                        <td style={{ textAlign: 'center' }}>{dateFormat(lease.enddate, "dd-mmm-yyyy")}</td>
                                        <td>{lease.status}</td>
                                        <td style={{ textAlign: 'right', }}>
                                            <DeleteComponentTable
                                                style={{ marginRight: '10px' }}
                                                canEdit={lease.customer_name && lease.droneStatus}
                                                onClickEdit={() => this.updateLeaseData(lease)}
                                                onClickDelete={() => {
                                                    this.setState((state) => ({
                                                        ...state,
                                                        deleteLeaseId: lease.id
                                                    }), () => {
                                                        this.setState((state) => ({
                                                            ...state,
                                                            itemName: "Lease",
                                                            processPopupIcon: "WARNING",
                                                            processAction: "DELETE",
                                                            processMessage: <div>All lease data including lease, invoice will be deleted.
                                                                <br />Are you sure you want to delete this Lease ?</div>,
                                                            showProcessPopup: true,
                                                        }))
                                                    })
                                                }}
                                            disableDelete={lease.status == 'Active' || lease.status == 'active'}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </table> :
                                <div className="empty-data-message" style={{ display: 'flex', flexDirection: 'column', height: '85vh', backgroundColor: "#FAFAFA", alignItems: 'center', justifyContent: 'center' }}>
                                    <img src={noLeaseVector} style={{ width: "18%", }} />
                                    <div style={{ marginTop: '20px' }}>No Lease Found</div>
                                </div>
                            }
                        </div>
                    </div>}
            </div>
        </div >
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateProfileDetails: (payload) => dispatch(updateProfileDetails(payload))
    };
};

const mapStateToProps = state => {
    return {
        activeTeamId: state.profile.activeTeamId,
        activeTeam: state.profile.activeTeam,
        isLoggedInUserOwner: state.profile.isLoggedInUserOwner,
        SubscriptionData: state.subscription.subscriptionData,
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(userAllLease)