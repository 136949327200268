module.exports = {
    EXTENDBOUNDS: 0.00075,
    ORTHODETECTORTYPE: "ortho",
    tilesServer: process.env.REACT_APP_TILES_SERVER_URL,
    specialCharReg: /[!@#$%^&*()+\-`=\[\]{};':"\\|,.<>\/?]+/,
    SERVICES: {
        AEROGCS: 'AeroGCS Enterprise',
        DRONE_CONTROL_CENTER: "Drone Control Center"
    },
    COLORS: {
        PRIMARYCOLOR: "#2989CF",
        ANNOTATIONCOLOR: "#FF0000",
        COPYANNOTATIONCOLOR: "#fce514",
        STEPPERCOLOR: "#3c3c3c",
        STEPPERTEXTCOLOR: "white",
        CLASSSELECTEDCOLOR: "#2989CF26"
    },
    TASK: {
        STATUS: {
            CREATING: 'Creating',
            PREPARING: 'Preparing',
            PENDING: "Pending",
            IN_PROGRESS: "In progress",
            CREATED: "Created",
            FAILED: "Failed",
            COMPLETED: "Completed",
            MANUAL: "Manual",
        },
        TYPE: {
            TRAINING: 'Training',
            DETECTION: 'Detection',
            ANNREPORT: 'Annotation Report'
        }
    },

    EMPTY_IMAGESET_POPUP_MESSAGE: "( Empty or already processed imagesets will not appear in this list.)",
    EMPTY_PROJECT_POPUP_MESSAGE: "(You will only see projects that contain unprocessed imagesets.)",
    EMPTY_PLAN_POPUP_MESSAGE: "(You will only see plans that contain unprocessed imagesets.)",
    EMPTY_PROJECT_POPUP_MESSAGE_TRAINING: "(You will only see projects that contain unprocessed annotationsets.)",
    EMPTY_PLAN_POPUP_MESSAGE_TRAINING: "(You will only see plans that contain unprocessed annotationsets.)",

    POPUP_INPUT_TEXT_LIMIT: 50,
    OBJECT_NAME_LIMIT: 50,


    PAGES: [
        { DISPLAY_NAME: "Main dashboard", PAGE_NAME: "MAIN_DASHBOARD", ROUTE: "/" },
        { DISPLAY_NAME: "AeroGCS-E dashboard", PAGE_NAME: "AEROGCSE_DASHBOARD", ROUTE: "/" },
        { DISPLAY_NAME: "PicStork dashboard", PAGE_NAME: "PICSTORK_DASHBOARD", ROUTE: "/picstork/dashboard" },
        { DISPLAY_NAME: "DroneNaksha dashboard", PAGE_NAME: "DRONENAKSHA_DASHBOARD", ROUTE: "/dronenaksha/dashboard" },

        //AEROGCS-ENTERPRISE PAGES
        { DISPLAY_NAME: "AeroGCS-E Projects", PAGE_NAME: "AEROGCSE_PROJECTS", ROUTE: "/aerogcsenterprise/projects" },
        { DISPLAY_NAME: "AeroGCS-E Plans", PAGE_NAME: "AEROGCSE_PLANS", ROUTE: "/aerogcsenterprise/plans" },
        { DISPLAY_NAME: "AeroGCS-E Plan view", PAGE_NAME: "AEROGCSE_PLANVIEW", ROUTE: "/aerogcsenterprise/remote_mission/assignDroneToPlan" },
        { DISPLAY_NAME: "Plan flight logs", PAGE_NAME: "PLAN_FLIGHT_LOGS", ROUTE: "/aerogcsenterprise/remote_mission/flightLogs" },
        { DISPLAY_NAME: "AeroGCS-E Plan videos", PAGE_NAME: "AEROGCSE_VIDEOS", ROUTE: "/aerogcsenterprise/remote_mission/my_videos" },
        { DISPLAY_NAME: "AeroGCS-E Image Sets", PAGE_NAME: "AEROGCSE_IMAGE_SETS", ROUTE: "/aerogcsenterprise/remote_mission/image_collection" },
        { DISPLAY_NAME: "AeroGCS-E Images", PAGE_NAME: "AEROGCSE_IMAGES", ROUTE: "/aerogcsenterprise/remote_mission/my_images" },
        { DISPLAY_NAME: "Drone Control Center", PAGE_NAME: "DRONE_CONTROL_CENTER", ROUTE: "/aerogcsenterprise/drone_control_center/all_drones" },
        { DISPLAY_NAME: "Drone Performance", PAGE_NAME: "DRONE_PERFORMANCE", ROUTE: "/aerogcsenterprise/remote_mission/flights_completed_hours" },
        { DISPLAY_NAME: "Flight Details", PAGE_NAME: "FLIGHT_DETAILS", ROUTE: "/aerogcsenterprise/remote_mission/flight_details" },
        { DISPLAY_NAME: "Flight Log Analyzer", PAGE_NAME: "FLIGHT_LOG_ANALYZER", ROUTE: "/aerogcsenterprise/flight_logAnalyzer" },
        { DISPLAY_NAME: "Flight Status Logs", PAGE_NAME: "FLIGHT_STATUS_LOGS", ROUTE: "/aerogcsenterprise/remote_mission/flight_status_logs" },
        { DISPLAY_NAME: "Detailed Logs", PAGE_NAME: "DETAILED_LOG", ROUTE: "/aerogcsenterprise/remote_mission/detailedlog" },
        { DISPLAY_NAME: "All Flight Plans ", PAGE_NAME: "ALL_FLIGHT_PLANS", ROUTE: "/aerogcsenterprise/allFlightPlans" },
        { DISPLAY_NAME: "Manage Drones", PAGE_NAME: "MANAGE_DRONES", ROUTE: "/aerogcsenterprise/drones" },
        { DISPLAY_NAME: "Drones Details", PAGE_NAME: "DRONE_DETAILS", ROUTE: "/aerogcsenterprise/drone_details" },
        { DISPLAY_NAME: "Select Location", PAGE_NAME: "SELECT_LOCATION", ROUTE: "/aerogcsenterprise/remote_mission/selectLocation" },
        { DISPLAY_NAME: "All Flights", PAGE_NAME: "ALL_FLIGHTS", ROUTE: "/aerogcsenterprise/flights" },
        { DISPLAY_NAME: "All Pilots", PAGE_NAME: "All_PILOTS", ROUTE: "/aerogcsenterprise/flights/pilots" },
        { DISPLAY_NAME: "Acres", PAGE_NAME: "ACRES", ROUTE: "/aerogcsenterprise/flights/acres" },
        { DISPLAY_NAME: "Plans", PAGE_NAME: "FLIGHT_PLAN", ROUTE: "/aerogcsenterprise/remote_mission/flightPlan" },
        { DISPLAY_NAME: "Survey Flight Plan", PAGE_NAME: "SURVEY_FLIGHT_PLAN", ROUTE: "/aerogcsenterprise/remote_mission/surveyFlightPlan" },
        { DISPLAY_NAME: "Survey Flight Plot", PAGE_NAME: "SPRAYING_FLIGHT_PLOT", ROUTE: "/aerogcsenterprise/remote_mission/sprayingFlightPlot" },
        { DISPLAY_NAME: "Survey Flight Plan", PAGE_NAME: "SPRAYING_FLIGHT_PLAN", ROUTE: "/aerogcsenterprise/remote_mission/sprayingFlightPlan" },
        { DISPLAY_NAME: "User Flight Locations", PAGE_NAME: "USER_FLIGHT_LOCATIONS", ROUTE: "/aerogcsenterprise/user_flight_locations" },
        { DISPLAY_NAME: "Teams", PAGE_NAME: "TEAMS", ROUTE: "/teams" },
        { DISPLAY_NAME: "Team Access", PAGE_NAME: "TEAM_ACCESS", ROUTE: "/teams/givenAccess" },
        { DISPLAY_NAME: "Profile Settings", PAGE_NAME: "PROFILE_SETTINGS", ROUTE: "/profile-settings" },
        { DISPLAY_NAME: "Plans", PAGE_NAME: "PLANS", ROUTE: "/aeroGCS_plans" },
        { DISPLAY_NAME: "Usage Statistics", PAGE_NAME: "USAGE_STATISTICS", ROUTE: "/usage_statistics" },
        { DISPLAY_NAME: "User Statistics", PAGE_NAME: "USER_STATISTICS", ROUTE: "/analytictool/user_statistics" },
        { DISPLAY_NAME: "User Details", PAGE_NAME: "USER_DETAILS", ROUTE: "/analytictool/userdetails" },
        { DISPLAY_NAME: "User Tracking Logs", PAGE_NAME: "USER_TRACKING_LOGS", ROUTE: "/analytictool/user_tracking_logs" },
        { DISPLAY_NAME: "User Screen Logs", PAGE_NAME: "USER_SCREEN_LOGS", ROUTE: "/analytictool/user_screen_logs" },
        { DISPLAY_NAME: "Custom Statistic", PAGE_NAME: "CUSTOM_STATISTICS", ROUTE: "/analytictool/customStatistics" },

        //User 
        { DISPLAY_NAME: "Usage statistics", PAGE_NAME: "USAGE_STATISTICS", ROUTE: "/usage_statistics" },
        { DISPLAY_NAME: "User Profile", PAGE_NAME: "USER_PROFILE", ROUTE: "/profile-settings" },
        { DISPLAY_NAME: "User Login", PAGE_NAME: "USER_LOGIN", ROUTE: "/login" },
        { DISPLAY_NAME: "User Logout", PAGE_NAME: "USER_LOGOUT", ROUTE: "/logout" }
    ],

    BLOB_URL: process.env.REACT_APP_AZURE_BLOB_URL,


    MAP_COLORS: ['#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
        '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
        '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
        '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
        '#66994D', '#B366CC', '#6666FF', '#B33300', '#CC80CC',
        '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
        '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
        '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
        '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
        '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#4D8000'],
    PICSTORK_PLAN_NAME: 'PICSTORK_PLAN',

    //React joyride constants 
    TRIGGER_DELAY: 1000,
    FLOATER_PROPS: { disableAnimation: true },
    NEXT_BUTTON: {
        fontSize: '14px',
        content: 'Step',
        background: 'none',
        color: '#2989cf',
    },
    SKIP_BUTTON: {
        fontSize: '14px',
        content: 'Step',
        background: 'none',
        color: '#2989cf',
    },
    MAIN_OPTIONS: {
        arrowColor: '#fff',
        primaryColor: '#2989cf',
        textColor: '#3c3c3c',
        font: "popins",
        width: "100%",
        zIndex: 1000,
        beaconSize: 35,
        overlayColor: 'rgba(0, 0, 0, 0.7)',
    },


    TEXTSTYLING: { textAlign: 'left', fontSize: '12px', height: '20px', color: '#3c3c3c' },
    TITLESTYLING: { textAlign: 'left', paddingLeft: '10px', fontSize: '16px' },


    IMAGE_SET_RENAME_VARIABLE: "_$PDRL",
    STEPPER: {
        STEPPERWIDTH: "260px"
    },




    PLANNER: {
        STATUS: {
            TO_DO: "#FF6161",
            IN_PROGRESS: "#FFE24B",
            COMPLETED: "#43CAC8",
        }
    },

    MAP_URLS: {
        ROAD: 'https://mt0.google.com/vt/lyrs=h&hl=en&x={x}&y={y}&z={z}',
        ROAD_MAP: 'https://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}',
        TERRAIN: 'https://mt0.google.com/vt/lyrs=p&hl=en&x={x}&y={y}&z={z}',
        ALTER_ROAD: 'https://mt0.google.com/vt/lyrs=r&hl=en&x={x}&y={y}&z={z}',
        SATELITE: 'https://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}',
        TERREN_ONLY: 'https://mt0.google.com/vt/lyrs=t&hl=en&x={x}&y={y}&z={z}',
        HYBRID: 'https://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}',
    },


    SUBSCRIPTOPTIONS: {
        ITEMS: {
            STORAGE: "storage",
            ANALYTICS_CREDITS: "analytics",
            PROCESSING_CREDITS: "processing"
        },
        PRODUCT_TYPES: {
            SERVICES_PLAN: "services_plan",
            TASKS_CREDITS: "tasks_credits"
        }
    },
    BUY_NEW_DRONES: "BUY_NEW_DRONES",
    BUY_MORE_DRONES: "BUY_MORE_DRONES",
    CANCEL_DUE_PAYMENT: "CANCEL_DUE_PAYMENT",
    CANCEL_SUBSCRIPTION: "CANCEL_SUBSCRIPTION",
    CANCEL_DRONE_ADDON: "CANCEL_DRONE_ADDON",
    SOFTWARES: {
        GREEN: "GREEN",
        ORANGE: "ORANGE",
        FIRMWARE: "FIRMWARE"
    },
    ADMIN_USERS: [
        "suraj.bhalerao@pdrl.in",
        "chetanpdrl@yahoo.com",
        "chetanpdrl1@yahoo.com"
    ],

    // Hardware Details
    PART_TYPES: {
        GPS: "gps",
        MOTOR: "motor",
        PROPELLER: "propeller",
        BATTERY: "battery",
        BATTERY_CAPACITY: "battery_capacity",
        CHASSIS: "chassis",
        SENSOR: "sensor",
        RC: "rc",
        FC: "fc",
        POWER_MODULE: "power_module",
        NOZZLE: "nozzle",
        OVERALL_DIMENSION: "overall_dimession"
    },
    GPS_INTERFACES: [
        { label: "CAN", value: "CAN" },
        { label: "Serial", value: "Serial" },
        { label: "I2C", value: "I2C" }
    ],
    BATTERY_VOLTAGE: [
        { label: "3.7V (1S)", value: "3.7V (1S)" },
        { label: "7.4V (2S)", value: "7.4V (2S)" },
        { label: "11.1V (3S)", value: "11.1V (3S)" },
        { label: "14.8V (4S)", value: "14.8V (4S)" },
        { label: "18.5V (5S)", value: "18.5V (5S)" },
        { label: "22.2V (6S)", value: "22.2V (6S)" },
    ],
    PORPELLER_SIZE: [
        { label: "2-inch", value: "2" },
        { label: "3-inch", value: "3" },
        { label: "4-inch", value: "4" },
        { label: "5-inch", value: "5" },
        { label: "6-inch", value: "6" },
        { label: "7-inch", value: "7" },
        { label: "8-inch", value: "8" },
        { label: "9-inch", value: "9" },
        { label: "10-inch", value: "10" },
        { label: "11-inch", value: "11" },
        { label: "12-inch", value: "12" },
        { label: "13-inch", value: "13" },
        { label: "14-inch", value: "14" },
        { label: "15-inch", value: "15" },
        { label: "18-inch", value: "18" },
    ],
    MOTOR_NAMES: [
        { label: "X6", value: "X6" },
        { label: "X6+", value: "X6+" },
        { label: "X8", value: "X8" },
        { label: "X8+", value: "X8+" },
        { label: "X9", value: "X9" },
        { label: "X9+", value: "X9+" },
        { label: "X10", value: "X10" },
        { label: "X12", value: "X12" },
        { label: "X16", value: "X16" },
        { label: "T-Motor F60", value: "t-motor-f60" }
    ],
    CHASSIS_CLASSES: [
        { label: "X-Frame", value: "X-Frame" },
        { label: "Y-Frame", value: "Y-Frame" },
        { label: "Plus Frame", value: "Plus Frame" }
    ],
    SENSORS_APPLICATION: [
        { label: "Radar", value: "Radar" },
        { label: "Altimeter", value: "Altimeter" },
        { label: "LiDAR", value: "LiDAR" }
    ],
    SENSOR_INTERFACE: [
        { label: "CAN (Controller Area Network)", value: "CAN (Controller Area Network)" },
        { label: "Serial (RS232/RS485)", value: "Serial (RS232/RS485)" },
        { label: "I2C (Inter-Integrated Circuit)", value: "I2C (Inter-Integrated Circuit)" }
    ],
    RC_CONNECTIVITY: [
        { label: "Serial", value: "Serial" },
        { label: "USB", value: "USB" },
        { label: "Bluetooth", value: "Bluetooth" },
        { label: "UDP", value: "UDP" }
    ],
    FC_BOARDS: [
        { label: "Cube Orange", value: "Cube Orange" },
        { label: "Cube Orange Plus", value: "Cube Orange Plus" },
        { label: "CUAV V5", value: "CUAV V5" },
        { label: "CUAV V5+", value: "CUAV V5+" },
        { label: "CUAV V6+", value: "CUAV V6+" },
        { label: "CUAV X7", value: "CUAV X7" },
        { label: "CUAV X7+", value: "CUAV X7+" },
        { label: "CUAV Nora", value: "CUAV Nora" }
    ],
    POWER_MODULE_BATTERY_CELLS: [
        { label: "2S (7.4V)", value: "2S (7.4V)" },
        { label: "3S (11.1V)", value: "3S (11.1V)" },
        { label: "4S (14.8V)", value: "4S (14.8V)" },
        { label: "5S (18.5V)", value: "5S (18.5V)" },
        { label: "6S (22.2V)", value: "6S (22.2V)" },
        { label: "7S (25.9V)", value: "7S (25.9V)" },
        { label: "8S (29.6V)", value: "8S (29.6V)" },
        { label: "10S (37.0V)", value: "10S (37.0V)" },
        { label: "12S (44.4V)", value: "12S (44.4V)" },
        { label: "14S (51.8V)", value: "14S (51.8V)" }
    ],
    DIMENSION_CATEGORY: [
        { label: "Small", value: "Small" },
        { label: "Medium", value: "Medium" },
        { label: "Large", value: "Large" },
    ],
}


