import React, { Component } from "react";
import * as API from "./api.js";
import "./style.css";
import VideoPlayer from "./VideoPlayer.js"
import qs from 'qs';
import LoaderComponent from "./ReusableComponents/LoaderComponent.js";
import NoContent from "./ReusableComponents/NoContent.js";



class DCCFeed extends Component {
  state = {
    user: {},
    type: "",
    loading: true,
    title: "ALL",
    drones: {},
    drone: '',
  };

  componentWillUnmount() {
    clearInterval(this.interval)
    clearInterval(this.streamKeyInterval)
  }

  getPageData = () => {
    API.getAeroStreamVideos(0, this.state.drone).then(data => {
      let drones = this.state.drones;
      const flights = data.flights
      if (flights.length > 0) {
        flights.forEach(flight => {
          drones[flight.drone_id] = {
            ...drones[flight.drone_id],
            droneName: flight.drone_name,
            streamKey: flight.streamkey,
            streamURL: `${flight.streamLiveBase ? `${flight.streamLiveBase}` : process.env.REACT_APP_STREAMING_BASE}/streams/${flight.streamkey}.m3u8`,
            teleData: { lat: 0, lon: 0 }
          }
        })
      } else {
        drones[this.state.drone] = {
          ...drones[this.state.drone],
          droneName: this.state.drone,
          streamKey: this.state.drone,
          streamURL: `${process.env.REACT_APP_STREAMING_BASE}/streams/${this.state.drone}.m3u8`,
          teleData: { lat: 0, lon: 0 }
        }
      }
      this.setState({
        drones,
        loading: false,
      })
    }).catch(err => console.log(err))
  }

  componentDidMount() {
    document.title = "Drone Control Center - Live Drone Feed - AeroGCS";

    if (this.props && this.props.location && this.props.location.state) {
      this.setState((prevState) => ({
        ...prevState,
        user: this.props.location.state.user,
        type: this.props.location.state.type,
        sidebarMode: this.props.location.state.sidebarMode,
        drone: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).drone
      }), () => {
        this.getPageData();
      });
    } else {
      API.getUserType().then(([blah, type, user]) => {
        console.log("ROLE ", type);
        console.log("USER ", user);
        this.setState((state) => ({
          ...state,
          user,
          type,
          sidebarMode: "shrunk",
          drone: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).drone
        }), () => {
          this.getPageData();
        })
      }, (e) => {
        window.location.replace("/login");
      })
    }

  }


  render() {
    let drone = this.state.drone;
    return (
      <div className="wrapper"
        style={{ overflowY: "hidden" }} >
        <div
          className="right-content-new"
          style={{ height: "100vh", left: "0px", width: "100vw" }}
        >
          <div className="top-bar-new">
            <div className="top-bar-text-new">{(this.state.drones[drone] ? "Drone Control Center - " + this.state.drones[drone].droneName + " - Live Camera Feed" : "Drone Control Center - Live Camera Feed")}</div>
          </div>
          <div className="main-content-new" >
            {!this.state.loading ? <React.Fragment>
              <div className="dcc-feed-feed-window">
                <div className="dcc-all-drones-live-feeds-header">
                  <span className="dcc-all-drones-live-feeds-header-text">
                    {`${this.state.drones[drone].droneName}` + (this.state.drones[drone].address ? ` - ${this.state.drones[drone].address}` : ``)}
                  </span>
                </div>
                <div>
                  <VideoPlayer url={this.state.drones[drone].streamURL} />
                </div>
              </div>
            </React.Fragment> : <LoaderComponent />}

          </div>
        </div>
      </div>

    );
  }
}
export default DCCFeed;
