import { AnimatedModal } from "./AnimatedModal";
import React, { Component } from "react";
import CloudStorage from "../icons/CloudStorage.png";
import Frame from '../assets/Images/Frame.png'
import projectSS from '../assets/Images/projectSS.png';
import FlightStatistic from '../assets/Images/flightStatisticSS.png';
import Vector from '../assets/Images/vector.png';
import VectorPrev from '../assets/Images/vectorPrev.png';
import Teams from '../assets/Images/Teams2.png';
import flightLog from '../assets/Images/flightLog.png';
import projectManagement from '../assets/Images/projectManagement.png';
import teamWork from '../assets/Images/teamWork.png';
import closeIcon from '../Icon/closeIcon.svg'

/**
To use this popup you have to pass following props
  1) onRequestClose as function
  2) show as flag
  3) if You want to give height then pass width otherwise it take 50% by default

*/
export default class SignUpInfoPopup extends Component {
  state = {
    data: [
      {
        img: Frame,
        bottomImg: CloudStorage,
        heading: 'Enterprise Features',
        title: "",
        info: "Connect to AeroGCS Cloud for advanced features and flight tracking. Sync projects securely and access media instantly across AeroGCS services Elevate your aerial experiences with AeroGCS Cloud now.",
      },
      {
        img: Teams,
        bottomImg: teamWork,
        title: "Teamwork Amplified ",
        info: "Collaborate seamlessly with your team. Streamline communication, boost efficiency, and achieve better results together."
      },
      {
        img: projectSS,
        bottomImg: projectManagement,
        title: "Project Management ",
        info: 'Manage your flight plans at one place seamlessly using projects. Plan from AeroGCS account and sync with AeroGCS.'
      },
      {
        img: FlightStatistic,
        bottomImg: flightLog,
        title: "Detailed Flight Log",
        info: "Get access to most detailed logs on a GCS. Save logs offline or on cloud, get interesting and useful analytics about your team resources . Plan your project efficiently."
      },
    ],
    currentData: {
      img: Frame,
      bottomImg: CloudStorage,
      heading: 'Enterprise Features',
      title: "",
      info: "Connect to AeroGCS Cloud for advanced features and flight tracking. Sync projects securely and access media instantly across AeroGCS services Elevate your aerial experiences with AeroGCS Cloud now."
    },
    currentpage: 0,
    imgLoad: true,
  };

  closePopup = () => {
    this.setState({
      currentData: this.state.data[0],
      currentpage: 0,
    });
    this.props.close();
  };

  prevpage = () => {
    const { currentpage } = this.state;
    if (currentpage > 0) {
      this.setState((prevState) => ({
        currentpage: prevState.currentpage - 1,
        currentData: prevState.data[prevState.currentpage - 1]
      }));
    }
  };

  nextpage = () => {
    const { currentpage, data } = this.state;
    if (currentpage < data.length - 1) {
      this.setState((prevState) => ({
        currentpage: prevState.currentpage + 1,
        currentData: prevState.data[prevState.currentpage + 1]
      }));
    }
  };

  render() {
    const { currentpage, currentData } = this.state;
    return (<div>
      <AnimatedModal
        height="70%"
        width="46%"
        borderRadius='none'
        border='none'
        isOpen={this.props.show}
        boxShadow='none'
        background='transparent'
        onRequestClose={() => {
          this.closePopup();
        }}
      >
        <div className="signup-info-popup-container">

          <img src={closeIcon} alt='close icon'
            className="signUp-Popup-Close-Img"
            onClick={() => { this.closePopup(); }}
          />

          <div className="signup-info-popup-image-container">
            <div className="signup-info-popup-navigation" >
              {currentpage > 0 && <img src={VectorPrev} onClick={this.prevpage} />}
            </div>

            <div className="signup-info-popup-image"><img src={currentData.img} loading='lazy' /></div>

            <div className="signup-info-popup-navigation">
              {currentpage < this.state.data.length - 1 && <img src={Vector} onClick={this.nextpage} />}
            </div>
          </div>

          <div className="signup-info-popup-info-container" >
            <div className="signup-info-popup-info" >
              <div className="signup-info-popup-info-heading">{currentData.heading}</div>
              <div className="signup-info-popup-info-heading">{currentData.title}</div>
              <div className="signup-info-popup-description">{currentData.info}</div>
            </div>
            <div style={{
              display: "flex", justifyContent: "center", alignItems: "center", gap: "10px", width: '100%', height: '20%'
            }}>
              <div className="signup-info-popup-description-pagination" style={{ backgroundColor: currentpage == 0 ? 'white' : '#ffffff80' }} />
              <div className="signup-info-popup-description-pagination" style={{ backgroundColor: currentpage == 1 ? 'white' : '#ffffff80' }} />
              <div className="signup-info-popup-description-pagination" style={{ backgroundColor: currentpage == 2 ? 'white' : '#ffffff80' }} />
              <div className="signup-info-popup-description-pagination" style={{ backgroundColor: currentpage == 3 ? 'white' : '#ffffff80' }} />
            </div>
          </div>

        </div>
      </AnimatedModal >
    </div >);
  }
  Detailed
}