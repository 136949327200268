import { Link } from 'react-router-dom';
import React from 'react';
import "./style.css";
import { Component } from 'react';
import allServices from "./allServices"
import * as API from "./api.js";
import { getActiveTeam } from './Teams';


export default class ServicesTileTray extends Component {
    state = {
        tiles: [],
        type: '',
        inactiveTiles: [],
        requestServiceModal: false,
        reqMessage: "Thank you for your interest, we will activate selected service in your account at the earliest once requested.",
        reqSent: {
            0: false,
            1: false,
            2: false
        },
        reqSentMessage: "The confirmation email will be sent to your account",
        mainServices: "false"
        // sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk")
    }

    componentWillMount() {
        getActiveTeam().then(activeTeam => {
            this.setState({
                activeTeam: activeTeam
            }, () => {
                const { user, type, tiles, sidebarMode, mainServices } = this.props
                if (mainServices == "true") {
                    let inactiveTiles = allServices.services.filter(({ title: id1 }) => !tiles.some(({ title: id2 }) => id2 === id1));
                    this.setState((state) => ({
                        ...state,
                        user,
                        type,
                        tiles,
                        sidebarMode,
                        inactiveTiles,
                        mainServices
                    }))
                }
            })
        })
    }

    sendServiceRequest = (serviceTitle) => {
        API.request_service(serviceTitle).then(response => {
            let activeKey = this.state.key
            this.setState((state) => ({
                ...state,
                reqSent: { ...state.reqSent, [activeKey]: true }
            }))
        })
    }

    render() {
        let { user, type, sidebarMode, tiles, inactiveTiles, requestServiceModal, clickedInactiveService } = this.state;
        return <>
            <div style={{ width: "100%" }}>
                <div style={{ width: "100%", marginTop: "2%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <div className='content-text' style={{ fontSize: "22px", fontWeight: "500", color: "#2989cf" }}>{user?.first_name}, Welcome to AeroMegh</div>
                    <div className='content-text' style={{ fontSize: "14px", margin: "1% 1% 1% 2%", width: "70%", fontWeight: "500", color: "#3c3c3c", textAlign: "center" }}>
                        <p>{"AeroMegh is a SaaS platform offering multiple services. Each service is uniquely designed with the highest security (level1) and simplicity to address the needs of the drone ecosystem."}</p>
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "center", width: "100%", marginTop: "30px" }}>
                    {
                        tiles.map((service) => {
                            return (
                                service.title === 'AeroGCS Enterprise' && !this.state.activeTeam?.isOwner ?
                                    <Link to={{
                                        pathname: '/aerogcsenterprise/projects',
                                        state: {
                                            user,
                                            type,
                                            service,
                                            sidebarMode
                                        }
                                    }} className='service-card'>
                                        <div className='service-card'>
                                            <div className='service-icon'>
                                                <img style={{ height: "45px", width: "45px" }} src={service.image} alt="imageIcon" />
                                            </div>
                                            <div className='content-text' style={{ fontSize: "18px", fontWeight: "450", color: "black", marginTop: "3%" }}>
                                                {service.title}
                                            </div>
                                            <div className='content-text' style={{ display: "flex", alignItems: "center", textAlign: "center", fontSize: "12px", fontWeight: "400", color: "#666666", margin: "2% 6%" }}>
                                                {service.description.length <= 200 ? service.description : service.description.substr(0, 47) + "..."}
                                            </div>
                                        </div>
                                    </Link>
                                    :
                                    <Link to={{
                                        pathname: service.redirectURL || service.redirecturl,
                                        state: {
                                            user,
                                            type,
                                            service,
                                            sidebarMode
                                        }
                                    }} className='service-card'>
                                        <div className='service-card'>
                                            <div className='service-icon'>
                                                <img style={{ height: "45px", width: "45px" }} src={service.image} alt="imageIcon" />
                                            </div>
                                            <div className='content-text' style={{ fontSize: "18px", fontWeight: "450", color: "black", marginTop: "3%" }}>
                                                {service.title}
                                            </div>
                                            <div className='content-text' style={{ display: "flex", alignItems: "center", textAlign: "center", fontSize: "12px", fontWeight: "400", color: "#666666", margin: "2% 6%" }}>
                                                {service.description.length <= 200 ? service.description : service.description.substr(0, 47) + "..."}
                                            </div>
                                        </div>
                                    </Link>
                            )
                        })
                    }
                </div>
            </div>
        </>
    }
}
