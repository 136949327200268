import React, { Component } from 'react';
import circleLeft from './icons/prev.png';
import circleRight from './icons/next.png';
import * as API from './api.js';
import trashIcon from './icons/trash.svg';
import { BreadCrumbs } from './ReusableComponents/BreadCrumbs';
import closeButton from './close-button.png';
import './style.css';
import Popup from "reactjs-popup";
import Loader from './icons/loader.svg';
import { Link } from 'react-router-dom';
import thumb from "./assets/map_placeholder.png";
import service from "./sidebaricon/Services.png";
import VideosInactive from './sidebaricon/VideosInactive.png'
import FlightLogsInactive from './sidebaricon/FlightLogsInactive.svg'
import ProjectsInActive from './icons/ProjectsInactive.png';
import PlanIcon from './assets/sideBarIcons/PlanInactive.png'
import AeroGCSInactive from './sidebaricon/AeroGCSInactive.png'
import AeroMeghLogo from "./aeromeghLog.png";
import ImageActive from './sidebaricon/ImageSet.png';
import Noimageset from './assets/Images/Noimageset.png';
import LocationIcon from './res/locationwhite.png';
import ViewImagesOnMap from './ReusableComponents/inspectOnMap';
import ViewImageInList from './ReusableComponents/viewImageInList';
import menuIcon from "./assets/Icons/menu.png";
import Dropdown from 'react-bootstrap/Dropdown';
import { ActivityPopup } from './ReusableComponents/ActivityPopup.js';
import Tiff from 'tiff.js';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import Search from "./Icon/Search.png";
import mapViewIcon from '../src/assets/sideBarIcons/PlanInactive.png'
import mapViewActive from '../src/assets/sideBarIcons/mapView.png'
import gridViewIcon from '../src/icons/gridview.png'
import gridViewActiveIcon from '../src/icons/gridViewActiveIcon.png'
import listViewIcon from '../src/icons/listview.png'
import listViewActiveIcon from '../src/icons/listViewActiveIcon.png'
import { addUserAnalytic, updateOutTime } from './ReusableComponents/reusableFunctions';
import { ActiveRole, GCS_FEATURES, getFeaturesPermissions } from './Teams';
import AppConstants from './AppConstants';


const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href=""
        ref={ref}
        onClick={e => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
        <img src={menuIcon} alt="I am alt" />
    </a>
));

class RMPMyImages extends Component {

    state = {
        user: {},
        type: "",
        progress: 0,
        showCheckboxes: false,
        selectedImageCount: 0,
        Idx: 0,
        userperpage: 1000000,
        currentpage: 1,
        userdisplay: [],
        showDownloadPopup: false,
        loader: true,
        services: [],
        counts: 0,
        projects: [],
        collection_name: '',
        createCollectionMsg: '',
        tasks: [],
        images: [],
        reports: [
        ],
        selecttedImages: [],
        showBackIcon: true,
        cancel: false,
        loading: true,
        sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk"),
        imageset: '',
        subscriptionPlan: '',
        subscriptionPopup: false,
        subcheck: false,
        scale: 0,
        mapView: false,
        listview: false,
        gridView: true,
        imageLoad: false,
        itemName: "",
        showProcessPopup: false,
        processPopupIcon: "",
        processAction: "",
        processMessage: "",
        thumbURLs: [],
        imageURL: "",
        uploadPercent: null,
        hasMoreItems: true,
        key: "",
        sortType: 'Last Modified',
        outId: ""
    }

    // function to load tiff thumbnails
    loadThumbTiff = async (images) => {
        return new Promise(async (resolve, reject) => {
            for (let i = 0; i < images.length; i++) {
                if (images[i].file_name) {
                    let name = images[i].file_name
                    let turl = `${AppConstants.BLOB_URL}/${this.state.permissions.container}/FlightImages/collection-${this.state.collection.id}/thumb-${images[i].file_name}?${this.state.permissions.st}`
                    images[i].thumbURL = turl
                }
                if (images.length - 1 == i) {
                    console.log(i, "fdshfsfjfkbjkbggdskgds", images.length - 1);
                    resolve(images)
                }
            }

        })

    }

    // to load tiff image 
    loadTiffImageSrc = (i) => {
        let { images, user } = this.state
        var imageURL = ""
        if (images[i].file_name) {
            let name = images[i].file_name.toLowerCase()
            if ((name.indexOf(".tif")) == -1) {
                let iurl = `${AppConstants.BLOB_URL}/${this.state.permissions.container}/FlightImages/collection-${this.state.collection.id}/${images[i].file_name}?${this.state.permissions.st}`
                imageURL = iurl
                this.setState((state) => ({
                    ...state,
                    imageURL: imageURL,
                }))
            } else {
                // for image
                var xhr = new XMLHttpRequest();
                xhr.responseType = 'arraybuffer';
                let url = `${AppConstants.BLOB_URL}/${this.state.permissions.container}/FlightImages/collection-${this.state.collection.id}/${images[i].file_name}?${this.state.permissions.st}`
                xhr.open('GET', url);
                xhr.onload = function (e) {
                    var arraybuffer = e.target.response
                    Tiff.initialize({
                        TOTAL_MEMORY: 16777216 * 10
                    });
                    var tiff = new Tiff({
                        buffer: arraybuffer
                    })
                    var dataUrl = tiff.toDataURL();
                    let url = e.target.responseURL
                    var index = 0
                    this.state.images.map((image, j) => {
                        if (url.indexOf(encodeURIComponent(image.file_name)) != -1) {
                            index = j
                        }
                    })
                    imageURL = dataUrl   // to insert url at specific position
                    this.setState((state) => ({
                        ...state,
                        imageURL: imageURL,
                    }))
                }.bind(this);
                xhr.send()
            }
        }
    }
    parentSidebarSwitch = () => {
        this.setState((state) => ({
            ...state,
            sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
        }))
    }
    closeActivityPopup = () => {
        this.setState((state) => ({
            ...state,
            showProcessPopup: false,
            processMessage: null
        }))
    }

    cancelDelete = () => {
        this.state.images.forEach((image) => {
            image.checked = false
        })
        this.setState((state) => ({
            ...state,
            showCheckboxes: false,
            selectedImageCount: 0,
        }))
    }

    getImages = () => {
        let { user, type, userdisplay, currentpage, userperpage, key, counts, requestStatus } = this.state;
        const lastIndex = currentpage * userperpage;
        const firstpage = lastIndex - userperpage;
        const currentuser = userdisplay.slice(firstpage, lastIndex);
        const totalpages = userdisplay.length / userperpage;
        key = key == "" ? " " : key
        API.getImageCollectionImages(this.state.collection.id, key, userperpage, firstpage).then(async (data) => {
            if (data.images.length > 0) {
                if (this.state.uploadPercent == 100 || this.state.uploadPercent == null) {
                    this.loadThumbTiff(data.images).then((tiffImg) => {
                        this.setState((state) => ({
                            ...state,
                            loading: false,
                            images: tiffImg,
                            sasToken: data.sasToken,
                            counts: data.totalCount,
                            showCheckboxes: false,
                            selectedImageCount: 0,
                        }), () => {
                            if (this.state.sortType === 'A-Z') {
                                this.sortByName()
                            } else if (this.state.sortType === 'Z-A') {
                                this.reverseByName()
                            } else if (this.state.sortType === 'First Modified') {
                                this.sortByDate()
                            } else if (this.state.sortType === 'Last Modified') {
                                this.reverseByDate()
                            }
                            if (this.state.mapView) {
                                this.setState((state) => ({
                                    ...state,
                                    mapView: false
                                }), () => {
                                    this.setState((state) => ({
                                        ...state,
                                        mapView: true
                                    }))
                                })
                            }
                        })
                    })
                }
            } else {
                this.setState((state) => ({
                    ...state,
                    loading: false,
                    images: [],
                    // sasToken: '',
                    counts: 0,
                    // showCheckboxes: false,
                    selectedImageCount: 0,
                }))
            }

        }, (e) => {
            console.log(e);
            alert('Something went wrong')
            this.setState((state) => ({
                ...state,
                loading: false,
            }))
        })
    }


    onChangeSortEvent = (sortType) => {
        console.log(sortType);
        if (sortType === 'Last Modified') {
            this.reverseByDate()
        } else if (sortType === 'First Modified') {
            this.sortByDate()
        } else if (sortType === 'A-Z') {
            this.sortByName()
        } else if (sortType === 'Z-A') {
            this.reverseByName()
        }
    }




    sortByName = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'A-Z'
        }));
        let sort = this.state.images.sort((a, b) => a.file_name.localeCompare(b.file_name))
        this.setState((state) => ({
            ...state,
            images: sort,
        }));

    }

    reverseByName = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'Z-A'
        }));
        let sort = this.state.images.sort((a, b) => b.file_name.localeCompare(a.file_name))
        this.setState((state) => ({
            ...state,
            images: sort,
        }));
    }

    sortByDate = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'First Modified'
        }));
        let sort = this.state.images.sort((a, b) => a.created_on.localeCompare(b.created_on))
        this.setState((state) => ({
            ...state,
            images: sort,
        }));
    }

    reverseByDate = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'Last Modified'
        }));
        let sort = this.state.images.sort((a, b) => b.created_on.localeCompare(a.created_on))
        this.setState((state) => ({
            ...state,
            images: sort,
        }));
    }

    nextImg = () => {
        this.setState({
            ...this.state,
            imageLoad: true,
            Idx: this.state.Idx + 1
        }, () => this.loadTiffImageSrc(this.state.Idx))
    }

    prevImg = () => {
        this.setState({
            ...this.state,
            imageLoad: true,
            Idx: this.state.Idx - 1
        }, () => this.loadTiffImageSrc(this.state.Idx))
    }
    openImage = (idx) => {
        // window.alert(idx)
        this.setState((state) => ({
            ...state,
            Idx: idx,
            imageLoad: true,
            scale: 0,

        }), () => {
            this.loadTiffImageSrc(this.state.Idx)
            var style = document.createElement('style');
            style.type = 'text/css';
            style.innerHTML = '.popup-content { width: 800px !important; border-radius:15px; }';
            document.getElementsByTagName('head')[0].appendChild(style);
            document.getElementById("view-image-popup-button").click()
        })

    }

    deleteImages = async () => {
        this.setState((state) => ({
            ...state,
            Idx: 0,
            itemName: "Images",
            processPopupIcon: "WAIT",
            processAction: "DELETE",
            processMessage: null,
            showProcessPopup: true,
        }))
        let temp = [];
        let counter = 0;
        await this.state.images.forEach(img => {
            if (img.checked == true) {
                temp.push(img.file_name);
            }
            counter++;
            if (counter == this.state.images.length) {
                return temp
            }
        });
        let images = temp
        let collection_id = this.state.collection.id;
        API.deleteImages(collection_id, images).then((data) => {
            this.setState((state) => ({
                ...state,
                itemName: "Images",
                processPopupIcon: "COMPLETE",
                processAction: "DELETE",
                processMessage: null,
                showProcessPopup: true,
                showCheckboxes: false,
            }), () => {
                this.getImages()
                // setTimeout(() => {
                //     document.getElementById("delete-images-popup-button").click();
                // }, 3000);
            })
        }, (e) => {
            this.setState((state) => ({
                ...state,
                itemName: "Images",
                processPopupIcon: "ERROR",
                processAction: "DELETE",
                processMessage: e,
                showProcessPopup: true,
            }))
        })
    }


    componentWillUnmount() { updateOutTime(this.state.outId) }

    componentWillMount() {
        getFeaturesPermissions([GCS_FEATURES.IMAGECOLLECTIONS, GCS_FEATURES.PLANS])
            .then(permissions => {
                this.setState(state => ({ ...state, permissions, }), () => {
                    addUserAnalytic(this.props.location.pathname).then(id => this.setState({ outId: id }))
                    // var style = document.createElement('style');
                    // style.type = 'text/css';
                    // style.innerHTML = '.popup-content { width: 770px !important; }';
                    // document.getElementsByTagName('head')[0].appendChild(style);
                    document.title = "My Images";
                    if (this.props.location.state) {
                        let { user, type, task, project, plan, collection } = this.props.location.state;
                        this.setState((state) => ({
                            ...state,
                            user,
                            type,
                            task,
                            project,
                            plan,
                            collection
                        }), () => {
                            this.getImages()
                            API.getUserSubbedServices().then(
                                (response) => {
                                    let services = response[0];
                                    // console.log(services);
                                    if (services.length == 1 && services[0].title == 'AeroStream') {
                                        this.setState((state) => ({
                                            ...state,
                                            showBackIcon: false
                                        }))
                                    } else {
                                        this.setState((state) => ({
                                            ...state,
                                            showBackIcon: true
                                        }))
                                    }
                                }
                            )
                        })
                    } else {
                        API.getUserType().then(([blah, type, user]) => {
                            //console.log("ROLE ", type);
                            //console.log("USER ", user)
                            this.setState((state) => ({
                                ...state,
                                user,
                                type
                            }), () => {
                                this.getImages()
                                //  console.log("ASKDJHASK", user);
                            })
                        }, (e) => {
                            window.location.replace("/login");
                        })
                    }

                })
            })
    }
    //Get SearchKey Function Start Here-----
    search(key) {
        if (key == '') {
            this.setState((state) => ({
                ...state,
                hasMoreItems: true
            }))
        }
        this.setState((state) => ({
            ...state,
            key,
            currentpage: 1
        }),
            () => this.getImages());
    }
    //Get SearchKey Function Ends Here-----
    //Get on First page
    firstpage = () => {
        if (this.state.currentpage > 1) {
            this.setState((state) => ({
                ...state,
                currentpage: 1
            }), () => {
                this.getImages();
            });
        } else {
            console.log("Reached first page")
        }
    }
    // Prev Page function
    prevpage = () => {
        if (this.state.currentpage > 1) {
            this.setState((state) => ({
                ...state,
                currentpage: this.state.currentpage - 1
            })
                , () => {
                    this.getImages();

                });
        }
    }
    // Next page Function
    nextpage = () => {
        console.log('next called');
        if (this.state.currentpage < Math.ceil(this.state.counts / this.state.userperpage)) {
            this.setState((state) => ({
                ...state,
                currentpage: state.currentpage + 1
            }), () => {
                this.getImages();
            });
        } else {
            console.log("Reached next page")
        }
    }
    // Get Last Page Function Start here----------------------
    lastpage = () => {
        if (this.state.currentpage < Math.ceil(this.state.counts / this.state.userperpage)) {
            this.setState((state) => ({
                ...state,
                currentpage: Math.ceil(this.state.counts / this.state.userperpage)
            }), () => {
                this.getImages();
            });
        } else {
            console.log("Reached last page")
        }
    }

    // Get Last Page Function End here ----------------------------
    // Get Changenumber Page Function Starts here ----------------------------
    changepage(num) {
        console.log(isNaN(num))
        if (isNaN(num)) {

            return null;
        }
        else {
            this.setState((state) => ({
                ...state,
                currentpage: num,
            }), () => this.getImages());
        }

    }

    imageSelectDeleted = (key) => {
        this.state.images[key].checked = !this.state.images[key].checked
        if (this.state.images[key].checked == true) {
            let cnt = this.state.selectedImageCount + 1
            this.setState((state) => ({
                ...state,
                selectedImageCount: cnt
            }))
        } else {
            let cnt = this.state.selectedImageCount - 1
            this.setState((state) => ({
                ...state,
                selectedImageCount: cnt
            }))
        }

    }

    handleChange = (event) => {
        this.setState({ collection_name: event.target.value });
    }

    render() {

        let { user, type, images, sidebarMode, showBackIcon, collection, currentpage, userperpage, plan, project } = this.state
        const listLoader = <div style={{ height: "10vh", width: "80vw", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <img src={Loader} style={{ height: "10vh", width: "50vw", margin: "auto" }} />
        </div>


        return (
            user.username ? <div className="wrapper">
                <div id='tiffloaded' className="right-content" >
                    <Popup
                        style={{ width: "min-content" }}

                        trigger={<button id="view-image-popup-button" style={{
                            display: "none",
                        }}
                        ></button>}
                        modal
                    ><img
                            id="close-popup"
                            src={closeButton} style={{
                                width: "auto",
                                height: "25px",
                                position: "absolute",
                                top: "-12px",
                                right: "-12px",
                                cursor: "pointer"

                            }} onClick={() => {
                                var style = document.createElement('style');
                                style.type = 'text/css';
                                style.innerHTML = '.popup-content { width: 800px !important; border-radius:15px;}';
                                document.getElementsByTagName('head')[0].appendChild(style);
                                document.getElementById("view-image-popup-button").click();
                                this.setState((state) => ({
                                    ...state,
                                    regions: [],
                                    scale: 0,
                                    imageLoad: false,
                                }));

                            }} />
                        <div style={{ display: 'flex' }} >
                            <div className='flex-center' style={{ alignItems: 'end', background: "#666666", width: "790px", height: '567px', borderRadius: '15px', overflow: "hidden" }}>
                                {this.state.imageLoad ?
                                    <img className='image-popup' src={Loader} style={{ position: "absolute", zIndex: 5, height: "100%", width: "8vw", marginLeft: "10%" }} /> : ''
                                }
                                <TransformWrapper>
                                    {({ zoomIn, zoomOut, centerView, resetTransform, ...rest }) => (
                                        <TransformComponent>
                                            <img id="action-image" style={{ zIndex: 0, opacity: this.state.imageLoad ? 0 : 1 }} className='image-popup' loading='lazy'
                                                src={this.state.images && this.state.images.length ? this.state.imageURL : ''}
                                                onLoad={((load) => {
                                                    console.log("image Loaded")
                                                    this.setState((state) => ({
                                                        ...state,
                                                        imageLoad: false,
                                                    }),
                                                        centerView(),
                                                        resetTransform())

                                                })} />
                                        </TransformComponent>
                                    )}
                                </TransformWrapper>
                                {this.state.images && this.state.images.length ? <div className='imageinfo'>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div className="imagePopupTitle">{images[this.state.Idx].file_name.length > 50 ? images[this.state.Idx].file_name.substring(0, 50) : images[this.state.Idx].file_name}</div>
                                        <div className="imagePopupSubTitleTile">
                                            <div className="imagePopupSubTitle">{images[this.state.Idx].resolution} MP</div>
                                        </div>

                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div className="imagePopupSubTitle">
                                            {images[this.state.Idx].latitude == null || images[this.state.Idx].latitude == "" ? "-" : `${images[this.state.Idx].latitude.length > 11 ? `${images[this.state.Idx].latitude.substring(0, 11)}` : `${images[this.state.Idx].latitude}`}`}, {images[this.state.Idx].longitude == null || images[this.state.Idx].longitude == "" ? "-" : `${images[this.state.Idx].longitude.length > 11 ? `${images[this.state.Idx].longitude.substring(0, 11)}` : `${images[this.state.Idx].longitude}`}`}
                                        </div>
                                        <div className="imagePopupSubTitleTile">
                                            <div className="imagePopupSubTitle">{images[this.state.Idx].file_size} MB</div>
                                        </div>
                                    </div>
                                </div> : ''}

                                {this.state.images && this.state.images.length > 1 ? < div className='nextprevfunction'>
                                    {
                                        this.state.Idx == 0 ?
                                            <div className='prevDiv'>
                                                <img className='prev' src={circleLeft} style={{ opacity: '0.5' }} />
                                            </div> :
                                            <div className='prevDiv' onClick={this.prevImg}>
                                                <img className='prev' src={circleLeft} style={{ cursor: 'pointer' }} />
                                            </div>

                                    }
                                    {
                                        this.state.Idx == this.state.images.length - 1 ?
                                            <div className='nextDiv'> <img className='next' src={circleRight} style={{ opacity: '0.5' }}
                                            /> </div> :
                                            <div className='nextDiv' onClick={this.nextImg} >
                                                <img className='next' src={circleRight} style={{ cursor: 'pointer' }} />
                                            </div>
                                    }

                                </div> : ''
                                }
                            </div>
                        </div>
                    </Popup>

                    {/* -------------- Activity Popup ----------------------- */}
                    <ActivityPopup
                        item={this.state.itemName}
                        open={this.state.showProcessPopup}
                        icon={this.state.processPopupIcon}
                        action={this.state.processAction}
                        msg={this.state.processMessage}
                        close={() => this.closeActivityPopup()}
                        onClickOk={() => this.deleteImages()}
                    />
                    {/* --------------------------------------------------------------- */}

                    <div className="top-bar">
                        <div className="breadCrumbs">
                            <BreadCrumbs data={[
                                {
                                    displayName: "projects",
                                    pathname: "/aerogcsenterprise/projects",
                                    state: {
                                        type,
                                        user,
                                    }
                                },
                                {
                                    displayName: this.props.location.state.project.project_name,
                                    pathname: "/aerogcsenterprise/plans",
                                    state: {
                                        type,
                                        user,
                                        project: this.props.location.state.project
                                    }
                                },
                                {
                                    displayName: this.props.location.state.plan.plan_name,
                                    pathname: "/aerogcsenterprise/remote_mission/assignDroneToPlan",
                                    state: {
                                        type,
                                        user,
                                        plan: this.props.location.state.plan,
                                        project: this.props.location.state.project,
                                    }
                                },
                                {
                                    displayName: this.state.collection.collection_name
                                }
                            ]} />
                            <ActiveRole
                                hideBrackets={true}
                            />
                        </div>
                    </div>

                    {/*<TopBar user={user} sidebarMode={sidebarMode} type={type} pageTitle = {'SERVICES'}></TopBar>*/}
                    {this.state.loading ? <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
                        <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
                    </div> : <>
                        <div className="main-content" style={{ background: 'none' }} onClick={
                            () => {
                                this.setState((state) => ({
                                    ...state, sidebarMode: 'shrunk'
                                }))
                            }
                        }>

                            {/* <SnackBar user={user} sidebarMode={sidebarMode} type={type} /> */}
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: "10px", width: "100%", alignItems: 'center' }}>
                                <div className="services-content-title">{`Total Images`}
                                    <span className='data-count'>({this.state.counts})</span>
                                </div>

                                <div className="add-user-form-text-wrapper" style={{ display: 'flex', alignItems: 'center' }}
                                >
                                    <div style={{ marginRight: '20px' }}>
                                        <img src={Search} style={{ height: "20px", width: "20px", position: 'relative', left: '25px' }} />
                                        <input type="text" value={this.state.key} className="add-user-form-text2" placeholder="Search images" style={{ height: '29px', width: "230px", paddingLeft: '28px', border: '1px solid rgba(102, 102, 102, 0.3)' }}
                                            onChange={(event) => this.search(event.target.value)} />
                                    </div>
                                    <div className="imageViewButton" style={{ display: 'flex', height: '24px', marginRight: '20px' }}  >
                                        <div className={this.state.listview ? 'imageViewButtonListActive' : 'imageViewButtonList'} style={{ width: "60px", borderRadius: '2px 0px 0px 2px', borderRight: 'solid 1px #2989cf' }}
                                            onClick={() => {
                                                this.setState((state) => ({
                                                    ...state,
                                                    listview: true,
                                                    gridView: false,
                                                    mapView: false,
                                                }))
                                            }}>
                                            <div><img src={this.state.listview ? listViewActiveIcon : listViewIcon} style={{ height: '18px', width: '18px', cursor: 'pointer', margin: '0px 5px' }} /></div>
                                        </div>



                                        <div className={this.state.gridView ? 'imageViewButtonListActive' : 'imageViewButtonList'} style={{ width: "60px", borderRight: 'solid 1px #2989cf', borderLeft: 'solid 1px #2989cf' }}
                                            onClick={() => {
                                                this.setState((state) => ({
                                                    ...state,
                                                    listview: false,
                                                    gridView: true,
                                                    mapView: false,
                                                }))
                                            }}
                                        >
                                            <div><img src={this.state.gridView ? gridViewActiveIcon : gridViewIcon} style={{ height: '18px', width: '18px', cursor: 'pointer', margin: '0px 5px' }} /></div>
                                        </div>

                                        <div className={this.state.mapView ? 'imageViewButtonListActive' : 'imageViewButtonList'} style={{ width: "60px", borderRadius: '0px 2px 2px 0px', borderLeft: 'solid 1px #2989cf' }}
                                            onClick={() => {
                                                this.cancelDelete()
                                                this.setState((state) => ({
                                                    ...state,
                                                    listview: false,
                                                    gridView: false,
                                                    mapView: true,
                                                }))
                                            }}>
                                            <div><img src={this.state.mapView ? mapViewActive : mapViewIcon} style={{ height: '20px', width: '20px', cursor: 'pointer', margin: '0px 5px' }} /></div>
                                        </div>
                                    </div>
                                    {this.state.showCheckboxes ?
                                        <div className="addcustomer-button-empty-dashboard" style={{ paddingLeft: '30px', paddingRight: '30px', marginRight: '20px', cursor: 'pointer' }}
                                            onClick={() => { this.cancelDelete() }}
                                        >Cancel</div>
                                        : <></>}
                                    <>{this.state.showCheckboxes ? this.state.selectedImageCount > 0 ?
                                        < img src={trashIcon} style={{ height: '24px', width: '24px', cursor: 'pointer', marginRight: '20px' }}
                                            onClick={() => {
                                                // this.deleteImages()
                                                this.setState((state) => ({
                                                    ...state,
                                                    itemName: "Images",
                                                    processPopupIcon: "WARNING",
                                                    processAction: "DELETE",
                                                    processMessage: "Are you sure you want to delete these images ?",
                                                    showProcessPopup: true,
                                                }))
                                            }}
                                        /> : '' : ''}
                                    </>

                                    {!this.state.mapView ? <Dropdown style={{}}>
                                        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" >
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu size="sm" title=""
                                            className={this.state.images == 0 ? "custom-dropdown-settings1nodata" : "custom-dropdown-settings1"}
                                        >
                                            {images.length ? <Dropdown.Item
                                                style={{ padding: '0px !important' }}
                                                className="dropdown-set-padding"
                                            >{!this.state.permissions[GCS_FEATURES.IMAGECOLLECTIONS].DELETE || this.state.showCheckboxes || images.length == 0 ? <>
                                                <div style={{ fontSize: "12px", color: '#3c3c3c', opacity: '0.5', cursor: 'auto' }}>Delete Images</div>
                                            </> : <div style={{ fontSize: "12px", color: '#3c3c3c' }}
                                                onClick={
                                                    () => {

                                                        this.setState((state) => ({
                                                            ...state,
                                                            showCheckboxes: true,
                                                        }))
                                                    }
                                                }>Delete Images</div>}

                                            </Dropdown.Item> : <></>}

                                        </Dropdown.Menu>
                                    </Dropdown> : <></>}
                                </div>

                            </div>

                            {this.state.mapView ?
                                <div>
                                    <ViewImagesOnMap
                                        service={AppConstants.SERVICES.AEROGCS}
                                        height={'84vh'}
                                        width={'100%'}
                                        inspect={this.state.images}
                                        collection_id={this.state.collection.id}
                                        plan_id={this.state.plan.id}
                                        project_id={this.state.project.id}
                                        getImages={() => this.getImages()}
                                        onClickOnPoint={idx => this.openImage(idx)}
                                    />
                                </div>
                                : <>{this.state.listview ?
                                    <div style={{ height: '84vh', overflow: 'auto' }}>
                                        <ViewImageInList sources={this.state.thumbURLs} user={this.state.user} showCheckboxes={this.state.showCheckboxes} collection_id={this.state.collection.id} inspect={this.state.images} sortType={this.state.sortType}
                                            loaded={(i) => {
                                                let images = this.state.images
                                                images[i].imageLoad = false
                                                this.setState((state) => ({
                                                    ...state,
                                                    images: images
                                                }))
                                            }}
                                            onClickOnCheckBox={key => this.imageSelectDeleted(key)} onClickOnPoint={idx => this.openImage(idx)}
                                            onClickSort={sortType => this.onChangeSortEvent(sortType)} />
                                    </div>
                                    // </div>
                                    : <>     {
                                        images.length ?
                                            <div style={{ height: '84vh', overflow: 'auto' }}>
                                                <div className="aerocapture-cards-tray-images1">
                                                    {
                                                        images.map((image, key) => {
                                                            return (
                                                                <div>
                                                                    <div>
                                                                        {< div key={key} className="customer-cards-dashboard card" style={{ boxShadow: '0px 6px 10px rgb(0 0 0 / 30%)', }}>
                                                                            <div className="card-header customer-cards-dashboard-logo project-card-header"
                                                                                style={{}}

                                                                            >
                                                                                {/* {name.indexOf(".tif") == -1 ? */}
                                                                                <img id="ProjectImage2" onClick={
                                                                                    () => {
                                                                                        if (this.state.showCheckboxes) {
                                                                                            this.imageSelectDeleted(key)
                                                                                        } else {
                                                                                            this.openImage(key)
                                                                                        }
                                                                                    }
                                                                                } style={{ objectFit: 'cover', cursor: 'pointer' }}
                                                                                    src={image.thumbURL}
                                                                                    loading='lazy'
                                                                                    onError={(e) => { e.target.onerror = null; e.target.src = thumb }} />
                                                                                {/* :
                                                                                    <img id="ProjectImage2" onClick={
                                                                                        () => {
                                                                                            if (this.state.showCheckboxes) {
                                                                                                this.imageSelectDeleted(key)
                                                                                            } else {
                                                                                                this.openImage(key)
                                                                                            }
                                                                                        }
                                                                                    } style={{ objectFit: 'cover', cursor: 'pointer' }}
                                                                                        src={image.thumbURL}
                                                                                        loading='eager'
                                                                                        onError={(e) => { e.target.onerror = null; e.target.src = thumb }} />

                                                                                } */}
                                                                                {this.state.showCheckboxes ? <input type='checkbox' id={image.file_name} checked={image.checked} style={{ height: '15px', width: '15px', marginLeft: 'auto', marginRight: "-145px", marginTop: '-40px', position: 'absolute' }}
                                                                                    onClick={() => { this.imageSelectDeleted(key) }}
                                                                                /> : <> </>
                                                                                }
                                                                            </div>
                                                                            <div style={{ paddingTop: "3px !important" }}
                                                                                className="card-body change-color customer-cards-dashboard-text project-card-body2"
                                                                            >
                                                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                                    <span style={{ fontSize: '11px' }}>{
                                                                                        image.file_name.length > 20 ?
                                                                                            `${image.file_name.substring(0, 20)}...` :
                                                                                            `${image.file_name}`
                                                                                    }</span>
                                                                                    <div style={{ display: 'flex' }}>
                                                                                        <div style={{ fontSize: '11px', height: '12px', }}>
                                                                                            {image.latitude == "" || image.longitude == "" || image.latitude == null || image.longitude == null || image.latitude == '0' || image.longitude == '0' ? <></> : <img style={{ marginTop: '-2px', height: '12px', width: '12px', color: '#3c3c3c' }} src={LocationIcon} />}
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                                    <div style={{ height: "11px", overflow: "hidden", fontSize: '10px' }}>{image.file_size} MB</div>
                                                                                    <div style={{ height: "11px", overflow: "hidden", fontSize: '10px' }}>{image.resolution} MP</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            :
                                            <div className="rmp-dashboard-empty-data-message" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
                                                <img style={{ height: '180px', width: '180px' }} src={Noimageset} />
                                                <p style={{ marginTop: '10px' }}>No images available</p>
                                            </div>
                                    }
                                        {/* This is footer  */}
                                        {/* {this.state.showCheckboxes ? '' :
                                            <footer className="footer" style={{ visibility: (this.state.counts > userperpage ? "visible" : "hidden"), position: 'fixed', width: '100%', bottom: '3%' }} >
                                                <div style={{ float: "left", marginTop: "0.5rem", display: "flex" }}>
                                                    <div style={{ color: "#109cf1", fontSize: "13px", fontFamily: "Poppins", fontStyle: "normal", fontWeight: "600" }} >
                                                        Page : {currentpage}
                                                    </div>


                                                </div>
                                                <div style={{ float: "right", marginTop: "0.5rem", display: "flex", marginRight: '13.5rem' }}>
                                                    {this.state.currentpage == 1 ?
                                                        <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", cursor: "default", opacity: '0.5' }}>
                                                            <div className="add-user-form-submit-button" >
                                                                First
                                                            </div>
                                                        </button> :
                                                        <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", }} onClick={this.firstpage}>
                                                            <div className="add-user-form-submit-button" >
                                                                First
                                                            </div>
                                                        </button>}
                                                    {this.state.currentpage == 1 ?
                                                        <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", marginRight: "10px", marginLeft: "10px", cursor: 'default', opacity: '0.5' }}>
                                                            <div className="addcustomer-button-empty-dashboard" >
                                                                Prev
                                                            </div>
                                                        </button> :

                                                        <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", marginRight: "10px", marginLeft: "10px" }} onClick={this.prevpage}>
                                                            <div className="addcustomer-button-empty-dashboard" >
                                                                Prev
                                                            </div>
                                                        </button>}
                                                    <form>
                                                        <div style={{ display: "flex" }}>
                                                            <div className="add-user-form-label" style={{ color: "#109cf1", marginTop: "7px", fontSize: "13px", fontFamily: "Poppins", fontStyle: "normal", fontWeight: "600" }}>
                                                                Go to page:
                                                            </div>

                                                            <div className="add-user-form-text-wrapper">
                                                                <input type="text" className="add-user-form-text" style={{ height: "40px", width: "40px", textAlign: "center", color: "#109cf1", fontSize: "13px", marginLeft: "5px", backgroundColor: "#F5F6F8" }} onChange={(event) => this.changepage(parseInt(event.target.value))} name="currentpage" value={currentpage} />
                                                            </div>
                                                        </div>
                                                    </form>
                                                    {this.state.currentpage == Math.ceil(this.state.counts / this.state.userperpage) ?
                                                        <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", marginLeft: "15px", color: "#109cf1", marginLeft: "10px", cursor: "default", opacity: '0.5' }}>
                                                            <div className="addcustomer-button-empty-dashboard" >
                                                                Next
                                                            </div>
                                                        </button> :
                                                        <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", marginLeft: "15px", color: "#109cf1", marginLeft: "10px" }} onClick={this.nextpage}>
                                                            <div className="addcustomer-button-empty-dashboard" >
                                                                Next
                                                            </div>
                                                        </button>
                                                    }
                                                    {this.state.currentpage == Math.ceil(this.state.counts / this.state.userperpage) ?
                                                        <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", marginLeft: "10px", cursor: 'default', opacity: '0.5' }}>
                                                            <div className="add-user-form-submit-button" >
                                                                Last
                                                            </div>
                                                        </button> :
                                                        <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", marginLeft: "10px" }} onClick={this.lastpage}>
                                                            <div className="add-user-form-submit-button" >
                                                                Last
                                                            </div>
                                                        </button>}
                                                </div>
                                            </footer>} */}
                                    </>} </>}
                        </div>

                    </>

                    }
                </div>



            </div > : <div />
        );
    }
}

export default RMPMyImages;

