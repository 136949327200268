import React, { Component } from 'react';
import Logo from "./icons/logo.png";
import * as API from './api.js';
import './style.css';
import AppConstants from './AppConstants';
import { ActivityPopup } from './ReusableComponents/ActivityPopup.js';
import dateFormat from 'dateformat';
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import AeroMeghLogo from "./aeromeghLog.png";
import { formatToIndianRupees } from './ReusableComponents/reusableFunctions.js';
import LoaderComponent from './ReusableComponents/LoaderComponent.js';
import RenewSubscription from './subscription/Reusable/RenewSubscription.js';
import { BreadCrumbs } from './ReusableComponents/BreadCrumbs.js';
export default class Invoice extends Component {
    state = {
        user: {},
        type: "",
        org: {},
        loading: true,
        sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk"),
        invoiceID: this.props.location.state.invoiceId,
        activityPopupObj: {
            item: undefined,
            open: undefined,
            msg: undefined,
        },
        invoice: {},
    }

    parentSidebarSwitch = () => {
        this.setState((state) => ({
            ...state,
            sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
        }))
    }

    componentDidMount() {
        if (this.props.location.state.invoice) {
            let { invoice } = this.props.location.state
            console.log(invoice)
            let product = invoice.product
            let basePrice = invoice.price
            let discount = basePrice * (invoice.discount / 100)
            discount = discount.toFixed(2)
            let discounted = basePrice - discount
            let total = discounted.toFixed(2)
            this.setState({
                invoice,
                basePrice,
                discount,
                total,
            }, () => { this.setState({ loading: false }) })
        } else
            API.getInvoice(this.state.invoiceID).then(res => {
                let { invoice } = res
                let product = invoice.product
                let basePrice = invoice.price
                let discount = basePrice * (invoice.discount / 100)
                discount = discount.toFixed(2)
                let discounted = basePrice - discount
                let total = discounted.toFixed(2)
                this.setState({
                    invoice,
                    basePrice,
                    discount,
                    total,
                }, () => { this.setState({ loading: false }) })
            }).catch(e => {
                this.setState({
                    activityPopupObj: {
                        item: 'ERROR',
                        open: true,
                        msg: "Currupted Invoice",
                    }
                })
            })
    }

    componentWillMount() {
        document.title = `Invoice - ${this.state.invoiceID} - AeroGCS`;
        if (this.props.location.state) {
            let { user, type, organizationID, modelNumber } = this.props.location.state;
            this.setState({
                user,
                type,
                organizationID,
                modelNumber
            })
        } else {
            API.getUserType().then(([blah, type, user]) => {
                this.setState({
                    user,
                    type
                })
            }, (e) => {
                window.location.replace("/login");
            })
        }
    }

    downloadPDF = () => {
        const input = document.querySelector('.myDiv');

        html2canvas(input, {
            scale: 3,
            useCORS: true,
            backgroundColor: null,
        }).then(canvas => {
            const imgWidth = 210;
            const pageHeight = 297;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            const heightLeft = imgHeight;

            const pdf = new jsPDF({
                orientation: 'portrait',
                unit: 'mm',
                format: 'a4',
            });

            let position = 0;

            pdf.addImage(
                canvas.toDataURL('image/png'),
                'PNG',
                0,
                position,
                imgWidth,
                imgHeight
            );

            let remainingHeight = heightLeft;
            while (remainingHeight > pageHeight) {
                position -= pageHeight;
                pdf.addPage();
                pdf.addImage(
                    canvas.toDataURL('image/png'),
                    'PNG',
                    0,
                    position,
                    imgWidth,
                    imgHeight
                );
                remainingHeight -= pageHeight;
            }

            pdf.save('invoice.pdf');
        });
    };

    render() {
        let { user } = this.state
        return (
            <div className="wrapper">
                <div className="right-content-new">
                    <ActivityPopup
                        item={this.state.activityPopupObj?.item}
                        open={this.state.activityPopupObj?.open}
                        msg={this.state.activityPopupObj?.msg}
                        close={() => window.location.replace(process.env.REACT_APP_CLIENT_PROTOCOL + "://" + process.env.REACT_APP_CLIENT_HOST + (process.env.REACT_APP_CLIENT_PORT ? process.env.REACT_APP_CLIENT_PORT : ""))}
                    />
                    <div className="top-bar-new" style={{ display: "flex", zIndex: '2' }}>
                        <div className="top-bar-text-new">
                            <BreadCrumbs
                                data={[
                                    {
                                        displayName: "Settings",
                                        pathname: "/profile-settings",
                                        state: {
                                            user
                                        }
                                    },
                                    {
                                        displayName: "Invoice",
                                        pathname: this.props.location.state.user_info ? `/admin/userdetails` : "/profile-settings",
                                        state: {
                                            user,
                                            onPage: 3,
                                            user_info: this.props.location?.state?.user_info || null
                                        }
                                    },
                                    {
                                        displayName: this.state?.invoice?.invoice_number || "-",
                                    }
                                ]}
                            />

                        </div>
                    </div>
                    {this.state.loading ? <LoaderComponent /> : <div className="main-content-new">
                        <ActivityPopup
                            item={this.state.activityPopupObj?.item}
                            open={this.state.activityPopupObj?.open}
                            msg={this.state.activityPopupObj?.msg}
                            close={() => window.location.replace(process.env.REACT_APP_CLIENT_PROTOCOL + "://" + process.env.REACT_APP_CLIENT_HOST + (process.env.REACT_APP_CLIENT_PORT ? process.env.REACT_APP_CLIENT_PORT : ""))}
                        />
                        <div style={{ overflow: "auto", position: "relative" }}>
                            <div style={{ position: 'absolute', top: '10px', right: '410px', display: 'flex', alignItems: 'center', gap: '20px' }}>
                                {this.state.invoice.payment?.payment_status == "due" && !this.props.location.state.adminView && <RenewSubscription data={this.state.invoice} user={this.state.user} />}
                                <a style={{ color: '#007BFF', cursor: 'pointer', fontSize: '14px', }} onClick={() => this.downloadPDF()}>Download Invoice</a>
                            </div>
                            <div style={{ width: '620px', margin: '50px auto', border: '1px solid rgba(215, 218, 224, 0.4)', borderRadius: '5px', boxShadow: '0 0 10px rgba(0,0,0,0.1)', color: "#3B3B3B", backgroundColor: '#fff' }}>
                                <div className='myDiv'>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '0px 30px', padding: '20px 0px', borderBottom: '1px solid rgba(215, 218, 224, 0.3)', }}>
                                        <div>
                                            <img src={AeroMeghLogo} alt="AElogo" width={"100px"} />
                                        </div>
                                        <div style={{ textAlign: 'right' }}>
                                            <p style={{ margin: '0', fontSize: "13px", }}><span style={{ fontWeight: 600, color: "#1A1C21" }}>Invoice No.</span> - {this.state.invoice?.invoice_number || "-"}</p>
                                        </div>
                                    </div>

                                    <div style={{ display: 'flex', justifyContent: 'space-between', color: "#727272", padding: "20px 30px" }}>
                                        <div>
                                            <div style={{ marginBottom: '20px' }}>
                                                <p style={{ margin: '0', fontWeight: 500 }}>To</p>
                                                <p style={{ margin: '0', fontWeight: 'bold', color: "#1E1E1E" }}>{`${this.state.invoice?.to?.name}` || "-"}</p>
                                                {this.state.invoice?.to?.mobileNumber && <p style={{ margin: '0' }}>{this.state.invoice?.to?.mobileNumber}</p>}
                                                <p style={{ margin: '0' }}>{this.state.invoice?.to?.address || "-"}</p>
                                            </div>
                                            <div>
                                                <p style={{ margin: '0', fontWeight: 500 }}>From</p>
                                                <p style={{ margin: '0', fontWeight: 'bold', color: "#1E1E1E" }}>{this.state.invoice?.from?.name}</p>
                                                <p style={{ margin: '0' }}>{this.state.invoice?.from?.address}</p>
                                                <p style={{ margin: '0' }}>GSTIN - {this.state.invoice?.from?.gstin}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ borderTop: '1px solid rgba(215, 218, 224, 0.6)', paddingTop: '10px', margin: "10px 30px 30px", display: "flex", justifyContent: "space-between" }}>
                                        <p style={{ margin: '0', fontSize: "14px" }}>
                                            <span style={{ fontWeight: 600, color: "#5E6470" }}>Invoice Date - </span>
                                            <span style={{ color: "#5E6470" }}>{dateFormat(this.state.invoice?.payment?.date_time, "dd mmm yyyy")}</span>
                                        </p>
                                        {/* <p style={{ margin: '0', fontSize: "14px" }}>
                                            <span style={{ fontWeight: 600, color: "#5E6470" }}>Subscription id - </span>
                                            <span style={{ color: "#5E6470" }}>{this.state.invoice?.payment?.subscription_id}</span>
                                        </p> */}
                                        <p style={{ margin: '0', fontSize: "14px" }}>
                                            <span style={{ fontWeight: 600, color: "#5E6470" }}>Transaction id - </span>
                                            <span style={{ color: "#5E6470" }}>{this.state.invoice?.payment?.transaction_id}</span>
                                        </p>
                                    </div>

                                    <div style={{ backgroundColor: '#D9D9D9', padding: '10px 25px', display: 'flex', justifyContent: 'space-between', fontWeight: 700, color: '#1E1E1E', height: "50px", alignItems: "center", fontSize: "18px" }}>
                                        <span>Payment Summary</span>
                                        <span>Rates</span>
                                    </div>

                                    <div style={{ padding: '10px 30px 50px' }}>
                                        <div style={{ marginBottom: '10px' }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 500 }}>
                                                <span>{this.state.invoice?.product?.replaceAll("_", " ").split(" ").map(word => word.split("").map((letter, i) => i === 0 ? letter.toUpperCase() : letter.toLowerCase()).join("")).join(" ")}</span>
                                                <span>₹ {formatToIndianRupees(this.state.basePrice || 0)}</span>
                                            </div>
                                        </div>
                                        {this.state.discount > 0 && <div style={{ marginBottom: '10px' }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 500 }}>
                                                <span>Discount</span>
                                                <span>₹ {formatToIndianRupees(this.state.discount || 0)}</span>
                                            </div>
                                        </div>}
                                        {/* {this.state.invoice?.applied_csgt_amount > 0 &&
                                            <div style={{ marginBottom: '10px' }}>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 500 }}>
                                                    <span>CGST</span>
                                                    <span style={{}}>+ ₹ {formatToIndianRupees(this.state.invoice?.applied_csgt_amount || 0)}</span>
                                                </div>
                                            </div>
                                        } */}
                                        {/* {this.state.invoice?.applied_sgst_amount > 0 &&
                                            <div style={{ marginBottom: '10px' }}>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 500 }}>
                                                    <span>SGST</span>
                                                    <span style={{}}>+ ₹ {formatToIndianRupees(this.state.invoice?.applied_sgst_amount || 0)}</span>
                                                </div>
                                            </div>
                                        } */}
                                        <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '10px', marginTop: '10px', marginBottom: '20px', fontWeight: 'bold', borderTop: '1px solid rgba(215, 218, 224, 0.6)', borderBottom: '1px solid rgba(215, 218, 224, 0.6)', paddingBottom: '10px', fontSize: "18px" }}>
                                            <span>Total Amount</span>
                                            <span>₹ {formatToIndianRupees(this.state.total || 0)}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>}
                </div>
            </div>)
    }
}
