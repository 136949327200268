import React, { Component } from 'react';
import waiting from '../res/waiting.png';
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import * as API from '../api.js';
import MyProfile from "./MyProfile";
import Invoices from "../Invoices";
import EditUser from "./editUser";
import MgmtSettings from "./MgmtSettings";
import { Line, Circle } from 'rc-progress'
import SettingsActive from '../sidebaricon/SettingsActive.png';
import { checkExpiry, addUserAnalytic, updateOutTime } from '../ReusableComponents/reusableFunctions.js';
import { updateProfileDetails, activesubscription, updateSubscriptionData } from '../redux';
import Help from "../sidebaricon/Help.png";
import APIKeys from './APIKeys';
import AppConstants from '../AppConstants';
import BuyDrone from '../ReusableComponents/BuyDrone';
import { ActivityPopup } from '../ReusableComponents/ActivityPopup';
import ChangeMobileNumber from '../userAuth/ChangeMobileNumber.js';
import profileIconActive from '../Icon/profileIconActive.png';
import profileIconInActive from '../Icon/profileIconInActive.png';
import CalendarCheckActive from '../Icon/CalendarCheckActive.svg';
import CalendarCheckInactive from '../Icon/CalendarCheckInactive.svg';
import invoice_active from '../Icon/invoice_active.svg';
import invoice_inactive from '../Icon/invoice_inactive.svg';
import LockActive from '../Icon/LockActive.svg';
import LockInActive from '../Icon/LockInActive.svg'
import KeyActive from '../Icon/KeyActive.svg'
import KeyInActive from '../Icon/KeyInActive.svg'
import EmailActive from '../Icon/EmailActive.svg'
import EmailInActive from '../Icon/EmailInActive.svg'
import settingTeamActive from '../Icon/settingTeamActive.png'
import settingTeamInActive from '../Icon/settingTeamInActive.png'
import SubscriptionModel from './SubscriptionModel.js';
import { BreadCrumbs } from '../ReusableComponents/BreadCrumbs.js';
import EmailSettings from './EmailSettings.js';
import TeamAccess from './TeamAccess.js';
import LoaderComponent from '../ReusableComponents/LoaderComponent.js';
export const BUY_NEW_DRONES = "BUY_NEW_DRONES"
export const BUY_MORE_DRONES = "BUY_MORE_DRONES"
export const CANCEL_DUE_PAYMENT = "CANCEL_DUE_PAYMENT"
export const CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION"
export const CANCEL_DRONE_ADDON = "CANCEL_DRONE_ADDON"
class ProfileSettings extends Component {

    state = {
        subscriptions: [],
        user: {},
        type: "",
        avatarURL: '',
        createIcon: waiting,
        showCancelSubsPopup: false,
        cancelSubsText: "Are you sure you want to cancel subscription ?",
        org: {},
        models: [],
        model: {},
        outId: "",
        loading: true,
        sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk"),
        subscriptionTitleMap: {
            "aerogcs_plan_pro": "AeroMegh Pro",
            "aerogcs_plan_free": "AeroMegh Free",
            "aeromegh_plan_pro": "AeroMegh Pro",
            "aeromegh_plan_free": "AeroMegh Free",
            "aerostream_plan_starter": "AeroStream Starter",
            "aerostream_plan_pro": "AeroStream Pro"
        },
        onPage: 1,
        pendingPayments: [],
        subLoading: false
    }

    getAllSubscriptions = () => { this.setState({ subscriptions: (this.props.SubscriptionData?.plans || []) }) }

    toggleTwoStepAuth = (isVerified, phone) => {
        if (this.state.user.otp_flag || isVerified || (this.state.user.phone && this.state.user.phone_verified)) API.updateTwoStepAuth(!this.state.user.otp_flag)
            .then(data => {
                this.setState({
                    user: {
                        ...this.state.user,
                        otp_flag: !this.state.user.otp_flag,
                        phone_verified: isVerified || this.state.user.phone_verified,
                        phone: phone || this.state.user.phone
                    },
                    phoneVerificationPopup: false,
                    showProcessPopup: true,
                    processTitle: 'Two step authentication',
                    processMsg: !this.state.user.otp_flag ? 'Two step authentication enabled successfully!' : 'Two step authentication disabled successfully!',
                })
            })
            .catch(e => {
                alert("Something went wrong while upating auth settings.")
            })
        else this.setState({
            phoneVerificationPopup: true
        })
    }

    handleCancelDuePayment = async () => {
        this.setState({ quantityData: this.handleQuantityData("Cancelled") }, () => {
            fetch(`${process.env.REACT_APP_SERVER_PROTOCOL}://${process.env.REACT_APP_SERVER_HOST}${process.env.REACT_APP_SERVER_PORT}/cancelDuePayment/${this.state.quantityData.id}/${this.state.quantityData?.price_id}?email=${this.state.user.username}&quantity=${1}`, { method: "POST" }).then(res => {
                this.setState((state) => ({
                    ...state,
                    canceling: false,
                    canceled: true,
                    cancelSubsText: 'Your drone add-on payment has been cancelled succesfully'
                    ,
                }), () => {
                    this.getPendingPayments()
                })
            }).catch(err => console.log(err))
        })

    }
    handleQuantityData = (status) => {
        if (this.state.plans.length > 0) {
            const droneAddonList = this.state.plans.filter(pl => pl.service === AppConstants.SERVICES.DRONE_CONTROL_CENTER) //all the list of plans with drone control center as serivce
            for (const drone of droneAddonList) {
                for (const userPlan of (this.props.SubscriptionData?.plans || [])) { // all the list of user plans
                    if (drone.id === userPlan.plan_id && userPlan.service_name === AppConstants.SERVICES.DRONE_CONTROL_CENTER && userPlan.subscription_status === 'Active') //find plan which is cancelled or activer
                    {
                        return { ...drone, ...userPlan }
                    }
                }
            }

        }
    }
    getAeromeghPlans = () => {
        API.getAeromeghPlans().then(res => {
            if (res.plans.length > 0) {
                this.setState((state) => ({
                    ...state,
                    plans: res.plans
                }))
            } else {
                return 0
            }
            this.getAllSubscriptions();
            this.getViewersStat()
        }).catch(e => {
            console.log(e)
        })
    }

    componentWillUnmount() { updateOutTime(this.state.outId) }

    componentWillMount() {
        addUserAnalytic(this.props.location.pathname).then(id => this.setState({ outId: id }))
        document.title = "My Profile - AeroGCS";
        this.refreshUser().then(user => {
            this.getPendingPayments(false, true)
            if (this.props.location.state) {
                let { type, organizationID, modelNumber, view, onPage } = this.props.location.state;
                if (view) this.changeView(view)
                if (!user.sub) {
                    user.sub = { "plan": "aeromegh_plan_free" }
                }
                if (user.avatar_url && user.avatar_url.length > 0) {
                    this.setState((state) => ({
                        ...state,
                        avatarURL: `${user.avatar_url}?${user.sas_token}&t${new Date().getTime()}`
                    }))

                } else {
                    let avatarURL = `/images/${user.first_name[0].toLowerCase()}.PNG`
                    this.setState((state) => ({
                        ...state,
                        avatarURL: avatarURL
                    }))
                }
                this.setState((state) => ({
                    ...state,
                    type,
                    organizationID,
                    modelNumber,
                    onPage: onPage || 1
                }), () => {
                    this.getAeromeghPlans();
                })
            } else {
                API.getUserType().then(([blah, type, user]) => {
                    if (!user.sub) {
                        user.sub = {
                            "plan": "aeromegh_plan_free"
                        }
                    }
                    this.setState((state) => ({
                        ...state,
                        type,
                    }), () => {
                        this.getAeromeghPlans();

                    })
                }, (e) => {
                    window.location.replace("/login");
                })
            }
        });
    }

    refreshUser = async () => {
        return new Promise((resolve, reject) => {
            API.getUserType().then(([blah, type, user]) => {
                this.props.updateProfileDetails({
                    activeTeamId: '',
                    activeTeam: '',
                    isLoggedInUserOwner: false,
                    user: user,
                    type: type
                })
                this.setState((state) => ({
                    ...state,
                    user,
                    type
                }), () => {
                    this.changeView(1)
                    resolve(user)
                })
            }, (e) => {
                window.location.replace("/login");
            })
        })
    }

    changeView = (viewPage) => {
        if (viewPage == 2)
            document.title = "My Subscriptions - AeroMegh";
        this.setState((state) => ({
            ...state,
            onPage: viewPage
        }))
    }

    getViewersStat = () => {
        API.getViewerMinutesStats().then(r => {
            this.setState({
                viewMinutesStats: r,
                loading: false,
            })
        }).catch(e => {
            alert(e)
        })
    }

    getPendingPayments = (redirectToPaymentPage) => {
        API.getPendingPayments().then(res => {
            this.setState({ pendingPayments: res.pendingPayments, updateComponent: !this.state.updateComponent }, () => {
                if (redirectToPaymentPage) {
                    document.getElementById('payment-link').click()

                } else {
                }
            })
        }).catch(err => {
            console.log(err)
        })
    }

    callPendingPaymentsNoRedirection = () => {
        this.getPendingPayments(false)
    }

    callPendingPaymentsRedirection = () => {

        this.getPendingPayments(true)
    }

    callPendingPaymentMultiple = async (times) => {
        if (!times) times = 1
        const arr = [...Array(times)]
        await Promise.all(arr.map(item => {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    this.getPendingPayments()
                    resolve()
                }, 2000)
            })
        }))


    }

    handleTabSwitch = () => {
        if (!this.state.paymentIntiated) return
        if (typeof document.hidden !== "undefined") {
            // Set the name of the hidden property and the change event
            const hiddenProperty = "hidden" in document ? "hidden" : "webkitHidden";
            const visibilityChangeEvent = hiddenProperty.replace(/hidden/i, "visibilitychange");

            // Add an event listener for visibility change
            document.addEventListener(visibilityChangeEvent, async () => {
                if (document[hiddenProperty]) {
                    await this.callPendingPaymentMultiple(3)

                    // Tab is switched or the window is minimized
                } else {
                    await this.callPendingPaymentMultiple(3)
                    // Tab is active or the window is maximized
                }
            });
        } else {
            // Page Visibility API is not supported
        }

    }

    redirectToPayment = (qtyInc) => {
        this.setState({ paymentIntiated: true, }, async () => {
            await this.callPendingPaymentMultiple(5)
            this.callPendingPaymentsRedirection()
            qtyInc && this.setState({ showProcessPopup: false, processMsg: "", processIcon: "" })
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.paymentIntiated !== this.state.paymentIntiated && this.state.paymentIntiated) this.handleTabSwitch()
    }

    componentDidMount() {
        this.handleTabSwitch()
        this.getUserSubscriptionPlan()

    }

    getUserSubscriptionPlan = () => {
        this.setState({ subLoading: true }, () => {
            API.getUserSubscriptionPlan().then((sub) => {
                this.setState({
                    subLoading: false
                })
                if (sub.plans) {
                    this.setState({
                        user_subscription_plans: sub.plans,
                        buyingDroneFirstTime: (sub.plans).find(plan => plan.service_name == AppConstants.SERVICES.DRONE_CONTROL_CENTER && (plan.subscription_status) == "Active") ? false : true
                    })
                    this.props.updateSubscriptionData({
                        subscriptionData: sub
                    })
                }
            }).catch(err => {
                this.setState({ subLoading: false })
                console.log(err)
            })
        })
    }

    render() {
        let { user, type, model, sidebarMode, onPage } = this.state
        let viewerMinutesUsagePercent = null
        if (this.state.viewMinutesStats) {

            if (this.state.viewMinutesStats.quota === 0) {
                viewerMinutesUsagePercent = 100
            } else {
                viewerMinutesUsagePercent = (this.state.viewMinutesStats.used / this.state.viewMinutesStats.quota) * 100
            }

        }
        return (
            user.username ? (<div className="wrapper">
                <div className="right-content-new"
                    style={{ overflowY: "hidden", height: "100%" }}>
                    <ActivityPopup
                        title={this.state.processTitle}
                        open={this.state.showProcessPopup}
                        icon={this.state.processIcon}
                        msg={this.state.processMsg}
                        close={() => {
                            switch (this.state.processOkAction) {
                                case CANCEL_DUE_PAYMENT:
                                    this.setState({ showProcessPopup: false })
                                    break;
                                case BUY_MORE_DRONES:
                                    this.redirectToPayment(true)
                                    break;
                                default: this.setState({ showProcessPopup: false })
                                    break;
                            }
                        }}
                        onClickOk={() => {
                            switch (this.state.processOkAction) {
                                case CANCEL_DUE_PAYMENT:
                                    this.handleCancelDuePayment()
                                    this.setState({ showProcessPopup: false })
                                    break;
                                case BUY_MORE_DRONES:
                                    this.redirectToPayment(true)
                                    break;
                                default: break;
                            }
                        }}
                    />
                    {this.state.phoneVerificationPopup && <ChangeMobileNumber
                        onMobileNumberChanged={(phone) => { this.toggleTwoStepAuth(true, phone) }}
                        onClose={() => this.setState({
                            phoneVerificationPopup: false
                        })}
                        user={user} phone={user.phone || ""} />}
                    <a id='payment-link' href={this.state.pendingPayments?.[0]?.payment_link} target='_blank'></a>
                    <div className="top-bar-new" style={{ display: "flex", zIndex: '2' }}>
                        <div className="top-bar-text-new">
                            <BreadCrumbs
                                data={[
                                    {
                                        displayName: "Settings",
                                        pathname: "/profile-settings",
                                        state: {
                                            user
                                        }
                                    },
                                    {
                                        displayName: onPage == 1 ? 'Profile' : onPage == 2 ? 'Subscriptions' : onPage == 3 ? 'Invoices' : onPage == 4 ? 'Security' : onPage == 5 ? 'API Keys' : onPage == 6 ? 'Email Settings' : onPage == 7 ? 'Team Access' : '',
                                    }]}
                            />
                        </div>
                    </div>
                    {this.state.loading ? <LoaderComponent /> :
                        <div style={{ position: 'absolute', height: "100%", width: "100%" }}>
                            <div style={{ display: 'flex', width: '90%', height: '80%', justifyContent: 'center', position: 'relative', top: '45%', left: '50%', transform: 'translate(-50%, -50%)', border: '1px solid grey', background: "white", border: 'none', borderRadius: "7px", }}>
                                <div style={{ width: '20%', padding: "20px" }}>
                                    <div className={this.state.onPage == 1 || this.state.onPage == 0 ? "active-text" : "inactive-text"}
                                        onClick={() => this.changeView(1)}>
                                        <img src={this.state.onPage == 1 || this.state.onPage == 0 ? profileIconActive : profileIconInActive} alt="profileIcon"
                                            style={{ height: "20px" }} />
                                        <div>Profile</div>
                                    </div>
                                    <div className={this.state.onPage == 2 ? "active-text" : "inactive-text"}
                                        onClick={() => {
                                            this.changeView(2)
                                            this.getUserSubscriptionPlan()
                                        }}>
                                        <img src={this.state.onPage == 2 ? CalendarCheckActive : CalendarCheckInactive} alt="profileIcon" style={{ height: "20px" }} />
                                        <div>Subscriptions</div>
                                    </div>
                                    <div className={this.state.onPage == 3 ? "active-text" : "inactive-text"} onClick={() => this.changeView(3)}>
                                        <img src={this.state.onPage == 3 ? invoice_active : invoice_inactive} alt="profileIcon" style={{ height: "20px" }} />
                                        <div>Invoices</div>
                                    </div>
                                    <div className={this.state.onPage == 4 ? "active-text" : "inactive-text"} onClick={() => this.changeView(4)}>
                                        <img src={this.state.onPage == 4 ? LockActive : LockInActive} alt="profileIcon" style={{ height: "20px" }} />
                                        <div>Security</div></div>
                                    <div className={this.state.onPage == 5 ? "active-text" : "inactive-text"} onClick={() => this.changeView(5)}>
                                        <img src={this.state.onPage == 5 ? KeyInActive : KeyActive} alt="profileIcon" style={{ height: "20px" }} />
                                        <div>API keys</div>
                                    </div>
                                    <div className={this.state.onPage == 6 ? "active-text" : "inactive-text"} onClick={() => this.changeView(6)}>
                                        <img src={this.state.onPage == 6 ? EmailActive : EmailInActive} alt="emailISettingIcon" style={{ height: "15px" }} />
                                        <div>Email Settings</div>
                                    </div>
                                    <div className={this.state.onPage == 7 ? "active-text" : "inactive-text"} onClick={() => this.changeView(7)}>
                                        <img src={this.state.onPage == 7 ? settingTeamActive : settingTeamInActive} alt="emailISettingIcon" style={{ height: "20px" }} />
                                        <div>My Team Access</div>
                                    </div>
                                </div>
                                <div style={{ width: "80%" }}>
                                    <div style={{ borderRadius: "0px", height: "100%", overflow: "auto" }} className="box-shadow-1" >
                                        {this.state.onPage == 0 ? <div> <EditUser user={user} type={type} first_name={user.first_name} last_name={user.last_name} email={user.username} address={user.address} phone={user.phone} userid={user.userid} country={user.country} avatarURL={user.avatar_url} sas_token={user.sas_token} cancel={() => this.changeView(1)} save={() => this.refreshUser()} /> </div>
                                            : this.state.onPage == 1 ? <div><MyProfile toggleTwoStepAuth={this.toggleTwoStepAuth} edit={() => this.changeView(0)} user={user} type={type} save={() => this.refreshUser()} /> </div>
                                                : this.state.onPage == 2 ? <div style={{ height: "100%" }}><SubscriptionModel /></div>
                                                    : this.state.onPage == 3 ? <div style={{ height: "100%" }}><Invoices user={user} type={type} /></div>
                                                        : this.state.onPage == 4 ? <div><MgmtSettings user={user} cancel={() => this.changeView(1)} toggleTwoStepAuth={this.toggleTwoStepAuth} type={type} /></div>
                                                            : this.state.onPage == 5 ? <div><APIKeys user={user} type={type} /></div>
                                                                : this.state.onPage == 6 ? <div> <EmailSettings user={user} type={type} /> </div>
                                                                    : this.state.onPage == 7 ? <div> <TeamAccess user={user} type={type} /></div>
                                                                        : ''
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>}
                </div>
            </div >
            ) : ''
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateProfileDetails: (payload) => dispatch(updateProfileDetails(payload)),
        activesubscription: (payload) => dispatch(activesubscription(payload)),
        updateSubscriptionData: (payload) => dispatch(updateSubscriptionData(payload))
    };
};

const mapStateToProps = state => {
    return {
        activeTeamId: state.profile.activeTeamId,
        activeTeam: state.profile.activeTeam,
        SubscriptionData: state.subscription.subscriptionData,
        activesubscription: state.activesubscription.activesubscription,
        isLoggedInUserOwner: state.profile.isLoggedInUserOwner
    };
};

export default (connect(mapStateToProps, mapDispatchToProps)(ProfileSettings));