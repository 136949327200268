import React, { Component } from "react";
import * as API from "../api.js";
import { Link, StaticRouter, Redirect } from "react-router-dom";
import aeromeghLogo from './assets/aeromeghLogo.png'
import emailIcon from './assets/email.svg'
import userIcon from './assets/user.svg'
import lockIcon from './assets/lock.svg'
import tickLockIcon from './assets/ticklock.svg'
import googleIcon from './assets/google.png'
import Cookies from 'universal-cookie';
import Loader from '../icons/loader.svg'
import microsoftIcon from './assets/microsoft.png'
import { ActivityPopup } from '../ReusableComponents/ActivityPopup.js';
import CookiePolicyPopup from "./CookiePolicyPopup.js";
import eyeIcon from './assets/eye.svg'
import eyeOffIcon from './assets/eye-off.svg'
import LoaderComponent from "../ReusableComponents/LoaderComponent.js";

const cookie = new Cookies()
const query = new URLSearchParams(window.location.search);

class userSignup extends Component {
    state = {
        first_name: "",
        last_name: "",
        password: "",
        passwordVerify: "",
        address: "",
        email: "",
        error: "",
        registrationType: "individual",
        checked: false,
        signUpSuccess: false,
        loading: false,
        showPassword: false,
        showCPassword: false,
    }

    componentWillMount() {
        if (query.get("token")) {
            this.setState((state) => ({
                ...state,
                loading: true,
            }))
            cookie.set("session_IAMAero", query.get("token"), {
                path: "/"
            });
            window.location.replace("/")
        }
        document.title = "AeroGCS - Signup";
    }

    componentDidMount() {
        setTimeout(() => {
            if (query.get("error")) {
                let msg = query.get("error") == 0 ? "Google authentication failed, please try again." : "Microsoft authentication failed, please try again."
                this.setState((state) => ({
                    ...state,
                    itemName: "Login",
                    processPopupIcon: "ERROR",
                    processAction: "",
                    processMessage: msg,
                    showProcessPopup: true,
                }))
            }
        }, 1000)

    }

    onFormSubmit = (formData) => {
        formData.preventDefault()
        if (this.state.password === this.state.passwordVerify) {
            this.setState({ loadingData: true })
            API.signUp(this.state).then((e) => {
                this.setState({ signUpSuccess: true, loadingData: false })
            }).catch((e) => {
                e.then(error => {
                    this.setState({
                        loadingData: false,
                        error: undefined, error: error.message || "Something went wrong..."
                    })
                })
            })
        } else {
            this.setState({
                error: "Passwords do not match"
            })
        }
    }

    googleLogin = () => {
        window.location.replace(`${process.env.REACT_APP_SERVER_PROTOCOL}://${process.env.REACT_APP_SERVER_HOST}${process.env.REACT_APP_SERVER_PORT ? process.env.REACT_APP_SERVER_PORT : ''}/auth/google`)
    }

    microsoftLogin = () => {
        window.location.replace(`${process.env.REACT_APP_SERVER_PROTOCOL}://${process.env.REACT_APP_SERVER_HOST}${process.env.REACT_APP_SERVER_PORT ? process.env.REACT_APP_SERVER_PORT : ''}/auth/microsoft`)
    }

    closeActivityPopup = () => {
        this.setState((state) => ({
            ...state,
            showProcessPopup: false,
            processMessage: null,
        }))
    }

    render() {
        return (
            <div>
                <ActivityPopup
                    item={this.state.itemName}
                    open={this.state.showProcessPopup}
                    icon={this.state.processPopupIcon}
                    action={this.state.processAction}
                    msg={this.state.processMessage}
                    close={() => this.closeActivityPopup()}
                    onClickOk={() => this.closeActivityPopup()}
                >

                </ActivityPopup>

                {this.state.loading ? <LoaderComponent />
                    : <div className="authPage">
                        <CookiePolicyPopup />
                        <div className="EnterpriseLogoName">
                            <img src={aeromeghLogo} className="EnterpriseLogoNameImg" />
                            <div>AeroGCS<span className="EnterpriseLogoNameSpan"> Enterprise</span></div>
                        </div>
                        {this.state.signUpSuccess ?
                            <div className="cardLogin login-card">
                                <div className="loginCardHeader">Thanks For Registering !</div>
                                <div className="contentText">Activation link sent to <span style={{ fontWeight: '700' }}>{this.state.email}</span>. check your email.</div>
                                <Link to={{ pathname: "/login", }}>
                                    <button className="loginUserButton" name="loginUserButton">LOGIN</button>
                                </Link>
                            </div>
                            : <form className="cardLogin login-card" id="signupfrm" onSubmit={this.onFormSubmit}>
                                <div className="loginCardHeader">Welcome !</div>
                                <div className="login-error1">{this.state.error || ''} </div>
                                <div className="add-user-form-row1">
                                    <img className="inputLeftIcon" src={userIcon} />
                                    <input onChange={(e) => {
                                        this.setState({
                                            error: undefined,
                                            first_name: e.target.value
                                        })
                                    }}
                                        className="loginSignupInput" id="first_name" name="first_name" type="text" maxLength={30}
                                        placeholder="First Name" required pattern="([a-zA-Z]{3,30}\s*)+"
                                        title="First Name must contain only upper case and lower case characters, at least 3 character, maximum 29 characters" />
                                </div>

                                <div className="add-user-form-row1">
                                    <img className="inputLeftIcon" src={userIcon} />
                                    <input onChange={(e) => {
                                        this.setState({
                                            error: undefined,
                                            last_name: e.target.value
                                        })
                                    }}
                                        className="loginSignupInput" id="last_name" name="last_name" type="text" maxLength={30}
                                        placeholder="Last Name" required pattern="([a-zA-Z]{3,30}\s*)+"
                                        title="Last Name must contain only upper case and lower case characters, at least 3 character, maximum 29 characters" />

                                </div>

                                <div className="add-user-form-row1">
                                    <img className="inputLeftIcon" src={emailIcon} />
                                    <input onChange={(e) => {
                                        this.setState({
                                            error: undefined,
                                            email: e.target.value.toLowerCase()
                                        })
                                    }} className="loginSignupInput" id="email" name="email" type="email" placeholder="E-mail"
                                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required maxLength={100} />

                                </div>

                                <div className="add-user-form-row1">
                                    <img className="inputLeftIcon" src={lockIcon} />
                                    <input onChange={(e) => {
                                        this.setState({
                                            error: undefined,
                                            password: e.target.value
                                        })
                                    }} className="loginSignupInput" id="password" name="password" type={this.state.showPassword ? "text" : "password"} placeholder="Password"
                                        pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" minLength={8} maxLength={30}
                                        title="Password must contain at least 1 upper case character, at least 1 lower case character, at least 1 digit and 8 total characters"
                                        required />
                                    <img className="inputRightIcon" id="view-password"
                                        src={this.state.showPassword ? eyeOffIcon : eyeIcon} onClick={() => {
                                            this.setState({ showPassword: !this.state.showPassword })
                                        }} />
                                </div>

                                <div className="add-user-form-row1">
                                    <img className="inputLeftIcon" src={tickLockIcon} />
                                    <input onChange={(e) => {
                                        this.setState({
                                            error: undefined,
                                            passwordVerify: e.target.value
                                        })
                                    }} className="loginSignupInput" id="cpassword" name="password" type={this.state.showCPassword ? "text" : "password"} placeholder="Confirm Password"
                                        pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" minLength={8} maxLength={30}
                                        title="Password must contain at least 1 upper case character, at least 1 lower case character, at least 1 digit and 8 total characters"
                                        required />
                                    <img className="inputRightIcon" id="view-password"
                                        src={this.state.showCPassword ? eyeOffIcon : eyeIcon} onClick={() => {
                                            this.setState({ showCPassword: !this.state.showCPassword })
                                        }} />
                                </div>

                                <div className="signupAgreement">
                                    <input type="checkbox" id="tnc" name="tnc"
                                        onChange={() => {
                                            this.setState({
                                                error: undefined,
                                                checked: !this.state.checked
                                            })
                                        }} />I agree to <a
                                            href="https://aerogcs.com/terms-of-services/" target="_blank"
                                        ><u> terms and conditions</u></a>
                                </div>
                                <button type="submit" className={(this.state.loadingData || !this.state.checked || this.state.first_name === "" || this.state.last_name === "" || this.state.email === "" || this.state.password === "" || this.state.passwordVerify === "") ?
                                    "loginUserButtonDisable" : "loginUserButton"} name="loginButton" id="loginButton"
                                    disabled={(this.state.loadingData || !this.state.checked || this.state.first_name === "" || this.state.last_name === "" || this.state.email === "" || this.state.password === "" || this.state.passwordVerify === "")}>Sign Up</button>

                                <div className="signup">
                                    Already have an account? <Link className="login-signup-button" to={{ pathname: "/login", }} style={{ marginLeft: '4px' }}><u>Login</u></Link>
                                </div>

                                <div className="signupOrData">OR</div>
                                <div className="OauthContainer">
                                    <div className="sigin-oauth" onClick={() => { this.googleLogin() }}>
                                        <img src={googleIcon} className="sigin-oauthImg" />
                                        Google
                                    </div>

                                    <div className="sigin-oauth" onClick={() => { this.microsoftLogin() }}>
                                        <img src={microsoftIcon} className="sigin-oauthImg" />
                                        Microsoft
                                    </div>
                                </div>
                            </form>}
                    </div>}
            </div>
        );
    }
}

export default userSignup
