
import React, { Component } from 'react';
import * as API from './api.js';
import './style.css';
import Help from "./sidebaricon/Help.png";
import Settings from './sidebaricon/Settings.png';
import { Link } from 'react-router-dom';
import SideBar from './SideBar';
import SideBarComponent from './ReusableComponents/SideBarComponent.js';

export default class F0F extends Component {

  state = {
    user: {},
    type: "",
    services: [],
    sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk")
  }
  parentSidebarSwitch = () => {
    this.setState((state) => ({
      ...state,
      sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
    }))
  }

  componentWillMount() {
    document.title = "404 Page could not be found - AeroGCS";
    if (this.props.location.state) {
      let { user, type } = this.props.location.state;
      this.setState((state) => ({
        ...state,
        user,
        type
      }))
    } else {
      API.getUserType().then(([blah, type, user]) => {
        console.log("ROLE ", type);
        console.log("USER ", user)
        this.setState((state) => ({
          ...state,
          user,
          type
        }))
      }, (e) => {
        window.location.replace("/login");
      })
    }

  }

  render() {
    let { user, type, services } = this.state
    let sidebarConfigurations = {
      options: [

        {
          icon: Help,
          text: "Help",
          path: "/feedback"
        },
        {
          icon: Settings,
          text: "Settings",
          path: "/profile-settings"
        }
      ],
      user,
      type,
      mode: this.state.sidebarMode  // or expanded
    }
    return (
      user.username ? <div className="wrapper">
        <div className="right-content-new" style={{ left: '0', width: '100%' }}>
          <div className="top-bar-new"><div className="top-bar-text-new">404</div></div>
          <div className="main-content-new">
            <div className="page" style={{ paddingTop: "132px" }}>
              <div style={{ width: "100%", textAlign: 'center', fontSize: '100px', fontWeight: 'bold' }}>404</div>
              <div className="success-message1" style={{ textAlign: "center" }}>{"Sorry, that page does not exist"}</div>
              <div style={{ margin: "30px auto 0 auto", width: "fit-content", height: "0" }}>
                <Link to={{ pathname: "/", state: { user, type, sidebarMode: this.state.sidebarMode } }}>
                  <div className="addcustomer-button-empty-dashboard" >Back To Safety</div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div> : <div />
    );
  }
}
