import React, { Component } from "react";
import * as API from "../api.js";
import { Link, StaticRouter, Redirect } from "react-router-dom";
import aeromeghLogo from './assets/aeromeghLogo.png'
import emailIcon from './assets/email.svg'

class ForgotPassword extends Component {
    state = {
        email: "",
        emailSent: false,
        error: null,
        loadingData: false
    }

    componentWillMount() {
        document.title = "AeroGCS - Forgot Password";
    }

    resetPassword = (e) => {
        e.preventDefault()
        this.setState({ loadingData: true })
        API.resetPassoword(this.state.email).then(() => {
            this.setState({ emailSent: true, loadingData: false })
        }).catch((e) => {
            e.then(error => {
                this.setState({ loadingData: false, error: error.message || "Something went wrong..." })
            })
        })
    }

    render() {
        return (
            <div className="authPage">
                <div className="EnterpriseLogoName">
                    <img src={aeromeghLogo} className="EnterpriseLogoNameImg" />
                    <div>AeroGCS<span className="EnterpriseLogoNameSpan"> Enterprise</span></div>
                </div>
                {this.state.emailSent ? <div className="cardLogin login-card">
                    <div className="loginCardHeader">Email Sent !</div>
                    <div className="contentText">Follow the instructions sent to <span style={{ fontWeight: '700' }}>{this.state.email}</span> to recover your password</div>
                    <Link to={{ pathname: "/login", }}><button className="loginUserButton">LOGIN</button></Link>
                </div>
                    : <form className="cardLogin login-card" onSubmit={this.resetPassword}>
                        <div className="loginCardHeader">Forgot Password !</div>
                        <div className="contentText">Get instructions sent to this email that explain how to reset your password</div>
                        <div className="login-error1">{this.state.error || ''}</div>
                        <div className="add-user-form-row1" style={{ marginBottom: '15px' }}>
                            <img className="inputLeftIcon" src={emailIcon} />
                            <input className="loginSignupInput" autoFocus id="username" name="username" type="email" placeholder="E-mail"
                                onChange={(e) => {
                                    this.setState({
                                        error: null,
                                        email: e.target.value.toLowerCase()
                                    })
                                }} />
                        </div>
                        <button disabled={this.state.error || !this.state.email || this.state.loadingData}
                            className={this.state.error || !this.state.email || this.state.loadingData ? "loginUserButtonDisable" : "loginUserButton"}
                            name="loginButton" id="loginButton">Send</button>
                    </form>}
            </div>
        );
    }
}

export default ForgotPassword
