import React, { useState } from "react";
import Arrow from "../../../assets/Blue-arrow-right.svg"
import Calender from "../../../assets/Calender.svg"
import DatePicker from "react-datepicker";
import dateFormat from 'dateformat';
import "react-datepicker/dist/react-datepicker.css";
import './FilterFlights.css'
import { ActivityPopup } from "../../../ReusableComponents/ActivityPopup";


const DateTimePicker = ({
    setOpenDatepicker,
    fromDateTime,
    toDateTime,
    formdatePicker,
    todatePicker,
    handleFilterChange,
    isDisabled,
}) => {
    const [dateError, setError] = useState({
        error: false,
        message: "",
    });

    const isSameDate = (date1, date2) => {
        return new Date(date1)?.getDate() === new Date(date2)?.getDate() &&
            new Date(date1)?.getMonth() === new Date(date2)?.getMonth() &&
            new Date(date1)?.getFullYear() === new Date(date2)?.getFullYear();
    }



    const handleFromDate = (fromDate) => {

        if (isSameDate(fromDate, toDateTime)) {
            if (new Date(fromDate).getTime() > new Date(toDateTime).getTime()) {
                setError((prev) => ({
                    ...prev,
                    error: true,
                    message: "From time can not be greater than to date time !"
                }))
                return;
            }
        }

        if (isSameDate(new Date(), fromDate)) {
            if (new Date(fromDate).getTime() > new Date().getTime()) {
                setError((prev) => ({
                    ...prev,
                    error: true,
                    message: "From time can not be greater than to current time !"
                }))
                return;
            }
        }

        // from date can not greater than today date
        if (new Date(fromDate) > new Date()) {
            setError((prev) => ({
                ...prev,
                error: true,
                message: "From date can not be greater than today date!"
            }))
            return;
        }
        // from date can not greater than To Date 
        if (new Date(fromDate) >= new Date(toDateTime)) {
            setError((prev) => ({
                ...prev,
                error: true,
                message: "From date can not be greater than To Date !"
            }))
            return;
        }

        handleFilterChange("fromDateTime", new Date(fromDate));
    }


    const handleToDate = (toDate) => {
        if (isSameDate(new Date(), new Date(toDate))) {
            if (new Date(toDate).getTime() < new Date(fromDateTime).getTime()) {
                setError((prev) => ({
                    ...prev,
                    error: true,
                    message: "to date time can not be less than from date time"
                }))
                return;
            }
            handleFilterChange("toDateTime", toDate);

        }
        if (new Date(toDate).setHours(0, 0, 0, 1) > new Date().setHours(0, 0, 0, 1)) {
            setError((prev) => ({
                ...prev,
                error: true,
                message: "You cannot select the date beyond today's date"
            }))
            return;
        }
        if (new Date(toDate) < new Date(fromDateTime)) {
            setError((prev) => ({
                ...prev,
                error: true,
                message: "To date can not be less than from Date !"
            }))
            return;
        }


        if (toDateTime === undefined) {
            if (!new Date(toDate).getHours()) {
                toDate = new Date(toDate).setHours(23, 59, 59, 999);
                handleFilterChange("toDateTime", new Date(toDate));

            }
            else {
                const selectedTime = new Date(toDate);
                handleFilterChange("toDateTime", new Date(selectedTime));
            }
        }
        handleFilterChange("toDateTime", new Date(toDate));
    }

    return (

        <div className="dates__wrapper" style={isDisabled ? { cursor: 'not-allowed', opacity: '0.5' } : { cursor: 'auto', opacity: '1' }}>
            <ActivityPopup
                open={dateError.error}
                msg={dateError.message}
                close={() => {
                    setError((prev) => ({
                        ...prev,
                        error: false,
                        message: "",
                    }))
                }}
            />
            <div style={isDisabled ? { cursor: 'not-allowed', opacity: '0.5' } : { cursor: 'auto', opacity: '1' }}
                className="single__date"
                onClick={() => {
                    if (!isDisabled) {
                        setOpenDatepicker((prev) => ({ ...prev, fromdatePicker: true }))
                    }
                }}
            >
                <div style={{}} >
                    <img src={Calender} />
                </div>
                {
                    fromDateTime !== undefined ?
                        <div className="date_time_wrapper">
                            <span>
                                {dateFormat(fromDateTime, "dd/mm/yyyy")}
                            </span>
                            <span>
                                {dateFormat(fromDateTime, "hh:MM TT")}
                            </span>
                        </div>
                        :
                        <div className="undefined_date_wrapper">
                            <span className="undefined_date">
                                --/--/----
                            </span>
                            <span className="undefined_time">
                                --:--
                            </span>
                        </div>
                }

            </div>
            <div className="arrow__icon" style={isDisabled ? { cursor: 'not-allowed', opacity: '0.5' } : { cursor: 'auto', opacity: '1' }}>
                <img src={Arrow} />
            </div>
            <div style={isDisabled ? { cursor: 'not-allowed', opacity: '0.5' } : { cursor: 'auto', opacity: '1' }}
                className="single__date"
                onClick={() => {
                    if (!isDisabled) {
                        setOpenDatepicker((prev) => ({ ...prev, todatePicker: true }))
                    }
                }}
            >
                <div style={{}}>
                    <img src={Calender} />
                </div>
                {
                    toDateTime !== undefined ?
                        <div className="date_time_wrapper">
                            <span>
                                {dateFormat(toDateTime, "dd/mm/yyyy")}
                            </span>
                            <span>
                                {dateFormat(toDateTime, "hh:MM TT")}
                            </span>
                        </div>
                        :
                        <div className="undefined_date_wrapper">
                            <span className="undefined_date">
                                --/--/----
                            </span>
                            <span className="undefined_time">
                                --:--
                            </span>
                        </div>
                }
            </div>
            <div className="react-datepicker-wrapper-custom-1 ">
                <DatePicker
                    showTimeSelect
                    open={formdatePicker}
                    className="hidden"
                    selected={fromDateTime}
                    value={fromDateTime}
                    maxTime={isSameDate(new Date(), new Date(fromDateTime)) ? new Date().getTime() : new Date().setHours(23, 59, 59, 999)}
                    minTime={new Date().setHours(0, 0, 0, 1)}
                    maxDate={new Date()}
                    onChange={(date) => handleFromDate(date)}
                    popperPlacement="bottom"
                    tetherConstraints={[]}
                    timeIntervals={5}
                    popperModifiers={[
                        {
                            name: "flip",
                            options: {
                                fallbackPlacements: ["bottom"],
                                allowedAutoPlacements: ["bottom"],
                            },
                        },
                    ]}
                    onClickOutside={() =>
                        setOpenDatepicker((prev) => ({
                            ...prev,
                            fromdatePicker: false,
                            todatePicker: false,
                        }))
                    }
                    placeholderText="From Date"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                />
            </div>
            <div className="react-datepicker-wrapper-custom-2">
                <DatePicker
                    open={todatePicker}
                    showTimeSelect
                    className="hidden"
                    selected={toDateTime}
                    maxDate={new Date()}
                    maxTime={isSameDate(new Date(), new Date(toDateTime)) ? new Date().getTime() : new Date().setHours(23, 59, 59, 999)}
                    minTime={new Date().setHours(0, 0, 0, 1)}
                    value={toDateTime}
                    onChange={(date) => handleToDate(date)}
                    popperPlacement="bottom"
                    tetherConstraints={[]}
                    timeIntervals={5}
                    popperModifiers={[
                        {
                            name: "flip",
                            options: {
                                fallbackPlacements: ["bottom"],
                                allowedAutoPlacements: ["bottom"],
                            },
                        },
                    ]}
                    onClickOutside={() =>
                        setOpenDatepicker((prev) => ({
                            ...prev,
                            fromdatePicker: false,
                            todatePicker: false,
                        }))
                    }
                    placeholderText="To Date"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                />
            </div>
        </div>
    )
}


export default DateTimePicker;