import React, { Component } from "react";
import { Link, } from "react-router-dom";
import aeromeghLogo from './assets/aeromeghLogo.png'

class ResetPasswordSuccessful extends Component {
  state = {
  }

  componentWillMount() {
    document.title = "AeroGCS-Reset Password";
  }

  render() {
    return (
      <div className="authPage">
        <div className="EnterpriseLogoName">
          <img src={aeromeghLogo} className="EnterpriseLogoNameImg" />
          <div>AeroGCS<span className="EnterpriseLogoNameSpan"> Enterprise</span></div>
        </div>
        <div className="cardLogin login-card">
          <div className="loginCardHeader">Password Changed Successfully !</div>
          <div className="contentText">A new password has been sent to your email address. check your email and login with new password.</div>
          <Link to={{ pathname: "/login" }}><button className="loginUserButton">LOGIN</button></Link>
        </div>
      </div>
    );
  }
}

export default ResetPasswordSuccessful
