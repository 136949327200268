import React, { Component } from "react";
import { addProjectDetails } from "./redux";
import * as API from "./api.js";
import "./style.css";
import service from "./sidebaricon/Services.png";
import plan from './sidebaricon/Plan.png'
import AeroGCSInactive from './sidebaricon/AeroGCSInactive.png';
import RPAS from './rpas.png';
import DronesInactive from './drones.png';
import SideBar from "./SideBar";
import { connect } from "react-redux";
import { Link, StaticRouter, Redirect } from "react-router-dom";
import Geocode from "react-geocode";
import * as htmlToImage from 'html-to-image'
import Loader from './icons/loader.svg';
import SingleMap from "./ReusableComponents/map"
import { ActivityPopup } from './ReusableComponents/ActivityPopup.js';
import "leaflet/dist/leaflet.css";
import L, { layerGroup, marker, polygon } from "leaflet";
import "leaflet-editable"
import { v4 as uuid } from 'uuid';
import compass from './icons/compass.png'
import compassArrow from './icons/compassArrow.png'
import spacingImg from './icons/spacingImg.png'
import pluseIcon from './icons/PlusIcon.png'
import minusIcon from './icons/Minus.png'
import indentationImg from './icons/indentationImg.png'
import obstacleImg from './icons/obstacleImg.png'
import { SelectComponent } from './ReusableComponents/SelectComponent'
import wayptBack from './icons/wayptback.png'
import wayptDown from './icons/wayptdown.png'
import wayptLeft from './icons/wayptleft.png'
import wayptRev from './icons/wayptrev.png'
import wayptRight from './icons/wayptright.png'
import wayptUp from './icons/wayptup.png'
import downArrow from './icons/DownIcon.png'
import nextBtn from './icons/scrollRight2.png'
import prevBtn from './icons/scrollLeft2.png'
import nextBtn1 from './icons/scrollRight1.png'
import prevBtn1 from './icons/scrollLeft1.png'
import * as turf from '@turf/turf'
import {
    GoogleApiWrapper,
    LoadingContainer,
} from "google-maps-react";
import {
    onUpdateLayer,
    onRightClick,
} from './ReusableComponents/map/leafletMeasure.js';
import { addPlan } from "./redux";
import getArrows from './ReusableComponents/ArrowOnPolyline'
import roadMapView from './assets/Images/roadMapViewzz.png'
import sateliteView from './assets/Images/sateliteView.png'
import { twoPolyLineIntersect, polygonPolyLineIntersect, addUserAnalytic, updateOutTime } from './ReusableComponents/reusableFunctions.js'
import AppConstants, { MAP_URLS } from "./AppConstants";

Geocode.setApiKey("AIzaSyBuxfUtRZSEUT2QrbZA8zUsG58lZUYYmwI");
Geocode.setLanguage("en");

const EARTH_RADIUS = 6371.0 / 0.001;
var d2r = Math.PI / 180; // degrees to radians
var earthsradius = 3963; // 3963 is the radius of the earth in miles

class RMPSprayinhFlightPlan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            user: {},
            map: {},
            center: { lat: 20.008482, lng: 73.756834 },
            altitude: 5,
            speed: 0,
            drawPlan: '',
            speedd: "",
            type: "",
            flightType: "",
            sidebarMode: this.props.location.state
                ? this.props.location.state.sidebarMode
                : "shrunk",
            redirect: false,
            creatingPlan: false,
            Distance: '',
            sprayingSpeed: 2,
            pumpRate: 10,
            spacing: 3,
            sprayingPoint: 0,
            sprayingTrigger: 2,
            turnAroundAngle: 0,
            turnAroundDist: 0,
            sprayingArea: 0,
            sprayingLength: 0,
            polygon: {},
            fencePolygon: {},
            imagenaryPolygon: {},
            fenceCircle: {},
            polygonWidth: 0,
            polygonHeight: 0,
            reversePointPlot: false,
            itemName: "",
            showProcessPopup: false,
            processPopupIcon: "",
            processAction: "",
            processMessage: "",
            deleteItem: "",
            latLongList: [],
            latLongList1: [],
            DGCAfenceList: [],
            toggle: false,
            cameraIndex: 0,
            planName: '',
            projectName: '',
            editPlan: false,
            sprayingType: false,
            sprayingTime: 0,
            layer: {},
            bounds: [],
            idx: 0,
            mapLoading: true,
            RGBShow: true,
            band1: "1",
            band2: "2",
            band3: "3",
            processTileIndex: "NDVI",
            processTileMin: -0.283,
            processTileMax: 0.181,
            processTileType: "RGN",
            baseLayer: '',
            activeBaseLayer: 'satelite',
            selectedLayer: 'Orthomosaic',
            layerSelectionPopup: false,
            selectedLayerType: 'orthomosaic',
            planLine: null,
            intersectReversePointPlot: false,
            selectedPolygon: undefined,
            obstacleDraw: false,
            obsCircleDraw: false,
            obsPolygons: [],
            offSetPolygon: {},
            offSetObsPolygons: [],
            spaceAroundObstacle: 0,
            obstaclePolygons: [],
            boundryIndentation: 0,
            selectedParameter: 'parameter',
            fenceBoundary: 1,
            sprayAmount: 20,
            nextPrevBtn: true,
            selectedItem: [{ value: "parameter", label: "Parameter" }],
            itemsList: [{ value: "parameter", label: "Parameter" }, { value: "waypoint", label: "Waypoint" }, { value: "spacing", label: "Spacing" }, { value: "indentation", label: "Indentation" }, { value: "obstacleBoundary", label: "Obstacle Boundary" }, { value: "routeAdjust", label: "Route Adjust" }],
            selectedWayPoint: '',
            selectedPointindex: '',
            selectedPointLastLocation: '',
            indentationArray: [],
            indentationIndex: 0,
            obsCircle: [],
            offSetObsCircle: [],
        };
    }

    surveyWayPoints = []
    outerPolgonPoint = []
    pointsOnIntersectline = []
    visited = []
    unvisited = []


    isObInsidePlan = (coordinates) => {
        return new Promise(async (resolve, reject) => {
            let polygonOffset = await this.getPolygonOffset1(this.state.polygon)
            const latLngs = polygonOffset.getLatLngs()[0]
            const plotPolygon = turf.polygon([[...(latLngs.map(latLng => [latLng.lat, latLng.lng])), [latLngs[0].lat, latLngs[0].lng]]]);
            const points = turf.points(coordinates.map(latLng => [latLng.lat, latLng.lng]));
            if ((turf.pointsWithinPolygon(points, plotPolygon)).features.length === points.features.length) {
                resolve(true)
            } else {
                this.setState((state) => ({
                    ...state,
                    itemName: "Plan",
                    processPopupIcon: "ERROR",
                    processAction: "ADD",
                    processMessage: 'you can not draw obstacle outside plan.',
                    showProcessPopup: true,
                }))
                resolve(false)
            }
        })
    }

    checkPolygonObstaclesInsidePlot = () => {
        return new Promise(async (resolve, reject) => {
            let obsData = 0
            for (let i = 0; i < this.state.obsPolygons.length; i++) {
                let obs = await this.isObInsidePlan(this.state.obsPolygons[i].coordinates)
                obsData = obs == true ? obsData : obsData + 1
            }
            resolve(obsData > 0 ? false : true)
        })
    }

    checkCircleObstaclesInsidePlot = () => {
        return new Promise(async (resolve, reject) => {
            let obsData = 0
            for (let i = 0; i < this.state.obsCircle.length; i++) {
                let circlePoygon = await this.getLatLngsOfCircle(this.state.obsCircle[i].coordinates, this.state.obsCircle[i].radius, 36)
                let obs = await this.isObInsidePlan(circlePoygon)
                obsData = obs == true ? obsData : obsData + 1
            }
            resolve(obsData > 0 ? false : true)
        })
    }

    onChangeEvent = (e) => {
        console.log(e);
        let value = e[0].value
        this.setState({
            selectedItem: e,
            selectedParameter: value,
            obsCircleDraw: false,
            obstacleDraw: false,
        }, () => {
            this.disableEdit()
            if (value == "indentation") {
                this.drawLineForIndentation()
            } else {
                if (this.state.indetationLineLayer) this.state.indetationLineLayer.remove()
            }
            if (this.state.selectedWayPoint) {
                this.state.selectedWayPoint.remove()
                this.setState({ selectedWayPoint: '' })
            }
        })
    }

    setBaseLayerToState = (baseLayer) => {
        this.setState({ baseLayer })
    }

    updateHistogram = async () => {
        return new Promise((resolve, reject) => {
            let taskId = this.state.linked_map
            let { processTileIndex, processTileType, processTileMin, processTileMax, user } = this.state
            let sas = encodeURIComponent(this.state.user.sas_token)
            let blobContainer = user.blob_container
            let algorithm = processTileIndex
            API.getHistogram({ taskId, algorithm, processTileType: processTileType ? processTileType : "RGB", processTileMin, processTileMax, sas, blobContainer }).then((data) => {
                this.setState((state) => ({
                    ...state,
                    processTileMin: data.statistics[1].percentiles[0].toFixed(4),
                    processTileMax: data.statistics[1].percentiles[1].toFixed(4),
                    processTileType: processTileType ? this.state.processTileType : data.algorithms[0].filters[0]
                }), () => {
                    this.overlayTilesLayerOf("plantHealth")
                })
            })
        })
    }

    updatePlanStyle = (type) => {
        //-------- Change polyline, arrows, polygon and marker color according to layer --------
        if (this.state.planLine)
            this.state.planLine.setStyle({ color: type == 'orthomosaic' || type == 'base' ? 'orange' : '#2989cf' })
        if (this.state.toggle && this.state.fencePolygon)
            this.state.fencePolygon.setStyle({ color: type == 'orthomosaic' || type == 'base' ? 'green' : 'white' })
        if (this.state.arrowArray && this.state.arrowMarkerLayer) {
            this.state.arrowMarkerLayer.remove()
            let arrows = getArrows(this.state.arrowArray, this.state.selectedLayer == 'Orthomosaic' || this.state.selectedLayer == 'Base' ? 'orange' : '#2989cf', 1, this.state.map)
            let arrowMarkerLayer = L.featureGroup(arrows).addTo(this.state.map)
            this.setState((state) => ({
                ...state,
                arrowMarkerLayer: arrowMarkerLayer,
            }))
        }
    }

    changeSelectedLayer = (layer) => {
        if (layer == 'NDVI') {
            this.setState((state) => ({
                selectedLayer: layer,
                layerSelectionPopup: false,
                processTileIndex: "NDVI",
                processTileType: "RGN",
                selectedLayerType: 'plantHealth'
            }), () => {
                this.updateHistogram()
            })
        } else if (layer == 'VARI') {
            this.setState((state) => ({
                selectedLayer: layer,
                layerSelectionPopup: false,
                processTileIndex: "VARI",
                processTileType: "RGB",
                selectedLayerType: 'plantHealth'
            }), () => {
                this.updateHistogram()
            })
        } else if (layer == 'Orthomosaic') {
            this.setState({
                selectedLayer: layer,
                layerSelectionPopup: false,
                selectedLayerType: 'orthomosaic'
            })
            this.overlayTilesLayerOf("orthomosaic")
        } else {
            this.setState({
                selectedLayer: layer,
                layerSelectionPopup: false,
                selectedLayerType: 'base'
            })
            this.updatePlanStyle('base')
            this.removeLayer('orthoLayer')
        }
    }

    // To change base layer of map
    switchBaseLayer = () => {
        const { map1 } = this.state
        this.setState({ activeBaseLayer: this.state.activeBaseLayer == "satelite" ? "terrain" : "satelite" }, () => {
            this.state.baseLayer.setUrl(this.state.activeBaseLayer === "satelite" ? MAP_URLS.SATELITE : MAP_URLS.ROAD_MAP)
        })
    }

    // save plan function starts here
    convertToWaypoints = () => {
        var latLongList = {
            waypoints:
                this.surveyWayPoints.map((location, key) => {
                    return ({
                        alt: Number(this.state.altitude),
                        coordType: (key % 2) == 0 ? 1 : 0,
                        "lat": location.lat,
                        "lon": location.lng,
                        speed: Number(this.state.speed),
                    })
                })
        }
        this.setState((state) => ({
            ...state,
            latLongList: latLongList
        }))
        return latLongList;
    }

    convertToFencePoint = () => {
        let polyPoints = this.state.polygon.leafletLayer.toGeoJSON().geometry.coordinates[0]
        var latLongList1 = {
            geofence:
                polyPoints.map((location, key) => {
                    return ({
                        "lat": location[1],
                        "lon": location[0],
                        "alt": Number(this.state.altitude),
                    })
                })
        }
        this.setState((state) => ({
            ...state,
            latLongList1: latLongList1
        }))
        return latLongList1;
    }

    DGCAfence = () => {
        let polyPoints = this.state.fencePolygon.toGeoJSON().geometry.coordinates[0]
        var DGCAfenceList = {
            geofence:
                polyPoints.map((location, key) => {
                    return ({
                        "lat": location[1],
                        "lon": location[0],
                        "alt": Number(this.state.altitude),
                    })
                })
        }
        this.setState((state) => ({
            ...state,
            DGCAfenceList: DGCAfenceList
        }))
        return DGCAfenceList;
    }

    getStaticstics = () => {
        let stat = {
            "missionStatistics": [
                {
                    "crossGridEnable": 0,
                    "headingLockStatus": false,
                    "imageFootprintHeight": Number(this.state.sprayingTrigger),
                    "imageFootprintWidth": parseFloat(this.state.spacing),
                    "landAltLatitue": Number(this.surveyWayPoints[this.surveyWayPoints.length - 1].lat),
                    "landAltLongitude": Number(this.surveyWayPoints[this.surveyWayPoints.length - 1].lng),
                    "landAltitude": 0,
                    "landLatitue": 0,
                    "landLongitude": 0,
                    "missionDefaultAltitude": Number(this.state.altitude),
                    "missionDefaultSpeed": Number(this.state.speed),
                    "missionHomeAltitude": 0,
                    "missionHomeLatitude": Number(this.surveyWayPoints[0].lat),
                    "missionHomeLongitude": Number(this.surveyWayPoints[0].lng),
                    "missionType": 2,
                    "optimizeSpraying": this.state.sprayingType,
                    "planeMaxSpeed": Number(this.state.speed),
                    "planeMinSpeed": Number(this.state.speed),
                    "speed": Number(this.state.speed),
                    "sprayLockHeading": 0,
                    "sprayingAltitude": Number(this.state.altitude),
                    "sprayingArea": Number(this.state.sprayingArea),
                    "sprayingPumpRate": Number(this.state.pumpRate),
                    "sprayingSpacing": parseFloat(this.state.spacing),
                    "sprayingSpeed": Number(this.state.sprayingSpeed),
                    "sprayingSpot": Number(this.state.sprayingPoint),
                    "sprayingTrigger": Number(this.state.sprayingTrigger),
                    "sprayingType": 0,
                    "surveyCamTriggerDist": Number(this.state.sprayingTrigger),
                    "surveyCamTriggerFlag": 1,
                    "surveyCoordCount": Number(this.surveyWayPoints.length),
                    "totalPlanDistance": Number(this.state.sprayingLength),
                    "takeOffAltitude": Number(this.state.altitude),
                    "takeOffLatitue": Number(this.surveyWayPoints[0].lat),
                    "takeOffLongitude": Number(this.surveyWayPoints[0].lng),
                    "totalPlanDistance": 0,
                    "turnaroundAngle": Number(this.state.turnAroundAngle),
                    "turnaroundDistance": Number(this.state.turnAroundDist),
                    "vehicleType": "QuadCopter",
                    "verticalMission": 0,
                    "verticalRularScaleMaxValue": 0,
                    "wpRadius": 0
                }
            ]
        }
        return (stat)
    }

    validateWaypoints = async (latLongList) => {
        let counter = 0;
        for (let j = 0; j < latLongList.waypoints.length; j++) {
            let point = { lat: parseFloat(latLongList.waypoints[j].lat), lng: parseFloat(latLongList.waypoints[j].lon) }
            let temp = await this.isMarkerInsidePolygon(point, this.state.fencePolygon)
            if (!temp) {
                counter++;
            }
        }
        return counter;
    }


    getflightSrc = async () => {
        let lat = this.state.latLongList.waypoints[0].lat;
        let lng = this.state.latLongList.waypoints[0].lon;

        let add = await Geocode.fromLatLng(lat, lng).then(
            (response) => {
                const address = response.results[0].address_components[0].short_name;
                return address;
            },
            (error) => {
                console.error(error);
            }
        );
        return add;
    }

    getFlightDest = async () => {
        let length = this.state.latLongList.waypoints.length;
        let lat1 = this.state.latLongList.waypoints[length - 1].lat;
        let lng1 = this.state.latLongList.waypoints[length - 1].lon;
        let add = await Geocode.fromLatLng(lat1, lng1).then(
            (response) => {
                const address = response.results[0].address_components[0].short_name;
                return address;
            },
            (error) => {
                console.error(error);
            }
        );
        return add;
    }

    resizedataURL = (datas, wantedWidth, wantedHeight) => {
        return new Promise(async function (resolve, reject) {
            var img = document.createElement("img");
            img.onload = function () {
                var canvas = document.createElement("canvas");
                var ctx = canvas.getContext("2d");
                canvas.width = wantedWidth;
                canvas.height = wantedHeight;
                ctx.drawImage(this, 0, 0, wantedWidth, wantedHeight);
                var dataURI = canvas.toDataURL();
                resolve(dataURI);
            };
            img.src = datas;
        });
    }

    getAsset = async () => {
        this.setState((prevState) => ({
            ...prevState,
            creatingPlan: this.state.editPlan ? false : true,
            itemName: "Plan",
            processPopupIcon: "WAIT",
            processAction: this.state.editPlan ? "UPDATE" : "ADD",
            processMessage: null,
            showProcessPopup: true,
        }));
        // take screenshot of plan using html-to-image library
        var newDataUri;
        let a = await htmlToImage
            .toPng(document.getElementById("rmp-map"))
            .then(async (dataUrl) => {
                let newDataUri1;
                newDataUri = await this.resizedataURL(dataUrl, 1080, 720); // reduce the image size
                return newDataUri1;
                // download(dataUrl, 'map.png');
            });
        return newDataUri;
    }

    getObstaclePolygons = () => {
        return new Promise(async (resolve, reject) => {
            let polygons = []
            for (let i = 0; i < this.state.obsPolygons.length; i++) {
                let vertices = this.state.obsPolygons[i].coordinates.map((cords => ({ alt: 0, lat: cords.lat, lon: cords.lng })))
                polygons.push({
                    "indentation": this.state.obsPolygons[i].indentation,
                    "vertices": vertices
                })
            }
            resolve(polygons)
        })
    }

    getObstacleCircles = () => {
        return new Promise(async (resolve, reject) => {
            let circles = []
            for (let i = 0; i < this.state.obsCircle.length; i++) {
                circles.push({
                    "centroid": { lat: this.state.obsCircle[i].coordinates.lat, lon: this.state.obsCircle[i].coordinates.lng },
                    "radius": this.state.obsCircle[i].radius,
                    "area": (3.14159 * this.state.obsCircle[i].radius * this.state.obsCircle[i].radius),
                    "indentation": this.state.obsCircle[i].indentation
                })
            }
            resolve(circles)
        })

    }

    getPlan = async () => {
        let latLongList = await this.convertToWaypoints();
        let latLongList1 = await this.convertToFencePoint();
        let DGCAfenceList = await this.DGCAfence();
        let planStatistics = await this.getStaticstics();
        let obstaclePolygons = {}
        if (this.state.obsPolygons.length > 0) {
            obstaclePolygons['polygons'] = await this.getObstaclePolygons()
        }

        if (this.state.obsCircle.length > 0) {
            obstaclePolygons['circles'] = await this.getObstacleCircles()
        }
        let inside1 = await this.validateWaypoints(latLongList); // call waypoint validation function
        if (inside1 > 0) {
            if (latLongList.length < 0) {
                this.setState((state) => ({
                    ...state,
                    itemName: "Plan",
                    processPopupIcon: "ERROR",
                    processAction: "ADD",
                    processMessage: "Mission is outside geofence area.",
                    showProcessPopup: true,
                }));
            } else {
                this.setState((state) => ({
                    ...state,
                    itemName: "Plan",
                    processPopupIcon: "ERROR",
                    processAction: "ADD",
                    processMessage: "Please select geofence area.",
                    showProcessPopup: true,
                }));
            }
        }
        else {

            let Plan = {};
            let planAddress = {};
            let flightSrc = await this.getflightSrc();
            let flightDest = await this.getFlightDest();
            planAddress.flightDestination = flightDest;
            planAddress.flightSource = flightSrc;
            let myAsset = await this.getAsset();

            let Assets = {
                "planMap.png": myAsset,
            };
            let dt = await new Date();
            let date = dt.getDate().toString().padStart(2, "0");
            let month = (dt.getMonth() + 1).toString().padStart(2, "0");
            let year = dt.getFullYear();
            let hour = dt.getHours();
            let minute = dt.getMinutes();
            let seconds = dt.getSeconds();
            let dateTime = `${year}-${month}-${date} ${hour}:${minute}:${seconds}`;

            let planConfiguration = {
                projectDetails: [
                    {
                        clientName: this.state.clientName,
                        'dateTime': new Date().toISOString(),
                        flightCompletedCount: 0,
                        projectName: this.state.projectName,
                        projectId: this.props.location.state.project.id,
                        planName: this.state.planName,
                        planCompletedStatus: 0,
                        softwareVerison: "1.1",
                        linkedMap: this.state.linked_map
                    }
                ]
            }
            Plan.planConfiguration = await planConfiguration;
            Plan.planAddress = await planAddress;
            Plan.missionStatistics = await planStatistics;
            Plan.DGCAfence = await DGCAfenceList;
            Plan.RallyPointData = [];
            Plan.corridor = [];
            Plan.obstacle = await obstaclePolygons;
            Plan.Mission = await latLongList;
            Plan.Geofence = await latLongList1;
            Plan.Assets = await Assets;
            return Plan;

        }
    }

    createPlan = async (e) => {
        if (this.state.editPlan == false && this.props.location.state.drawPlan == true) {
            this.setState((state) => ({
                ...state,
                editPlan: true
            }));
        }
        let plan = await this.getPlan();
        if (this.state.editPlan == true) {
            API.editPlan(plan, this.props.activeTeamId).then(
                (data) => {
                    if (data.message == "OK") {
                        this.setState((prevState) => ({
                            ...prevState,
                            itemName: "Plan",
                            processPopupIcon: "COMPLETE",
                            processAction: "UPDATE",
                            processMessage: null,
                            showProcessPopup: true,
                            redirect: true,
                        }));
                    } else {
                        this.setState((prevState) => ({
                            ...prevState,
                            itemName: "Plan",
                            processPopupIcon: "ERROR",
                            processAction: "UPDATE",
                            processMessage: data.message,
                            showProcessPopup: true,
                        }));
                    }
                },
                (e) => {
                    this.setState((state) => ({
                        ...state,
                        itemName: "Plan",
                        processPopupIcon: "ERROR",
                        processAction: "UPDATE",
                        processMessage: e,
                        showProcessPopup: true,
                    }));
                }
            );
        } else {
            API.createPlan(plan, this.props.activeTeamId).then(
                async (data) => {
                    this.setState({
                        plan: data.plan
                    })
                    if (data.message == "OK") {
                        this.setState((prevState) => ({
                            ...prevState,
                            itemName: "Plan",
                            processPopupIcon: "COMPLETE",
                            processAction: "ADD",
                            processMessage: null,
                            showProcessPopup: true,
                            redirect: true,

                        }));
                    } else if (data.message == "Plan already exists") {
                        this.setState((prevState) => ({
                            ...prevState,
                            itemName: "Plan",
                            processPopupIcon: "ERROR",
                            processAction: "ADD",
                            processMessage: "Plan name already exist",
                            showProcessPopup: true,
                        }));
                    } else if (data.error) {
                        this.setState((prevState) => ({
                            ...prevState,
                            itemName: "Plan",
                            processPopupIcon: "ERROR",
                            processAction: "ADD",
                            processMessage: data.error,
                            showProcessPopup: true,
                        }));
                    } else {
                        this.setState((prevState) => ({
                            ...prevState,
                            itemName: "Plan",
                            processPopupIcon: "ERROR",
                            processAction: "ADD",
                            processMessage: data.message,
                            showProcessPopup: true,
                        }));
                    }
                },
                (e) => {
                    this.setState((state) => ({
                        ...state,
                        itemName: "Plan",
                        processPopupIcon: "ERROR",
                        processAction: "ADD",
                        processMessage: e,
                        showProcessPopup: true,
                    }));
                }
            );
        }
    }

    viewSet = (map, drawPolygon) => {
        console.log("viewSet called");
        let polygonView = new L.Polygon(drawPolygon.map((latLng => [latLng.lat, latLng.lng])), {
            color: 'red',
            fillOpacity: 0,
            weight: 3,
            zIndex: 1,
        })
        let bounds = polygonView.getBounds()
        map.fitBounds(bounds);
    }

    initMap = () => {
        let { map, center } = this.state
        this.drawPolygon(map, this.state.polygon.coordinates)
        this.viewSet(map, this.state.polygon.coordinates)
        onUpdateLayer(map, this.polygonUpdated)
        this.overlayTilesLayerOf("orthomosaic")
        onRightClick(map, this.getSeletedPoint)
    };

    addOffsetObstaclePolygon = () => {
        if (this.state.obstacleDraw && this.state.selectedPolygon) {
            if (this.state.obsCircleDraw) {
                this.setState((state) => ({
                    obsCircle: this.state.obsCircle.map((obs, i) => {
                        if (obs.id === this.state.selectedPolygon.id) {
                            return {
                                ...obs,
                                indentation: this.state.spaceAroundObstacle
                            }
                        } else {
                            return obs
                        }
                    }),
                }), () => {
                    this.polygonUpdated((this.state.obsCircle.find(obs => obs.id === this.state.selectedPolygon.id).leafletLayer))
                })
            } else {
                this.setState((state) => ({
                    obsPolygons: this.state.obsPolygons.map((obs, i) => {
                        if (obs.id === this.state.selectedPolygon.id) {
                            return {
                                ...obs,
                                indentation: this.state.spaceAroundObstacle
                            }
                        } else {
                            return obs
                        }
                    }),
                }), () => {
                    this.polygonUpdated((this.state.obsPolygons.find(obs => obs.id === this.state.selectedPolygon.id).leafletLayer))
                })
            }
        } else {
            this.setState((state) => ({
                ...state,
                itemName: "Plan",
                processPopupIcon: "ERROR",
                processAction: "ADD",
                processMessage: 'Please select obstacle.',
                showProcessPopup: true,
                spaceAroundObstacle: 0
            }))
        }
    }

    getPolygonOffset = (polygon, offset) => {
        return new Promise(async (resolve, reject) => {
            if (offset == 0) {
                resolve(polygon)
            } else {
                let polyPoint = polygon.getLatLngs()[0].map((latlng => latlng))
                let offsetPolygonPoints = []
                for (let i = 0; i < polyPoint.length; i++) {
                    let currentPoint = polyPoint[i]
                    let nextPoint = polyPoint[i + 1] || polyPoint[0]
                    let prevPoint = polyPoint[i - 1] || polyPoint[polyPoint.length - 1]
                    let nextBearing = this.bearingAngle(nextPoint, currentPoint)
                    let newNextPoint = this.calcDestinationGeocoordFromHeading(currentPoint, 2, nextBearing)
                    let prevBearing = this.bearingAngle(prevPoint, currentPoint)
                    let newPrevPoint = this.calcDestinationGeocoordFromHeading(currentPoint, 2, prevBearing)
                    let distance = L.latLng(newNextPoint.lat, newNextPoint.lng).distanceTo(L.latLng(newPrevPoint.lat, newPrevPoint.lng));
                    let newPointsBearing = this.bearingAngle(newPrevPoint, newNextPoint)
                    let newCenterPoint = this.calcDestinationGeocoordFromHeading(newNextPoint, distance / 2, newPointsBearing)
                    let newBearing = this.bearingAngle(currentPoint, newCenterPoint)
                    let newPoint = this.calcDestinationGeocoordFromHeading(currentPoint, offset, newBearing)
                    let inside = this.isMarkerInsidePolygon(newPoint, polygon)
                    if (inside === true) {
                        let insideBearing = this.bearingAngle(newCenterPoint, currentPoint)
                        let insideNewPoint = this.calcDestinationGeocoordFromHeading(currentPoint, offset, insideBearing)
                        offsetPolygonPoints.push(insideNewPoint)
                    } else {
                        offsetPolygonPoints.push(newPoint)
                    }
                }
                let offsetPolygon = new L.Polygon(offsetPolygonPoints.map((latLng => [latLng.lat, latLng.lng])), {
                    color: 'blue',
                    fillOpacity: 0,
                    weight: 3,
                })
                // .addTo(this.state.markersLayer)
                resolve(offsetPolygon)
            }
        })
    }

    disableEdit = async () => {
        if (this.state.selectedPolygon) {
            this.state.selectedPolygon.disableEdit()
            this.setState({
                selectedPolygon: undefined,
            })
        }
    }

    getTilesBounds = async (taskId) => {
        return new Promise((resolve, reject) => {
            let sas = encodeURIComponent(this.state.user.sas_token)
            let blobContainer = this.state.user.blob_container
            API.getTilesBounds({ taskId, tiffType: "orthomosaic", sas, blobContainer }).then((data) => {
                this.setState({
                    tilesBounds: data.tilesBounds,
                    bounds: data.bounds,
                    //defaultOrtho: true,
                    band1: "1",
                    band2: "2",
                    band3: "3",
                }, () => {
                    resolve(this.state.bounds)
                })
            })
        })
    }

    removeLayer = async (layer) => {
        let removeLayer = this.state[layer]
        this.setState({ [layer]: undefined }, () => {
            if (removeLayer) {
                removeLayer.remove()
            }
        })
    }

    overlayTilesLayerOf = async (type) => {
        let { map } = this.state
        this.removeLayer("orthoLayer").then(async () => {
            if (this.state.linked_map) {
                this.updatePlanStyle(type)
                let taskid = this.state.linked_map
                let { processTileIndex, processTileType, processTileMin, processTileMax, tilesBounds, tileMinZoom, tileMaxZoom } = this.state
                let blobContainer = this.state.user.blob_container;
                let sas = encodeURIComponent(this.state.user.sas_token);
                await this.getTilesBounds(taskid)
                let bounds = [[this.state.bounds[1], this.state.bounds[0]], [this.state.bounds[3], this.state.bounds[2]]]
                let bandsSelection = `${this.state.RGBShow ? `&bands=${this.state.band1},${this.state.band2},${this.state.band3}` : "&viewDefault=true"}`
                if (type == "plantHealth") {
                    let orthoLayer = L.tileLayer(`${AppConstants.tilesServer}/tiles/${taskid}/plantHealth/{z}/{x}/{y}.png?sas=${sas}&blobContainer=${blobContainer}&algorithm=${processTileIndex}&min=${processTileMin}&max=${processTileMax}&filterType=${processTileType}`, {
                        tileSize: 256,
                        maxZoom: 28,
                        bounds: bounds
                    }).addTo(map);
                    this.setState({ orthoLayer })
                } else {
                    let orthoLayer = L.tileLayer(`${AppConstants.tilesServer}/tiles/${taskid}/${type}/{z}/{x}/{y}.png?sas=${sas}&blobContainer=${blobContainer}${bandsSelection}`, {
                        tileSize: 256,
                        maxZoom: 28,
                        bounds: bounds
                    }).addTo(map);
                    orthoLayer.on("load", () => { this.setState({ mapLoading: false }) });
                    this.setState({ orthoLayer })
                }
            }
        })
    }

    drawPolygon = (map, drawPolygon) => {
        let markersLayer = L.featureGroup().addTo(map)
        for (let i = 0; i < drawPolygon.length; i++) {
            this.state.indentationArray[i] = this.state.indentationArray[i] ? this.state.indentationArray[i] : 0
        }
        this.setState((state) => ({
            ...state,
            markersLayer,
        }), async () => {
            let polygonDraw = new L.Polygon(drawPolygon.map((latLng => [latLng.lat, latLng.lng])), {
                color: 'red',
                fillOpacity: 0,
                weight: 3,
                zIndex: 1,
            }).on('click', () => {
                this.disableEdit().then(() => {
                    if (this.state.selectedWayPoint) {
                        this.state.selectedWayPoint.remove()
                        this.setState((state) => ({
                            ...state,
                            selectedWayPoint: '',
                            selectedPointindex: '',
                            selectedPointLastLocation: '',
                        }))
                    }
                    polygonDraw.enableEdit();
                    this.setState({ selectedPolygon: polygonDraw, obstacleDraw: false, obsCircleDraw: false, drawPlan: true })
                })
            })

            let fencePolygon = await this.getPolygonOffset(polygonDraw, Number(this.state.fenceBoundary))
            fencePolygon.addTo(this.state.markersLayer)
            fencePolygon.setStyle({
                color: this.state.selectedLayer == 'Orthomosaic' || this.state.selectedLayer == 'Base' ? 'green' : 'white',
                fillOpacity: 0,
                weight: 3,
            })
            let sprayingArea = L.GeometryUtil.geodesicArea(polygonDraw.getLatLngs()[0])
            let offSetPolygon = await this.getPolygonOffset(polygonDraw, 0)
            polygonDraw.addTo(this.state.markersLayer)
            this.setState((state) => ({
                ...state,
                polygon: {
                    coordinates: polygonDraw.getLatLngs()[0].map((latlng) => { return { lat: latlng.lat, lng: latlng.lng } }),
                    leafletLayer: polygonDraw
                },
                fencePolygon,
                sprayingArea,
                offSetPolygon,
            }), () => {
                this.drawFence()
            })
        })
    }

    polygonUpdated = async (updatedPolygon) => {
        this.surveyWayPoints = []
        this.outerPolgonPoint = []
        this.pointsOnIntersectline = []
        this.visited = []
        this.unvisited = []
        this.state.offSetObsPolygons = []
        if (this.state.markersLayer) this.state.markersLayer.remove();
        if (this.state.arrowMarkerLayer) this.state.arrowMarkerLayer.remove();
        if (this.state.obstacleDraw) {
            if (this.state.obsCircleDraw) {
                let circlePoygon = await this.getLatLngsOfCircle({ lat: updatedPolygon.getLatLng().lat, lng: updatedPolygon.getLatLng().lng }, updatedPolygon.getRadius(), 36)
                if (await this.isObInsidePlan(circlePoygon)) {
                    this.setState((state) => ({
                        ...state,
                        offSetObsPolygons: [],
                        obsCircle: this.state.obsCircle.map((obs) => {
                            if (obs.id == updatedPolygon.id) {
                                return {
                                    ...obs,
                                    coordinates: { lat: updatedPolygon.getLatLng().lat, lng: updatedPolygon.getLatLng().lng },
                                    leafletLayer: updatedPolygon,
                                    radius: updatedPolygon.getRadius(),
                                }
                            } else {
                                return obs
                            }
                        })
                    }), () => {
                        this.drawPolygon(this.state.map, this.state.polygon.leafletLayer.getLatLngs()[0])
                    })
                } else {
                    updatedPolygon.disableEdit()
                    updatedPolygon.setLatLng((this.state.obsCircle.find(obs => obs.id === updatedPolygon.id)).coordinates)
                    updatedPolygon.setRadius((this.state.obsCircle.find(obs => obs.id === updatedPolygon.id)).radius)
                    this.drawPolygon(this.state.map, this.state.polygon.leafletLayer.getLatLngs()[0])
                }
            } else {
                if (await this.isObInsidePlan(updatedPolygon.getLatLngs()[0])) {
                    this.setState((state) => ({
                        ...state,
                        offSetObsPolygons: [],
                        obsPolygons: this.state.obsPolygons.map((obs) => {
                            if (obs.id == updatedPolygon.id) {
                                return {
                                    ...obs,
                                    coordinates: updatedPolygon.getLatLngs()[0].map((latlng) => { return { lat: latlng.lat, lng: latlng.lng } }),
                                    leafletLayer: updatedPolygon
                                }
                            } else {
                                return obs
                            }
                        })
                    }), () => {
                        this.drawPolygon(this.state.map, this.state.polygon.leafletLayer.getLatLngs()[0])
                    })
                } else {
                    this.drawPolygon(this.state.map, this.state.polygon.coordinates)
                }

            }
        } else {
            let polygonInside = await this.checkPolygonObstaclesInsidePlot()
            let circleInside = await this.checkCircleObstaclesInsidePlot()
            if (polygonInside && circleInside) {
                this.drawPolygon(this.state.map, updatedPolygon.getLatLngs()[0])
            } else {
                updatedPolygon.disableEdit()
                if (this.state.selectedParameter === 'indentation') {
                    this.state.indentationArray[this.state.indentationIndex] = this.state.prevBoundryIndentation
                }
                this.drawPolygon(this.state.map, this.state.polygon.coordinates)
            }
        }
    }


    calcDistanceFromCenter = async (center) => {
        let polyPoints = this.state.polygon.coordinates
        let calcDistnce = 0
        var calculatedFence = []
        for (let i = 0; i < polyPoints.length; i++) {
            let distance = center.distanceTo(polyPoints[i])
            if (calcDistnce < distance) { calcDistnce = distance }
            let bearing = await this.bearingAngle(polyPoints[i], center)
            let polygonOffSet = 50
            let fencePoint = await this.calcDestinationGeocoordFromHeading(polyPoints[i], polygonOffSet, bearing)
            calculatedFence.push(fencePoint)
        }
        return ({ calcDistnce: calcDistnce, calculatedFence: calculatedFence })
    }

    boundaryIndentation = () => {
        let polyPoint = this.state.polygon.coordinates
        if (this.state.nextPrevBtn) {
            let indentationIndex = (Number(this.state.indentationIndex) - 1)
            if (indentationIndex < 0) {
                this.setState({ indentationIndex: polyPoint.length - 1 }, this.drawLineForIndentation())
            } else if (indentationIndex > polyPoint.length - 1) {
                this.setState({ indentationIndex: 0 }, this.drawLineForIndentation())
            } else {
                this.setState({ indentationIndex: indentationIndex }, this.drawLineForIndentation())
            }
        } else {
            let indentationIndex = (Number(this.state.indentationIndex) + 1)
            if (indentationIndex < 0) {
                this.setState({ indentationIndex: polyPoint.length - 1 }, this.drawLineForIndentation())
            } else if (indentationIndex > polyPoint.length - 1) {
                this.setState({ indentationIndex: 0 }, this.drawLineForIndentation())
            } else {
                this.setState({ indentationIndex: indentationIndex }, this.drawLineForIndentation())
            }
        }
    }

    drawLineForIndentation = () => {
        if (this.state.indetationLineLayer) this.state.indetationLineLayer.remove()
        let indetationLineLayer = L.featureGroup().addTo(this.state.map)
        this.setState((state) => ({
            ...state,
            indetationLineLayer,
            obsCircleDraw: false,
            obstacleDraw: false,
        }), () => {
            let polyPoint = this.state.polygon.leafletLayer.getLatLngs()[0]
            this.setState({ boundryIndentation: this.state.indentationArray[this.state.indentationIndex] })
            if (this.state.indentationIndex == polyPoint.length - 1) {
                new L.polyline([polyPoint[this.state.indentationIndex], polyPoint[0]], {
                    color: '#2989cf',
                    weight: 5,
                }).addTo(this.state.indetationLineLayer)
                L.circleMarker(polyPoint[this.state.indentationIndex], {
                    color: 'black',
                    fillColor: '#2989CF',
                    fillOpacity: 2,
                    radius: 6,
                    weight: 1,
                }).addTo(this.state.indetationLineLayer)
                L.circleMarker(polyPoint[0], {
                    color: 'black',
                    fillColor: '#2989CF',
                    fillOpacity: 2,
                    radius: 6,
                    weight: 1,
                }).addTo(this.state.indetationLineLayer)
            } else {
                new L.polyline([polyPoint[this.state.indentationIndex], polyPoint[Number(this.state.indentationIndex) + 1]], {
                    color: '#2989cf',
                    weight: 5,
                }).addTo(this.state.indetationLineLayer)
                L.circleMarker(polyPoint[this.state.indentationIndex], {
                    color: 'black',
                    fillColor: '#2989CF',
                    fillOpacity: 2,
                    radius: 6,
                    weight: 1,
                }).addTo(this.state.indetationLineLayer)
                L.circleMarker(polyPoint[Number(this.state.indentationIndex) + 1], {
                    color: 'black',
                    fillColor: '#2989CF',
                    fillOpacity: 2,
                    radius: 6,
                    weight: 1,
                }).addTo(this.state.indetationLineLayer)
            }
        })
    }

    addIndetationForSelectedLine = () => {
        this.state.indentationArray[this.state.indentationIndex] = this.state.boundryIndentation
        this.polygonUpdated(this.state.polygon.leafletLayer)
    }

    getPolygonOffset1 = (polygon) => {
        return new Promise(async (resolve, reject) => {
            let polyPoint = polygon.leafletLayer.getLatLngs()[0].map((latlng => latlng))
            for (let i = 0; i < polyPoint.length; i++) {
                let indentation = Number(this.state.indentationArray[i] || 0)
                let lineFirstPoint = polyPoint[i]
                let lineSecondPoint = polyPoint[i + 1] || polyPoint[0]

                let linePrevPoint = polyPoint[i - 1] || polyPoint[polyPoint.length - 1]
                let lineNextPoint = (i == polyPoint.length - 2) ? polyPoint[0] : (i == polyPoint.length - 1) ? polyPoint[1] : polyPoint[i + 2]

                let lineFirstSecondBearing = this.bearingAngle(lineSecondPoint, lineFirstPoint)
                let lineSecondFirstBearing = this.bearingAngle(lineFirstPoint, lineSecondPoint)

                let firstPrevBearing = this.bearingAngle(linePrevPoint, lineFirstPoint)
                let secondNextBearing = this.bearingAngle(lineNextPoint, lineSecondPoint)

                let ffPoint = this.calcDestinationGeocoordFromHeading(lineFirstPoint, 2, firstPrevBearing)
                let fsPoint = this.calcDestinationGeocoordFromHeading(lineFirstPoint, 2, lineFirstSecondBearing)

                let sfPoint = this.calcDestinationGeocoordFromHeading(lineSecondPoint, 2, secondNextBearing)
                let ssPoint = this.calcDestinationGeocoordFromHeading(lineSecondPoint, 2, lineSecondFirstBearing)

                let lineFirstDist = L.latLng(ffPoint).distanceTo(L.latLng(fsPoint))
                let lineSecondDist = L.latLng(sfPoint).distanceTo(L.latLng(ssPoint))

                let lineFirstBearing = this.bearingAngle(fsPoint, ffPoint)
                let lineSecondBearing = this.bearingAngle(ssPoint, sfPoint)

                let fDistPoint = this.calcDestinationGeocoordFromHeading(ffPoint, lineFirstDist / 2, lineFirstBearing)
                let sDistPoint = this.calcDestinationGeocoordFromHeading(sfPoint, lineSecondDist / 2, lineSecondBearing)

                let fDistPointInside = this.isMarkerInsidePolygon(fDistPoint, L.polygon(polyPoint))
                let sDistPointInside = this.isMarkerInsidePolygon(sDistPoint, L.polygon(polyPoint))

                let fristNewPoint = this.calcDestinationGeocoordFromHeading(lineFirstPoint, indentation, fDistPointInside ? firstPrevBearing : firstPrevBearing + 180)
                let secondNewPoint = this.calcDestinationGeocoordFromHeading(lineSecondPoint, indentation, sDistPointInside ? secondNextBearing : secondNextBearing + 180)

                polyPoint[i] = fristNewPoint
                let val = (i == polyPoint.length - 1) ? 0 : (i + 1)
                polyPoint[val] = secondNewPoint
            }

            let offsetPolygon = new L.Polygon(polyPoint, {
                color: 'blue',
                fillOpacity: 0,
                weight: 3,
            })
            resolve(offsetPolygon)
        })
    }

    drawFence = async () => {
        let center = this.state.polygon.leafletLayer.getBounds().getCenter()
        let polygonOffset = await this.getPolygonOffset1(this.state.polygon)
        var imagenaryPolygon = []
        let calcFun = await this.calcDistanceFromCenter(center)
        let calcCircleDistnce = calcFun.calcDistnce
        let fenceCircle = L.circle(center, calcCircleDistnce, {
            color: 'blue',
            fillOpacity: 0,
            weight: 0,
            zIndex: 0,
        }).addTo(this.state.markersLayer)
        let polyPoints = [fenceCircle.getBounds().getSouthWest(), fenceCircle.getBounds().getNorthWest(), fenceCircle.getBounds().getNorthEast(), fenceCircle.getBounds().getSouthEast()]
        fenceCircle.remove()
        for (let i = 0; i < polyPoints.length; i++) {
            let bearing = await this.bearingAngle(polyPoints[i], center)
            let distance = center.distanceTo(polyPoints[i])
            let cords = await this.calcDestinationGeocoordFromHeading(center, distance + 50, bearing + Number(this.state.turnAroundAngle))
            imagenaryPolygon.push(cords)
        }

        let polygonFence = new L.Polygon(imagenaryPolygon, {
            color: 'orange',
            fillOpacity: 0,
            weight: 3,
        })
        this.setState((state) => ({
            ...state,
            imagenaryPolygon: polygonFence,
            fenceCircle: fenceCircle,
            offSetPolygon: polygonOffset,
        }), () => {
            this.getPolygonHeightWidth(polygonFence)
            this.wayPoints()
        })
    }

    bearingAngle = (startingPoint, endingPoint) => {
        let PI = 3.14159
        let center_to_start = Math.atan2(startingPoint.lng - endingPoint.lng, startingPoint.lat - endingPoint.lat);
        let bearing = (center_to_start * 180 / PI);
        return (Number(bearing))
    }

    calcDestinationGeocoordFromHeading = (sourceCoord, distanceToTravel, bearingAngle) => {
        let sourceLatitude = this.qDegreesToRadians(sourceCoord.lat);
        let distanceToEarthRad = (distanceToTravel / (EARTH_RADIUS));
        let sourceLongitude = this.qDegreesToRadians(sourceCoord.lng);
        let lat1 = (Math.sin(sourceLatitude) * Math.cos((distanceToEarthRad)));
        let lat2 = (Math.cos(sourceLatitude) * Math.sin(((distanceToEarthRad))) * Math.cos(this.qDegreesToRadians(bearingAngle)));
        let m_destinationLatitude = Math.asin(lat1 + lat2);
        let destlon = (Math.sin(this.qDegreesToRadians(bearingAngle)) * Math.sin(distanceToEarthRad) * Math.cos(sourceLatitude));
        let destlon2 = ((Math.cos(distanceToEarthRad)) - (Math.sin(sourceLatitude) * Math.sin(m_destinationLatitude)));
        let atanValue = (Math.atan2(destlon, destlon2));
        let m_destinationLongitude = (sourceLongitude + atanValue);
        return { lat: this.qRadiansToDegrees(m_destinationLatitude), lng: this.qRadiansToDegrees(m_destinationLongitude) };
    }

    qDegreesToRadians = (radians) => {
        return radians * (Math.PI / 180);
    }

    qRadiansToDegrees = (degrees) => {
        return degrees * (180 / Math.PI);
    }

    wayPoints = async () => {
        let { map, imagenaryPolygon, markersLayer } = this.state
        let markerLocation = { lat: imagenaryPolygon.getLatLngs()[0][0].lat, lng: imagenaryPolygon.getLatLngs()[0][0].lng }
        let angle = 45 + Number(this.state.turnAroundAngle)
        let cords = await this.calcDestinationGeocoordFromHeading(markerLocation, 1, angle);
        this.outerPolgonPoint.push(cords)
        this.drawWayPoints()
    }

    isMarkerInsidePolygon = (marker, poly) => {
        let polyPoints = poly.getLatLngs()[0];
        let x = marker.lat, y = marker.lng;
        let inside = false;
        for (let i = 0, j = polyPoints.length - 1; i < polyPoints.length; j = i++) {
            let xi = polyPoints[i].lat, yi = polyPoints[i].lng;
            let xj = polyPoints[j].lat, yj = polyPoints[j].lng;

            let intersect = ((yi > y) != (yj > y))
                && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
            if (intersect) {
                inside = !inside
            }
        }
        return inside;
    };

    // plot polygon obstacle
    plotObstacle = () => {
        return new Promise(async (resolve, reject) => {
            for (let i = 0; i < this.state.obsPolygons.length; i++) {
                let obspolygon = new L.Polygon(this.state.obsPolygons[i].coordinates.map((latLng => [latLng.lat, latLng.lng])), {
                    color: '#2989CF',
                    fillOpacity: 0,
                    weight: 3,
                }).addTo(this.state.markersLayer).on('click', () => {
                    if (this.state.selectedWayPoint) {
                        this.state.selectedWayPoint.remove()
                        this.setState((state) => ({
                            ...state,
                            selectedWayPoint: '',
                            selectedPointindex: '',
                            selectedPointLastLocation: '',
                        }))
                    }
                    this.disableEdit().then(() => {
                        this.state.polygon.leafletLayer.disableEdit()
                        obspolygon.enableEdit();
                        this.setState({ selectedPolygon: obspolygon, obstacleDraw: true, obsCircleDraw: false, spaceAroundObstacle: this.state.obsPolygons[i].indentation ? this.state.obsPolygons[i].indentation : 0 })
                    })
                })
                obspolygon.id = this.state.obsPolygons[i].id

                this.setState((state) => ({
                    ...state,
                    obsPolygons: this.state.obsPolygons.map((obs) => {
                        if (obs.id == this.state.obsPolygons[i].id) {
                            return {
                                ...obs,
                                coordinates: obspolygon.getLatLngs()[0].map((latlng) => { return { lat: latlng.lat, lng: latlng.lng } }),
                                leafletLayer: obspolygon,
                                indentation: this.state.obsPolygons[i].indentation ? this.state.obsPolygons[i].indentation : 0
                            }
                        } else {
                            return obs
                        }
                    })
                }), async () => {
                    let polygonOffset = await this.getPolygonOffset(obspolygon, Number(this.state.obsPolygons[i].indentation))
                    this.state.offSetObsPolygons.push({
                        id: this.state.obsPolygons[i].id,
                        coordinates: polygonOffset.getLatLngs()[0].map((latlng) => { return { lat: latlng.lat, lng: latlng.lng } }),
                        leafletLayer: polygonOffset
                    })
                })
            }
            resolve(true)
        })
    }
    // convert circle in polygon
    getLatLngsOfCircle = (latLng, radius, points) => {
        return new Promise((resolve, reject) => {
            var rlat = ((radius / earthsradius / (180 / Math.PI)) * 2.0418);
            var rlng = rlat / Math.cos(latLng.lat * d2r);

            var extp = new Array();
            for (var i = 0; i < points; i++) {
                var theta = Math.PI * (i / (points / 2));
                let ex = latLng.lng + (rlng * Math.cos(theta));
                let ey = latLng.lat + (rlat * Math.sin(theta));
                extp.push({ lat: ey, lng: ex });
            }
            resolve(extp);
        })
    }

    // plot ocircle obstacle
    plotObstacleCircle = () => {
        return new Promise(async (resolve, reject) => {
            for (let i = 0; i < this.state.obsCircle.length; i++) {
                let obsCir = new L.circle(this.state.obsCircle[i].coordinates, this.state.obsCircle[i].radius, {
                    color: '#2989CF',
                    fillOpacity: 0,
                    weight: 3,
                    zIndex: 1,
                }).addTo(this.state.markersLayer).on('click', () => {
                    if (this.state.selectedWayPoint) {
                        this.state.selectedWayPoint.remove()
                        this.setState((state) => ({
                            ...state,
                            selectedWayPoint: '',
                            selectedPointindex: '',
                            selectedPointLastLocation: '',
                        }))
                    }
                    this.disableEdit().then(() => {
                        this.state.polygon.leafletLayer.disableEdit()
                        obsCir.enableEdit();
                        this.setState({ selectedPolygon: obsCir, obstacleDraw: true, obsCircleDraw: true, spaceAroundObstacle: this.state.obsCircle[i].indentation ? this.state.obsCircle[i].indentation : 0 })
                    })
                })
                obsCir.id = this.state.obsCircle[i].id
                this.setState((state) => ({
                    ...state,
                    obsCircle: this.state.obsCircle.map((obs) => {
                        if (obsCir.id == obs.id) {
                            return {
                                ...obs,
                                coordinates: { lat: obsCir.getLatLng().lat, lng: obsCir.getLatLng().lng },
                                leafletLayer: obsCir,
                                radius: obsCir.getRadius(),
                                indentation: this.state.obsCircle[i].indentation ? this.state.obsCircle[i].indentation : 0
                            }
                        } else {
                            return obs
                        }
                    })
                }), async () => {
                    let radius = (Number(this.state.obsCircle[i].radius) + Number(this.state.obsCircle[i].indentation))
                    let circleToPolygon = await this.getLatLngsOfCircle(this.state.obsCircle[i].coordinates, radius, (36 + Math.round(Number(radius))))
                    let circleOffset = new L.Polygon(circleToPolygon, {
                        color: 'orange',
                        fillOpacity: 0,
                        weight: 3,
                        zIndex: 1,
                    })
                    this.state.offSetObsPolygons.push({
                        id: this.state.obsCircle[i].id,
                        coordinates: circleOffset.getLatLngs()[0].map((latlng) => { return { lat: latlng.lat, lng: latlng.lng } }),
                        leafletLayer: circleOffset
                    })
                })
            }
            resolve(true)
        })
    }

    // sort intersection points
    sortInterctPoints = (points, lastpoint) => {
        return new Promise((resolve, reject) => {
            for (let i = 0; i < points.length; i++) {
                let dist = L.latLng(lastpoint).distanceTo(L.latLng(points[i]))
                points[i]['distance'] = dist
            }
            points.sort((a, b) => a.distance - b.distance)
            resolve(points)
        })
    }

    getPolygonInsidePoints = () => {
        return new Promise(async (resolve, reject) => {
            let startPoint = this.outerPolgonPoint[this.outerPolgonPoint.length - 1]
            let widthIns = 0
            for (let k = 0; k < 100000000; k++) {
                let newWayPoint = await this.calcDestinationGeocoordFromHeading(this.outerPolgonPoint[this.outerPolgonPoint.length - 1], this.state.polygonHeight, (0 + Number(this.state.turnAroundAngle)));
                let lineIntersectOffsetPolygon = await polygonPolyLineIntersect(this.outerPolgonPoint[this.outerPolgonPoint.length - 1], newWayPoint, this.state.offSetPolygon, this.state.map)
                if (lineIntersectOffsetPolygon.length > 0) {
                    let sortInterctPoints = await this.sortInterctPoints(lineIntersectOffsetPolygon, this.state.reversePointPlot ? newWayPoint : this.outerPolgonPoint[this.outerPolgonPoint.length - 1])
                    let distance = L.latLng(sortInterctPoints[0]).distanceTo(L.latLng(sortInterctPoints[sortInterctPoints.length - 1]))
                    if (distance < 1) {
                        let newWayPoint11 = await this.calcDestinationGeocoordFromHeading(sortInterctPoints[0], (distance / 2), this.state.reversePointPlot ? (180 + Number(this.state.turnAroundAngle)) : (0 + Number(this.state.turnAroundAngle)));
                        newWayPoint11['distance'] = sortInterctPoints[0].distance
                        newWayPoint11['vertex'] = sortInterctPoints[0].vertex
                        this.unvisited.push(newWayPoint11)
                    } else {
                        let newWayPoint1 = await this.calcDestinationGeocoordFromHeading(sortInterctPoints[0], 0.5, this.state.reversePointPlot ? (180 + Number(this.state.turnAroundAngle)) : (0 + Number(this.state.turnAroundAngle)));
                        newWayPoint1['distance'] = sortInterctPoints[0].distance
                        newWayPoint1['vertex'] = sortInterctPoints[0].vertex
                        let newWayPoint2 = await this.calcDestinationGeocoordFromHeading(sortInterctPoints[sortInterctPoints.length - 1], 0.5, this.state.reversePointPlot ? (0 + Number(this.state.turnAroundAngle)) : (180 + Number(this.state.turnAroundAngle)));
                        newWayPoint2['distance'] = sortInterctPoints[sortInterctPoints.length - 1].distance
                        newWayPoint2['vertex'] = sortInterctPoints[sortInterctPoints.length - 1].vertex
                        for (let i = 0; i < sortInterctPoints.length; i++) {
                            if (i == 0) {
                                this.unvisited.push(newWayPoint1)
                            } else if (i == sortInterctPoints.length - 1) {
                                this.unvisited.push(newWayPoint2)
                            } else {
                                this.unvisited.push(sortInterctPoints[i])
                            }
                        }
                    }
                }
                this.setState((state) => ({
                    ...state,
                    reversePointPlot: !this.state.reversePointPlot,
                }))
                this.outerPolgonPoint.push(newWayPoint)
                widthIns = widthIns + parseFloat(this.state.spacing)
                let newWayPoint1 = await this.calcDestinationGeocoordFromHeading(startPoint, widthIns, (90 + Number(this.state.turnAroundAngle)));
                let result1 = await this.isMarkerInsidePolygon(newWayPoint1, this.state.imagenaryPolygon)
                if (result1 == false) {
                    break;
                } else {
                    this.outerPolgonPoint.push(newWayPoint1)
                }
            }
            resolve(true)
        })
    }

    getLeftTraversePath = (pointIndex, pointIndex1, polygonPoints) => {
        return new Promise((resolve, reject) => {
            let leftTaverseArray = []
            let indexPolyPoint = pointIndex > polygonPoints.length - 1 ? 0 : pointIndex
            let indexPolyPoint1 = pointIndex1 > polygonPoints.length - 1 ? 0 : pointIndex1
            for (let i = 0; i < polygonPoints.length; i++) {
                leftTaverseArray.push(polygonPoints[indexPolyPoint])
                if (indexPolyPoint == 0 && indexPolyPoint != indexPolyPoint1) {
                    indexPolyPoint = polygonPoints.length - 1
                } else if (indexPolyPoint == indexPolyPoint1) {
                    break;
                } else {
                    indexPolyPoint = indexPolyPoint - 1
                }
            }
            resolve(leftTaverseArray)
        })
    }

    getRightTraversPath = (pointIndex, pointIndex1, polygonPoints) => {
        return new Promise((resolve, reject) => {
            let rightTaverseArray = []
            let indexPolyPoint = pointIndex > polygonPoints.length - 1 ? 0 : pointIndex
            let indexPolyPoint1 = pointIndex1 > polygonPoints.length - 1 ? 0 : pointIndex1
            for (let i = 0; i < polygonPoints.length; i++) {
                rightTaverseArray.push(polygonPoints[indexPolyPoint])
                if (indexPolyPoint == polygonPoints.length - 1 && indexPolyPoint != indexPolyPoint1) {
                    indexPolyPoint = 0
                } else if (indexPolyPoint == indexPolyPoint1) {
                    break;
                } else {
                    indexPolyPoint = indexPolyPoint + 1
                }
            }
            resolve(rightTaverseArray)
        })
    }

    getPathDistance = (path, distance) => {
        return new Promise((resolve, reject) => {
            let pathDistance = distance
            for (let i = 0; i < path.length - 1; i++) {
                let lineLength = L.latLng(path[i]).distanceTo(L.latLng(path[i + 1]))
                pathDistance = pathDistance + lineLength
            }
            resolve(pathDistance)
        })
    }

    findSortesPath = (point1, point2, polygon) => {
        return new Promise(async (resolve, reject) => {
            let polyPoints = polygon.getLatLngs()[0]
            let leftTraversPath = await this.getLeftTraversePath(point1.vertex, point2.vertex + 1, polyPoints)
            let dist = L.latLng(point1).distanceTo(L.latLng(leftTraversPath[0]))
            let dist1 = L.latLng(point2).distanceTo(L.latLng(leftTraversPath[leftTraversPath.length - 1]))
            let rightTraversPath = await this.getRightTraversPath(point1.vertex, point2.vertex + 1, polyPoints)
            let dist2 = L.latLng(point1).distanceTo(L.latLng(rightTraversPath[0]))
            let dist3 = L.latLng(point2).distanceTo(L.latLng(rightTraversPath[rightTraversPath.length - 1]))
            let leftTraverseDistance = await this.getPathDistance(leftTraversPath, (dist + dist1))
            let rightTraversDistance = await this.getPathDistance(rightTraversPath, (dist2 + dist3))
            let finalList = []
            let finalDist = 0
            if (leftTraverseDistance > rightTraversDistance) {
                finalList = rightTraversPath
                finalDist = rightTraversDistance
            } else {
                finalList = leftTraversPath
                finalDist = leftTraverseDistance
            }

            let leftTraversPath1 = await this.getLeftTraversePath(point1.vertex + 1, point2.vertex, polyPoints)
            let dist4 = L.latLng(point1).distanceTo(L.latLng(leftTraversPath1[0]))
            let dist5 = L.latLng(point2).distanceTo(L.latLng(leftTraversPath1[leftTraversPath1.length - 1]))
            let rightTraversPath1 = await this.getRightTraversPath(point1.vertex + 1, point2.vertex, polyPoints)
            let dist6 = L.latLng(point1).distanceTo(L.latLng(rightTraversPath1[0]))
            let dist7 = L.latLng(point2).distanceTo(L.latLng(rightTraversPath1[rightTraversPath1.length - 1]))
            let leftTraverseDistance1 = await this.getPathDistance(leftTraversPath1, (dist4 + dist5))
            let rightTraversDistance1 = await this.getPathDistance(rightTraversPath1, (dist6 + dist7))
            let finalList1 = []
            let finalDist1 = 0
            if (leftTraverseDistance1 > rightTraversDistance1) {
                finalList1 = rightTraversPath1
                finalDist1 = rightTraversDistance1
            } else {
                finalList1 = leftTraversPath1
                finalDist1 = leftTraverseDistance1
            }

            if (finalDist > finalDist1) {
                resolve(finalList1)
            } else {
                resolve(finalList)
            }
        })
    }

    getSortedVisitedList = () => {
        return new Promise(async (resolve, reject) => {
            let sortInterctPoints = this.unvisited
            this.unvisited = []
            for (let j = 0; j < sortInterctPoints.length - 1; j++) {
                let lineLength = L.latLng(sortInterctPoints[j]).distanceTo(L.latLng(sortInterctPoints[j + 1]))
                let bearingAngle = await this.bearingAngle(sortInterctPoints[j + 1], sortInterctPoints[j])
                let newPoint = await this.calcDestinationGeocoordFromHeading(sortInterctPoints[j], (lineLength / 2), bearingAngle)
                let result = await this.isMarkerInsidePolygon(newPoint, this.state.offSetPolygon)
                if (result) {
                    this.unvisited.push(sortInterctPoints[j])
                } else {
                    this.unvisited.push(sortInterctPoints[j])
                    let shortPath = await this.findSortesPath(sortInterctPoints[j], sortInterctPoints[j + 1], this.state.offSetPolygon)
                    this.unvisited = this.unvisited.concat(shortPath)
                }
                // }
            }
            this.unvisited.push(sortInterctPoints[sortInterctPoints.length - 1])
            resolve(true)
        })

    }

    getLineIntersectedObstacle = (point1, point2) => {
        return new Promise(async (resolve, reject) => {
            let intersectPoints = []
            for (let i = 0; i < this.state.offSetObsPolygons.length; i++) {
                let intersect = await polygonPolyLineIntersect(point1, point2, this.state.offSetObsPolygons[i].leafletLayer, this.state.map)
                if (intersect.length > 0) {
                    for (let j = 0; j < intersect.length; j++) {
                        let dist = L.latLng(point1).distanceTo(L.latLng(intersect[j]))
                        intersect[j]['distance'] = dist
                        intersect[j]['obsId'] = this.state.offSetObsPolygons[i].id
                        intersectPoints.push(intersect[j])
                    }
                }
            }
            intersectPoints.sort((a, b) => a.distance - b.distance)
            resolve(intersectPoints)
        })

    }

    getObstacleIntersectedPoints = () => {
        return new Promise(async (resolve, reject) => {
            let unvisitedList = this.unvisited
            this.unvisited = []
            for (let i = 0; i < unvisitedList.length - 1; i++) {
                let intersected = await this.getLineIntersectedObstacle(unvisitedList[i], unvisitedList[i + 1])
                this.visited.push(unvisitedList[i])
                if (intersected.length > 0) {
                    this.visited = this.visited.concat(intersected)
                }
            }
            this.visited.push(unvisitedList[unvisitedList.length - 1])
            resolve(true)
        })
    }

    avoidObstacleCross = () => {
        return new Promise(async (resolve, reject) => {
            let visitedList = this.visited
            this.visited = []
            for (let i = 0; i < visitedList.length - 1; i++) {
                this.surveyWayPoints.push(visitedList[i])
                if (visitedList[i].obsId) {
                    if (visitedList[i].obsId == visitedList[i + 1].obsId) {
                        let intersectObs = this.state.offSetObsPolygons.find((obs) => obs.id == visitedList[i].obsId)
                        let dist = L.latLng(visitedList[i]).distanceTo(L.latLng(visitedList[i + 1]))
                        let bearingAngle = await this.bearingAngle(visitedList[i + 1], visitedList[i])
                        let newPoint = await this.calcDestinationGeocoordFromHeading(visitedList[i], dist / 2, bearingAngle)
                        let result = await this.isMarkerInsidePolygon(newPoint, intersectObs.leafletLayer)
                        if (result) {
                            let shortPath = await this.findSortesPath(visitedList[i], visitedList[i + 1], intersectObs.leafletLayer)
                            this.surveyWayPoints = this.surveyWayPoints.concat(shortPath)
                        }
                    }
                }
            }
            this.surveyWayPoints.push(visitedList[visitedList.length - 1])
            resolve(true)
        })
    }

    drawWayPoints = async () => {
        this.setState({ reversePointPlot: false })
        if (this.state.obsPolygons.length > 0) {
            await this.plotObstacle()
        }

        if (this.state.obsCircle.length > 0) {
            await this.plotObstacleCircle()
        }
        await this.getPolygonInsidePoints()
        await this.getSortedVisitedList()

        if (this.state.offSetObsPolygons.length > 0) {
            await this.getObstacleIntersectedPoints()
            await this.avoidObstacleCross()
        } else {
            this.surveyWayPoints = this.surveyWayPoints.concat(this.unvisited)
        }
        this.plotWayPointOnMap()
    }

    plotWayPointOnMap = async () => {
        let planLine = L.polyline(this.surveyWayPoints, {
            color: this.state.selectedLayer == 'Orthomosaic' || this.state.selectedLayer == 'Base' ? 'orange' : '#2989cf',
            weight: 3,
        }).addTo(this.state.markersLayer)
        let surveyLength = 0
        this.setState({ planLine })
        this.surveyWayPoints.map(async (cords, index) => {
            if (index == 0) {
                let tempMarker = L.circleMarker(cords, {
                    color: 'black',
                    fillColor: '#2989CF',
                    fillOpacity: 2,
                    radius: 10,
                    weight: 1,
                }).bindTooltip(`S`,
                    {
                        className: 'tooltip-label',
                        permanent: true,
                        direction: 'center',
                    })
                tempMarker.addTo(this.state.markersLayer)
            }
            if (index == this.surveyWayPoints.length - 1) {
                let tempMarker = L.circleMarker(cords, {
                    color: 'black',
                    fillColor: '#2989CF',
                    fillOpacity: 2,
                    radius: 10,
                    weight: 1,
                }).bindTooltip(`F`,
                    {
                        className: 'tooltip-label',
                        permanent: true,
                        direction: 'center',
                    })
                tempMarker.addTo(this.state.markersLayer)
            }
            if (index == this.surveyWayPoints.length - 1) {
                let sprayingTime = await this.convertHMS(surveyLength / this.state.sprayingSpeed)
                this.setState((state) => ({
                    ...state,
                    sprayingLength: Math.round(surveyLength),
                    sprayingPoint: Math.round(surveyLength / this.state.sprayingTrigger),
                    sprayingTime: sprayingTime,
                }))
            } else {
                let distance = L.latLng(cords).distanceTo(L.latLng(this.surveyWayPoints[index + 1]))
                surveyLength = (surveyLength + distance)
            }
        })
        if (this.state.selectedWayPoint) {
            this.state.selectedWayPoint.addTo(this.state.markersLayer)
        }
        if (this.state.selectedParameter == 'indentation') {
            this.drawLineForIndentation()
        }
    }

    convertHMS = (value) => {
        const sec = parseInt(value, 10); // convert value to number if it's string
        let hours = Math.floor(sec / 3600); // get hours
        let minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
        let seconds = sec - (hours * 3600) - (minutes * 60); //  get seconds
        if (hours < 10) { hours = "0" + hours; }
        if (minutes < 10) { minutes = "0" + minutes; }
        if (seconds < 10) { seconds = "0" + seconds; }
        return hours + ':' + minutes + ':' + seconds;
    }

    getPolygonHeightWidth = (imagenaryPolygon) => {
        let polygonWidth = imagenaryPolygon.getBounds().getSouthWest().distanceTo(imagenaryPolygon.getBounds().getSouthEast())
        let polygonHeight = imagenaryPolygon.getBounds().getSouthWest().distanceTo(imagenaryPolygon.getBounds().getNorthWest())
        this.setState((state) => ({
            ...state,
            polygonWidth: Math.round(polygonWidth),
            polygonHeight: Math.round(polygonHeight),
        }))
    }

    handleMapClick = (e) => {
        console.log(e)
    }
    handleMoveStart = (e) => {
        console.log(e)
    }

    successRedirectComponent = () => {
        if (this.state.redirect) {
            return (
                <Redirect
                    to={{
                        pathname: "/aerogcsenterprise/remote_mission/assignDroneToPlan",
                        state: {
                            gmarkers: [],
                            gmarkers1: [],
                            gpoly: [],
                            gpoly1: [],
                            user: this.state.user,
                            type: this.state.type,
                            center: this.state.center,
                            plan: {
                                id: this.props.location?.state?.plan?.id || this.state.plan?.id,
                                plan_id: this.state.plan ? this.state.plan.id : null,
                                plan_name: this.props.location?.state?.plan?.plan_name || this.state.plan?.plan_name || this.state.planName,
                                project_name: this.props.location?.state?.project?.project_name
                            },
                            project: {
                                id: this.props.location?.state?.project ? (this.props.location.state.project.project_id || this.props.location.state.project.id) : {},
                                project_name: this.props.location?.state?.project?.project_name
                            },
                            sidebarMode: this.state.sidebarMode,
                        },
                    }}
                />
            );
        }
    };

    convertFenceToPoint = (Geofence) => {
        let poly = []
        for (let i = 0; i < Geofence.length - 1; i++) {
            let polyPoint = { lat: Geofence[i].geofence['geofence X'], lng: Geofence[i].geofence['geofence Y'] }
            poly.push(polyPoint)
        }
        return (poly)
    }

    convertFenceToPoint1 = (Geofence) => {
        let poly = []
        for (let i = 0; i < Geofence.geofence.length - 1; i++) {
            let polyPoint = { lat: Geofence.geofence[i].lat, lng: Geofence.geofence[i].lon }
            poly.push(polyPoint)
        }
        return (poly)
    }

    getObstacleObject = (polygons) => {
        return new Promise((resolve, reject) => {
            polygons.map((polygon) => {
                let uniqueId = uuid()
                this.state.obsPolygons.push({
                    coordinates: polygon.vertices.map((latlng) => { return { lat: latlng.lat, lng: latlng.lon } }),
                    id: uniqueId,
                    indentation: polygon.indentation
                })
            })
            resolve(true)
        })
    }

    getCircleObstacleObject = (circles) => {
        return new Promise((resolve, reject) => {
            circles.map((cir) => {
                let uniqueId = uuid()
                this.state.obsCircle.push({
                    coordinates: { lat: cir.centroid.lat, lng: cir.centroid.lon },
                    id: uniqueId,
                    indentation: cir.indentation,
                    radius: cir.radius
                })
            })
            resolve(true)
        })
    }

    getPlanData = () => {
        API.getPlan(this.state.projectName, this.state.planName)
            .then(async (planData) => {
                if (planData.planConfiguration[0]) {
                    let editPolygon = await this.convertFenceToPoint(planData.Geofence)
                    let MissionStatistics = planData.MissionStatistics[0].MissionStatistics
                    if (planData.obstacle) {
                        if (planData.obstacle.polygons.length > 0) {
                            await this.getObstacleObject(planData.obstacle.polygons)
                        }
                        if (planData.obstacle.circles.length > 0) {
                            await this.getCircleObstacleObject(planData.obstacle.circles)
                        }
                    }
                    this.setState((state) => ({
                        ...state,
                        altitude: MissionStatistics.Spraying_Altitude,
                        speed: MissionStatistics.Speed,
                        sprayingType: MissionStatistics.Optimize_Spraying,
                        sprayingArea: MissionStatistics.Spraying_Area,
                        pumpRate: MissionStatistics.Spraying_Pump_Rate,
                        sprayingSpeed: MissionStatistics.Spraying_Speed,
                        sprayingPoint: MissionStatistics.Spraying_Spot,
                        sprayingTrigger: MissionStatistics.Spraying_Trigger,
                        spacing: MissionStatistics.Spraying_Spacing,
                        sprayingLength: MissionStatistics.TotalPlanDistance,
                        turnAroundAngle: MissionStatistics.Turnaround_Angle,
                        turnAroundDist: MissionStatistics.Turnaround_Distance,
                        polygon: {
                            coordinates: editPolygon
                        },
                    }), () => {
                        this.setState({ loading: false })
                    })
                } else {
                    let editPolygon = await this.convertFenceToPoint1(planData.Geofence)
                    let MissionStatistics = planData.MissionStatistics.missionStatistics[0]
                    if (planData.obstacle) {
                        if (planData.obstacle.polygons?.length > 0) {
                            await this.getObstacleObject(planData.obstacle.polygons)
                        }
                        if (planData.obstacle.circles?.length > 0) {
                            await this.getCircleObstacleObject(planData.obstacle.circles)
                        }
                    }
                    this.setState((state) => ({
                        ...state,
                        altitude: MissionStatistics.sprayingAltitude,
                        speed: MissionStatistics.speed,
                        sprayingType: MissionStatistics.optimizeSpraying,
                        sprayingArea: MissionStatistics.sprayingArea,
                        pumpRate: MissionStatistics.sprayingPumpRate,
                        sprayingSpeed: MissionStatistics.sprayingSpeed,
                        sprayingPoint: MissionStatistics.sprayingSpot,
                        sprayingTrigger: MissionStatistics.sprayingTrigger,
                        spacing: MissionStatistics.sprayingSpacing,
                        sprayingLength: MissionStatistics.totalPlanDistance,
                        turnAroundAngle: MissionStatistics.turnaroundAngle,
                        turnAroundDist: MissionStatistics.turnaroundDistance,
                        polygon: {
                            coordinates: editPolygon
                        },
                    }), () => {
                        this.setState({ loading: false })
                    })
                }

            })
    }

    componentWillUnmount() { updateOutTime(this.state.outId) }

    componentWillMount() {
        document.title = "Flight Type:Spraying";
        addUserAnalytic(this.props.location.pathname).then(id => { this.setState({ outId: id }) })
        if (this.props.location.state.editPlan) {
            let { user, type, flightType, planName, projectName, editPlan, sidebarMode, project, plan, linked_map, clientName, polygon, obstaclePolygons } = this.props.location.state;
            this.props.addProjectDetails({
                projectName: projectName,
                clientName: "",
                planName: planName,
                dateAndTime: "",
            });
            this.setState((state) => ({
                ...state,
                flightType,
                type,
                user,
                planName,
                projectName,
                project,
                plan,
                linked_map: linked_map ? linked_map : plan && plan.linked_map ? plan.linked_map : null,
                clientName: clientName ? clientName : "asd",
                editPlan,
                sidebarMode,
                polygon: {
                    coordinates: polygon
                },
                obsPolygons: [],
                obsCircle: [],
            }), () => { this.getPlanData() })
        } else if (this.props.location.state) {
            let { Distance, altitude, center, drawPlan, flightType, speed, speedd, type, user, project, plan, linked_map, clientName, planName, projectName, polygon, obstaclePolygons, obstacleCircles } = this.props.location.state;
            this.setState((state) => ({
                ...state,
                Distance,
                altitude: altitude ? altitude : 5,
                center,
                drawPlan,
                flightType,
                speed,
                sprayingSpeed: speed ? speed : 2,
                speedd,
                type,
                user,
                project,
                planName,
                projectName,
                plan,
                linked_map: linked_map ? linked_map : plan && plan.linked_map ? plan.linked_map : null,
                clientName: clientName ? clientName : "asd",
                polygon: {
                    coordinates: polygon
                },
                obsPolygons: obstaclePolygons,
                obsCircle: obstacleCircles,
                loading: false,
            }))
        } else window.location.replace("/login");
    }

    getSeletedPoint = (point) => {
        if (this.state.selectedWayPoint) {
            this.state.selectedWayPoint.remove()
        }
        if (this.state.obsCircleDraw == false && this.state.selectedParameter == 'waypoint') {
            let selected = L.circleMarker(point.vertex.latlng, {
                color: 'black',
                fillColor: 'white',
                fillOpacity: 2,
                radius: 10,
                weight: 1,
            })
            selected.addTo(this.state.markersLayer)
            this.setState((state) => ({
                ...state,
                selectedWayPoint: selected,
                selectedPointindex: point.vertex.getIndex(),
                selectedPointLastLocation: { lat: point.vertex.latlng.lat, lng: point.vertex.latlng.lng }
            }))
        }
    }

    setPosition = (newPosition) => {
        if (this.state.obstacleDraw) {
            let ployPoints = this.state.selectedPolygon.getLatLngs()[0]
            ployPoints[this.state.selectedPointindex] = newPosition
            for (let i = 0; i < this.state.obsPolygons.length; i++) {
                if (this.state.selectedPolygon.id == this.state.obsPolygons[i].id) {
                    this.state.obsPolygons[i].coordinates = ployPoints.map((latlng) => { return { lat: latlng.lat, lng: latlng.lng } })
                    this.state.obsPolygons[i].leafletLayer.setLatLngs(ployPoints)
                    this.polygonUpdated(this.state.polygon.leafletLayer)
                }
            }
        } else {
            let ployPoints = this.state.polygon.leafletLayer.getLatLngs()[0]
            ployPoints[this.state.selectedPointindex] = newPosition
            this.state.polygon.coordinates = ployPoints.map((latlng) => { return { lat: latlng.lat, lng: latlng.lng } })
            this.state.polygon.leafletLayer.setLatLngs(ployPoints)
            this.polygonUpdated(this.state.polygon.leafletLayer)
        }
    }

    wayptRevClick = () => {
        if (this.state.selectedWayPoint) {
            this.state.selectedWayPoint.setLatLng(this.state.selectedPointLastLocation)
            this.setPosition(this.state.selectedPointLastLocation)
        }
    }

    wayptUpClick = () => {
        if (this.state.selectedWayPoint) {
            let source = this.state.selectedWayPoint.getLatLng()
            let newPosition = this.calcDestinationGeocoordFromHeading(source, 0.5, 0)
            this.state.selectedWayPoint.setLatLng(newPosition)
            this.setPosition(newPosition)
        }
    }

    wayptRightClick = () => {
        if (this.state.selectedWayPoint) {
            let source = this.state.selectedWayPoint.getLatLng()
            let newPosition = this.calcDestinationGeocoordFromHeading(source, 0.5, 90)
            this.state.selectedWayPoint.setLatLng(newPosition)
            this.setPosition(newPosition)
        }
    }

    wayptDownClick = () => {
        if (this.state.selectedWayPoint) {
            let source = this.state.selectedWayPoint.getLatLng()
            let newPosition = this.calcDestinationGeocoordFromHeading(source, 0.5, 180)
            this.state.selectedWayPoint.setLatLng(newPosition)
            this.setPosition(newPosition)
        }
    }

    wayptLeftClick = () => {
        if (this.state.selectedWayPoint) {
            let source = this.state.selectedWayPoint.getLatLng()
            let newPosition = this.calcDestinationGeocoordFromHeading(source, 0.5, 270)
            this.state.selectedWayPoint.setLatLng(newPosition)
            this.setPosition(newPosition)
        }
    }


    render() {
        return (
            <>
                <div className="wrapper">
                    {this.successRedirectComponent()}
                    <div className="right-content-new" style={{ overflowY: "hidden" }}
                        onClick={
                            () => {
                                this.setState((state) => ({
                                    ...state, sidebarMode: 'shrunk'
                                }))
                            }
                        }
                    >
                        <ActivityPopup
                            item={this.state.itemName}
                            open={this.state.showProcessPopup}
                            icon={this.state.processPopupIcon}
                            action={this.state.processAction}
                            msg={this.state.processMessage}
                            close={() => {
                                this.setState((state) => ({
                                    ...state,
                                    showProcessPopup: false,
                                    processMessage: null
                                }))
                            }}
                            onClickOk={() => { }}
                        />
                        <div className="top-bar-new" >
                            <div className="top-bar-text-new">
                                FLIGHT PLAN
                            </div>
                        </div>
                        {this.state.loading ? <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
                            <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
                        </div> :
                            <div id="rmp-map" className="main-content-new" style={{ padding: "0", height: "calc(100vh - 70px)", }}>
                                <SingleMap
                                    setBaseLayerToState={this.setBaseLayerToState}
                                    initCenter={[this.state.center.lat, this.state.center.lng]}
                                    initZoom={18}
                                    handleMapClick={this.handleMapClick}
                                    maxZoom={23}
                                    setMap={(map) => {
                                        this.setState({ map }, this.initMap)
                                    }}
                                />
                            </div>}
                        {/* Layer selection popup */}
                        {this.state.linked_map ? <div className='slide' style={{ textAlign: 'center', zIndex: 1, fontWeight: 500, border: 'solid 1px rgb(102, 102, 102, 0.3)', position: 'absolute', width: "105px", padding: "4px 12px", bottom: '20px', right: '118px', backgroundColor: 'white', borderRadius: '5px', cursor: 'pointer', fontSize: "12px" }}
                            onClick={() => {
                                this.setState({ layerSelectionPopup: !this.state.layerSelectionPopup })
                            }}>
                            {this.state.selectedLayer}
                        </div> : <></>}
                        {this.state.layerSelectionPopup && this.state.linked_map ?
                            <div style={{ zIndex: 1, position: 'absolute', width: "105px", fontWeight: 500, fontSize: "12px", bottom: '52px', right: '118px', backgroundColor: 'white', borderRadius: '5px', padding: "1px" }} classname="cardscon content-text">
                                <div onClick={() => this.changeSelectedLayer("Orthomosaic")} style={{ cursor: 'pointer', margin: "3px 4px 3px 12px" }}>
                                    Orthomosaic
                                </div>
                                <hr style={{ margin: "3px" }}></hr>
                                <div onClick={() => this.changeSelectedLayer("NDVI")} style={{ cursor: 'pointer', margin: "3px 4px 3px 12px" }}>
                                    NDVI
                                </div>
                                <hr style={{ margin: "3px" }}></hr>
                                <div onClick={() => this.changeSelectedLayer("VARI")} style={{ cursor: 'pointer', margin: "3px 4px 3px 12px" }}>
                                    VARI
                                </div>
                                <hr style={{ margin: "3px" }}></hr>
                                <div onClick={() => this.changeSelectedLayer("Base")} style={{ cursor: 'pointer', margin: "3px 4px 3px 12px" }}>
                                    BASE
                                </div>
                            </div> :
                            <></>
                        }

                        {/* changeBaseMapView  */}
                        <div className='slide' style={{ border: 'solid 1px rgb(102, 102, 102, 0.3)', position: 'absolute', bottom: '20px', right: '18px', backgroundColor: 'rgba(255, 255, 255, 0.9)', borderRadius: '5px', cursor: 'pointer', zIndex: '1' }}
                            onClick={() => {
                                this.switchBaseLayer()
                            }}>
                            <img style={{ width: '90px', height: '27px', borderRadius: '4px' }} src={this.state.activeBaseLayer === "satelite" ? roadMapView : sateliteView} />
                        </div>

                        {this.state.loading ? <></> : <div style={{ position: 'absolute', top: '85px', left: '25px', background: 'white', borderRadius: '6px', padding: '2px 0px' }}>
                            <SelectComponent
                                selected={this.state.selectedItem}
                                itemsList={this.state.itemsList}
                                selectIcon={downArrow}
                                onchangeHandler={(value) => { this.onChangeEvent(value) }}
                                fontSize={"15px"}
                                width={"250px"}
                                fontWeight={500}
                                padding={"0px 15px"}
                                selectColor={"#2989CF"}
                            />
                        </div>}

                        {this.state.loading ? '' : <div
                            style={{
                                top: "130px",
                                left: '25px',
                                zIndex: "1",
                                position: 'absolute',
                                height: 'fit-content',
                                borderRadius: '15px',
                                width: "250px",
                                borderRadius: '6px',
                                background: 'white',
                                padding: '1px'
                            }}
                        >
                            <div
                                style={{
                                    padding: "2px 4px",
                                    height: '100%',
                                    width: "100%",
                                }}
                            >
                                {this.state.selectedParameter === 'parameter' ? <div style={{ padding: '0px 10px', }}>
                                    <div className="add-user-form-row" style={{ flexDirection: 'column' }}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <div style={{ fontFamily: "Poppins", fontStyle: "normal", fontWeight: "400", fontSize: "12px", color: "#3C3C3C" }}>Spray Amount</div>
                                            <div style={{ fontFamily: "Poppins", fontStyle: "normal", fontWeight: "400", fontSize: "12px", color: "#3C3C3C" }}>
                                                <span style={{ color: '#2989CF', fontSize: '18px', fontWeight: '500' }}>{this.state.sprayAmount}</span> L/Acre</div>
                                        </div>
                                        <div style={{ height: '20px', display: 'flex', alignItems: 'center', }}>
                                            <input className='rangeColor' type="range" min="1" max="50" style={{ width: '100%', cursor: 'pointer' }}
                                                onChange={(e) => {
                                                    let pumpRate = e.target.value
                                                    this.setState((state) => ({
                                                        ...state,
                                                        sprayAmount: Number(pumpRate)
                                                    }))
                                                }}
                                                value={this.state.sprayAmount} />
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <div style={{ fontFamily: "Poppins", fontStyle: "normal", fontWeight: "400", fontSize: "12px", color: "#3C3C3C" }}>Spray Rate</div>
                                            <div style={{ fontFamily: "Poppins", fontStyle: "normal", fontWeight: "400", fontSize: "12px", color: "#3C3C3C" }}>
                                                <span style={{ color: '#2989CF', fontSize: '18px', fontWeight: '500' }}>{this.state.pumpRate}</span> %</div>
                                        </div>
                                        <div style={{ height: '20px', display: 'flex', alignItems: 'center', }}>
                                            <input className='rangeColor' type="range" min="1" max="100" style={{ width: '100%', cursor: 'pointer' }}
                                                onChange={(e) => {
                                                    let pumpRate = e.target.value
                                                    this.setState((state) => ({
                                                        ...state,
                                                        pumpRate: Number(pumpRate)
                                                    }))
                                                }}
                                                value={this.state.pumpRate} />
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' }}>
                                            <div style={{ fontFamily: "Poppins", fontStyle: "normal", fontWeight: "400", fontSize: "11px", color: "#3C3C3C" }}>1</div>
                                            <div style={{ fontFamily: "Poppins", fontStyle: "normal", fontWeight: "400", fontSize: "11px", color: "#3C3C3C" }}>100</div>
                                        </div>
                                    </div>

                                    <div className="add-user-form-row" style={{ flexDirection: 'column' }}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <div style={{ fontFamily: "Poppins", fontStyle: "normal", fontWeight: "400", fontSize: "12px", color: "#3C3C3C" }}>Spray Altitude</div>
                                            <div style={{ fontFamily: "Poppins", fontStyle: "normal", fontWeight: "400", fontSize: "12px", color: "#3C3C3C" }}>
                                                <span style={{ color: '#2989CF', fontSize: '18px', fontWeight: '500' }}>{this.state.altitude}</span> m</div>
                                        </div>
                                        <div style={{ height: '20px', display: 'flex', alignItems: 'center', }}>
                                            <input className='rangeColor' type="range" min="1" max="20" style={{ width: '100%', cursor: 'pointer' }}
                                                onChange={(e) => {
                                                    let altitude = e.target.value
                                                    this.setState((state) => ({
                                                        ...state,
                                                        altitude: Number(altitude)
                                                    }))
                                                }}
                                                value={this.state.altitude} />
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' }}>
                                            <div style={{ fontFamily: "Poppins", fontStyle: "normal", fontWeight: "400", fontSize: "11px", color: "#3C3C3C" }}>1</div>
                                            <div style={{ fontFamily: "Poppins", fontStyle: "normal", fontWeight: "400", fontSize: "11px", color: "#3C3C3C" }}>20</div>
                                        </div>
                                    </div>

                                    <div className="add-user-form-row" style={{ flexDirection: 'column' }}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <div style={{ fontFamily: "Poppins", fontStyle: "normal", fontWeight: "400", fontSize: "12px", color: "#3C3C3C" }}>Flight Speed</div>
                                            <div style={{ fontFamily: "Poppins", fontStyle: "normal", fontWeight: "400", fontSize: "12px", color: "#3C3C3C" }}>
                                                <span style={{ color: '#2989CF', fontSize: '18px', fontWeight: '500' }}>{this.state.sprayingSpeed}</span> m/s</div>
                                        </div>
                                        <div style={{ height: '20px', display: 'flex', alignItems: 'center', }}>
                                            <input className='rangeColor' type="range" min="1" max="20" style={{ width: '100%', cursor: 'pointer' }}
                                                onChange={(e) => {
                                                    let sprayingSpeed = e.target.value
                                                    this.setState((state) => ({
                                                        ...state,
                                                        sprayingSpeed: Number(sprayingSpeed)
                                                    }))
                                                }}
                                                value={this.state.sprayingSpeed} />
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' }}>
                                            <div style={{ fontFamily: "Poppins", fontStyle: "normal", fontWeight: "400", fontSize: "11px", color: "#3C3C3C" }}>1</div>
                                            <div style={{ fontFamily: "Poppins", fontStyle: "normal", fontWeight: "400", fontSize: "11px", color: "#3C3C3C" }}>20</div>
                                        </div>
                                    </div>

                                    <div className="add-user-form-row" style={{ flexDirection: 'column' }}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <div style={{ fontFamily: "Poppins", fontStyle: "normal", fontWeight: "400", fontSize: "12px", color: "#3C3C3C" }}>Spacing</div>
                                            <div style={{ fontFamily: "Poppins", fontStyle: "normal", fontWeight: "400", fontSize: "12px", color: "#3C3C3C" }}>
                                                <span style={{ color: '#2989CF', fontSize: '18px', fontWeight: '500' }}>{this.state.spacing}</span> m</div>
                                        </div>
                                        <div style={{ height: '20px', display: 'flex', alignItems: 'center', }}>
                                            <input className='rangeColor' type="range" min="1" max="10" style={{ width: '100%', cursor: 'pointer' }}
                                                onChange={(e) => {
                                                    let spacing = e.target.value
                                                    this.setState((state) => ({
                                                        ...state,
                                                        spacing: Number(spacing)
                                                    }), () => {
                                                        this.polygonUpdated(this.state.polygon.leafletLayer)
                                                    })
                                                }}
                                                value={this.state.spacing} />
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' }}>
                                            <div style={{ fontFamily: "Poppins", fontStyle: "normal", fontWeight: "400", fontSize: "11px", color: "#3C3C3C" }}>1</div>
                                            <div style={{ fontFamily: "Poppins", fontStyle: "normal", fontWeight: "400", fontSize: "11px", color: "#3C3C3C" }}>10</div>
                                        </div>
                                    </div>
                                    <div className="add-user-form-row" style={{ flexDirection: 'column' }}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <div style={{ fontFamily: "Poppins", fontStyle: "normal", fontWeight: "400", fontSize: "12px", color: "#3C3C3C" }}>Fence Boundary</div>
                                            <div style={{ fontFamily: "Poppins", fontStyle: "normal", fontWeight: "400", fontSize: "12px", color: "#3C3C3C" }}>
                                                <span style={{ color: '#2989CF', fontSize: '18px', fontWeight: '500' }}>{this.state.fenceBoundary}</span> m</div>
                                        </div>
                                        <div style={{ height: '20px', display: 'flex', alignItems: 'center', }}>
                                            <input className='rangeColor' type="range" min="1" max="10" style={{ width: '100%', cursor: 'pointer' }}
                                                onChange={(e) => {
                                                    let fenceBoundary = e.target.value
                                                    this.setState((state) => ({
                                                        ...state,
                                                        fenceBoundary: Number(fenceBoundary)
                                                    }), () => {
                                                        this.polygonUpdated(this.state.polygon.leafletLayer)
                                                    })
                                                }}
                                                value={this.state.fenceBoundary} />
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' }}>
                                            <div style={{ fontFamily: "Poppins", fontStyle: "normal", fontWeight: "400", fontSize: "11px", color: "#3C3C3C" }}>1</div>
                                            <div style={{ fontFamily: "Poppins", fontStyle: "normal", fontWeight: "400", fontSize: "11px", color: "#3C3C3C" }}>10</div>
                                        </div>
                                    </div>
                                </div>
                                    : this.state.selectedParameter === 'waypoint' ? <div style={{ padding: '2px 10px', }}>
                                        <div style={{ fontFamily: "Poppins", fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "#3C3C3C", textAlign: 'center', marginTop: '15px' }} >Adjust Waypoints</div>
                                        <div style={{ textAlign: 'center', margin: '20px 0px' }}>
                                            <img src={wayptBack} style={{ width: '110px', height: '110px' }} />
                                            <img src={wayptRev} style={{ width: '25px', height: '25px', position: 'absolute', top: '106px', left: '112px', cursor: 'pointer' }} onClick={() => {
                                                this.wayptRevClick()
                                            }} />
                                            <img src={wayptUp} style={{ width: '25px', height: '25px', position: 'absolute', top: '63px', left: '112px', cursor: 'pointer' }} onClick={() => {
                                                this.wayptUpClick()
                                            }} />
                                            <img src={wayptRight} style={{ width: '25px', height: '25px', position: 'absolute', top: '106px', left: '155px', cursor: 'pointer' }} onClick={() => {
                                                this.wayptRightClick()
                                            }} />
                                            <img src={wayptDown} style={{ width: '25px', height: '25px', position: 'absolute', top: '150px', left: '112px', cursor: 'pointer' }} onClick={() => {
                                                this.wayptDownClick()
                                            }} />
                                            <img src={wayptLeft} style={{ width: '25px', height: '25px', position: 'absolute', top: '105px', left: '70px', cursor: 'pointer' }} onClick={() => {
                                                this.wayptLeftClick()
                                            }} />
                                        </div>
                                    </div>
                                        : this.state.selectedParameter === 'spacing' ? <div style={{ padding: '2px 10px', }}>
                                            <div style={{ fontFamily: "Poppins", fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "#3C3C3C", textAlign: 'center', marginTop: '15px' }} >Adjust Spacing</div>
                                            <div style={{ fontFamily: "Poppins", fontStyle: "normal", fontWeight: "400", fontSize: "13px", color: "#3C3C3C", textAlign: 'center', marginTop: '10px' }} >Adjust spacing between rows</div>
                                            <div style={{ textAlign: 'center', margin: '30px 0px' }}><img src={spacingImg} style={{ width: '80%', }} /></div>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <div style={{ fontFamily: "Poppins", fontStyle: "normal", fontWeight: "400", fontSize: "12px", color: "#3C3C3C" }}>Spacing</div>
                                                <div style={{ fontFamily: "Poppins", fontStyle: "normal", fontWeight: "400", fontSize: "12px", color: "#3C3C3C" }}>
                                                    <span style={{ color: '#2989CF', fontSize: '18px', fontWeight: '500' }}>{this.state.spacing}</span> m</div>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0px', alignItems: 'center' }}>
                                                <div>
                                                    <img src={minusIcon} style={{ width: '18px', cursor: 'pointer' }} onClick={() => {
                                                        if (this.state.spacing > 1) {
                                                            this.setState((state) => ({
                                                                ...state,
                                                                spacing: (Number(this.state.spacing) - 1)
                                                            }), () => {
                                                                this.polygonUpdated(this.state.polygon.leafletLayer)
                                                            })
                                                        }
                                                    }} />
                                                </div>
                                                <input className='rangeColor' type="range" min="1" max="10" value={this.state.spacing} style={{ width: '75%', cursor: 'pointer' }} onChange={(e) => {
                                                    let spacing = e.target.value
                                                    this.setState((state) => ({
                                                        ...state,
                                                        spacing: Number(spacing)
                                                    }), () => {
                                                        this.polygonUpdated(this.state.polygon.leafletLayer)
                                                    })
                                                }} />
                                                <div>
                                                    <img src={pluseIcon} style={{ width: '18px', cursor: 'pointer' }} onClick={() => {
                                                        if (this.state.spacing < 10) {
                                                            this.setState((state) => ({
                                                                ...state,
                                                                spacing: (Number(this.state.spacing) + 1)
                                                            }), () => {
                                                                this.polygonUpdated(this.state.polygon.leafletLayer)
                                                            })
                                                        }
                                                    }} />
                                                </div>
                                            </div>
                                        </div>
                                            : this.state.selectedParameter === 'indentation' ? <div style={{ padding: '2px 10px', }}>
                                                <div style={{ fontFamily: "Poppins", fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "#3C3C3C", textAlign: 'center', marginTop: '15px' }} >Indentation</div>
                                                <div style={{ fontFamily: "Poppins", fontStyle: "normal", fontWeight: "400", fontSize: "13px", color: "#3C3C3C", textAlign: 'center', marginTop: '10px' }} >Adjust space around plan inside the boundary</div>
                                                <div style={{ textAlign: 'center', margin: '30px 0px' }}><img src={indentationImg} style={{ width: '80%', }} /></div>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <div style={{ fontFamily: "Poppins", fontStyle: "normal", fontWeight: "400", fontSize: "12px", color: "#3C3C3C" }}>Indentation</div>
                                                    <div style={{ fontFamily: "Poppins", fontStyle: "normal", fontWeight: "400", fontSize: "12px", color: "#3C3C3C" }}>
                                                        <span style={{ color: '#2989CF', fontSize: '18px', fontWeight: '500' }}>{this.state.boundryIndentation}</span> m</div>
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0px', alignItems: 'center' }}>
                                                    <div>
                                                        <img src={minusIcon} style={{ width: '18px', cursor: 'pointer' }} onClick={() => {
                                                            if (this.state.boundryIndentation > 0) {
                                                                this.setState((state) => ({
                                                                    ...state,
                                                                    prevBoundryIndentation: Number(this.state.boundryIndentation),
                                                                    boundryIndentation: (Number(this.state.boundryIndentation) - 1),
                                                                }), () => {
                                                                    this.addIndetationForSelectedLine()
                                                                })
                                                            }
                                                        }} />
                                                    </div>
                                                    <input className='rangeColor' type="range" min="0" max="10" value={this.state.boundryIndentation} style={{ width: '75%', cursor: 'pointer' }} onChange={(e) => {
                                                        let boundryIndentation = e.target.value
                                                        this.setState((state) => ({
                                                            ...state,
                                                            prevBoundryIndentation: Number(this.state.boundryIndentation),
                                                            boundryIndentation: Number(boundryIndentation)
                                                        }), () => {
                                                            this.addIndetationForSelectedLine()
                                                        })
                                                    }} />
                                                    <div>
                                                        <img src={pluseIcon} style={{ width: '18px', cursor: 'pointer' }} onClick={() => {
                                                            if (this.state.boundryIndentation < 10) {
                                                                this.setState((state) => ({
                                                                    ...state,
                                                                    prevBoundryIndentation: Number(this.state.boundryIndentation),
                                                                    boundryIndentation: (Number(this.state.boundryIndentation) + 1),

                                                                }), () => {
                                                                    this.addIndetationForSelectedLine()
                                                                })
                                                            }
                                                        }} />
                                                    </div>
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', margin: '30px 0px 15px 0px' }}>
                                                    <div
                                                        style={{ background: this.state.nextPrevBtn ? '#3c3c3c' : 'white', width: '60px', height: '25px', border: '1px solid #3c3c3c', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}
                                                        onClick={() => {
                                                            this.setState((state) => ({
                                                                ...state,
                                                                nextPrevBtn: true,
                                                            }), () => {
                                                                this.boundaryIndentation()
                                                            })
                                                        }} >
                                                        <img src={this.state.nextPrevBtn ? prevBtn1 : prevBtn} style={{ width: '18px', height: '15px' }} />
                                                    </div>
                                                    <div
                                                        style={{ background: this.state.nextPrevBtn ? 'white' : '#3c3c3c', width: '60px', height: '25px', border: '1px solid #3c3c3c', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}
                                                        onClick={() => {
                                                            this.setState((state) => ({
                                                                ...state,
                                                                nextPrevBtn: false,
                                                            }), () => {
                                                                this.boundaryIndentation()
                                                            })
                                                        }}>
                                                        <img src={this.state.nextPrevBtn ? nextBtn : nextBtn1} style={{ width: '18px', height: '15px' }} /></div>
                                                </div>
                                            </div>
                                                : this.state.selectedParameter === 'obstacleBoundary' ? <div style={{ padding: '2px 10px', }}>
                                                    <div style={{ fontFamily: "Poppins", fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "#3C3C3C", textAlign: 'center', marginTop: '15px' }} >Obstacle Boundary</div>
                                                    <div style={{ fontFamily: "Poppins", fontStyle: "normal", fontWeight: "400", fontSize: "13px", color: "#3C3C3C", textAlign: 'center', marginTop: '10px' }} >Adjust spacing between obstacle and mission plan</div>
                                                    <div style={{ textAlign: 'center', margin: '30px 0px' }}><img src={obstacleImg} style={{ width: '80%', }} /></div>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <div style={{ fontFamily: "Poppins", fontStyle: "normal", fontWeight: "400", fontSize: "12px", color: "#3C3C3C" }}>Spacing</div>
                                                        <div style={{ fontFamily: "Poppins", fontStyle: "normal", fontWeight: "400", fontSize: "12px", color: "#3C3C3C" }}>
                                                            <span style={{ color: '#2989CF', fontSize: '18px', fontWeight: '500' }}>{this.state.spaceAroundObstacle}</span> m</div>
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0px', alignItems: 'center' }}>
                                                        <div>
                                                            <img src={minusIcon} style={{ width: '18px', cursor: 'pointer' }} onClick={() => {
                                                                if (this.state.spaceAroundObstacle > 0) {
                                                                    this.setState((state) => ({
                                                                        ...state,
                                                                        spaceAroundObstacle: (Number(this.state.spaceAroundObstacle) - 1)
                                                                    }), () => {
                                                                        this.addOffsetObstaclePolygon()
                                                                    })
                                                                }
                                                            }} />
                                                        </div>
                                                        <input className='rangeColor' type="range" min="0" max="10" value={this.state.spaceAroundObstacle} style={{ width: '75%', cursor: 'pointer' }} onChange={(e) => {
                                                            let spaceAroundObstacle = e.target.value
                                                            this.setState((state) => ({
                                                                ...state,
                                                                spaceAroundObstacle: Number(spaceAroundObstacle)
                                                            }), () => {
                                                                this.addOffsetObstaclePolygon()
                                                            })
                                                        }} />
                                                        <div>
                                                            <img src={pluseIcon} style={{ width: '18px', cursor: 'pointer' }} onClick={() => {
                                                                if (this.state.spaceAroundObstacle < 10) {
                                                                    this.setState((state) => ({
                                                                        ...state,
                                                                        spaceAroundObstacle: (Number(this.state.spaceAroundObstacle) + 1)
                                                                    }), () => {
                                                                        this.addOffsetObstaclePolygon()
                                                                    })
                                                                }
                                                            }} />
                                                        </div>
                                                    </div>
                                                </div>
                                                    : this.state.selectedParameter === 'routeAdjust' ? <div style={{ padding: '2px 10px', }}>
                                                        <div style={{ fontFamily: "Poppins", fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "#3C3C3C", textAlign: 'center', marginTop: '15px' }} >Route Adjust</div>
                                                        <div style={{ fontFamily: "Poppins", fontStyle: "normal", fontWeight: "400", fontSize: "13px", color: "#3C3C3C", textAlign: 'center', marginTop: '10px' }} >Adjust the route for plan orientation and location</div>
                                                        <div style={{ textAlign: 'center', margin: '20px 0px' }}>
                                                            <img src={compass} style={{ width: '140px', height: '140px' }} />
                                                            <img src={compassArrow} style={{ width: '45px', height: '55px', position: 'absolute', top: '155px', left: '103px', transform: `rotate(${this.state.turnAroundAngle}deg)` }} />
                                                        </div>
                                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                            <div style={{ fontFamily: "Poppins", fontStyle: "normal", fontWeight: "400", fontSize: "12px", color: "#3C3C3C" }}>Angle</div>
                                                            <div style={{ fontFamily: "Poppins", fontStyle: "normal", fontWeight: "400", fontSize: "12px", color: "#3C3C3C" }}>
                                                                <span style={{ color: '#2989CF', fontSize: '18px', fontWeight: '500' }}>{this.state.turnAroundAngle}</span>&#176;</div>
                                                        </div>
                                                        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0px', alignItems: 'center' }}>
                                                            <div>
                                                                <img src={minusIcon} style={{ width: '18px', cursor: 'pointer' }} onClick={() => {
                                                                    if (this.state.turnAroundAngle > 0) {
                                                                        this.setState((state) => ({
                                                                            ...state,
                                                                            turnAroundAngle: (Number(this.state.turnAroundAngle) - 1),
                                                                            obsCircleDraw: false,
                                                                            obstacleDraw: false,
                                                                        }), () => {
                                                                            this.polygonUpdated(this.state.polygon.leafletLayer)
                                                                        })
                                                                    }
                                                                }} />
                                                            </div>
                                                            <input className='rangeColor' type="range" min="0" max="360" value={this.state.turnAroundAngle} style={{ width: '75%', cursor: 'pointer' }} onChange={(e) => {
                                                                let turnAroundAngle = e.target.value
                                                                this.setState((state) => ({
                                                                    ...state,
                                                                    turnAroundAngle: Number(turnAroundAngle),
                                                                    obsCircleDraw: false,
                                                                    obstacleDraw: false,
                                                                }), () => {
                                                                    this.polygonUpdated(this.state.polygon.leafletLayer)
                                                                })
                                                            }} />
                                                            <div>
                                                                <img src={pluseIcon} style={{ width: '18px', cursor: 'pointer' }} onClick={() => {
                                                                    if (this.state.turnAroundAngle < 360) {
                                                                        this.setState((state) => ({
                                                                            ...state,
                                                                            turnAroundAngle: (Number(this.state.turnAroundAngle) + 1),
                                                                            obsCircleDraw: false,
                                                                            obstacleDraw: false,
                                                                        }), () => {
                                                                            this.polygonUpdated(this.state.polygon.leafletLayer)
                                                                        })
                                                                    }
                                                                }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                        : <></>}
                                <hr style={{ width: '100%', margin: '5px 0px' }}></hr>
                                <div style={{ padding: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '5px' }}>
                                    <div
                                        className="add-user-form-cancel-button"
                                        style={{ cursor: 'pointer', width: '80px', height: '26px', padding: '2px 10px' }}
                                        onClick={() => {
                                            this.setState({ obstacleDraw: false, obsCircleDraw: false }, () => {
                                                this.polygonUpdated(this.state.polygon.leafletLayer)
                                            })
                                        }}
                                    >
                                        Apply
                                    </div>
                                    <div
                                        className="add-user-form-cancel-button"
                                        style={{ cursor: 'pointer', width: '80px', height: '26px', padding: '2px 10px' }}
                                        onClick={() => {
                                            this.createPlan()
                                        }}
                                    >
                                        Save
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        projectName: state.project.projectName,
        clientName: state.project.clientName,
        planName: state.project.planName,
        dateAndTime: state.project.dateAndTime,
        activeTeamId: state.profile.activeTeamId,
        activeTeam: state.profile.activeTeam,
        isLoggedInUserOwner: state.profile.isLoggedInUserOwner
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        addPlan: (payload) => dispatch(addPlan(payload)),
        addProjectDetails: (payload) => dispatch(addProjectDetails(payload))
    };
};

export default GoogleApiWrapper({
    apiKey: "AIzaSyBuxfUtRZSEUT2QrbZA8zUsG58lZUYYmwI",
    libraries: ["drawing", "places"],
    LoadingContainer: LoadingContainer,
})(connect(mapStateToProps, mapDispatchToProps)(RMPSprayinhFlightPlan));