import React, { Component } from 'react';
import * as API from '../../api.js';
// import './style.css';
import "react-datepicker/dist/react-datepicker.css";
import { BreadCrumbs } from '../../ReusableComponents/BreadCrumbs';
import { addUserAnalytic, Popup, updateOutTime } from '../../ReusableComponents/reusableFunctions';
import { getActiveTeam } from '../../Teams';
import editIcon from '../../icons/hardwareEditIcon.svg'
import Button from '../../ReusableComponents/Button/Button.js';
import { v4 as uuid } from "uuid"
import CreateSelect from '../../ReusableComponents/CreateSelect.js';
import PlusIcon from '../../icons/HardwareplusIcon.svg'
import deleteIcon from '../../icons/HardwareDeleteIcon.svg'
import {
    BATTERY_VOLTAGE,
    CHASSIS_CLASSES, DIMENSION_CATEGORY,
    FC_BOARDS, GPS_INTERFACES, MOTOR_NAMES,
    PART_TYPES, PORPELLER_SIZE, SENSOR_INTERFACE,
    RC_CONNECTIVITY, SENSORS_APPLICATION,
    POWER_MODULE_BATTERY_CELLS
} from "../../AppConstants.js";
import LoaderComponent from '../../ReusableComponents/LoaderComponent.js';


export default class DroneHardwareDetails extends Component {
    constructor(props) {
        super(props);
        this.selectRefs = {};
        this.state = {
            loading: true,
            user: '',
            outId: "",
            editDetails: false,
            drone: '',
            droneDetails: '',
            gpsData: [],
            batteryData: [],
            motorData: [],
            sensorData: [],
            propellerData: {},
            chassisData: {},
            rcData: {},
            fcData: {},
            powerModule: {},
            nozzleData: {},
            overallDimensionData: {},
            dronePartManufacturer: [],
            dronePartTypes: [],
        }
    }

    componentWillUnmount() { updateOutTime(this.state.outId) }

    componentWillMount() {
        document.title = "Drone Hardware Details";
        addUserAnalytic(this.props.location.pathname).then(id => this.setState({ outId: id }))
        getActiveTeam().then(activeTeam => {
            this.setState({ activeTeam }, () => {
                if (this.props.location.state) {
                    let { user, drone } = this.props.location.state;
                    this.setState({ user: user, drone: drone }, async () => {
                        await this.getManufacturer()
                        await this.getPartTypes()
                        this.getDronesData();
                    })
                } else {
                    API.getUserType().then(([blah, user]) => {
                        this.setState({ user }, async () => {
                            await this.getManufacturer()
                            await this.getPartTypes()
                            this.getDronesData();
                        })
                    }, (e) => {
                        window.location.replace("/login");
                    })
                }
            })
        })
    }

    getDronesData = async () => {
        try {
            this.setState({
                loading: true
            }, async () => {
                const result = await API.getGCSDrones({ droneId: this.state.drone.drone_id })
                result.drones.map((drone1, key) => {
                    if (drone1.drone_id == this.state.drone.drone_id) {
                        this.setState({ drone: drone1 }, () => { this.getDroneHardWareDetails() })
                    }
                })
            })
        }
        catch (e) {
            console.error(e)
            Popup.alert('ERROR', e.message || 'Something wents wrong !')
        }
    }

    getDroneHardWareDetails = async () => {
        try {
            this.setState({ loading: true })
            let result = await API.getDroneHardwareDetails(this.state.drone.id);
            if (result.droneDetails.length == 0) {
                this.updateAllStateData(undefined)
                Popup.alert("NOTE", "There is currently no hardware information available. Please click on the 'Edit' option to add or update information.")
            } else {
                this.updateAllStateData(result.droneDetails[0])
            }
        } catch (err) {
            Popup.alert('ERROR', err.message || 'Something wents wrong !')
        }
    }

    updateAllStateData = async (droneDetails) => {
        this.setState({
            droneDetails: droneDetails,
            gpsData: await droneDetails?.gps.map((d, i) => { return { ...d, id: uuid() } }) || [{ id: uuid(), manufacturer_id: "", manufacturer_name: "", gps_name: "", interface: "", }],
            batteryData: await droneDetails?.battery.map((d, i) => { return { ...d, id: uuid() } }) || [{ id: uuid(), manufacturer_id: "", manufacturer_name: "", voltage: "", cells: "", capacity: "", discharge_rate: "", type: "" }],
            motorData: droneDetails?.motor.map((d, i) => { return { ...d, id: uuid() } }) || [{ id: uuid(), manufacturer_id: "", manufacturer_name: "", application: "", name: "", type: "", KVRating: "" }],
            sensorData: droneDetails?.sensor.map((d, i) => { return { ...d, id: uuid() } }) || [{ id: uuid(), manufacturer_id: "", manufacturer_name: "", application: "", type: "", interface: "", flow_meter: "" }],
            propellerData: droneDetails?.propeller || {},
            chassisData: droneDetails?.chassis || {},
            rcData: droneDetails?.rc || {},
            fcData: droneDetails?.fc || {},
            powerModule: droneDetails?.power_module || {},
            nozzleData: droneDetails?.nozzle || {},
            overallDimensionData: droneDetails?.overall_dimession || {},
            loading: false
        })
    }

    getManufacturer = async () => {
        try {
            let result = await API.getDronePartmanufacturer();
            this.setState({ dronePartManufacturer: result.dronePartManufacturers })
        } catch (err) {
            Popup.alert('ERROR', err.message)
        }
    }

    addManufacturer = async (manufacturerName, partType) => {
        try {
            let result = await API.addDronePartManufacturer(manufacturerName, partType)
            const data = [...this.state.dronePartManufacturer, result.manufacture[0]]
            this.setState({ dronePartManufacturer: data })
            return result.manufacture[0]
        } catch (err) {
            Popup.alert("ERROR", err.message)
        }
    }

    getPartTypes = async () => {
        try {
            let result = await API.getDronePartTypes();
            this.setState({ dronePartTypes: result.dronePartTypes })
        } catch (err) {
            Popup.alert('ERROR', err.message)
        }
    }

    createPartType = async (typeName, part) => {
        try {
            let result = await API.addDronePartType(typeName, part)
            const data = [...this.state.dronePartTypes, result.type[0]]
            this.setState({ dronePartTypes: data })
            return result.type[0]
        } catch (err) {
            Popup.alert("ERROR", err.message)
        }
    }

    updateDronePart = async () => {
        try {
            Popup.alert("WAIT", "Please wait...")
            let newJson = {
                ...this.state.droneDetails,
                gps: JSON.stringify(this.state.gpsData),
                battery: JSON.stringify(this.state.batteryData),
                motor: JSON.stringify(this.state.motorData),
                sensor: JSON.stringify(this.state.sensorData),
                propeller: JSON.stringify(this.state.propellerData),
                chassis: JSON.stringify(this.state.chassisData),
                rc: JSON.stringify(this.state.rcData),
                fc: JSON.stringify(this.state.fcData),
                power_module: JSON.stringify(this.state.powerModule),
                nozzle: JSON.stringify(this.state.nozzleData),
                overall_dimession: JSON.stringify(this.state.overallDimensionData)
            }

            let result = await API.updateDronePart(this.state.droneDetails?.id, this.state.drone.id, newJson)
            if (result) {
                this.setState({ editDetails: false })
                Popup.alert("COMPLETE", "Hardware details updated successfully")
                this.getDroneHardWareDetails()
            }
        } catch (err) {
            Popup.alert("ERROR", err.message)
        }
    }

    render() {
        return <div className="wrapper">
            <div className="right-content-new" >
                <div className="top-bar-new">
                    <div className="top-bar-text-new">
                        <BreadCrumbs
                            data={[
                                {
                                    displayName: "AeroGCS",
                                    pathname: "/",
                                    state: {
                                        user: this.state.user
                                    }
                                },
                                {
                                    displayName: 'Drones',
                                    pathname: "/aerogcsenterprise/drones",
                                    state: {
                                        user: this.state.user
                                    }
                                },
                                {
                                    displayName: 'Drone Details',
                                    pathname: "/aerogcsenterprise/drone_details",
                                    state: {
                                        user: this.state.user,
                                        drone: this.state.drone
                                    }
                                },
                                {
                                    displayName: 'Hardware Details',
                                }
                            ]} />
                    </div>
                </div>
                <div className="main-content-new">
                    {this.state.loading ? <LoaderComponent /> : <div className="hardwareDetailsContainer" style={{ marginTop: '15px' }}>
                        <div className='hardwareDetailsSubContainer' style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: '40px' }}>
                            <div className="droneDetailsViewTitle" title={this.state.editDetails ? `Edit Hardware Details` : `Hardware Details`}>{this.state.editDetails ? `Edit Hardware Details` : `Hardware Details`}</div>
                            <div>
                                {this.state.editDetails ? <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: '15px' }}>
                                    <Button text={'Cancel'} className='new-btn-lg' onClick={() => { this.setState({ editDetails: false }, () => this.updateAllStateData(this.state.droneDetails)) }} />
                                    <Button text={'Save Changes'} isBtnActive className='new-btn-lg' onClick={() => { this.updateDronePart() }} />
                                </div> : <img src={editIcon} style={{ cursor: 'pointer' }} onClick={() => { this.setState({ editDetails: true }) }} />}
                            </div>
                        </div>
                        <hr />
                        <div className='scroll-container' style={{ height: 'calc(100% - 80px)', overflow: 'auto', }}>
                            {/* GPS Details */}
                            <div className="hardwareDetailsSubContainer">
                                {this.state.gpsData.map((gps, index) => {
                                    return <div className='hardwareDetailsPartSection'>
                                        <div className="hardwareDetailsTitleDiv">
                                            <div style={{ display: "flex", justifyContent: "left", alignItems: "center", gap: "15px" }}>
                                                <div className="droneDetailsViewSubTitle">GPS {this.state.gpsData.length > 1 && index + 1}</div>
                                                {index == 0 && this.state.editDetails ? <img src={PlusIcon} className="hardwareDetailsAddIcon"
                                                    onClick={() => {
                                                        if (this.state.gpsData.length < 10) {
                                                            this.setState({
                                                                gpsData: [...this.state.gpsData, { id: uuid(), manufacturer_id: "", manufacturer_name: "", gps_name: "", interface: "", }]
                                                            })
                                                        } else { Popup.alert("NOTE", "You have reached the maximum limit of 10 GPS devices.") }
                                                    }} /> : <></>}
                                            </div>
                                            {this.state.editDetails && this.state.gpsData.length > 1 ? <img src={deleteIcon} className="hardwareDetailsDeleteIcon"
                                                onClick={() => {
                                                    if (gps.id) {
                                                        let newGcsData = this.state.gpsData.filter((g, i) => g.id != gps.id)
                                                        this.setState({ gpsData: newGcsData })
                                                    }
                                                }}
                                            /> : <></>}
                                        </div>

                                        <div className="hardwareDetailSection" >
                                            <div className="hardwareDetailsDiv">
                                                <div className="hardwareDetailsLabel" title='Manufacturer'>Manufacturer</div>
                                                {this.state.editDetails ?
                                                    <CreateSelect
                                                        allowCreate={true}
                                                        stopLoading={gps.stopLoading || false}
                                                        options={this.state.dronePartManufacturer?.filter(mfp => mfp.part_type == PART_TYPES.GPS).map(mfp => ({ label: `${mfp.manufacturer}`, value: mfp.id }))}
                                                        value={gps?.manufacturer_name ? { label: gps?.manufacturer_name, value: gps?.manufacturer_id } : null}
                                                        onChange={async (selectedOption) => {
                                                            if (selectedOption) {
                                                                if (selectedOption?.__isNew__) {
                                                                    let newManufacture = await this.addManufacturer(selectedOption.label, PART_TYPES.GPS)
                                                                    if (newManufacture) {
                                                                        this.setState({
                                                                            gpsData: await this.state.gpsData.map((d, i) => {
                                                                                if (d.id == gps.id) return { ...d, manufacturer_name: newManufacture.manufacturer, manufacturer_id: newManufacture.id, stopLoading: gps.stopLoading ? !gps.stopLoading : true }
                                                                                else return d
                                                                            })
                                                                        })
                                                                    }
                                                                } else {
                                                                    this.setState({
                                                                        gpsData: await this.state.gpsData.map((d, i) => {
                                                                            if (d.id == gps.id) return { ...d, manufacturer_name: selectedOption.label, manufacturer_id: selectedOption.value }
                                                                            else return d
                                                                        })
                                                                    })
                                                                }
                                                            } else {
                                                                this.setState({
                                                                    gpsData: await this.state.gpsData.map((d, i) => {
                                                                        if (d.id == gps.id) return { ...d, manufacturer_name: "", manufacturer_id: "" }
                                                                        else return d
                                                                    })
                                                                })
                                                            }
                                                        }}
                                                    />
                                                    : <div className="hardwareDetailsValue" title={gps?.manufacturer_name}> {gps?.manufacturer_name ? gps?.manufacturer_name : "N/A"}</div>}
                                            </div>

                                            <div className="hardwareDetailsDiv">
                                                <div className="hardwareDetailsLabel" title='Name'>Name</div>
                                                {this.state.editDetails ?
                                                    <input
                                                        type="text"
                                                        className="hardwareDetailsInputField"
                                                        value={gps.gps_name || ""}
                                                        placeholder="Gps name"
                                                        onChange={async (e) => {
                                                            if (e.target.value.length <= 30) {
                                                                this.setState({
                                                                    gpsData: await this.state.gpsData.map((d, i) => {
                                                                        if (d.id == gps.id) return { ...d, gps_name: e.target.value }
                                                                        else return d
                                                                    })
                                                                })
                                                            }
                                                        }}
                                                    /> : <div className="hardwareDetailsValue" title={gps?.gps_name || "N/A"}>{gps?.gps_name || "N/A"}</div>}
                                            </div>

                                            <div className="hardwareDetailsDiv">
                                                <div className="hardwareDetailsLabel" title='Interfaces (Communication Ports)'>Interfaces (Communication Ports)</div>
                                                {this.state.editDetails ?
                                                    <CreateSelect
                                                        allowCreate={false}
                                                        options={GPS_INTERFACES.map(e => (
                                                            { label: `${e.label}`, value: e.value }
                                                        ))}
                                                        value={gps?.interface ? { label: gps?.interface, value: gps?.interface } : null}
                                                        onChange={async (selectedOption) => {
                                                            this.setState({
                                                                gpsData: await this.state.gpsData.map((d, i) => {
                                                                    if (d.id == gps.id) return { ...d, interface: selectedOption.label || null }
                                                                    else return d
                                                                })
                                                            })
                                                        }}
                                                    /> : <div className="hardwareDetailsValue" title={gps?.interface || "N/A"}>{gps?.interface || "N/A"}</div>}
                                            </div>
                                            <div className="hardwareDetailsDiv" />
                                        </div>
                                    </div>
                                })}
                            </div>
                            <hr />
                            {/* Battery Details */}
                            <div className="hardwareDetailsSubContainer">
                                {this.state.batteryData.map((battery, index) => {
                                    return <div className='hardwareDetailsPartSection'>
                                        <div className="hardwareDetailsTitleDiv">
                                            <div style={{ display: "flex", justifyContent: "left", alignItems: "center", gap: "15px" }}>
                                                <div className="droneDetailsViewSubTitle">Battery {this.state.batteryData.length > 1 && index + 1}</div>
                                                {index == 0 && this.state.editDetails ? <img src={PlusIcon} className="hardwareDetailsAddIcon"
                                                    onClick={() => {
                                                        if (this.state.batteryData.length < 20) {
                                                            this.setState({
                                                                batteryData: [...this.state.batteryData, { id: uuid(), manufacturer_id: "", manufacturer_name: "", voltage: "", cells: "", capacity: "", discharge_rate: "", type: "" }]
                                                            })
                                                        } else { Popup.alert("NOTE", "You have reached the maximum limit of 20 batteries.") }
                                                    }} /> : <></>}
                                            </div>
                                            {this.state.editDetails && this.state.batteryData.length > 1 ? <img src={deleteIcon} className="hardwareDetailsDeleteIcon"
                                                onClick={() => {
                                                    if (battery.id) {
                                                        let newGcsData = this.state.batteryData.filter((g, i) => g.id != battery.id)
                                                        this.setState({ batteryData: newGcsData })
                                                    }
                                                }}
                                            /> : <></>}
                                        </div>

                                        <div className="hardwareDetailSection" >
                                            <div className="hardwareDetailsDiv">
                                                <div className="hardwareDetailsLabel" title='Manufacturer'>Manufacturer</div>
                                                {this.state.editDetails ?
                                                    <CreateSelect
                                                        allowCreate={true}
                                                        stopLoading={battery.stopLoading || false}
                                                        options={this.state.dronePartManufacturer?.filter(mfp => mfp.part_type == PART_TYPES.BATTERY).map(mfp => ({ label: `${mfp.manufacturer}`, value: mfp.id }))}
                                                        value={battery?.manufacturer_name ? { label: battery?.manufacturer_name, value: battery?.manufacturer_id } : null}
                                                        onChange={async (selectedOption) => {
                                                            if (selectedOption) {
                                                                if (selectedOption?.__isNew__) {
                                                                    let newManufacture = await this.addManufacturer(selectedOption.label, PART_TYPES.BATTERY)
                                                                    if (newManufacture) {
                                                                        this.setState({
                                                                            batteryData: await this.state.batteryData.map((d, i) => {
                                                                                if (d.id == battery.id) return { ...d, manufacturer_name: newManufacture.manufacturer, manufacturer_id: newManufacture.id, stopLoading: battery.stopLoading ? !battery.stopLoading : true }
                                                                                else return d
                                                                            })
                                                                        })
                                                                    }
                                                                } else {
                                                                    this.setState({
                                                                        batteryData: await this.state.batteryData.map((d, i) => {
                                                                            if (d.id == battery.id) return { ...d, manufacturer_name: selectedOption.label, manufacturer_id: selectedOption.value }
                                                                            else return d
                                                                        })
                                                                    })
                                                                }
                                                            } else {
                                                                this.setState({
                                                                    batteryData: await this.state.batteryData.map((d, i) => {
                                                                        if (d.id == battery.id) return { ...d, manufacturer_name: "", manufacturer_id: "" }
                                                                        else return d
                                                                    })
                                                                })
                                                            }
                                                        }}
                                                    /> : <div className="hardwareDetailsValue" title={battery?.manufacturer_name}>
                                                        {battery?.manufacturer_name ? battery?.manufacturer_name : "N/A"}
                                                    </div>}
                                            </div>

                                            <div className="hardwareDetailsDiv">
                                                <div className="hardwareDetailsLabel" title='Type'>Type</div>
                                                {this.state.editDetails ?
                                                    <CreateSelect
                                                        allowCreate={true}
                                                        stopLoading={battery.stopLoadingType || false}
                                                        options={this.state.dronePartTypes?.filter(typ => typ.part == PART_TYPES.BATTERY).map(typ => ({ label: `${typ.type_name}`, value: typ.id }))}
                                                        value={battery?.type ? { label: battery?.type, value: battery?.partTypeId } : null}
                                                        onChange={async (selectedOption) => {
                                                            if (selectedOption) {
                                                                if (selectedOption?.__isNew__) {
                                                                    let newPartType = await this.createPartType(selectedOption.label, PART_TYPES.BATTERY)
                                                                    if (newPartType) {
                                                                        this.setState({
                                                                            batteryData: await this.state.batteryData.map((d, i) => {
                                                                                if (d.id == battery.id) return { ...d, type: newPartType.type_name, partTypeId: newPartType.id, stopLoadingType: battery.stopLoadingType ? !battery.stopLoadingType : true }
                                                                                else return d
                                                                            })
                                                                        })
                                                                    }
                                                                } else {
                                                                    this.setState({
                                                                        batteryData: await this.state.batteryData.map((d, i) => {
                                                                            console.log(battery.id, d.id);

                                                                            if (d.id == battery.id) return { ...d, type: selectedOption.label, partTypeId: selectedOption.value }
                                                                            else return d
                                                                        })
                                                                    })
                                                                }
                                                            } else {
                                                                this.setState({
                                                                    batteryData: await this.state.batteryData.map((d, i) => {
                                                                        if (d.id == battery.id) return { ...d, type: "", partTypeId: "" }
                                                                        else return d
                                                                    })
                                                                })
                                                            }
                                                        }}
                                                    /> : <div className="hardwareDetailsValue" title={battery?.type || "N/A"}>{battery?.type || "N/A"}</div>}
                                            </div>

                                            <div className="hardwareDetailsDiv">
                                                <div className="hardwareDetailsLabel" title='Voltage'>Voltage</div>
                                                {this.state.editDetails ?
                                                    <CreateSelect
                                                        allowCreate={false}
                                                        options={BATTERY_VOLTAGE.map(e => ({ label: `${e.label}`, value: e.value }))}
                                                        onChange={async (selectedOption) => {
                                                            this.setState({
                                                                batteryData: await this.state.batteryData.map((d, i) => {
                                                                    if (d.id == battery.id) return { ...d, voltage: selectedOption.label }
                                                                    else return d
                                                                })
                                                            })
                                                        }}
                                                        value={battery?.voltage ? { label: battery?.voltage, value: battery?.voltage } : null}
                                                    /> : <div className="hardwareDetailsValue" title={`${battery?.voltage || "0"} V`}>{battery?.voltage || "0"} V</div>}
                                            </div>

                                            <div className="hardwareDetailsDiv">
                                                <div className="hardwareDetailsLabel" title='Capacity'>Capacity {this.state.editDetails ? `(mAh)` : ''}</div>
                                                {this.state.editDetails ?
                                                    <CreateSelect
                                                        allowCreate={true}
                                                        stopLoading={battery.stopLoadingCapacity || false}
                                                        options={this.state.dronePartTypes?.filter(typ => typ.part == PART_TYPES.BATTERY_CAPACITY).map(typ => ({ label: `${typ.type_name}`, value: typ.id }))}
                                                        value={battery?.capacity ? { label: battery?.capacity, value: battery?.capacityId } : null}
                                                        onChange={async (selectedOption) => {
                                                            if (selectedOption) {
                                                                if (selectedOption?.__isNew__) {
                                                                    let newPartType = await this.createPartType(selectedOption.label, PART_TYPES.BATTERY_CAPACITY)
                                                                    if (newPartType) {
                                                                        this.setState({
                                                                            batteryData: await this.state.batteryData.map((d, i) => {
                                                                                if (d.id == battery.id) return { ...d, capacity: newPartType.type_name, capacityId: newPartType.id, stopLoadingCapacity: battery.stopLoadingCapacity ? !battery.stopLoadingCapacity : true }
                                                                                else return d
                                                                            })
                                                                        })
                                                                    }
                                                                } else {
                                                                    this.setState({
                                                                        batteryData: await this.state.batteryData.map((d, i) => {
                                                                            console.log(battery.id, d.id);

                                                                            if (d.id == battery.id) return { ...d, capacity: selectedOption.label, capacityId: selectedOption.value }
                                                                            else return d
                                                                        })
                                                                    })
                                                                }
                                                            } else {
                                                                this.setState({
                                                                    batteryData: await this.state.batteryData.map((d, i) => {
                                                                        if (d.id == battery.id) return { ...d, capacity: "", capacityId: "" }
                                                                        else return d
                                                                    })
                                                                })
                                                            }
                                                        }}
                                                    // onChange={async (selectedOption) => {
                                                    //     this.setState({
                                                    //         batteryData: await this.state.batteryData.map((d, i) => {
                                                    //             if (d.id == battery.id) return { ...d, capacity: selectedOption.label }
                                                    //             else return d
                                                    //         })
                                                    //     })
                                                    // }}

                                                    // value={battery?.capacity ? { label: battery?.capacity, value: battery?.capacity } : null}
                                                    /> : <div className="hardwareDetailsValue" title={`${battery?.capacity || "0"} mAh`}>{battery?.capacity || "0"} mAh</div>}
                                            </div>
                                        </div>

                                        <div className="hardwareDetailSection" style={{ marginTop: '25px' }}>
                                            {/* <div className="hardwareDetailsDiv">
                                                <div className="hardwareDetailsLabel" title='Cells'>Cells</div>
                                                {this.state.editDetails ?
                                                    <input
                                                        type="text"
                                                        className="hardwareDetailsInputField"
                                                        value={battery.cells || ""}
                                                        placeholder="Enter cells"
                                                        onChange={async (e) => {
                                                            if (e.target.value.length <= 30) {
                                                                this.setState({
                                                                    batteryData: await this.state.batteryData.map((d, i) => {
                                                                        if (d.id == battery.id) return { ...d, cells: e.target.value }
                                                                        else return d
                                                                    })
                                                                })
                                                            }
                                                        }}
                                                    /> : <div className="hardwareDetailsValue" title={battery?.cells || "N/A"}>{battery?.cells || "N/A"}</div>}
                                            </div> */}
                                            <div className="hardwareDetailsDiv">
                                                <div className="hardwareDetailsLabel" title='Discharge Rate'>Discharge Rate {this.state.editDetails ? `(C)` : ''}</div>
                                                {this.state.editDetails ?
                                                    <input
                                                        type="text"
                                                        className="hardwareDetailsInputField"
                                                        value={battery.discharge_rate || ""}
                                                        placeholder="Enter discharge rate"
                                                        onChange={async (e) => {
                                                            let inputValue = e.target.value;
                                                            const regex = /^[0-9]{1,4}(\.[0-9]{0,3})?$/;
                                                            if (inputValue === '') {
                                                                this.setState({
                                                                    batteryData: await this.state.batteryData.map((d, i) => {
                                                                        if (d.id == battery.id) return { ...d, discharge_rate: '' }
                                                                        else return d
                                                                    })
                                                                })
                                                            } else if (inputValue.startsWith("0") && inputValue.length > 1 && inputValue[1] !== ".") {
                                                                inputValue = inputValue.replace(/^0+/, '');
                                                            } else if (inputValue.charAt(4) == ".") {
                                                                return;
                                                            } else if (regex.test(inputValue) && inputValue.length <= 5) {
                                                                this.setState({
                                                                    batteryData: await this.state.batteryData.map((d, i) => {
                                                                        if (d.id == battery.id) return { ...d, discharge_rate: inputValue }
                                                                        else return d
                                                                    })
                                                                })
                                                            }
                                                        }
                                                        }
                                                    /> : <div className="hardwareDetailsValue" title={`${battery?.discharge_rate || "0"} C`}>{battery?.discharge_rate || "0"} C</div>}
                                            </div>
                                            <div className="hardwareDetailsDiv" />
                                            <div className="hardwareDetailsDiv" />
                                            <div className="hardwareDetailsDiv" />
                                        </div>
                                    </div>
                                })}
                            </div>
                            <hr />
                            {/* Motor Details */}
                            <div className="hardwareDetailsSubContainer">
                                {this.state.motorData.map((motor, index) => {
                                    return <div className='hardwareDetailsPartSection'>
                                        <div className="hardwareDetailsTitleDiv">
                                            <div style={{ display: "flex", justifyContent: "left", alignItems: "center", gap: "15px" }}>
                                                <div className="droneDetailsViewSubTitle">Motor {this.state.motorData.length > 1 && index + 1}</div>
                                                {index == 0 && this.state.editDetails ? <img src={PlusIcon} className="hardwareDetailsAddIcon"
                                                    onClick={() => {
                                                        if (this.state.motorData.length < 10) {
                                                            this.setState({
                                                                motorData: [...this.state.motorData, { id: uuid(), manufacturer_id: "", manufacturer_name: "", application: "", name: "", type: "", KVRating: "" }]
                                                            })
                                                        } else { Popup.alert("NOTE", "You have reached the maximum limit of 10 motor devices.") }
                                                    }} /> : <></>}
                                            </div>
                                            {this.state.editDetails && this.state.motorData.length > 1 ? <img src={deleteIcon} className="hardwareDetailsDeleteIcon"
                                                onClick={() => {
                                                    if (motor.id) {
                                                        let newGcsData = this.state.motorData.filter((g, i) => g.id != motor.id)
                                                        this.setState({ motorData: newGcsData })
                                                    }
                                                }}
                                            /> : <></>}
                                        </div>

                                        <div className="hardwareDetailSection" >
                                            <div className="hardwareDetailsDiv">
                                                <div className="hardwareDetailsLabel" title='Manufacturer'>Manufacturer</div>
                                                {this.state.editDetails ?
                                                    <CreateSelect
                                                        allowCreate={true}
                                                        stopLoading={motor.stopLoading || false}
                                                        options={this.state.dronePartManufacturer?.filter(mfp => mfp.part_type == PART_TYPES.MOTOR).map(mfp => ({ label: `${mfp.manufacturer}`, value: mfp.id }))}
                                                        value={motor?.manufacturer_name ? { label: motor?.manufacturer_name, value: motor?.manufacturer_id } : null}
                                                        onChange={async (selectedOption) => {
                                                            console.log("selectedOption : ", selectedOption);
                                                            if (selectedOption) {
                                                                if (selectedOption?.__isNew__) {
                                                                    let newManufacture = await this.addManufacturer(selectedOption.label, PART_TYPES.MOTOR)
                                                                    if (newManufacture) {
                                                                        this.setState({
                                                                            motorData: await this.state.motorData.map((d, i) => {
                                                                                if (d.id == motor.id) return { ...d, manufacturer_name: newManufacture.manufacturer, manufacturer_id: newManufacture.id, stopLoading: motor.stopLoading ? !motor.stopLoading : true }
                                                                                else return d
                                                                            })
                                                                        })
                                                                    }
                                                                } else {
                                                                    this.setState({
                                                                        motorData: await this.state.motorData.map((d, i) => {
                                                                            if (d.id == motor.id) return { ...d, manufacturer_name: selectedOption.label, manufacturer_id: selectedOption.value }
                                                                            else return d
                                                                        })
                                                                    })
                                                                }
                                                            } else {
                                                                this.setState({
                                                                    motorData: await this.state.motorData.map((d, i) => {
                                                                        if (d.id == motor.id) return { ...d, manufacturer_name: "", manufacturer_id: "" }
                                                                        else return d
                                                                    })
                                                                })
                                                            }
                                                        }}
                                                    /> : <div className="hardwareDetailsValue" title={motor?.manufacturer_name}>
                                                        {motor?.manufacturer_name ? motor?.manufacturer_name : "N/A"}
                                                    </div>}
                                            </div>
                                            <div className="hardwareDetailsDiv">
                                                <div className="hardwareDetailsLabel" title='Name'>Name</div>
                                                {this.state.editDetails ?
                                                    <CreateSelect
                                                        allowCreate={false}
                                                        options={MOTOR_NAMES.map(e => ({ label: `${e.label}`, value: e.value }))}
                                                        onChange={async (selectedOption) => {
                                                            this.setState({
                                                                motorData: await this.state.motorData.map((d, i) => {
                                                                    if (d.id == motor.id) return { ...d, name: selectedOption.label }
                                                                    else return d
                                                                })
                                                            })
                                                        }}
                                                        value={motor?.name ? { label: motor?.name, value: motor?.name } : null}
                                                    /> : <div className="hardwareDetailsValue" title={motor?.name || "N/A"}>{motor?.name || "N/A"}</div>}
                                            </div>

                                            <div className="hardwareDetailsDiv">
                                                <div className="hardwareDetailsLabel" title='Type'>Type</div>
                                                {this.state.editDetails ?
                                                    <CreateSelect
                                                        allowCreate={true}
                                                        stopLoading={motor.stopLoadingType || false}
                                                        options={this.state.dronePartTypes?.filter(typ => typ.part == PART_TYPES.MOTOR).map(typ => ({ label: `${typ.type_name}`, value: typ.id }))}
                                                        onChange={async (selectedOption) => {
                                                            if (selectedOption) {
                                                                if (selectedOption?.__isNew__) {
                                                                    let newPartType = await this.createPartType(selectedOption.label, PART_TYPES.MOTOR)
                                                                    if (newPartType) {
                                                                        this.setState({
                                                                            motorData: await this.state.motorData.map((d, i) => {
                                                                                if (d.id == motor.id) return { ...d, type: newPartType.type_name, partTypeId: newPartType.id, stopLoadingType: motor.stopLoadingType ? !motor.stopLoadingType : true }
                                                                                else return d
                                                                            })
                                                                        })
                                                                    }
                                                                } else {
                                                                    this.setState({
                                                                        motorData: await this.state.motorData.map((d, i) => {
                                                                            if (d.id == motor.id) return { ...d, type: selectedOption.label, partTypeId: selectedOption.value }
                                                                            else return d
                                                                        })

                                                                    })
                                                                }
                                                            } else {
                                                                this.setState({
                                                                    motorData: await this.state.motorData.map((d, i) => {
                                                                        if (d.id == motor.id) return { ...d, type: "", partTypeId: "" }
                                                                        else return d
                                                                    })

                                                                })
                                                            }
                                                        }}
                                                        value={motor?.type ? { label: motor?.type, value: motor?.type } : null}
                                                    /> : <div className="hardwareDetailsValue" title={motor?.type || "N/A"}>{motor?.type || "N/A"}</div>}
                                            </div>
                                            <div className="hardwareDetailsDiv">
                                                <div className="hardwareDetailsLabel" title='KV Rating'>KV Rating {this.state.editDetails && `(Kv)`}</div>
                                                {this.state.editDetails ?
                                                    <input
                                                        type="text"
                                                        className="hardwareDetailsInputField"
                                                        value={motor?.KVRating || ""}
                                                        placeholder="Enter KV Rating"
                                                        onChange={async (e) => {
                                                            let inputValue = e.target.value;
                                                            const regex = /^[0-9]{1,4}(\.[0-9]{0,3})?$/;

                                                            if (inputValue === '') {
                                                                this.setState({
                                                                    motorData: await this.state.motorData.map((d, i) => {
                                                                        if (d.id == motor.id) return { ...d, KVRating: '' }
                                                                        else return d
                                                                    })
                                                                })
                                                                return;
                                                            } else if (inputValue.startsWith("0") && inputValue.length > 1 && inputValue[1] !== ".") {
                                                                inputValue = inputValue.replace(/^0+/, '');
                                                            } else if (inputValue.charAt(4) == ".") {
                                                                return;
                                                            } else if (regex.test(inputValue) && inputValue.length <= 5) {
                                                                this.setState({
                                                                    motorData: await this.state.motorData.map((d, i) => {
                                                                        if (d.id == motor.id) return { ...d, KVRating: inputValue }
                                                                        else return d
                                                                    })
                                                                })
                                                            }

                                                        }}
                                                    /> : <div className="hardwareDetailsValue" title={motor?.KVRating || "0"}>{motor?.KVRating || "0"} Kv</div>}
                                            </div>
                                        </div>
                                    </div>
                                })}
                            </div>
                            <hr />
                            {/* Sensors Details */}
                            <div className="hardwareDetailsSubContainer">
                                {this.state.sensorData.map((sensor, index) => {
                                    return <div className='hardwareDetailsPartSection'>
                                        <div className="hardwareDetailsTitleDiv">
                                            <div style={{ display: "flex", justifyContent: "left", alignItems: "center", gap: "15px" }}>
                                                <div className="droneDetailsViewSubTitle">Sensor {this.state.sensorData.length > 1 && index + 1}</div>
                                                {index == 0 && this.state.editDetails ? <img src={PlusIcon} className="hardwareDetailsAddIcon"
                                                    onClick={() => {
                                                        if (this.state.sensorData.length < 10) {
                                                            this.setState({
                                                                sensorData: [...this.state.sensorData, { id: uuid(), manufacturer_id: "", manufacturer_name: "", application: "", type: "", interface: "", flow_meter: "" }]
                                                            })
                                                        } else { Popup.alert("NOTE", "You have reached the maximum limit of 10 sensors.") }
                                                    }} /> : <></>}
                                            </div>
                                            {this.state.editDetails && this.state.sensorData.length > 1 ? <img src={deleteIcon} className="hardwareDetailsDeleteIcon"
                                                onClick={() => {
                                                    if (sensor.id) {
                                                        let newGcsData = this.state.sensorData.filter((g, i) => g.id != sensor.id)
                                                        this.setState({ sensorData: newGcsData })
                                                    }
                                                }}
                                            /> : <></>}
                                        </div>

                                        <div className="hardwareDetailSection" >
                                            <div className="hardwareDetailsDiv">
                                                <div className="hardwareDetailsLabel" title='Application'>Application</div>
                                                {this.state.editDetails ?
                                                    <CreateSelect
                                                        allowCreate={false}
                                                        options={SENSORS_APPLICATION.map(e => ({ label: `${e.label}`, value: e.value }))}
                                                        value={sensor?.application ? { label: sensor?.application, value: sensor?.manufacturer_id } : null}
                                                        onChange={async (selectedOption) => {
                                                            console.log("selectedOption : ", selectedOption);
                                                            if (selectedOption) {
                                                                this.setState({
                                                                    sensorData: await this.state.sensorData.map((d, i) => {
                                                                        if (d.id == sensor.id) return { ...d, application: selectedOption.label }
                                                                        else return d
                                                                    })
                                                                })
                                                            }
                                                        }}
                                                    /> : <div className="hardwareDetailsValue" title={sensor?.application}>
                                                        {sensor?.application ? sensor?.application : "N/A"}
                                                    </div>}
                                            </div>
                                            <div className="hardwareDetailsDiv">
                                                <div className="hardwareDetailsLabel" title='Type'>Type</div>
                                                {this.state.editDetails ?
                                                    <CreateSelect
                                                        allowCreate={true}
                                                        stopLoading={sensor.stopLoading || false}
                                                        options={this.state.dronePartTypes?.filter(typ => typ.part == PART_TYPES.SENSOR).map(typ => ({ label: `${typ.type_name}`, value: typ.id }))}
                                                        onChange={async (selectedOption) => {
                                                            if (selectedOption) {
                                                                if (selectedOption?.__isNew__) {
                                                                    let newPartType = await this.createPartType(selectedOption.label, PART_TYPES.SENSOR)
                                                                    if (newPartType) {
                                                                        this.setState({
                                                                            sensorData: await this.state.sensorData.map((d, i) => {
                                                                                if (d.id == sensor.id) return { ...d, type: newPartType.type_name, partTypeId: newPartType.id, stopLoading: sensor.stopLoading ? !sensor.stopLoading : true }
                                                                                else return d
                                                                            })
                                                                        })
                                                                    }
                                                                } else {
                                                                    this.setState({
                                                                        sensorData: await this.state.sensorData.map((d, i) => {
                                                                            if (d.id == sensor.id) return { ...d, type: selectedOption.label, partTypeId: selectedOption.value }
                                                                            else return d
                                                                        })
                                                                    })
                                                                }
                                                            } else {
                                                                this.setState({
                                                                    sensorData: await this.state.sensorData.map((d, i) => {
                                                                        if (d.id == sensor.id) return { ...d, type: "", partTypeId: "" }
                                                                        else return d
                                                                    })
                                                                })
                                                            }
                                                        }}
                                                        value={sensor?.type ? { label: sensor?.type, value: sensor?.partTypeId } : null}
                                                    /> : <div className="hardwareDetailsValue" title={sensor?.type || "N/A"}>{sensor?.type || "N/A"}</div>}
                                            </div>

                                            <div className="hardwareDetailsDiv">
                                                <div className="hardwareDetailsLabel" title='Interface'>Interface</div>
                                                {this.state.editDetails ?
                                                    <CreateSelect
                                                        allowCreate={false}
                                                        options={SENSOR_INTERFACE.map(e => ({ label: `${e.label}`, value: e.value }))}
                                                        onChange={async (selectedOption) => {
                                                            this.setState({
                                                                sensorData: await this.state.sensorData.map((d, i) => {
                                                                    if (d.id == sensor.id) return { ...d, interface: selectedOption.label }
                                                                    else return d
                                                                })
                                                            })
                                                        }}
                                                        value={sensor?.interface ? { label: sensor.interface, value: sensor.interface } : null}
                                                    /> : <div className="hardwareDetailsValue" title={sensor?.interface || "N/A"}>{sensor?.interface || "N/A"}</div>}
                                            </div>
                                            <div className="hardwareDetailsDiv" />
                                        </div>
                                        <div className="hardwareDetailSection" style={{ marginTop: '25px' }}>
                                            <div className="hardwareDetailsDiv">
                                                <div className="hardwareDetailsLabel" title='Flow Meter'>Flow Meter</div>
                                                {this.state.editDetails ?
                                                    <input
                                                        type="text"
                                                        className="hardwareDetailsInputField"
                                                        value={sensor?.flow_meter || ""}
                                                        placeholder="Enter Flow Meter"
                                                        onChange={async (e) => {
                                                            if (e.target.value.length <= 30) {
                                                                this.setState({
                                                                    sensorData: await this.state.sensorData.map((d, i) => {
                                                                        if (d.id == sensor.id) return { ...d, flow_meter: e.target.value }
                                                                        else return d
                                                                    })
                                                                })
                                                            }
                                                        }}
                                                    /> : <div className="hardwareDetailsValue" title={sensor?.flow_meter || "N/A"}>{sensor?.flow_meter || "N/A"}</div>}
                                            </div>
                                        </div>
                                    </div>
                                })}
                            </div>
                            <hr />
                            {/* Propeller details */}
                            <div className="hardwareDetailsSubContainer">
                                <div className='hardwareDetailsPartSection'>
                                    <div className="hardwareDetailsTitleDiv">
                                        <div style={{ display: "flex", justifyContent: "left", alignItems: "center", gap: "15px" }}>
                                            <div className="droneDetailsViewSubTitle">Propeller</div>
                                        </div>
                                    </div>

                                    <div className="hardwareDetailSection" >
                                        <div className="hardwareDetailsDiv">
                                            <div className="hardwareDetailsLabel" title='Size'>Size</div>
                                            {this.state.editDetails ?
                                                <CreateSelect
                                                    allowCreate={false}
                                                    options={PORPELLER_SIZE}
                                                    value={this.state.propellerData?.size ? { label: this.state.propellerData?.size, value: this.state.propellerData?.size } : null}
                                                    onChange={async (selectedOption) => {
                                                        if (selectedOption) {
                                                            this.setState({ propellerData: { ...this.state.propellerData, size: selectedOption.label } })
                                                        }
                                                    }}
                                                /> : <div className="hardwareDetailsValue" title={this.state.propellerData?.size}>
                                                    {this.state.propellerData?.size ? this.state.propellerData?.size : "N/A"}
                                                </div>}
                                        </div>
                                        <div className="hardwareDetailsDiv">
                                            <div className="hardwareDetailsLabel" title='Type'>Type</div>
                                            {this.state.editDetails ?
                                                <CreateSelect
                                                    allowCreate={true}
                                                    stopLoading={this.state.propellerData?.stopLoading || false}
                                                    options={this.state.dronePartTypes?.filter(typ => typ.part == PART_TYPES.PROPELLER).map(typ => ({ label: `${typ.type_name}`, value: typ.id }))}
                                                    onChange={async (selectedOption) => {
                                                        if (selectedOption) {
                                                            if (selectedOption?.__isNew__) {
                                                                let newPartType = await this.createPartType(selectedOption.label, PART_TYPES.PROPELLER)
                                                                if (newPartType) {
                                                                    this.setState({ propellerData: { ...this.state.propellerData, type: newPartType.type_name, partTypeId: newPartType.id, stopLoading: this.state.propellerData?.stopLoading ? !this.state.propellerData?.stopLoading : true } })
                                                                }
                                                            } else {
                                                                this.setState({ propellerData: { ...this.state.propellerData, type: selectedOption.label, partTypeId: selectedOption.value } })
                                                            }
                                                        } else {
                                                            this.setState({ propellerData: { ...this.state.propellerData, type: "", partTypeId: "" } })
                                                        }
                                                    }}
                                                    value={this.state.propellerData?.type ? { label: this.state.propellerData?.type, value: this.state.propellerData?.partTypeId } : null}
                                                /> : <div className="hardwareDetailsValue" title={this.state.propellerData?.type || "N/A"}>{this.state.propellerData?.type || "N/A"}</div>}
                                        </div>

                                        <div className="hardwareDetailsDiv" />
                                        <div className="hardwareDetailsDiv" />
                                    </div>
                                </div>
                            </div>
                            <hr />
                            {/* Chassis  details*/}
                            <div className="hardwareDetailsSubContainer">
                                <div className='hardwareDetailsPartSection'>
                                    <div className="hardwareDetailsTitleDiv">
                                        <div style={{ display: "flex", justifyContent: "left", alignItems: "center", gap: "15px" }}>
                                            <div className="droneDetailsViewSubTitle">Chassis</div>
                                        </div>
                                    </div>

                                    <div className="hardwareDetailSection" >
                                        <div className="hardwareDetailsDiv">
                                            <div className="hardwareDetailsLabel" title='Manufacturer'>Manufacturer</div>
                                            {this.state.editDetails ?
                                                <CreateSelect
                                                    allowCreate={true}
                                                    stopLoading={this.state.chassisData?.stopLoading || false}
                                                    options={this.state.dronePartManufacturer?.filter(mfp => mfp.part_type === PART_TYPES.CHASSIS).map(mfp => ({ label: `${mfp.manufacturer}`, value: mfp.id, }))}
                                                    value={this.state.chassisData?.manufacturer_id ? { label: this.state.chassisData?.manufacturer_name, value: this.state.chassisData?.manufacturer_id, } : null}
                                                    onChange={async (selectedOption) => {
                                                        if (selectedOption) {
                                                            if (selectedOption?.__isNew__) {
                                                                let newManufacture = await this.addManufacturer(selectedOption.label, PART_TYPES.CHASSIS)
                                                                if (newManufacture) {
                                                                    this.setState({ chassisData: { ...this.state.chassisData, manufacturer_name: newManufacture.manufacturer, manufacturer_id: newManufacture.id, stopLoading: this.state.chassisData?.stopLoading ? !this.state.chassisData?.stopLoading : true } })
                                                                }
                                                            } else {
                                                                this.setState({ chassisData: { ...this.state.chassisData, manufacturer_name: selectedOption.label, manufacturer_id: selectedOption.value } })
                                                            }
                                                        } else {
                                                            this.setState({ chassisData: { ...this.state.chassisData, manufacturer_name: "", manufacturer_id: "" } })
                                                        }
                                                    }}
                                                /> : <div className="hardwareDetailsValue" title={this.state.chassisData?.manufacturer_name}>
                                                    {this.state.chassisData?.manufacturer_name ? this.state.chassisData?.manufacturer_name : "N/A"}
                                                </div>}
                                        </div>
                                        <div className="hardwareDetailsDiv">
                                            <div className="hardwareDetailsLabel" title='Type'>Type</div>
                                            {this.state.editDetails ?
                                                <CreateSelect
                                                    allowCreate={true}
                                                    stopLoading={this.state.chassisData?.stopLoadingType || false}
                                                    options={this.state.dronePartTypes?.filter(typ => typ.part == PART_TYPES.CHASSIS).map(typ => ({ label: `${typ.type_name}`, value: typ.id }))}
                                                    onChange={async (selectedOption) => {
                                                        if (selectedOption) {
                                                            if (selectedOption?.__isNew__) {
                                                                let newPartType = await this.createPartType(selectedOption.label, PART_TYPES.CHASSIS)
                                                                if (newPartType) {
                                                                    this.setState({ chassisData: { ...this.state.chassisData, type: newPartType.type_name, partTypeId: newPartType.id, stopLoadingType: this.state.chassisData?.stopLoadingType ? !this.state.chassisData?.stopLoadingType : true } })
                                                                }
                                                            } else {
                                                                this.setState({ chassisData: { ...this.state.chassisData, type: selectedOption.label, partTypeId: selectedOption.value } })
                                                            }
                                                        } else {
                                                            this.setState({ chassisData: { ...this.state.chassisData, type: "", partTypeId: "" } })
                                                        }
                                                    }}
                                                    value={this.state.chassisData?.type ? { label: this.state.chassisData?.type, value: this.state.chassisData?.partTypeId } : null}
                                                /> : <div className="hardwareDetailsValue" title={this.state.chassisData?.type || "N/A"}>{this.state.chassisData?.type || "N/A"}</div>}
                                        </div>

                                        <div className="hardwareDetailsDiv">
                                            <div className="hardwareDetailsLabel" title='Class'>Class</div>
                                            {this.state.editDetails ?
                                                <CreateSelect
                                                    allowCreate={false}
                                                    options={CHASSIS_CLASSES}
                                                    onChange={async (selectedOption) => {
                                                        this.setState({ chassisData: { ...this.state.chassisData, class: selectedOption.label } })
                                                    }}
                                                    value={this.state.chassisData?.class ? { label: this.state.chassisData?.class, value: this.state.chassisData?.class, } : null}
                                                /> : <div className="hardwareDetailsValue" title={this.state.chassisData?.class || "N/A"}>{this.state.chassisData?.class || "N/A"}</div>}
                                        </div>
                                        <div className="hardwareDetailsDiv">
                                            <div className="hardwareDetailsLabel" title='Application'>Application</div>
                                            {this.state.editDetails ?
                                                <input
                                                    type="text"
                                                    className="hardwareDetailsInputField"
                                                    value={this.state.chassisData?.application || ""}
                                                    placeholder="Enter Application"
                                                    onChange={async (e) => {
                                                        if (e.target.value.length <= 30) {
                                                            this.setState({ chassisData: { ...this.state.chassisData, application: e.target.value } })
                                                        }
                                                    }}
                                                /> : <div className="hardwareDetailsValue" title={this.state.chassisData?.application || "N/A"}>{this.state.chassisData?.application || "N/A"}</div>}
                                        </div>
                                    </div>

                                    <div className="hardwareDetailSection" style={{ marginTop: '25px' }}>
                                        <div className="hardwareDetailsDiv">
                                            <div className="hardwareDetailsLabel" title='Category'>Category</div>
                                            {this.state.editDetails ?
                                                <input
                                                    type="text"
                                                    className="hardwareDetailsInputField"
                                                    value={this.state.chassisData?.category || ""}
                                                    placeholder="Enter Category"
                                                    onChange={async (e) => {
                                                        if (e.target.value.length <= 30) {
                                                            this.setState({ chassisData: { ...this.state.chassisData, category: e.target.value } })
                                                        }
                                                    }}
                                                /> : <div className="hardwareDetailsValue" title={this.state.chassisData?.category || "N/A"}>{this.state.chassisData?.category || "N/A"}</div>}
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <hr />
                            {/* RC  details*/}
                            <div className="hardwareDetailsSubContainer">
                                <div className='hardwareDetailsPartSection'>
                                    <div className="hardwareDetailsTitleDiv">
                                        <div style={{ display: "flex", justifyContent: "left", alignItems: "center", gap: "15px" }}>
                                            <div className="droneDetailsViewSubTitle">RC (Remote Controller)</div>
                                        </div>
                                    </div>

                                    <div className="hardwareDetailSection" >
                                        <div className="hardwareDetailsDiv">
                                            <div className="hardwareDetailsLabel" title='Manufacturer'>Manufacturer</div>
                                            {this.state.editDetails ?
                                                <CreateSelect
                                                    allowCreate={true}
                                                    stopLoading={this.state.rcData?.stopLoading || false}
                                                    options={this.state.dronePartManufacturer?.filter(mfp => mfp.part_type === PART_TYPES.RC).map(mfp => ({ label: `${mfp.manufacturer}`, value: mfp.id }))}
                                                    value={this.state.rcData?.manufacturer_id ? { label: this.state.rcData?.manufacturer_name, value: this.state.rcData?.manufacturer_id, } : null}
                                                    onChange={async (selectedOption) => {
                                                        if (selectedOption) {
                                                            if (selectedOption?.__isNew__) {
                                                                let newManufacture = await this.addManufacturer(selectedOption.label, PART_TYPES.RC)
                                                                if (newManufacture) {
                                                                    this.setState({ rcData: { ...this.state.rcData, manufacturer_name: newManufacture.manufacturer, manufacturer_id: newManufacture.id, stopLoading: this.state.rcData?.stopLoading ? !this.state.rcData?.stopLoading : true } })
                                                                }
                                                            } else {
                                                                this.setState({ rcData: { ...this.state.rcData, manufacturer_name: selectedOption.label, manufacturer_id: selectedOption.value } })
                                                            }
                                                        } else {
                                                            this.setState({ rcData: { ...this.state.rcData, manufacturer_name: "", manufacturer_id: "" } })
                                                        }
                                                    }}
                                                /> : <div className="hardwareDetailsValue" title={this.state.rcData?.manufacturer_name}>
                                                    {this.state.rcData?.manufacturer_name ? this.state.rcData?.manufacturer_name : "N/A"}
                                                </div>}
                                        </div>
                                        <div className="hardwareDetailsDiv">
                                            <div className="hardwareDetailsLabel" title='Type'>Type</div>
                                            {this.state.editDetails ?
                                                <CreateSelect
                                                    allowCreate={true}
                                                    stopLoading={this.state.rcData?.stopLoadingType || false}
                                                    options={this.state.dronePartTypes?.filter(typ => typ.part == PART_TYPES.RC).map(typ => ({ label: `${typ.type_name}`, value: typ.id }))}
                                                    onChange={async (selectedOption) => {
                                                        if (selectedOption) {
                                                            if (selectedOption?.__isNew__) {
                                                                let newPartType = await this.createPartType(selectedOption.label, PART_TYPES.RC)
                                                                if (newPartType) {
                                                                    this.setState({ rcData: { ...this.state.rcData, type: newPartType.type_name, partTypeId: newPartType.id, stopLoadingType: this.state.rcData?.stopLoadingType ? !this.state.rcData?.stopLoadingType : true } })
                                                                }
                                                            } else {
                                                                this.setState({ rcData: { ...this.state.rcData, type: selectedOption.label, partTypeId: selectedOption.value } })
                                                            }
                                                        } else {
                                                            this.setState({ rcData: { ...this.state.rcData, type: "", partTypeId: "" } })
                                                        }
                                                    }}
                                                    value={this.state.rcData?.type ? { label: this.state.rcData?.type, value: this.state.rcData?.partTypeId } : null}
                                                /> : <div className="hardwareDetailsValue" title={this.state.rcData?.type || "N/A"}>{this.state.rcData?.type || "N/A"}</div>}
                                        </div>

                                        <div className="hardwareDetailsDiv">
                                            <div className="hardwareDetailsLabel" title='Connectivity'>Connectivity</div>
                                            {this.state.editDetails ?
                                                <CreateSelect
                                                    allowCreate={false}
                                                    options={RC_CONNECTIVITY}
                                                    onChange={async (selectedOption) => {
                                                        this.setState({ rcData: { ...this.state.rcData, connectivity: selectedOption.label } })
                                                    }}
                                                    value={this.state.rcData?.connectivity ? { label: this.state.rcData?.connectivity, value: this.state.rcData?.connectivity, } : null}
                                                /> : <div className="hardwareDetailsValue" title={this.state.rcData?.connectivity || "N/A"}>{this.state.rcData?.connectivity || "N/A"}</div>}
                                        </div>
                                        <div className="hardwareDetailsDiv" />
                                    </div>
                                </div>

                            </div>
                            <hr />
                            {/* FC  details*/}
                            <div className="hardwareDetailsSubContainer">
                                <div className='hardwareDetailsPartSection'>
                                    <div className="hardwareDetailsTitleDiv">
                                        <div style={{ display: "flex", justifyContent: "left", alignItems: "center", gap: "15px" }}>
                                            <div className="droneDetailsViewSubTitle">FC (Flight Controller)</div>
                                        </div>
                                    </div>

                                    <div className="hardwareDetailSection" >
                                        <div className="hardwareDetailsDiv">
                                            <div className="hardwareDetailsLabel" title='Manufacturer'>Manufacturer</div>
                                            {this.state.editDetails ?
                                                <CreateSelect
                                                    allowCreate={true}
                                                    stopLoading={this.state.fcData?.stopLoading || false}
                                                    options={this.state.dronePartManufacturer?.filter(mfp => mfp.part_type === PART_TYPES.FC).map(mfp => ({ label: `${mfp.manufacturer}`, value: mfp.id }))}
                                                    value={this.state.fcData?.manufacturer_id ? { label: this.state.fcData?.manufacturer_name, value: this.state.fcData?.manufacturer_id, } : null}
                                                    onChange={async (selectedOption) => {
                                                        if (selectedOption) {
                                                            if (selectedOption?.__isNew__) {
                                                                let newManufacture = await this.addManufacturer(selectedOption.label, PART_TYPES.FC)
                                                                if (newManufacture) {
                                                                    this.setState({ fcData: { ...this.state.fcData, manufacturer_name: newManufacture.manufacturer, manufacturer_id: newManufacture.id, stopLoading: this.state.fcData?.stopLoading ? !this.state.fcData?.stopLoading : true } })
                                                                }
                                                            } else {
                                                                this.setState({ fcData: { ...this.state.fcData, manufacturer_name: selectedOption.label, manufacturer_id: selectedOption.value } })
                                                            }
                                                        } else {
                                                            this.setState({ fcData: { ...this.state.fcData, manufacturer_name: "", manufacturer_id: "" } })
                                                        }
                                                    }}
                                                /> : <div className="hardwareDetailsValue" title={this.state.fcData?.manufacturer_name}>
                                                    {this.state.fcData?.manufacturer_name ? this.state.fcData?.manufacturer_name : "N/A"}
                                                </div>}
                                        </div>
                                        <div className="hardwareDetailsDiv">
                                            <div className="hardwareDetailsLabel" title='Board'>Board</div>
                                            {this.state.editDetails ?
                                                <CreateSelect
                                                    allowCreate={false}
                                                    options={FC_BOARDS}
                                                    onChange={async (selectedOption) => {
                                                        this.setState({ fcData: { ...this.state.fcData, board: selectedOption.label } })
                                                    }}
                                                    value={this.state.fcData?.board ? { label: this.state.fcData?.board, value: this.state.fcData?.board, } : null}
                                                /> : <div className="hardwareDetailsValue" title={this.state.fcData?.board || "N/A"}>{this.state.fcData?.board || "N/A"}</div>}
                                        </div>

                                        <div className="hardwareDetailsDiv" />
                                        <div className="hardwareDetailsDiv" />
                                    </div>
                                </div>

                            </div>
                            <hr />
                            {/* Power Module */}
                            <div className="hardwareDetailsSubContainer">
                                <div className='hardwareDetailsPartSection'>
                                    <div className="hardwareDetailsTitleDiv">
                                        <div style={{ display: "flex", justifyContent: "left", alignItems: "center", gap: "15px" }}>
                                            <div className="droneDetailsViewSubTitle">Power Module</div>
                                        </div>
                                    </div>

                                    <div className="hardwareDetailSection" >
                                        <div className="hardwareDetailsDiv">
                                            <div className="hardwareDetailsLabel" title='Manufacturer'>Manufacturer</div>
                                            {this.state.editDetails ?
                                                <CreateSelect
                                                    allowCreate={true}
                                                    stopLoading={this.state.powerModule?.stopLoading || false}
                                                    options={this.state.dronePartManufacturer?.filter(mfp => mfp.part_type === PART_TYPES.POWER_MODULE).map(mfp => ({ label: `${mfp.manufacturer}`, value: mfp.id }))}
                                                    value={this.state.powerModule?.manufacturer_id ? { label: this.state.powerModule?.manufacturer_name, value: this.state.powerModule?.manufacturer_id, } : null}
                                                    onChange={async (selectedOption) => {
                                                        if (selectedOption) {
                                                            if (selectedOption?.__isNew__) {
                                                                let newManufacture = await this.addManufacturer(selectedOption.label, PART_TYPES.POWER_MODULE)
                                                                if (newManufacture) {
                                                                    this.setState({ powerModule: { ...this.state.powerModule, manufacturer_name: newManufacture.manufacturer, manufacturer_id: newManufacture.id, stopLoading: this.state.powerModule?.stopLoading ? !this.state.powerModule?.stopLoading : true } })
                                                                }
                                                            } else {
                                                                this.setState({ powerModule: { ...this.state.powerModule, manufacturer_name: selectedOption.label, manufacturer_id: selectedOption.value } })
                                                            }
                                                        } else {
                                                            this.setState({ powerModule: { ...this.state.powerModule, manufacturer_name: "", manufacturer_id: "" } })
                                                        }
                                                    }}
                                                /> : <div className="hardwareDetailsValue" title={this.state.powerModule?.manufacturer_name}>
                                                    {this.state.powerModule?.manufacturer_name ? this.state.powerModule?.manufacturer_name : "N/A"}
                                                </div>}
                                        </div>
                                        <div className="hardwareDetailsDiv">
                                            <div className="hardwareDetailsLabel" title={`Input Voltage ${this.state.editDetails && `(V)`}`}>Input Voltage {this.state.editDetails && `(V)`}</div>
                                            {this.state.editDetails ?
                                                <input
                                                    type="text"
                                                    className="hardwareDetailsInputField"
                                                    value={this.state.powerModule?.voltage || ""}
                                                    placeholder="Enter voltage"
                                                    min="0"
                                                    max="999"
                                                    step="0.1"
                                                    onChange={async (e) => {
                                                        let inputValue = e.target.value;

                                                        const regex = /^[0-9]{1,4}(\.[0-9]{0,3})?$/;

                                                        if (inputValue === '') {
                                                            this.setState({ powerModule: { ...this.state.powerModule, voltage: '' } })
                                                            return;
                                                        } else if (inputValue.startsWith("0") && inputValue.length > 1 && inputValue[1] !== ".") {
                                                            inputValue = inputValue.replace(/^0+/, '');
                                                        } else if (inputValue.charAt(4) === ".") {
                                                            return;
                                                        } else if (regex.test(inputValue) && inputValue.length <= 5) {
                                                            this.setState({ powerModule: { ...this.state.powerModule, voltage: inputValue } })
                                                        }

                                                    }}
                                                /> : <div className="hardwareDetailsValue" title={`${this.state.powerModule?.voltage || "0"} V`}>{this.state.powerModule?.voltage || "0"} V</div>}
                                        </div>
                                        <div className="hardwareDetailsDiv">
                                            <div className="hardwareDetailsLabel" title={`Max Current Support ${this.state.editDetails && `(A)`}`}>Max Current Support {this.state.editDetails && `(A)`}</div>
                                            {this.state.editDetails ?
                                                <input
                                                    type="text"
                                                    className="hardwareDetailsInputField"
                                                    min="0"
                                                    max="999"
                                                    step="0.1"
                                                    value={this.state.powerModule?.max_current_support || ""}
                                                    placeholder="Enter Max Current Support"
                                                    onChange={async (e) => {
                                                        let inputValue = e.target.value;

                                                        const regex = /^[0-9]{1,4}(\.[0-9]{0,3})?$/;

                                                        if (inputValue === '') {
                                                            this.setState({ powerModule: { ...this.state.powerModule, max_current_support: '' } })
                                                            return;
                                                        } else if (inputValue.startsWith("0") && inputValue.length > 1 && inputValue[1] !== ".") {
                                                            inputValue = inputValue.replace(/^0+/, '');
                                                        } else if (inputValue.charAt(4) == ".") {
                                                            return;
                                                        } else if (regex.test(inputValue) && inputValue.length <= 5) {
                                                            this.setState({ powerModule: { ...this.state.powerModule, max_current_support: inputValue } })
                                                        }


                                                    }}
                                                /> : <div className="hardwareDetailsValue" title={`${this.state.powerModule?.max_current_support || "0"} A`}>{this.state.powerModule?.max_current_support || "0"} A</div>}
                                        </div>
                                        <div className="hardwareDetailsDiv">
                                            <div className="hardwareDetailsLabel" title={`Max Voltage Support ${this.state.editDetails && `(V)`}`}>Max Voltage Support {this.state.editDetails && `(V)`}</div>
                                            {this.state.editDetails ?
                                                <input
                                                    type="text"
                                                    className="hardwareDetailsInputField"
                                                    min="0"
                                                    max="999"
                                                    step="0.1"
                                                    value={this.state.powerModule?.max_voltage_support || ""}
                                                    placeholder="Enter Max voltage support"
                                                    onChange={async (e) => {
                                                        let inputValue = e.target.value;

                                                        const regex = /^[0-9]{1,4}(\.[0-9]{0,3})?$/;

                                                        if (inputValue === '') {
                                                            this.setState({ powerModule: { ...this.state.powerModule, max_voltage_support: '' } })
                                                            return;
                                                        } else if (inputValue.startsWith("0") && inputValue.length > 1 && inputValue[1] !== ".") {
                                                            inputValue = inputValue.replace(/^0+/, '');
                                                        } else if (inputValue.charAt(4) == ".") {
                                                            return;
                                                        } else if (regex.test(inputValue) && inputValue.length <= 5) {
                                                            this.setState({ powerModule: { ...this.state.powerModule, max_voltage_support: inputValue } })
                                                        }
                                                    }}
                                                /> : <div className="hardwareDetailsValue" title={`${this.state.powerModule?.max_voltage_support || "0"} V`}>{this.state.powerModule?.max_voltage_support || "0"} V</div>}
                                        </div>
                                    </div>
                                    <div className="hardwareDetailSection" style={{ marginTop: '25px' }}>
                                        <div className="hardwareDetailsDiv">
                                            <div className="hardwareDetailsLabel" title='Battery Cells Support'>Battery Cells Support</div>
                                            {this.state.editDetails ?
                                                <CreateSelect
                                                    allowCreate={false}
                                                    options={POWER_MODULE_BATTERY_CELLS}
                                                    value={this.state.powerModule?.battery_cells_support ? { label: this.state.powerModule?.battery_cells_support, value: this.state.powerModule?.battery_cells_support, } : null}
                                                    onChange={async (selectedOption) => {
                                                        this.setState({ powerModule: { ...this.state.powerModule, battery_cells_support: selectedOption.label } })
                                                    }}
                                                /> : <div className="hardwareDetailsValue" title={this.state.powerModule?.battery_cells_support}>
                                                    {this.state.powerModule?.battery_cells_support ? this.state.powerModule?.battery_cells_support : "N/A"}
                                                </div>}
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <hr />
                            {/* Nozzle */}
                            <div className="hardwareDetailsSubContainer">
                                <div className='hardwareDetailsPartSection'>
                                    <div className="hardwareDetailsTitleDiv">
                                        <div style={{ display: "flex", justifyContent: "left", alignItems: "center", gap: "15px" }}>
                                            <div className="droneDetailsViewSubTitle">Nozzle</div>
                                        </div>
                                    </div>

                                    <div className="hardwareDetailSection" >
                                        <div className="hardwareDetailsDiv">
                                            <div className="hardwareDetailsLabel" title='Manufacturer'>Manufacturer</div>
                                            {this.state.editDetails ?
                                                <CreateSelect
                                                    allowCreate={true}
                                                    stopLoading={this.state.nozzleData?.stopLoading || false}
                                                    options={this.state.dronePartManufacturer?.filter(mfp => mfp.part_type === PART_TYPES.NOZZLE).map(mfp => ({ label: `${mfp.manufacturer}`, value: mfp.id }))}
                                                    value={this.state.nozzleData?.manufacturer_id ? { label: this.state.nozzleData?.manufacturer_name, value: this.state.nozzleData?.manufacturer_id, } : null}
                                                    onChange={async (selectedOption) => {
                                                        if (selectedOption) {
                                                            if (selectedOption?.__isNew__) {
                                                                let newManufacture = await this.addManufacturer(selectedOption.label, PART_TYPES.NOZZLE)
                                                                if (newManufacture) {
                                                                    this.setState({ nozzleData: { ...this.state.nozzleData, manufacturer_name: newManufacture.manufacturer, manufacturer_id: newManufacture.id, stopLoading: this.state.nozzleData?.stopLoading ? !this.state.nozzleData?.stopLoading : true } })
                                                                }
                                                            } else {
                                                                this.setState({ nozzleData: { ...this.state.nozzleData, manufacturer_name: selectedOption.label, manufacturer_id: selectedOption.value } })
                                                            }
                                                        } else {
                                                            this.setState({ nozzleData: { ...this.state.nozzleData, manufacturer_name: "", manufacturer_id: "" } })
                                                        }
                                                    }}
                                                /> : <div className="hardwareDetailsValue" title={this.state.nozzleData?.manufacturer_name}>
                                                    {this.state.nozzleData?.manufacturer_name ? this.state.nozzleData?.manufacturer_name : "N/A"}
                                                </div>}
                                        </div>
                                        <div className="hardwareDetailsDiv">
                                            <div className="hardwareDetailsLabel" title='Type'>Type</div>
                                            {this.state.editDetails ?
                                                <CreateSelect
                                                    allowCreate={true}
                                                    stopLoading={this.state.nozzleData?.stopLoadingType || false}
                                                    options={this.state.dronePartTypes?.filter(typ => typ.part == PART_TYPES.NOZZLE).map(typ => ({ label: `${typ.type_name}`, value: typ.id }))}
                                                    onChange={async (selectedOption) => {
                                                        if (selectedOption) {
                                                            if (selectedOption?.__isNew__) {
                                                                let newPartType = await this.createPartType(selectedOption.label, PART_TYPES.NOZZLE)
                                                                if (newPartType) {
                                                                    this.setState({ nozzleData: { ...this.state.nozzleData, type: newPartType.type_name, partTypeId: newPartType.id, stopLoadingType: this.state.nozzleData?.stopLoadingType ? !this.state.nozzleData?.stopLoadingType : true } })
                                                                }
                                                            } else {
                                                                this.setState({ nozzleData: { ...this.state.nozzleData, type: selectedOption.label, partTypeId: selectedOption.value } })
                                                            }
                                                        } else {
                                                            this.setState({ nozzleData: { ...this.state.nozzleData, type: "", partTypeId: "" } })
                                                        }
                                                    }}
                                                    value={this.state.nozzleData?.type ? { label: this.state.nozzleData?.type, value: this.state.nozzleData?.partTypeId } : null}
                                                /> : <div className="hardwareDetailsValue" title={this.state.nozzleData?.type || "N/A"}>{this.state.nozzleData?.type || "N/A"}</div>}
                                        </div>
                                        <div className="hardwareDetailsDiv">
                                            <div className="hardwareDetailsLabel" title={`Radius ${this.state.editDetails && `(Cm)`}`}>Radius {this.state.editDetails && `(Cm)`}</div>
                                            {this.state.editDetails ?
                                                <input
                                                    type="text"
                                                    className="hardwareDetailsInputField"
                                                    min="0"
                                                    max="999"
                                                    step="0.1"
                                                    value={this.state.nozzleData?.radius || ""}
                                                    placeholder="Enter Radius"
                                                    onChange={async (e) => {
                                                        let inputValue = e.target.value;

                                                        const regex = /^[0-9]{1,4}(\.[0-9]{0,3})?$/;

                                                        if (inputValue === '') {
                                                            this.setState({ nozzleData: { ...this.state.nozzleData, radius: '' } })
                                                            return;
                                                        } else if (inputValue.startsWith("0") && inputValue.length > 1 && inputValue[1] !== ".") {
                                                            inputValue = inputValue.replace(/^0+/, '');
                                                        } else if (inputValue.charAt(4) == ".") {
                                                            return;
                                                        } else if (regex.test(inputValue) && inputValue.length <= 5) {
                                                            this.setState({ nozzleData: { ...this.state.nozzleData, radius: inputValue } })
                                                        }

                                                    }}
                                                /> : <div className="hardwareDetailsValue" title={`${this.state.nozzleData?.radius || "0"} Cm`}>{this.state.nozzleData?.radius || "0"} Cm</div>}
                                        </div>
                                        <div className="hardwareDetailsDiv">
                                            <div className="hardwareDetailsLabel" title={`Droplet Size ${this.state.editDetails && `(μm)`}`}>Droplet Size {this.state.editDetails && `(μm)`}</div>
                                            {this.state.editDetails ?
                                                <input
                                                    type="text"
                                                    className="hardwareDetailsInputField"
                                                    value={this.state.nozzleData?.dropper_size || ""}
                                                    placeholder="Enter Droplet Size"
                                                    onChange={async (e) => {
                                                        let inputValue = e.target.value;

                                                        const regex = /^[0-9]{1,4}(\.[0-9]{0,3})?$/;

                                                        if (inputValue === '') {
                                                            this.setState({ nozzleData: { ...this.state.nozzleData, dropper_size: '' } })
                                                            return;
                                                        } else if (inputValue.startsWith("0") && inputValue.length > 1 && inputValue[1] !== ".") {
                                                            inputValue = inputValue.replace(/^0+/, '');
                                                        } else if (inputValue.charAt(4) == ".") {
                                                            return;
                                                        } else if (regex.test(inputValue) && inputValue.length <= 5) {
                                                            this.setState({ nozzleData: { ...this.state.nozzleData, dropper_size: inputValue } })
                                                        }
                                                    }}
                                                /> : <div className="hardwareDetailsValue" title={`${this.state.nozzleData?.dropper_size || "0"} μm`}>{this.state.nozzleData?.dropper_size || "0"} μm</div>}
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <hr />
                            {/* Overall Dimension */}
                            <div className="hardwareDetailsSubContainer">
                                <div className='hardwareDetailsPartSection'>
                                    <div className="hardwareDetailsTitleDiv">
                                        <div style={{ display: "flex", justifyContent: "left", alignItems: "center", gap: "15px" }}>
                                            <div className="droneDetailsViewSubTitle">Overall Dimension</div>
                                        </div>
                                    </div>

                                    <div className="hardwareDetailSection" >
                                        <div className="hardwareDetailsDiv">
                                            <div className="hardwareDetailsLabel" title='Category'>Category</div>
                                            {this.state.editDetails ?
                                                <CreateSelect
                                                    allowCreate={false}
                                                    options={DIMENSION_CATEGORY}
                                                    value={this.state.overallDimensionData?.category ? { label: this.state.overallDimensionData?.category, value: this.state.overallDimensionData?.category } : null}
                                                    onChange={async (selectedOption) => {
                                                        this.setState({ overallDimensionData: { ...this.state.overallDimensionData, category: selectedOption.label } })

                                                    }}
                                                /> : <div className="hardwareDetailsValue" title={this.state.overallDimensionData?.category}>
                                                    {this.state.overallDimensionData?.category ? this.state.overallDimensionData?.category : "N/A"}
                                                </div>}
                                        </div>
                                        <div className="hardwareDetailsDiv">
                                            <div className="hardwareDetailsLabel" title={`Weight ${this.state.editDetails && `(Kg)`}`}>Weight {this.state.editDetails && `(Kg)`}</div>
                                            {this.state.editDetails ?
                                                <input
                                                    type="text"
                                                    className="hardwareDetailsInputField"
                                                    step="0.1"
                                                    value={this.state.overallDimensionData?.weight || ""}
                                                    placeholder="Enter Weight"
                                                    onChange={async (e) => {
                                                        let inputValue = e.target.value;

                                                        const regex = /^[0-9]{1,4}(\.[0-9]{0,3})?$/;

                                                        if (inputValue === '') {
                                                            this.setState({ overallDimensionData: { ...this.state.overallDimensionData, weight: '' } })
                                                            return;
                                                        } else if (inputValue.charAt(4) === ".") {
                                                            return;
                                                        } else if (inputValue.startsWith("0") && inputValue.length > 1 && inputValue[1] !== ".") {
                                                            inputValue = inputValue.replace(/^0+/, '');
                                                        } else if (regex.test(inputValue) && inputValue.length <= 5) {
                                                            this.setState({ overallDimensionData: { ...this.state.overallDimensionData, weight: inputValue } })
                                                        }
                                                    }}
                                                /> : <div className="hardwareDetailsValue" title={`${this.state.overallDimensionData?.weight || "0"} Kg`}>{this.state.overallDimensionData?.weight || "0"} Kg</div>}
                                        </div>
                                        <div className="hardwareDetailsDiv" />
                                        <div className="hardwareDetailsDiv" />
                                    </div>
                                </div>
                            </div>
                            {/* <hr /> */}
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    }
}