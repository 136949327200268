import React, { Component } from "react";
import AeroMeghLogo from "./aeromeghLog.png";
import "./style.css";
import Help from "./sidebaricon/Help.png";

import { Link } from "react-router-dom";

export default class SideBar extends Component {
  state = {
    user: {},
    type: "",
    services: [],
    avatarURL: "",
    config: this.props.config,
  };

  componentWillMount() {
    let { services, config } = this.props;
    let { user, type } = config;
    if (user.avatar_url && user.avatar_url.length > 0) {
      this.setState((state) => ({
        ...state,
        avatarURL: `${user.avatar_url}?${user.sas_token
          }&t${new Date().getTime()}`,
      }));
    } else {
      let avatarURL = `/images/${user.first_name?.[0].toLowerCase()}.PNG`;
      this.setState((state) => ({
        ...state,
        avatarURL: avatarURL,
      }));
    }
  }

  render() {
    let { config } = this.props;
    let { user, type } = config;

    return <div className="sidebar-expanded"
      style={{ paddingTop: "0px", backgroundColor: this.props.backgroundColor ? this.props.backgroundColor : 'white', height: this.props.height || "", position: 'absolute', left: '0', top: 0, zIndex: 99 }}  >
      <div style={{ display: "flex", transition: "0.3s", margin: 'auto', alignItems: 'center', justifyContent: 'center' }}>
        <img className='aeromegh-icon' src={AeroMeghLogo} />
      </div>
      <ul className="sidebar-nav" id="sidebar-nav">
        {config.options.map((item) => {
          return <li><Link to={{ pathname: item.path, state: { config, user, type, } }} >
            <div className="sidecontent" id="sidecontent">
              <div className="sidebar-icon"><img className="sidebar-icon" src={item.icon} alt="serviceIcon" /></div>
              {item.selected ? <div name="service" className="sidebaropt"
                style={{ color: this.props.selectedColor ? this.props.selectedColor : "#2989CF" }}
                onClick={this.changeColor}>{item.text}</div>
                : <div style={{ color: this.props.nonSelectedColor ? this.props.nonSelectedColor : '#3c3c3c' }}
                  name="service" className="sidebaropt"
                  onClick={this.changeColor} >{item.text} </div>}
            </div>
          </Link></li>
        })
        }
        {<li> <div className="onhoverext"
            onClick={() => {
              const url = `https://aerogcs-docs.aeromegh.com/aerogcs-kea-enterprise`
              window.open(url, "_blank");
            }} >
            <div className="sidecontent" id="sidecontent" style={{ cursor: "pointer" }} >
              <div className="sidebar-icon"><img className="sidebar-icon" src={Help} alt="serviceIcon" /></div>
              <div name="service" className={"sidebaropt"} style={{ color: this.props.nonSelectedColor ? this.props.nonSelectedColor : '#3c3c3c' }}>{"Help"}</div>
            </div>
          </div> </li>}
      </ul>
    </div>
  }
}