import React, { Component } from "react";
import * as API from "../api.js";
import "../style.css";
import { AnimatedModal } from '../ReusableComponents/AnimatedModal'
import dateFormat from 'dateformat'
import Dropdown from 'react-bootstrap/Dropdown';
import MenuDark from '../icons/MenuDark.png';
import editIcon from "../edit.svg";
import deleteIcon from "../trash.svg";
import { Link, Redirect } from "react-router-dom";
import { ActivityPopup } from '../ReusableComponents/ActivityPopup.js';
import saveImage from '../icons/saveImage.png'
import blackClose from '../icons/blackClose.png'
import Loader from '../icons/loader.svg';
import noLeaseData from '../icons/noLeaseData.png'
import noInvoices from '../icons/noInvoices.png'
import { DeleteComponentTable } from "../ReusableComponents/Menu.js";

export default class leaseDroneDetails extends Component {
  state = {
    user: {},
    type: "",
    deleteRedirect: false,
    drone: {},
    model: {},
    itemName: "",
    showProcessPopup: false,
    processPopupIcon: "",
    processAction: "",
    processMessage: "",
    addLeaseDronePopUp: false,
    usersCustomers: [],
    selectedCustomer: '',
    selectedCustomerName: '',
    fromDate: '',
    toDate: '',
    lease: [],
    edit: false,
    editLease: '',
    minDate: new Date(),
    lease_drone: false,
    leaseActivityPopup: false,
    droneRentEdit: false,
    rentAmount: 0,
    invoices: [],
    invoiceLoading: true,
    leaseLoading: true,
    leaseOnPopUp: false,
    leaseDetailsSelect: {},
    showNumWarning: false,
  };

  getAllInvoices = () => {
    console.log('inget invoices');
    API.getLeaseInvoices(null, this.state.drone.drone_id).then((leaseResult) => {
      console.log("invoice data", leaseResult);
      this.setState(state => ({
        ...state,
        invoices: leaseResult.invoiceData,
        loading: false,
      }), () => {
        this.setState({ invoiceLoading: false })
      })
    }, (e) => {
      console.log(e)
    })
  }


  closeActivityPopup = () => {
    this.setState((state) => ({
      ...state,
      showProcessPopup: false,
      processMessage: null
    }))
  }

  leaseActivityPopup = () => {
    this.setState((state) => ({
      ...state,
      leaseActivityPopup: false,
      processMessage: null
    }))
  }

  addRemoveDroneLease = () => {
    let lease_drone = !(this.state.drone.lease_drone)
    console.log("lease flag", lease_drone);
    API.addRemoveDroneLease(this.state.drone.drone_id, lease_drone).then((result) => {
      console.log('add to lease', result);
      if (result.message == "OK") {
        this.setState((state) => ({
          ...state,
          itemName: "Drone",
          processPopupIcon: "COMPLETE",
          processAction: "UPDATE",
          processMessage: this.state.drone.lease_drone == true ? 'Drone removed from lease sucessfully' : 'Drone added to lease sucessfully',
          leaseActivityPopup: true,
        }), () => {
          this.getDronesData()
        })
      } else {
        this.setState((state) => ({
          ...state,
          itemName: "Drone",
          processPopupIcon: "ERROR",
          processAction: "UPDATE",
          processMessage: result.message,
          leaseActivityPopup: true,
        }))
      }
    }, (e) => {
      this.setState((state) => ({
        ...state,
        itemName: "Drone",
        processPopupIcon: "ERROR",
        processAction: "UPDATE",
        processMessage: 'Something went wrong while adding drone to lease.',
        leaseActivityPopup: true,
      }))
    })

  }

  onChangeRentAmount = (e) => {
    let rentAmount = e.target;
    if (!isNaN(rentAmount.value)) { this.setState({ rentAmount: rentAmount.value, showNumWarning: false, }) }
    else this.setState({ showNumWarning: true })
  }

  updateDroneRent = () => {
    this.setState((state) => ({
      ...state,
      itemName: "Drone",
      processPopupIcon: "WAIT",
      processAction: "UPDATE",
      processMessage: "Updating drone rent  please wait...",
      showProcessPopup: true,
    }), () => {
      API.updateDroneRent(this.state.drone.drone_id, this.state.rentAmount).then((data) => {
        console.log(data);
        if (data.message == "OK") {
          this.setState((state) => ({
            ...state,
            droneRentEdit: false,
            itemName: "Drone",
            processPopupIcon: "COMPLETE",
            processAction: "UPDATE",
            processMessage: 'Drone rent updated sucessfully',
          }), () => {
            this.getDronesData()
          })
        } else {
          this.setState((state) => ({
            ...state,
            itemName: "Drone",
            processPopupIcon: "ERROR",
            processAction: "UPDATE",
            processMessage: data.message,
          }))
        }
      }, (e) => {
        this.setState((state) => ({
          ...state,
          itemName: "Drone",
          processPopupIcon: "ERROR",
          processAction: "UPDATE",
          processMessage: 'Something went wrong while updating drone rent.',
        }))
      })
    })
  }

  addUserCustomerDroneLease = () => {
    this.setState((state) => ({
      ...state,
      addLeaseDronePopUp: false,
      itemName: "Lease",
      processPopupIcon: "WAIT",
      processAction: "ADD",
      processMessage: null,
      showProcessPopup: true,
    }), () => {
      API.addUserCustomerDroneLease(this.state.selectedCustomer.id, this.state.drone.drone_id, this.state.fromDate, this.state.toDate, this.state.drone.drone_rent)
        .then((leaseRes) => {
          console.log('leaseRes', leaseRes);
          if (leaseRes.message == "OK") {
            this.setState((state) => ({
              ...state,
              addLeaseDronePopUp: false,
              itemName: "Lease",
              processPopupIcon: "COMPLETE",
              processAction: "ADD",
              processMessage: 'Lease added sucessfully',
              showProcessPopup: true,
              selectedCustomer: '',
              selectedCustomerName: '',
              fromDate: '',
              toDate: '',
            }), () => {
              this.getAllUserDronesLease()
              this.getDronesData()
            })
          } else {
            this.setState((state) => ({
              ...state,
              addLeaseDronePopUp: false,
              itemName: "Lease",
              processPopupIcon: "ERROR",
              processAction: "ADD",
              processMessage: leaseRes.message,
              showProcessPopup: true,
            }))
          }
        }, (e) => {
          this.setState((state) => ({
            ...state,
            addLeaseDronePopUp: false,
            itemName: "Lease",
            processPopupIcon: "ERROR",
            processAction: "ADD",
            processMessage: 'Something went wrong while adding lease.',
            showProcessPopup: true,
          }))
        })
    })
  }

  updateUserCustomerDroneLease = () => {
    this.setState((state) => ({
      ...state,
      addLeaseDronePopUp: false,
      itemName: "Lease",
      processPopupIcon: "WAIT",
      processAction: "UPDATE",
      processMessage: null,
      showProcessPopup: true,
    }), () => {
      API.updateUserCustomerDroneLease(this.state.fromDate, this.state.toDate, this.state.editLease.id, this.state.editLease.drone_id, this.state.drone.drone_rent)
        .then((leaseRes) => {
          console.log('leaseRes', leaseRes);
          if (leaseRes.message == "OK") {
            this.setState((state) => ({
              ...state,
              addLeaseDronePopUp: false,
              itemName: "Lease",
              processPopupIcon: "COMPLETE",
              processAction: "UPDATE",
              processMessage: 'Lease updated sucessfully',
              showProcessPopup: true,
              selectedCustomer: '',
              selectedCustomerName: '',
              fromDate: '',
              toDate: '',
              edit: false,
              editLease: '',
            }), () => {
              this.getAllUserDronesLease()
              this.getDronesData()
            })
          } else {
            this.setState((state) => ({
              ...state,
              addLeaseDronePopUp: false,
              itemName: "Lease",
              processPopupIcon: "ERROR",
              processAction: "UPDATE",
              processMessage: leaseRes.message,
              showProcessPopup: true,
            }))
          }
        }, (e) => {
          this.setState((state) => ({
            ...state,
            addLeaseDronePopUp: false,
            itemName: "Lease",
            processPopupIcon: "ERROR",
            processAction: "UPDATE",
            processMessage: 'Something went wrong while updating lease.',
            showProcessPopup: true,
          }))
        })
    })
  }

  getAllUserCustomer = () => {
    API.getAllUserCustomer('lease', 0).then((data) => {
      console.log(data);
      if (data.usersCustomers && data.usersCustomers.length > 0) {
        this.setState((state) => ({
          ...state,
          usersCustomers: data.usersCustomers
        }))
      } else {
        this.setState((state) => ({
          ...state,
          usersCustomers: []
        }))
      }
    }, (e) => {
      console.log(e)
    })
  }

  getAllUserDronesLease = () => {
    API.getAllUserDronesLease(null, this.state.drone.drone_id).then((leaseData) => {
      console.log('leaseData', leaseData);
      this.setState(state => ({
        ...state,
        lease: leaseData.usersCustomersDroneLease,
        leaseLoading: false
      }))
    }, (e) => {
      console.log(e)
    })
  }

  deleteUserCustomerDroneLease = () => {
    this.setState((state) => ({
      ...state,
      addCustomerPopUp: false,
      itemName: "Lease",
      processPopupIcon: "WAIT",
      processAction: "ADD",
      processMessage: null,
      showProcessPopup: true,
    }), () => {
      API.deleteUserCustomerDroneLease(this.state.deleteLeaseId).then((deleteRes) => {
        console.log('deleteRes', deleteRes);
        if (deleteRes.message == "OK") {
          this.setState((state) => ({
            ...state,
            addCustomerPopUp: false,
            itemName: "Lease",
            processPopupIcon: "COMPLETE",
            processAction: "DELETE",
            processMessage: 'Lease deleted sucessfully',
            showProcessPopup: true,
            deleteUserCust: '',
            deleteLeaseId: ''
          }), () => {
            this.getAllUserDronesLease()
          })
        } else {
          this.setState((state) => ({
            ...state,
            addCustomerPopUp: false,
            itemName: "Lease",
            processPopupIcon: "ERROR",
            processAction: "DELETE",
            processMessage: deleteRes.message,
            showProcessPopup: true,
          }))
        }
      }, (e) => {
        this.setState((state) => ({
          ...state,
          addCustomerPopUp: false,
          itemName: "Customer",
          processPopupIcon: "ERROR",
          processAction: "DELETE",
          processMessage: 'Something went wrong while deleting lease.',
          showProcessPopup: true,
        }))
      })
    })
  }

  onChangeListenerDrone = (e) => {
    e.preventDefault();
    let key = e.target.value
    console.log(key);
    if (e.target.value == -1) {
      this.setState((state) => ({
        ...state,
        selectedCustomer: {},
        selectedCustomerName: "",
      }))
    } else {
      this.setState((state) => ({
        ...state,
        selectedCustomer: this.state.usersCustomers[key],
        selectedCustomerName: key
      }))
    }
  }

  componentWillMount() {
    if (this.props.location.state) {
      document.title =
        this.props.location.state.drone.drone_id +
        " - Drone Details - Management Services";
      let { user, type, organizationID, drone } = this.props.location.state;
      console.log("ROLE ", type);
      console.log("USER ", user);
      this.setState(
        (state) => ({
          ...state,
          user,
          type,
          drone,
        }),
        () => {
          this.getAllUserCustomer()
          this.getAllUserDronesLease()
          this.getDronesData()
          this.getAllInvoices()
        }
      );
    } else {
      window.location.replace("/login");
      return;
    }
  }

  deleteDroneRedirectComponent = () => {
    if (this.state.deleteRedirect) {
      return (
        <Redirect
          to={{
            pathname: "/drone_management/drones",
            state: {
              user: this.state.user,
              type: this.state.type,
              sidebarMode: this.state.sidebarMode,
            },
          }}
        />
      );
    }
  };

  getDronesData = () => {
    API.gcsDrones().then((result) => {
      console.log('resultdrone', result);
      result.drones.map((drone1, key) => {
        if (drone1.drone_id == this.state.drone.drone_id) {
          this.setState(state => ({
            ...state,
            drone: drone1,
          }))
        }
      })
    }, (e) => {
      console.log(e)
    })
  }

  render() {
    let { user, type, model, drone, sidebarMode } = this.state;

    return user.username ? (
      <div className="wrapper">
        <div
          className="right-content-new">

          <ActivityPopup
            item={this.state.itemName}
            open={this.state.showProcessPopup}
            icon={this.state.processPopupIcon}
            action={this.state.processAction}
            msg={this.state.processMessage}
            close={() => this.closeActivityPopup()}
            onClickOk={() => this.deleteUserCustomerDroneLease()}
          />

          <ActivityPopup
            item={this.state.itemName}
            open={this.state.leaseActivityPopup}
            icon={this.state.processPopupIcon}
            action={this.state.processAction}
            msg={this.state.processMessage}
            close={() => this.leaseActivityPopup()}
            onClickOk={() => this.addRemoveDroneLease()}
          />

          <AnimatedModal
            isOpen={this.state.addLeaseDronePopUp}
            height="350px"
            width="500px"
          >
            <div style={{ width: "100%", height: "100%", padding: '10px 20px' }}>
              <div className="services-content-title"
                style={{
                  margin: "10px 0px",
                  textAlign: "center",
                  color: "#3C3C3C",
                }}
              >
                Add Drone On Lease
              </div>
              <hr style={{ margin: '5px 0px 0px 0px', padding: '0px', width: '100%' }} />
              <div style={{ margin: '15px 20px 0px 20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: '75px', }}>
                <div style={{ textAlign: "left", width: '100%', }}>
                  <div className="content-text">Select Customer</div>
                  <select
                    onChange={(event) => this.onChangeListenerDrone(event)}
                    className="add-user-form-text2"
                    value={this.state.selectedCustomerName}
                    style={{ width: "100%", cursor: 'pointer' }}
                    disabled={this.state.edit}
                  >
                    <option key={-1} value={-1}>Select Customer For Lease</option>
                    {this.state.usersCustomers.map((cust, i) => {
                      return <option key={i} value={i}>{cust.customer_name}</option>
                    })}
                  </select>
                </div>
              </div>
              {this.state.edit ? <></> :
                <div style={{ fontSize: '12px', textAlign: 'left', marginLeft: '22px', color: '#2989CF', marginTop: '-7px' }}>
                  <Link to={{
                    pathname: "/aerogcsenterprise/lease_customers",
                    state: { user: this.state.user, type: this.state.type, addCustomerPopUp: true }
                  }}>Add New Customer</Link>
                </div>}


              <div style={{ margin: '15px 20px 0px 20px', display: 'flex', justifyContent: 'space-between', height: '75px', }}>
                <div style={{ textAlign: "left", width: '45%', }}>
                  <div className="content-text" >Start Date</div>
                  <div>
                    <input
                      id="dateRequired"
                      name="dateRequired"
                      className="add-user-form-text"
                      type="date"
                      max={this.state.toDate ? dateFormat(this.state.toDate, 'yyyy-mm-dd') : '9000-01-01'}
                      min={dateFormat(this.state.minDate, 'yyyy-mm-dd')}
                      defaultValue={this.state.fromDate}
                      onChange={(e) => {
                        let value = e.target.value;
                        this.setState((state) => ({
                          ...state,
                          fromDate: value,
                        }))
                      }}
                      value={this.state.fromDate}
                      style={{
                        height: "auto",
                        borderRadius: '10px',
                        paddingLeft: '5px',
                        width: "auto",
                      }}
                    />
                  </div>
                </div>

                <div style={{ textAlign: "left", width: '45%', }}>
                  <div className="content-text" >End Date</div>
                  <div>
                    <input
                      id="dateRequired"
                      name="dateRequired"
                      defaultValue={this.state.toDate}
                      className="add-user-form-text"
                      type="date"
                      max='9000-01-01'
                      min={dateFormat(this.state.fromDate ? this.state.fromDate : this.state.minDate, 'yyyy-mm-dd')}
                      onChange={(e) => {
                        let value = e.target.value;
                        this.setState((state) => ({
                          ...state,
                          toDate: value,
                        }))
                      }}
                      value={this.state.toDate}
                      style={{
                        height: "auto",
                        borderRadius: '10px',
                        paddingLeft: '5px',
                        width: "auto",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div style={{ display: 'flex', margin: '30px 20px', }}>
                <button
                  className="addcustomer-button-empty-dashboard popup-btn-left"
                  onClick={() => {
                    this.setState((state) => ({
                      ...state,
                      addLeaseDronePopUp: false,
                      selectedCustomer: {},
                      selectedCustomerName: "",
                      fromDate: '',
                      toDate: '',
                      edit: false,
                      editLease: '',
                    }));
                  }}
                >
                  cancel
                </button>
                {this.state.fromDate && this.state.toDate && this.state.selectedCustomerName ? <button
                  className="add-user-form-submit-button popup-btn-right btn-ok"
                  onClick={() => {
                    this.state.edit ? this.updateUserCustomerDroneLease() : this.addUserCustomerDroneLease()
                  }}
                >
                  Save
                </button> : <button style={{ opacity: '0.5', cursor: 'auto' }}
                  className="add-user-form-submit-button popup-btn-right btn-ok">
                  Save
                </button>}
              </div>
            </div>
          </AnimatedModal>

          <div className="top-bar-new">
            <div className="top-bar-text-new">DRONE DETAILS</div>
          </div>
          <div className="main-content-new">
            {/* <SnackBar user={user} sidebarMode={sidebarMode} type={type} /> */}


            <AnimatedModal
              isOpen={this.state.leaseOnPopUp}
              height="400px"
              width="600px"
            >
              <div style={{ width: "100%", height: "100%", padding: '10px 40px' }}>
                <div
                  style={{
                    marginTop: "10px",
                    textAlign: "left",
                    fontSize: "15px",
                    fontWeight: "500",
                    color: "#3C3C3C",
                  }}
                >
                  Lease Details
                </div>
                <hr style={{ margin: '5px 0px 0px 0px', padding: '0px', width: '100%' }} />

                <div style={{ margin: '15px 0px', display: 'flex', width: '100%' }}>
                  <div style={{ width: '60%' }}>
                    <div style={{ textAlign: "left", fontSize: '14px', color: '#666666' }}>Drone Id</div>
                    <div style={{ textAlign: "left", fontSize: '16px', }}>{this.state.leaseDetailsSelect.drone_id}</div>
                  </div>
                  <div style={{ width: '40%' }}>
                    <div style={{ textAlign: "left", fontSize: '14px', color: '#666666' }}>Mob. No.</div>
                    <div style={{ textAlign: "left", fontSize: '16px', }}>{this.state.leaseDetailsSelect.contact_number ? this.state.leaseDetailsSelect.contact_number : '-'}</div>
                  </div>
                </div>

                <div style={{ margin: '15px 0px', display: 'flex', width: '100%' }}>
                  <div style={{ width: '60%' }}>
                    <div style={{ textAlign: "left", fontSize: '14px', color: '#666666' }}>Name</div>
                    <div style={{ textAlign: "left", fontSize: '16px', }}>{this.state.leaseDetailsSelect.customer_name ? this.state.leaseDetailsSelect.customer_name : 'Unknown'}</div>
                  </div>
                  <div style={{ width: '40%' }}>
                    <div style={{ textAlign: "left", fontSize: '14px', color: '#666666' }}>Status</div>
                    <div style={{ textAlign: "left", fontSize: '16px', }}>{this.state.leaseDetailsSelect.status}</div>
                  </div>
                </div>

                <div style={{ margin: '15px 0px', display: 'flex', width: '100%' }}>
                  <div style={{ width: '60%' }}>
                    <div style={{ textAlign: "left", fontSize: '14px', color: '#666666' }}>GST No.</div>
                    <div style={{ textAlign: "left", fontSize: '16px', }}>{this.state.leaseDetailsSelect.gst_no ? this.state.leaseDetailsSelect.gst_no : '-'}</div>
                  </div>
                  <div style={{ width: '40%' }}>
                    <div style={{ textAlign: "left", fontSize: '14px', color: '#666666' }}>Date</div>
                    <div style={{ textAlign: "left", fontSize: '16px', }}>{dateFormat(this.state.leaseDetailsSelect.startdate, 'dd/mm/yyyy')}  To  {dateFormat(this.state.leaseDetailsSelect.enddate, 'dd/mm/yyyy')}</div>
                  </div>
                </div>

                <div style={{ margin: '15px 0px', display: 'flex', width: '100%' }}>
                  <div style={{ width: '100%' }}>
                    <div style={{ textAlign: "left", fontSize: '14px', color: '#666666' }}>Address</div>
                    <div style={{ textAlign: "left", fontSize: '16px', }}>{this.state.leaseDetailsSelect.address ? this.state.leaseDetailsSelect.address : '-'}</div>
                  </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', bottom: '25px', width: '85%', position: 'absolute', justifyContent: 'end', }}>
                  <div className="addcustomer-button-empty-dashboard"
                    style={{ cursor: "pointer", padding: "8px 38px", background: "white", }}
                    onClick={() => {
                      this.setState((state) => ({
                        ...state,
                        leaseOnPopUp: false,
                        leaseDetailsSelect: {},
                        invoiceType: false,
                        withGst: false,
                        totalValue: 0,
                        gst: 18,
                        discount: 0,
                      }));
                    }}
                  >
                    Close
                  </div>
                </div>
              </div>
            </AnimatedModal>

            <div
              className="page"
              style={{
                padding: "0px",
                height: "auto",
                minHeight: "0",
                width: "100%",
                marginTop: '10px'
              }}
            >
              <div
                className="rpas-model-info-top-section"
                style={{ padding: "10px 50px" }}
              >
                <div style={{ width: '100%' }} className="rpas-model-info-primary-details">
                  <div style={{ marginTop: '8px' }} className="rpas-model-info-primary-details-title-row">
                    <div className="services-content-title">
                      {drone.drone_id}
                    </div>
                    <div
                      className="drones-table-actions"
                      style={{ marginRight: "0px" }}
                    >
                      {this.state.user.subs.length == 0 ? (
                        <div
                          className="add-user-form-submit-button"
                          style={{
                            cursor: "default",
                            backgroundColor: "#666666",
                          }}
                        >
                          Lease Drone
                        </div>
                      ) : (
                        <div
                          className="add-user-form-submit-button"
                          onClick={() => {
                            this.setState({
                              addLeaseDronePopUp: true
                            })
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          Lease Drone
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="rpas-model-info-bottom-section"
                style={{ paddingBottom: "10px" }}
              >
                <div style={{ display: 'flex', paddingTop: '10px' }}>
                  <div className="rpas-model-info-primary-details-row">
                    <div className="rpas-model-info-primary-details-title">
                      Drone Name:
                    </div>
                    <div className="rpas-model-info-primary-details-value">
                      {drone.drone_name}
                    </div>
                  </div>
                  <div className="rpas-model-info-primary-details-row">
                    <div className="rpas-model-info-primary-details-title">
                      Registered Date:
                    </div>
                    <div className="rpas-model-info-primary-details-value">
                      {(new Date(drone.registered_date)).toGMTString().split(' ').slice(1, 4).join(' ')}
                    </div>
                  </div>

                </div>

                <div style={{ display: 'flex' }}>
                  <div className="rpas-model-info-primary-details-row">
                    <div className="rpas-model-info-primary-details-title">
                      Drone ID:
                    </div>
                    <div className="rpas-model-info-primary-details-value">
                      {drone.drone_id}
                    </div>
                  </div>

                  <div>
                    <div className="rpas-model-info-primary-details-title">
                      Lease A Drone:
                    </div>
                    <div className="containerToggle">
                      <div className="toggle-switch">
                        <input type="checkbox" className="checkboxToggle" checked={this.state.drone.lease_drone} onChange={() => {
                          this.setState({
                            itemName: "Drone",
                            processPopupIcon: "WARNING",
                            processAction: "ADD",
                            processMessage: this.state.drone.lease_drone == true ? `Are you sure you want to remove this drone from lease ?` : `Are you sure you want to add this drone to lease ?`,
                            leaseActivityPopup: true
                          })
                        }}
                          name={'label'} id={'label'} />
                        <label className="labelToggle" htmlFor={'label'}>
                          <span className="innerToggle" />
                          <span className="switchToggle" />
                        </label>
                      </div>
                    </div>
                  </div>

                </div>
                <div style={{ display: 'flex' }}>
                  <div className="rpas-model-info-primary-details-row">
                    <div className="rpas-model-info-primary-details-title">
                      Drone Per Day Rent:
                    </div>
                    <div className="rpas-model-info-primary-details-value">
                      {this.state.droneRentEdit ?
                        <><div style={{ display: 'flex', alignItems: 'center' }}>
                          <input type='text' placeholder="Drone Per Day Rent" value={this.state.rentAmount} style={{ width: '200px' }} className="add-user-form-text" maxLength={10} onChange={(e) => { this.onChangeRentAmount(e) }} />
                          <img style={{ cursor: 'pointer', marginLeft: '10px', width: '30px', height: '30px' }} src={saveImage} onClick={() => {
                            this.updateDroneRent()
                          }} />
                          <img style={{ cursor: 'pointer', marginLeft: '10px', width: '30px', height: '30px' }} src={blackClose} onClick={() => {
                            this.setState((state) => ({
                              ...state,
                              droneRentEdit: false
                            }))
                          }} />
                        </div>{this.state.showNumWarning && <div style={{ fontSize: "8px", color: "red" }}>Numeric input is required. *</div>} </>
                        : <div style={{ display: 'flex', alignItems: 'center', height: '37px' }}>
                          <div style={{ minWidth: '100px', }} >Rs. {drone.drone_rent}</div>
                          <img style={{ cursor: 'pointer', marginLeft: '10px', width: '22px' }} src={editIcon} onClick={() => {
                            this.setState((state) => ({
                              ...state,
                              droneRentEdit: true,
                              rentAmount: drone.drone_rent,
                            }))
                          }} />
                        </div>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', height: 'calc(100vh - 430px)', width: '100%', overflow: 'hidden', padding: '20px 0px 0px 0px', }}>
              {this.state.leaseLoading ?
                <div style={{ display: 'flex', border: '1px solid #C2C2C2', backgroundColor: "#FAFAFA", alignItems: 'center', borderRadius: "5px", width: '50%', marginTop: '10px', marginRight: '1%' }}>
                  <img src={Loader} style={{ height: "50%", width: "100%", marginLeft: '5%' }} />
                </div> :
                <div style={{ width: '50%', marginRight: '1%' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div className="table-header">Lease</div>
                  </div>
                  <div style={{ border: '1px solid #C2C2C2', background: '#FAFAFA', borderRadius: '5px', marginTop: '10px', height: 'calc(100% - 35px)', overflow: 'auto' }}>
                    {this.state.lease.length ? <table style={{ marginTop: '0px', }} className="customers-table">
                      <col style={{ width: '50%' }} />
                      <col style={{ width: '25%' }} />
                      <col style={{ width: '20%' }} />
                      <col style={{ width: '5%' }} />
                      <tr className="customers-table-header-row">
                        <th>Customer Name</th>
                        <th style={{ textAlign: 'center' }}>Phone Number</th>
                        <th>Status</th>
                        <th></th>
                      </tr>
                      {this.state.lease.map((lease, key) => (
                        <tr className="customers-table-data-row">
                          <td className="leasedData" onClick={() => {
                            this.setState((state) => ({
                              ...state,
                              leaseDetailsSelect: lease,
                            }), () => {
                              this.setState({ leaseOnPopUp: true })
                            })
                          }}>{lease.customer_name ? lease.customer_name.length > 25 ? `${lease.customer_name.substring(0, 24)}...` : lease.customer_name : 'Unknown'}</td>
                          <td style={{ textAlign: 'center' }}>{lease.contact_number ? lease.contact_number : '-'}</td>
                          <td>{lease.status}</td>
                          <td style={{ textAlign: 'right', }}>
                            <DeleteComponentTable
                              style={{ marginRight: '10px' }}
                              canEdit={lease.customer_name}
                              onClickEdit={() => {
                                this.state.usersCustomers.map((cust, i) => {
                                  if (lease.customer_id == cust.id) {
                                    this.setState((state) => ({
                                      ...state,
                                      edit: true,
                                      editLease: lease,
                                      selectedCustomerName: i,
                                      selectedCustomer: cust,
                                      fromDate: dateFormat(lease.startdate, 'yyyy-mm-dd'),
                                      toDate: dateFormat(lease.enddate, 'yyyy-mm-dd'),
                                    }), () => {
                                      this.setState((state) => ({
                                        ...state,
                                        addLeaseDronePopUp: true
                                      }))
                                    })
                                  }
                                })
                              }}
                              onClickDelete={() => {
                                this.setState((state) => ({
                                  ...state,
                                  deleteLeaseId: lease.id
                                }), () => {
                                  this.setState((state) => ({
                                    ...state,
                                    itemName: "Lease",
                                    processPopupIcon: "WARNING",
                                    processAction: "DELETE",
                                    processMessage: <div>All lease data including lease, invoice will be deleted.
                                      <br />Are you sure you want to delete this Lease ?</div>,
                                    showProcessPopup: true,
                                  }))
                                })
                              }}
                            // disableDelete={activities.disableDelete}
                            />
                          </td>
                        </tr>
                      ))}
                    </table> :
                      <div className="empty-data-message" style={{ display: 'flex', flexDirection: 'column', height: '100%', backgroundColor: "#FAFAFA", alignItems: 'center', justifyContent: 'center' }}>
                        <img src={noLeaseData} style={{ height: "60px", width: "60px", }} />
                        <div style={{ marginTop: '20px' }}>No Lease</div>
                      </div>
                    }
                  </div>
                </div>}

              {this.state.invoiceLoading ?
                <div style={{ display: 'flex', border: '1px solid #C2C2C2', backgroundColor: "#FAFAFA", alignItems: 'center', borderRadius: "5px", width: '50%', marginTop: '10px', marginLeft: '1%' }}>
                  <img src={Loader} style={{ height: "50%", width: "100%", marginLeft: '5%' }} />
                </div> :
                <div style={{ width: '50%', marginLeft: '1%' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div className="table-header">Invoices</div>
                  </div>
                  <div style={{ border: '1px solid #C2C2C2', background: '#FAFAFA', borderRadius: '5px', marginTop: '10px', height: 'calc(100% - 35px)', overflow: 'auto' }}>
                    {this.state.invoices.length ? <table className="customers-table" style={{ marginTop: '0px', borderRadius: '5px', }}>
                      <tr className="customers-table-header-row" style={{ zIndex: '0' }}>
                        <th>Invoice Id</th>
                        <th style={{ textAlign: 'center' }}>Date</th>
                        <th>Status</th>
                      </tr>
                      {this.state.invoices.map((invoice, key) => (
                        <tr className="customers-table-data-row">
                          <td>
                            <Link style={{ color: '#3c3c3c' }} to={{
                              pathname: '/aerogcsenterprise/lease_drone_invoice',
                              state: {
                                user: this.state.user,
                                type: this.state.type,
                                lease: invoice.leaseData,
                                withGst: invoice.gstflag,
                                totalValue: invoice.total_amount,
                                gst: invoice.gst,
                                discount: invoice.discount,
                                dailycharges: invoice.dailycharges,
                                paid_status: invoice.paid_status
                              }
                            }}>
                              {invoice.invoiceid}
                            </Link>
                          </td>
                          <td style={{ textAlign: 'center' }}>{dateFormat(invoice.generate_date, "dd/mm/yyyy")}</td>
                          <td>{invoice.paid_status == true ? 'Paid' : 'Unpaid'}</td>
                        </tr>
                      ))}
                    </table> :
                      <div className="empty-data-message" style={{ display: 'flex', flexDirection: 'column', height: '100%', backgroundColor: "#FAFAFA", alignItems: 'center', justifyContent: 'center' }}>
                        <img src={noInvoices} style={{ height: "60px", width: "60px", }} />
                        <div style={{ marginTop: '20px' }}>No Invoices</div>
                      </div>
                    }
                  </div>
                </div>}
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div />
    );
  }
}
