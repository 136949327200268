

import React, { Component } from 'react';
import * as API from './api.js';
import SnackBar from './snackBar.js';
import './style.css';
import Noproject from './assets/Images/Noproject.png';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import { addUserAnalytic, updateOutTime, checkSubscriptionAndStorage } from './ReusableComponents/reusableFunctions.js';
import { SortComponent } from './ReusableComponents/SortComponent';
import { ActivityPopup } from './ReusableComponents/ActivityPopup.js';
import ButtonWithIcon from './ReusableComponents/ButtonWithIcon';
import { getActiveTeam } from './Teams';
import { ManageProjectAccess } from './Teams/ManageProjectAccess';
import { AnimatedModal } from './ReusableComponents/AnimatedModal';
import Autocomplete from "react-google-autocomplete";
import { GoogleApiWrapper } from "google-maps-react";
import SingleMap from "./ReusableComponents/map"
import L from "leaflet";
import MarkerIcon from './assets/marker.png'
import AppConstants from './AppConstants';
import DeleteProject from './ReusableComponents/DeleteProject';
import SubscriptionExpireModal from './subscription/Reusable/SubscriptionExpireModal';
import TableView from "./ReusableComponents/TableView/TableView"
import OverviewBox from './AeroGCSEnterprise/Projects/OverviewBox.js';
import folder from "./icons/folder.svg";
import "./AeroGCSEnterprise/Projects/Project.css";
import { BreadCrumbs } from './ReusableComponents/BreadCrumbs.js';
import LoaderComponent from './ReusableComponents/LoaderComponent.js';
import SearchHeader from './ReusableComponents/SearchHeader/SearchHeader.js';
import addIcon from "./icons/addIcon.svg";
import Button from './ReusableComponents/Button/Button.js';
import TableViewPagination from './ReusableComponents/TableView/TableViewPagination.js';
import { debounnceFn } from '././ReusableComponents/reusableFunctions.js';
import { updateProfileDetails } from './redux'
import Cookies from "universal-cookie";
const cookies = new Cookies();

class Projects extends Component {
    state = {
        activeTeam: undefined,
        user: {},
        type: "",
        loader: true,
        services: [],
        projects: [],
        sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk"),
        showDeletePopup: false,
        allData: [],
        deleteItemType: "",
        deleteLoader: true,
        sortType: 'Last Modified',
        outId: "",
        gridView: false,
        project_name: '',
        error: null,
        itemName: "",
        showProcessPopup: false,
        processPopupIcon: "",
        processAction: "",
        processMessage: "",
        selectedMangeAccessProject: false,
        selectedProjectData: undefined,
        redirect: false,
        projectLocation: '',
        center: { lat: 19.993982, lng: 73.790416 },
        locationName: '',
        showSubscriptionExpirePopup: false,
        filter: {
            searchString: undefined,
            pageSize: 15,
            pageNumber: 0,
            taskType: undefined,
            filterRole: undefined,
            serviceName: AppConstants.SERVICES.AEROGCS,
            fromDate: undefined,
            toDate: undefined,
            orderBy: 'created_date',
            orderType: 'DESC'
        },
        totalCount: 0,
        mainLoader: true,
        debounceSearch: undefined,
        cookieData: undefined
    }


    handleChange = (e) => {
        let format = /[!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?]+/;
        if (format.test(e.target.value)) {
            this.setState({ error: "Spacial characters are not allowed." })
        } else if (e.target.value.includes(' ')) {
            this.setState({ error: "Spaces are not allowed." })
        } else {
            this.setState({ error: null, project_name: e.target.value });
        }
    }

    closeActivityPopup = () => {
        this.setState((state) => ({
            ...state,
            showProcessPopup: false,
            processMessage: null,
        }))
    }

    parentSidebarSwitch = () => {
        this.setState((state) => ({
            ...state,
            sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
        }))
    }

    closePojectPopup = () => {
        this.setState({
            showAddProjectPopup: false,
            project_name: ''
        })
    }

    successRedirectComponent = () => {
        if (this.state.redirect) {
            return <Redirect to={{
                pathname: "/aerogcsenterprise/remote_mission/selectLocation",
                state: { user: this.state.user, type: this.state.type, sidebarMode: this.state.sidebarMode, project: { id: this.state.proejctId, project_name: this.state.project_name }, projectName: this.state.project_name }
            }} />
        }
    }

    createProject = () => {
        this.setState((state) => ({
            ...state,
            itemName: "Project",
            processPopupIcon: "WAIT",
            processAction: "ADD",
            processMessage: null,
            showProcessPopup: true,
        }))
        let project = {
            service: AppConstants.SERVICES.AEROGCS,
            projectDetails: {
                clientName: 'asd',
                "dateTime": new Date().toISOString(),
                flightCompletedCount: 0,
                projectName: this.state.project_name,
                softwareVerison: "1.0",
                locationName: this.state.locationName,
                locationLatLng: this.state.center
            }
        }
        API.createNewProject(project).then((data) => {
            if (data.message == "OK") {
                this.setState((state) => ({
                    ...state,
                    itemName: "Project",
                    processPopupIcon: "COMPLETE",
                    processAction: "ADD",
                    processMessage: null,
                    locationName: '',
                    projectName: '',
                    proejctId: data.project_id,
                    showAddProjectPopup: false,
                }), () => {
                    this.setState({ redirect: true })
                });
            }
            else if (data.error) {
                this.setState((state) => ({
                    ...state,
                    itemName: "Project",
                    processPopupIcon: "ERROR",
                    processAction: "ADD",
                    processMessage: data.error,
                }));
            }
            else {
                this.setState((state) => ({
                    ...state,
                    itemName: "Project",
                    processPopupIcon: "ERROR",
                    processAction: "ADD",
                    processMessage: 'Something went wrong',
                }));
            }
        })

    }


    getProjects = () => {
        this.setState((state) => ({
            ...state,
            loader: true
        }), () => {
            let { searchString, pageSize, pageNumber, orderBy, orderType, taskType, filterRole, serviceName, fromDate, toDate } = this.state.filter;
            API.getAllProjects(searchString || ' ', pageSize, pageNumber, taskType, filterRole, serviceName, fromDate, toDate, orderBy, orderType).then(async (data) => {
                let totalCount = data.count
                data = data.projects
                if (data && data.length == 0) {
                    this.setState((state) => ({
                        ...state,
                        projects: [],
                        loading: false,
                        loader: false,
                        totalCount: 0,
                        mainLoader: false
                    }))
                } else {
                    let projectsData = data && data.length > 0 ? data : []
                    this.setState((state) => ({
                        ...state,
                        projects: projectsData,
                        loading: false,
                        totalCount,
                        loader: false,
                        mainLoader: false
                    }))
                }
            }).catch(err => {
                console.log("error : ", err);
                this.setState({
                    itemName: "Project",
                    processPopupIcon: "ERROR",
                    processMessage: err,
                    showProcessPopup: true,
                    mainLoader: false
                })
            })
        })
    }

    getAllDataToDelete = () => {
        this.setState((state) => ({
            ...state,
            deleteLoader: true,
            showDeletePopup: true,
        }))
        API.getProjectData(this.state.deleteItemType, this.state.selectedProject.project_name, this.state.selectedProject.id).then((data) => {
            let keys = Object.keys(data)
            let alldata = []
            keys = keys.reverse()
            keys.forEach(element => {
                let obj = { "name": element, "count": data[element].length, "processIcon": "", data: data[element] }
                alldata.push(obj)
            });
            this.setState((state) => ({
                ...state,
                allData: data.isEmptyProject ? null : alldata,
                deleteLoader: false
            }))
        }, (e) => {
            console.log(e);
        })
    }

    componentWillUnmount() {
        updateOutTime(this.state.outId)
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.filter.fromDate !== this.state.filter.fromDate || prevState.filter.toDate !== this.state.filter.toDate) this.getProjects();
    }

    componentWillMount() {
        this.state.debounceSearch = debounnceFn(this.getProjects, 700);
        document.title = "Projects - AeroGCS";
        let cookiesData = cookies.get("projects_list")
        getActiveTeam().then((activeTeam) => {
            this.setState({ activeTeam })
            addUserAnalytic(this.props.location.pathname).then(id => { this.setState({ outId: id }) })
            if (!this.props.location.state) {
                API.getUserType().then(([registered, type, user]) => {
                    this.setState(state => ({
                        ...state,
                        loading: false,
                        registered,
                        type,
                        user,
                        filter: { ...this.state.filter, ...cookiesData },
                        gridView: cookiesData ? cookiesData?.selectView == 'grid' : false
                    }), () => {
                        this.getProjects()
                        checkSubscriptionAndStorage(AppConstants.SERVICES.AEROGCS).then(data => {
                            if (data.error || data.storageExceed || data.isExpired || !data.isValidStoragePlan) this.setState({ showSubscriptionExpirePopup: { ...data, user: this.props?.location?.state?.user } });
                            else this.setState({ showSubscriptionExpirePopup: false, infoPopup: true });
                        })
                    })
                }, (e) => {
                    this.setState({
                        user: {},
                        loading: false,
                        type: 'unknown'
                    })
                    window.location.replace("/login");
                })
            } else {
                if (this.props.location.state.config) {
                    this.setState((state) => ({
                        ...state,
                        user: this.props.location.state.config.user,
                        loading: false,
                        type: this.props.location.state.config.type,
                        filter: { ...this.state.filter, ...cookiesData },
                        gridView: cookiesData ? cookiesData?.selectView == 'grid' : false
                    }), () => {
                        this.getProjects()
                        checkSubscriptionAndStorage(AppConstants.SERVICES.AEROGCS).then(data => {
                            if (data.error || data.storageExceed || data.isExpired || !data.isValidStoragePlan) this.setState({ showSubscriptionExpirePopup: { ...data, user: this.props?.location?.state?.user } });
                            else this.setState({ showSubscriptionExpirePopup: false, infoPopup: true });
                        })
                    })
                } else if (this.props.location.state.user) {
                    this.setState((state) => ({
                        ...state,
                        user: this.props.location.state.user,
                        loading: false,
                        type: this.props.location.state.type,
                        filter: { ...this.state.filter, ...cookiesData },
                        gridView: cookiesData ? cookiesData?.selectView == 'grid' : false
                    }), () => {
                        this.getProjects()
                        checkSubscriptionAndStorage(AppConstants.SERVICES.AEROGCS).then(data => {
                            if (data.error || data.storageExceed || data.isExpired || !data.isValidStoragePlan) this.setState({ showSubscriptionExpirePopup: { ...data, user: this.props?.location?.state?.user } });
                            else this.setState({ showSubscriptionExpirePopup: false, infoPopup: true });
                        })

                    })
                }
            }
        })
        this.getOwnerTeamDetails()
    }

    getOwnerTeamDetails = () => {
        API.getTeamsList()
            .then((data) => {
                const { teams } = data;
                this.setState({ ownerTeamDetaisToSwitch: teams.find(team => team.isOwner) })
            })
    }

    onchangePlace = (e) => {
        let val = e.target.value.trim()
        let seperator = val.indexOf(",") != -1 ? ',' : val.indexOf(" ") != -1 ? " " : " "
        let latlng = val.trim().split(seperator)
        if (latlng.length == 2) {
            this.setState({ center: { lat: latlng[0], lng: latlng[1] } })
        }
    }

    handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            this.state.marker.setLatLng(this.state.center);
        }
    }

    changeMarkerPosition = (mark) => {
        this.state.map.setView(this.state.center);
        this.state.marker.setLatLng(mark);
    }

    handleMapClick = (e) => {
        console.log(e)
        let center = { lat: e.latlng.lat, lng: e.latlng.lng }
        this.state.marker.setLatLng(center);
        this.state.map.setView(center);
        this.setState({ center })
    }

    initMap = () => {
        let { map, center } = this.state
        var markerOptions = {
            title: "MyLocation",
            clickable: true,
            draggable: false,
            icon: new L.Icon({ iconUrl: MarkerIcon, iconSize: [20, 28], iconAnchor: [10, 28] })
        }
        let marker = new L.Marker(center, markerOptions).addTo(map)
        this.state.map.setView(center, 17);
        this.setState({ marker })
    };
    render() {
        let { user, type, projects, sidebarMode } = this.state
        const CustomSortToggle = React.forwardRef(({ children, onClick }, ref) => (
            <a
                href=""
                ref={ref}
                onClick={e => {
                    e.preventDefault();
                    onClick(e);
                }}
            >
                {children}
                <div className='sort-type-style'>{this.state.sortType}</div>
            </a>
        ));

        return (
            user.username ?
                <div className="wrapper">
                    <ManageProjectAccess
                        projectId={this.state.selectedMangeAccessProject}
                        closeManageAccess={() => {
                            this.setState({
                                selectedMangeAccessProject: false,
                                selectedProjectData: undefined
                            })
                        }}
                        senderData={user}
                        propsData={this.state.selectedProjectData}
                    />
                    <div className="right-content-new"
                        onClick={
                            () => {
                                this.setState((state) => ({
                                    ...state, sidebarMode: 'shrunk'
                                }))
                            }
                        }
                    >
                        {this.successRedirectComponent()}

                        <div className="top-bar-new">
                            <div className="top-bar-text-new">
                                <BreadCrumbs
                                    data={[
                                        {
                                            displayName: "AeroGCS",
                                            pathname: "/",
                                            state: {
                                                user
                                            }
                                        },
                                        {
                                            displayName: 'Projects',
                                        }]}
                                />
                            </div>
                        </div>

                        <div className="main-content-new gcs-projects-screen">
                            {/* <SnackBar user={user} sidebarMode={sidebarMode} type={type} /> */}
                            {this.state.showSubscriptionExpirePopup ?
                                <SubscriptionExpireModal
                                    data={this.state.showSubscriptionExpirePopup}
                                    updateProfile={() => {
                                        this.props.updateProfileDetails({
                                            activeTeamId: this.state.ownerTeamDetaisToSwitch.teamId,
                                            activeTeam: this.state.ownerTeamDetaisToSwitch,
                                            user: this.state.user,
                                            type: undefined,
                                        })
                                        window.location.replace("/")
                                    }}
                                /> : <></>}
                            <AnimatedModal
                                isOpen={this.state.showAddProjectPopup}
                                height="550px"
                                width="700px"
                            >
                                <div style={{ textAlign: 'left', padding: '25px 25px 0px' }}>
                                    <div className="services-content-title" >{"Create new project"}</div>
                                    <hr style={{ margin: '10px 0px' }} />
                                    <div style={{ marginBottom: "30px", display: 'flex', justifyContent: 'space-between' }}>
                                        <div style={{ marginTop: "8px" }}>
                                            <div className="font-color-secondary" style={{ fontSize: "12px" }}>Enter project name</div>
                                            <input type="text" maxLength={50} value={this.state.project_name} className="add-user-form-text" placeholder="eg. My_Project"
                                                onChange={this.handleChange} />
                                            <div style={{ color: "red", fontSize: "12px", height: "5px", padding: "5px 0 0 3px" }}>{this.state.error}</div>
                                        </div>
                                    </div>
                                    <div style={{ height: '300px', width: '100%' }}>
                                        <div style={{ position: 'absolute', zIndex: '1', background: '#3c3c3c99', padding: '5px' }}>
                                            <Autocomplete
                                                style={{ width: '270px' }}
                                                className="input-transparent1"
                                                placeholder='Search location'
                                                onChange={(e) => { this.onchangePlace(e) }}
                                                onKeyDown={(e) => this.handleKeyDown(e)}
                                                onPlaceSelected={(place) => {
                                                    try {
                                                        let lat = place.geometry.location.lat();
                                                        let lng = place.geometry.location.lng();
                                                        let cntr = {};
                                                        cntr.lat = parseFloat(lat);
                                                        cntr.lng = parseFloat(lng);
                                                        this.setState((state) => ({
                                                            ...state,
                                                            center: cntr,
                                                            locationName: place.formatted_address
                                                        }), () => {
                                                            this.changeMarkerPosition(cntr)
                                                        });

                                                    } catch { }
                                                }}
                                                types={[]}
                                            />
                                        </div>
                                        <SingleMap
                                            initCenter={[this.state.center.lat, this.state.center.lng]}
                                            initZoom={22}
                                            handleMapClick={this.handleMapClick}
                                            maxZoom={23}
                                            setMap={(map) => {
                                                this.setState({ map }, this.initMap)
                                            }}
                                        />
                                    </div>
                                    <hr />
                                    <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                                        <ButtonWithIcon
                                            text="cancel"
                                            width="80px"
                                            onClick={() => {
                                                this.setState((state) => ({
                                                    ...state,
                                                    showAddProjectPopup: false,
                                                    project_name: false,
                                                    locationName: '',
                                                }));
                                            }}
                                        />
                                        <ButtonWithIcon
                                            text="Ok"
                                            width="80px" isBtnActive
                                            onClick={this.createProject}
                                            isDisabled={this.state.project_name ? false : true}
                                        />
                                    </div>
                                </div>
                            </AnimatedModal>
                            <ActivityPopup
                                item={this.state.itemName}
                                open={this.state.showProcessPopup}
                                icon={this.state.processPopupIcon}
                                action={this.state.processAction}
                                msg={this.state.processMessage}
                                close={() => this.closeActivityPopup()}
                            />
                            <DeleteProject
                                projectsState={{ projectCount: this.state.projects.length, pageNumber: this.state.filter.pageNumber }}
                                service={AppConstants.SERVICES.AEROGCS}
                                projectId={this.state?.selectedProject?.id}
                                show={this.state.showDeletePopup}
                                selectedPageChanged={(isSelectedPageChanged) => {
                                    if (isSelectedPageChanged) {
                                        this.setState(prevState => ({
                                            filter: {
                                                ...prevState.filter,
                                                pageNumber: this.state.filter.pageNumber - 1,
                                            }
                                        }));
                                    }
                                }}
                                onClose={(isDeleted) => {
                                    this.setState({
                                        showDeletePopup: false
                                    }, () => {
                                        if (isDeleted)
                                            this.getProjects()
                                    })
                                }}
                            />

                            {
                                this.state.mainLoader ? <LoaderComponent message={"Loading please wait..."} /> :
                                    <div style={{ height: "100%" }}>
                                        <div style={{ display: "flex", marginBottom: "10px", alignItems: 'center' }}>
                                            <div className="services-content-title">{`Projects`}
                                                <span className='services-content-title'>({this.state.totalCount})</span>
                                            </div>
                                            <div className="add-user-form-text-wrapper" style={{ display: "flex", alignItems: 'center', }} >
                                                {this.state.activeTeam.isOwner ?
                                                    <div style={{ width: "fit-content", margin: "0px 10px", filter: this.state.activeTeam.isOwner ? "" : "grayscale(100%)" }}>
                                                        {
                                                            <Button
                                                                className={'new-btn-rounded'}
                                                                icon={addIcon}
                                                                borderRadius={"50%"}
                                                                backgroundColor={"#3988F9"}
                                                                display={"flex"}
                                                                alignItems={"center"}
                                                                isBtnActive={true}
                                                                iconPadding={"2px"}
                                                                iconWidth={"18px"}
                                                                onClick={
                                                                    () => this.setState({ showAddProjectPopup: true, project_name: '', error: null })
                                                                }
                                                                isDisabled={this.state.activeTeam.isOwner ? false : true}
                                                            />
                                                        }
                                                    </div> : <></>}
                                            </div>
                                        </div>
                                        <SearchHeader
                                            placeholder={"Search projects..."}
                                            searchString={cookies.get("projects_list")?.searchString || ""}
                                            onFilterDataChange={(filter, updatedField) => {
                                                this.setState(() => ({
                                                    gridView: filter.selectView === "grid",
                                                    filter: {
                                                        ...this.state.filter,
                                                        searchString: filter.searchString,
                                                        fromDate: filter.fromDateTime === undefined || filter.toDateTime === undefined ? undefined : new Date(filter.fromDateTime).toISOString(),
                                                        toDate: filter.fromDateTime === undefined || filter.toDateTime === undefined ? undefined : new Date(filter.toDateTime).toISOString(),
                                                        pageNumber: 0,
                                                        selectView: filter.selectView
                                                    },
                                                }), () => {
                                                    cookies.set('projects_list', JSON.stringify({ ...this.state.filter, ...filter }), { path: "/aerogcsenterprise/projects" })
                                                    if (updatedField === "searchString") this.state.debounceSearch()
                                                    else this.getProjects()
                                                })
                                            }}
                                            isShowViewButtons
                                            defaultView={cookies.get("projects_list")?.selectView || "list"}
                                            isShowDateTimePicker={true}
                                        />

                                        {projects.length > 0 ?
                                            <>
                                                {this.state.projects.length > 0 && this.state.gridView ? <>
                                                    <div style={{ height: "30px" }}>
                                                        <SortComponent
                                                            marginLeft={"22px"}
                                                            label={'Name'}
                                                            sortAscending={() => {
                                                                this.setState({
                                                                    filter: {
                                                                        ...this.state.filter,
                                                                        orderType: 'ASC',
                                                                        orderBy: 'project_name'
                                                                    }
                                                                }, () => { this.getProjects() })
                                                            }}
                                                            sortDecending={() => {
                                                                this.setState({
                                                                    filter: {
                                                                        ...this.state.filter,
                                                                        orderType: 'DESC',
                                                                        orderBy: 'project_name'
                                                                    }
                                                                }, () => { this.getProjects() })
                                                            }}
                                                            sortASC={true}
                                                        />
                                                    </div>
                                                </> : <></>}

                                                {this.state.gridView ?
                                                    this.state.loader ? <LoaderComponent message={"Loading projects please wait..."} /> :
                                                        <div className="box_container scroll-container" >
                                                            {projects.map((project, key) => {
                                                                return (
                                                                    <OverviewBox
                                                                        linkData={{
                                                                            pathname: '/aerogcsenterprise/plans',
                                                                            state: { user, type, project, sidebarMode: this.state.sidebarMode }
                                                                        }}
                                                                        boxImage={folder}
                                                                        boxTitle={project.project_name}
                                                                        boxSubTitle={`${project.plan_count} Plans`}
                                                                        boxTime={new Date(project.created_date)}
                                                                        activities={this.state.activeTeam.isOwner && {
                                                                            canManage: true,
                                                                            onClickManage: () => {
                                                                                this.setState({
                                                                                    selectedMangeAccessProject: project.id,
                                                                                    selectedProjectData: { pathname: "/aerogcsenterprise/plans", state: { user, type, project, sidebarMode: this.state.sidebarMode } }
                                                                                })
                                                                            },
                                                                            disableDelete: !this.state.activeTeam.isOwner,
                                                                            onClickDelete: () => {
                                                                                if (project.project_name != 'ManualProject') {
                                                                                    this.setState((state) => ({
                                                                                        ...state,
                                                                                        selectedProject: project,
                                                                                        deleteItemType: "project"
                                                                                    }), () => {
                                                                                        this.getAllDataToDelete()
                                                                                    })
                                                                                } else {
                                                                                    this.setState({
                                                                                        itemName: "Project",
                                                                                        processPopupIcon: "ERROR",
                                                                                        processMessage: 'You can not delete this project.',
                                                                                        showProcessPopup: true,
                                                                                        mainLoader: false
                                                                                    })
                                                                                }

                                                                            }
                                                                        }}
                                                                    />
                                                                )
                                                            })}
                                                        </div>
                                                    : <div className="projects-component-table-view">
                                                        <TableView
                                                            isLoading={this.state.loader}
                                                            onClickRow={(row) => [
                                                                this.props.history.push("/aerogcsenterprise/plans", {
                                                                    user: this.state.user,
                                                                    project: row
                                                                })
                                                            ]}
                                                            columns={[
                                                                { Header: 'Name', accessor: 'project_name', isSortable: true, width: "30%" },
                                                                { Header: 'Plans', accessor: 'plan_count', width: "20%" },
                                                                { Header: 'Location', accessor: 'location_name', width: "20%" },
                                                                { Header: 'Date Created', accessor: 'created_date', isSortable: true, isDate: true, width: "20%" },
                                                            ]}
                                                            data={projects}
                                                            activities={this.state.activeTeam.isOwner && {
                                                                canManage: true,
                                                                onClickManage: (project) => {
                                                                    this.setState({
                                                                        selectedMangeAccessProject: project.id,
                                                                        selectedProjectData: { pathname: "/aerogcsenterprise/plans", state: { user, type, project, sidebarMode: this.state.sidebarMode } }
                                                                    })
                                                                },
                                                                disableDelete: !this.state.activeTeam.isOwner,
                                                                onClickDelete: (project) => {
                                                                    if (project.project_name != 'ManualProject') {
                                                                        this.setState((state) => ({
                                                                            ...state,
                                                                            selectedProject: project,
                                                                            deleteItemType: "project"
                                                                        }), () => { this.getAllDataToDelete() })
                                                                    } else {
                                                                        this.setState({
                                                                            itemName: "Project",
                                                                            processPopupIcon: "ERROR",
                                                                            processMessage: 'You can not delete this project.',
                                                                            showProcessPopup: true,
                                                                            mainLoader: false
                                                                        })
                                                                    }
                                                                }
                                                            }}
                                                            user={user}
                                                            onStateChange={(changedState) => {
                                                                this.setState({
                                                                    filter: {
                                                                        ...this.state.filter,
                                                                        pageNumber: changedState.pageNumber - 1,
                                                                        orderBy: changedState.orderBy,
                                                                        orderType: changedState.orderType
                                                                    }
                                                                }, () => {
                                                                    cookies.set('projects_list', JSON.stringify({ ...this.state.filter }), { path: "/aerogcsenterprise/projects" })
                                                                    this.getProjects()
                                                                })
                                                            }}
                                                        />
                                                    </div>}
                                                <div className='grid-view-pagination-width'>
                                                    <TableViewPagination
                                                        currentPage={this.state.filter.pageNumber + 1}
                                                        totalCount={this.state.totalCount}
                                                        pageSize={this.state.filter.pageSize}
                                                        padding="10px 5px"
                                                        onPageChange={(page) => {
                                                            this.setState({
                                                                filter: {
                                                                    ...this.state.filter,
                                                                    pageNumber: page - 1
                                                                }
                                                            }, () => {
                                                                cookies.set('projects_list', JSON.stringify({ ...this.state.filter }), { path: "/aerogcsenterprise/projects" })
                                                                if (page - 1 == this.state.filter.pageNumber) this.getProjects()
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </> : <div className="rmp-dashboard-empty-data-message" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
                                                <img style={{ height: '180px', width: '200px' }} src={Noproject} />
                                                <p style={{ marginTop: '10px' }}>No projects available</p>
                                            </div>}
                                    </div>
                            }
                        </div>
                    </div>
                </div > : <div />
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateProfileDetails: (payload) => dispatch(updateProfileDetails(payload))
    };
};

const mapStateToProps = state => {
    return {
        SubscriptionData: state.subscription.subscriptionData,
    };
};

const connectedProjects = GoogleApiWrapper({
    apiKey: "AIzaSyBuxfUtRZSEUT2QrbZA8zUsG58lZUYYmwI",
    libraries: ["drawing", "places"],
})(connect(mapStateToProps, mapDispatchToProps)(Projects));


export default withRouter(connectedProjects); 