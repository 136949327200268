import React, { useState, useEffect, useRef } from 'react';
import dateFormat from 'dateformat';
import closeIcon from '../src/icons/close.png';

const WeekPicker = ({ onClose, onContinue, fromDate, currentValue }) => {

    const today = new Date();
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth(); // 0 = Jan, 11 = Dec

    const years = Array.from({ length: fromDate ? (currentYear - Number(new Date(fromDate).getFullYear()) + 1) : currentYear - 2016 + 1 }, (_, i) => currentYear - i);
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const [selectedYear, setSelectedYear] = useState(currentYear);
    const [selectedMonth, setSelectedMonth] = useState(currentMonth);
    const parentDivRef = useRef(null);
    const childDivRefs = useRef([]);
    const [selectedIndex, setSelectedIndex] = useState(selectedMonth);

    const [startWeek, setStartWeek] = useState(null);
    const [endWeek, setEndWeek] = useState(null);

    const onLoadData = (value) => {
        const month = new Date(value.toDate).getMonth()
        const year = new Date(value.toDate).getFullYear()
        setSelectedMonth(month)
        setSelectedIndex(month)
        setSelectedYear(year)
        const weeks = getWeeksInMonth(year, month);
        const date1 = new Date(value.frmDate)
        const date2 = new Date(value.toDate)
        console.log('weeks', weeks, date1, date2);

        const weekIndexes = weeks.map((week, index) => {
            if (date1 >= week.start && date1 <= week.end) return index;
            if (date2 >= week.start && date2 <= week.end) return index;
            return null;
        }).filter(index => index !== null);
        console.log('weekIndexes', weekIndexes);

        if (weekIndexes.length == 0) {
            const month1 = new Date().getMonth()
            const year1 = new Date().getFullYear()
            setSelectedMonth(month1)
            setSelectedIndex(month1)
            setSelectedYear(year1)
        } else {
            setStartWeek(weekIndexes.length > 0 ? weekIndexes[0] : null)
            setEndWeek(weekIndexes[1] || null)
        }
    }
    
    useEffect(() => {
        onLoadData(currentValue)
    }, [currentValue])

    useEffect(() => {
        if (childDivRefs.current[selectedIndex]) {
            childDivRefs.current[selectedIndex].scrollIntoView({ behavior: "smooth", block: "nearest" });
        }
    }, [selectedIndex]);

    const handleSelect = (index) => {
        setSelectedIndex(index);
        if (childDivRefs.current[index]) {
            childDivRefs.current[index].scrollIntoView({ behavior: "smooth", block: "nearest" });
        }
    };

    const isFutureWeek = (week) => {
        return week.end > today;
    };

    const isFutureMonth = (month) => {
        const currentDate = new Date();
        const selectedDate = new Date(selectedYear, months.indexOf(month), 1);

        return selectedDate > currentDate;
    };

    const getWeeksInMonth = (year, month) => {
        const firstDayOfMonth = new Date(year, month, 1);
        const lastDayOfMonth = new Date(year, month + 1, 0);
        const weeks = [];
        let currentWeekStart = new Date(firstDayOfMonth);

        while (currentWeekStart <= lastDayOfMonth) {
            let weekEnd = new Date(currentWeekStart);
            weekEnd.setDate(weekEnd.getDate() + 6);
            if (weekEnd > lastDayOfMonth) weekEnd = lastDayOfMonth;

            weeks.push({
                start: new Date(currentWeekStart),
                end: new Date(weekEnd)
            });
            currentWeekStart.setDate(currentWeekStart.getDate() + 7);
        }

        return weeks;
    };

    const weeksInSelectedMonth = selectedMonth !== null ? getWeeksInMonth(selectedYear, selectedMonth) : [];

    const isInRange = (index) => {
        return startWeek !== null && endWeek !== null && index >= startWeek && index <= endWeek;
    };

    const getWeekRange = () => {
        if (startWeek !== null && endWeek !== null) {
            const { start } = weeksInSelectedMonth[startWeek];
            const { end } = weeksInSelectedMonth[endWeek];

            return {
                startDate: start,
                endDate: end,
            };
        } else if (startWeek !== null) {
            const { start, end } = weeksInSelectedMonth[startWeek];
            return {
                startDate: start,
                endDate: end,
            };
        }
        return null;
    };

    const handleWeekClick = (weekIndex) => {
        if (startWeek === null) {
            setStartWeek(weekIndex);
        } else if (startWeek !== null) {
            if (startWeek !== null && endWeek !== null) {
                if (weekIndex > startWeek) { setEndWeek(weekIndex) }
                else if (weekIndex < startWeek) { setStartWeek(weekIndex) }
            }
            else if (startWeek !== null && endWeek === null) {
                if (weekIndex > startWeek) {
                    setEndWeek(weekIndex);
                } else if (weekIndex < startWeek) {
                    setStartWeek(weekIndex);
                    setEndWeek(startWeek);
                }
            }
        }
    };

    const handleContinueClick = () => {
        if (selectedYear != null && selectedMonth != null && startWeek != null) {
            const range = getWeekRange();
            if (range) {
                onContinue({ frmDate: range.startDate, toDate: range.endDate })
            } else {
                console.log('Please select a weeks.');
            }
        }
    };

    return (
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "99%",
            width: "100%",
            position: "absolute"
        }}>
            <div style={{ position: "relative", width: "23%", height: "99%", background: "#f5f5f5" }}>
                <div style={{ fontSize: "16px", fontWeight: "500", display: "flex", justifyContent: "left", alignItems: "center", padding: "10px", }}> Year</div>
                <div style={{ overflow: "auto", display: "flex", flexDirection: "column", gap: "4px", height: "30%", padding: "0px 30px 0 10px" }}>

                    {years.map((year) => (
                        <div
                            key={year}
                            onClick={() => { setSelectedYear(year); setSelectedMonth(null); }}
                            style={{
                                fontSize: "13px",
                                fontWeight: "500",
                                display: "flex",
                                justifyContent: "left",
                                alignItems: "center",
                                backgroundColor: selectedYear === year ? '#3988F91A' : '',
                                color: selectedYear === year ? '#3988F9' : '#68717E',
                                border: 'none',
                                cursor: 'pointer',
                                borderRadius: '15px',
                                padding: "0 0 0 15px"
                            }}
                        >
                            {year}
                        </div>
                    ))}
                </div>
                <div style={{ fontSize: "16px", fontWeight: "500", display: "flex", justifyContent: "left", alignItems: "center", padding: "10px", }}> Month</div>

                <div
                    ref={parentDivRef}
                    style={{ overflow: "auto", display: "flex", flexDirection: "column", gap: "4px", height: "35%", padding: "0px 30px 0 10px" }}
                >
                    {months.map((month, index) => (
                        <div
                            key={month}
                            ref={(el) => (childDivRefs.current[index] = el)}
                            tabIndex='0'
                            onClick={() => {
                                if (!isFutureMonth(month)) {
                                    setSelectedMonth(index)
                                    handleSelect(index)
                                }
                            }}
                            style={{
                                fontSize: "13px",
                                fontWeight: "500",
                                display: "flex",
                                justifyContent: "left",
                                alignItems: "center",
                                backgroundColor: selectedMonth === index ? '#3988F91A' : '',
                                color: selectedMonth === index ? '#3988F9' : '#68717E',
                                border: 'none',
                                cursor: isFutureMonth(month) ? 'not-allowed' : 'pointer',
                                borderRadius: '15px',
                                padding: "0 0 0 15px",
                                opacity: isFutureMonth(month) ? '0.5' : '1'
                            }}
                        >
                            {isFutureMonth(month) ? '' : month}
                        </div>
                    ))}
                </div>

            </div>
            <div style={{ padding: "10px 20px", position: "relative", width: "77%", height: "100%", boxShadow: "-11px 0px 18px -14px #bbbbbb" }}>

                <div style={{ position: "relative", width: "100%", height: "90%", display: "flex", flexDirection: "column", justifyContent: "left", alignItems: "flex-start" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                        <div style={{ fontSize: "16px", fontWeight: "500", }}>  Week <span style={{ fontSize: "9px", color: "#68717E" }}>( Click to select multiple weeks )</span>  </div>
                        <div onClick={onClose}>
                            <img src={closeIcon} style={{ height: "12px", cursor: "pointer" }} />
                        </div>
                    </div>
                    <div >
                        {selectedMonth !== null && <div style={{ padding: "30px 20px", display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '27px', width: "100%", height: "100%" }}>
                            {weeksInSelectedMonth.map((week, index) => (
                                <div
                                    key={index}
                                    onClick={() => {
                                        if (!isFutureWeek(week)) handleWeekClick(index)
                                        // toggleWeekSelection(index)
                                    }}
                                    style={{ cursor: isFutureWeek(week) ? "not-allowed" : "pointer", opacity: isFutureWeek(week) ? "0.5" : "1", }}
                                >
                                    <div style={{
                                        fontSize: "14px",
                                        color: startWeek === index || endWeek === index || isInRange(index) ? "#081425" : "#68717E",
                                        backgroundColor: startWeek === index || endWeek === index || isInRange(index) ? '#D7E6FC' : '',
                                    }}>
                                        Week {index + 1}
                                    </div>
                                    <div style={{ fontSize: "9px", color: "#68717E" }}>
                                        {dateFormat(week.start, "dd/mm/yyyy")} - {dateFormat(week.end, "dd/mm/yyyy")}
                                    </div>
                                </div>
                            ))}
                        </div>}
                    </div>
                </div>

                <div style={{ padding: "0px", position: "relative", width: "100%", height: "10%", display: "flex", justifyContent: "right", gap: "20px", alignItems: "center" }}>
                    <div
                        onClick={() => {
                            setSelectedYear(currentYear);
                            setSelectedMonth(currentMonth);
                            setStartWeek(null);
                            setEndWeek(null);
                        }}
                        style={{
                            border: "1px solid rgb(201, 201, 201)",
                            width: "110px",
                            height: "30px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "7px",
                            fontSize: "14px",
                            cursor: "pointer",
                        }} >
                        Reset
                    </div>
                    <div
                        onClick={handleContinueClick}
                        disabled={selectedYear === null || selectedMonth === null || startWeek === null}
                        style={{
                            alignSelf: 'center',
                            backgroundColor: selectedYear != null && selectedMonth != null && startWeek != null ? '#007bff' : '#ccc',
                            color: '#fff',
                            border: 'none',
                            cursor: selectedYear != null && selectedMonth != null && startWeek != null ? 'pointer' : 'not-allowed',
                            borderRadius: '5px',
                            height: "25px",
                            display: "flex",
                            width: "100px",
                            fontSize: "12px",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        Continue
                    </div>
                </div>
            </div>
        </div >

    );
};

export default WeekPicker;
