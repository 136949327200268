import Services from "./sidebaricon/Services.svg"
import ServicesInactive from "./sidebaricon/Services-Inactive.svg"
import AeroGCS from "./sidebaricon/AeroGCS.svg"
import AeroGCSInactive from "./sidebaricon/AeroGCS-Inactive.svg"
import Drones from "./sidebaricon/Drone.png"
import DronesInactive from "./sidebaricon/Drone-Inactive.png"
import Teams from "./sidebaricon/Teams.svg"
import TeamsInactive from "./sidebaricon/Teams-Inactive.png"
import ControlCenter from "./sidebaricon/ControlCenter.svg"
import ControlCenterInactive from "./sidebaricon/ControlCenter-Inactive.svg"
import Projects from "./sidebaricon/Projects.svg"
import ProjectsInactive from "./sidebaricon/Projects-Inactive.png"
import Project from "./sidebaricon/ProjectNew.png"
import ProjectInactive from "./sidebaricon/ProjectNew-Inactive.png"
import Plans from "./sidebaricon/Plans.png"
import PlansInactive from "./sidebaricon/Plans-Inactive.png"
import Imagesets from "./sidebaricon/ImageSets.png"
import ImagesetsInactive from './sidebaricon/ImageSets-Inactive.png';
import Videos from "./sidebaricon/Video.png"
import VideosInactive from "./sidebaricon/Video-Inactive.png"
import FlightLogs from "./sidebaricon/FlightLogs.png"
import FlightLogsInactive from "./sidebaricon/FlightLogs-Inactive.png"
import AeroStream from "./sidebaricon/AeroStreams.png"
import AeroStreamInactive from "./sidebaricon/AeroStreams-Inactive.png"
import Settings from './sidebaricon/SettingsActive.png';
import SettingsInactive from './sidebaricon/SettingsInactive.png';
import UsersActive from './sidebaricon/usersActive.svg';
import UsersInactive from './sidebaricon/users1.svg'
import UsersStats from './sidebaricon/UsersStats.svg'
import UsersStatsInactive from './sidebaricon/UsersStatsInactive.svg'
import systemIcon from './sidebaricon/system.svg'
import systemIconInative from './sidebaricon/systemInactive.svg'
import barChartIcon from './sidebaricon/barChart.svg'
import barChartIconInactive from './sidebaricon/barChartInactive.svg'
import weeklyReportIcon from './sidebaricon/weekly-report-icon.svg'
import weeklyReportIconInactive from './sidebaricon/weekly-report-icon-inactive.svg'
import softwareVersion from './sidebaricon/software_version.svg'
import softwareVersionInactive from './sidebaricon/software_version_inactive.svg'
import dashboardActive from './icons/layout.png'
import dashboardInactive from './icons/layoutInactive.png'
import emailLogs from './icons/emailAdmin.png'
import emailLogsInactive from './icons/emailAdminInactive.png'
import OEMDroneIconActive from './icons/OEMDroneActive.png'
import OEMDroneIconInActive from './icons/OEMDroneInactive.png'
import leaseActive from './icons/lease.svg'
import leaseInactive from './icons/leaseInactive.svg'
import GreenActiveIcon from './sidebaricon/GreenActiveIcon.svg'
import GreenInactiveIcon from './sidebaricon/GreenInactiveIcon.svg'
import ReportActiveIcon from './sidebaricon/ReportActiveIcon.svg'
import ReportInactiveIcon from './sidebaricon/ReportInactiveIcon.svg'
import CustomerActiveIcon from './sidebaricon/CustomerActiveIcon.svg'
import CustomerInactiveIcon from './sidebaricon/CustomerInactiveIcon.svg'

const defaultAdminToolSideBar = {
    tools: [

        {
            icon: {
                active: dashboardActive,
                inActive: dashboardInactive
            },
            name: "Dashboard",
            path: "/admin",
        },
        {
            name: 'Control Center',
            path: "/admin/drone_control_center/all_drones",
            icon: {
                active: ControlCenter,
                inActive: ControlCenterInactive
            },
        },
        {
            icon: {
                active: UsersActive,
                inActive: UsersInactive
            },
            name: "Users",
            path: "/admin/users",
        },
        {
            icon: {
                active: emailLogs,
                inActive: emailLogsInactive
            },
            name: "Email Logs",
            path: "/admin/email_logs",
        }, ,
        {
            icon: {
                active: systemIcon,
                inActive: systemIconInative
            },
            name: "Machines",
            path: "/admin/machines",
        },
        {
            icon: {
                active: softwareVersion,
                inActive: softwareVersionInactive
            },
            name: "Software Versions",
            path: "/admin/software_versions",
        },
        {
            icon: {
                active: OEMDroneIconActive,
                inActive: OEMDroneIconInActive
            },
            name: "OEM Drones",
            path: "/admin/OEMUser",
        },
    ]
}

const defaultAeroGCSSideBar = {
    tools: [
        {
            name: 'AeroGCS',
            path: '/',
            icon: {
                active: AeroGCS,
                inActive: AeroGCSInactive
            },
        },
        {
            name: 'Drones',
            path: "/aerogcsenterprise/drones",
            icon: {
                active: Drones,
                inActive: DronesInactive
            },
        },
        {
            name: 'Control Center',
            path: "/aerogcsenterprise/drone_control_center/all_drones",
            icon: {
                active: ControlCenter,
                inActive: ControlCenterInactive
            },
        },
        {
            name: 'Teams',
            path: "/teams",
            icon: {
                active: Teams,
                inActive: TeamsInactive
            },
        },
        {
            name: 'Projects',
            path: "/aerogcsenterprise/projects",
            icon: {
                active: Projects,
                inActive: ProjectsInactive
            },
        },
        {
            name: 'Weekly Report',
            path: "/aerogcsenterprise/weeklyReport",
            icon: {
                active: weeklyReportIcon,
                inActive: weeklyReportIconInactive
            },
            className: 'profileSettingIcon'
        },
        {
            name: 'Settings',
            path: "/profile-settings",
            icon: {
                active: Settings,
                inActive: SettingsInactive
            },
            className: 'profileSettingIcon'
        },
    ],
    selectedPath: '/',
    aeroGCSHelp: true
}

const defaultDronesSideBar = {
    tools: [
        {
            name: 'AeroGCS',
            path: '/',
            icon: {
                active: AeroGCS,
                inActive: AeroGCSInactive
            },
        },
        {
            name: 'Drones',
            path: "/aerogcsenterprise/drones",
            icon: {
                active: Drones,
                inActive: DronesInactive
            },
        },
        {
            name: 'Control Center',
            path: "/aerogcsenterprise/drone_control_center/all_drones",
            icon: {
                active: ControlCenter,
                inActive: ControlCenterInactive
            },
        },
        {
            name: 'Teams',
            path: "/teams",
            icon: {
                active: Teams,
                inActive: TeamsInactive
            },
        },
        {
            name: 'Projects',
            path: "/aerogcsenterprise/projects",
            icon: {
                active: Projects,
                inActive: ProjectsInactive
            },
        },
        {
            name: 'Settings',
            path: "/profile-settings",
            icon: {
                active: Settings,
                inActive: SettingsInactive
            },
            className: 'profileSettingIcon'
        },],
    aeroGCSHelp: true,
    selectedPath: '/aerogcsenterprise/drones'
}

const aeroGCSPlans = {
    tools: [
        {
            name: 'AeroGCS',
            path: '/',
            icon: {
                active: AeroGCS,
                inActive: AeroGCSInactive
            },
        },
        {
            name: 'Drones',
            path: "/aerogcsenterprise/drones",
            icon: {
                active: Drones,
                inActive: DronesInactive
            },
        },
        {
            name: 'Control Center',
            path: "/aerogcsenterprise/drone_control_center/all_drones",
            icon: {
                active: ControlCenter,
                inActive: ControlCenterInactive
            },
        },
        {
            name: 'Teams',
            path: "/teams",
            icon: {
                active: Teams,
                inActive: TeamsInactive
            },
        },
        {
            name: 'Projects',
            path: "/aerogcsenterprise/projects",
            icon: {
                active: Projects,
                inActive: ProjectsInactive
            },
        },
        {
            name: 'Imagesets',
            path: "/aerogcsenterprise/remote_mission/image_collection",
            icon: {
                active: Imagesets,
                inActive: ImagesetsInactive
            },
            className: "sidebaricon-plan"
        },
        {
            name: 'Videos',
            path: "/aerogcsenterprise/remote_mission/my_videos",
            icon: {
                active: Videos,
                inActive: VideosInactive
            },
            className: "sidebaricon-plan"
        },
        {
            name: 'Flight logs',
            path: "/aerogcsenterprise/remote_mission/flightLogs",
            icon: {
                active: FlightLogs,
                inActive: FlightLogsInactive
            },
            className: "sidebaricon-flightlogs"
        },
        {
            name: 'Settings',
            path: "/profile-settings",
            icon: {
                active: Settings,
                inActive: SettingsInactive
            },
            className: 'profileSettingIcon'
        },
    ],
    selectedPath: '/aerogcsenterprise/projects'
}

const defaultProjectSideBar = {
    tools: [
        {
            name: 'AeroGCS',
            path: '/',
            icon: {
                active: AeroGCS,
                inActive: AeroGCSInactive
            },
        },
        {
            name: 'Drones',
            path: "/aerogcsenterprise/drones",
            icon: {
                active: Drones,
                inActive: DronesInactive
            },
        },
        {
            name: 'Control Center',
            path: "/aerogcsenterprise/drone_control_center/all_drones",
            icon: {
                active: ControlCenter,
                inActive: ControlCenterInactive
            },
        },
        {
            name: 'Teams',
            path: "/teams",
            icon: {
                active: Teams,
                inActive: TeamsInactive
            },
        },
        {
            name: 'Projects',
            path: "/aerogcsenterprise/projects",
            icon: {
                active: Projects,
                inActive: ProjectsInactive
            },
        },
        {
            name: 'Settings',
            path: "/profile-settings",
            icon: {
                active: Settings,
                inActive: SettingsInactive
            },
            className: 'profileSettingIcon'
        },
    ],
    aeroGCSHelp: true,
    selectedPath: '/aerogcsenterprise/projects'
}

const defaultFlightPlan = {
    tools: [
        {
            name: 'AeroGCS',
            path: '/',
            icon: {
                active: AeroGCS,
                inActive: AeroGCSInactive
            },
        },
        {
            name: 'Drones',
            path: "/aerogcsenterprise/drones",
            icon: {
                active: Drones,
                inActive: DronesInactive
            },
        },
        {
            name: 'Control Center',
            path: "/aerogcsenterprise/drone_control_center/all_drones",
            icon: {
                active: ControlCenter,
                inActive: ControlCenterInactive
            },
        },
        {
            name: 'Projects',
            path: "/aerogcsenterprise/projects",
            icon: {
                active: Projects,
                inActive: ProjectsInactive
            },
        },
        {
            name: 'Plan',
            path: "/aerogcsenterprise/remote_mission/assignDroneToPlan",
            icon: {
                active: Plans,
                inActive: PlansInactive
            },
            className: "sidebaricon-plan"
        },
        {
            name: 'Settings',
            path: "/profile-settings",
            icon: {
                active: Settings,
                inActive: SettingsInactive
            },
            className: 'profileSettingIcon'
        },
    ],
    selectedPath: "/aerogcsenterprise/remote_mission/assignDroneToPlan",
    aeroGCSHelp: true
}

const defaultSurveyFlightPlan = {
    tools: [
        {
            name: 'AeroGCS',
            path: '/',
            icon: {
                active: AeroGCS,
                inActive: AeroGCSInactive
            },
        },
        {
            name: 'Projects',
            path: "/aerogcsenterprise/projects",
            icon: {
                active: Projects,
                inActive: ProjectsInactive
            },
        },
        {
            name: 'Settings',
            path: "/profile-settings",
            icon: {
                active: Settings,
                inActive: SettingsInactive
            },
            className: 'profileSettingIcon'
        },
    ],
    selectedPath: "/aerogcsenterprise/projects",
    aeroGCSHelp: true
}

const defaultSprayingFlightPlan = {
    tools: [
        {
            name: 'AeroGCS',
            path: '/',
            icon: {
                active: AeroGCS,
                inActive: AeroGCSInactive
            },
        },
        {
            name: 'Projects',
            path: "/aerogcsenterprise/projects",
            icon: {
                active: Projects,
                inActive: ProjectsInactive
            },
        },
        {
            name: 'Plan',
            path: "/aerogcsenterprise/remote_mission/assignDroneToPlan",
            icon: {
                active: Plans,
                inActive: PlansInactive
            },
            className: "sidebaricon-plan"
        },
        {
            name: 'Settings',
            path: "/profile-settings",
            icon: {
                active: Settings,
                inActive: SettingsInactive
            },
            className: 'profileSettingIcon'
        },
    ],
    selectedPath: "/aerogcsenterprise/remote_mission/assignDroneToPlan",
    aeroGCSHelp: true
}

const defaultAnalyticToolSideBar = {
    tools: [
        {
            name: 'User Analytics',
            path: "/analytictool/user_statistics",
            icon: {
                active: Teams,
                inActive: TeamsInactive
            },
        },
        {
            name: 'Settings',
            path: "/profile-settings",
            icon: {
                active: Settings,
                inActive: SettingsInactive
            },
            className: 'profileSettingIcon'
        },
    ],
    selectedPath: '/analytictool/user_statistics',
    help: true
}

const defaultServicesSideBar = {
    tools: [
        {
            name: 'Services',
            path: "/",
            icon: {
                active: Services,
                inActive: ServicesInactive
            },
        },
        {
            name: 'Teams',
            path: "/teams",
            icon: {
                active: Teams,
                inActive: TeamsInactive
            },
        },
        {
            name: 'Planner',
            path: "/planner",
            icon: {
                active: Teams,
                inActive: TeamsInactive
            },
        },
        {
            name: 'Settings',
            path: "/profile-settings",
            icon: {
                active: Settings,
                inActive: SettingsInactive
            },
            className: 'profileSettingIcon'
        },
    ],
    selectedPath: '/',
    help: true
}

const defaultLeaseSideBar = {
    tools: [
        {
            name: 'AeroGCS',
            path: '/',
            icon: {
                active: AeroGCS,
                inActive: AeroGCSInactive
            },
        },
        {
            name: 'Dashboard',
            path: '/aerogcsenterprise/lease_dashboard',
            icon: {
                active: dashboardActive,
                inActive: dashboardInactive
            },
        },
        {
            name: 'Customers',
            path: '/aerogcsenterprise/lease_customers',
            icon: {
                active: Teams,
                inActive: TeamsInactive
            },
        },
        {
            name: 'Lease',
            path: '/aerogcsenterprise/lease_dronesLease',
            icon: {
                active: leaseActive,
                inActive: leaseInactive
            },
        },

        {
            name: 'Drones',
            path: '/aerogcsenterprise/lease_dronesMgmt',
            icon: {
                active: Drones,
                inActive: DronesInactive
            },
        },
        {
            name: 'Invoices',
            path: '/aerogcsenterprise/lease_drone_invoices',
            icon: {
                active: weeklyReportIcon,
                inActive: weeklyReportIconInactive
            },
        },

    ],
    selectedPath: '/aerogcsenterprise/lease_dashboard',
    help: true
}


const defaultGreenSideBar = {
    tools: [
        {
            name: 'AeroGCS',
            path: '/',
            icon: {
                active: AeroGCS,
                inActive: AeroGCS
            },
        },
        {
            name: 'Green',
            path: '/aerogcsenterprise/green_dashboard',
            icon: {
                active: GreenActiveIcon,
                inActive: GreenInactiveIcon
            },
        },
        {
            name: 'Reports',
            path: '/aerogcsenterprise/green_all_reports',
            icon: {
                active: ReportActiveIcon,
                inActive: ReportInactiveIcon
            },
        },
        {
            name: 'Customers',
            path: '/aerogcsenterprise/green_all_customers',
            icon: {
                active: CustomerActiveIcon,
                inActive: CustomerInactiveIcon
            },
        },

    ],
    selectedPath: '/aerogcsenterprise/green_dashboard',
    help: true
}

export const sideBarPaths = {
    "/": defaultAeroGCSSideBar,
    "/invoice": { ...defaultAeroGCSSideBar, selectedPath: '/profile-settings' },
    "/aerogcsenterprise/remote_mission/flights_completed_hours": defaultAeroGCSSideBar,
    "/aerogcsenterprise/remote_mission/flight_details": defaultAeroGCSSideBar,
    "/aerogcsenterprise/flight_logAnalyzer": defaultAeroGCSSideBar,
    '/aerogcsenterprise/remote_mission/flight_status_logs': defaultAeroGCSSideBar,
    '/aerogcsenterprise/remote_mission/detailedlog': defaultAeroGCSSideBar,
    '/aerogcsenterprise/allFlightPlans': defaultAeroGCSSideBar,
    '/aerogcsenterprise/remote_mission/assignDroneToPlan': aeroGCSPlans,
    '/aerogcsenterprise/remote_mission/image_collection': { ...aeroGCSPlans, selectedPath: '/aerogcsenterprise/remote_mission/image_collection' },
    '/aerogcsenterprise/remote_mission/my_videos': { ...aeroGCSPlans, selectedPath: '/aerogcsenterprise/remote_mission/my_videos' },
    '/aerogcsenterprise/drones': defaultDronesSideBar,
    '/aerogcsenterprise/drone_details': defaultDronesSideBar,
    '/aerogcsenterprise/drone/drone_health_report': defaultDronesSideBar,
    '/aeroGCSEnterprise/drone/hardwareDetails': defaultDronesSideBar,
    '/aerogcsenterprise/drone/errorLogs': defaultDronesSideBar,
    '/aerogcsenterprise/projects': { ...defaultDronesSideBar, selectedPath: '/aerogcsenterprise/projects' },
    '/aerogcsenterprise/plans': defaultProjectSideBar,
    '/aerogcsenterprise/remote_mission/flightLogs': ({ ...aeroGCSPlans, selectedPath: '/aerogcsenterprise/remote_mission/flightLogs' }),
    '/aerogcsenterprise/remote_mission/selectLocation': aeroGCSPlans,
    '/aerogcsenterprise/flights': defaultAeroGCSSideBar,
    '/aerogcsenterprise/user_flight_locations': defaultDronesSideBar,
    '/teams/givenAccess': { ...defaultAeroGCSSideBar, selectedPath: '/teams' },
    '/aerogcsenterprise/flights/pilots': defaultAeroGCSSideBar,
    '/aerogcsenterprise/flights/acres': defaultAeroGCSSideBar,
    '/aerogcsenterprise/remote_mission/flightPlan': defaultFlightPlan,
    "/aerogcsenterprise/remote_mission/surveyFlightPlan": defaultSurveyFlightPlan,
    '/aerogcsenterprise/remote_mission/sprayingFlightPlot': defaultSprayingFlightPlan,
    '/aerogcsenterprise/remote_mission/sprayingFlightPlan': defaultSprayingFlightPlan,
    '/teams': { ...defaultAeroGCSSideBar, selectedPath: '/teams' },
    '/profile-settings': { ...defaultAeroGCSSideBar, selectedPath: '/profile-settings' },
    '/aeroGCS_plans': { ...defaultAeroGCSSideBar, selectedPath: '/profile-settings' },
    '/usage_statistics': { ...defaultAeroGCSSideBar, selectedPath: '/profile-settings' },
    '/analytictool/user_statistics': defaultAnalyticToolSideBar,
    '/analytictool/userdetails': defaultAnalyticToolSideBar,
    '/analytictool/user_tracking_logs': defaultAnalyticToolSideBar,
    '/analytictool/user_screen_logs': defaultAnalyticToolSideBar,
    '/analytictool/customStatistics': defaultAnalyticToolSideBar,
    '/aerogcsenterprise/weeklyReport': { ...defaultAeroGCSSideBar, selectedPath: '/aerogcsenterprise/weeklyReport' },

    '/aerogcsenterprise/lease_dashboard': defaultLeaseSideBar,
    '/aerogcsenterprise/lease_dronesLease': { ...defaultLeaseSideBar, selectedPath: '/aerogcsenterprise/lease_dronesLease' },
    '/aerogcsenterprise/lease_customers': { ...defaultLeaseSideBar, selectedPath: '/aerogcsenterprise/lease_customers' },
    '/aerogcsenterprise/lease_customer': { ...defaultLeaseSideBar, selectedPath: '/aerogcsenterprise/lease_customers' },
    '/aerogcsenterprise/lease_dronesMgmt': { ...defaultLeaseSideBar, selectedPath: '/aerogcsenterprise/lease_dronesMgmt' },
    '/aerogcsenterprise/lease_drone_details': { ...defaultLeaseSideBar, selectedPath: '/aerogcsenterprise/lease_dronesMgmt' },
    '/aerogcsenterprise/lease_drone_invoices': { ...defaultLeaseSideBar, selectedPath: '/aerogcsenterprise/lease_drone_invoices' },
    '/aerogcsenterprise/lease_drone_invoice': { ...defaultLeaseSideBar, selectedPath: '/aerogcsenterprise/lease_drone_invoices' },

    '/aerogcsenterprise/green_dashboard': { ...defaultGreenSideBar, selectedPath: '/aerogcsenterprise/green_dashboard' },
    '/aerogcsenterprise/green_all_customers': { ...defaultGreenSideBar, selectedPath: '/aerogcsenterprise/green_all_customers' },
    '/aerogcsenterprise/green_all_reports': { ...defaultGreenSideBar, selectedPath: '/aerogcsenterprise/green_all_reports' },

    '/admin': { ...defaultAdminToolSideBar, selectedPath: '/admin' },
    '/admin/cropsAndAgrochemicals': { ...defaultAdminToolSideBar, selectedPath: '/admin' },
    '/admin/users': { ...defaultAdminToolSideBar, selectedPath: '/admin/users' },
    '/admin/email_logs': { ...defaultAdminToolSideBar, selectedPath: '/admin/email_logs' },
    '/admin/machines': { ...defaultAdminToolSideBar, selectedPath: '/admin/machines' },
    '/admin/allsubscription': { ...defaultAdminToolSideBar, selectedPath: '/admin/users' },
    '/admin/new_subscription': { ...defaultAdminToolSideBar, selectedPath: '/admin/users' },
    '/admin/discount_coupons': { ...defaultAdminToolSideBar, selectedPath: '/admin/users' },
    '/admin/subscription_items': { ...defaultAdminToolSideBar, selectedPath: '/admin/users' },
    '/admin/userdetails': { ...defaultAdminToolSideBar, selectedPath: '/admin/users' },
    '/admin/user_tracking_logs': { ...defaultAdminToolSideBar, selectedPath: '/admin/users' },
    '/admin/user_screen_logs': { ...defaultAdminToolSideBar, selectedPath: '/admin/user_screen_logs' },
    '/admin/edituser': { ...defaultAdminToolSideBar, selectedPath: '/admin/users' },
    '/admin/assign_services': { ...defaultAdminToolSideBar, selectedPath: '/admin/users' },
    '/admin/user_manage_plan': { ...defaultAdminToolSideBar, selectedPath: '/admin/users' },
    '/admin/OEMUser': { ...defaultAdminToolSideBar, selectedPath: '/admin/OEMUser' },
    '/admin/software_versions': { ...defaultAdminToolSideBar, selectedPath: '/admin/software_versions' },
    '/admin/all_drones_locations': { ...defaultAdminToolSideBar, selectedPath: '/admin' },
    '/admin/drone_flight_locations': { ...defaultAdminToolSideBar, selectedPath: '/admin' },
}
