// dashboard
import React, { Component } from 'react';
import * as API from '../api.js';
import Loader from '../icons/loader.svg';
import SideBar from '../SideBar';
import validator from 'validator'
import { AnimatedModal } from '../ReusableComponents/AnimatedModal'
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { updateProfileDetails } from '../redux';
import {
    Chart as ChartJS,
    BarElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
} from 'chart.js';
import { Line } from "react-chartjs-2";
import dateFormat from 'dateformat';
import { addUserAnalytic, updateOutTime, checkSubscriptionAndStorage } from '../ReusableComponents/reusableFunctions';
import { ActivityPopup } from '../ReusableComponents/ActivityPopup.js';
import greenReportActive from '../icons/greenReportActive.png'
import { SelectComponent } from '../ReusableComponents/SelectComponent'
import btnAdd from '../icons/GreenPlusIcon.png';
import closeBtn from '../icons/greenCloseButton.png'
import customerActive from '../icons/customerInactive.png'
import acre from '../icons/acer.png'
import crop from '../icons/crop.png'
import revenue from '../icons/revenue.png'
import greenDownArrow from '../icons/greenDownArrow.png'
import AeroMeghLogo from "../aeromegh-logo-new.png";
import AeroGcsWhite from '../icons/AeroGcsWhite.png'
import greenCropBlack from '../icons/greenCropBlack.png'
import greenBackground from '../icons/greenBackground.png'
import chartGrowDown from '../icons/chartGrowDown.png'
import chartGrowUp from '../icons/chartGrowUp.png'
import greenGrowthDownArrow from '../icons/greenGrowthDownArrow.png'
import greenGrowthUpArrow from '../icons/greenGrowthUpArrow.png'
import Menu from '../icons/menuGreen.png'
import Dropdown from "react-bootstrap/Dropdown"
import editIcon from "../edit.svg";
import deleteIcon from "../trash.svg";
import reportWhiteIcon from '../icons/reportWhiteIcon.png'
import customerWhiteIcon from '../icons/customerWhiteIcon.png'
import AppConstants from '../AppConstants.js';
import SubscriptionExpireModal from '../subscription/Reusable/SubscriptionExpireModal.js';

var someDate = new Date();
someDate.setDate(someDate.getDate());
var date = someDate.toISOString().substring(0, 10);


var today = new Date();
var lastWeek = new Date();
lastWeek.setFullYear(today.getFullYear())
lastWeek.setMonth(today.getMonth())
lastWeek.setDate(today.getDate() - 6)
let aa = lastWeek.toISOString().substring(0, 10);
// let markers = []

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href=""
        ref={ref}
        onClick={e => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
        <div style={{ height: "20px", width: "20px" }} alt="I am alt" >
            <img src={Menu} style={{ height: "16px" }} alt="I am alt" />
        </div>
    </a>
));

class AeroGCSGreenDashboard extends Component {
    state = {
        inviteMail: "",
        inviteModal: false,
        user: {},
        type: "",
        loading: true,
        showBackIcon: true,
        sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk"),
        validationDisplay: "none",
        validationMessage: "",
        teamsList: [],
        activeTeam: "",
        teamUsers: [],
        isUserTeamOwner: false,
        deleteUserName: "",
        teamLoading: true,
        itemName: "",
        showProcessPopup: false,
        processPopupIcon: "",
        processAction: "",
        processMessage: "",
        outId: "",
        selectedGreaphUnit: "week",
        selectedItem: [{ value: "week", label: "Week" }],
        itemsList: [{ value: "week", label: "Week" }, { value: "month", label: "Month" }, { value: "year", label: "Year" }],
        fromDate: aa,
        toDate: date,
        data: { datasets: [] },
        yTicks: {
            padding: 7,
            fill: "black",
            display: false,
            fontSize: 12,
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontColor: "#666666",
            lineHeight: "14px",
            beginAtZero: true,
        },
        color1: "rgba(75,161,100,0.35)",
        color2: '#4BA163',
        color3: "rgba(213,168,56,0.35)",
        color4: "#D4A936",
        allReports: [],
        usersCustomers: [],
        reportsLoading: true,
        reportDetailSelect: '',
        reportOnPopUp: false,
        addCustomerPopUp: false,
        customerName: '',
        contactNumber: '',
        contactNumberErromsg: '',
        customerAddress: '',
        customerGovIdNo: '',
        customerEmail: '',
        contactEmailErromsg: '',
        customerPilotLicenseId: '',
        gst_no: '',
        usersCustomers: [],
        custLoading: true,
        userDetailSelect: '',
        statLoading: false,
        totalRevenue: 0,
        totalAcres: 0,
        totalCustomers: 0,
        totalCrops: 0,
        allCrops: [],
        chartLoading: true,
        currentState: 'Good Morning',
        seasons: [],
        lastThreeMTotalAmount: 0,
        lastThreeMonths: [],
        monthPer: 0,
        monthper1: 0,
        monthper2: 0,
        monthOnMonthPer1: 0,
        monthOnMonthPer2: 0,
        monthOnMonthIncrease: 0,
        monthsGdata: {
            labels: ['a', 'b', 'c'],
            datasets: [{
                data: [0, 0, 0],
                fill: false,
                borderColor: '#06A36D',
                tension: 0.5,
            }]
        },
        seasonsOnSeasons: {
            labels: ['a', 'b', 'c'],
            datasets: [{
                data: [0, 0, 0],
                fill: false,
                borderColor: '#06A36D',
            }]
        },
        topCustomer: {},
        topCrop: {},
        addSeasonPopUp: false,
        seasonName: '',
        fromDateSeason: '',
        toDateSeason: '',
        editSeason: false,
        editSeasonData: '',
        minDate: new Date(),
        deleteSeasonId: '',
        deleteSeason: false,
        activeSeason: {},
        lastTwoSeasons: [],
        seasonsOnSeasonsIncrease: 0,
        lastSeasonIncrease: 0,
    }

    kFormatter = (value, len = false) => {
        var val = Math.abs(value)
        if (len) {
            if (val >= 1000000000) {
                let val1 = (val / 10000000).toFixed(1);
                return val1.replace(/(\d)(?=(\d{3})+\.)/g, '$1,') + ' Cr'
            } else {
                return val.toFixed(1).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
            }
        } else {
            if (val >= 10000000) {
                val = (val / 10000000).toFixed(1) + ' Cr';
            } else if (val >= 100000) {
                val = (val / 100000).toFixed(1) + ' Lac';
            }
            return val;
        }
    }


    onChangeEvent = (e) => {
        console.log(e);
        let value = e[0].value
        this.setState({
            selectedItem: e,
            selectedGreaphUnit: value,
            chartLoading: true,
        })
        if (value === 'week') {
            var lastWeek = new Date();
            lastWeek.setFullYear(today.getFullYear())
            lastWeek.setMonth(today.getMonth())
            lastWeek.setDate(today.getDate() - 6)
            let aa = lastWeek.toISOString().substring(0, 10);
            this.setState(
                (state) => ({
                    ...state,
                    fromDate: aa,
                }), () => this.getGreenSprayReportStat());
        } else if (value === 'month') {
            var lastMonth = new Date();
            lastMonth.setFullYear(today.getFullYear())
            lastMonth.setMonth(today.getMonth() - 1)
            lastMonth.setDate(today.getDate())
            let aa = lastMonth.toISOString().substring(0, 10);
            this.setState(
                (state) => ({
                    ...state,
                    fromDate: aa,
                }), () => this.getGreenSprayReportStat());
        } else if (value === 'year') {
            var lastYear = new Date();
            lastYear.setFullYear(today.getFullYear() - 1)
            lastYear.setMonth(today.getMonth() + 1)
            lastYear.setDate(1)
            let aa = lastYear.toISOString().substring(0, 10);
            this.setState((state) => ({
                ...state,
                fromDate: aa,
            }), () => this.getGreenSprayReportStat());
        }
    }

    getTime = () => {
        let td = new Date()
        let hr = td.getHours()
        if (hr < 12) {
            this.setState({ currentState: 'Good Morning' })
        } else if (hr < 18) {
            this.setState({ currentState: 'Good Afternoon' })
        } else {
            this.setState({ currentState: 'Good Evening' })
        }
    }

    parentSidebarSwitch = () => {
        this.setState((state) => ({
            ...state,
            sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
        }))
    }

    closeActivityPopup = () => {
        this.setState((state) => ({
            ...state,
            showProcessPopup: false,
            processMessage: null,
            deleteSeasonId: '',
            deleteSeason: false,
        }))
    }

    componentWillUnmount() { updateOutTime(this.state.outId) }

    componentWillMount() {
        ChartJS.register(BarElement, CategoryScale,
            ArcElement,
            LinearScale,
            PointElement,
            LineElement,
            Title,
            Tooltip,
            Legend,

        );
        addUserAnalytic(this.props.location.pathname).then(id => this.setState({ outId: id }))
        document.title = "Green dashboard - AeroGCSEnterprise";

        checkSubscriptionAndStorage(AppConstants.SERVICES.AEROGCS).then(data => {
            if (data.error || data.storageExceed || data.isExpired || !data.isValidStoragePlan) this.setState({ showSubscriptionExpirePopup: { ...data, user: this.props?.location?.state?.user } });
            else this.setState({ showSubscriptionExpirePopup: false, infoPopup: true });
        })

        if (this.props.location.state) {
            let { user, type } = this.props.location.state;
            console.log('props', this.props.location.state)
            this.setState((state) => ({
                ...state,
                user,
                type,
                activeTeam: this.props.activeTeamId,
                isUserTeamOwner: true,
            }), () => {
                this.getTime()
                this.getAllGreenSeason()
                this.getAllUserCustomer();
            })
        } else {
            API.getUserType().then(([blah, type, user]) => {
                this.setState((state) => ({
                    ...state,
                    user,
                    type,
                    activeTeam: this.props.activeTeamId,
                    isUserTeamOwner: true,
                }), () => {
                    this.getTime()
                    this.getAllGreenSeason()
                    this.getAllUserCustomer();
                })
            },
                (e) => {
                    window.location.replace("/login");
                }
            )
        }
    }

    onChangeHandler = (e) => {
        let t = e.target
        if (t.name == 'contactNumber') {
            if (t.value.length < 11) {
                this.setState((state) => ({
                    ...state,
                    [t.name]: t.value
                }))
            }
        } else if (t.name == 'customerEmail') {
            this.setState((state) => ({
                ...state,
                [t.name]: t.value.toLowerCase()
            }))
        } else if (t.name == 'customerGovIdNo') {
            this.setState((state) => ({
                ...state,
                [t.name]: t.value.toUpperCase()
            }))
        } else {
            this.setState((state) => ({
                ...state,
                [t.name]: t.value
            }))
        }
    }

    addUserCustomer = () => {
        if (this.state.contactNumber.length > 13 || this.state.contactNumber.length < 10) {
            this.setState((state) => ({
                ...state,
                contactNumberErromsg: 'Please enter valid contact number'
            }), () => {
                setTimeout(() => {
                    this.setState((state) => ({
                        ...state,
                        contactNumberErromsg: ''
                    }))
                }, 5000)
            })
        }
        if (!(validator.isEmail(this.state.customerEmail))) {
            this.setState((state) => ({
                ...state,
                contactEmailErromsg: 'Enter valid email address.'
            }), () => {
                setTimeout(() => {
                    this.setState((state) => ({
                        ...state,
                        contactEmailErromsg: ''
                    }))
                }, 5000)
            })
        }
        if (validator.isEmail(this.state.customerEmail) && this.state.contactNumber.length > 9) {
            this.setState((state) => ({
                ...state,
                addCustomerPopUp: false,
                itemName: "Customer",
                processPopupIcon: "WAIT",
                processAction: "ADD",
                processMessage: null,
                showProcessPopup: true,
            }), () => {
                let customerEmail = this.state.customerEmail == '' ? ' ' : this.state.customerEmail
                let customerAddress = this.state.customerAddress == '' ? ' ' : this.state.customerAddress
                let customerGovIdNo = this.state.customerGovIdNo == '' ? ' ' : this.state.customerGovIdNo
                let customerPilotLicenseId = this.state.customerPilotLicenseId == '' ? ' ' : this.state.customerPilotLicenseId
                let gst_no = this.state.gst_no == '' ? ' ' : this.state.gst_no
                API.addUserCustomer(this.state.customerName, this.state.contactNumber, customerEmail, customerAddress, customerGovIdNo, customerPilotLicenseId, gst_no, 'green').then((custAdd) => {
                    if (custAdd.message == "OK") {
                        this.setState((state) => ({
                            ...state,
                            addCustomerPopUp: false,
                            itemName: "Customer",
                            processPopupIcon: "COMPLETE",
                            processAction: "ADD",
                            processMessage: 'Customer added sucessfully',
                            showProcessPopup: true,
                        }), () => {
                            this.getAllUserCustomer()
                            this.setState((state) => ({
                                ...state,
                                addCustomerPopUp: false,
                                customerName: '',
                                contactNumber: '',
                                customerEmail: '',
                                customerAddress: '',
                                customerGovIdNo: '',
                                customerPilotLicenseId: '',
                                gst_no: '',
                            }))
                        })
                    } else {
                        this.setState((state) => ({
                            ...state,
                            addCustomerPopUp: false,
                            itemName: "Customer",
                            processPopupIcon: "ERROR",
                            processAction: "ADD",
                            processMessage: custAdd.message,
                            showProcessPopup: true,
                        }))
                    }
                }, (e) => {
                    this.setState((state) => ({
                        ...state,
                        addCustomerPopUp: false,
                        itemName: "Customer",
                        processPopupIcon: "ERROR",
                        processAction: "ADD",
                        processMessage: 'Something went wrong while adding customer.',
                        showProcessPopup: true,
                    }))
                })
            })
        }
    }

    getAllUserCustomer = () => {
        API.getAllUserCustomer('green', 10).then((data) => {
            if (data.usersCustomers && data.usersCustomers.length > 0) {
                this.setState((state) => ({
                    ...state,
                    usersCustomers: data.usersCustomers
                }), () => {
                    this.setState({ custLoading: false })
                })
            } else {
                this.setState((state) => ({
                    ...state,
                    usersCustomers: []
                }), () => {
                    this.setState({ custLoading: false })
                })
            }
        }, (e) => {
            console.log(e)
        })
    }

    deleteUserCustomer = () => {
        this.setState((state) => ({
            ...state,
            addCustomerPopUp: false,
            itemName: "Customer",
            processPopupIcon: "WAIT",
            processAction: "DELETE",
            processMessage: null,
            showProcessPopup: true,
        }), () => {
            API.deleteUserCustomer(this.state.userDetailSelect.id).then((deleteRes) => {
                if (deleteRes.message == "OK") {
                    this.setState((state) => ({
                        ...state,
                        userOnPopUp: false,
                        itemName: "Customer",
                        processPopupIcon: "COMPLETE",
                        processAction: "DELETE",
                        processMessage: 'Customer Deleted sucessfully',
                        showProcessPopup: true,
                        userDetailSelect: ''
                    }), () => {
                        this.getAllUserCustomer()
                    })
                } else {
                    this.setState((state) => ({
                        ...state,
                        itemName: "Customer",
                        processPopupIcon: "ERROR",
                        processAction: "DELETE",
                        processMessage: deleteRes.message,
                        showProcessPopup: true,
                    }))
                }
            }, (e) => {
                this.setState((state) => ({
                    ...state,
                    itemName: "Customer",
                    processPopupIcon: "ERROR",
                    processAction: "DELETE",
                    processMessage: 'Something went wrong while deleting customer.',
                    showProcessPopup: true,
                }))
            })
        })
    }

    getPertage = (value1, value2, msg) => {
        if (value2 == 0) {
            return (0)
        } else {
            let a = value2 - value1
            let b = a / value2
            let c = b * 100
            return (c)
        }
    }

    getGreenSprayReportStat = async () => {
        let graphUnit = this.state.selectedGreaphUnit;
        let toDate = this.state.toDate
        let fromDate = this.state.fromDate
        toDate = new Date(toDate + " 23:59:00+00")
        toDate = toDate.getTime() / 1000
        fromDate = new Date(fromDate + " 00:00:00+00")
        fromDate = fromDate.getTime() / 1000
        API.getGreenSprayReportStat(graphUnit, fromDate, toDate).then(async (data) => {
            if (!data.message) {
                let yTicks = {
                    callback: v => this.kFormatter(v),
                    padding: 7,
                    fill: "black",
                    display: true,
                    fontSize: 12,
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontColor: "#666666",
                    lineHeight: "14px",
                    beginAtZero: true,
                }

                this.setState((state) => ({
                    ...state,
                    yTicks: yTicks,
                    totalAcres: data.totalAcres,
                    totalCustomers: data.totalCustomers,
                    totalRevenue: data.totalRevenue,
                    totalCrops: data.totalCrops,
                    allCrops: data.allCrops,
                    topCustomer: data.topCust,
                    topCrop: data.allCrops.length > 0 ? data.allCrops[0] : {},
                    lastThreeMTotalAmount: data.lastThreeMTotalAmount,
                    lastThreeMonths: data.lastThreeMonths,
                    monthOnMonthPer2: this.getPertage(Number(data.lastThreeMonths[1].amount), Number(data.lastThreeMonths[2].amount), 'monthonMonth'),
                    monthPer: Number(data.lastThreeMonths[2].amount) - Number(data.lastThreeMonths[0].amount) - Number(data.lastThreeMonths[1].amount),
                    lastTwoSeasons: data.lastTwoSeasons
                }), () => {
                    let monthOnMonthIncrease = Number(data.lastThreeMonths[2].amount) - Number(data.lastThreeMonths[1].amount)
                    let mvalue = [0, data.lastThreeMonths[1].amount, data.lastThreeMonths[2].amount]
                    let mlables = ['d', data.lastThreeMonths[1].monthName, data.lastThreeMonths[2].monthName]
                    let s1Value = data.lastTwoSeasons[1] ? Number(data.lastTwoSeasons[1].seasonTotalAmount) : 0
                    let s2Value = data.lastTwoSeasons[0] ? Number(data.lastTwoSeasons[0].seasonTotalAmount) : 0
                    let svalue = [0, s1Value, s2Value]
                    this.setState({
                        monthOnMonthIncrease: monthOnMonthIncrease,
                        seasonsOnSeasonsIncrease: this.getPertage(s1Value, s2Value, 'seasonOnseason'),
                        lastSeasonIncrease: s2Value - s1Value,
                        seasonsOnSeasons: {
                            labels: mlables,
                            datasets: [{
                                tension: 0.5,
                                data: svalue,
                                fill: false,
                                borderColor: '#06A36D',
                            }]
                        },
                        monthsGdata: {
                            labels: mlables,
                            datasets: [{
                                data: mvalue,
                                fill: false,
                                borderColor: '#06A36D',
                                tension: 0.5
                            }]
                        }, loading: false, reportsLoading: false
                    })
                })

                if (this.state.selectedGreaphUnit == 'week') {
                    let data1 = (canvas) => {
                        return {
                            labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
                            datasets: [
                                {
                                    label: 'Revenue',
                                    data: data.data.amount,
                                    fill: false,
                                    borderColor: '#0D9164',
                                    backgroundColor: '#0D9164',
                                    lineTension: 0.1,
                                },
                                {
                                    label: 'Acreage',
                                    data: data.data.acreage,
                                    fill: false,
                                    borderColor: '#E88560',
                                    backgroundColor: '#E88560',
                                    lineTension: 0.1,
                                },
                            ],
                        }
                    }
                    this.setState((state) => ({
                        ...state,
                        data: data1(),
                        xAxisLabel: "Days",
                        chartLoading: false,
                    })
                    );
                } else if (this.state.selectedGreaphUnit == 'month') {
                    const data1 = (canvas) => {
                        return {
                            labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
                            datasets: [
                                {
                                    label: 'Revenue',
                                    data: data.data.amount,
                                    fill: false,
                                    borderColor: '#0D9164',
                                    backgroundColor: '#0D9164',
                                    lineTension: 0.1,
                                },
                                {
                                    label: 'Acreage',
                                    data: data.data.acreage,
                                    fill: false,
                                    borderColor: '#E88560',
                                    backgroundColor: '#E88560',
                                    lineTension: 0.1,
                                },
                            ],
                        }
                    }
                    this.setState((state) => ({
                        ...state,
                        data: data1(),
                        xAxisLabel: "Days",
                        chartLoading: false,
                    })
                    );
                } else if (this.state.selectedGreaphUnit == 'year') {
                    const data1 = (canvas) => {
                        return {
                            labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"],
                            datasets: [
                                {
                                    label: 'Revenue',
                                    data: data.data.amount,
                                    fill: false,
                                    borderColor: '#0D9164',
                                    backgroundColor: '#0D9164',
                                    lineTension: 0.1,
                                },
                                {
                                    label: 'Acreage',
                                    data: data.data.acreage,
                                    fill: false,
                                    borderColor: '#E88560',
                                    backgroundColor: '#E88560',
                                    lineTension: 0.1,
                                },
                            ],
                        }
                    }
                    this.setState((state) => ({
                        ...state,
                        data: data1(),
                        xAxisLabel: "Months",
                        chartLoading: false,
                    })
                    );
                }
            } else {
                this.setState((state) => ({
                    ...state,
                    itemName: "ReportStat",
                    processPopupIcon: "ERROR",
                    processAction: "GET",
                    processMessage: data.message,
                    showProcessPopup: true,
                    loading: false,

                }))
            }
        },
            (err) => {
                console.log(err);
                this.setState((state) =>
                ({
                    ...state,
                    loading: false
                }))
            })
    }

    addGreenSeason = () => {
        if (this.state.editSeason) {
            this.setState((state) => ({
                ...state,
                addLeaseDronePopUp: false,
                itemName: "Season",
                processPopupIcon: "WAIT",
                processAction: "UPDATE",
                processMessage: null,
                showProcessPopup: true,
            }), () => {
                API.updateGreenSeason(this.state.editSeasonData.id, this.state.seasonName, this.state.fromDateSeason, this.state.toDateSeason).then((seasonRes) => {
                    if (seasonRes.message) {
                        this.setState((state) => ({
                            ...state,
                            addSeasonPopUp: false,
                            itemName: "season",
                            processPopupIcon: "COMPLETE",
                            processAction: "UPDATE",
                            processMessage: 'Season updated sucessfully',
                            showProcessPopup: true,
                            seasonName: '',
                            fromDateSeason: '',
                            toDateSeason: '',
                            editSeason: false,
                            editSeasonData: '',
                        }), () => {
                            this.getAllGreenSeason()
                        })
                    } else {
                        this.setState((state) => ({
                            ...state,
                            addSeasonPopUp: false,
                            itemName: "Season",
                            processPopupIcon: "ERROR",
                            processAction: "UPDATE",
                            processMessage: seasonRes.errorMessage ? seasonRes.errorMessage : seasonRes.error,
                            showProcessPopup: true,
                        }))
                    }

                }, (e) => {
                    this.setState((state) => ({
                        ...state,
                        addSeasonPopUp: false,
                        itemName: "Season",
                        processPopupIcon: "ERROR",
                        processAction: "UPDATE",
                        processMessage: 'Something went wrong while updating season.',
                        showProcessPopup: true,
                    }))
                })
            })
        } else {
            this.setState((state) => ({
                ...state,
                addLeaseDronePopUp: false,
                itemName: "Season",
                processPopupIcon: "WAIT",
                processAction: "ADD",
                processMessage: null,
                showProcessPopup: true,
            }), () => {
                API.addGreenSeason(this.state.seasonName, this.state.fromDateSeason, this.state.toDateSeason).then((seasonRes) => {
                    if (seasonRes.message) {
                        this.setState((state) => ({
                            ...state,
                            addSeasonPopUp: false,
                            itemName: "season",
                            processPopupIcon: "COMPLETE",
                            processAction: "ADD",
                            processMessage: 'Season added sucessfully',
                            showProcessPopup: true,
                            seasonName: '',
                            fromDateSeason: '',
                            toDateSeason: '',
                            editSeason: false,
                            editSeasonData: '',
                        }), () => {
                            this.getAllGreenSeason()
                        })
                    } else {
                        this.setState((state) => ({
                            ...state,
                            addSeasonPopUp: false,
                            itemName: "Season",
                            processPopupIcon: "ERROR",
                            processAction: "ADD",
                            processMessage: seasonRes.errorMessage ? seasonRes.errorMessage : seasonRes.error,
                            showProcessPopup: true,
                        }))
                    }

                }, (e) => {
                    this.setState((state) => ({
                        ...state,
                        addSeasonPopUp: false,
                        itemName: "Season",
                        processPopupIcon: "ERROR",
                        processAction: "ADD",
                        processMessage: 'Something went wrong while adding season.',
                        showProcessPopup: true,
                    }))
                })
            })
        }

    }

    getAllGreenSeason = () => {
        API.getAllGreenSeason(10).then((data) => {
            if (data.seasons) {
                let newDate = new Date()
                this.setState((state) => ({
                    ...state,
                    seasons: data.seasons,
                    activeSeason: data.seasons.find(e => new Date(e.start_date) <= newDate && new Date(e.end_date) >= newDate)
                }), () => {
                    this.getGreenSprayReportStat()
                })
            } else {
                this.setState((state) => ({
                    ...state,
                    addSeasonPopUp: false,
                    itemName: "Season",
                    processPopupIcon: "ERROR",
                    processAction: "ADD",
                    processMessage: data.errorMessage ? data.errorMessage : data.error,
                    showProcessPopup: true,
                }))
            }
        }, (e) => {
            this.setState((state) => ({
                ...state,
                addSeasonPopUp: false,
                itemName: "Season",
                processPopupIcon: "ERROR",
                processAction: "ADD",
                processMessage: 'Something went wrong while getting season.',
                showProcessPopup: true,
            }))
        })
    }


    deleteGreenSeason = () => {
        this.setState((state) => ({
            ...state,
            addCustomerPopUp: false,
            itemName: "Season",
            processPopupIcon: "WAIT",
            processAction: "DELETE",
            processMessage: null,
            showProcessPopup: true,
        }), () => {
            API.deleteGreenSeason(this.state.deleteSeasonId).then((deleteRes) => {
                if (deleteRes.message) {
                    this.setState((state) => ({
                        ...state,
                        itemName: "Season",
                        processPopupIcon: "COMPLETE",
                        processAction: "DELETE",
                        processMessage: 'Season Deleted sucessfully',
                        showProcessPopup: true,
                        deleteSeasonId: '',
                        deleteSeason: false,
                    }), () => {
                        this.getAllGreenSeason()
                    })
                } else {
                    this.setState((state) => ({
                        ...state,
                        itemName: "Season",
                        processPopupIcon: "ERROR",
                        processAction: "DELETE",
                        processMessage: deleteRes.errorMessage ? deleteRes.errorMessage : deleteRes.error,
                        showProcessPopup: true,
                        deleteSeasonId: '',
                        deleteSeason: false,
                    }))
                }
            }, (e) => {
                this.setState((state) => ({
                    ...state,
                    itemName: "Season",
                    processPopupIcon: "ERROR",
                    processAction: "DELETE",
                    processMessage: 'Something went wrong while deleting Season.',
                    showProcessPopup: true,
                    deleteSeasonId: '',
                    deleteSeason: false,
                }))
            })
        })
    }

    render() {
        return (
            <>
                {this.state.user.username ? <div className="wrapper">
                    <div className="right-content-new"
                        onClick={
                            () => {
                                this.setState((state) => ({
                                    ...state, sidebarMode: 'shrunk'
                                }))
                            }
                        }
                    >
                        <div className="top-bar-new"><div className="top-bar-text-new" style={{ color: 'black' }}>{"AeroGCS Green"}</div></div>
                        {this.state.loading ? <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
                            <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
                        </div> : <div className="main-content-new" style={{ background: '#F2F1EF', display: 'flex', justifyContent: 'space-between', overflow: 'hidden' }} >

                            {
                                this.state.showSubscriptionExpirePopup ?
                                    <SubscriptionExpireModal
                                        data={this.state.showSubscriptionExpirePopup}
                                    /> : <></>
                            }
                            {/* -------------- Activity Popup ----------------------- */}
                            <ActivityPopup
                                item={this.state.itemName}
                                open={this.state.showProcessPopup}
                                icon={this.state.processPopupIcon}
                                action={this.state.processAction}
                                msg={this.state.processMessage}
                                close={() => this.closeActivityPopup()}
                                onClickOk={() => this.state.deleteSeason == false && this.state.userOnPopUp ? this.deleteUserCustomer() : this.deleteGreenSeason()}
                            />

                            {/* // detailed reports popup */}
                            <AnimatedModal
                                isOpen={this.state.reportOnPopUp}
                                height="375px"
                                width="600px"
                            >
                                <div style={{ width: "100%", height: "100%", color: "black", }}>

                                    <div
                                        style={{
                                            padding: '10px 40px 0px 40px',
                                            textAlign: "left",
                                            fontSize: "15px",
                                            fontWeight: "500",
                                        }}
                                    >
                                        Report Details
                                    </div>
                                    <img src={closeBtn} style={{ width: '22px', position: 'absolute', top: '5px', right: '5px', cursor: 'pointer' }} onClick={() => {
                                        this.setState((state) => ({
                                            ...state,
                                            reportDetailSelect: '',
                                            reportOnPopUp: false,
                                        }));
                                    }} />
                                    <hr style={{ margin: '5px 0px 0px 0px', padding: '0px', width: '100%', background: '#9AD8AB' }} />
                                    <div style={{ padding: '0px 40px 10px 40px', }}>
                                        <div style={{ margin: '15px 0px', display: 'flex', width: '100%' }}>
                                            <div style={{ width: '60%' }}>
                                                <div className="itemKey1" style={{ textAlign: "left", lineHeight: '15px' }}>Customer Name</div>
                                                <div className="services-content-titleGreen" style={{ textAlign: "left", lineHeight: '20px' }}>{this.state.reportDetailSelect.customer_name ? this.state.reportDetailSelect.customer_name.length > 25 ?
                                                    `${this.state.reportDetailSelect.customer_name.substring(0, 25)}...` :
                                                    `${this.state.reportDetailSelect.customer_name}` : 'Unknown'}</div>
                                            </div>
                                            <div style={{ width: '40%' }}>
                                                <div className="itemKey1" style={{ textAlign: "left", lineHeight: '15px' }}>Crop Type</div>
                                                <div className="services-content-titleGreen" style={{ textAlign: "left", lineHeight: '20px' }}>{this.state.reportDetailSelect.crop_type ? this.state.reportDetailSelect.crop_type.length > 25 ?
                                                    `${this.state.reportDetailSelect.crop_type.substring(0, 25)}...` :
                                                    `${this.state.reportDetailSelect.crop_type}` : 'Unknown'}</div>
                                            </div>
                                        </div>

                                        <div style={{ margin: '15px 0px', display: 'flex', width: '100%' }}>
                                            <div style={{ width: '60%' }}>
                                                <div className="itemKey1" style={{ textAlign: "left", lineHeight: '15px' }}>Project Name</div>
                                                <div className="services-content-titleGreen" style={{ textAlign: "left", lineHeight: '20px' }}>{this.state.reportDetailSelect.project_name ? this.state.reportDetailSelect.project_name.length > 25 ?
                                                    `${this.state.reportDetailSelect.project_name.substring(0, 25)}...` :
                                                    `${this.state.reportDetailSelect.project_name}` : 'Unknown'}</div>
                                            </div>
                                            <div style={{ width: '40%' }}>
                                                <div className="itemKey1" style={{ textAlign: "left", lineHeight: '15px' }}>Plan Name</div>
                                                <div className="services-content-titleGreen" style={{ textAlign: "left", lineHeight: '20px' }}>{this.state.reportDetailSelect.plan_name ? this.state.reportDetailSelect.plan_name.length > 25 ?
                                                    `${this.state.reportDetailSelect.plan_name.substring(0, 25)}...` :
                                                    `${this.state.reportDetailSelect.plan_name}` : 'Unknown'}</div>
                                            </div>
                                        </div>

                                        <div style={{ margin: '15px 0px', display: 'flex', width: '100%' }}>
                                            <div style={{ width: '60%' }}>
                                                <div className="itemKey1" style={{ textAlign: "left", lineHeight: '15px' }}>Flight Time</div>
                                                <div className="services-content-titleGreen" style={{ textAlign: "left", lineHeight: '20px' }}>{this.state.reportDetailSelect.flight_time}</div>
                                            </div>
                                            <div style={{ width: '40%' }}>
                                                <div className="itemKey1" style={{ textAlign: "left", lineHeight: '15px' }}>Area</div>
                                                <div className="services-content-titleGreen" style={{ textAlign: "left", lineHeight: '20px' }}>{this.state.reportDetailSelect.spray_area} sq.m</div>
                                            </div>
                                        </div>

                                        <div style={{ margin: '15px 0px', display: 'flex', width: '100%' }}>
                                            <div style={{ width: '60%' }}>
                                                <div className="itemKey1" style={{ textAlign: "left", lineHeight: '15px' }}>Amount</div>
                                                <div className="services-content-titleGreen" style={{ textAlign: "left", lineHeight: '20px' }}>{this.state.reportDetailSelect.amount} Rs.</div>
                                            </div>
                                            <div style={{ width: '40%' }}>
                                                <div className="itemKey1" style={{ textAlign: "left", lineHeight: '15px' }}>Created Date</div>
                                                <div className="services-content-titleGreen" style={{ textAlign: "left", lineHeight: '20px' }}>{dateFormat(this.state.reportDetailSelect.created_datetime, 'dd/mm/yyyy')}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </AnimatedModal>
                            {/* // user detailed popup */}
                            <AnimatedModal
                                isOpen={this.state.userOnPopUp}
                                height="375px"
                                width="600px"
                            >
                                <div style={{ width: "100%", height: "100%", color: "black", }}>
                                    <div
                                        style={{
                                            padding: '10px 40px 0px 40px',
                                            textAlign: "left",
                                            fontSize: "15px",
                                            fontWeight: "500",
                                        }}
                                    >
                                        User Details
                                    </div>
                                    <img src={closeBtn} style={{ width: '22px', position: 'absolute', top: '5px', right: '5px', cursor: 'pointer' }} onClick={() => {
                                        this.setState((state) => ({
                                            ...state,
                                            userDetailSelect: '',
                                            userOnPopUp: false,
                                        }));
                                    }} />
                                    <hr style={{ margin: '5px 0px 0px 0px', padding: '0px', width: '100%', background: '#9AD8AB', color: '#9AD8AB' }} />
                                    <div style={{ padding: '0px 40px 10px 40px', }}>
                                        <div style={{ margin: '15px 0px', display: 'flex', width: '100%' }}>
                                            <div style={{ width: '60%' }}>
                                                <div className="itemKey1" style={{ textAlign: "left", fontSize: '12px' }}>Customer Name</div>
                                                <div className="services-content-titleGreen" style={{ textAlign: "left", fontSize: '14px' }}>{this.state.userDetailSelect.customer_name ? this.state.userDetailSelect.customer_name.length > 25 ?
                                                    `${this.state.userDetailSelect.customer_name.substring(0, 25)}...` :
                                                    `${this.state.userDetailSelect.customer_name}` : 'Unknown'}</div>
                                            </div>
                                            <div style={{ width: '40%' }}>
                                                <div className="itemKey1" style={{ textAlign: "left", fontSize: '12px' }}>Contact Number</div>
                                                <div className="services-content-titleGreen" style={{ textAlign: "left", fontSize: '14px' }}>{this.state.userDetailSelect.contact_number ? this.state.userDetailSelect.contact_number.length > 25 ?
                                                    `${this.state.userDetailSelect.contact_number.substring(0, 25)}...` :
                                                    `${this.state.userDetailSelect.contact_number}` : '-'}</div>
                                            </div>
                                        </div>

                                        <div style={{ margin: '15px 0px', display: 'flex', width: '100%' }}>
                                            <div style={{ width: '60%' }}>
                                                <div className="itemKey1" style={{ textAlign: "left", fontSize: '12px' }}>Email</div>
                                                <div className="services-content-titleGreen" style={{ textAlign: "left", fontSize: '14px' }}>{this.state.userDetailSelect.email ? this.state.userDetailSelect.email.length > 25 ?
                                                    `${this.state.userDetailSelect.email.substring(0, 25)}...` :
                                                    `${this.state.userDetailSelect.email}` : '-'}</div>
                                            </div>
                                            <div style={{ width: '40%' }}>
                                                <div className="itemKey1" style={{ textAlign: "left", fontSize: '12px' }}>Government Id Number</div>
                                                <div className="services-content-titleGreen" style={{ textAlign: "left", fontSize: '14px' }}>{this.state.userDetailSelect.government_id ? this.state.userDetailSelect.government_id.length > 25 ?
                                                    `${this.state.userDetailSelect.government_id.substring(0, 25)}...` :
                                                    `${this.state.userDetailSelect.government_id}` : '-'}</div>
                                            </div>
                                        </div>

                                        <div style={{ margin: '15px 0px', display: 'flex', width: '100%' }}>
                                            <div style={{ width: '60%' }}>
                                                <div className="itemKey1" style={{ textAlign: "left", fontSize: '12px' }}>Address</div>
                                                <div className="services-content-titleGreen" style={{ textAlign: "left", fontSize: '14px' }}>{this.state.userDetailSelect.address ? this.state.userDetailSelect.address : '-'}</div>
                                            </div>
                                            <div style={{ width: '40%' }}>
                                                <div className="itemKey1" style={{ textAlign: "left", fontSize: '12px' }}>GST Number</div>
                                                <div className="services-content-titleGreen" style={{ textAlign: "left", fontSize: '14px' }}>{this.state.userDetailSelect.gst_no ? this.state.userDetailSelect.gst_no : '-'}</div>
                                            </div>
                                        </div>

                                        <div style={{ margin: '15px 0px', display: 'flex', width: '100%' }}>
                                            <div style={{ width: '60%' }}>
                                                <div className="itemKey1" style={{ textAlign: "left", fontSize: '12px' }}>Total Acre</div>
                                                <div className="services-content-titleGreen" style={{ textAlign: "left", fontSize: '14px' }}>{this.state.userDetailSelect.totalAcres ? this.state.userDetailSelect.totalAcres : '0'} Acr</div>
                                            </div>
                                            <div style={{ width: '40%' }}>
                                                <div className="itemKey1" style={{ textAlign: "left", fontSize: '12px' }}>Total Amount</div>
                                                <div className="services-content-titleGreen" style={{ textAlign: "left", fontSize: '14px' }}>{this.state.userDetailSelect.totalRevenue ? this.state.userDetailSelect.totalRevenue : '0'} Rs</div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </AnimatedModal>

                            {/* // add customer popup  */}
                            <AnimatedModal
                                isOpen={this.state.addCustomerPopUp}
                                height="480px"
                                width="600px"
                            >
                                <div style={{ width: "100%", height: "100%" }}>
                                    <div className="services-content-titleGreen" style={{ padding: '10px 40px 0px 40px', textAlign: 'left' }}>
                                        Add Customer
                                    </div>
                                    <hr style={{ margin: '5px 0px 0px 0px', padding: '0px', width: '100%', background: '#9AD8AB' }} />
                                    <div style={{ padding: '0px 20px 10px 20px', }}>
                                        <div style={{ margin: '15px 20px 0px 20px', display: 'flex', justifyContent: 'space-between', height: '75px', }}>
                                            <div style={{ textAlign: "left", width: '45%', }}>
                                                <div className="itemKey1">Name<span style={{ color: 'red', }}>*</span></div>
                                                <input type="text" maxLength='30' name="customerName" value={this.state.customerName} className="add-user-form-textGreen greenAllSideShadowDiv" placeholder="Name" style={{ width: "100%" }} onChange={this.onChangeHandler} />
                                            </div>
                                            <div style={{ textAlign: "left", width: '45%', }}>
                                                <div className="itemKey1">Contact Number<span style={{ color: 'red', }}>*</span></div>
                                                <input type="number" name="contactNumber" value={this.state.contactNumber} className="add-user-form-textGreen greenAllSideShadowDiv" placeholder="Contact Number" style={{ width: "100%" }} onChange={this.onChangeHandler} />
                                                {this.state.contactNumberErromsg ? <div style={{ fontSize: '11px', color: 'red' }} >{this.state.contactNumberErromsg}</div> : ''}
                                            </div>
                                        </div>

                                        <div style={{ margin: '5px 20px 0px 20px', display: 'flex', justifyContent: 'space-between', height: '75px', }}>
                                            <div style={{ textAlign: "left", width: '45%', }}>
                                                <div className="itemKey1">Address<span style={{ color: 'red', }}>*</span></div>
                                                <input type="text" maxLength='30' name="customerAddress" value={this.state.customerAddress} className="add-user-form-textGreen greenAllSideShadowDiv" placeholder="Address" style={{ width: "100%" }} onChange={this.onChangeHandler} />
                                            </div>
                                            <div style={{ textAlign: "left", width: '45%', }}>
                                                <div className="itemKey1">Government Id Number<span style={{ color: 'red', }}>*</span></div>
                                                <input type="text" maxLength='12' name="customerGovIdNo" value={this.state.customerGovIdNo} className="add-user-form-textGreen greenAllSideShadowDiv" placeholder="PAN or Addhar Number" style={{ width: "100%" }} onChange={this.onChangeHandler} />
                                            </div>
                                        </div>

                                        <div style={{ margin: '5px 20px 0px 20px', display: 'flex', justifyContent: 'space-between', height: '75px', }}>
                                            <div style={{ textAlign: "left", width: '45%', }}>
                                                <div className="itemKey1">Email<span style={{ color: 'red', }}>*</span></div>
                                                <input type="text" maxLength='50' name="customerEmail" value={this.state.customerEmail} className="add-user-form-textGreen greenAllSideShadowDiv" placeholder="Email" style={{ width: "100%" }} onChange={this.onChangeHandler} />
                                                {this.state.contactEmailErromsg ? <div style={{ fontSize: '11px', color: 'red' }} >{this.state.contactEmailErromsg}</div> : ''}
                                            </div>
                                            <div style={{ textAlign: "left", width: '45%', }}>
                                                <div className="itemKey1">Pilot License Id</div>
                                                <input type="text" maxLength='20' name="customerPilotLicenseId" value={this.state.customerPilotLicenseId} className="add-user-form-textGreen greenAllSideShadowDiv" placeholder="Pilot License Id Number" style={{ width: "100%" }} onChange={this.onChangeHandler} />
                                            </div>
                                        </div>
                                        <div style={{ margin: '5px 20px 0px 20px', display: 'flex', justifyContent: 'space-between', height: '75px', }}>
                                            <div style={{ textAlign: "left", width: '45%', }}>
                                                <div className="itemKey1">GST Number</div>
                                                <input type="text" maxLength='15' name="gst_no" value={this.state.gst_no} className="add-user-form-textGreen greenAllSideShadowDiv" placeholder="gst_no" style={{ width: "100%" }} onChange={this.onChangeHandler} />
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', margin: '30px 20px', }}>
                                            <button
                                                className="addcustomer-button-empty-dashboard popup-btn-left"
                                                onClick={() => {
                                                    this.setState((state) => ({
                                                        ...state,
                                                        addCustomerPopUp: false,
                                                        customerName: '',
                                                        contactNumber: '',
                                                        customerEmail: '',
                                                        customerAddress: '',
                                                        customerGovIdNo: '',
                                                        customerPilotLicenseId: '',
                                                        gst_no: ''
                                                    }));
                                                }}
                                            >
                                                cancel
                                            </button>
                                            {this.state.customerName && this.state.contactNumber && this.state.customerAddress && this.state.customerGovIdNo && this.state.customerEmail ? <button
                                                className="add-user-form-submit-button popup-btn-right btn-ok"
                                                onClick={() => {
                                                    this.state.custEdit ? this.updateUserCustomer() : this.addUserCustomer()
                                                }}
                                            >
                                                Save
                                            </button> : <button style={{ opacity: '0.5', cursor: 'auto' }}
                                                className="add-user-form-submit-button popup-btn-right btn-ok">
                                                Save
                                            </button>}
                                        </div>
                                    </div>
                                </div>
                            </AnimatedModal>

                            {/* // add seasons popup  */}
                            <AnimatedModal
                                isOpen={this.state.addSeasonPopUp}
                                height="300px"
                                width="450px"
                            >
                                <div style={{ width: "100%", height: "100%", }}>
                                    <div className="services-content-titleGreen"
                                        style={{
                                            padding: '10px 40px 0px 40px',
                                            textAlign: "left",
                                        }}
                                    >
                                        {this.state.editSeason ? 'Update Season' : 'Add Season'}
                                    </div>
                                    <hr style={{ margin: '5px 0px 0px 0px', padding: '0px', width: '100%' }} />

                                    <div style={{ padding: '0px 20px 10px 20px', }}>
                                        <div style={{ margin: '15px', display: 'flex', justifyContent: 'space-between', height: '75px', }}>
                                            <div style={{ textAlign: "left", width: '100%', }}>
                                                <div className="content-text">Name<span style={{ color: 'red', }}>*</span></div>
                                                <input type="text" maxLength='30' name="seasonName" value={this.state.seasonName} className="add-user-form-text add-user-form-textGreen" placeholder="Name" style={{ width: "100%" }} onChange={this.onChangeHandler} />
                                            </div>
                                        </div>

                                        <div style={{ margin: '15px', display: 'flex', justifyContent: 'space-between', height: '75px', }}>
                                            <div style={{ textAlign: "left", width: '45%', }}>
                                                <div className="content-text" >Start Date</div>
                                                <div>
                                                    <input
                                                        id="dateRequired"
                                                        name="dateRequired"
                                                        className="greenCalenderIcon greenAllSideShadowDiv"
                                                        type="date"
                                                        max='9000-01-01'
                                                        min={dateFormat(this.state.minDate, 'yyyy-mm-dd')}
                                                        onChange={(e) => {
                                                            let value = e.target.value;
                                                            this.setState((state) => ({
                                                                ...state,
                                                                fromDateSeason: value,
                                                            }))
                                                        }}
                                                        value={dateFormat(this.state.fromDateSeason, 'yyyy-mm-dd')}
                                                        style={{
                                                            border: '1px solid #b4b4bb',
                                                            height: "auto",
                                                            borderRadius: '6px',
                                                            paddingLeft: '5px',
                                                            width: "100%",
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div style={{ textAlign: "left", width: '45%', }}>
                                                <div className="content-text" >End Date</div>
                                                <div>
                                                    <input
                                                        id="dateRequired"
                                                        name="dateRequired"
                                                        className="greenCalenderIcon greenAllSideShadowDiv"
                                                        type="date"
                                                        max='9000-01-01'
                                                        min={dateFormat(this.state.fromDateSeason ? this.state.fromDateSeason : this.state.minDate, 'yyyy-mm-dd')}
                                                        onChange={(e) => {
                                                            let value = e.target.value;
                                                            this.setState((state) => ({
                                                                ...state,
                                                                toDateSeason: value,
                                                            }))
                                                        }}
                                                        value={dateFormat(this.state.toDateSeason, 'yyyy-mm-dd')}
                                                        style={{
                                                            border: '1px solid #b4b4bb',
                                                            height: "auto",
                                                            borderRadius: '6px',
                                                            paddingLeft: '5px',
                                                            width: "100%",
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', margin: '10px', }}>
                                            <button
                                                className="addcustomer-button-empty-dashboard popup-btn-left"
                                                onClick={() => {
                                                    this.setState((state) => ({
                                                        ...state,
                                                        addSeasonPopUp: false,
                                                        editSeasonData: '',
                                                        seasonName: '',
                                                        fromDateSeason: '',
                                                        toDateSeason: '',
                                                        editSeason: false,
                                                        editSeasonData: '',
                                                    }));
                                                }}
                                            >
                                                cancel
                                            </button>
                                            {this.state.fromDateSeason && this.state.toDateSeason && this.state.seasonName ? <button
                                                className="add-user-form-submit-button popup-btn-right btn-ok"
                                                onClick={() => {
                                                    this.addGreenSeason()
                                                }}
                                            >
                                                {this.state.editSeason ? 'Update' : 'Save'}
                                            </button> : <button style={{ opacity: '0.5', cursor: 'auto' }}
                                                className="add-user-form-submit-button popup-btn-right btn-ok">
                                                {this.state.editSeason ? 'Update' : 'Save'}
                                            </button>}
                                        </div>
                                    </div>
                                </div>
                            </AnimatedModal>


                            <div style={{ width: '74%' }}>
                                <div style={{ width: '100%', height: '20vh', }}>
                                    <div style={{ fontSize: '15px', fontWeight: '500', letterSpacing: '1px', color: 'black', height: '25px' }}>{this.state.user ? `${this.state.currentState}, ${this.state.user.first_name}` : ''}</div>
                                    <div className='greenCards'>
                                        <div className='greendashCard greenAllSideShadowDiv' style={{ background: '#2E9FC1' }}>
                                            <div>
                                                <div className='greenCardMain'>Revenue</div>
                                                <div className='greenCardSub'>Total Revenue</div>
                                                <div className='greenCardMain' style={{ marginTop: '5px' }}>{this.kFormatter(Number(this.state.totalRevenue), true)} Rs</div>
                                            </div>
                                            <div className='greenCardsImage'>
                                                <img src={revenue} style={{ width: '60%', margin: 'auto' }} />
                                            </div>
                                        </div>


                                        <div className='greendashCard greenAllSideShadowDiv' style={{ background: '#E88560' }}>
                                            <div>
                                                <div className='greenCardMain'>Crops</div>
                                                <div className='greenCardSub'>Total Crops</div>
                                                <div className='greenCardMain' style={{ marginTop: '5px' }}>{this.state.totalCrops}</div>
                                            </div>
                                            <div className='greenCardsImage'>
                                                <img src={crop} style={{ width: '60%', margin: 'auto' }} />
                                            </div>
                                        </div>

                                        <div className='greendashCard greenAllSideShadowDiv' style={{ background: '#42AFA2' }}>
                                            <div>
                                                <div className='greenCardMain'>Customers</div>
                                                <div className='greenCardSub'>Total Customers</div>
                                                <div className='greenCardMain' style={{ marginTop: '5px' }}>{this.state.totalCustomers}</div>
                                            </div>
                                            <div className='greenCardsImage'>
                                                <img src={customerActive} style={{ width: '60%', margin: 'auto' }} />
                                            </div>
                                        </div>

                                        <div className='greendashCard greenAllSideShadowDiv' style={{ background: '#D6AF4F' }}>
                                            <div>
                                                <div className='greenCardMain'>Acreage</div>
                                                <div className='greenCardSub'>Total Acreage</div>
                                                <div className='greenCardMain' style={{ marginTop: '5px' }}>{this.kFormatter(Number(this.state.totalAcres), true)} Acr</div>
                                            </div>
                                            <div className='greenCardsImage'>
                                                <img src={acre} style={{ width: '60%', margin: 'auto' }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ width: '100%', height: '30vh', marginTop: '5px', display: "flex", flexDirection: 'column', justifyContent: 'center' }}>
                                    <div style={{ fontSize: '15px', fontWeight: '500', letterSpacing: '1px', color: 'black', }}>Growth Staticstics</div>
                                    <div className='growthStat'>

                                        {/* // last three months data  */}
                                        <div className='growthStatSub greenAllSideShadowDiv'>
                                            <div style={{ fontSize: '12px', fontWeight: '500', color: 'black', width: '100%', height: '20px' }}>Last 3 Months</div>
                                            <div className='growthStatCircle'>
                                                <div className='growthStatCircleInline'>
                                                    <div className='growthStatMonths'>{this.state.lastThreeMonths[0] ? this.kFormatter(this.state.lastThreeMonths[0].amount) : '0'}</div>
                                                    <div style={{ fontSize: '12px', marginTop: '5px' }}>{this.state.lastThreeMonths[0] ? this.state.lastThreeMonths[0].monthName : '-'}</div>
                                                </div>
                                                <div className='growthStatCircleInline'>
                                                    <div className='growthStatMonths'>{this.state.lastThreeMonths[1] ? this.kFormatter(this.state.lastThreeMonths[1].amount) : '0'}</div>
                                                    <div style={{ fontSize: '12px', marginTop: '5px' }}>{this.state.lastThreeMonths[1] ? this.state.lastThreeMonths[1].monthName : '-'}</div>
                                                </div>
                                                <div className='growthStatCircleInline'>
                                                    <div className='growthStatMonths'>{this.state.lastThreeMonths[2] ? this.kFormatter(this.state.lastThreeMonths[2].amount) : '0'}</div>
                                                    <div style={{ fontSize: '12px', marginTop: '5px' }}>{this.state.lastThreeMonths[2] ? this.state.lastThreeMonths[2].monthName : '-'}</div>
                                                </div>
                                            </div>
                                            <div className='growthStatFooter'>
                                                <div style={{ height: '30px', width: '30px', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '5px', background: Math.sign(this.state.monthPer) == -1 ? '#FFD3D3' : '#D4F5EA' }}>
                                                    <img src={Math.sign(this.state.monthPer) == -1 ? chartGrowDown : chartGrowUp} style={{ width: '100%' }} />
                                                </div>
                                                &nbsp; &nbsp; <span style={{ fontWeight: '500', }}>{isNaN(this.state.monthPer) ? '0' : Math.round(this.state.monthPer)} Rs</span> &nbsp;  {Math.sign(this.state.monthPer) == -1 ? 'decrease in last Month' : 'increase in last Month'}
                                            </div>
                                        </div>


                                        {/* // month on month */}
                                        <div className='growthStatSub greenAllSideShadowDiv'>
                                            <div style={{ fontSize: '12px', fontWeight: '500', color: 'black', width: '100%', height: '20px' }}>Month on Month</div>
                                            <div className='growthStatCircle' style={{ color: '#06A36D', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <div style={{ fontSize: '30px', fontWeight: '500', width: '38%', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>{isNaN(this.state.monthOnMonthPer2) || Math.sign(this.state.monthOnMonthPer2) == -1 ? '0' : Math.round(this.state.monthOnMonthPer2)}% <img src={Math.sign(this.state.monthOnMonthPer2) == -1 ? greenGrowthDownArrow : greenGrowthUpArrow} style={{ width: '20%' }} /></div>
                                                <div style={{ height: '100%', width: '60%' }}>
                                                    <div
                                                        style={{
                                                            textAlign: 'center',
                                                            width: "100%",
                                                            height: '80%',
                                                        }}
                                                    >
                                                        <Line
                                                            width={"100%"}
                                                            height={"100%"}
                                                            options={{
                                                                responsive: true,
                                                                maintainAspectRatio: false,
                                                                plugins: {
                                                                    legend: {
                                                                        display: false
                                                                    }
                                                                },
                                                                elements: {
                                                                    point: {
                                                                        radius: 0
                                                                    }
                                                                },
                                                                scales: {
                                                                    x: {
                                                                        display: false,
                                                                        grid: {
                                                                            display: false,
                                                                            drawOnChartArea: false
                                                                        },
                                                                        ticks: {
                                                                            display: false
                                                                        },
                                                                        title: {
                                                                            display: false
                                                                        }
                                                                    },
                                                                    y: {
                                                                        display: false,
                                                                        grid: {
                                                                            display: false,
                                                                            drawOnChartArea: false
                                                                        },
                                                                        ticks: {
                                                                            display: false
                                                                        },
                                                                        title: {
                                                                            display: false
                                                                        }
                                                                    }
                                                                }
                                                            }}
                                                            data={this.state.monthsGdata}
                                                        />

                                                    </div>
                                                    <div style={{ textAlign: 'center', marginTop: '5px', fontSize: '13px', }}>{this.state.lastThreeMonths.length > 0 ? <div>{this.state.lastThreeMonths[1].monthName} - {this.state.lastThreeMonths[2].monthName}</div> : '-'}</div>
                                                </div>
                                            </div>
                                            <div className='growthStatFooter'>
                                                <div style={{ height: '30px', width: '30px', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '5px', background: Math.sign(this.state.monthOnMonthIncrease) == -1 ? '#FFD3D3' : '#D4F5EA' }}>
                                                    <img src={Math.sign(this.state.monthOnMonthIncrease) == -1 ? chartGrowDown : chartGrowUp} style={{ width: '100%' }} />
                                                </div>
                                                &nbsp; &nbsp; <span style={{ fontWeight: '500', }}>{isNaN(this.state.monthOnMonthIncrease) ? '0' : Math.round(this.state.monthOnMonthIncrease)} Rs</span> &nbsp;  {Math.sign(this.state.monthOnMonthIncrease) == -1 ? 'decrease in last Month' : 'increase in last Month'}
                                            </div>
                                        </div>


                                        {/* // seasons on seasons */}
                                        <div className='growthStatSub greenAllSideShadowDiv'>
                                            <div style={{ fontSize: '12px', fontWeight: '500', color: 'black', width: '100%', height: '20px' }}>Season on Season</div>
                                            <div className='growthStatCircle' style={{ color: '#06A36D', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <div style={{ fontSize: '30px', fontWeight: '500', width: '38%', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>{isNaN(this.state.seasonsOnSeasonsIncrease) || Math.sign(this.state.seasonsOnSeasonsIncrease) == -1 ? '0' : Math.round(this.state.seasonsOnSeasonsIncrease)}% <img src={Math.sign(this.state.seasonsOnSeasonsIncrease) == -1 ? greenGrowthDownArrow : greenGrowthUpArrow} style={{ width: '20%' }} /></div>
                                                <div style={{ height: '100%', width: '60%' }}>
                                                    <div
                                                        style={{
                                                            textAlign: 'center',
                                                            width: "100%",
                                                            height: '80%',
                                                        }}
                                                    >
                                                        <Line
                                                            width={"100%"}
                                                            height={"100%"}
                                                            options={{
                                                                responsive: true,
                                                                maintainAspectRatio: false,
                                                                plugins: {
                                                                    legend: {
                                                                        display: false
                                                                    }
                                                                },
                                                                elements: {
                                                                    point: {
                                                                        radius: 0,
                                                                    }
                                                                },
                                                                scales: {
                                                                    x:

                                                                    {
                                                                        display: false,
                                                                        gridLines: {
                                                                            display: false,
                                                                            drawOnChartArea: false,
                                                                        },
                                                                        ticks: {
                                                                            display: false,
                                                                        },
                                                                        scaleLabel: {
                                                                            display: false,
                                                                        },
                                                                    },
                                                                    y:
                                                                    {
                                                                        display: false,
                                                                        gridLines: {
                                                                            display: false,
                                                                            drawOnChartArea: false,
                                                                        },
                                                                        ticks: {
                                                                            display: false,
                                                                        },
                                                                        scaleLabel: {
                                                                            display: false,
                                                                        },
                                                                    },
                                                                },
                                                            }}
                                                            data={this.state.seasonsOnSeasons}
                                                        />

                                                    </div>
                                                    <div style={{ textAlign: 'center', marginTop: '5px', fontSize: '14px', }}>{this.state.lastTwoSeasons[1] ? this.state.lastTwoSeasons[1].season_name.length > 10 ?
                                                        `${this.state.lastTwoSeasons[1].season_name.substring(0, 10)} -` : `${this.state.lastTwoSeasons[1].season_name} -` : ''}  {this.state.lastTwoSeasons[0] ?
                                                            this.state.lastTwoSeasons[0].season_name.length > 10 ? this.state.lastTwoSeasons[0].season_name.substring(0, 7) : this.state.lastTwoSeasons[0].season_name : ''}</div>
                                                </div>
                                            </div>
                                            <div className='growthStatFooter'>
                                                <div style={{ height: '30px', width: '30px', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '5px', background: Math.sign(this.state.lastSeasonIncrease) == -1 ? '#FFD3D3' : '#D4F5EA' }}>
                                                    <img src={Math.sign(this.state.lastSeasonIncrease) == -1 ? chartGrowDown : chartGrowUp} style={{ width: '100%' }} />
                                                </div>
                                                &nbsp; &nbsp; <span style={{ fontWeight: '500', }}>{isNaN(this.state.lastSeasonIncrease) ? '0' : Math.round(this.state.lastSeasonIncrease)} Rs</span> &nbsp;  {Math.sign(this.state.lastSeasonIncrease) == -1 ? 'decrease in last Season' : 'increase in last Season'}
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div style={{ width: '100%', height: '40vh', display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
                                    <div style={{ width: '66%', height: '100%' }}>
                                        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', }}>
                                            <div style={{ fontSize: '15px', fontWeight: '500', letterSpacing: '1px', color: 'black', }}>Revenue Trend ({this.state.selectedGreaphUnit == 'week' ?
                                                `${dateFormat(this.state.fromDate, 'ddd')} - ${dateFormat(this.state.toDate, 'ddd')}`
                                                : this.state.selectedGreaphUnit == 'month' ? `${dateFormat(this.state.fromDate, 'dd/mmm')} - ${dateFormat(this.state.toDate, 'dd/mmm')}`
                                                    : `${dateFormat(this.state.fromDate, 'mmm/yyyy')} - ${dateFormat(this.state.toDate, 'mmm/yyyy')}`})</div>
                                            <div style={{ border: '1px solid #c2c2c2', background: 'white', borderRadius: '6px', padding: '2px 0px' }}>
                                                <SelectComponent
                                                    selected={this.state.selectedItem}
                                                    itemsList={this.state.itemsList}
                                                    selectIcon={greenDownArrow}
                                                    iconSize={"16px"}
                                                    onchangeHandler={(value) => { this.onChangeEvent(value) }}
                                                    fontSize={"14px"}
                                                    width={"150px"}
                                                    fontWeight={500}
                                                    padding={"0px 5px 0px 10px"}
                                                    selectedColor={'black'}
                                                    height={'25px'}
                                                />
                                            </div>
                                        </div>
                                        <div className='revenueTrend'>
                                            <div className='greenAllSideShadowDiv' style={{ width: '100%', height: '100%', background: 'white', borderRadius: '5px' }}>
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        height: '100%',
                                                        padding: '5px 15px 5px 0px'
                                                    }}
                                                >
                                                    <Line
                                                        width={"55%"}
                                                        height={"20%"}
                                                        options={{
                                                            responsive: true,
                                                            maintainAspectRatio: false,
                                                            labels: {
                                                                font: {
                                                                    family: "Poppins",
                                                                    size: "10",
                                                                    style: "normal",
                                                                    color: "#666666",
                                                                },
                                                            },
                                                            plugins: {
                                                                legend: {
                                                                    display: true,
                                                                    position: 'top',
                                                                    align: 'end',
                                                                    labels: {
                                                                        usePointStyle: true,
                                                                        boxWidth: 10,
                                                                    }
                                                                }
                                                            },
                                                            elements: {
                                                                point: {
                                                                    pointStyle: 'circle',
                                                                    radius: 4,
                                                                }
                                                            },
                                                            scales: {
                                                                x:
                                                                {
                                                                    gridLines: {
                                                                        color: "#c2c2c2",
                                                                        display: true,
                                                                        drawOnChartArea: false,
                                                                        drawTicks: true,
                                                                        lineWidth: 1
                                                                    },
                                                                    ticks: {
                                                                        padding: 2,
                                                                        fill: "black",
                                                                        display: true,
                                                                        beginAtZero: true,
                                                                        fontSize: 11,
                                                                        fontFamily: "Poppins",
                                                                        fontStyle: "normal",
                                                                        fontColor: "#666666",
                                                                        lineHeight: "auto",
                                                                    },
                                                                    scaleLabel: {
                                                                        display: true,
                                                                        fontSize: 11,
                                                                        fontFamily: "Poppins",
                                                                        fontStyle: "normal",
                                                                        fontColor: "#666666",
                                                                        // labelString: "Days",
                                                                        borderColor: "#666666",
                                                                    },
                                                                },
                                                                y:
                                                                {
                                                                    gridLines: {
                                                                        color: "#c2c2c2",
                                                                        value: "none",
                                                                        display: true,
                                                                        drawOnChartArea: true,
                                                                        drawTicks: false,
                                                                        lineWidth: 1
                                                                    },
                                                                    ticks: this.state.yTicks,

                                                                    scaleLabel: {
                                                                        fontSize: 11,
                                                                        fontFamily: "Poppins",
                                                                        fontStyle: "normal",
                                                                        fontColor: "#666666",
                                                                        display: true,
                                                                    },
                                                                },
                                                            },
                                                        }}
                                                        data={this.state.data}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div style={{ height: 'calc(100% - 3px)', width: '31.5%', display: 'flex', color: 'black', flexDirection: 'column', justifyContent: 'space-between' }}>
                                        <div className='greenAllSideShadowDiv' style={{ width: '100%', height: '48%', background: 'white', borderRadius: '5px', padding: '5px 10px' }}>
                                            <div style={{ fontSize: '12px', fontWeight: '500', color: 'black' }}>Top Customers</div>
                                            <div style={{ display: 'flex', marginTop: '3%', width: '100%', height: 'calc(100% - 25px)' }}>
                                                {this.state.topCustomer.customer_name ? <><div style={{ height: '100%', marginRight: '15px', marginLeft: '10px' }}>
                                                    <div className='growthTopCust'>{this.state.topCustomer.customer_name.substring(0, 1)}</div>
                                                </div>
                                                    <div style={{ height: '100%', maxHeight: '120px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                                        <div>
                                                            <div style={{ fontSize: '14px', fontWeight: '500', wordBreak: "break-word", height: "20px", overflow: "hidden" }}>{this.state.topCustomer.customer_name}</div>
                                                            <div style={{ fontSize: '10px' }}>{this.state.topCustomer.address}</div>
                                                        </div>
                                                        <div style={{ fontSize: '22px', fontWeight: '500', wordBreak: "break-word", height: "35px", overflow: "hidden" }}>{this.kFormatter(Number(this.state.topCustomer.highValue), true)} Rs</div>
                                                        <div style={{ fontSize: '10px', color: '#06A36D' }}>Top Season Customer</div>
                                                    </div></>
                                                    : <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', fontSize: '12px', justifyContent: 'center' }}>No details found</div>}

                                            </div>

                                        </div>
                                        <div className='greenAllSideShadowDiv' style={{ width: '100%', color: 'black', height: '48%', background: 'white', borderRadius: '5px', padding: '5px 10px' }}>
                                            <div style={{ fontSize: '12px', fontWeight: '500', color: 'black' }}>Top Crop</div>
                                            <div style={{ display: 'flex', marginTop: '3%', width: '100%', height: 'calc(100% - 25px)' }}>
                                                {this.state.topCrop.crop_type ? <>
                                                    <div style={{ height: '100%', marginRight: '15px', marginLeft: '10px' }}>
                                                        <div className='growthTopCust'><img src={greenCropBlack} style={{ width: '60%' }} /></div>
                                                    </div>
                                                    <div style={{ height: '100%', maxHeight: '120px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                                        <div>
                                                            <div style={{ fontSize: '14px', fontWeight: '500' }}>{this.state.topCrop.crop_type}</div>
                                                            <div style={{ fontSize: '10px' }}>{this.state.topCrop.count} Customers</div>
                                                        </div>
                                                        <div style={{ fontSize: '22px', fontWeight: '500' }}>{this.kFormatter(Number(this.state.topCrop.acre), true)} Acr</div>
                                                        <div style={{ fontSize: '10px', color: '#06A36D' }}>Top Grossing Crops</div>
                                                    </div>
                                                </> :
                                                    <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', fontSize: '12px', justifyContent: 'center' }}>No details found</div>}

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div style={{ width: '24%', height: '91vh', display: 'flex', color: 'black', flexDirection: 'column', }}>
                                <div style={{ fontSize: '15px', fontWeight: '500', letterSpacing: '1px', color: 'black', height: '25px', }}>{this.state.activeSeason && this.state.activeSeason.season_name ? this.state.activeSeason.season_name : 'Season 1'}</div>
                                <div style={{ width: '100%', height: 'calc(100% - 35px)', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: '3%' }}>
                                    <div className='greenAllSideShadowDiv seasonsPanel' style={{ padding: '5px 0px' }}>
                                        <div style={{ display: 'flex', width: '100%', height: '20px', alignItems: 'center', justifyContent: 'space-between', padding: '0px 20px 0px 10px' }}>
                                            <div style={{ fontSize: '12px', fontWeight: '500' }}>Seasons</div>
                                            <div>
                                                <img src={btnAdd} style={{ width: '20px', height: '100%', cursor: 'pointer' }} onClick={() => {
                                                    this.setState({ addSeasonPopUp: true })
                                                }} />
                                            </div>
                                        </div>
                                        <div style={{ height: 'calc(100% - 40px)', width: '100%', marginTop: '10px', overflow: 'auto', padding: '0px 10px' }}>
                                            {this.state.seasons.length > 0 ? <>
                                                {this.state.seasons.map((ses, key) => (
                                                    <div className='tableRowDiv' style={{ background: (key % 2) == 0 ? '#EBFFF9' : '#EDEDED' }}>
                                                        <div style={{ width: '60%' }}>{ses.season_name.length > 15 ? `${ses.season_name.substring(0, 15)}..` : ses.season_name}</div>
                                                        <div style={{ width: '25%' }}>{dateFormat(ses.start_date, 'mmm')} - {dateFormat(ses.end_date, 'mmm')}</div>
                                                        <div style={{ width: '10%', height: '100%', display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                                                            <Dropdown >
                                                                <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" >
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu size="sm" title="" className="custom-dropdown-dashboard1">
                                                                    <Dropdown.Item eventKey="" className="dropdown-set-padding-dashboard">
                                                                        <div style={{ display: "flex", cursor: 'pointer' }}
                                                                            onClick={() => {
                                                                                if (ses.season_type == "primary") {
                                                                                    this.setState((state) => ({
                                                                                        ...state,
                                                                                        itemName: "Season",
                                                                                        processPopupIcon: "ERROR",
                                                                                        processAction: "UPDATE",
                                                                                        processMessage: "You can not update default season!",
                                                                                        showProcessPopup: true,
                                                                                    }))
                                                                                } else {
                                                                                    this.setState({
                                                                                        editSeason: true,
                                                                                        addSeasonPopUp: true,
                                                                                        editSeasonData: ses,
                                                                                        seasonName: ses.season_name,
                                                                                        fromDateSeason: ses.start_date,
                                                                                        toDateSeason: ses.end_date,
                                                                                    })
                                                                                }
                                                                            }}>
                                                                            <img src={editIcon} style={{ height: "16px" }} />
                                                                            <div style={{ marginLeft: '7px', color: "#3c3c3c" }}>Edit</div>
                                                                        </div>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item eventKey="" className="dropdown-set-padding-dashboard">
                                                                        <div style={{ display: "flex", cursor: 'pointer' }}
                                                                            onClick={() => {
                                                                                this.setState((state) => ({
                                                                                    ...state,
                                                                                    deleteSeasonId: ses.id,
                                                                                    deleteSeason: true,
                                                                                }), () => {
                                                                                    if (ses.season_type == "primary") {
                                                                                        this.setState((state) => ({
                                                                                            ...state,
                                                                                            itemName: "Season",
                                                                                            processPopupIcon: "ERROR",
                                                                                            processAction: "DELETE",
                                                                                            processMessage: "You can not delete default season!",
                                                                                            showProcessPopup: true,
                                                                                        }))
                                                                                    } else {
                                                                                        this.setState((state) => ({
                                                                                            ...state,
                                                                                            itemName: "Season",
                                                                                            processPopupIcon: "WARNING",
                                                                                            processAction: "DELETE",
                                                                                            processMessage: "Are you sure you want to delete this Season ?",
                                                                                            showProcessPopup: true,
                                                                                        }))
                                                                                    }
                                                                                })
                                                                            }} >
                                                                            <img src={deleteIcon} style={{ height: "16px" }} />
                                                                            <div style={{ marginLeft: '7px', color: "#3c3c3c" }}>Delete</div>
                                                                        </div>
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown >
                                                        </div>
                                                    </div>
                                                ))}
                                            </> : <div style={{ height: '100%', width: '100%', display: 'flex', fontSize: '12px', justifyContent: 'center', alignItems: 'center' }}>No Seasons records found.</div>}
                                        </div>
                                    </div>
                                    <div className='greenAllSideShadowDiv seasonsPanel'>
                                        <div style={{ display: 'flex', width: '100%', height: '20px', alignItems: 'center', justifyContent: 'space-between', paddingRight: '6px' }}>
                                            <div style={{ fontSize: '12px', fontWeight: '500' }}>Recent Customers</div>
                                            <div style={{ fontSize: '12px', color: '#0D9164', fontWeight: '500' }}>
                                                <Link style={{ color: '#0D9164', }} to={{
                                                    pathname: `/aerogcsenterprise/green_all_customers`,
                                                    state: { user: this.state.user, type: this.state.user }
                                                }}>View All</Link>
                                            </div>
                                        </div>
                                        <div style={{ height: 'calc(100% - 40px)', width: '100%', marginTop: '10px', overflow: 'hidden' }}>
                                            {this.state.usersCustomers.length > 0 ? <>
                                                {this.state.usersCustomers.map((cust, key) => (
                                                    <div className='tableRowDiv' style={{ background: (key % 2) == 0 ? '#EBFFF9' : '#EDEDED' }}>
                                                        <div className='tableRowDivData'><span style={{ fontWeight: '500', cursor: 'pointer' }} onClick={() => {
                                                            this.setState((state) => ({
                                                                ...state,
                                                                userDetailSelect: cust,
                                                            }), () => {
                                                                this.setState({ userOnPopUp: true })
                                                            })
                                                        }}>{cust.customer_name ? cust.customer_name.length > 10 ? `${cust.customer_name.substring(0, 10)}..` : cust.customer_name : 'Unknown'}</span>
                                                            {/* <br /><span style={{ fontWeight: '400' }}>{cust.address ? cust.address.length > 10 ? `${cust.address.substring(0, 10)}..` : cust.address : '-'}</span> */}
                                                        </div>
                                                        <div className='tableRowDivData' style={{ width: '21%' }}>{cust.totalAcres ? this.kFormatter(cust.totalAcres) : '0'} Acr</div>
                                                        <div className='tableRowDivData' style={{ width: '21%' }}>{cust.totalRevenue ? this.kFormatter(cust.totalRevenue) : '0'} Rs</div>
                                                        <div className='tableRowDivData' style={{ width: '21%' }}>{dateFormat(cust.register_date, 'dS mmm')}</div>
                                                    </div>
                                                ))}
                                            </> : <div style={{ height: '100%', width: '100%', display: 'flex', fontSize: '12px', justifyContent: 'center', alignItems: 'center' }}>No customers records found.</div>}
                                        </div>
                                    </div>
                                    <div className='greenAllSideShadowDiv seasonsPanel' style={{ padding: '5px 0px', backgroundImage: `url(${greenBackground})`, backgroundPosition: 'bottom', backgroundSize: 'contain', backgroundRepeat: 'no-repeat' }}>
                                        <div style={{ display: 'flex', width: '100%', height: '20px', alignItems: 'center', justifyContent: 'space-between', padding: '0px 10px' }}>
                                            <div style={{ fontSize: '12px', fontWeight: '500' }}>Crops</div>
                                        </div>
                                        <div style={{ height: 'calc(100% - 30px)', width: '100%', marginTop: '10px', overflow: 'auto', padding: '0px 10px' }}>
                                            {this.state.allCrops.map((crop, key) => {
                                                return (
                                                    <button className='cropNameButton'>{crop.crop_type}</button>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div> : <div />}

            </>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateProfileDetails: (payload) => dispatch(updateProfileDetails(payload))
    };
};

const mapStateToProps = state => {
    return {
        activeTeamId: state.profile.activeTeamId,
        activeTeam: state.profile.activeTeam,
        isLoggedInUserOwner: state.profile.isLoggedInUserOwner,
        SubscriptionData: state.subscription.subscriptionData,
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(AeroGCSGreenDashboard);

