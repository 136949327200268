import React, { Component } from "react";
import * as API from "./api.js";
import Billing from "./sidebaricon/Billing.svg";
import Settings from "./sidebaricon/Settings.png";
import UploadIcon from './upload.svg';
import HelpActive from "./sidebaricon/HelpActive.png";
import axios from 'axios';
import "./style.css";

import { Link, Redirect } from "react-router-dom";

import SideBar from "./SideBar";

export default class Feedback extends Component {
  state = {
    user: {},
    type: "",
    topic: "General",
    message: "",
    services: [],
    attachment: null,
    sidebarMode: this.props.location.state
      ? this.props.location.state.sidebarMode
      : "shrunk",
    successParams: {
      title: "FEEDBACK",
      message1: "Feedback has been recorded",
      message2: "To go back to home, click",
      hereLink: "/",
      buttonText: "Go Back",
      buttonLink: "/feedback"
    }
  };

  parentSidebarSwitch = () => {
    this.setState((state) => ({
      ...state,
      sidebarMode: state.sidebarMode == "shrunk" ? "expanded" : "shrunk",
    }));
  };


  componentWillMount() {
    window.history.back();
    window.open("https://docs.aeromegh.com")
    // document.title = "Feedback - AeroGCS";
    // if (this.props.location.state) {
    //   let { user, type } = this.props.location.state;
    //   this.setState(
    //     (state) => ({
    //       ...state,
    //       user,
    //       type,
    //     }),
    //     () => {
    //     }
    //   );
    // } else {
    //   API.getUserType().then(
    //     ([blah, type, user]) => {
    //       console.log("ROLE ", type);
    //       console.log("USER ", user);
    //       this.setState(
    //         (state) => ({
    //           ...state,
    //           user,
    //           type,
    //         }),
    //         () => {

    //         }
    //       );
    //     },
    //     (e) => {
    //       window.location.replace(
    //         process.env.REACT_APP_SERVER_PROTOCOL + "://" + process.env.REACT_APP_SERVER_HOST + "" + window.location.replace("/login");
    //       );
    //     }
    //   );
    // }
  }

  topicChangeListener = (event) => {
    let topic = event.target.value;
    this.setState((state) => ({
      ...state,
      topic
    }))
  }

  messageChangeListener = (event) => {
    let message = event.target.value;
    this.setState((state) => ({
      ...state,
      message
    }))
  }

  successRedirectComponent = () => {
    if (this.state.redirect) {
      return <Redirect to={{
        pathname: "/success",
        state: {
          ...this.state.successParams,
          user: this.state.user,
          type: this.state.type,
          sidebarMode: this.state.sidebarMode,
          config: {
            options: [
              // {
              //   icon: Service,
              //   text: "Services",
              //   path: "/",
              // },
              // {
              //   icon: Reports,
              //   text: "Reports",
              //   path: "/under_progress",
              // },
              {
                icon: Billing,
                text: "Billing",
                path: "/invoices",
              },
              {
                icon: HelpActive,
                text: "Help",
                path: "/feedback",
                selected: true,
              },
              {
                icon: null,
                text: "hr",
                path: null,
              },
              {
                icon: Settings,
                text: "Settings",
                path: "/profile-settings",
              },
            ],
            user: this.state.user,
            type: this.state.type,
            mode: this.state.sidebarMode, // or expanded
          }
        }
      }} />
    }
  }

  onChangeListenerFile = (e) => {
    let t = e.target;
    console.log(t.files[0]);
    const data = new FormData()
    data.append("file", t.files[0])
    axios.post(process.env.REACT_APP_FILE_PROTOCOL + "://" + process.env.REACT_APP_FILE_HOST + (process.env.REACT_APP_FILE_PORT ? process.env.REACT_APP_FILE_PORT : "") + "/upload", data, {}).then(res => {
      console.log(res.data)
      let url = res.data.split("URL: ")[1];
      let fileName = res.data.split("URL: ")[1].split(process.env.REACT_APP_FILE_PROTOCOL + "://" + process.env.REACT_APP_FILE_HOST + process.env.REACT_APP_FILE_PORT + "/hosted/")[1]
      this.setState((state) => ({
        ...state,
        attachment: {
          url: url,
          fileName: fileName
        }
      }))
    }, (e) => {
      console.log(e)
    })
  }

  submitFeedback = (e) => {
    e.preventDefault()
    let subject = "AeroGCS Feedback/Query - " + this.state.topic
    let body = "Message From: " + this.state.user.username +
      "<br><br>" +
      "===================== START OF USER MESSAGE =====================<br><br>" +
      this.state.message + "<br><br>" +
      "===================== END OF USER MESSAGE =====================<br><br>" +
      "Attachment: " + this.state.attachment.url
    API.sendFeedback(subject, body).then(result => {
      this.setState((state) => ({
        ...state,
        redirect: true
      }))
    }).catch(e => {
      console.log(e)
    })

    return false;
  }

  render() {
    let { user, type, services } = this.state;
    let sidebarConfigurations = {
      options: [
        // {
        //   icon: Service,
        //   text: "Services",
        //   path: "/"
        // },
        // {
        //   icon: ReportsIcon,
        //   text: "Reports",
        //   path: "/reports"
        // },
        {
          icon: HelpActive,
          text: "Help",
          path: "/feedback",
          selected: true,
        },
       
        {
          icon: Settings,
          text: "Settings",
          path: "/profile-settings"
        }
      ],
      user,
      type,
      mode: this.state.sidebarMode  // or expanded
    }


    return user.username ? (
      <div className="wrapper">
        <SideBar
          config={sidebarConfigurations}
          parentSidebarSwitch={this.parentSidebarSwitch}
        />
        {this.successRedirectComponent()}
        <div className="right-content"
          onClick={
            () => {
              this.setState((state) => ({
                ...state, sidebarMode: 'shrunk'
              }))
            }
          }
        >
          <div className="top-bar">
            <div className="top-bar-text">FEEDBACK</div>
          </div>
          <div className="main-content">
            <div style={{ display: "flex" }}>
            </div>
            <div className="page" style={{
              padding: "32px 129px 32px 129px"
            }}>
              <form onSubmit={this.submitFeedback} action={this.submitFeedback}>
                <div className="add-user-form-row">
                  <div className="add-user-form-row-left">
                    <div className="add-user-form-label">
                      Topic*
                      </div>
                    <div className="add-user-form-text-wrapper">
                      <select name="topic" className="add-user-form-text" value={this.state.topic} required onChange={this.topicChangeListener}>
                        <option>General</option>
                        <option>NPNT Services</option>
                        <option>Projects</option>
                        <option>COVID-19</option>
                        <option>Drone Control Center</option>
                        <option>AeroGCS Website</option>
                        <option>AeroGCS</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="add-user-form-row">
                  <div className="add-user-form-row-left" style={{ width: "100%" }}>
                    <div className="add-user-form-label">
                      Message*
                      </div>
                    <div className="add-user-form-text-wrapper">
                      <textarea name="message" type="text" value={this.state.message} className="add-user-form-text" placeholder="Add a message..." required onChange={this.messageChangeListener} style={{
                        width: "100%",
                        height: "400px",
                        padding: "10px 13px"
                      }} />
                    </div>
                  </div>
                </div>

                <div className="add-user-form-row">
                  <div className="add-user-form-row-left">
                    <div className="add-user-form-label">
                      Attachment
                      </div>
                    <div className="add-user-form-text-wrapper">
                      <div className="add-user-form-file-wrapper">
                        <div className="add-user-form-file-display-design">
                          <div><img src={UploadIcon} /></div>
                          {this.state.attachment ? <div style={{ marginTop: "10px" }}>{this.state.attachment.fileName}</div> :
                            <div>
                              <div>Drag and Drop</div>
                              <div>OR</div>
                              <div style={{ color: "#0089CF" }}>Browse Files</div>
                            </div>
                          }
                        </div>
                        <input name="company_logo" type="file" className="add-user-form-file" onChange={this.onChangeListenerFile} />
                      </div>
                    </div>
                  </div>
                </div>
                <button type="submit" className="add-user-form-submit-button" style={{
                  marginTop: "20px",
                  cursor: "pointer"
                }}>
                  Submit
              </button>
              </form>

            </div>
          </div>
        </div>
      </div>
    ) : (
      <div />
    );
  }
}
