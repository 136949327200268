import React, { Component } from 'react';
import VideoPlayer from "./VideoPlayer.js"
import * as API from "./api.js";
import viewIcon from './icons/view.svg';
import LoaderComponent from './ReusableComponents/LoaderComponent.js';
import dateFormat from 'dateformat'
export default class RMPLiveVideoStramingURL extends Component {
    state = {
        error: '',
        token: '',
        streamID: '',
    }

    getSharableLinkAccess = () => {
        API.getSharableLinkAccess(this.state.streamID).then((data) => {
            this.setState((state) => ({
                ...state,
                token: data.token
            }), () => {
                this.getSharableLinkDetails()
            })
        }, (e) => {
            console.log(e);
            this.setState((state) => ({
                ...state,
                error: e
            }))
        })
    }

    getSharableLinkDetails = () => {
        API.getSharableLinkDetails(this.state.streamID, this.state.token).then((data) => {
            this.setState((state) => ({
                ...state,
                flights: data.flights,
                userDetails: data.user,
                requestDetails: data.request
            }))
        }, (e) => {
            console.log(e);
            this.setState((state) => ({
                ...state,
                error: e
            }))
        })

    }

    componentWillMount() {
        const getQueryParams = () => window.location.search.replace('?', '').split('&').reduce((r, e) => (r[e.split('=')[0]] = decodeURIComponent(e.split('=')[1]), r), {});
        let queryParams = getQueryParams();
        let streamID = queryParams.streamID;
        this.setState((state) => ({
            ...state,
            error: '',
            streamID: streamID,
        }), () => {
            this.getSharableLinkAccess()
        });
    }
    render() {
        let { userDetails, requestDetails, flights } = this.state
        let videoPlayerHeight = 350
        let videoPlayerWidth = videoPlayerHeight * 1.777777777
        const style = {
            width: '446px',
            height: '220px',
        }
        return (
            <div style={{ background: '#f5f6f8', overflowY: 'auto', height: 'inherit' }}>
                {this.state.error.length > 0 ? <div
                    style={{
                        fontColor: "grey",
                        marginTop: "20px",
                        fontFamily: "Poppins",
                        fontSize: "20px",
                        marginLeft: "40px",
                        opacity: "0.5",
                    }}
                >
                    <p>{this.state.error}</p>
                </div> :
                    <div className="container" style={{ width: '100%' }}>
                        <div className="row" >
                            <div className="page" style={{ padding: "0% 0% 0% 0%", height: "auto", minWidth: '320px', background: 'transparent', boxShadow: 'none' }}>
                                {this.state.userDetails ?

                                    <div className="rpas-model-info-top-section" style={{ width: '100%', paddingLeft: '10px', paddingTop: 'unset', background: '#22285C', marginBottom: '0px', paddingBottom: '0px' }}>
                                        <div className="rpas-model-info-primary-details" style={{ width: '100%' }}>
                                            <div className="container" style={{ width: '100%', background: 'transparent' }}>
                                                <div className="row">
                                                    <div className="col-md-1" style={{ width: '18%' }}>
                                                        <div>

                                                            {this.state.userDetails.avatar_url == null ?
                                                                <div className="rpas-model-info-photo-wrapper" style={{ height: '50px', width: '45px', marginLeft: '0px', marginTop: '2px' }}>
                                                                    <img style={{ borderRadius: "50%", border: this.state.requestDetails && requestDetails.status && requestDetails.status == 'COMPLETED' ? '4px solid #666666' : '4px solid #2989CF' }} src={`/images/${userDetails.first_name[0].toLowerCase()}.PNG`}></img> </div> :
                                                                <div className="rpas-model-info-photo-wrapper" style={{ height: '50px', width: '45px', marginLeft: '0px', marginTop: '2px' }}>

                                                                    <img style={{ borderRadius: "50%", border: this.state.requestDetails && requestDetails.status && requestDetails.status == 'COMPLETED' ? '4px solid #666666' : '4px solid #2989CF' }} src={`${userDetails.avatar_url}?${userDetails.sas_token}&t${new Date().getTime()}`}></img>
                                                                </div>

                                                            }
                                                        </div>
                                                        <div>
                                                            <div className="rpas-model-info-primary-details-row" style={{ marginTop: '0px' }}>
                                                                {this.state.requestDetails && requestDetails.status && requestDetails.status == 'COMPLETED' ?
                                                                    <div className="rpas-model-info-primary-details-value" style={{ width: 'auto', fontWeight: 'bold', color: '#666666', fontSize: '12px' }}></div> :
                                                                    <div className="rpas-model-info-primary-details-value" style={{ width: 'auto', fontWeight: 'bold', color: '#2989CF', fontSize: '12px', marginLeft: '10px' }}>Live</div>
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6" style={{ width: '75%' }}>
                                                        <div className="rpas-model-info-primary-details-row" style={{ marginTop: '10px', marginBottom: '0px', width: '100%' }}>
                                                            <div className="rpas-model-info-primary-details-value" style={{ width: 'auto', fontWeight: 'bold', color: 'white', marginRight: 'auto', fontSize: '15px', lineHeight: '11px' }}>{this.state.requestDetails && this.state.requestDetails.title ? this.state.requestDetails.title : ''}</div>
                                                        </div>
                                                        <div className="rpas-model-info-primary-details-row" style={{ marginTop: '0px', marginBottom: '0px', width: '100%' }}>
                                                            <div className="rpas-model-info-primary-details-value" style={{ width: 'auto', fontWeight: '600', color: 'white', marginRight: 'auto', fontSize: '13px', lineHeight: '11x' }}>{`${userDetails.first_name} ${userDetails.last_name}`}</div>
                                                        </div>
                                                        <div className="rpas-model-info-primary-details-row" style={{ marginTop: '0px', marginBottom: '0px', width: '100%' }}>
                                                            <div className="rpas-model-info-primary-details-value" style={{ width: 'auto', fontWeight: '400', color: 'white', marginRight: 'auto', fontSize: '11px', lineHeight: '11px' }}>{`${this.state.flights && this.state.flights.length > 0 && flights[0].flight_source ? flights[0].flight_source : ''}`}</div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="row">
                                                    {this.state.flights && this.state.flights.length > 0 ?
                                                        <div className="rpas-model-info-primary-details-value" style={{ width: 'auto', fontWeight: 'bold', color: 'white', marginLeft: 'auto', fontSize: '10px', marginTop: '-25px' }}>{`${(new Date(flights[0].start_time)).toGMTString().split(' ').slice(1, 4).join(' ')}`}</div> : ''}
                                                </div>

                                            </div>



                                        </div>
                                    </div> : <div style={{ marginLeft: '8%', marginTop: '30px' }}> <LoaderComponent /> </div>}
                                {this.state.flights && this.state.flights.length > 0 ? <div style={{ width: "100%", marginTop: '2%' }}>
                                    {this.state.flights.map((flight) => {
                                        return <div style={{ fontFamily: 'Poppins', fontSize: '12px' }}>{flight.end_time == null ? <div style={{ display: 'flex', paddingLeft: '8px', paddingRight: '8px', margin: 'auto', maxWidth: '620px', marginBottom: '-20px' }}>
                                            <label>
                                                {dateFormat(flight.start_time, 'hh:MM:ss TT')}
                                            </label>
                                            <div style={{ display: 'flex', marginLeft: 'auto' }}>
                                                <img src={viewIcon} style={{ height: '18px', width: '18px', marginRight: '8px' }}></img>
                                                <p className="font-color-primary">{flight.videoViewCount}</p>
                                            </div>
                                        </div> : <div style={{ display: 'flex', fontSize: '12px', paddingLeft: '8px', paddingRight: '8px', margin: 'auto', maxWidth: '620px', marginBottom: '-20px' }}>
                                            <label>
                                                {dateFormat(flight.start_time, 'hh:MM:ss TT')}
                                            </label>
                                            <div style={{ display: 'flex', marginLeft: 'auto' }}>
                                                <img src={viewIcon} style={{ height: '18px', width: '18px', marginRight: '8px' }}></img>
                                                <p className="font-color-primary">{flight.videoViewCount}</p>
                                            </div>
                                        </div>

                                        }
                                            <div>
                                                <VideoPlayer paddingLeft={"0px"} style={{ margin: 'auto', width: 'auto' }} paddingRight={"0px"} height={"355px"} playerHeight={videoPlayerHeight} playerWidth={videoPlayerWidth} url={flight.streamingURL} playing={false} streamKey={flight.streamkey}></VideoPlayer>
                                            </div>
                                            <div style={{ display: 'flex', margin: 'auto', maxWidth: '620px', marginTop: '-10px', marginBottom: '12px' }}>
                                                <div style={{ marginLeft: 'auto', color: '#c0c0c0', fontSize: '10px' }}>
                                                    Powered By AeroStream © 2021</div>
                                                <div>
                                                </div>
                                            </div>
                                            {/* <div style={{ display: 'flex' }}> */}

                                        </div>
                                    })}

                                </div> : <div className="empty-data-message"
                                    style={{
                                        fontColor: "#3c3c3c",
                                        marginTop: "20px",
                                        fontFamily: "Poppins",
                                        marginLeft: "40px",
                                        opacity: "0.8",
                                    }}
                                >
                                    <p>No Video Stream Found</p>
                                </div>}
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}
