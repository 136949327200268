import React, { Component } from 'react';
import * as API from '../api.js';
import '../style.css';
import { Link } from 'react-router-dom';
import { ActivityPopup } from '../ReusableComponents/ActivityPopup.js';
import dateFormat from 'dateformat';
import noInvoiceVector from '../icons/noInvoiceVector.png'
import LoaderComponent from '../ReusableComponents/LoaderComponent.js';


export default class leaseInvoices extends Component {
    state = {
        user: {},
        type: "",
        org: {},
        loading: true,
        lease: {},
        sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk"),
        discount: 10,
        gst: 18,
        totalValue: 0,
        withGst: false,
        invoices: [],
        itemName: "",
        showProcessPopup: false,
        processPopupIcon: "",
        processAction: "",
        processMessage: "",
        deleteInvoiceId: '',
    }

    getAllInvoices = () => {
        API.getLeaseInvoices(null, null).then((leaseResult) => {
            console.log("invoice data", leaseResult);
            this.setState(state => ({
                ...state,
                invoices: leaseResult.invoiceData,
                loading: false,
            }), () => {
                this.setState({ loading: false })
            })
        }, (e) => {
            console.log(e)
        })
    }

    deleteLeaseInvoice = () => {
        this.setState((state) => ({
            ...state,
            addCustomerPopUp: false,
            itemName: "invoice",
            processPopupIcon: "WAIT",
            processAction: "DELETE",
            processMessage: null,
            showProcessPopup: true,
        }), () => {
            API.deleteLeaseInvoice(this.state.deleteInvoiceId).then((deleteRes) => {
                console.log('deleteRes', deleteRes);
                if (deleteRes.message == "OK") {
                    this.setState((state) => ({
                        ...state,
                        addCustomerPopUp: false,
                        itemName: "Invoice",
                        processPopupIcon: "COMPLETE",
                        processAction: "DELETE",
                        processMessage: 'Invoice deleted sucessfully',
                        showProcessPopup: true,
                        deleteInvoiceId: ''
                    }), () => {
                        this.getAllInvoices()
                    })
                } else {
                    this.setState((state) => ({
                        ...state,
                        addCustomerPopUp: false,
                        itemName: "Invoice",
                        processPopupIcon: "ERROR",
                        processAction: "DELETE",
                        processMessage: deleteRes.message,
                        showProcessPopup: true,
                    }))
                }
            }, (e) => {
                this.setState((state) => ({
                    ...state,
                    addCustomerPopUp: false,
                    itemName: "Customer",
                    processPopupIcon: "ERROR",
                    processAction: "DELETE",
                    processMessage: 'Something went wrong while deleting lease.',
                    showProcessPopup: true,
                }))
            })
        })
    }

    closeActivityPopup = () => {
        this.setState((state) => ({
            ...state,
            showProcessPopup: false,
            processMessage: null,
        }))
    }

    componentWillMount() {
        document.title = `Lease-Invoice`;
        if (this.props.location.state) {
            let { user, type } = this.props.location.state;
            this.setState((state) => ({
                ...state,
                user,
                type,
            }), () => {
                this.getAllInvoices()
            })
        } else {
            API.getUserType().then(([blah, type, user]) => {
                this.setState((state) => ({
                    ...state,
                    user,
                    type
                }), () => {
                    this.setState({ loading: false })
                })
            }, (e) => {
                window.location.replace("/login");
            })
        }
    }

    render() {
        let { user, type, model } = this.state

        return (
            user.username ? <div className="wrapper">

                <div className="right-content-new" >
                    <div className="top-bar-new"><div className="top-bar-text-new">Invoice</div></div>

                    <ActivityPopup
                        item={this.state.itemName}
                        open={this.state.showProcessPopup}
                        icon={this.state.processPopupIcon}
                        action={this.state.processAction}
                        msg={this.state.processMessage}
                        close={() => this.closeActivityPopup()}
                        onClickOk={() => this.deleteLeaseInvoice()}
                    />

                    {this.state.loading ? <LoaderComponent /> :
                        <div className="main-content-new">
                            <div style={{ height: 'calc(100% - 10px)', marginTop: '10px' }}>
                                {this.state.invoices.length ? <table className="customers-table" style={{ marginTop: '0px', borderRadius: '5px', }}>
                                    <tr className="customers-table-header-row" style={{ zIndex: '0' }}>
                                        <th>Invoice Id</th>
                                        <th style={{ textAlign: 'center' }}>Date</th>
                                        <th>Status</th>
                                        {/* <th></th> */}
                                    </tr>
                                    {this.state.invoices.map((invoice, key) => (
                                        <tr className="customers-table-data-row">
                                            <td>
                                                <Link style={{ color: '#3c3c3c' }} to={{
                                                    pathname: '/aerogcsenterprise/lease_drone_invoice',
                                                    state: {
                                                        user: this.state.user,
                                                        type: this.state.type,
                                                        lease: invoice.leaseData,
                                                        withGst: invoice.gstflag,
                                                        totalValue: invoice.total_amount,
                                                        gst: invoice.gst,
                                                        discount: invoice.discount,
                                                        dailycharges: invoice.dailycharges,
                                                        paid_status: invoice.paid_status
                                                    }
                                                }}>
                                                    {invoice.invoiceid}</Link>
                                            </td>
                                            <td style={{ textAlign: 'center' }}>{dateFormat(invoice.generate_date, "dd/mm/yyyy")}</td>
                                            <td>{invoice.paid_status == true ? 'Paid' : 'Unpaid'}</td>
                                        </tr>
                                    ))}
                                </table> :
                                    <div className="empty-data-message" style={{ display: 'flex', flexDirection: 'column', height: '100%', backgroundColor: "#FAFAFA", alignItems: 'center', justifyContent: 'center' }}>
                                        <img src={noInvoiceVector} style={{ width: "18%", }} />
                                        <div>No Invoices Found</div>
                                    </div>
                                }
                            </div>
                        </div>}
                </div>
            </div > : <div />
        );
    }
}
