import { Link } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { isServiceAeroGCS } from './reusableFunctions'
import AeroMeghLogo from "../aeromegh-logo-new.png";
import GREEN from '../assets/sideBarIcons/green-icon.png'
import { sideBarPaths } from '../sideBarPaths';
import Help from "../sidebaricon/Help-new.png"
import HelpInactive from "../sidebaricon/Help-Inactive.png"
import Apps from "../sidebaricon/Apps.png"
import AppsInactive from "../sidebaricon/Apps-Inactive.png"
import * as API from '../api'
import { useSelector } from 'react-redux';
import Tippy from '@tippyjs/react';
import CloseIcon from "../icons/blackClose.png"
import leaseIcon from '../icons/leaseIcon.svg'
import { ADMIN_USERS } from '../AppConstants';
import AdminOrthoImporter from '../AdminOrthoImporter';

const SideBarComponent = (props) => {
    const location = useLocation()
    const sideBarState = useSelector(state => state.sidebar)
    const [route, setRoute] = useState(sideBarPaths[location.pathname])
    const [user, setUser] = useState();
    const [type, setType] = useState()
    const [openApps, setOpenApps] = useState(false)
    const [isGreenPath, setIsGreenPath] = useState(false)

    useEffect(() => {
        if (location.pathname) {
            setRoute(sideBarPaths[location.pathname])
            setIsGreenPath(location.pathname.includes("green_") ? true : false)
        }
    }, [location.pathname])

    useEffect(() => {
        if (!user)
            API.getUserType().then(([blah, type, user]) => {
                setUser(user);
                setType(type)
            })
    }, [])

    const [apps, setApps] = useState([
        {
            backgroundColor: '#238E28',
            icon: GREEN,
            text: "Green",
            redirectTo: `/aerogcsenterprise/green_dashboard`,
        },
        {
            backgroundColor: '#2989CF',
            icon: leaseIcon,
            text: "Lease",
            redirectTo: `/aerogcsenterprise/lease_dashboard`,
        },
    ])

    const isToolSelected = (item) => {
        if (!item) return false
        return route.selectedPath === item.path
    }

    const toolJSX = (item) => {
        return <div style={{ borderLeft: `solid 2px ${isGreenPath && isToolSelected(item) ? '#238E28' : 'transparent'}` }}>
            <li>{item?.onClick ?
                <div className="sidecontent-new" id="sidecontent" onClick={item.onClick}>
                    <div className="sidebar-icon-new">
                        <img className={`sidebar-icon-img-new  ${item.className}`} src={isToolSelected(item) ? item.icon.active : item.icon.inActive} alt="serviceIcon" />
                    </div>
                    {isToolSelected(item) ? (<div name="service" className={`sidebaropt-new ${isGreenPath && 'sidebaropt-new-Active'}`} style={{ color: isGreenPath ? '#238E28' : "#FFFFFF", fontWeight: 500 }}  >{item.name}</div>)
                        : (<div style={{ color: isGreenPath ? '#FFFFFF' : "#FFFFFF", }} name="service" className={"sidebaropt-new"}>{item.name}</div>)}
                </div>
                : <Link
                    to={{ pathname: item.path, state: { config: { user, type }, user, type, ...sideBarState } }} >
                    <div className="sidecontent-new" id="sidecontent">
                        <div className="sidebar-icon-new">
                            <img className={`sidebar-icon-img-new ${item.className}`} src={isToolSelected(item) ? item.icon.active : item.icon.inActive} alt="serviceIcon" />
                        </div>
                        {isToolSelected(item) ? (<div name="service" className={`sidebaropt-new ${isGreenPath && 'sidebaropt-new-Active'}`} style={{ color: isGreenPath ? '#238E28' : "#FFFFFF", fontWeight: 500, }} >{item.name}</div>)
                            : (<div style={{ color: isGreenPath ? '#FFFFFF' : '#FFFFFF99' }} name="service" className={"sidebaropt-new"}  >{item.name} </div>)}
                    </div>
                </Link>
            }</li>
        </div>
    }

    return isServiceAeroGCS(location.pathname, props.adminService) && route ?
        <div className="sidebar-expanded-new"
            style={{ paddingTop: "0px", backgroundColor: isGreenPath ? '#052218' : '#081425', position: 'absolute', left: '0', top: 0, zIndex: 99 }} >
            <div style={{ display: "flex", transition: "0.3s", margin: 'auto', alignItems: 'center', justifyContent: 'center' }}>
                <img className='aeromegh-icon' src={AeroMeghLogo} />
            </div>
            <ul className="sidebar-nav-new" id="sidebar-nav-new">
                <div>
                    {route?.tools?.map((item) => {
                        if (item.path == '/admin/OEMUser') {
                            if (ADMIN_USERS.includes(user?.username)) {
                                return toolJSX(item)
                            }
                        } else {
                            return toolJSX(item)
                        }
                    })}

                    {window.location.pathname.includes('/admin') && ADMIN_USERS.includes(user?.username) && < div style={{ borderLeft: `solid 2px transparent` }}  >
                        <li>
                            <AdminOrthoImporter />
                        </li>
                    </div>}

                    {/* APPS */}
                    {!(window.location.pathname.includes('lease_') || window.location.pathname.includes('green_') || window.location.pathname.includes('/admin')) && <li>
                        <Tippy visible={openApps} interactive onClickOutside={() => setOpenApps(false)} className='apps-container' placement='right' content={<AppsContainer apps={apps} onClose={() => setOpenApps(false)} />}>
                            <div className="sidecontent-new" id="sidecontent" onClick={() => setOpenApps(prev => !prev)}>
                                <div className="sidebar-icon-new">
                                    <img className="sidebar-icon-img-new" src={openApps ? Apps : AppsInactive} alt="serviceIcon" />
                                </div>
                                {openApps ? (
                                    <div name="service" className={"sidebaropt-new"} style={{ color: "#FFFFFF", fontWeight: 500 }} >Apps</div>) : (
                                    <div style={{ color: '#FFFFFF99' }} name="service" className={"sidebaropt-new"}  > Apps </div>
                                )}
                            </div>
                        </Tippy>
                    </li>}

                    {/* HELP TOOL */}
                    {toolJSX({
                        name: 'Help', path: "/help",
                        icon: { active: Help, inActive: isGreenPath ? Help : HelpInactive },
                        onClick: () => {
                            const url = `https://aerogcs-docs.aeromegh.com/aerogcs-kea-enterprise`
                            window.open(url, "_blank");
                        }
                    })}
                </div>
            </ul >
        </div >
        : <></>
}

export default SideBarComponent


const AppsContainer = ({ apps, onClose }) => {
    return <>
        <img src={CloseIcon} className='apps-close-icon' onClick={onClose} />
        <div className='apps-title'>Applications</div>
        <div className='apps-grid'>
            {apps.map(app => <Link onClick={onClose} to={{ pathname: app.redirectTo, state: app.state }}>
                <div className='apps-icon-container' style={{ background: app.backgroundColor }}>
                    <img style={{ width: "100%" }} src={app.icon} />
                </div>
                <div className='apps-name'>{app.text}</div>
            </Link>)}
        </div>
    </>
}