import React, { Component } from "react";
import { Link, StaticRouter, Redirect } from "react-router-dom";
import aeromeghLogo from './assets/aeromeghLogo.png'
class accountActivetionmsg extends Component {
  state = {
    username: "",
    password: "",
    showPassword: false
  }

  componentWillMount() {
    document.title = "AeroMegh-Account_Activeted";
  }

  render() {
    return (
      <div className="authPage">
        <div className="EnterpriseLogoName">
          <img src={aeromeghLogo} className="EnterpriseLogoNameImg" />
          <div>AeroGCS<span className="EnterpriseLogoNameSpan"> Enterprise</span></div>
        </div>
        <div className="cardLogin login-card">
          <div className="loginCardHeader">Account Activated !</div>
          <div className="contentText">Your account has been successfully activated. You may now login to your account.</div>
          <Link to={{ pathname: "/login" }}><button className="loginUserButton">LOGIN</button></Link>
        </div>
      </div>
    );
  }
}

export default accountActivetionmsg
