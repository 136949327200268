import React, { Component } from "react";
import * as API from "./api.js";
import "./style.css";
import VideoPlayer from "./VideoPlayer.js"
import viewFullscreenFeedButton from './viewFullscreenFeedButton.svg';
import LoaderComponent from "./ReusableComponents/LoaderComponent.js";
import NoContent from "./ReusableComponents/NoContent.js";



class DCCAllFeeds extends Component {
  state = {
    user: {},
    type: "",
    loading: true,
    title: "ALL",
    drones: {},
  };

  componentWillUnmount() {
    clearInterval(this.interval)
    clearInterval(this.streamKeyInterval)
  }

  setStreamKeyInterval = () => {
    console.log("stream key interval called")
    Object.keys(this.state.drones).forEach((drone, i) => {
      API.latestDroneStreamKey(drone).then((response) => {
        if (this.state.drones[drone].streamURL.split(`${response.streamLiveBase ? `${response.streamLiveBase}` : process.env.REACT_APP_STREAMING_BASE}/streams/`)[1].split(".m3u8")[0] != response.streamKey) {
          console.log("rcvd stream key not equal", {
            state: this.state.drones[drone].streamURL,
            rcvd: response.streamKey
          })
          this.setState((prevState) => ({
            ...prevState,
            drones: {
              ...prevState.drones,
              [drone]: {
                ...prevState.drones[drone],
                streamURL: `${response.streamLiveBase ? `${response.streamLiveBase}` : process.env.REACT_APP_STREAMING_BASE}/streams/${response.streamKey}.m3u8`
              }
            }
          }))
        } else {
          console.log("rcvd stream key equal", {
            state: this.state.drones[drone].streamURL,
            rcvd: response.streamKey
          })
        }
      }).catch((e) => {
        console.log(e)
      })
    })
  }

  getPageData = () => {
    API.getAeroStreamVideos(0).then(data => {
      let drones = this.state.drones;
      const flights = data.flights
      flights.forEach(flight => {
        drones[flight.drone_id] = {
          ...drones[flight.drone_id],
          droneName: flight.drone_name,
          streamKey: flight.streamkey,
          streamURL: `${flight.streamLiveBase ? `${flight.streamLiveBase}` : process.env.REACT_APP_STREAMING_BASE}/streams/${flight.streamkey}.m3u8`,
          teleData: {
            lat: 0,
            lon: 0
          }
        }
      })
      this.setState({
        drones,
        loading: false,
      })
    }).catch(err => console.log(err))
  }

  componentDidMount() {
    document.title = "Drone Control Center - All Available Drones Feeds - AeroGCS";
    if (this.props && this.props.location && this.props.location.state) {
      this.setState((prevState) => ({
        ...prevState,
        user: this.props.location.state.user,
        type: this.props.location.state.type,
        sidebarMode: this.props.location.state.sidebarMode,
      }));
      this.getPageData();

    } else {
      API.getUserType().then(([blah, type, user]) => {
        this.setState((state) => ({
          ...state,
          user,
          type,
          sidebarMode: "shrunk"
        }), () => {
          this.getPageData();

        })
      }, (e) => {
        window.location.replace("/login");
      })
    }

  }


  render() {
    let isDroneLive = Object.keys(this.state.drones).filter((drone) => { return this.state.drones[drone].online }).length > 0
    return (
      <div className="wrapper"
        style={{ overflowY: "hidden" }} >
        <div
          className="right-content-new"
          style={{ height: "100vh", overflowY: "auto", left: "0px", width: "100vw" }}
        >
          <div className="top-bar-new">
            <div className="top-bar-text-new">Drone Control Center - All Available Drone Feeds</div>
          </div>
          <div
            className="main-content main-content-dcc"
            style={{
              paddingTop: "1px",
              paddingLeft: "30px",
              paddingRight: "30px",
              display: "flex",
              overflowY: "auto"
            }}
          >

            {!this.state.loading ? Object.keys(this.state.drones).length == 0 ? <NoContent />
              : <div className="dcc-all-feeds-live-feeds">
                {Object.keys(this.state.drones).map((drone, i) => (
                  <div className="dcc-all-feeds-feed-window">
                    <div className="dcc-all-drones-live-feeds-header">
                      <span className="dcc-all-feeds-live-feeds-header-text">
                        {this.state.drones[drone].droneName.length > 20 ? `${this.state.drones[drone].droneName.substring(0, 20)}...` : this.state.drones[drone].droneName}
                      </span>
                      <span className="dcc-all-drones-live-feeds-header-button">
                        <img src={viewFullscreenFeedButton} onClick={() => {
                          window.open("/aerogcsenterprise/drone_control_center/feed?drone=" + drone, '_blank');
                        }} />
                      </span>
                    </div>
                    <VideoPlayer url={this.state.drones[drone].streamURL} />
                  </div>
                ))}
              </div> : <LoaderComponent />}

          </div>
        </div>
      </div >

    );
  }
}
export default DCCAllFeeds;
