import React, { Component } from 'react';
import closeButton from './close-button.png';
import closeIcon from "../src/icons/blackClose.png"
import editIcon from './edit.svg';
import { Modal } from "react-bootstrap";
import waiting from './res/waiting.png';
import success from './assets/check_circle_24px.png';
import fail from './assets/error.png';
import { Link } from 'react-router-dom';
import * as API from './api.js';
import './style.css';
import ButtonWithIcon from './ReusableComponents/ButtonWithIcon';
import { ActivityPopup } from './ReusableComponents/ActivityPopup';
import { AnimatedModal } from './ReusableComponents/AnimatedModal.js';
import AppConstants from './AppConstants.js';
import { Popup } from './ReusableComponents/reusableFunctions';
import dateFormat from 'dateformat';
import TableViewPagination from './ReusableComponents/TableView/TableViewPagination.js';


export default class AdminUserDetails extends Component {
    state = {
        userCredits: [],
        service: {
            servs: [],
            allservs: []
        },
        services: [],
        allServices: [],
        serviceStatus: [],
        user: {},
        invoices: [],
        type: "",
        user_info: {
            first_name: "",
            last_name: "",
            email: "",
            address: "",
            password: "",
            phone: "",
            userid: ""
        },
        config: {},
        confirmCancelSub: false,
        popupText: "",
        subId: '',
        today: new Date().toISOString(),
        userAnalytic: false,
        userStorageDetails: undefined,
        addOnPopup: false,
        addOnExpiryDate: '',
        addOnQty: '',
        updateAddOn: false,
        activityPopup: false,
        isDelete: false,
        pageNumber: 1,
        pageSize: 10,
        totalCount: 0
    }

    sendReminderEMail = (invoiceID) => {
        API.adminSendInvoiceReminder(invoiceID).then(res => {
            Popup.alert("COMPLETE", "Email sent to user")
        }).catch(e => {
            alert(e)
            Popup.alert("ERROR", e)
        })
    }

    getUsersInvoices = () => {
        API.adminGetUsersInvoices(this.state.user_info.userid, this.state.pageSize, this.state.pageNumber).then(res => {
            let { invoices, totalCount } = res
            this.setState({
                invoices,
                totalCount
            })
        }).catch(e => {
            Popup.alert("ERROR", "Failed to get your invoices. Try again later. Error: " + e)
        })
    }

    getUser = () => {
        API.getUser(this.state.user_info.userid, true).then(res => {
            let userData = res?.users?.[0]?.subs || []
            console.log('res.users[0]', res.users[0]);

            this.setState({
                user_info: res.users[0],
                updateAddOn: userData.length > 0 ? userData.find((e) => e.service == AppConstants.SERVICES.DRONE_CONTROL_CENTER) : false,
            })
        }).catch(e => {
            Popup.alert("ERROR", e)
        })
    }

    componentWillMount() {
        if (this.props.location.state) {
            document.title = " Users Details - Admin";
            let { user, type, user_info } = this.props.location.state;

            this.setState((state) => ({
                ...state,
                user,
                type,
                user_info,
            }), () => {
                this.getSubbedServices()
                this.getSubbedServices1()
                this.getUserStorage();
                this.getUser();
                this.getUsersInvoices()

            })
        } else {
            window.location.replace(process.env.REACT_APP_CLIENT_PROTOCOL + "://" + process.env.REACT_APP_CLIENT_HOST + (process.env.REACT_APP_CLIENT_PORT ? process.env.REACT_APP_CLIENT_PORT : "") + "/admin/dashboard");
            return;
        }
    }

    deleteUser = () => {
        this.setState({ isDelete: false })
        Popup.alert('WAIT', 'Deleting user please wait...')
        API.deleteUser(this.state.user_info).then((result) => {
            if (result == 'OK') {
                Popup.alert('COMPLETE', 'User deleted successfully.')
                this.props.history.push('/admin/users', {
                    user: this.state.user,
                });
            }
        }).catch((e) => {
            Popup.alert('ERROR', e.message || 'An internal server error occured while deleting user.')
        })
    }

    cancelUserSubscription = () => {
        Popup.alert('WAIT', "Cancelling Subscription, Please Wait...")
        API.cancelUserSubscriptionPlan(this.state.subId).then(async (data) => {
            let res = await data.json()
            this.setState({ subId: '' })
            if (data.status == 200) {
                Popup.alert('COMPLETE', 'Subscription Cancelled Successfully !')
                this.getUser()
            } else {
                Popup.alert('ERROR', res.message || 'An internal server error occured.')
                this.getUser()
            }
        })
    }

    getSubbedServices = () => {
        API.getUserSubbedServices(this.state.user_info.username).then((response) => {
            let serviceStatus = response[1]
            serviceStatus.forEach((service, i) => {
                if (response[0].find((element) => {
                    return element.id === service.id
                })) {
                    service.assigned = true;
                } else {
                    service.assigned = false;
                }
            });
            this.setState((state) => ({
                ...state,
                services: response[0],
                allServices: response[1],
                serviceStatus
            }))
        })
    }

    getSubbedServices1 = () => {
        API.getUserSubbedServices().then(
            (services) => {

                let names = services[0].map((service) => { return service.title })
                if (names.findIndex(data => data == "Analytic Tool") != -1) {
                    this.setState((state) => ({
                        ...state,
                        userAnalytic: true
                    }));
                }
            },
            (e) => {
                this.setState({
                    userAnalytic: false
                });
            }
        );
    };

    getUserStorage = async () => {
        await API.getUserStorage(this.state.user_info.userid).then(res => {
            this.setState({
                userStorageDetails: res
            })
        }).catch(err => {
            console.log(err);

        })
    }

    handleUserCreditLimitChange = (id, value) => {

        this.setState(state => {
            const newlimit = state.userCredits.map(credit => {
                if (credit.id === id) {
                    return { ...credit, credits_limit: value }
                } else return credit
            })
            return { ...state, userCredits: newlimit }

        })
    }

    handleEditUserCredit = (editType, id) => {
        this.setState(state => {
            const newCredits = state.userCredits.map(credit => {
                if (credit.id === id) {
                    if (editType === "usage") return { ...credit, editUsage: !credit.editUsage }
                    if (editType === "limit") return { ...credit, editLimit: !credit.editLimit }

                } else return credit
            })
            return { ...state, userCredits: newCredits }
        })
    }

    closeActivityPopup = () => {
        this.setState({
            activityPopup: false,
            confirmCancelSub: false,
        })
    }

    addAddOnDrones = () => {
        this.setState({ addOnPopup: false, })
        Popup.alert('WAIT', "Adding please wait...")
        let newDate = new Date(this.state.addOnExpiryDate)
        newDate.setHours(23, 59,)
        let expiryDate = new Date(newDate).toISOString()
        console.log('expiryDate', expiryDate);
        API.addDroneAddOn({ dronesLimit: this.state.addOnQty, expiryDate: expiryDate, userId: this.state.user_info.userid }).then(result => {
            console.log('result', result);
            this.setState({ expiryDate: '', addOnQty: '' })
            Popup.alert('COMPLETE', "Drones added successfully.")
            this.getUser()
        }).catch(err => {
            console.log(err)
            this.setState({ expiryDate: '', addOnQty: '' })
            Popup.alert('ERROR', "Error occured while add add-on drones.")
        })
    }

    updateAddOnDrones = () => {
        this.setState({ addOnPopup: false, })
        Popup.alert('WAIT', "Updating please wait...")
        let newDate = new Date(this.state.addOnExpiryDate)
        newDate.setHours(23, 59,)
        let expiryDate = new Date(newDate).toISOString()
        console.log('expiryDate', expiryDate);
        API.updateDroneAddOn({ userAddOnDronesSubscriptionId: this.state.updateAddOn.id, dronesLimit: this.state.addOnQty, expiryDate: expiryDate, userId: this.state.user_info.userid }).then(result => {
            console.log('result', result);
            this.setState({ expiryDate: '', addOnQty: '' })
            Popup.alert('COMPLETE', "Drones updated successfully.")
            this.getUser()
        }).catch(err => {
            console.log(err)
            this.setState({ expiryDate: '', addOnQty: '' })
            Popup.alert('ERROR', "Error occured while updating add-on drones.")
        })
    }

    getCurrentDate = (d) => {
        const date = d ? new Date(d) : new Date();
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');  // Months are zero-indexed
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    render() {
        let { user, type, user_info, } = this.state
        return (
            user && user.username ? <div className="wrapper">
                <ActivityPopup
                    item={this.state.activityPopup?.item}
                    open={this.state.activityPopup}
                    icon={this.state.activityPopup?.icon}
                    action={this.state.activityPopup?.action}
                    msg={this.state.activityPopup?.message}
                    close={() => {
                        this.closeActivityPopup()
                    }}
                    onClickOk={() => {
                        if (this.state.isDelete) {

                            this.deleteUser()
                        } else if (this.state.confirmCancelSub) {
                            this.cancelUserSubscription()
                        }
                        this.closeActivityPopup()
                    }}
                />


                <div className="right-content-new" >
                    <AnimatedModal
                        isOpen={this.state.addOnPopup}
                        height={"auto"}
                        width={"550px"}
                    >
                        <div style={{
                            boxShadow: "0px 0px 0px 1px rgb(235,238,241)",
                            background: "white",
                            height: "",
                            width: "100%",
                            marginTop: "",
                            overflow: "hidden",
                            borderRadius: "10px",
                            display: "flex",
                            flexDirection: "column"
                        }}>
                            <div style={{ backgroundColor: "#EAEAEA", height: "40px", display: "flex", justifyContent: "center", alignItems: "center", font: "15px", color: "#3c3c3c", fontWeight: "500" }}> AEROGCS ADD ON DRONES </div>
                            <div style={{ padding: "20px", borderBottom: "", textAlign: "left", position: "relative", display: "flex", flexDirection: "column", gap: "10px", height: "100%" }}>
                                <table>
                                    <col style={{ width: '50%' }} />
                                    <col style={{ width: '50%' }} />
                                    <tr style={{ borderTop: "1px solid lightgray", borderBottom: "1px solid lightgray" }} >
                                        <th style={{ color: "rgb(65,69,82)", fontWeight: "700", padding: "10px 0px", }}>Qty</th>
                                        <th style={{ color: "rgb(65,69,82)", fontWeight: "700", padding: "10px 0px", }}>Subscription Expiry</th>
                                    </tr>
                                    <tr style={{ borderBottom: "1px solid lightgray" }}>
                                        <td style={{ color: "rgb(65,69,82)", fontWeight: "500", padding: "10px 0px", }}>
                                            <input className="sub-add-user-form-text" type="number"
                                                value={this.state.addOnQty}
                                                style={{ width: '90%' }}
                                                onChange={(e) => {
                                                    let v = e.target.value
                                                    if (v < 999999) {
                                                        this.setState({ addOnQty: parseInt(v) })
                                                    }
                                                }} maxLength={6} />
                                        </td>
                                        <td style={{ color: "rgb(65,69,82)", fontWeight: "500", padding: "10px 0px", display: 'flex', alignItems: 'center' }}>
                                            <input
                                                className="sub-add-user-form-text"
                                                type="date"
                                                value={this.state.addOnExpiryDate}
                                                style={{ width: '90%' }}
                                                min={this.getCurrentDate()}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    this.setState({ addOnExpiryDate: value });
                                                }}
                                            />
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: '20px' }}>
                                <button
                                    className='remove-botton-styling'
                                    style={{ transition: "0.5s all", fontSize: "11px", height: "30px", color: "#2989cf", backgroundColor: "white", border: "1px solid #2989cf", borderRadius: "20px", padding: "6px 20px 10px", fontWeight: "600", }} type="button"
                                    onClick={() => {
                                        this.setState({ addOnPopup: false, addOnExpiryDate: '', addOnQty: '' })
                                    }}
                                >Cancel</button>
                                <button
                                    className='remove-botton-styling'
                                    style={{
                                        transition: "0.5s all",
                                        fontSize: "11px",
                                        height: "30px",
                                        color: "white",
                                        backgroundColor: "#2989cf",
                                        borderRadius: "20px",
                                        padding: "6px 20px 10px",
                                        fontWeight: "600",
                                        opacity: this.state.addOnExpiryDate && this.state.addOnQty > 0 ? "1" : "0.5",
                                        cursor: this.state.addOnExpiryDate && this.state.addOnQty > 0 ? "pointer" : "not-allowed"
                                    }}
                                    onClick={() => {
                                        if (this.state.updateAddOn) {
                                            this.updateAddOnDrones()
                                        } else {
                                            this.addAddOnDrones()
                                        }

                                    }}
                                    disabled={this.state.addOnExpiryDate && this.state.addOnQty > 0 ? false : true}
                                >{this.state.updateAddOn ? 'Update' : 'Add'}</button>
                            </div>
                        </div>
                    </AnimatedModal>

                    <div className="top-bar-new"><div className="top-bar-text-new">USER DETAILS</div></div>
                    <div className="main-content-new">
                        <div className="page" style={{ padding: "0px 0px 20px 0px", height: "auto", minHeight: "815px" }}>
                            <div className="customer-details-top-section">
                                <img className="customer-details-logo" style={{ borderRadius: "50%" }} src={`/images/${user_info.first_name[0].toLowerCase()}.PNG`} />
                                <div className="services-content-title customer-details-company-name">{user_info.first_name + " " + user_info.last_name}</div>
                                <div className="customer-details-actions-row" style={{ width: "fit-content", marginLeft: "auto", marginTop: "5px", marginRight: "40px" }}>
                                    {this.state.userAnalytic &&
                                        <Link
                                            style={{ display: 'flex', marginRight: '16px' }}
                                            to={{
                                                pathname: "/admin/user_tracking_logs",
                                                state: {
                                                    user, type, user_info: user_info,
                                                    serviceStatus: this.state.serviceStatus,
                                                    services: this.state.services
                                                }
                                            }}
                                        >
                                            <button style={{ padding: "0", outline: "none", border: "none", backgroundColor: "white" }}>
                                                <div className="addcustomer-button-empty-dashboard" style={{ width: "6rem", display: 'flex', justifyContent: 'center' }} >
                                                    Analytics
                                                </div>
                                            </button>
                                        </Link>
                                    }
                                    <Link
                                        style={{ display: 'flex', }}
                                        to={{
                                            pathname: "/admin/edituser",
                                            state: {
                                                user,
                                                type,
                                                first_name: user_info.first_name,
                                                last_name: user_info.last_name,
                                                email: user_info.username,
                                                phone: user_info.phone,
                                                address: user_info.address,
                                                userid: user_info.userid
                                            }
                                        }}>
                                        <button style={{ padding: "0", outline: "none", border: "none", backgroundColor: "white" }}>
                                            <div className="addcustomer-button-empty-dashboard" style={{ width: "5rem" }} >
                                                Edit
                                            </div>
                                        </button>
                                    </Link>
                                    <button style={{ padding: "0", outline: "none", border: "none", marginLeft: "15px", backgroundColor: "white" }}
                                        onClick={() => {
                                            this.setState({
                                                isDelete: true,
                                                activityPopup: {
                                                    icon: "WARNING",
                                                    message: 'Are you sure you want to delete this user and all associated data?',
                                                }
                                            });
                                        }}>
                                        <div className="addcustomer-button-empty-dashboard" style={{ width: "5rem" }} >
                                            Delete
                                        </div>
                                    </button>
                                </div>
                            </div>

                            <div className="customer-details-middle-section">
                                <div className="customer-details-primary-row">
                                    <div className="customer-details-primary-row-left">
                                        <div className="customer-details-primary-row-left-title">First Name:</div>
                                        <div className="customer-details-primary-row-left-value">{`${user_info.first_name}`}</div>
                                    </div>
                                    <div className="customer-details-primary-row-right">
                                        <div className="customer-details-primary-row-left-title">Last Name:</div>
                                        <div className="customer-details-primary-row-left-value">
                                            <div>{`${user_info.last_name}`}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="customer-details-primary-row">
                                    <div className="customer-details-primary-row-left">
                                        <div className="customer-details-primary-row-left-title">Email:</div>
                                        <div className="customer-details-primary-row-left-value">{`${user_info.username}`}</div>
                                    </div>
                                    <div className="customer-details-primary-row-right">
                                        <div className="customer-details-primary-row-left-title">Phone Number:</div>
                                        <div className="customer-details-primary-row-left-value">
                                            <div>{`${user_info.phone}`}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="customer-details-primary-row">
                                    <div className="customer-details-primary-row-left">
                                        <div className="customer-details-primary-row-left-title">Address:</div>
                                        <div className="customer-details-primary-row-left-value">{user_info.address}</div>
                                    </div>
                                    <div className="customer-details-primary-row-right">
                                        <div className="customer-details-primary-row-left-title">Flights:</div>
                                        <div className="customer-details-primary-row-left-value">
                                            <div>{`${user_info.flightCount}`}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="customer-details-primary-row">
                                    <div className="customer-details-primary-row-left">
                                        <div className="customer-details-primary-row-left-title">Base Plan Drones Limit:</div>
                                        <div className="customer-details-primary-row-left-value">{user_info.serviceDronesLimit}</div>
                                    </div>
                                    <div className="customer-details-primary-row-right">
                                        <div className="customer-details-primary-row-left-title">Add-on Drones:</div>
                                        <div className="customer-details-primary-row-left-value">
                                            <div>{user_info.addOnDroneCountLimit}</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="customer-details-primary-row">
                                    <div className="customer-details-primary-row-left">
                                        <div className="customer-details-primary-row-left-title">Total Registered Drones:</div>
                                        <div className="customer-details-primary-row-left-value">{user_info.droneCount}</div>
                                    </div>
                                    <div className="customer-details-primary-row-right">
                                        <div className="customer-details-primary-row-left-title">Active Status Drones:</div>
                                        <div className="customer-details-primary-row-left-value">
                                            <div>{user_info.registerDroneCount}</div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="customer-details-bottom-section">
                                <div style={{ marginBottom: "19px", display: "flex" }}>
                                    <div className="customer-details-primary-row-left-title" style={{ paddingTop: "10px" }}>User Subscriptions</div>
                                    <div style={{ marginLeft: "auto", width: "fit-content", display: 'flex', gap: '20px' }}>
                                        <div className="addcustomer-button-empty-dashboard"
                                            style={{ padding: "10px 23px 10px 23px", cursor: 'pointer' }}
                                            onClick={() => {
                                                if (this.state.updateAddOn) {
                                                    this.setState({ addOnQty: user_info.addOnDroneCountLimit, addOnExpiryDate: this.getCurrentDate(this.state.updateAddOn.expiry_date) })
                                                }
                                                this.setState({ addOnPopup: true })
                                            }}
                                        >{`${this.state.updateAddOn ? 'Modify' : ''} Drone Add-On`}</div>
                                        <Link to={{ pathname: "/admin/user_manage_plan", state: { user, type, user_info: user_info, serviceStatus: this.state.serviceStatus, services: this.state.services } }}>
                                            <div className="addcustomer-button-empty-dashboard" style={{ padding: "10px 23px 10px 23px", }}>Add Subscription</div>
                                        </Link>
                                    </div>
                                </div>
                                <table className="customers-table" >
                                    <tr className="customers-table-header-row" style={{ position: "inherit" }}>
                                        <th style={{
                                            width: "25%", position: "inherit"
                                        }}>Subscription Tier</th>
                                        <th style={{
                                            width: "20%", position: "inherit"
                                        }}>Service</th>
                                        <th style={{
                                            width: "15%", position: "inherit"
                                        }}>Status</th>
                                        <th style={{
                                            width: "25%", position: "inherit"
                                        }}>Expires on</th>
                                        <th style={{
                                            width: "15%", position: "inherit"
                                        }}>Actions</th>
                                    </tr>
                                    {user_info.subs && user_info.subs.length > 0 ?
                                        user_info.subs.filter((s) => s.service == AppConstants.SERVICES.DRONE_CONTROL_CENTER || s.service == AppConstants.SERVICES.AEROGCS).map((sub) => (
                                            <tr className="customers-table-data-row">
                                                <td>{sub.plan_name}</td>
                                                <td>{sub.service}</td>
                                                <td>{sub.expiry_date > this.state.today ? <div style={{ color: "green" }}>Active</div> : <div style={{ color: "red" }}>Expired</div>}</td>
                                                <td>{dateFormat(sub.expiry_date, 'ddd, mmm dd yyyy  hh:MM TT')}</td>
                                                {sub.expiry_date > this.state.today ? <td>
                                                    <button className="addcustomer-button-empty-dashboard" style={{ padding: "10px 15px 10px 15px", color: "#f97272", borderColor: "#f97272" }}
                                                        onClick={() => {
                                                            this.setState((state) => ({
                                                                ...state,
                                                                confirmCancelSub: true,
                                                                subId: sub.id,
                                                                activityPopup: {
                                                                    icon: "WARNING",
                                                                    message: `Are you sure you want to cancel this subscription for '${sub.plan_name}' ?`,
                                                                }
                                                            }))
                                                        }}
                                                    >Cancel</button>
                                                </td> : <td align='center'> - </td>}
                                            </tr>
                                        )) : (
                                            <tr className="customers-table-data-row">
                                                <td colspan="5" style={{ textAlign: "center" }}>-- No Subscriptions --</td>
                                            </tr>
                                        )
                                    }
                                </table>


                                {/* User Storage */}
                                <div style={{ marginBottom: "19px", display: "flex", alignItems: "center", marginTop: "40px" }}>
                                    <div className="customer-details-primary-row-left-title" style={{ paddingTop: "10px" }}>User Storage</div>
                                </div>
                                <table className="customers-table" >
                                    <tr className="customers-table-header-row" style={{ position: "inherit" }}>
                                        {/* <th style={{
                                            width: "25%", position: "inherit"
                                        }}>Service</th> */}
                                        <th style={{
                                            width: "25%", position: "inherit"
                                        }}>Type</th>
                                        <th style={{
                                            width: "20%", position: "inherit"
                                        }}>Usage (GB)</th>
                                        <th style={{
                                            width: "15%", position: "inherit"
                                        }}>Limits (GB)</th>

                                    </tr>
                                    {
                                        this.state?.userStorageDetails ?
                                            this.state?.userStorageDetails?.storageDetails.filter((s) => s.service == AppConstants.SERVICES.AEROGCS || s.service == AppConstants.SERVICES.DRONE_CONTROL_CENTER).map((sd) => (
                                                <tr className="customers-table-data-row">
                                                    <td>{sd.service} </td>
                                                    <td><div style={{ display: "flex", alignItems: "center" }}><div>{(Number(sd.used) / 1024).toFixed(2)}</div> </div></td>
                                                    <td><div style={{ display: "flex", alignItems: "center" }}><div>{(Number(sd.limit) / 1024).toFixed(2)}</div>  </div></td>
                                                </tr>
                                            )) :
                                            <tr className="customers-table-data-row">
                                                <td colspan="3" style={{ textAlign: "center" }}>--No Data Available--</td>
                                            </tr>
                                    }
                                </table>


                                <div style={{ marginBottom: "19px", display: "flex" }}>
                                    <div className="customer-details-primary-row-left-title" style={{ marginTop: "40px" }}>User's Invoices</div>
                                </div>
                                <table className="customers-table">
                                    <tr className="customers-table-header-row" style={{ position: "inherit" }}>
                                        <th style={{ position: "inherit" }}>
                                            Invoice Number
                                        </th>
                                        <th style={{ position: "inherit" }}>
                                            Product
                                        </th>
                                        <th style={{ position: "inherit" }}>
                                            Price
                                        </th>
                                        <th style={{ position: "inherit" }}>
                                            Created Time
                                        </th>
                                        <th style={{ position: "inherit" }}>
                                            Status
                                        </th>
                                        {/* <th style={{ position: "inherit" }}>
                                            Action
                                        </th> */}
                                    </tr>
                                    {this.state.invoices.length ? this.state.invoices.map((invoice, i) => <tr className="customers-table-data-row">
                                        <td>
                                            <Link to={{
                                                pathname: "/invoice",
                                                state: { user, user_info: user_info, invoiceId: invoice.id, adminView: true, }
                                            }} style={{
                                                color: "#707683"
                                            }}>
                                                {invoice.invoice_number}
                                            </Link>
                                        </td>
                                        <td>
                                            {invoice.product}
                                        </td>
                                        <td>
                                            {invoice?.payment?.currency === "inr" ? "₹" : invoice?.payment?.currency === "usd" ? "$" : "₹"}{invoice.price - (invoice.price * invoice.discount / 100)}
                                        </td>
                                        <td>{dateFormat(invoice.created_time, 'ddd, mmm dd yyyy  hh:MM TT')}</td>
                                        <td>
                                            {invoice.payment.payment_status == "paid" ? <div style={{ color: "green" }}>Paid</div> : <div style={{ color: "red" }}>Pending</div>}
                                        </td>
                                        {/* <td style={{ width: "190px" }}>
                                            {!invoice.payment.date_time ? <button className="addcustomer-button-empty-dashboard" onClick={() => {
                                                this.sendReminderEMail(invoice.id)
                                            }}>Remind</button> : <div>--</div>}
                                        </td> */}
                                    </tr>) : <tr className="customers-table-data-row">
                                        <td colspan="6" style={{ textAlign: "center" }}>--No invoices--</td>
                                    </tr>}
                                </table>
                                <TableViewPagination
                                    currentPage={Number(this.state.pageNumber)}
                                    totalCount={this.state.totalCount || 0}
                                    pageSize={this.state.pageSize}
                                    padding="10px 5px"
                                    onPageChange={(page) => {
                                        this.setState({ pageNumber: page }, () => { this.getUsersInvoices() })
                                    }}
                                />

                                <div style={{ marginBottom: "19px", marginTop: "80px", display: "flex" }}>
                                    <div className="customer-details-primary-row-left-title" style={{ paddingTop: "10px" }}>Services Assigned</div>
                                    <div style={{ marginLeft: "auto", width: "fit-content" }}>
                                        <Link to={{ pathname: "/admin/assign_services", state: { user, type, user_info: user_info, serviceStatus: this.state.serviceStatus, services: this.state.services } }}>
                                            <div className="addcustomer-button-empty-dashboard" >Assign Services</div>
                                        </Link>
                                    </div>
                                </div>
                                <table className="customers-table">
                                    <tr className="customers-table-header-row" style={{ position: "inherit" }}>
                                        <th style={{ position: "inherit" }}>Service Name</th>
                                        <th style={{ position: "inherit" }}>Subscribed</th>
                                    </tr>
                                    {
                                        this.state.serviceStatus.filter(service => (!(this.state.user_info.role_id == '8b28f377-c02f-4f3c-8bd7-f4794c066546') || !(service.title == "NPNT Services")) && (!(this.state.user_info.organization == "31e5a4fd-150f-4600-a233-c1ed88bb59b3") || !(service.title == "NPNT Services"))).map((service) => (
                                            <tr className="customers-table-data-row">
                                                <td>{service.title}</td>
                                                <td>{service.assigned ? "yes" : "no"}</td>
                                            </tr>
                                        ))
                                    }
                                </table>
                            </div>
                        </div>
                    </div>
                </div >
            </div > : <div />
        );
    }
}
