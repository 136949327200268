import React, { useEffect, useRef, useState } from 'react'
import { createChart } from 'lightweight-charts';
import dateFormat from 'dateformat'
import Loader from "../../icons/loader.svg";
import NoContent from '../../ReusableComponents/NoContent';
import LoaderComponent from '../../ReusableComponents/LoaderComponent';


const InteractiveGraph = ({
  title,
  titleStyle,
  data,
  loading,
  showZoom,
  showDecimalValues,
  style,
  isPriceFromat,
  lineColor,
  topColor,
  bottomColor,
  dateTimeFormat,
  displayCurrentTime,
  tickLength,
  pointMarkersVisible,
  pointMarkersRadius,
  lineType,
  xAxisLable,
  lineWidth,
  isShowTooltip = true,
  isShowCrosshairMarker = true,
  isVertLine = true,
  isHorzLine = true,
  isTimeScaleVisible = true,
  isTimeVisible = true,
  leftPriceScaleVisible = true,
  crosshairMode = 0,
  priceLineVisible = true,
  loaderWidth,
  loaderHeight,
  interval,
  isCummulative,
  fromDate,
}) => {
  const [handleScaleScroll, setHandleScaleScroll] = useState(false);
  const chartContainerRef = useRef();
  const graphScaleScroll = () => {
    setHandleScaleScroll(!handleScaleScroll);
  };

  const myPriceFormatter = (p) => {
    if (p < 0 && !isPriceFromat) {
      return "";
    }
    const rounded = p.toFixed(2);
    const wholeNumber = Number(rounded);
    return Number.isInteger(wholeNumber)
      ? wholeNumber.toString()
      : showDecimalValues
        ? rounded
        : "";
  };

  const myTickMarkFormatter = (time, tickMarkType, locale) => {
    return dateFormat(
      new Date(time * 1000),
      dateTimeFormat || "dd mmm yyyy hh:MM TT"
    );
  };

  const myTimeFormatter = (originalTime) => {
    return dateFormat(
      new Date(originalTime * 1000),
      dateTimeFormat || "dd mmm yyyy hh:MM TT"
    );
  };

  const uniqueData = () => {
    const seenTimes = new Set();
    return data.reduce((acc, d) => {
      const time = Number(d.time);
      if (!seenTimes.has(time)) {
        seenTimes.add(time);
        acc.push({ value: Number(d.value), time: time });
      }
      return acc;
    }, []);
  };

  useEffect(() => {
    if (data && chartContainerRef.current) {
      const uniqueArray = uniqueData();
      const chartOptions = {
        layout: {
          textColor: "black",
          background: {
            type: "solid",
            color: "#fcfcfc",
          },
        },
        rightPriceScale: {
          visible: false,
        },
        leftPriceScale: {
          visible: leftPriceScaleVisible,
          mode: "logarithmic",
          borderVisible: false,
          borderColor: "#ffffff",
          minValue: 0,
        },
        grid: {
          vertLines: false,
          horzLines: false,
        },
        timeScale: {
          visible: isTimeScaleVisible,
          timeVisible: isTimeVisible,
          secondsVisible: false,
          borderVisible: false,
          borderColor: "#ffffff",
        }
      };
      const chart = createChart(chartContainerRef.current, chartOptions);

      const areaSeries = chart.addAreaSeries({
        lineColor: lineColor || "#2962FF",
        topColor: topColor || "rgba(57, 136, 249, 0.4)",
        bottomColor: bottomColor || "rgba(57, 136, 249, 0)",
        lineWidth: lineWidth || 1.5,
        lineType: lineType == undefined ? 2 : lineType,
        crosshairMarkerRadius: 6,
        crosshairMarkerBorderColor: lineColor || "#2962FF",
        crosshairMarkerBackgroundColor: "#ffffff",
        crosshairMarkerBorderWidth: 2,
        fixLeftEdge: true,
        fixRightEdge: true,
        pointMarkersVisible: pointMarkersVisible || true,
        pointMarkersRadius: pointMarkersRadius || 3,
        crosshairMarkerVisible: isShowCrosshairMarker,
        priceLineVisible: priceLineVisible
      });

      chart.timeScale().fitContent();

      chart.applyOptions({
        crosshair: {
          vertLine: {
            visible: isVertLine,
            color: lineColor || "#2962FF",
          },
          horzLine: {
            visible: isHorzLine,
            color: lineColor || "#2962FF",
          },
          mode: crosshairMode
        },
        timeScale: {
          tickMarkFormatter: myTickMarkFormatter,
          tickMarkMaxCharacterLength: tickLength || 19,
        },
        localization: {
          priceFormatter: myPriceFormatter,
          timeFormatter: myTimeFormatter,
        },
        handleScroll: {
          mouseWheel: handleScaleScroll,
          pressedMouseMove: handleScaleScroll,
          horzTouchDrag: handleScaleScroll,
          vertTouchDrag: handleScaleScroll,
        },
        handleScale: {
          axisPressedMouseMove: handleScaleScroll,
          mouseWheel: handleScaleScroll,
          pinch: handleScaleScroll,
        },
      });

      const toolTipHeight = 70;
      const toolTipMargin = 15;

      // Create and style the tooltip html element
      const toolTip = document.createElement("div");
      toolTip.style = `width: max-content; height: 70px; position: absolute; display: none; padding: 8px; box-sizing: border-box; font-size: 12px;
            text-align: center; z-index: 1000; top: 12px; left: 12px; pointer-events: none; border: 1px solid; border-radius: 5px;
            font-family: -apple-system, BlinkMacSystemFont, 'Trebuchet MS', Roboto, Ubuntu, sans-serif; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;`;
      toolTip.style.background = "white";
      toolTip.style.color = "black";
      toolTip.style.borderColor = lineColor || "#2962FF";
      chartContainerRef.current.appendChild(toolTip);

      if (isShowTooltip) {
        chart.subscribeCrosshairMove((param) => {
          if (
            param.point === undefined ||
            !param.time ||
            param.point.x < 0 ||
            param.point.x > chartContainerRef.current.clientWidth ||
            param.point.y < 0 ||
            param.point.y > chartContainerRef.current.clientHeight
          ) {
            toolTip.style.display = "none";
          } else {
            const dateStr = param.time;
            const dataIndex = areaSeries.data().findIndex((point) => point.time === dateStr);

            // Get current data point
            const currentData = areaSeries.data()[dataIndex];

            if (!currentData) { return; }

            let formattedPreviousDate = "";
            let formattedCurrentDate;
            if (interval === "year") {
              formattedCurrentDate = dateFormat(currentData.time * 1000, 'mmm');
            } else {
              if (interval === "week") {
                formattedCurrentDate = dateFormat(new Date(currentData.time * 1000), dateTimeFormat || "dd mmm yyyy");
              } else {
                formattedCurrentDate = dateFormat(new Date(currentData.time * 1000), dateTimeFormat || "dd mmm yyyy hh:MM TT");
              }
            }


            //show the previous date for the first data points 
            if (dataIndex == 0 && !isCummulative) {
              const time1 = parseFloat(areaSeries.data()[0]?.time);
              const time2 = parseFloat(areaSeries.data()[1]?.time);
              const timeDiffInSeconds = time2 - time1;
              const lessThanDay = timeDiffInSeconds * 1000 < 24 * 60 * 60 * 1000;

              // Convert the first timestamp to a Date object
              const firstDate = new Date(time1 * 1000);

              // Subtract the time difference from the first timestamp
              const previousDateTimestamp = time1 - timeDiffInSeconds;
              const previousDate = new Date(previousDateTimestamp * 1000);
              if (interval === 'custom') {
                if (fromDate) {
                  const previousDate = new Date(fromDate).getTime();
                  formattedPreviousDate = dateFormat(new Date(previousDate), "dd mmm yyyy");
                }
              } else {
                formattedPreviousDate = dateFormat(new Date(previousDate), "dd mmm yyyy");
                if (lessThanDay) {
                  formattedPreviousDate += ", " + dateFormat(new Date(previousDate), "hh:MM TT");
                }
              }
            }
            if (dataIndex > 0) {
              const previousData = isCummulative ? areaSeries.data()[0] : areaSeries.data()[dataIndex - 1];
              if (previousData) {
                const timeDifference = Math.abs(currentData.time - previousData.time);
                const lessThanDay = timeDifference * 1000 < 24 * 60 * 60 * 1000;
                formattedPreviousDate = dateFormat(new Date(previousData.time * 1000), "dd mmm yyyy");
                if (lessThanDay) {
                  formattedPreviousDate += ", " + dateFormat(new Date(previousData.time * 1000), "hh:MM TT");
                }
              }
            }

            if (isCummulative && (interval === 'all')) {
              const time1 = parseFloat(areaSeries.data()[0]?.time);
              const time2 = parseFloat(areaSeries.data()[1]?.time);
              const timeDiffInSeconds = time2 - time1;
              const lessThanDay = timeDiffInSeconds * 1000 < 24 * 60 * 60 * 1000;

              // Convert the first timestamp to a Date object
              const firstDate = new Date(time1 * 1000);

              // Subtract the time difference from the first timestamp
              const previousDateTimestamp = time1 - timeDiffInSeconds;
              const previousDate = new Date(previousDateTimestamp * 1000);
              formattedPreviousDate = dateFormat(new Date(previousDate), "dd mmm yyyy");
              if (lessThanDay) {
                formattedPreviousDate += ", " + dateFormat(new Date(previousDate), "hh:MM TT");
              }
            } else {
              if (isCummulative) {
                if (fromDate) {
                  const previousDate = new Date(fromDate).getTime();
                  if (interval === "day") {
                    formattedPreviousDate = dateFormat(new Date(previousDate), "dd mmm yyyy hh:MM TT");
                  } else {
                    formattedPreviousDate = dateFormat(new Date(previousDate), "dd mmm yyyy ");
                  }
                }
              }
            }

            toolTip.style.display = "block";
            const price = currentData.value !== undefined ? currentData.value : currentData.close;
            let formatedDate;
            if (formattedPreviousDate && formattedCurrentDate) {
              formatedDate = displayCurrentTime ? `${formattedCurrentDate}` : `${formattedPreviousDate} - ${formattedCurrentDate}`;
            } else {
              formatedDate = `${formattedCurrentDate}`;
            }

            toolTip.innerHTML = `
                          <div style="color: ${"#7f7f7f"}; font-size: 16px; font-weight: 500">
                              ${(interval === "year" || interval === "week" && !isCummulative) ? formattedCurrentDate : formatedDate}
                          </div>
                          <div style="margin: 4px 0px;">
                              <span style="color: ${"black"}; font-size: 18px; font-weight: 500">
                                  ${Math.round(100 * price) / 100} 
                              </span>
                          </div>
                      `;

            const coordinate = areaSeries.priceToCoordinate(price);
            if (coordinate === null) { return; }
            const chartWidth = chartContainerRef.current.clientWidth;
            const toolTipWidth = toolTip.offsetWidth;
            let shiftedCoordinate = param.point.x - 50;
            const overflowRight = (shiftedCoordinate + toolTipWidth) - chartWidth;
            if (overflowRight > 0) {
              shiftedCoordinate -= (overflowRight - 40); // Shift left only by overflow amount
            }
            shiftedCoordinate = Math.max(0, shiftedCoordinate);

            const coordinateY = coordinate - toolTipHeight - toolTipMargin > 0 ? coordinate - toolTipHeight - toolTipMargin
              : Math.max(0, Math.min(chartContainerRef.current.clientHeight - toolTipHeight - toolTipMargin, coordinate + toolTipMargin));

            toolTip.style.left = shiftedCoordinate + "px";
            toolTip.style.top = coordinateY + "px";
          }
        });
      }
      areaSeries.setData(
        uniqueArray.map((d) => {
          return { value: Number(d.value), time: Number(d.time) };
        })
      );

      return () => {
        chart.remove();
        toolTip.remove();
      };
    }
  }, [data, chartContainerRef.current, handleScaleScroll, loading]);

  return (
    <div className="flight-graph-container-new" style={style}>
      {loading ?
        <LoaderComponent width={loaderWidth || ""} height={loaderHeight || ""} />
        :
        data.length === 0 ?
          <NoContent /> :

          <>
            <div style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}>
              <div className="flight-graph-container-title" style={{ paddingBottom: "10px", fontSize: "18px", fontWeight: "500", ...titleStyle }}>
                {title}
              </div>
              {showZoom ? (
                <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                  <input
                    type="checkbox"
                    style={{ height: "18px", width: "18px" }}
                    checked={handleScaleScroll}
                    onChange={() => graphScaleScroll()}
                  />{" "}
                  Zoom
                </div>
              ) : (
                <div></div>
              )}
            </div>
            {data.length === 0 ?
              <NoContent />
              :
              <>
                <div
                  ref={chartContainerRef}
                  style={{
                    width: "100%",
                    height: xAxisLable
                      ? "calc(100% - 65px)"
                      : "calc(100% - 35px)",
                  }}
                />
                {xAxisLable ? (
                  <div
                    style={{
                      marginTop: "5px",
                      width: "100%",
                      textAlign: "center",
                      color: "#555C67",
                    }}
                  >
                    {xAxisLable}
                  </div>
                ) : (
                  <></>
                )}
              </>
            }
          </>
      }
    </div>
  );
};

export default InteractiveGraph;