import React, { Component, createRef, useRef } from "react";
import * as API from "./api.js";
import "./style.css";
import AeroGCSInactive from './sidebaricon/AeroGCSInactive.png';
import DronesActive from "./dronesActive.png";
import RPAS from "./rpas.png";
import MenuDark from './icons/MenuDark.png';
import { Redirect, withRouter } from "react-router-dom";
import { ActivityPopup } from './ReusableComponents/ActivityPopup.js';
import TableView from "./ReusableComponents/TableView/TableView.js";
import { getFlightTime } from "./AeroGCSEnterprise/Flights/Flights.js";
import { BreadCrumbs } from "./ReusableComponents/BreadCrumbs.js";
import dateFormat from 'dateformat';
import Button from "./ReusableComponents/Button/Button.js";
import { GCS_FEATURES, getActiveTeam, getFeaturesPermissions } from "./Teams/index.js";
import { Popup, addUserAnalytic, updateOutTime } from "./ReusableComponents/reusableFunctions.js";
import { AnimatedModal } from './ReusableComponents/AnimatedModal.js';
import flightReplay from "./replayVideo.svg";
import lockIcon from './userAuth/assets/lock.svg'
import eyeIcon from './userAuth/assets/eye.svg'
import eyeOffIcon from './userAuth/assets/eye-off.svg'
import closeBtn from "./icons/close.png"
import DroneErrorLogsBtn from "./ReusableComponents/ErrorLogsBtn.js";
import LoaderComponent from "./ReusableComponents/LoaderComponent.js";
import SearchHeader from './ReusableComponents/SearchHeader/SearchHeader.js';
import DateTimePicker from "./AeroGCSEnterprise/Components/FlightsFilter/DateTimePicker.js";
import Cookies from "universal-cookie";
const cookies = new Cookies();

let otpInterval = null


class DrnMgmtDroneDetails extends Component {
  state = {
    user: {},
    type: "",
    deleteRedirect: false,
    drone: {},
    model: {},
    sidebarMode: this.props.location.state
      ? this.props.location.state.sidebarMode
      : "shrunk",
    showUnregisterDronePopup: false,
    addLeaseDronePopUp: false,
    usersCustomers: [],
    selectedCustomer: '',
    selectedCustomerName: '',
    lease: [],
    edit: false,
    editLease: '',
    minDate: new Date(),
    lease_drone: false,
    leaseActivityPopup: false,
    droneRentEdit: false,
    rentAmount: 0,
    isLoading: true,
    filter: {
      searchString: undefined,
      fromDateTime: undefined,
      toDateTime: undefined,
      orderBy: undefined,
      orderType: undefined,
      pageSize: 15,
      pageNumber: 1,
    },

    activityPopup: undefined,
    permissions: undefined,
    deleteData: undefined,
    outId: "",
    activityPopup: undefined,
    deleteFlightLogs: false,
    droneDataDeleteOtpPopup: false,
    errorOtp: '',
    showOtp: false,
    otpTimer: ``,
    timeOutFlag: false,
    verifyOtpAndDeleteData: false,
    otp: '',
    droneDelete: false,
    loading: true,
    occuredError: [],
    crashErrors: [],
    medianErrorCount: 0,
  };

  closeActivityPopup = () => {
    this.setState((state) => ({
      ...state,
      activityPopup: undefined,
      deleteData: undefined,
      deleteFlightLogs: false,
      errorOtp: '',
      showOtp: false,
      otpTimer: ``,
      timeOutFlag: false,
      verifyOtpAndDeleteData: false,
      otp: '',
      otpTimer: '',
      droneDelete: false,
    }))
    if (this.state.deleteRedirect) window.history.back();
  }

  unregister = () => {
    this.setState((state) => ({
      activityPopup: {
        open: true,
        msg: "Unregistering Drone , Please Wait...",
        item: "WAIT",
        icon: "WAIT",
      }
    }), () => {
      API.removeGCSDrones(this.state.drone.drone_id)
        .then((results) => {
          console.log("result:", results);
          this.setState((prevState) => ({
            ...prevState,
            activityPopup: {
              open: true,
              msg: "Drone unregistered successfully.",
              item: "COMPLETE",
              icon: "COMPLETE",
            }
          }), () => {
            this.getDronesData()
          });
        })
        .catch((e) => {
          this.setState((state) => ({
            activityPopup: {
              open: true,
              msg: e.message || 'Error',
              item: "ERROR",
              icon: "ERROR",
            }
          }))
          try {
            console.log(JSON.stringify(e));
          } catch (exception) {
            console.log(e);
          }
        });
    })

  };

  componentWillUnmount() { updateOutTime(this.state.outId) }

  componentWillMount() {
    getActiveTeam().then(activeTeam => {
      this.setState({
        activeTeam: activeTeam
      }, () => {
        if (this.props.location.state.drone) {
          let cookiesData = cookies.get("drone_details");
          console.log('cookiesData', cookiesData);

          if (!cookiesData) {
            cookies.set('drone_details',
              JSON.stringify({ ...this.state.filter, droneId: this.props.location.state.drone.drone_id }),
              { path: "drone_management/drone_details" })
          }
          this.setState({ filter: cookiesData && cookiesData.droneId == this.props.location.state.drone.drone_id ? cookiesData : this.state.filter })

          document.title = "Drone Details - Management Services";
          let { user, type, drone } = this.props.location.state;
          getFeaturesPermissions([GCS_FEATURES.PLANS]).then(permissions => {
            addUserAnalytic(this.props.location.pathname).then(id => { this.setState({ outId: id }) })
            this.setState({
              user,
              type,
              drone,
              permissions,
            }, async () => {
              await this.getDronesData()
              await this.getFlightsData()
              const flightsStats = await API.getGCSFlightStats({ drones: [this.state.drone.drone_id] })
              this.setState({
                totalFlights: flightsStats.total_flights,
                totalAcreage: flightsStats.total_acres_covered,
                totalDistance: flightsStats.total_distance,
                loading: false,
              })
            });
          })
        } else {
          addUserAnalytic(this.props.location.pathname).then(id => { this.setState({ outId: id }) })
          window.location.replace("/login");
          return;
        }
      })
    })
  }

  getDronesData = async () => {
    try {
      this.setState({
        isLoading: true
      }, async () => {
        const result = await API.getGCSDrones({ droneId: this.state.drone.drone_id })
        result.drones.map((drone1, key) => {
          if (drone1.drone_id == this.state.drone.drone_id) {
            this.setState(state => ({
              ...state,
              drone: drone1,
              isLoading: false,
            }))
          }
        })
      })
    }
    catch (e) {
      console.error(e)
      this.setState({
        activityPopup: {
          open: true,
          msg: e.message || "Something went wrong while getting drone data.",
          item: "ERROR",
          icon: "ERROR",
        }
      })
    }
  }

  getFlightsData = async () => {
    try {
      this.setState({
        isLoading: true
      }, async () => {
        cookies.set('drone_details', {...this.state.filter, droneId:this.state.drone.drone_id}, { path: "drone_management/drone_details" })

        let { fromDateTime, toDateTime } = this.state.filter;
        const flights = await API.getGCSFlights({
          drones: [this.state.drone.drone_id], fromDateTime: fromDateTime ? new Date(fromDateTime).toISOString() : undefined, toDateTime: toDateTime ? new Date(toDateTime).toISOString() : undefined,
          orderBy: this.state.filter.orderBy, orderType: this.state.filter.orderType, pageSize: this.state.filter.pageSize, pageNumber: this.state.filter.pageNumber, projectName: undefined, planName: undefined
        })
        this.setState({ flights, isLoading: false })
      })
    }
    catch (e) {
      console.error(e)
      this.setState({
        activityPopup: {
          open: true,
          msg: e.message || 'Something went wrong while getting flights data.',
          item: "ERROR",
          icon: "ERROR",
        }
      })
    }
  }


  deleteFlight = async () => {
    try {
      this.setState({
        activityPopup: {
          open: true,
          msg: 'Deleting flight and related data please wait...',
          item: "WAIT",
          icon: "WAIT",
        }
      })
      const data = await API.deleteFlightData(this.state.deleteData.id)
      this.setState({
        deleteData: undefined,
        activityPopup: {
          open: true,
          msg: data.message,
          item: "COMPLETE",
          icon: "COMPLETE",
        }
      })
      this.getDronesData()
      this.getFlightsData()
      const flightsStats = await API.getGCSFlightStats({ drones: [this.state.drone.drone_id] })
      this.setState({
        totalFlights: flightsStats.total_flights,
        totalAcreage: flightsStats.total_acres_covered,
        totalDistance: flightsStats.total_distance,
      })
    }
    catch (e) {
      this.closeActivityPopup()
      Popup.alert("ERROR", e?.message || e)
    }
  }

  deleteDrone = () => {
    this.setState({
      activityPopup: {
        open: true,
        msg: "Deleting drone please wait...",
        item: "WAIT",
        icon: "WAIT",
      }
    }, () => {
      API.deleteDrone(this.state.drone.drone_id).then(async (result) => {
        let res = await result.json()
        if (result.status == 200) {
          this.setState({
            activityPopup: {
              open: true,
              msg: res.message,
              item: "COMPLETE",
              icon: "COMPLETE",
            }
          }, () => {
            setTimeout(() => {
              window.history.back()
            }, 1000)
          })
        } else if (result.status == 401) {
          this.setState({
            deleteFlightLogs: true,
            activityPopup: {
              open: true,
              msg: `This drone has ${res.flightCount} associated flights. Are you sure you want to delete them as well?`,
              item: "WARNING",
              icon: "WARNING",
            }
          })
        } else {
          this.setState({
            activityPopup: {
              open: true,
              msg: res.message || 'Error',
              item: "ERROR",
              icon: "ERROR",
            }
          })
        }
      }).catch((e) => {
        this.setState({
          activityPopup: {
            open: true,
            msg: e.message || 'Error',
            item: "ERROR",
            icon: "ERROR",
          }
        })
      })
    })
  }

  sendOtpForDeleteLogs = () => {
    API.sendOtpForDeleteLogs(this.state.drone.drone_id).then(async (result) => {
      let res = await result.json()
      if (otpInterval) clearInterval(otpInterval);
      if (result.status == 200) {
        let timerData = res.remainTime
        let minutes = Number(timerData.split(':')[0])
        let seconds = Number(timerData.split(':')[1])
        console.log('time', minutes, seconds);
        otpInterval = setInterval(() => {
          let min = `0${minutes}`
          let sec = seconds < 10 ? `0${seconds}` : seconds
          this.setState((state) => ({
            ...state,
            otpTimer: min < 1 ? `Resend OTP in ${min} : ${sec} sec` : `Resend OTP in ${min} : ${sec} min`
          }))
          seconds--;
          if (seconds < 0) {
            minutes--;
            seconds = 59;
            if (minutes < 0) {
              clearInterval(otpInterval);
              this.setState((state) => ({
                ...state,
                otpTimer: ``,
                timeOutFlag: true
              }))
            }
          }
        }, 1000);
        this.setState({ activityPopup: undefined, droneDataDeleteOtpPopup: true, timeOutFlag: false, resend: false, deleteFlightLogs: false })
      } else {
        this.setState({
          activityPopup: {
            open: true,
            msg: res.message || 'Error',
            item: "ERROR",
            icon: "ERROR",
          }
        })
      }
    }).catch((e) => {
      this.setState({
        activityPopup: {
          open: true,
          msg: e.message || 'Error',
          item: "ERROR",
          icon: "ERROR",
        }
      })
    })
  }

  verifyOtpAndDeleteDroneDataWithDrone = () => {
    this.setState({
      verifyOtpAndDeleteData: false,
      activityPopup: {
        open: true,
        msg: 'Deleting drone and all associated flights. please wait...',
        item: "WAIT",
        icon: "WAIT",
      }
    }, () => {
      API.verifyOtpAndDeleteDroneDataWithDrone(this.state.drone.drone_id, this.state.otp).then(async (result) => {
        let res = await result.json()
        if (result.status == 200) {
          this.setState({
            droneDataDeleteOtpPopup: false,
            activityPopup: {
              open: true,
              msg: 'The drone and all associated flights have been successfully deleted.',
              item: "COMPLETE",
              icon: "COMPLETE",
            }
          }, () => {
            setTimeout(() => {
              window.history.back()
            }, 1000)
          })
        } else {
          this.setState({
            activityPopup: {
              open: true,
              msg: res.message || 'Error',
              item: "ERROR",
              icon: "ERROR",
            }
          })
        }
      }).catch((e) => {
        this.setState({
          activityPopup: {
            open: true,
            msg: e.message || 'Error',
            item: "ERROR",
            icon: "ERROR",
          }
        })
      })
    })
  }

  handleFilterChange = (newFilter, updatedField) => {
    const { fromDateTime, toDateTime } = newFilter;
    const isoFromDateTime = fromDateTime === undefined ? undefined : new Date(fromDateTime).toISOString();
    const isoToDateTime = toDateTime === undefined ? undefined : new Date(toDateTime).toISOString();
    this.setState({
      filter: {
        ...this.state.filter,
        ...newFilter,
        fromDateTime: fromDateTime === undefined || toDateTime === undefined ? undefined : isoFromDateTime,
        toDateTime: fromDateTime === undefined || toDateTime === undefined ? undefined : isoToDateTime,
      }
    }, () => {
      if (updatedField === "searchString") {
        this.setState({
          filter: {
            ...this.state.filter,
            pageNumber: 1,
          }
        }, () => { this.debounnceSearchFn(); })
      } else {
        this.getFlightsData();
      }
    });
  }

  render() {
    let { user, drone, } = this.state;

    return user.username ? (
      <div className="wrapper">
        <div className="right-content-new" >
          <ActivityPopup
            item={this.state.activityPopup?.item}
            open={this.state.activityPopup?.open}
            icon={this.state.activityPopup?.icon}
            msg={this.state.activityPopup?.msg}
            close={() => { this.closeActivityPopup() }}
            onClickOk={() => {
              if (this.state.deleteData) {
                this.deleteFlight()
              } else if (this.state.verifyOtpAndDeleteData) {
                this.verifyOtpAndDeleteDroneDataWithDrone()
              } else if (this.state.deleteFlightLogs) {
                this.setState({
                  activityPopup: {
                    open: true,
                    msg: "Sending OTP to delete flights associated with this drone. Please wait...",
                    item: "WAIT",
                    icon: "WAIT",
                  }
                }, () => {
                  this.sendOtpForDeleteLogs()
                })
              } else if (this.state.drone.is_registered == false && this.state.droneDelete) {
                this.deleteDrone()
              } else {
                this.unregister()
              }
            }}
          />
          <AnimatedModal
            isOpen={this.state.droneDataDeleteOtpPopup}
            height="350px"
            width="500px"
          >
            <div style={{ width: '100%', textAlign: 'center', fontSize: '16px', fontWeight: '500', marginTop: '25px' }}>Verify Account</div>
            <img src={closeBtn} style={{
              width: "12px",
              height: "12px",
              cursor: "pointer",
              top: "12px",
              right: '17px',
              position: 'absolute'
            }} onClick={() => {
              this.setState({
                droneDataDeleteOtpPopup: false
              })
              this.closeActivityPopup()
            }} />
            <hr style={{ width: '96%', color: 'grey', marginTop: '10px' }} />
            <div style={{ width: '100%', textAlign: 'center', lineHeight: '20px', marginTop: '10px', fontSize: '14px', fontWeight: '400', padding: '0px 20px' }}>An OTP to delete the drone-associated data has been sent to your registered email.</div>

            <div class="login-error" id="otpErrorMsg" style={{ height: '30px', fontSize: '14px', color: 'red', marginTop: '5px' }}>{this.state.errorOtp}</div>

            <div style={{ textAlign: 'center' }}>
              <img style={{ position: 'absolute', marginTop: '21px', marginLeft: '25px' }} src={lockIcon} />
              <input
                autoFocus
                class="text-input login-text-input" id="otp" style={{ width: '18rem' }} name="otp"
                type={this.state.showOtp ? "text" : "password"} onChange={(e) => {
                  this.setState({
                    errorOtp: '',
                    otp: e.target.value
                  })
                }}
                placeholder="OTP" maxlength="6" required />
              <img className="login-password-wrapper-img" id="view-password" style={{ cursor: 'pointer', top: '175px', left: '360px' }}
                src={this.state.showOtp ? eyeOffIcon : eyeIcon} onClick={() => {
                  this.setState({ showOtp: !this.state.showOtp })
                }} />
            </div>
            <div style={{ height: '25px', fontSize: '14px', marginTop: '10px' }}>
              {this.state.otpTimer}
            </div>

            <div style={{ textAlign: 'center', marginTop: '20px' }}>
              {this.state.timeOutFlag === false ?
                this.state.otp ?
                  <button type="button" class="loginButton" name="otpButton" id="otpButton" onClick={() => {
                    this.setState({
                      verifyOtpAndDeleteData: true,
                      deleteFlightLogs: false,
                      activityPopup: {
                        open: true,
                        msg: 'Are you sure you want to delete this drone and all associated flights?',
                        item: "WARNING",
                        icon: "WARNING",
                      }
                    })
                  }}>Verify</button>
                  : <button type="button" class="loginButton" name="otpButton" id="otpButton" style={{ opacity: '0.5', cursor: 'not-allowed' }}>Verify</button>
                : <button type="button" style={{ opacity: this.state.resend ? '0.5' : '1' }} class="loginButton" name="resendButton" id="resendButton" disabled={this.state.resend}
                  onClick={() => {
                    this.setState({ resend: true })
                    this.sendOtpForDeleteLogs()
                  }}>Resend OTP</button>}
            </div>
          </AnimatedModal>

          <div className="top-bar-new">
            <div className="top-bar-text-new">
              <BreadCrumbs
                data={[
                  {
                    displayName: "AeroGCS",
                    pathname: "/",
                    state: {
                      user
                    }
                  },
                  {
                    displayName: 'Drones',
                    pathname: "/aerogcsenterprise/drones",
                    state: {
                      user
                    }
                  },
                  {
                    displayName: 'Drone Details',
                  }
                ]} />
            </div>
          </div>
          <div className="main-content-new">
            {this.state.loading ? <LoaderComponent /> : <>
              <div className="droneDetailsView" style={{ marginTop: '15px' }}>
                <div style={{ display: 'flex', gap: "10px", alignItems: 'center' }}>
                  <div className="droneDetailsViewTitle" title={drone.drone_name || "-"}>{drone.drone_name ? drone.drone_name : "-"}</div>
                  <DroneErrorLogsBtn
                    linkState={{
                      drone: { drone_id: drone.drone_id, drone_name: drone.drone_name }
                    }}
                  />
                </div>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "10px" }}>
                  <Button text={'Hardware Details'} isDisabled={this.state.user.subs.length == 0} className='new-btn-lg'
                    onClick={() => {
                      this.props.history.push("/aeroGCSEnterprise/drone/hardwareDetails", { drone: this.state.drone, user: this.state.user })
                    }} />
                  <Button text={'Health Report'} isDisabled={this.state.user.subs.length == 0} className='new-btn-lg'
                    onClick={() => {
                      this.props.history.push("/aerogcsenterprise/drone/drone_health_report", { drone: this.state.drone, user: this.state.user })
                    }} />
                  {this.state.activeTeam.isOwner && <div style={{ display: "flex" }}>
                    {drone.is_registered ? <Button isBtnActive text={'Unregister'} isDisabled={this.state.user.subs.length == 0} className='new-btn-lg'
                      onClick={() => {
                        this.setState({
                          activityPopup: {
                            open: true,
                            msg: 'Are you sure you want to unregister this drone ?',
                            item: "WARNING",
                            icon: "WARNING",
                          }
                        })
                      }} />
                      : <Button isBtnActive text={'Delete'} isDisabled={this.state.user.subs.length == 0} className='new-btn-lg'
                        onClick={() => {
                          this.setState({
                            droneDelete: true,
                            activityPopup: {
                              open: true,
                              msg: 'Are you sure you want to delete this drone ?',
                              item: "WARNING",
                              icon: "WARNING",
                            }
                          })
                        }} />}
                  </div>}
                </div>

              </div>
              <div className="droneDetailsViewContainer" style={{ marginTop: '15px' }}>
                {/* Basic Info */}
                <div className="droneDetailsViewData" style={{ width: '50%' }}>
                  <div className="droneDetailsViewSubTitle">Basic Info</div>
                  <div className="droneDetailsViewInfo">
                    <div style={{ width: '33%' }}>
                      <div className="droneDetailsViewHeadings" title="Drone Nam">Drone Name</div>
                      <div className="droneDetailsViewValues" title={drone.drone_name || "NA"}>{drone.drone_name || "NA"}</div>
                    </div>
                    <div style={{ width: '33%' }}>
                      <div className="droneDetailsViewHeadings" title="Drone UIN">Drone UIN</div>
                      <div className="droneDetailsViewValues" title={drone.uin || "NA"}>{drone.uin || "NA"}</div>
                    </div>
                    <div style={{ width: '33%' }}>
                      <div className="droneDetailsViewHeadings" title="Registered Date">Registered Date</div>
                      <div className="droneDetailsViewValues" title={drone.registered_date ? dateFormat(drone.registered_date, "dd mmm yyyy") : "NA"}>{drone.registered_date ? dateFormat(drone.registered_date, "dd mmm yyyy") : "NA"}</div>
                    </div>
                  </div>
                  <div className="droneDetailsViewInfo">
                    <div style={{ width: '66%' }}>
                      <div className="droneDetailsViewHeadings" title="Drone ID">Drone ID</div>
                      <div className="droneDetailsViewValues" title={drone.drone_id || "NA"}>{drone.drone_id || "NA"}</div>
                    </div>
                    <div style={{ width: '33%' }}>
                      <div className="droneDetailsViewHeadings" title="Active Since">Active Since</div>
                      <div className="droneDetailsViewValues" title={drone.first_flight_date ? dateFormat(drone.first_flight_date, "dd mmm yyyy") : "N/A"}>{drone.first_flight_date ? dateFormat(drone.first_flight_date, "dd mmm yyyy") : "N/A"}</div>
                    </div>
                  </div>
                  <div className="droneDetailsViewInfo">
                    <div style={{ width: '33%' }}>
                      <div className="droneDetailsViewHeadings" title="License Type">License Type</div>
                      <div className="droneDetailsViewValues" title={drone.license_type || "NA"}>{drone.license_type || "NA"}</div>
                    </div>
                    <div style={{ width: '33%' }}>
                      <div className="droneDetailsViewHeadings" title="Pilots Worked Under This Drone">Pilots Worked Under This Drone</div>
                      <div className="droneDetailsViewValues" title={drone.pilot_count || 0}>{drone.pilot_count || 0}</div>
                    </div>
                    <div style={{ width: '33%' }}>
                      <div className="droneDetailsViewHeadings" title="Status">Status</div>
                      <div className="droneDetailsViewValues" title={drone.is_registered ? 'Registered' : 'Unregistered' || 'NA'}>{drone.is_registered ? 'Registered' : 'Unregistered' || 'NA'}</div>
                    </div>
                  </div>
                </div>
                {/* Flight summary */}
                <div className="droneDetailsViewData" style={{ width: '50%' }}>
                  <div className="droneDetailsViewSubTitle">Flight Summary</div>
                  <div className="droneDetailsViewInfo">
                    <div style={{ width: '33%' }}>
                      <div className="droneDetailsViewHeadings" title="Total Number of Hours">Total Number of Hours</div>
                      <div className="droneDetailsViewValues" title={getFlightTime(drone.total_flight_time) || "NA"}>{getFlightTime(drone.total_flight_time) || "NA"}</div>
                    </div>
                    <div style={{ width: '33%' }}>
                      <div className="droneDetailsViewHeadings" title="Total Number of flights">Total Number of flights</div>
                      <div className="droneDetailsViewValues" title={this.state.totalFlights || 0}>{this.state.totalFlights || 0}</div>
                    </div>
                    <div style={{ width: '33%' }}>
                      <div className="droneDetailsViewHeadings" title="Total Distance">Total Distance</div>
                      <div className="droneDetailsViewValues" title={this.state.totalDistance ? `${this.state.totalDistance} m` : "NA"}>{this.state.totalDistance ? `${this.state.totalDistance} m` : "NA"}</div>
                    </div>
                  </div>
                  <div className="droneDetailsViewInfo">
                    <div style={{ width: '33%' }}>
                      <div className="droneDetailsViewHeadings" title="Total Acreage">Total Acreage</div>
                      <div className="droneDetailsViewValues" title={this.state.totalAcreage ? `${(Number(this.state.totalAcreage) * 0.000247105).toFixed(6)} acre` : "-"}>{this.state.totalAcreage ? `${(Number(this.state.totalAcreage) * 0.000247105).toFixed(6)} acre` : "-"}</div>
                    </div>
                    <div style={{ width: '66%' }}>
                      <div className="droneDetailsViewHeadings" title="Last Coordinates">Last Coordinates</div>
                      <div className="droneDetailsViewValues" title={drone.latLng ? `${drone.latLng}` : 'NA'}>{drone.latLng ? `${drone.latLng}` : 'NA'}</div>
                    </div>
                  </div>
                  <div className="droneDetailsViewInfo">
                    <div style={{ width: '100%' }}>
                      <div className="droneDetailsViewHeadings" title="Last Location">Last Location</div>
                      <div className="droneDetailsViewValues" title={drone.location ? drone.location : "NA"}>{drone.location ? drone.location : "NA"}</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="droneDetailsViewContainer" style={{ marginTop: '15px' }}>
                {/* Software Info */}
                <div className="droneDetailsViewData" style={{ width: '50%' }}>
                  <div className="droneDetailsViewSubTitle">Software Info</div>
                  <div className="droneDetailsViewInfo">
                    <div style={{ width: '33%' }}>
                      <div className="droneDetailsViewHeadings" title="AeroGCS Build Number">AeroGCS Build Number</div>
                      <div className="droneDetailsViewValues" title={drone.green_build_number || 'NA'}>{drone.green_build_number || 'NA'}</div>
                    </div>
                    <div style={{ width: '33%' }}>
                      <div className="droneDetailsViewHeadings" title="Android Version">Android Version</div>
                      <div className="droneDetailsViewValues" title={drone.android_version || 'NA'}>{drone.android_version || 'NA'}</div>
                    </div>
                    <div style={{ width: '33%' }}>
                      <div className="droneDetailsViewHeadings" title="Firmware Version">Firmware Version</div>
                      <div className="droneDetailsViewValues" title={drone.firmware_version || 'NA'}>{drone.firmware_version || 'NA'}</div>
                    </div>
                  </div>
                </div>
                {/* Hardware Info */}
                <div className="droneDetailsViewData" style={{ width: '50%' }}>
                  <div className="droneDetailsViewSubTitle">Hardware Info</div>
                  <div className="droneDetailsViewInfo">
                    <div style={{ width: '33%' }}>
                      <div className="droneDetailsViewHeadings" title="Board Type">Board Type</div>
                      <div className="droneDetailsViewValues" title={drone.board_type || 'NA'}>{drone.board_type || 'NA'}</div>
                    </div>
                    <div style={{ width: '33%' }}>
                      <div className="droneDetailsViewHeadings" title="Device RAM">Device RAM</div>
                      <div className="droneDetailsViewValues" title={drone.device_ram || 'NA'}>{drone.device_ram || 'NA'}</div>
                    </div>
                    <div style={{ width: '33%' }}>
                      <div className="droneDetailsViewHeadings" title="Device Model Name">Device Model Name</div>
                      <div className="droneDetailsViewValues" title={drone.device_model_name || 'NA'}>{drone.device_model_name || 'NA'}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="droneDetailsView" style={{ marginTop: '25px', width: '100%' }}>
                <div className="reusable-table-title" style={{ width: '30%' }}>{`Flights (${this.state.totalFlights || 0})`}</div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '70%', gap: '20px' }}>
                  {this.state.totalFlights != 0 && <Button isBtnActive
                    text={'View on map'}
                    className='new-btn-lg'
                    onClick={() => {
                      this.props.history.push('/aerogcsenterprise/user_flight_locations', {
                        user: this.state.user,
                        drone: this.state.drone
                      });
                    }}
                  />}
                </div>
              </div>

              <div className="drone-mgt-table-height" style={{ marginTop: '5px' }}>
                <TableView
                  isLoading={this.state.isLoading}
                  pageNumber={this.state.filter.pageNumber}
                  onStateChange={(changedState) => {
                    console.log('changedState', changedState);

                    this.setState({ filter: { ...this.state.filter, ...changedState } }, () => {
                      this.getFlightsData()
                    })
                  }}
                  onClickRow={(flight) => {
                    this.props.history.push('/aerogcsenterprise/remote_mission/flight_details', {
                      ...flight,
                      isOwner: this.state.activeTeam.isOwner,
                      drone: this.state.drone,
                      user: user,
                      flightId: flight.id,
                      planName: flight.plan_name,
                      projectName: flight.project_name,
                      date: flight.end_time,
                      startTime: flight.start_time,
                      end_time: flight.end_time,
                      streamKey: flight.streamkey,
                      areaCovered: flight.area_covered,
                      areaCoveredMethod: flight.area_calculation_method,
                      distanceCovered: flight.distance_covered,
                      droneId: flight.drone_id,
                      firmwareVersion: flight.firmware_version_number,
                      greenBuildNumber: flight.green_build_number,
                      droneName: flight.drone_name,
                      droneUIN: flight.uin,
                      software_edition: flight.software_edition
                    });
                  }}
                  onClickLink={(flight) => {
                    this.props.history.push("/aerogcsenterprise/drone_control_center/fly_view", { flight: flight.id, user: this.state.user, replyData: 0 })
                  }}
                  columns={[
                    { Header: "Project Name", accessor: "project_name", width: '13%', isSortable: true },
                    { Header: "Plan Name", accessor: "plan_name", width: '13%', isSortable: true },
                    { Header: "Start Time", accessor: "start_time", width: '16%', isSortable: true, isDate: true },
                    { Header: "End Time", accessor: "end_time", width: '16%', isSortable: true, isDate: true },
                    { Header: "Total Time", accessor: "total_flight_time", width: '10%', isSortable: true },
                    { Header: "Distance (M)", accessor: "distance_covered", width: '7%', isSortable: true },
                    { Header: "Area (Acr)", accessor: "area_covered", width: '10%', isSortable: true },
                    { Header: "Replay", accessor: "link_data", width: '5%', isLink: true, isCenter: true },
                  ]}
                  count={this.state.totalFlights || 0}
                  data={this.state.flights?.map((flight) => {
                    return ({
                      ...flight,
                      total_flight_time: getFlightTime(flight.total_flight_time),
                      area_covered: (Number(flight.area_covered) / 4046.85642).toFixed(4),
                      link_data: { img: flightReplay }
                    })
                  }) || []}
                  activities={this.state.activeTeam.isOwner && {
                    disableDelete: !this.state.permissions?.[GCS_FEATURES.PLANS]?.DELETE,
                    onClickDelete: (flight) => {
                      this.setState({
                        deleteData: flight,
                        activityPopup: {
                          open: true,
                          msg: 'Are you sure you want to delete selected flight and related data. (ie. streaming video, images, flight logs) ?',
                          item: "WARNING",
                          icon: "WARNING",
                        }
                      })
                    }
                  }}
                />
              </div>

            </>}

          </div>
        </div >
      </div >
    ) : (
      <div />
    );
  }
}

export default withRouter(DrnMgmtDroneDetails)