import React, { Component } from "react";
import * as API from "../api.js";
import "../style.css";
import { addUserAnalytic, updateOutTime } from '../ReusableComponents/reusableFunctions.js';
import deleteIcon from "../trash.svg";
import SideBar from "../SideBar";
import Loader from '../icons/loader.svg';
import { connect } from "react-redux";
import { updateProfileDetails } from '../redux'
import { ActivityPopup } from '../ReusableComponents/ActivityPopup.js';
import Dropdown from 'react-bootstrap/Dropdown';
import MenuDark from '../icons/MenuDark.png';
import noCustomerVector from '../icons/noCustomerVector.png'
import dateFormat from 'dateformat';
import { SelectComponent } from '../ReusableComponents/SelectComponent'
import AeroMeghLogo from "../aeromegh-logo-new.png";
import AeroGcsWhite from '../icons/AeroGcsWhite.png'
import greenReportsActive from '../icons/greenReportsActive.png'
import greenReportWhite from '../icons/greenReportWhite.png'
import customerWhiteIcon from '../icons/customerWhiteIcon.png'
import {
    Chart as ChartJS,
    BarElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
} from 'chart.js';
import { Line } from "react-chartjs-2";
import greenDownArrow from '../icons/greenDownArrow.png'

var someDate = new Date();
someDate.setDate(someDate.getDate());
var date = someDate.toISOString().substring(0, 10);
let dt = someDate.getDate()
let date2 = new Date();
date2.setDate(dt + 7)
let dd = date2.toISOString().substring(0, 10)
var today = new Date();
var lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 6);
let aa = lastWeek.toISOString().substring(0, 10);
// let markers = []

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href=""
        ref={ref}
        onClick={e => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
        <img src={MenuDark} style={{ height: "14px" }} alt="I am alt" />
    </a>
));


class AeroGCSGreenReports extends Component {
    state = {
        user: {},
        type: "",
        loading: true,
        sidebarMode: this.props.location.state
            ? this.props.location.state.sidebarMode
            : "shrunk",
        addCustomerPopUp: this.props.location.state.addCustomerPopUp ? this.props.location.state.addCustomerPopUp : false,
        customerAddress: '',
        contactNumber: '',
        customerEmail: '',
        customerGovIdNo: '',
        customerName: '',
        customerPilotLicenseId: '',
        itemName: "",
        showProcessPopup: false,
        processPopupIcon: "",
        processAction: "",
        processMessage: "",
        usersCustomers: [],
        deleteUserCust: '',
        customerId: '',
        gst_no: '',
        custEdit: false,
        editCustId: '',
        userDetailSelect: '',
        allReports: [],
        reportOnPopUp: false,
        reportDetailSelect: '',
        reportDetailSelectIndex: 0,
        selectedGreaphUnit: "week",
        selectedItem: [{ value: "week", label: "Week" }],
        itemsList: [{ value: "week", label: "Week" }, { value: "month", label: "Month" }, { value: "year", label: "Year" }],
        fromDate: aa,
        toDate: date,
        data: { datasets: [] },
        yTicks: {
            padding: 7,
            fill: "black",
            display: false,
            fontSize: 12,
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontColor: "#666666",
            lineHeight: "14px",
            beginAtZero: true,
        },

    };

    sortByDate = () => {
        let sort = this.state.allReports.sort((a, b) => a.created_datetime.localeCompare(b.created_datetime))
        this.setState((state) => ({
            ...state,
            allReports: sort,
        }));
    }

    kFormatter = (value) => {
        var val = Math.abs(value)
        if (val >= 10000000) {
            val = (val / 10000000).toFixed(1) + ' Cr';
        } else if (val >= 100000) {
            val = (val / 100000).toFixed(1) + ' Lac';
        }
        // else if (val >= 1000) {
        //     val = (val / 1000).toFixed(1) + ' K';
        // } else {
        //     val = (val).toFixed(2)
        // }
        return val;
    }

    formatCurrency = (n) => {
        return n.toFixed(1).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
    }


    onChangeEvent = (e) => {
        console.log(e);
        let value = e[0].value
        this.setState({
            selectedItem: e,
            selectedGreaphUnit: value,
            chartLoading: true,
        })
        if (value === 'week') {
            var lastWeek = new Date();
            lastWeek.setFullYear(today.getFullYear())
            lastWeek.setMonth(today.getMonth())
            lastWeek.setDate(today.getDate() - 6)
            let aa = lastWeek.toISOString().substring(0, 10);
            this.setState(
                (state) => ({
                    ...state,
                    fromDate: aa,
                }), () => this.getGreenSprayReportStat());
        } else if (value === 'month') {
            var lastMonth = new Date();
            lastMonth.setFullYear(today.getFullYear())
            lastMonth.setMonth(today.getMonth() - 1)
            lastMonth.setDate(today.getDate())
            let aa = lastMonth.toISOString().substring(0, 10);
            this.setState(
                (state) => ({
                    ...state,
                    fromDate: aa,
                }), () => this.getGreenSprayReportStat());
        } else if (value === 'year') {
            var lastYear = new Date();
            lastYear.setFullYear(today.getFullYear() - 1)
            lastYear.setMonth(today.getMonth() + 1)
            lastYear.setDate(1)
            let aa = lastYear.toISOString().substring(0, 10);
            this.setState((state) => ({
                ...state,
                fromDate: aa,
            }), () => this.getGreenSprayReportStat());
        }
    }


    reverseByDate = () => {
        let sort = this.state.allReports.sort((a, b) => b.created_datetime.localeCompare(a.created_datetime))
        this.setState((state) => ({
            ...state,
            allReports: sort,
        }));
    }

    componentWillUnmount() { updateOutTime(this.state.outId) }

    parentSidebarSwitch = () => {
        this.setState((state) => ({
            ...state,
            sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
        }))
    }

    componentWillMount() {
        ChartJS.register(BarElement, CategoryScale,
            ArcElement,
            LinearScale,
            PointElement,
            LineElement,
            Title,
            Tooltip,
            Legend,

        );
        addUserAnalytic(this.props.location.pathname).then(id => this.setState({ outId: id }))
        document.title = "All-Green-Reports";
        if (this.props.location.state) {
            let { user, type } = this.props.location.state;
            this.setState((state) => ({
                ...state,
                user,
                type
            }), () => { this.getGreenSprayReportStat() })
        } else {
            API.getUserType().then(([blah, type, user]) => {
                this.setState((state) => ({
                    ...state,
                    user,
                    type
                }), () => { this.getGreenSprayReportStat() })
            }, (e) => { window.location.replace("/login") })
        }
    }

    getPertage = (value1, value2, msg) => {
        let a = value2 - value1
        let b = a / value2
        let c = b * 100
        console.log(msg, c);
        return (c)
    }

    getGreenSprayReportStat = async () => {
        let graphUnit = this.state.selectedGreaphUnit;
        let toDate = this.state.toDate
        let fromDate = this.state.fromDate
        console.log('fromDate :', this.state.fromDate, 'toDate :', this.state.toDate);
        toDate = new Date(toDate + " 23:59:00+00")
        toDate = toDate.getTime() / 1000
        fromDate = new Date(fromDate + " 00:00:00+00")
        fromDate = fromDate.getTime() / 1000

        console.log(graphUnit, fromDate, toDate);
        API.getGreenSprayReportStat(graphUnit, fromDate, toDate).then(async (data) => {
            console.log(data)
            if (!data.message) {
                let yTicks = {
                    callback: v => this.kFormatter(v),
                    padding: 7,
                    fill: "black",
                    display: true,
                    fontSize: 12,
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontColor: "#666666",
                    lineHeight: "14px",
                    beginAtZero: true,
                }

                this.setState((state) => ({
                    ...state,
                    yTicks: yTicks,
                    totalAcres: data.totalAcres,
                    totalCustomers: data.totalCustomers,
                    allReports: data.graphUnitData,
                    reportDetailSelect: data.graphUnitData.length > 0 ? data.graphUnitData[0] : '',
                    reportDetailSelectIndex: 0,
                    totalRevenue: data.totalRevenue,
                    totalCrops: data.totalCrops,
                    allCrops: data.allCrops,
                    topCustomer: data.topCust,
                    topCrop: data.allCrops.length > 0 ? data.allCrops[0] : {},
                    lastThreeMTotalAmount: data.lastThreeMTotalAmount,
                    lastThreeMonths: data.lastThreeMonths,
                    monthOnMonthPer2: this.getPertage(Number(data.lastThreeMonths[1].amount), Number(data.lastThreeMonths[2].amount), 'monthonMonth'),
                    monthPer: Number(data.lastThreeMonths[2].amount) - Number(data.lastThreeMonths[0].amount) - Number(data.lastThreeMonths[1].amount),
                    lastTwoSeasons: data.lastTwoSeasons
                }), () => {
                    let monthOnMonthIncrease = Number(data.lastThreeMonths[2].amount) - Number(data.lastThreeMonths[1].amount)
                    let mvalue = [0, data.lastThreeMonths[1].amount, data.lastThreeMonths[2].amount]
                    let mlables = ['d', data.lastThreeMonths[1].monthName, data.lastThreeMonths[2].monthName]
                    let s1Value = data.lastTwoSeasons[1] ? Number(data.lastTwoSeasons[1].seasonTotalAmount) : 0
                    let s2Value = data.lastTwoSeasons[0] ? Number(data.lastTwoSeasons[0].seasonTotalAmount) : 0
                    let svalue = [0, s1Value, s2Value]
                    this.setState({
                        monthOnMonthIncrease: monthOnMonthIncrease,
                        seasonsOnSeasonsIncrease: this.getPertage(s1Value, s2Value, 'seasonOnseason'),
                        lastSeasonIncrease: s2Value - s1Value,
                        seasonsOnSeasons: {
                            labels: mlables,
                            datasets: [{
                                data: svalue,
                                fill: false,
                                borderColor: '#06A36D',
                            }]
                        },
                        monthsGdata: {
                            labels: mlables,
                            datasets: [{
                                data: mvalue,
                                fill: false,
                                borderColor: '#06A36D',
                            }]
                        }, loading: false, reportsLoading: false
                    })
                })

                if (this.state.selectedGreaphUnit == 'week') {
                    let data1 = (canvas) => {
                        return {
                            labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
                            datasets: [
                                {
                                    label: 'Revenue',
                                    data: data.data.amount,
                                    fill: false,
                                    borderColor: '#0D9164',
                                    backgroundColor: '#0D9164',
                                    lineTension: 0.1,
                                },
                                {
                                    label: 'Acreage',
                                    data: data.data.acreage,
                                    fill: false,
                                    borderColor: '#E88560',
                                    backgroundColor: '#E88560',
                                    lineTension: 0.1,
                                },
                            ],
                        }
                    }
                    this.setState((state) => ({
                        ...state,
                        data: data1(),
                        xAxisLabel: "Days",
                        chartLoading: false,
                    })
                    );
                } else if (this.state.selectedGreaphUnit == 'month') {
                    const data1 = (canvas) => {
                        return {
                            labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
                            datasets: [
                                {
                                    label: 'Revenue',
                                    data: data.data.amount,
                                    fill: false,
                                    borderColor: '#0D9164',
                                    backgroundColor: '#0D9164',
                                    lineTension: 0.1,
                                },
                                {
                                    label: 'Acreage',
                                    data: data.data.acreage,
                                    fill: false,
                                    borderColor: '#E88560',
                                    backgroundColor: '#E88560',
                                    lineTension: 0.1,
                                },
                            ],
                        }
                    }
                    this.setState((state) => ({
                        ...state,
                        data: data1(),
                        xAxisLabel: "Days",
                        chartLoading: false,
                    })
                    );
                } else if (this.state.selectedGreaphUnit == 'year') {
                    const data1 = (canvas) => {
                        return {
                            labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"],
                            datasets: [
                                {
                                    label: 'Revenue',
                                    data: data.data.amount,
                                    fill: false,
                                    borderColor: '#0D9164',
                                    backgroundColor: '#0D9164',
                                    lineTension: 0.1,
                                },
                                {
                                    label: 'Acreage',
                                    data: data.data.acreage,
                                    fill: false,
                                    borderColor: '#E88560',
                                    backgroundColor: '#E88560',
                                    lineTension: 0.1,
                                },
                            ],
                        }
                    }
                    this.setState((state) => ({
                        ...state,
                        data: data1(),
                        xAxisLabel: "Months",
                        chartLoading: false,
                    })
                    );
                }
            } else {
                this.setState((state) => ({
                    ...state,
                    itemName: "ReportStat",
                    processPopupIcon: "ERROR",
                    processAction: "GET",
                    processMessage: data.message,
                    showProcessPopup: true,
                    loading: false,

                }))
            }
        },
            (err) => {
                console.log(err);
                this.setState((state) =>
                ({
                    ...state,
                    loading: false
                }))
            })
    }


    deleteSprayingReport = () => {
        this.setState((state) => ({
            ...state,
            addCustomerPopUp: false,
            itemName: "Report",
            processPopupIcon: "WAIT",
            processAction: "DELETE",
            processMessage: null,
            showProcessPopup: true,
        }), () => {
            API.deleteSprayingReport(this.state.selectedReportDelete.id).then((deleteRes) => {
                console.log('deleteRes', deleteRes);
                if (deleteRes.message == "OK") {
                    this.setState((state) => ({
                        ...state,
                        itemName: "Report",
                        processPopupIcon: "COMPLETE",
                        processAction: "DELETE",
                        processMessage: 'Report Deleted sucessfully',
                        showProcessPopup: true,
                        reportDetailSelect: '',
                        reportDetailSelectIndex: 0,
                    }), () => {
                        this.getGreenSprayReportStat()
                    })
                } else {
                    this.setState((state) => ({
                        ...state,
                        itemName: "Report",
                        processPopupIcon: "ERROR",
                        processAction: "DELETE",
                        processMessage: deleteRes.message,
                        showProcessPopup: true,
                    }))
                }
            }, (e) => {
                this.setState((state) => ({
                    ...state,
                    itemName: "Report",
                    processPopupIcon: "ERROR",
                    processAction: "DELETE",
                    processMessage: 'Something went wrong while deleting Report.',
                    showProcessPopup: true,
                }))
            })
        })
    }

    closeActivityPopup = () => {
        this.setState((state) => ({
            ...state,
            showProcessPopup: false,
            processMessage: null,
        }))
    }

    render() {
        return <div className="wrapper">
          <div
                className="right-content-new"
                onClick={() => {
                    this.setState((state) => ({
                        ...state,
                        sidebarMode: "shrunk",
                    }));
                }}
            >
                <div className="top-bar-new">
                    <div className="top-bar-text-new" style={{ color: 'black' }}>AeroGCS Green Report</div>
                </div>
                <ActivityPopup
                    item={this.state.itemName}
                    open={this.state.showProcessPopup}
                    icon={this.state.processPopupIcon}
                    action={this.state.processAction}
                    msg={this.state.processMessage}
                    close={() => this.closeActivityPopup()}
                    onClickOk={() => this.deleteSprayingReport()}
                />

                {/* --------------------------------------------------------------- */}
                {this.state.loading ? <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
                    <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
                </div> :
                    <div className="main-content-new" style={{ background: '#F2F1EF' }} >
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', height: '100%', width: '100%' }}>
                            <div style={{ width: '100%', height: '49%', overflow: 'hidden' }}>
                                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', }}>
                                    <div style={{ fontSize: '15px', fontWeight: '500', letterSpacing: '1px', color: 'black', }}>Revenue Trend ({this.state.selectedGreaphUnit == 'week' ?
                                        `${dateFormat(this.state.fromDate, 'ddd')} - ${dateFormat(this.state.toDate, 'ddd')}`
                                        : this.state.selectedGreaphUnit == 'month' ? `${dateFormat(this.state.fromDate, 'dd/mmm')} - ${dateFormat(this.state.toDate, 'dd/mmm')}`
                                            : `${dateFormat(this.state.fromDate, 'mmm/yyyy')} - ${dateFormat(this.state.toDate, 'mmm/yyyy')}`})</div>
                                    <div style={{ display: 'flex', alignItems: 'center', }}>
                                        <div className="greenAllSideShadowDiv" style={{ border: 'none', background: 'white', borderRadius: '6px', padding: '2px 0px' }}>
                                            <SelectComponent
                                                selected={this.state.selectedItem}
                                                itemsList={this.state.itemsList}
                                                selectIcon={greenDownArrow}
                                                iconSize={"16px"}
                                                onchangeHandler={(value) => { this.onChangeEvent(value) }}
                                                fontSize={"14px"}
                                                width={"150px"}
                                                fontWeight={500}
                                                padding={"0px 5px 0px 10px"}
                                                selectedColor={'black'}
                                                height={'25px'}
                                            />
                                        </div>
                                        <div className="add-user-form-text-wrapper" style={{ display: "flex", }} >
                                            <label className="add-user-form-label" style={{ fontFamily: "Poppins", fontSize: "12px", paddingTop: "8px", marginLeft: "25px", fontWeight: '600' }}>From:</label>
                                            <input
                                                id="dateRequired"
                                                name="dateRequired"
                                                defaultValue={this.state.fromDate}
                                                className="greenCalenderIcon greenAllSideShadowDiv"
                                                type="date"
                                                max='9000-01-01'
                                                onChange={(e) => {
                                                    let value = e.target.value;
                                                    this.setState((state) => ({
                                                        ...state,
                                                        fromDate: value,
                                                    }))
                                                }}
                                                value={this.state.fromDate}
                                                required
                                                style={{
                                                    marginLeft: "5px",
                                                    height: "auto",
                                                    borderRadius: '5px',
                                                    paddingLeft: '5px',
                                                    width: "auto",

                                                }}
                                            />
                                            <label className="add-user-form-label" style={{ fontFamily: "Poppins", fontSize: "12px", paddingTop: "8px", marginLeft: "25px", fontWeight: '600' }}>To:</label>
                                            <input
                                                id="dateRequired"
                                                name="dateRequired"
                                                defaultValue={this.state.toDate}
                                                className="greenCalenderIcon greenAllSideShadowDiv"
                                                type="date"
                                                max='9000-01-01'
                                                onChange={(e) => {
                                                    let value = e.target.value;
                                                    this.setState((state) => ({
                                                        ...state,
                                                        toDate: value,
                                                    }),
                                                    )

                                                }}
                                                value={this.state.toDate}
                                                required
                                                style={{
                                                    marginLeft: "5px",
                                                    height: "auto",
                                                    borderRadius: '5px',
                                                    paddingLeft: '5px',
                                                    width: "auto",
                                                }}
                                            />
                                            <div className="add-user-form-submit-button"
                                                style={{ height: "30px", margin: "2px 10px", cursor: "pointer", textAlign: "center", padding: "10px 20px 10px 20px" }}
                                                onClick={() => {
                                                    this.getGreenSprayReportStat()
                                                }}
                                            >View</div>
                                        </div>
                                    </div>

                                </div>
                                <div className='revenueTrend' style={{ marginTop: '10px' }}>
                                    <div className='greenAllSideShadowDiv' style={{ width: '100%', height: '100%', background: 'white', borderRadius: '5px' }}>
                                        <div
                                            style={{
                                                width: "97%",
                                                height: '100%',
                                                padding: '15px 10px 5px 0px'
                                            }}
                                        >
                                            <Line
                                                width={"100%"}
                                                height={"25%"}
                                                options={{
                                                    responsive: true,
                                                    maintainAspectRatio: false,
                                                    plugins: {
                                                        legend: {
                                                            display: false,
                                                            position: 'top',
                                                            align: 'end',
                                                            labels: {
                                                                usePointStyle: true,
                                                                boxWidth: 10
                                                            }
                                                        }
                                                    },
                                                    elements: {
                                                        point: {
                                                            pointStyle: 'circle',
                                                            radius: 4
                                                        }
                                                    },
                                                    scales: {
                                                        x: {
                                                            grid: {
                                                                color: "#c2c2c2",
                                                                display: true,
                                                                drawOnChartArea: false,
                                                                drawTicks: true,
                                                                lineWidth: 1
                                                            },
                                                            ticks: {
                                                                padding: 2,
                                                                color: "black",
                                                                display: true,
                                                                beginAtZero: true,
                                                                fontSize: 11,
                                                                font: {
                                                                    family: "Poppins",
                                                                    style: "normal"
                                                                },
                                                                color: "#666666",
                                                                lineHeight: "auto"
                                                            },
                                                            title: {
                                                                display: true,
                                                                fontSize: 11,
                                                                font: {
                                                                    family: "Poppins",
                                                                    style: "normal"
                                                                },
                                                                color: "#666666",
                                                                borderColor: "#666666"
                                                            }
                                                        },
                                                        y: {
                                                            grid: {
                                                                color: "#c2c2c2",
                                                                value: "none",
                                                                display: true,
                                                                drawOnChartArea: true,
                                                                drawTicks: false,
                                                                lineWidth: 1
                                                            },
                                                            ticks: this.state.yTicks,
                                                            title: {
                                                                fontSize: 11,
                                                                font: {
                                                                    family: "Poppins",
                                                                    style: "normal"
                                                                },
                                                                color: "#666666",
                                                                display: true
                                                            }
                                                        }
                                                    }
                                                }}
                                                data={this.state.data}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {this.state.allReports.length ? <div style={{ width: '100%', height: '49%', overflow: 'hidden', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div style={{ width: '74%', height: '100%', background: 'white', borderRadius: '5px' }}>
                                    <div style={{ fontSize: '15px', fontWeight: '500', letterSpacing: '1px', color: 'black', padding: '10px' }}>Reports</div>
                                    <div style={{ width: '100%', height: 'calc(100% - 50px)', overflow: 'auto', padding: '0px 10px' }}>
                                        <table className="greenCustomersTable" style={{ marginTop: '0px' }}>
                                            <col style={{ width: '10%' }} />
                                            <col style={{ width: '45%' }} />
                                            <col style={{ width: '20%' }} />
                                            <col style={{ width: '20%' }} />
                                            <col style={{ width: '5%' }} />
                                            {this.state.allReports.map((report, key) => (
                                                <tr className="greenCustomersTableDataRow" style={{ background: key == this.state.reportDetailSelectIndex ? '#9AD8AB' : '#EDEDED' }}>
                                                    <td style={{ borderRadius: '10px 0px 0px 10px' }}><div className="leasedData" style={{ cursor: 'pointer', width: 'fit-content', }}
                                                        onClick={() => {
                                                            this.setState((state) => ({
                                                                ...state,
                                                                reportDetailSelect: report,
                                                                reportDetailSelectIndex: key,
                                                            }), () => {
                                                                // this.setState({ reportOnPopUp: true })
                                                            })
                                                        }}
                                                    >{dateFormat(report.created_datetime, 'dd/mm/yyyy')} </div></td>
                                                    <td>{report.customer_name ? report.customer_name.length > 20 ?
                                                        `${report.customer_name.substring(0, 20)}...` :
                                                        `${report.customer_name}` : 'Unknown'}</td>

                                                    <td>{report.spray_area ? report.spray_area : '0'} Acr</td>

                                                    <td>{report.amount ? report.amount : '0'} Rs</td>
                                                    <td style={{ textAlign: 'right', borderRadius: '0px 10px 10px 0px' }}>
                                                        <Dropdown >
                                                            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" >
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu size="sm" title="" className="custom-dropdown-dashboard1">
                                                                <Dropdown.Item eventKey="rename" className="dropdown-set-padding-dashboard">
                                                                    <div style={{ display: "flex", cursor: 'pointer' }}
                                                                        onClick={() => {
                                                                            this.setState((state) => ({
                                                                                ...state,
                                                                                selectedReportDelete: report
                                                                            }), () => {
                                                                                this.setState((state) => ({
                                                                                    ...state,
                                                                                    itemName: "Report",
                                                                                    processPopupIcon: "WARNING",
                                                                                    processAction: "DELETE",
                                                                                    processMessage: "Are you sure you want to delete this Report ?",
                                                                                    showProcessPopup: true,
                                                                                }))
                                                                            })
                                                                        }} >
                                                                        <img src={deleteIcon} style={{ height: "16px" }} />
                                                                        <div style={{ marginLeft: '7px', color: "#3c3c3c" }}>Delete</div>
                                                                    </div>
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown >
                                                    </td>
                                                </tr>
                                            ))}
                                        </table>
                                    </div>
                                </div>
                                <div style={{ width: '24%', height: '100%', background: 'white', borderRadius: '5px' }}>
                                    <div style={{ fontSize: '15px', fontWeight: '500', letterSpacing: '1px', color: 'black', padding: '5px 10px' }}>Report Details</div>
                                    <div style={{ width: '100%', height: 'calc(100% - 40px)', overflow: 'auto', padding: '0px 10px' }}>
                                        <div style={{ margin: '5px 0px 0px 0px', display: 'flex', width: '100%' }}>
                                            <div style={{ width: '60%' }}>
                                                <div className="itemKey1" style={{ textAlign: "left", lineHeight: '20px' }}>Customer Name</div>
                                                <div className="services-content-titleGreen" style={{ textAlign: "left", lineHeight: '25px' }}>{this.state.reportDetailSelect.customer_name ? this.state.reportDetailSelect.customer_name.length > 12 ?
                                                    `${this.state.reportDetailSelect.customer_name.substring(0, 12)}...` :
                                                    `${this.state.reportDetailSelect.customer_name}` : 'Unknown'}</div>
                                            </div>
                                            <div style={{ width: '40%' }}>
                                                <div className="itemKey1" style={{ textAlign: "left", lineHeight: '20px' }}>Crop Type</div>
                                                <div className="services-content-titleGreen" style={{ textAlign: "left", lineHeight: '25px' }}>{this.state.reportDetailSelect.crop_type ? this.state.reportDetailSelect.crop_type.length > 12 ?
                                                    `${this.state.reportDetailSelect.crop_type.substring(0, 12)}...` :
                                                    `${this.state.reportDetailSelect.crop_type}` : 'Unknown'}</div>
                                            </div>
                                        </div>

                                        <div style={{ margin: '5px 0px 0px 0px', display: 'flex', width: '100%' }}>
                                            <div style={{ width: '60%' }}>
                                                <div className="itemKey1" style={{ textAlign: "left", lineHeight: '20px' }}>Project Name</div>
                                                <div className="services-content-titleGreen" style={{ textAlign: "left", lineHeight: '25px' }}>{this.state.reportDetailSelect.project_name ? this.state.reportDetailSelect.project_name.length > 12 ?
                                                    `${this.state.reportDetailSelect.project_name.substring(0, 12)}...` :
                                                    `${this.state.reportDetailSelect.project_name}` : 'Unknown'}</div>
                                            </div>
                                            <div style={{ width: '40%' }}>
                                                <div className="itemKey1" style={{ textAlign: "left", lineHeight: '20px' }}>Plan Name</div>
                                                <div className="services-content-titleGreen" style={{ textAlign: "left", lineHeight: '25px' }}>{this.state.reportDetailSelect.plan_name ? this.state.reportDetailSelect.plan_name.length > 12 ?
                                                    `${this.state.reportDetailSelect.plan_name.substring(0, 12)}...` :
                                                    `${this.state.reportDetailSelect.plan_name}` : 'Unknown'}</div>
                                            </div>
                                        </div>

                                        <div style={{ margin: '5px 0px 0px 0px', display: 'flex', width: '100%' }}>
                                            <div style={{ width: '60%' }}>
                                                <div className="itemKey1" style={{ textAlign: "left", lineHeight: '20px' }}>Flight Time</div>
                                                <div className="services-content-titleGreen" style={{ textAlign: "left", lineHeight: '25px' }}>{this.state.reportDetailSelect.flight_time}</div>
                                            </div>
                                            <div style={{ width: '40%' }}>
                                                <div className="itemKey1" style={{ textAlign: "left", lineHeight: '20px' }}>Sprayed Area</div>
                                                <div className="services-content-titleGreen" style={{ textAlign: "left", lineHeight: '25px' }}>{this.state.reportDetailSelect.spray_area} Acr</div>
                                            </div>
                                        </div>

                                        <div style={{ margin: '5px 0px 0px 0px', display: 'flex', width: '100%' }}>
                                            <div style={{ width: '60%' }}>
                                                <div className="itemKey1" style={{ textAlign: "left", lineHeight: '20px' }}>Amount</div>
                                                <div className="services-content-titleGreen" style={{ textAlign: "left", lineHeight: '25px' }}>{this.state.reportDetailSelect.amount} Rs.</div>
                                            </div>
                                            <div style={{ width: '40%' }}>
                                                <div className="itemKey1" style={{ textAlign: "left", lineHeight: '20px' }}>Created Date</div>
                                                <div className="services-content-titleGreen" style={{ textAlign: "left", lineHeight: '25px' }}>{dateFormat(this.state.reportDetailSelect.created_datetime, 'dd/mm/yyyy')}</div>
                                            </div>
                                        </div>
                                        <div style={{ margin: '5px 0px 0px 0px', display: 'flex', width: '100%' }}>
                                            <div style={{ width: '60%' }}>
                                                <div className="itemKey1" style={{ textAlign: "left", lineHeight: '20px' }}>Pesticide</div>
                                                <div className="services-content-titleGreen" style={{ textAlign: "left", lineHeight: '25px' }}>{this.state.reportDetailSelect.pesticide ? this.state.reportDetailSelect.pesticide.length > 10 ? `${this.state.reportDetailSelect.pesticide.substring(0, 10)}...` : `${this.state.reportDetailSelect.pesticide}` : '-'}</div>
                                            </div>
                                            <div style={{ width: '40%' }}>
                                                <div className="itemKey1" style={{ textAlign: "left", lineHeight: '20px' }}>Spray Volume</div>
                                                <div className="services-content-titleGreen" style={{ textAlign: "left", lineHeight: '25px' }}>{this.state.reportDetailSelect.spray_volume ? this.state.reportDetailSelect.spray_volume.length > 10 ? `${this.state.reportDetailSelect.spray_volume.substring(0, 10)}...` : `${this.state.reportDetailSelect.spray_volume}` : '-'}</div>
                                            </div>
                                        </div>
                                        <div style={{ margin: '5px 0px 0px 0px', display: 'flex', width: '100%' }}>
                                            <div style={{ width: '60%' }}>
                                                <div className="itemKey1" style={{ textAlign: "left", lineHeight: '20px' }}>Plot Area</div>
                                                <div className="services-content-titleGreen" style={{ textAlign: "left", lineHeight: '25px' }}>{this.state.reportDetailSelect.plot_area ? `${this.state.reportDetailSelect.plot_area} Acr` : '-'}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> : <div className="empty-data-message" style={{ display: 'flex', flexDirection: 'column', height: '49%', width: '100%', backgroundColor: "#FAFAFA", borderRadius: '5px', alignItems: 'center', justifyContent: 'center' }}>
                                <img src={noCustomerVector} style={{ width: "18%", }} />
                                <div>No Report Found</div>
                            </div>}
                        </div>
                    </div>}
            </div>
        </div >
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateProfileDetails: (payload) => dispatch(updateProfileDetails(payload))
    };
};

const mapStateToProps = state => {
    return {
        activeTeamId: state.profile.activeTeamId,
        activeTeam: state.profile.activeTeam,
        isLoggedInUserOwner: state.profile.isLoggedInUserOwner,
        SubscriptionData: state.subscription.subscriptionData,
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(AeroGCSGreenReports)